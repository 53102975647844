import {
  BackgroundImage,
  Button,
  Col,
  Container,
  Div,
  Divider,
  H6,
  Icons,
  Link,
  Paper,
  Row,
  SideMenu,
  Tab,
  Tabpanel,
  TapSelect,
  Text,
  TextInput,
} from "qdm-component-library";
import React, { useState } from "react";
import AddMember from "../../../components/global/addMember";
import Addmemberform from "../../../components/global/addmemberform";
import Addmemberotp from "../../../components/global/addmemberotp";
import { AppRoutes } from "../../../router/routes";
import { useNavigate } from "react-router-dom";
import AppointmentAddMemberSuccessPage from "../../../components/global/appointmentAddMemberSuccessPage";
import { UIColor } from "../../../themes/theme";
import { makeStyles } from "@mui/styles";
import Logo from "../../../assets/img/svg/logo_B.svg";
import BackgroundUrl from "../../../assets/img/svg/backgroundImage.svg";
import moment from "moment";
import { calculateAge, checkName } from "../../../utils";
import { ErrorMessage } from "../../../components/errorMessage";
import { DobAge } from "../../../components/dobAge";
const genderOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Others", label: "Others" },
];
  const useStyles = makeStyles({
    tabs: {
      '& .tabTitle': {
        borderColor: UIColor.lineBorderFill.color,
        borderRadius: 8
      },
      '& .activeChildHead': {
        letterSpacing: 0,
        fontSize: 14,
        fontFamily: 'pc_medium',
        lineHeight: 1.15
      },
      '& .inActiveChildHead': {
        letterSpacing: 0,
        fontSize: 14,
        color: UIColor.primaryColor.color,
        fontFamily: 'pc_regular',
        lineHeight: 1.15
      },
      '& .tabTitleChildDiv': {
        borderRadius: 8
      }
    },
    textInput: {
      '& input::placeholder': {
        fontFamily: 'pc_regular',
        color: UIColor.tertiaryText.color
      }
    },
    tapSelect: {
      '& .selectionDiv': {
        height: 40,
        borderRadius: 8,
        marginRight: 4,
        '&:last-of-type': {
          marginRight: 0
        },
        '& span': {
          fontFamily: 'pc_regular',
          fontSize: 14
        }
      },
      '& .active': {
        '& span': {
          fontFamily: 'pc_medium !important',
        }
      }
  
    }
  });
function ProceedAsAGuest() {
  const navigate = useNavigate();
  const [state, setState] = useState({
    type: 0,
    name: "",
    age: "2021-05-20",
    gender: "",
    opencalendar: null,
    sidebar: false,
    searchMenber: true,
    memberForm: false,
    memberOtp: false,
    chooseSearch: null,
  });
  const handleContinue = async () => {
   // await props.GUEST_INFO?.(state);
    navigate(AppRoutes.searchFacility);
  };

  const handelChangeSelect = (v) => {
    setState({
      ...state,
      chooseSearch: v,
    });
  };
  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const handlepapover = (e) => {
    if (state.opencalendar) {
      handlepapoverclose();
    } else {
      setState({ opencalendar: e.currentTarget });
    }
  };
  const handlepapoverclose = () => {
    setState({ ...state, opencalendar: null });
  };
  const toggleSideBar = (boolean) => {
    setState({
      state,
      sidebar: boolean,
      memberForm: false,
      searchMenber: true,
    });
  };
  const nextSection = (member) => {
    if (member === "addmemberform") {
      setState({
        ...state,
        searchMenber: false,
        memberForm: true,
        memberOtp: false,
      });
    } else if (member === "addmemberotp") {
      setState({
        ...state,
        searchMenber: false,
        memberForm: false,
        memberOtp: true,
      });
    } else if (member === "back") {
      setState({
        ...state,
        searchMenber: true,
        memberForm: false,
        memberOtp: false,
      });
    } else if (member === "movenextscreen") {
      setState({
        ...state,
        searchMenber: true,
        memberForm: false,
        memberOtp: false,
        sidebar: false,
      });
      window.location.href = "/appointments_listing_page";
    }
  };
  const checkAge = (val) => {
    let years = moment().diff(moment(val, 'DD-MM-YYYY'), 'years');
    let days = moment().diff(moment(val, 'DD-MM-YYYY'), 'days');
    if (!(years < 200 && days >= 0)) {
      return 'Age should be less than 200 years'
    }
    return ''
  }
  const classes =useStyles()
  return (
    <div id="guestsigin_parent_div">
        <Container
          id="guestsigin_parent_container"
          key={"0"}
          name="container"
          fluid={true}
          style={{
            backgroundColor: UIColor.greyBackground.color,
            // height: "100vh",
            padding: "0px",
          }}
        >
          <Row
            id="guestsigin_parent_row"
            key={"0"}
            alignItems="stretch"
            direction="row"
            justify="flex-start"
            spacing="2"
            style={{
              backgroundColor: "#ffffff",
              // borderBottom: "1px solid #eee",
            }}
          >
            <Col
              id="guestsigin_parent_col"
              key={"0"}
              name="qdm-col-12"
              alignItems="stretch"
              direction="row"
              justify="flex-start"
              textAlign="left"
              lg="12"
              xs="12"
              md="12"
              sm="12"
              style={{
                height: "64px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: '12px 37px'
              }}
            >
              <div
                id="guestsigin_header_parent_logo_div"
                style={{
                  width: "96px",
                  height: "32px",
                }}
              >
                <img
                  id="guestsigin_header_logo"
                  key={"0"}
                  variant="rounded"
                  letter="Logo"
                  src={Logo}
                  alt="Logo"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>

              <Div
                id="guestsigin_header_parent_phoneicon_div"
                key={"1"}
                inLineStyles="[object Object]"
                className="qdm"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Icons
                  id="guestsigin_header_phoneicon"
                  key={"0"}
                  fontIcon="phone"
                  ariaHidden="true"
                  className=""
                  size="small"
                  style={{ color: UIColor.secondaryText.color, margin: "10px" }}
                ></Icons>

                <Text
                  id="guestsigin_header_mobilenumber_text"
                  key={"1"}
                  name="+91 9821315141 , +91 9551482612"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 14,
                    color: UIColor.secondaryText.color,
                    letterSpacing: 0
                  }}
                  className="pc_regular"
                ></Text>

                <Divider
                  id="guestsigin_header_rightside_divider"
                  key={"2"}
                  className=""
                  label=""
                  borderColor=""
                  textColor=""
                  orientation="vertical"
                  variant="middle"
                  style={{ height: "27px", marginTop: "6px", marginLeft: '24px', marginRight: '17px' }}
                ></Divider>

                <Button
                  id="guestsignin_header_number_support_button"
                  key={"3"}
                  type="button"
                  variant="text"
                  name="Support"
                  style={{
                    borderColor: UIColor.primaryColor.color,
                    borderWidth: "",
                    borderRadius: "8px",
                    color: UIColor.primaryColor.color,
                    height: "36px",
                    lineHeight: 0,
                    fontSize: 14,
                    padding: '8px 16px'
                  }}
                  className="pc_medium"
                ></Button>
              </Div>
            </Col>
          </Row>

          <Row
            id="guestsignin_body_row"
            key={"1"}
            alignItems="stretch"
            direction="row"
            justify="flex-start"
            spacing="2"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 64px)",
              backgroundColor: "#F0F7FF",
              overflow: 'auto'
            }}
          >
            <BackgroundImage
              id="guestsignin_body_background_image"
              key={"0"}
              url={BackgroundUrl}
              alt="Mask-Group-71-2x"
              backgroundPosition="0% 0%"
              backgroundPositionX="center"
              backgroundPositionY="top"
              backgroundRepeat="no-repeat"
              backgroundRepeatX="no-repeat"
              backgroundRepeatY="no-repeat"
              backgroundAttachment="scroll"
              backgroundSize="contain"
              backgroundOrigin="padding-box"
              inLineStyles="[object Object]"
              style={{
                // height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Paper
                id="guestsignin_body_parent_paper"
                key={"0"}
                children="Paper"
                variant="elevation"
                elevation="1"
                style={{
                  width: "350px",
                  height: "",
                  backgroundColor: "#ffffff",
                  padding: "24px 32px 24px 32px",
                  borderRadius: "16px",
                  marginBottom: "24px",
                  marginTop: "24px",
                }}
              >
                <H6
                  id="guestsignin_title_h6"
                  key={"0"}
                  name="Welcome!"
                  style={{ color: UIColor.primaryText.color }}
                  inLineStyles={{
                    fontFamily: "'pc_semibold' !important",
                    fontSize: "24px",
                    letterSpacing: 0,
                    lineHeight: 1,
                    marginBottom: 12
                  }}
                  className={"pc_semibold"}
                ></H6>

                <H6
                  id="guestsignin_sublevel_text"
                  name="Share your basic info for personalized experience."
                  style={{ fontSize: "11px", color: UIColor.secondaryText.color, letterSpacing: '-0.19px', margin: 0, marginBottom: 24 }}
                  className={"pc_regular"}
                ></H6>

                <H6
                  id="guestsignin_booking_h6"
                  key={"2"}
                  name="Booking for?"
                  style={{
                    fontSize: "12px",
                    color: UIColor.secondaryText.color,
                    marginBottom: "8px",
                    letterSpacing: 0,
                    lineHeight: 1,

                  }}
                  className={"pc_regular"}
                ></H6>

                <Tab
                  id="guestsignin"
                  key={"3"}
                  align="center"
                  activetabs={0}
                  name=""
                  inLineStyles="[object Object]"
                  className=""
                  label=""
                  backgroundColor={UIColor.secondaryButtonColor.color}
                  inLineTabTitleStyles="[object Object]"
                  fullWidth={true}
                  size="small"
                  elevation="0"
                  border={false}
                  variant="bordered"
                  // activeColor={UIColor.primaryColor.color}
                  activeColor={"#fff"}
                  //borderColor={UIColor.lineBorderFill.color}
                  style={{
                    // marginTop: "2px",
                    // padding: "0px",
                    // borderRadius: "8px",
                  }}
                  onChange={(e) => {
                    if (!isNaN(e)) {
                      if (e === 0 || e === 1) {
                        setState({
                          ...state,
                          error: {
                            name: false,
                            gender: false,
                            age: false,
                          },
                        }, () => {
                          changeState("type", e);
                        })
                      }
                    }
                  }}
                >
                  <Tabpanel
                    id="guestsignin_myself_parent_tabpanel"
                    key={"0"}
                    label="Myself"
                    title="Myself"
                    color={UIColor.primaryColor.color}
                    //backgroundColor="red"
                    subTitle=""
                    style={{
                      height: "",
                      padding: "0px",
                      paddingLeft: "",
                      paddingTop: "12px",
                      lineHeight: 1,
                    }}
                    inLineStyles={{
                      borderRadius: "8px",
                      fontFamily: "'pc_medium' !important",
                      color: UIColor.primaryColor.color,
                    }}
                    className={"pc_medium"}
                  >
                    <TextInput
                      id="guestsignin_myself_entername_textinput"
                      key={"0"}
                      placeholder="Enter Name"
                      className={classes.textInput}
                      value={state.name}
                      onChange={(e) => {
                        if (e.target.value) {
                          setState({
                            ...state,
                            error: {
                              ...state.error,
                              name: false,
                              invalidName: !checkName(e.target.value)
                            },
                          })
                        }
                        changeState("name", e.target.value)
                      }}
                      type="text"
                      name="text"
                      variant="outlined"
                      helperTextType="error"
                      maxLength={30}
                      label={
                        <p
                          id="guestsignin_myself_entername_textinput_span"
                          className={"pc_regular"}
                          style={{
                            fontSize: "12px",
                            color: UIColor.secondaryText.color,
                            margin: 0,
                            marginBottom: "8px",
                            letterSpacing: 0,
                            lineHeight: 1,
                          }}
                        >
                          Your Name <span
                            id="guestsignin_myself_entername_textinput_span_one"
                            style={{ color: UIColor.error.color }}>*</span>
                        </p>
                      }
                      autoFocus={true}
                      autoComplete="off"
                      search={false}
                      borderColor={UIColor.lineBorderFill.color}
                      padding="10"
                      margin="0"
                      elevation="0"
                      style={{
                        marginBottom: "12px",
                        borderRadius: 8,
                        //borderColor: UIColor.lineBorderFill.color,
                        fontFamily: 'pc_medium'
                      }}
                    error={state?.error?.name && true}
                    ></TextInput>
                    {state.error?.name ? (
                      <div style={{ marginBottom: "10px" }}>
                        <Text
                          id="guestsignin_myself_entername_textinput_error"
                          style={{
                            color: UIColor.error.color,
                            fontSize: 12,
                          }}
                          className="pc_regular"
                        >
                          {"Please Enter Your Name"}
                        </Text>
                      </div>
                    ) : state.error?.invalidName ? (
                      <div
                        id="guestsignin_myself_entername_textinput_error_div"
                        style={{ marginBottom: "5px" }}>
                        <Text
                          id="guestsignin_myself_entername_textinput_error_empty"
                          style={{
                            color: UIColor.error.color,
                            fontSize: 12,
                          }}
                          className="pc_regular"
                        >
                          {"Please Enter Valid Name"}
                        </Text>
                      </div>
                    ) : null}
                    <Div
                      id="guestsignin_myself_select_dob_textinput_wrapper"
                      style={{
                        marginBottom: 12
                      }}
                    >
                      <DobAge
                        parentId="guestsignin_myself_select_dob_textinput"
                        showAge
                        dateLabel="DOB"
                        ageValue={
                          calculateAge(state?.age?.val)
                        }
                        isRequired
                        dateValue={state.age.val}
                        onChange={(value) => {
                          console.log("dobValue1",value);
                          if (value) {
                            const milli = moment(value, 'YYYY-MM-DD').valueOf();
                            const date = {
                              val: moment(value, 'YYYY-MM-DD').format('DD-MM-YYYY'),
                              milli,
                            };
                            changeState("age", date);
                          } else {
                            changeState("age", {
                              val: '',
                              milli: moment().valueOf(),
                            });
                          }
                        }}
                        maxDate={new Date()}
                        minDate={(moment(new Date()).subtract(200, 'years'))._d}
                        error={state.error?.age && (!state.age.val || state.age.val === 'Invalid date') && true}
                        placeholder="mm/dd/yyyy"
                        top={40}
                        right={10}
                      />
                    </Div>

                    {((state.age.val && state.age.val !== 'Invalid date') && checkAge(state?.age?.val)) ? (
                      <ErrorMessage
                        label="Age should be less than 200 years"
                        parentId="guestsignin_myself_age_lessthan_200Yr"
                      />
                    ) : (
                      <>
                        {state.error?.age && (!state.age.val || state.age.val === 'Invalid date') &&
                          <ErrorMessage
                            label="Please Select DOB"
                            parentId="guestsignin_myself_age"
                          />
                        }
                      </>
                    )}

                    <TapSelect
                      id="guestsignin_myself_gender_tapselect"
                      key={"2"}
                      type="single"
                      textSize="16"
                      iconHeight="20"
                      iconWidth="20"
                      selectedBorderColor={UIColor.secondaryButtonColor.color} 
                      selectedBackgroundColor={UIColor.secondaryButtonColor.color}
                      selectedTextColor="#fff"
                      borderColor={UIColor.differentiationBackground.color}
                      backgroundColor={UIColor.differentiationBackground.color}
                      textColor={UIColor.secondaryButtonColor.color}
                      label={
                        <H6
                          id="guestsignin_myself_gender_tapselect_span"
                          style={{
                            fontSize: "12px",
                            color: UIColor.primaryText.color,
                            margin: 0,
                            lineHeight: 1,
                            letterSpacing: 0
                          }}
                          className={"pc_regular"}
                        >
                          Gender <span
                            id="guestsignin_myself_gender_tapselect_staricon"
                            style={{ color: UIColor.error.color }}>*</span>
                        </H6>
                      }
                      options={genderOptions}
                      fullWidth
                      style={{ marginTop: "", paddingTop: "" }}
                      defaultSelected={state.gender}
                      onChange={(e) => {
                        if (e && Object.keys(e).length > 0) {
                          setState({
                            ...state,
                            error: {
                              ...state.error,
                              gender: false,
                            },
                          })
                          changeState("gender", e)
                        } else {
                          changeState("gender", null)
                        }
                      }}
                      inLineStyles={{
                        padding: "8px 0px 8px 0px",
                        // borderColor: UIColor.secondaryButtonColor.color,
                        fontSize: "14px",
                        fontFamily: "'pc_regular' !important",
                        margin: "8px 10px 10px 0px",
                      }}
                      className={classes.tapSelect}
                    // className={'pc_regular'}
                    ></TapSelect>
                    {state.error?.gender && (
                      <Text
                        id="guestsignin_myself_gender_tapselect_error"
                        style={{
                          fontSize: "12px",
                          color: UIColor.error.color,
                        }}
                        className={"pc_regular"}
                      >
                        {"Please Select Gender"}
                      </Text>
                    )}
                  </Tabpanel>

                  <Tabpanel
                    id="guestsignin_othres_parent_tabpanel"
                    key={"1"}
                    label="Others"
                    title="Others"
                    color={UIColor.primaryColor.color}
                    style={{
                      height: "",
                      padding: "0px",
                      paddingLeft: "",
                      paddingTop: "12px",
                      lineHeight: 1
                    }}
                    className={"pc_regular"}
                  >
                    <TextInput
                      id="guestsignin_others_entername_textinput"
                      key={"0"}
                      className={classes.textInput}
                      style={{
                        marginBottom: "12px",
                        borderRadius: 8,
                        //borderColor: UIColor.lineBorderFill.color,
                        fontFamily: 'pc_medium'
                      }}
                      value={state.name}
                      onChange={(e) => {
                        if (e.target.value) {
                          setState({
                            ...state,
                            error: {
                              ...state.error,
                              name: false,
                              invalidName: !checkName(e.target.value)
                            },
                          })
                        }
                        changeState("name", e.target.value)
                      }}
                      placeholder="Enter Name"
                      type="text"
                      name="text"
                      maxLength={30}
                      variant="outlined"
                      helperTextType="error"
                      label={
                        <p
                          id="guestsignin_others_entername_textinput_span"
                          className={"pc_regular"}
                          style={{
                            fontSize: "12px",
                            color: UIColor.secondaryText.color,
                            margin: 0,
                            marginBottom: "8px",
                            letterSpacing: 0,
                            lineHeight: 1,
                          }}
                        >
                          Patient Name <span style={{ color: UIColor.error.color }}>*</span>
                        </p>
                      }
                      autoFocus={false}
                      autoComplete="off"
                      search={false}
                      borderColor={UIColor.lineBorderFill.color}
                      padding="10"
                      margin="0"
                      elevation="0"
                      error={(state?.error?.name || state.error?.invalidName) && true}
                    ></TextInput>
                    {state.error?.name ? (
                      <div
                        id="guestsignin_others_entername_textinput_error_div"
                        style={{ marginBottom: "10px" }}>
                        <Text
                          id="guestsignin_others_entername_textinput_error"
                          style={{
                            color: UIColor.error.color,
                            fontSize: 12,
                          }}
                        >
                          {"Please Enter Patient Name"}
                        </Text>
                      </div>
                    ) : state.error?.invalidName ? (
                      <div
                        id="guestsignin_others_entervalid_textinput_error_div"
                        style={{ marginBottom: "10px" }}>
                        <Text
                          id="guestsignin_others_entervalid_error_text"
                          style={{
                            color: UIColor.error.color,
                            fontSize: 12,
                          }}
                        >
                          {"Please Enter Valid Name"}
                        </Text>
                      </div>
                    ) : null}
                    <Div
                      id="guestsignin_myself_select_dob_textinput_wrapper"
                      style={{
                        marginBottom: 12
                      }}
                    >
                      <DobAge
                        parentId="guestsignin_others_select_dob_textinput"
                        showAge
                        dateLabel="DOB"
                        ageValue={
                          calculateAge(state?.age?.val)
                        }
                        isRequired
                        dateValue={state.age.val}
                        onChange={(value) => {
                          if (value) {
                            const milli = moment(value, 'YYYY-MM-DD').valueOf();
                            const date = {
                              val: moment(value, 'YYYY-MM-DD').format('DD-MM-YYYY'),
                              milli,
                            };
                            changeState("age", date);
                          }
                        }}
                        maxDate={new Date()}
                        minDate={(moment(new Date()).subtract(200, 'years'))._d}
                        // maxDate={moment(new Date()).format('YYYY-MM-DD')}
                        // minDate={(moment(new Date()).subtract(200, 'years')).format('YYYY-MM-DD')}
                        placeholder="mm/dd/yyyy"
                        error={state.error?.age && (!state.age.val || state.age.val === 'Invalid date') && true}
                        top={40}
                        right={10}
                      />
                    </Div>
                    {((state.age.val && state.age.val !== 'Invalid date') && checkAge(state?.age?.val)) ? (
                      <ErrorMessage
                        label="Age should be less than 200 years"
                        parentId="guestsignin_myself_age_lessthan_200Yr"
                      />
                    ) : (
                      <>
                        {state.error?.age && (!state.age.val || state.age.val === 'Invalid date') &&
                          <ErrorMessage
                            label="Please Select DOB's"
                            parentId="guestsignin_myself_age"
                          />
                        }
                      </>
                    )}

                    <TapSelect
                      id="guestsignin_others_gender_tapselect"
                      key={"2"}
                      type="single"
                      fullWidth
                      textSize="16"
                      iconHeight="20"
                      iconWidth="20"
                      selectedBorderColor={UIColor.secondaryButtonColor.color} 
                      selectedBackgroundColor={UIColor.secondaryButtonColor.color}
                      selectedTextColor="#fff"
                      borderColor={UIColor.differentiationBackground.color}
                      backgroundColor={UIColor.differentiationBackground.color}
                      textColor={UIColor.secondaryButtonColor.color}
                      label={
                        <H6
                          id="guestsignin_others_gender_label_span"
                          style={{
                            fontSize: "12px",
                            color: UIColor.primaryText.color,
                            margin: 0,
                            lineHeight: 1,
                            letterSpacing: 0
                          }}
                          className={"pc_regular"}
                        >
                          Gender <span style={{ color: UIColor.error.color }}>*</span>
                        </H6>
                      }
                      options={genderOptions}
                      style={{ marginTop: "", paddingTop: "" }}
                      defaultSelected={state.gender}
                      onChange={(e) => {
                        if (e && Object.keys(e).length > 0) {
                          setState({
                            ...state,
                            error: {
                              ...state.error,
                              gender: false,
                            },
                          })
                          changeState("gender", e)
                        } else {
                          changeState("gender", null)
                        }
                      }}
                      inLineStyles={{
                        padding: "8px 0px 8px 0px",
                        // borderColor: UIColor.secondaryButtonColor.color,
                        //borderColor:"#E0E0E0",
                        fontSize: "14px",
                        fontFamily: "'pc_regular' !important",
                        margin: "8px 10px 10px 0px",
                      }}
                      className={classes.tapSelect}
                    ></TapSelect>
                    {state.error?.gender && (
                      <Text style={{ color: UIColor.error.color, fontSize: 14 }}
                        id="guestsignin_others_gender_error"
                      >
                        {"Please Select Gender"}
                      </Text>
                    )}
                  </Tabpanel>
                </Tab>
                <Button
                  id="guestsignin_others_continue_as_guest_button"
                  key={"4"}
                  onClick={handleContinue}
                  type="button"
                  variant="outlined"
                  name="Continue as Guest"
                  style={{
                    width: "100%",
                    marginTop: "14px",
                    borderRadius: "8px",
                    backgroundColor: UIColor.primaryColor.color,
                    borderColor: UIColor.primaryColor.color,
                    fontSize: "14px",
                    height: "40px",
                    letterSpacing: "0px",
                    lineHeight: 1
                  }}
                  className={"pc_medium"}
                ></Button>

                <Div
                  id="guestsignin_others_continue_as_guest_bottom_div"
                  key={"5"}
                  inLineStyles="[object Object]"
                  className="qdm"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop: "26px",
                  }}
                >
                  <Divider
                    id="guestsignin_others_continueas_as_guest_button_below_rightside_divider"
                    key={"0"}
                    className=""
                    label=""
                    borderColor=""
                    textColor=""
                    orientation="horizontal"
                    variant="fullWidth"
                    style={{ width: "130px" }}
                  ></Divider>

                  <Text
                    id="guestsignin_others_continueas_as_guest_button_below_center_or_text"
                    key={"1"}
                    name="Or"
                    style={{
                      color: UIColor.secondaryText.color,
                      width: "28px",
                      paddingLeft: "6px",
                      paddingRight: "6px",
                      backgroundColor: "#ffffff",
                      fontSize: 14
                    }}
                    className={"pc_regular"}
                  ></Text>

                  <Divider
                    id="guestsignin_others_continueas_as_guest_button_below_leftside_divider"
                    key={"2"}
                    className=""
                    label=""
                    borderColor=""
                    textColor=""
                    orientation="horizontal"
                    variant="fullWidth"
                    style={{ width: "130px" }}
                  ></Divider>
                </Div>

                <Button
                  id="guestsignin_others_continueas_as_guest_button_below_signin_button"
                  key={"6"}
                  onClick={() => {
                  //  props.history.push(Routes.signIn);
                  navigate(AppRoutes.signIn)
                  }}
                  type="button"
                  variant="text"
                  name="Sign In"
                  style={{
                    borderColor: UIColor.primaryColor.color,
                    borderRadius: "8px",
                    color: UIColor.primaryColor.color,
                    marginTop: "25px",
                    width: "100%",
                    fontSize: "14px",
                    height: "40px",
                    letterSpacing: "0px",
                    lineHeight: 1
                  }}
                  className={"pc_medium"}
                ></Button>

                <Div
                  id="guestsignin_others_continueas_as_guest_button_below_signup_div"
                  key={"7"}
                  inLineStyles="[object Object]"
                  className="qdm"
                  style={{
                    marginTop: "28px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    id="guestsignin_others_continueas_as_guest_button_below_dont_have_text"
                    key={"0"}
                    name="Don't have an account?"
                    color={UIColor.secondaryText.color}
                    style={{ color: UIColor.secondaryText.color, fontSize: "12px" }}
                    className={"pc_regular"}
                  ></Text>

                  <Link
                    id="guestsignin_others_continueas_as_guest_button_below_signup_link"
                    onClick={() => toggleSideBar(true)}
                    key={"1"}
                    underline="hide"
                    color="primary"
                    name="Sign Up"
                    style={{
                      color: UIColor.secondaryButtonColor.color,
                      fontSize: "12px",
                    }}
                    className={"pc_regular"}
                    inLineStyles={{ textDecoration: "unset" }}
                  ></Link>
                  <SideMenu
                    id="guestsignin_others_continueas_as_guest_sidemenu_parent_sidemenu"
                    open={state.sidebar}
                    direction="right"
                    width={431}
                    color="default"
                    onClose={() => toggleSideBar(false)}
                  >
                    {state.searchMenber ? (
                      <AddMember
                        signUp
                        parent_id={'guestsignin'}
                        name={"Sign Up"}
                        nextSection={(member, v, l) =>
                          nextSection(member, v, l)
                        }
                        signUpData={state.signUpData}
                        handelChangeSelect={(v) => handelChangeSelect(v)}
                        chooseSearch={state.chooseSearch}
                      />
                    ) : state.memberForm ? (
                      <Addmemberform
                        parent_id={'guestsignin'}
                        signUpData={state.signUpData}
                        nextSection={(member, signUpData) =>
                          nextSection(member, signUpData)
                        }
                      />
                    ) : state.memberOtp ? (
                      <Addmemberotp
                        parent_id={'guestsignin'}
                        addmmber={state.addmmber}
                        signUpData={state.signUpData}
                        nextSection={(member, v) => nextSection(member, v)}
                      />
                    ) : null}
                    {state.memberSuccessPage ? (
                      <AppointmentAddMemberSuccessPage
                        parent_id={'guestsignin'}
                        addmmber={state.addmmber}
                        signUpData={state.signUpData}
                        nextSection={(member, v, l) =>
                          nextSection(member, v, l)
                        }
                        toggleSideBar={(member) => toggleSideBar(member)}
                      />
                    ) : null}
                  </SideMenu>
                </Div>
              </Paper>
            </BackgroundImage>
          </Row>
        </Container>
      </div>
  );
}

export default ProceedAsAGuest;
