import React, { useState } from "react";
import {
  Avatar,
  Select,
  Card,
  Row,
  Text,
  H6,
  Div,
  Image,
} from "qdm-component-library";
import iconHigh from "../../../assets/img/icons8-high priority red.svg";
import iconHighAlert from "../../../assets/img/icons8-high priority white.svg";
import { Popover } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HighAlertPopUp from "../../../components/highAlertPopUp";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    width: "290px",
  },
}));
function PractitionerCard(props) {
  const styles = {
    card: {
      height: "100%",
      padding: "12px",
      boxShadow: "0px 10px 25px #0000000A",
      border: "1px solid #E0E0E0",
      borderRadius: 8,
    },
    title: {
      fontSize: 14,
      color: "#101010",
    },
    subTitle: {
      fontSize: 12,
      color: "#646464",
    },
    profilePic: {
      margin: 0,
      width: 50,
      height: 50,
      borderRadius: "8px",
    },
    emergencyDiv: {
      position: "absolute",
      backgroundColor: "#FF4D4A",
      borderRadius: "0px 0px 8px 8px",
      bottom: 0,
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "3px",
    },
    emergencyText: {
      color: "#FFFFFF",
      fontSize: "5px",
    },
    appointmentCount: {
      color: "var(--unnamed-color-f58b00)",
      textAlign: "left",
      //font: "normal normal 600 12px/18px Poppins",
      letterSpacing: "0px",
      color: "#F58B00",
      opacity: 1,
      fontSize: "12px",
      fontWeight: 600,
    },
  };
  const { parent_id } = props;
  const classes = useStyles();

  return (
    <div
      id={`${parent_id}${props.title.replaceAll(" ", "_")}_parent_div`}
      style={{ height: "100%", cursor: "pointer" }}
      onClick={() => props.handleClick(props.value)}
    >
      <Card
        id={`${parent_id}${props.title.replaceAll(" ", "_")}_parent_card`}
        style={{
          ...styles.card,
          border: props.selected ? "1px solid #0071F2" : "1px solid #E0E0E0",
        }}
      >
        <Row
          id={`${parent_id}${props.title.replaceAll(" ", "_")}_parent_row`}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            id={`${parent_id}${props.title.replaceAll(" ", "_")}_sub_div`}
            md={6}
            lg={6}
          >
            <Row
              id={`${parent_id}${props.title.replaceAll(" ", "_")}_sub_row`}
              alignItems={"center"}
            >
              <Div
                id={`${parent_id}${props.title.replaceAll(
                  " ",
                  "_"
                )}_avatar_div`}
                style={{ position: "relative", marginRight: 12 }}
              >
                <Avatar
                  id={`${parent_id}${props.title.replaceAll(" ", "_")}_avatar`}
                  variant="square"
                  letter="Avatar"
                  src={props?.url}
                  alt="Image"
                  // inLineStyles={styles.profilePic}
                  style={{
                    ...styles.profilePic,
                    border: props?.isEmergency ? "1px dashed #FF4D4A" : "0",
                  }}
                />
              </Div>
              <div
                id={`${parent_id}${props.title.replaceAll(
                  " ",
                  "_"
                )}_sub_title_div`}
              >
                <H6
                  id={`${parent_id}${props.title.replaceAll(
                    " ",
                    "_"
                  )}_sub_title_h6`}
                  className="pc_medium"
                  style={styles.title}
                >
                  {props.title}
                </H6>
                <Text
                  id={`${parent_id}${props.title.replaceAll(
                    " ",
                    "_"
                  )}_sub_title_text`}
                  className="pc_regular"
                  style={styles.subTitle}
                >
                  <span style={styles.appointmentCount}>
                    {props.appointmentCount ?? "00"}
                  </span>{" "}
                  {"Patient Assigned"}
                </Text>
              </div>
            </Row>
          </div>
          {props.selected && (
            <div
              id={`${parent_id}${props.title.replaceAll(
                " ",
                "_"
              )}_attend_button_div`}
              md={6}
              lg={6}
              style={{
                //backgroundColor:props?.priorityTotalData?.highPriority ? "#FF4D4A":"#F9E6E6",
                padding: "5px 8px 3px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            >
              <Image
                inLineStyles={{
                  height: "17px",
                  width: "16px",
                }}
                id={`${parent_id}${props.title.replaceAll(
                  " ",
                  "_"
                )}_high_priority`}
                src={"images/Group 93231.svg"}
              />
            </div>
          )}
        </Row>
      </Card>
    </div>
  );
}

PractitionerCard.defaultProps = {
  url: null,
  title: "",
  //subTitle: "",
  selected: false,
};

export default PractitionerCard;
