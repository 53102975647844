import React, { useState, useEffect } from "react";
import withAppBar from "../../hoc/withAppBar";
import { Div, Row, Col, H6, Paper } from "qdm-component-library";
import { TopNavbar, TopHeader } from "../../components";
// import Loading from "../../components/global/loading";
import withAllContexts from "../../hoc/withAllContexts";
import { referralRegisterTitle } from "../../utils/routesList";
import actions from "../../redux/actions";
import { useDispatch } from "react-redux";
import ReferralInRegistration from "./components/referralRegister";
import {
  AlertProps,
  actionButtonClick,
  isFullScreen,
  readFileAsBase64,
  urls,
  __tenantid__,
  getGlobalValueFrom_LclStrg_And_QryStrg
} from "../../utils";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
import jwtDecode from "jwt-decode";

const ReferralRegistration = (props) => {
  const userInfo = localStorage.getItem("UserInfo");
  const decodedUserinfo = jwtDecode(userInfo);
  // const roleName = decodedUserinfo.rolename;
  const facilityid = decodedUserinfo.facilityid;
  const tenantid = decodedUserinfo.tenantid;
  const practitionerid = decodedUserinfo.practitionerid;
  const [isError, setisError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location, "referal location");
  const [allMasters, setAllMasters] = useState([]);
  const [btnName, setBtnName] = useState(false);
  const [stateClear, setStateClear] = useState(false);
  const [editData, setEditData] = useState(false);
  const [files, setFiles] = React.useState([]);
  const [viewFiles, setViewFiles] = useState();
  const [referralRegistrationdatas, setReferralRegistratiodatas] = useState({});
  const [facilityNameData, setFacilityNameData] = useState([]);
  const [serviceRequestedData, setServiceRequestedData] = useState([]);
  const [practitionerNameData, setPractitionerNameData] = useState([]);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [actionName, setActionName] = useState("");
  const [cancelbookingreason, setCancelbookingreason] = useState({});
  const [loadDefaultData, setLoadDefaultData] = useState(false);

  const getAllMasters = async () => {
    const response = await dispatch(actions.GET_ALL_MASTERS(""));
    setAllMasters(response?.payload?.data);
  };

  function formatDate(inputDate) {
    const parts = inputDate?.split("-");
    if (parts?.length === 3) {
      const year = parts[0];
      const month = parts[1];
      const day = parts[2];
      const formattedDate = `${day}/${month}/${year}`;
      return formattedDate;
    } else {
      // Handle invalid input date format
      return "Invalid Date Format";
    }
  }
  let Date_of_Referral_In = formatDate(
    referralRegistrationdatas?.Date_of_Referral_In
  );
  const DateofReferralInEpochTime = dateToEpoch(Date_of_Referral_In);

  function dateToEpoch(dateString) {
    const [day, month, year] = dateString.split("/").map(Number);

    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return null;
    }

    const date = new Date(year, month - 1, day);
    const epochTime = date.getTime() / 1000;

    return epochTime;
  }
  const saveReferralDatas = async () => {
    let attachments = [];
    const promises = files.map((val) => readFileAsBase64(val));
    if (promises) {
      const base64Results = await Promise.all(promises);
      files?.map((val, i) => {
        let obj = {
          fileName: val?.name,
          fileid: "",
          filetype: val?.type,
          base64string: base64Results[i],
        };
        attachments.push(obj);
      });
    }
    if (viewFiles === undefined) {
      attachments = attachments ? attachments : [];
    } else {
      // attachments =
      //   viewFiles === undefined ? attachments : attachments.concat(viewFiles);
      const base64Results = await Promise.all(viewFiles);
      viewFiles?.map((val, i) => {
        let obj = {
          _id: val?._id,
          _key: val?._key,
          date: val?.date,
          fileid: val?.fileid,
          id: val?.id,
          objectid: val?.objectid,
          reftype: val?.reftype,
          url: val?.url,
          fileName: val?.fileName,
          filetype: val?.filetype,
          // base64string: base64Results[i],
          is_deleted: val?.is_deleted,
        };
        attachments.push(obj);
      });
    }
    let otherhealthcondList = [];
    referralRegistrationdatas?.Other_Health_Condition?.map((v, i) => {
      otherhealthcondList.push(v?._id);
    });
    const saveReferralData = {
      _id:
        location?.state?.referralId ??
        location?.state?.referraldetails?._id ??
        "",
      HealthCareServiceRequested:
        referralRegistrationdatas?.Referring_from_Facility?._id,
      RefSourceLetDate: DateofReferralInEpochTime,
      RequestorPractioner: referralRegistrationdatas?.Referring_Doctor,
      patientId: location?.state?.patientId,
      recipientBookingReqd: true,
      serviceRequested: [referralRegistrationdatas?.Service_Requested?._id],
      registrationtype: referralRegistrationdatas?.registration_type?._id,
      predialcentre: referralRegistrationdatas?.Previous_Dialysis_Centre,
      hepatitis: [referralRegistrationdatas?.Hepatitis?._id],
      otherhealthcond: otherhealthcondList,
      attachment: attachments,
      refstatus: process.env.REACT_APP_REFERRAL_REFSTATUS,
      tenantid: tenantid,
      facilityid: facilityid,
      reason: " ",
      remarks: "",
      practitionerroleid: practitionerid,
      queueid: "",
      scenarioid: "",
      healthdisease: referralRegistrationdatas?.disease,
      // participants: [
      //   {
      //     resoucetype: "",
      //     ressubtype: "",
      //     resouceid: "",
      //   },
      // ],
    };
    return saveReferralData;
  };
  const successfun = async () => {
    let saveReferralData = await saveReferralDatas();
    if (
      referralRegistrationdatas.length === 0 ||
      saveReferralData?.RequestorPractioner == null ||
      saveReferralData?.RefSourceLetDate == null ||
      saveReferralData?.serviceRequested == null ||
      saveReferralData?.HealthCareServiceRequested == null ||
      saveReferralData?.registrationtype == null ||
      saveReferralData?.hepatitis == null ||
      saveReferralData?.otherhealthcond.length == 0
    ) {
      setisError(true);
      props?.alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please save the details",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    } else if (
      (saveReferralData?.registrationtype === "CodingMaster/11451" &&
        saveReferralData?.predialcentre == null) ||
      ((saveReferralData?.otherhealthcond === "CodingMaster/11477" ||
        saveReferralData?.otherhealthcond === "CodingMaster/11478") &&
        saveReferralData?.healthdisease == null)
    ) {
      //CodingMaster/11477 CodingMaster/11478
      setisError(true);
      props?.alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please save the details",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    } else {
      setBtnName(true);
      let payload = {
        db_name: process.env.REACT_APP_DB,
        entity: "ReferralTrx",
        is_metadata: true,
        metadataId: process.env.REACT_APP_METADATAID,
        metadata_dbname: process.env.REACT_APP_METADATA_DBNAME,
        doc: saveReferralData,
      };
      let token = localStorage.getItem("JwtToken");

      await axios({
        method: "POST",
        url: urls.saveReferralRegistration,
        headers: {
          "Content-Type": "application/json",
          jwtToken: `${token}`,
        },
        data: JSON.stringify([payload]),
      })
        .then((res) => {
          
          if (res.status === 200 && !res.data.error) {
            props?.alert?.setSnack({
              open: true,
              severity: AlertProps.severity.success,
              msg:
                location?.state?.referralId ??
                  location?.state?.referraldetails?._id
                  ? "Updated successfully"
                  : "Added successfully",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
              tone: true,
            });
            console.log("skip", {
              prevRoute: AppRoutes.clinicalInfo,
              patientId: location.state.patientId ?? props?.patientId,
              referraldetails:
                res?.data?.[0]?.response?.[0]?.referraldetails ??
                location?.state?.referraldetails,
              addtionaldetails: location?.state?.addtionaldetails,
              nextofkin: location?.state?.nextofkin,
              patientdetails: location?.state?.patientdetails,
              isModify: location?.state?.isModify ?? false,
              userId: location?.state?.personId,
              encounterID:
                res?.data?.[0]?.response?.[0]?.encounterinfo?._id ??
                location?.state?.encounterID ??
                "",
              personid:
                location?.state?.personId || location?.state?.personid,
              referralId:
                res?.data?.[0]?.response?.[0]?.referraldetails?._id,
              //   appointmentInfo: {},
              //   stateAction: location?.state?.stateAction,
              //   stateEndPoint: location?.state?.stateEndPoint,
              //   stateBackEndCall: location?.state?.stateBackEndCall,
              //   stateTicketid: location?.state?.stateTicketid
            });
            setBtnName(false);
            clearState();
            location?.state?.isModify === true ?  navigate(AppRoutes.dashboard) :
            navigate(AppRoutes.clinicalInfo, {
              state: {
                prevRoute: AppRoutes.clinicalInfo,
                patientId: location.state.patientId ?? props?.patientId,
                referraldetails:
                  res?.data?.[0]?.response?.[0]?.referraldetails ??
                  location?.state?.referraldetails,
                addtionaldetails: location?.state?.addtionaldetails,
                nextofkin: location?.state?.nextofkin,
                patientdetails: location?.state?.patientdetails,
                isModify: location?.state?.isModify ?? false,
                userId: location?.state?.personId,
                encounterID:
                  res?.data?.[0]?.response?.[0]?.encounterinfo?._id ??
                  location?.state?.encounterID ??
                  "",
                personid:
                  location?.state?.personId || location?.state?.personid,
                referralId:
                  res?.data?.[0]?.response?.[0]?.referraldetails?._id,
                //   appointmentInfo: {},
                //   stateAction: location?.state?.stateAction,
                //   stateEndPoint: location?.state?.stateEndPoint,
                //   stateBackEndCall: location?.state?.stateBackEndCall,
                //   stateTicketid: location?.state?.stateTicketid
              },
            });
          } else {
            props?.alert?.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: res.data.message,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
              tone: true,
            });
            setBtnName(false);
          }
        })
        .catch((error) => {
          props?.alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Please contact to admin",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          setBtnName(false);
        });
    }
  };
  const handleSideMenu = () => {
    setIsSideMenuOpen(true);
  };
  const handleSideMenuclose = () => {
    setIsSideMenuOpen(false);
  };

  const handleSelectCancelbooking = (v) => {
    setCancelbookingreason(v);
  };

  const actionsMap = location?.state?.totalData?.action.reduce(
    (map, actionItem) => {
      map[actionItem.action] = {
        actionName: actionItem.action,
        action: actionItem.action,
        apiEndPoint: actionItem.apiendpoint,
        backendCall: actionItem.backEndCall,
      };
      return map;
    },
    {}
  );
  const cancelData = () => {
    if (actionName) {
      let data = {};
      const { action, apiEndPoint, backendCall } = actionsMap[actionName];
      data.action = action;
      data.apiEndPoint = apiEndPoint;
      data.backendCall = backendCall;
      data.ticketId = location?.state?.totalData?.ticketId;
      data.cancelReasonId = cancelbookingreason?._id;
      data.queue =
        location?.state?.totalData?.queueMetaData?.queuename?.queuename ??
        "Referral";

      return data;
    }
  };

  const handlecahngeCancelbooking = async () => {
    const { action, apiEndPoint, backendCall } = actionsMap[actionName];
    let fetchVal = await actionButtonClick(
      action,
      apiEndPoint,
      backendCall,
      location?.state?.totalData?.ticketId,
      cancelbookingreason?._id,
      location?.state?.totalData?.queueMetaData?.queuename?.queuename ??
      "Referral"
    );
    if (!fetchVal.error) {
      setIsSideMenuOpen(false);
      navigate(AppRoutes.dashboard);
    } else if (fetchVal.error) {
      setIsSideMenuOpen(true);
      props?.alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: fetchVal?.message,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
  };
  const facilityName = async () => {
    const response = await dispatch(actions.Get_Facility_Name());
    setFacilityNameData(response?.payload?.data);
  };
  const serviceRequested = async () => {
    const response = await dispatch(actions.Get_Service_Requested());
    setServiceRequestedData(response?.payload?.data);
  };
  const practitionerName = async () => {
    const response = await dispatch(actions.Get_Practitioner_Name());
    setPractitionerNameData(response?.payload?.data);
  };

  const loadReferralDetailsDefaultValues = async () => {
    let payload = {
      screenid: process.env.REACT_APP_REFERRAL_REGISTRATION_SCREEN_ID,
      tenantid: getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"tenantid"}),
    };
    let token = localStorage.getItem("JwtToken");

    let register = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_SCREEN_DEFAULT_VALUE,
      headers: {
        "Content-Type": "application/json",
        jwtToken: `${token}`,
      },
      data: JSON.stringify(payload),
    }).then((res) => {
      if (res?.data?.response?.screenId === process.env.REACT_APP_REFERRAL_REGISTRATION_SCREEN_ID) {
        const defaultValues = res?.data?.response?.defaultValues;
        const stateNames = ["Date_of_Referral_In", "service_requested", "registration_type"];
        const stateValues = {};
        defaultValues.map((s) => {
          if (stateNames.includes(s.stateName)) {
            stateValues[s.stateName] = s.value;
          }
        });
        setReferralRegistratiodatas({
          // Referring_from_Facility: {},
          Service_Requested: stateValues?.service_requested,
          Date_of_Referral_In: epochToDate(stateValues?.Date_of_Referral_In),
          // Referring_Doctor: "",
          // Hepatitis: {},
          registration_type: stateValues?.registration_type,
          // Previous_Dialysis_Centre: "",
          // Other_Health_Condition: [],
          // disease: "",
          // attachment: [],
        });
        setLoadDefaultData(true);
        setViewFiles();
      }
    }).catch((error) => {
      props?.alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please contact to admin",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    });
  };

  useEffect(() => {
    if (!location?.state?.isModify && !location?.state?.IsViewMode && !location?.state?.isEditedMode && !location?.state?.referralId && !location?.state?.referraldetails?._id) {
      loadReferralDetailsDefaultValues();
    }
  }, []);

  useEffect(() => {
    getAllMasters();
    facilityName();
    serviceRequested();
    practitionerName();
  }, []);

  const goBackFn = () => {
    navigate(AppRoutes.financeRegistration, {
      state: {
        loading: false,
        patientId: location.state.patientId ?? props?.patientId,
        data: location?.state?.data,
        totalData: location?.state?.totalData,
        IsViewMode: location?.state?.IsViewMode ?? "",
        from: location?.state?.from,
        visit: true,
        mobileNo: location?.state?.mobileNumber,
        mobileNumber: location?.state?.mobileNumber,
        appointmentId: location?.state?.appointmentId,
        referraldetails: location?.state?.referraldetails,
        addtionaldetails: location?.state?.addtionaldetails,
        nextofkin: location?.state?.nextofkin,
        patientdetails: location?.state?.patientdetails,
        isModify: location?.state?.isModify ?? false,
        personid: location?.state?.personId || location?.state?.personid,
        userId: location?.state?.personId || location?.state?.personid,
        appointmentInfo: {},
        stateAction: location?.state?.stateAction,
        stateEndPoint: location?.state?.stateEndPoint,
        stateBackEndCall: location?.state?.stateBackEndCall,
        stateTicketid: location?.state?.stateTicketid,
        backMode:
          location?.state?.referralId ??
          location?.state?.referraldetails?._id ??
          true,
        isEditedMode: location?.state?.isEditedMode,
      },
    });
  };
  let AVCMeeting = {
    RoomName: "",
    component: {
      key: null,
      props: { children: "Hai" },
      ref: null,
      _owner: null,
      _store: { validated: false },
    },
    counter: undefined,
    direction: "right",
    domain: "",
    dragging: false,
    isLarge: null,
    jwt: "",
    onClose: () => { },
    open: false,
    pos: { top: 250, left: 150 },
    rel: null,
    setMeetingDrawer: async (props) => { },
    user_name: "",
    variant: "temporary",
    widthAdjState: undefined,
  };
  const clearState = () => {
    setReferralRegistratiodatas({
      Referring_from_Facility: {},
      Service_Requested: {},
      Date_of_Referral_In: "",
      Referring_Doctor: {},
      Hepatitis: {},
      registration_type: {},
      Previous_Dialysis_Centre: "",
      Other_Health_Condition: [],
      disease: "",
    });
    setStateClear(true);
  };
  function epochToDate(epoch) {
    const date = new Date(epoch * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  const setReferralRegistrationData = () => {
    let otherhealthcondList = [];
    location?.state?.referraldetails?.otherhealthcond?.map((v, i) => {
      let obj = {
        label: v?.display,
        value: v?.display,
        _id: v?._id,
      };
      otherhealthcondList.push(obj);
    });
    let serviceRequestedList = (
      location?.state?.referraldetails?.serviceRequested || []
    ).reduce((acc, v) => {
      acc["data"] = {
        label: v?.longdesc,
        value: v?.longdesc,
        _id: v?._id,
      };
      return acc;
    }, {});

    setReferralRegistratiodatas({
      Referring_from_Facility: {
        value:
          location?.state?.referraldetails?.HealthCareServiceRequested?.name ??
          "",
        _id:
          location?.state?.referraldetails?.HealthCareServiceRequested?._id ??
          "",
      },
      Service_Requested: {
        value: serviceRequestedList?.data?.value ?? "",
        _id: serviceRequestedList?.data?._id ?? "",
      },
      Date_of_Referral_In: epochToDate(
        location?.state?.referraldetails?.RefSourceLetDate
      ),
      Referring_Doctor: location?.state?.referraldetails?.RequestorPractioner,
      Hepatitis: {
        _id: location?.state?.referraldetails?.hepatitis?.[0]?._id ?? "",
        value: location?.state?.referraldetails?.hepatitis?.[0]?.display ?? "",
      },
      registration_type: {
        _id: location?.state?.referraldetails?.registrationtype?._id ?? "",
        value:
          location?.state?.referraldetails?.registrationtype?.display ?? "",
      },
      Previous_Dialysis_Centre:
        location?.state?.referraldetails?.predialcentre ?? "",

      Other_Health_Condition: otherhealthcondList,
      disease: location?.state?.referraldetails?.healthdisease ?? "",
      attachment: location?.state?.referraldetails?.attachment ?? [],
    });
    setEditData(true);
    setViewFiles(location?.state?.referraldetails?.attachment);
  };

  const isModify = location?.state?.isModify;

  useEffect(() => {
    if (isModify) {
      setReferralRegistrationData();
    }
  }, [isModify]);
  useEffect(() => {
    if (location?.state?.IsViewMode) {
      setReferralRegistrationData();
    }
  }, [location?.state?.IsViewMode]);
  useEffect(() => {
    if (location?.state?.isEditedMode) {
      setReferralRegistrationData();
    }
  }, [location?.state?.isEditedMode]);
  useEffect(() => {
    if (
      location?.state?.referralId ||
      location?.state?.referraldetails?._id
    ) {
      setReferralRegistrationData();
    }
  }, [
    location?.state?.referralId || location?.state?.referraldetails?._id,
  ]);
  const viewModeNavigateToClinicalInfo = (patientid) => {
    navigate(AppRoutes.clinicalInfo, {
      state: {
        // patientdetails: patientdetails1,
        // nextofkin: nextOfKind1,
        // addtionaldetails: additionalDetails1,
        // allMasters: allMasters,
        IsViewMode: location?.state?.IsViewMode,
        isEditedMode: location?.state?.isEditedMode,
        data: location?.state?.data ?? "",
        referraldetails: location?.state?.data?.referraldetails ?? "",
        patientId:
          location?.state?.data?.patientId ?? location?.state?.data?._id ?? "",
        encounterID: location?.state?.data?.encounterinfo?._id ?? "",
        personid: location?.state?.data?.patientdetails?.personid ?? "",
        totalData: location?.state?.totalData,
        isModify: location?.state?.isModify,
      },
    });
  };
  const successfun2 = async () => {
    const userInfo = localStorage.getItem("UserInfo");
    const decodedUserinfo = jwtDecode(userInfo);
    // if (!patientData?.length > 0) {
    //   props?.alert?.setSnack({
    //     open: true,
    //     severity: AlertProps.severity.error,
    //     msg: "Please save the details",
    //     vertical: AlertProps.vertical.top,
    //     horizontal: AlertProps.horizontal.center,
    //     tone: true,
    //   });
    // } else {
    setBtnName(true);
    let payload = {
      ticketId: location?.state?.totalData?.ticketId ?? "",

      userAction: process.env.REACT_APP_REFERRAL_APPROVE,
      data: {
        ticketId: location?.state?.totalData?.ticketId ?? "",
        practitionerId: decodedUserinfo?.practitionerid,
        practitionerRoleId: decodedUserinfo?.roleid,
      },
    };
    let token = localStorage.getItem("JwtToken");
    let register = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        jwtToken: `${token}`,
      },
      url: process.env.REACT_APP_PERFORMUSERACTION_API,
      data: payload,
    })
      .then((res) => {
        if (res.status === 200 && !res.data.error) {
          props?.alert?.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: "Approved successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          setBtnName(false);
          navigate(AppRoutes.dashboard);
        } else {
          props?.alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: res.data.message,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          setBtnName(false);
        }
      })
      .catch((error) => {
        props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please contact to admin",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
        setBtnName(false);
      });
    // }
  };

  return (
    <Div id="referral_parent_parent_div" className="light-background-color">
      <TopNavbar
        goBack={goBackFn}
        parent_id={"Referral_Registration"}
        // alertHold={alertHold}
        // putOnHold={() =>
        //   putOnHold("undefined", props.location?.state?.appointmentId)
        // }
        // newUser={props.location?.state?.newUser}
        successfun={successfun}
        // navBarArr={[]}
        title={referralRegisterTitle}
        btn={
          location?.state?.IsViewMode
            ? "Continue"
            : btnName
              ? "Registering"
              : "Register & continue"
        }
        isAVC={AVCMeeting?.RoomName}
        parent_View_id={location?.state?.IsViewMode}
        isViewMode={location?.state?.IsViewMode}
        navigateToNextScreen={viewModeNavigateToClinicalInfo}
        setFiles={setFiles}
        approveStatus={successfun2}
        isSideMenuOpen={isSideMenuOpen}
        handleSideMenu={handleSideMenu}
        handleSideMenuclose={handleSideMenuclose}
        setActionName={setActionName}
        actionName={actionName}
        handlecahngeCancelbooking={handlecahngeCancelbooking}
        handleSelectCancelbooking={handleSelectCancelbooking}
        cancelbookingreason={cancelbookingreason}
        totalData={location?.state?.totalData}
        cancelData={cancelData()}
        setviewFiles={setViewFiles}
        initialFiles={viewFiles}
        isModify={location?.state?.isModify}
        isBackMode={
          (location?.state?.data?.referraldetails?.attachment ||
            location?.state?.referraldetails?.attachment) === ""
            ? true
            : false
        }
      />
      <div
        style={{
          height: "calc(100vh - 128px)",
          overflow: "auto",
          //   display: state.loading ? "flex" : "block",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Div id="referral_parent_sub_div" className="rv-list-view">
          <ReferralInRegistration
            setReferralRegistratiodatas={setReferralRegistratiodatas}
            referralRegistrationDatas={referralRegistrationdatas}
            allMasters={allMasters}
            parent_id={"Referral_Registration"}
            stateClear={stateClear}
            editData={editData}
            IsViewMode={location?.state?.IsViewMode}
            viewModeNavigateToClinicalInfo={viewModeNavigateToClinicalInfo}
            practitionerNameData={practitionerNameData}
            serviceRequestedData={serviceRequestedData}
            facilityNameData={facilityNameData}
            isError={isError}
            loadDefaultData={loadDefaultData}
          />
        </Div>
      </div>
    </Div>
  );
};

export default withAppBar(withAllContexts(ReferralRegistration));
