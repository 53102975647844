import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Grid,
  Typography,
  Autocomplete,
  TextField,
  Box,
  Chip,
  Paper,
  Button,
  Stack,
  Checkbox,
  CircularProgress,
  makeStyles,
  ThemeProvider,
} from "@mui/material";
//import styled from "@emotion/styled/macro";
import withAppBar from "../../hoc/withAppBar";
import theme from "../../utils/theme";

import MonthYearPicker from "../../components/customDatePicker/monthYearPicker";
import actions from "../../redux/actions";
import { useDispatch } from "react-redux";
import {
  AlertProps,
  __tenantid__,
  dbName,
  epochToDDMonthYY,
  epochToYYYMMDD,
  getGlobalValueFrom_LclStrg_And_QryStrg,
  urls,
} from "../../utils";
import axios from "axios";
import jwtDecode from "jwt-decode";
import withAllContexts from "../../hoc/withAllContexts";
import Loading from "../../components/global/loading";
import { AppRoutes } from "../../router/routes";
import { useNavigate } from "react-router-dom";
//import { NursingProcedureContext } from "../../context";
import newtheme from "../../themes/newtheme";

const BulkBooking = (props) => {
  const dispatch = useDispatch();
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [initialLoad, setInitialLoad] = React.useState(true);
  const [fetchButtonDisable, setFetchButtonDisable] = React.useState(false);
  const [generateButtonDisable, setGenerateButtonDisable] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [dayType, setDayType] = React.useState([]);
  const [shiftType, setShiftType] = React.useState([]);
  const [fetchData, setFetchData] = React.useState([]);
  const [dates, setDates] = React.useState({});
  const navigate = useNavigate();
  const DropDownData = async () => {
    const response = await dispatch(actions.ShiftTypeDropDown(""));
    const dayType =
      response?.payload?.data?.find((obj) => obj.hasOwnProperty("DAYTYPE"))
        ?.DAYTYPE?.Value || [];
    const shiftType =
      response?.payload?.data?.find((obj) => obj.hasOwnProperty("SHIFTTYPE"))
        ?.SHIFTTYPE?.Value || [];
    const dayTypeTransformed = dayType?.map((obj) => ({
      value: obj?._id,
      label: obj?.display,
    }));
    const shiftTypeTransformed = shiftType?.map((obj) => ({
      value: obj?._id,
      label: obj?.display,
    }));
    setDayType(dayTypeTransformed);
    setShiftType(shiftTypeTransformed);
  };
  const handleCheckChange = (patientIndex, orderIndex, isChecked, type) => {
    const orderId = orderIndex?.catlogid?._id;
    const orderQuantity = orderIndex?.order;
    const updatedFetchData = [...fetchData];
    const patientData = updatedFetchData[patientIndex - 1];

    if (!patientData) return;

    if (isChecked) {
        const updatedPatientData = { ...patientData, isChecked: true };
        updatedFetchData[patientIndex - 1] = updatedPatientData;
        
        const selModal = updatedFetchData.map((v, i) => (v.isChecked ? (i + 1).toString() : null)).filter(Boolean);
        
        setFetchData(updatedFetchData);
        setSelectionModel(selModal);
    }

    if (type === "packageOrder" && Array.isArray(patientData?.packageorder)) {
        const packageOrder = patientData.packageorder.find(order => order?.catlogid?._id === orderId && order?.order === orderQuantity);
        if (packageOrder) {
            packageOrder.isDelete = !isChecked;
            // patientData.isChecked = isChecked;
        }
    } else if (type === "nursingProcedure" || type === "labOrder") {
        const orderType = type === "nursingProcedure" ? "NURSINGPROCEDURE" : "LAB";
        patientData?.recurrentorders?.forEach(recurrentOrder => {
            if (recurrentOrder?.oltype === orderType && recurrentOrder?.catlogid?._id === orderId) {
                recurrentOrder.isDelete = !isChecked;
            }
        });
    }

    setFetchData(updatedFetchData);
};
  const handleDateChange = (event) => {
    const { name, value } = event?.target;
    const parsedDate = new Date(value);
    if (isNaN(parsedDate.getTime())) {
      console.error("Invalid date format:", value);
      return;
    }
    const updatedDates = {
      [`${name}StartDate`]: new Date(
        parsedDate.getFullYear(),
        parsedDate.getMonth(),
        1
      ),
      [`${name}EndDate`]: new Date(
        parsedDate.getFullYear(),
        parsedDate.getMonth() + 1,
        0
      ),
    };
    setDates((prevDates) => ({
      ...prevDates,
      ...updatedDates,
    }));
  };
  const handleAutocompleteChange = (index, field, newValue) => {
    setFetchData((prevData) =>
      prevData.map((item, i) => {
        if (i === index - 1) {
          if (field === "shift") {
            return {
              ...item,
              appointmentdetails: {
                ...item?.appointmentdetails,
                shift: {
                  _id: newValue?.value,
                  display: newValue?.label,
                },
              },
            };
          } else if (field === "session") {
            return {
              ...item,
              appointmentdetails: {
                ...item?.appointmentdetails,
                session: {
                  _id: newValue?.value,
                  display: newValue?.label,
                },
              },
            };
          }
        }
        return item;
      })
    );
  };
  const rowData = fetchData?.map((patient, index) => {
    return {
      id: `${index + 1}`,
      patientid: patient?.patientid,
      name: patient?.name,
      age: patient?.age,
      gender: patient?.gender,
      mrn: patient?.mrn,
      nric: patient?.nric,
      appointmentdetails: patient?.appointmentdetails,
      recurrentorders: patient?.recurrentorders,
      packageorder: patient?.packageorder,
      bulkstartdate: patient?.bulkstartdate,
      bulkenddate: patient?.bulkenddate,
      tenantid:patient?.tenantid,
      facilityid:patient?.facilityid,
      isChecked:false,
    };
  });
  const bulkColumns = [
    {
      field: "patientdetail",
      headerName: "PATIENT DETAIL",
      width: 250,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Grid container spacing={2} margin={0}>
         <Grid style={{marginBottom:"10px"}}>
            <Grid item xs={12} style={{ padding: 0 }}>
              <Typography variant="button" display="block">
                {params?.row?.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid >
            <Grid item xs={12} style={{ paddingLeft: 0 }}>
              <Stack direction="row" spacing={1}  sx={{ paddingBottom: 2 }}>
                <Chip
                  label={params?.row?.mrn}
                  color="primary"
                  variant="outlined"
                  size="small"//sx={{ width: 100 }}
                />
                <Chip
                  label={params?.row?.nric}
                  color="primary"
                  variant="outlined"
                  size="small"//sx={{ width: 130 }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} style={{ paddingLeft: 0 }}>
              <Stack direction="row" spacing={1}>
                <Chip
                  label={params?.row?.age}
                  color="primary"
                  variant="outlined"
                  size="small"//sx={{ width: 90 }}
                />
                <Chip
                  label={params?.row?.gender}
                  color="primary"
                  variant="outlined"
                  size="small"//sx={{ width: 80 }}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      field: "schedule",
      headerName: "DIALYSIS SHIFT",
      width: 220,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Box sx={{ justifyContent: "center", alignItems: "center"}}>
          <TextField
            id="outlined-basic"
            label="Appointment Date"
            size="small"
            type="date"
            InputLabelProps={{ shrink: true }}
            sx={{ width: 200, paddingBottom: 2 }}
            disabled
            value={epochToYYYMMDD(params?.row?.appointmentdetails?.start)}
          />
          {/* <Typography style={{padding:10}}>{epochToDDMonthYY(params?.row?.appointmentdetails?.start)}</Typography> */}

          <Autocomplete
            id={`shift-${params?.row?.id}`}
            options={shiftType}
            size="small"
            sx={{ width: 200, height: 45, marginBottom: 2 }}
            value={fetchData[parseInt(params?.row?.id)]?.shift||params?.row?.appointmentdetails?.shift?.display}
            onChange={(event, newValue) =>
              handleAutocompleteChange(parseInt(params?.row?.id), "shift", newValue)
            }
            renderInput={(params) => <TextField {...params} label="Shift" />}
            disabled={true}
          />
          <Autocomplete
            id={`dayType-${params?.row?.id}`}
            options={dayType}
            size="small"
            sx={{ width: 200, height: 45 }}
            value={fetchData[parseInt(params?.row?.id)]?.session?.display||params?.row?.appointmentdetails?.session?.display}
            onChange={(event, newValue) =>
              handleAutocompleteChange(parseInt(params?.row?.id), "session", newValue)
            }
            renderInput={(params) => <TextField {...params} label="Day Type" />}
            disabled={true}
          />
        </Box>
      ),
    },
    {
      field: "laborder",
      headerName: "LAB ORDER",
      flex:1,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Box sx={{ width: "100%", height:"100%", margin: 0, overflow: "auto" }}>
          {params?.row?.recurrentorders
            .filter((order) => order?.oltype === "LAB")
            .map((order) => (
              <Box key={order?.catlogid?._id} sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
              checked={!order?.isDelete}
              onChange={(e) => handleCheckChange(
                    parseInt(params?.row?.id),
                    order,
                    e.target.checked,
                    "labOrder"
                  )}
                />
                <Typography>{order?.catlogid?.value} - Qty: {order?.order?.orderQty}</Typography>
              </Box>
            ))}
        </Box>
      ),
    },
    {
      field: "nursingprocedure",
      headerName: "NURSING PROCEDURE",
      flex:1,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Box sx={{ width: "100%", height:"100%", margin: 0, overflow: "auto" }}>
          {params?.row?.recurrentorders
            .filter((order) => order?.oltype === "NURSINGPROCEDURE")
            .map((order) => (
              <Box key={order?.catlogid?._id} sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
              checked={!order?.isDelete}
              onChange={(e) => handleCheckChange(
                    parseInt(params?.row?.id),
                    order,
                    e.target.checked,
                    "nursingProcedure"
                  )}
                />
                <Typography>{order?.catlogid?.value} - Qty: {order?.order?.orderQty}</Typography>
              </Box>
            ))}
        </Box>
      ),
    },
    {
      field: "packageorder",
      headerName: "PACKAGE ORDER",
      flex:1,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Box sx={{ width: "100%", height:"100%", margin: 0, overflow: "auto" }}>
          {params?.row?.packageorder?.map((order) => (
            <Box key={order?.catlogid?._id} sx={{ display: "flex", alignItems: "center" }}>
              <Checkbox
              checked={!order?.isDelete}
              onChange={(e) => handleCheckChange(
                  parseInt(params?.row?.id),
                  order,
                  e.target.checked,
                  "packageOrder"
                )}
              />
              <Typography>{order?.catlogid?.value} - Qty: {order?.order}</Typography>
            </Box>
          ))}
        </Box>
      ),
    },
  ];
  
  const userInfo = localStorage.getItem("UserInfo");
  const decodedUserinfo = jwtDecode(userInfo);
  const orgid = decodedUserinfo.facilityid;
  const isStringToEpoch = (isoString) => {
    return Math.floor(new Date(isoString).getTime() / 1000);
  };
  const getEpochTime = (date) => Math.floor(date.getTime() / 1000);
  const epoachStartDate = (date) => {
    const startDate = new Date(date);
    startDate.setHours(0, 0, 0, 0);
    const startEpochTime = getEpochTime(startDate);
    return startEpochTime;
  }
  const epoachEndDate = (date) => {
    const endDate = new Date(date);
    endDate.setHours(23, 59, 59, 999);
    const endEpochTime = getEpochTime(endDate);
    return endEpochTime;
  }
  const fetchedData = async () => {
    let payload = {
      db_name: dbName,
      filter: {
        startdate: epoachStartDate(dates?.fetchDateStartDate), 
        enddate: epoachEndDate(dates?.fetchDateEndDate), 
        facilityid: orgid,
        tenantid: getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"tenantid"}),
      },
      queryid: "41f79355-9d8a-4409-82ec-54d8cdf1f627",
    };
    let token = localStorage.getItem("JwtToken");
    setFetchButtonDisable(true)
    let register = await axios({
      method: "POST",
      url: process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES,
      headers: {
        "Content-Type": "application/json",
        jwtToken: `${token}`,
      },
      data: JSON.stringify(payload),
    })
      .then((res) => {
        let listArrResp =[]
        let selModal =[]
        res?.data?.map((v,i)=>{
          let obj = v;
          obj.isChecked = true;
          listArrResp.push(obj);
          let listCount = i+1
          selModal.push(listCount.toString())
        })
        setFetchData(listArrResp);
        setFetchButtonDisable(false)
        setSelectionModel(selModal);
      })
      .catch((error) => {
        props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please contact to admin",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        setFetchButtonDisable(false)
      });
  };
  const saveGenerateDatas = async () => {
    const saveData = {
      bulkstartdate: epoachStartDate(dates?.generateDateStartDate), 
      bulkenddate: epoachEndDate(dates?.generateDateEndDate),
      patientAppOrderInfo: [],
    };
    await new Promise(resolve => setTimeout(resolve, 0));
  
    for (const data of fetchData) {
      if (data?.isChecked) {
        const filteredRecurrentOrders = data?.recurrentorders?.filter(order => !order?.isDelete).map(order =>({
          ...order,
          // _id: order?._id ?? "",
          display: order?.display ?? "",
          catlogid: {
            _id: order?.catlogid?._id ?? "",
            value: order?.catlogid?.value ?? ""
          }
        })) ?? [];
        const filteredPackageOrders = data?.packageorder?.filter(order => !order?.isDelete).map(order => ({
          ...order,
          // _id: order?._id ?? "",
          display: order?.display ?? "",
          catlogid: {
            _id: order?.catlogid?._id ?? "",
            value: order?.catlogid?.value ?? ""
          }
        })) ?? [];
  
        const appointmentdetails = {
          ...data?.appointmentdetails,
          _id: data?.appointmentdetails?._id ?? "",
          modeofappointment: {
            ...data?.appointmentdetails?.modeofappointment,
            _id: data?.appointmentdetails?.modeofappointment?._id ?? "",
            display: data?.appointmentdetails?.modeofappointment?.display ?? ""
          },
          shift: {
            ...data?.appointmentdetails?.shift,
            _id: data?.appointmentdetails?.shift?._id ?? "",
            display: data?.appointmentdetails?.shift?.display ?? ""
          },
          session: {
            ...data?.appointmentdetails?.session,
            _id: data?.appointmentdetails?.session?._id ?? "",
            display: data?.appointmentdetails?.session?.display ?? ""
          }
        };
  
        saveData.patientAppOrderInfo.push({
          age: data?.age ?? "",
          facilityid: data?.facilityid ?? "",
          gender: data?.gender ?? "",
          mrn: data?.mrn ?? "",
          name: data?.name ?? "",
          nric: data?.nric ?? "",
          tenantid: data?.tenantid ?? "",
          recurrentorders: filteredRecurrentOrders,
          packageorder: filteredPackageOrders,
          patientid: data?.patientid ?? "",
          appointmentdetails: appointmentdetails
        });
      }
    }
  
    return saveData;
  };
  // const generateData = async () => {
  //   setGenerateButtonDisable(true);
  //   setLoading(true);
  //   setProgress(0);
  
  //   try {
  //     let saveGenerate = await saveGenerateDatas();
  //     setProgress(30);
  //     let token = localStorage.getItem("JwtToken");
  //     let register = await axios({
  //       method: "POST",
  //       url: urls.saveBulkBooking,
  //       headers: {
  //         "Content-Type": "application/json",
  //         jwtToken: `${token}`,
  //       },
  //       data: JSON.stringify(saveGenerate),
  //     });
  //     setProgress(70);
  //     if (register.status === 200 && !register.data.error) {
  //       props?.alert?.setSnack({
  //         open: true,
  //         severity: AlertProps.severity.success,
  //         msg: register.data.message,
  //         vertical: AlertProps.vertical.top,
  //         horizontal: AlertProps.horizontal.center,
  //         tone: true,
  //       });
  //     } else {
  //       props?.alert?.setSnack({
  //         open: true,
  //         severity: AlertProps.severity.error,
  //         msg: register.data.message,
  //         vertical: AlertProps.vertical.top,
  //         horizontal: AlertProps.horizontal.center,
  //         tone: true,
  //       });
  //     }
  //     setProgress(100);
  //   } catch (error) {
  //     setProgress(100);
  //     props?.alert?.setSnack({
  //       open: true,
  //       severity: AlertProps.severity.error,
  //       msg: "Please contact to admin",
  //       vertical: AlertProps.vertical.top,
  //       horizontal: AlertProps.horizontal.center,
  //       tone: true,
  //     });
  //   } finally {
  //     setGenerateButtonDisable(false);
  //     setLoading(false);
  //   }
  // };
  const generateData = async () => {
    setGenerateButtonDisable(true);
    setLoading(true);
    setProgress(0);
  
    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 90 ? 90 : prevProgress + 1));
    }, 100); // Update progress every 100ms
  
    try {
      let saveGenerate = await saveGenerateDatas();
      let token = localStorage.getItem("JwtToken");
      let register = await axios({
        method: "POST",
        url: urls.saveBulkBooking,
        headers: {
          "Content-Type": "application/json",
          jwtToken: `${token}`,
        },
        data: JSON.stringify(saveGenerate),
      });
  
      if (register.status === 200 && !register.data.error) {
        props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: "Generate Successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
        navigate(AppRoutes.dashboard);
      } else {
        props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: register.data.message,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      }
  
      setProgress(100);
    } catch (error) {
      props?.alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please contact to admin",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    } finally {
      clearInterval(interval); // Clear interval
      setGenerateButtonDisable(false);
      setLoading(false);
    }
  };
  
  const handleSelectionModelChange = (newSelection) => {
    let isNew = null
    if(!newSelection?.length > 0){
      isNew = true
      let listArrResp =[]
      fetchData?.map((v,i)=>{
        let obj = v;
        obj.isChecked = false;
        listArrResp.push(obj);
      })
      setSelectionModel(newSelection);
      setFetchData(listArrResp);
    } else if (isNew === true){
      isNew = false
      let listArrResp =[]
      fetchData?.map((v,i)=>{
        let obj = v;
        obj.isChecked = true;
        listArrResp.push(obj);
      })
      setSelectionModel(newSelection);
      setFetchData(listArrResp);
    }
    if(newSelection?.length < selectionModel?.length){
    //uncheck
    const deselectedRowIds = selectionModel?.filter(id => !newSelection.includes(id));
    const deselectedRowData = deselectedRowIds?.map(rowId =>
      rowData?.find(row => row.id === rowId)
    );
    const updatedRows = fetchData?.map(row => {
      const isDeselected = deselectedRowData?.some(deselectedRow => deselectedRow?.patientid === row?.patientid);
      // if (isDeselected) {
      //   return { ...row, isChecked: false };
      // }
      // return row;
    if (isDeselected) {
      let updatedRow = { ...row, isChecked: false };
      if (updatedRow.packageorder) {
        updatedRow.packageorder = updatedRow.packageorder.map(order => ({ ...order, isDelete: true }));
      }
      if (updatedRow.recurrentorders) {
        updatedRow.recurrentorders = updatedRow.recurrentorders.map(order => ({ ...order, isDelete: true }));
      }
      return updatedRow;
    }
    return row;
  });
    setSelectionModel(newSelection);
    setFetchData(updatedRows);
    } else if (newSelection?.length > selectionModel?.length){
    //check
    let arr =[]
    fetchData?.filter((id,i) => {
      let val = i+1
        arr.push(val.toString())
    })
    const deselectedRowIds = arr?.filter(id => newSelection.includes(id));
    const deselectedRowData = deselectedRowIds?.map(rowId =>
      rowData?.find(row => row.id === rowId)
    );
    const updatedRows = fetchData?.map(row => {
      const isDeselected = deselectedRowData?.some(deselectedRow => deselectedRow?.patientid === row?.patientid);
      if (isDeselected) {
        let updatedRow = { ...row, isChecked: true };
        if (updatedRow.packageorder) {
          updatedRow.packageorder = updatedRow.packageorder.map(order => ({ ...order, isDelete: false }));
        }
        if (updatedRow.recurrentorders) {
          updatedRow.recurrentorders = updatedRow.recurrentorders.map(order => ({ ...order, isDelete: false }));
        }
        return updatedRow;
      }
      return row;
    });
    setSelectionModel(newSelection);
    setFetchData(updatedRows);
    }
  };
  

  React.useEffect(() => {
    DropDownData();
  }, []);
  
  React.useEffect(() => {
    if (initialLoad && rowData?.length > 0) {
      setSelectionModel(rowData?.map(row => row?.id));
      setInitialLoad(false);
    }
  }, [rowData, initialLoad]);
  const rowHeight = 180;   
  const totalHeight =fetchData?.length + rowHeight;
  console.log(fetchData,"jkh");
  return (
    <ThemeProvider theme={newtheme}>
    <Box
      sx={{
        height:"90vh",
          width: "100%",
          display: "flex",
        flexDirection: "column",
        "& .super-app-theme--header": {
          backgroundColor: "#20b2a5",
        },
      }}
    >
      <Grid Container spacing={2} >
        <Grid item xs={12}>
          <Paper sx={{ padding: "10px", marginTop: "10px" }}>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 1, md: 6 }}
            >
              <Grid item xs={12}>
                <Typography variant="h6">BULK BOOKING</Typography>
              </Grid>
              <Grid item xs={1.7}>
                <MonthYearPicker
                  name="fetchDate"
                  selectedDate={dates?.fetchDate}
                  handleDateChange={handleDateChange}
                  maxDate={ new Date()}
                />
              </Grid>
              <Grid item xs={7}>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  onClick={fetchedData}
                  disabled={fetchButtonDisable}
                  sx={{ backgroundColor: "#179e8e" }}
                >
                  FETCH
                </Button>
              </Grid>
              <Grid item xs={1.7}>
                <MonthYearPicker
                  name="generateDate"
                  selectedDate={dates?.generateDate}
                  handleDateChange={handleDateChange}
                  minDate={new Date(new Date().setDate(1)).setMonth(new Date().getMonth() + 1, 1)}
                />
              </Grid>
              <Grid item xs={1.5}>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  color="secondary"
                  onClick={generateData}
                  disabled={generateButtonDisable}
                  sx={{ backgroundColor: "#EC6A49" }}
                >
                  GENERATE
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {/* <div style={{ height: 550 }}> */}
      <DataGrid
        rows={rowData}
        columns={bulkColumns}
        showCellVerticalBorder
        showColumnVerticalBorder
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 3,
            },
          },
        }}
        pageSizeOptions={[5]}
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={(newSelectionModel) =>
          handleSelectionModelChange(newSelectionModel)
        }
        sx={{
          //width: "100%",
          margin: 1,
          "& .MuiDataGrid-cell": {
            alignContent: "center",
            alignItems: "center",
          },
        }}
        rowHeight={rowHeight}
        // autoHeight
        disableRowSelectionOnClick
        checkboxSelection={true}
      />
      {/* </div> */}
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            zIndex: 1,
          }}
        >
          <Box position="relative" display="inline-flex">
            <CircularProgress
              size={80}
              variant="determinate"
              value={progress}
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="caption"
                component="div"
                color="textSecondary"
              >{`${Math.round(progress)}%`}</Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  </ThemeProvider>
);
};

export default withAppBar(withAllContexts(BulkBooking));
