import React, { useEffect, useState } from "react";
import actions from "../../redux/actions";
//import { withRouter } from 'react-router-dom'
import { TextField, Grid, TextareaAutosize, Typography, InputAdornment, Popover } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  Container,
  Div,
  //   Icons,
  Text,
  Divider,
  Button,
  Select,
  TextInput
} from "qdm-component-library";
import {
  AlertProps,
  GetQueueData,
  __tenantid__,
  actionButtonClick,
  getUtcTime,
  getCurrentMinTime,
  getGlobalValueFrom_LclStrg_And_QryStrg,
} from "../../utils";
import { UIColor } from "../../themes/theme";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import withAllContexts from "../../hoc/withAllContexts";
import DatePicker from "react-datepicker";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { setHours, setMinutes } from "date-fns";
import { QueueManagementContext } from "../../context";
import CustomDatePicker from "../customDatePicker";
import { CalendarIcon } from "../../components";
import axios from "axios";
import CustomInputDatePicker from "../customDatePicker/inputDatePicker";
import calendarIcon from "../../assets/img/svg/icons8-calendar.svg";
import styles from "../../screens/patientRegistration/components/styles";

const NursingProcedure = (props) => {
  const { labelStyle, borderStyle } = styles;
  const dispatch = useDispatch();
  const {
    allData,
    displayData,
    previousData,
    loader,
    previousQueue,
    setAllData,
    setDisplayData,
    setPreviousData,
    setpreviousQueue,
    setloader,
  } = React.useContext(QueueManagementContext);
  const selector = useSelector((state) => {
    return {
      administratedby: state?.DashboardSlice?.administratedby?.data || [],
      branchCode: state?.DashboardSlice?.branchCode?.data || [],
      sampleCollected: state?.DashboardSlice?.sampleCollected?.data || [],
      
    };
  });

  console.log("selector" , selector)
  const [state, setState] = useState({
    batchNo: "",
    Expiry: "",
    hospitalSupply: "",
    administererBy: "",
    notes: "",
    admindate:""
  });
  
  const [allMasters, setAllMasters] = useState([]);
  
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (index, event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeState = (value, namee) => {
    if (namee === "hospitalSupply") {
      setState((prevState) => ({
        ...prevState,
        hospitalSupply: value,
      }));
    }else if (namee === "administererBy") {
      setState((prevState) => ({
        ...prevState,
        administererBy: value,
      }));
    } else {
      setState((prevState) => ({ ...prevState, [namee]: value }));
    }
      
  };

  const handleSubmit = async () => {
    const userInfo = localStorage.getItem("UserInfo");
    const decodedUserinfo = jwtDecode(userInfo);
    const orgid = decodedUserinfo.facilityid;

    let error = false;
    Object.keys(state).forEach((v) => {
      if (v !== "notes" && !Boolean(state[v])) {
        error = true;
      }
    });
    let admindateepoch = moment(state.admindate).unix();
    const date = new Date(admindateepoch * 1000);
    const startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime() / 1000;
    const endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(),23,59,59).getTime() / 1000;
    let pre_enc_check = await dispatch(
      actions.PREV_ENC_CHECK({
        "patientId":props?.selectedAdminstrator?.inputdoc?.patientid,
        "start":startDate,
        "end":endDate,
      })
    );
    if (!error) {
      if(pre_enc_check?.payload?.data?.length > 0){
        setloader(true);
          props.nursingProcedureClose();

          let ticket = props?.selectedAdminstrator?.ticketId;
          let fromstate = props?.selectedAdminstrator?.statesid;
          let patientid = props?.selectedAdminstrator?.inputdoc?.patientid;
          let encounterid = pre_enc_check?.payload?.data[0] || props?.selectedAdminstrator?.inputdoc?.encounterid;
          let orderline = props?.selectedAdminstrator?.inputdoc?.caorderlineid;
          let queue = props?.selectedAdminstrator?.queueid;
          let payload = {
            _id: "",
            tenantid: decodedUserinfo?.tenantid,
            facilityid: orgid,
            patientid: patientid,
            encounterid: encounterid,
            orderline: orderline,
            ticketid: ticket,
            fromstate: fromstate,
            medbatchno: state?.batchNo,
            expirydate: moment(state.Expiry).unix(),
            supplytype: state?.hospitalSupply?._id,
            adminby: state?.administererBy?.label,
            admindate: moment(state.admindate).unix(),
            notes: state?.notes,
          };
          let save_order_response = await dispatch(
            actions.SAVE_ORDER_STATEINFO(payload)
          );
          if (save_order_response?.payload?.data?.error) {
            props.alert.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: save_order_response?.payload?.data?.message,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
              tone: true,
            });
          } else {
            let pua = await dispatch(
              actions.PERFORM_USER_ACTION({
                ticketId: ticket,
                userAction: process.env.REACT_APP_NURSING_ADMINISTER,
              })
            );
            if (pua?.payload?.data?.error) {
              props.alert.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: pua?.payload?.data?.message,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
                tone: true,
              });
            } else {
              let data = await GetQueueData();
              setAllData(data.everyData);
              setDisplayData(data.getDisplayData);
              setPreviousData(data.getPreviousData);
              setpreviousQueue(queue);

              props.alert.setSnack({
                open: true,
                severity: AlertProps.severity.success,
                msg: "Medicine administered successfully !",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
                tone: true,
              });
              props?.RefreshLatestQueue();
            }
          }
      } else {
        props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "No encounter found",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      }
      //  if(save_order_response?.pa)
    } else {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all fields !",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
    setloader(false);
  };
  const loadDefaultValues = async () => {
    const userInfo = localStorage.getItem("UserInfo");
    const decodedUserinfo = jwtDecode(userInfo);
    const practitionerid = decodedUserinfo?.practitionerid;
    let selectedPrectioner;
    if(practitionerid){
      for (const item of selector?.administratedby ){
        if (item?.label === practitionerid){
           selectedPrectioner = item;
      }
    }
  }
  let payload = {
    screenid: process.env.REACT_APP_NURSING_QUEUE_ADMINISTER_SCREEN_ID,
    tenantid: getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"tenantid"}),
  }
  let token = localStorage.getItem("JwtToken");
    let register = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_SCREEN_DEFAULT_VALUE,
      headers: {
        "Content-Type": "application/json",
        jwtToken: `${token}`,
      },
      data: JSON.stringify(payload),
    }).then((res) => {
      if (res?.data?.response?.screenId === process.env.REACT_APP_NURSING_QUEUE_ADMINISTER_SCREEN_ID) {
        const defaultValues = res?.data?.response?.defaultValues;
        const stateNames = [
          "supplytype",
        ]
        const stateValues = {};
        defaultValues.forEach((s) => {
          if (stateNames.includes(s.stateName)) {
            stateValues[s.stateName] = s.value;
          }
        }); 
        setState({
          Expiry: "",
          administererBy:selectedPrectioner||"",
          batchNo: "",
          hospitalSupply: stateValues?.supplytype || "",
          notes: "",
          admindate:moment(new Date()).format("YYYY-MM-DD, hh:mm A")
        });
      }})
    
  };
  useEffect(() => {
    getAllMasters()
    loadDefaultValues();
  }, []);

  const getAllMasters = async () => {
    const response = await dispatch(actions.GET_ALL_MASTERS(""));
    setAllMasters(response?.payload?.data);
  };
  const FlterOptions3 = (type) => {
    let filteredArray = allMasters.filter((obj) => obj.hasOwnProperty([type]));
    let list = [];
    let data = filteredArray[0]?.[type].Value;
    for (let index in data) {
      var datatemp = {
        _id: data[index]?._id,
        value: data[index]?.display,
      };
      list.push(datatemp);
    }
    return list;
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { cancelbookingreason, parent_id, classes } = props;
  return (
    <div id={`${parent_id}_cancelbook_parent_div`}>
      <Container
        id={`${parent_id}_cancelbook_parent_container`}
        key={"0"}
        name="container"
        fluid={false}
        style={
          {
            // padding: "20px",
          }
        }
      >
        <Typography style={{ fontSize: 20, color: "#101010" }}>
          Medicine Administrative Information
        </Typography>

        <Divider
          id={`${parent_id}_cancelbook_bottom_divider`}
          key={"1"}
          textWidth={"50"}
          textColor={"#000"}
          borderColor={"#f0f0f0"}
          borderWidth={"1"}
          orientation={"horizontal"}
          variant={"middle"}
          style={{ marginLeft: 0, marginRight: 0 }}
          //   style={qdmstyles.BKv}
        ></Divider>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <p
              style={{
                fontWeight: "450",
                margin: "0",
                color: "#6A7888",
                fontSize: "13px",
                marginBottom: "6px",
              }}
            >
             Administer Date
              <span
                style={{
                  color: "red",
                  fontWeight: "500",
                  fontSize: "14px",
                  marginLeft: "4px",
                }}
              >
                *
              </span>
            </p>
            <TextField
              id={`admin_calender_textfield`}
              variant="outlined"
              size="small"
              style={{
                width: "100%",
                borderRadius: 8,
                borderColor: "#E0E0E0",
                color: "#6F6F6F",
                fontSize: "12px",
                marginBottom: "6px",
                fontFamily: `pc_regular !important`,
              }}
              autoComplete={"off"}
              value={
                state?.admindate
                  ? moment(state?.admindate).format(
                      "YYYY-MM-DD, hh:mm A"
                    )
                  : ""
              }
              onChange={(e, newValue) =>
                onChangeState(newValue, "admindate")
              }
              onClick={(event) => handleClick(1, event)}
              InputProps={{
                // classes: { input: classes.input },
                endAdornment: (
                  <InputAdornment
                    id={`admin_calender_inputadornment`}
                    position="end"
                  >
                    <Div
                      id={`admin_calendericon_div`}
                      aria-describedby={id}
                      style={{ cursor: "pointer" }}
                      onClick={(event) => handleClick(1, event)}
                    >
                      <CalendarIcon
                        id={`admin_calendericon`}
                        color={UIColor.secondaryText.color}
                        width={"16"}
                        height={"16"}
                      />
                    </Div>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        <Grid item xs={12}>
            <p
              style={{
                fontWeight: "450",
                margin: "0",
                color: "#6A7888",
                fontSize: "13px",
                marginBottom: "6px",
              }}
            >
             Medicine Batch No
              <span
                style={{
                  color: "red",
                  fontWeight: "500",
                  fontSize: "14px",
                  marginLeft: "4px",
                }}
              >
                *
              </span>
            </p>
            <TextInput
              id={`${parent_id}referral_parent_Date_of_Referral_In_label_textinput`}
              onChange={(e) => onChangeState(e?.target.value, "batchNo")}
              value={state?.batchNo}
              placeholder="Medicine Batch No"
              labelStyle={labelStyle}
              style={borderStyle}
              type="text"
              disabled={props?.IsViewMode}
              isRequired={true}
            />
          </Grid>
          <Grid item xs={12}>
            <p
              style={{
                fontWeight: "450",
                margin: "0",
                color: "#6A7888",
                fontSize: "13px",
                marginBottom: "6px",
              }}
            >
             Expiry
              <span
                style={{
                  color: "red",
                  fontWeight: "500",
                  fontSize: "14px",
                  marginLeft: "4px",
                }}
              >
                *
              </span>
            </p>
            {/* <DatePicker
              onChange={(date) => onChangeState(date, "Expiry")}
              selected={state?.Expiry}
              // showTimeSelect
              dateFormat="MM/dd/yyyy"
              placeholderText="DD-MM-YYYY"
              className={"pc_regular"}
              
              showIcon
              icon="fa fa-calendar"
              withPortal
            /> */}
            <TextField
              variant="outlined"
              size="small"
              type="date"
              onChange={(e) => onChangeState(e.target.value, "Expiry")}
              value={state?.Expiry}
              style={{
                width: "100%",
                borderRadius: 8,
                borderColor: "#E0E0E0",
                color: "#6F6F6F",
                fontSize: "12px",
                marginBottom: "6px",
                fontFamily: `pc_regular !important`,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              id={`${parent_id}_patient_name_use_label_select`}
              value={state?.hospitalSupply?.value}
              options={FlterOptions3("SUPPLYOROWN")}
              // getOptionLabel={(options) => options?.name || {}}
              showArrow
              label="Hospital Supply / Patient's own"
              labelStyle={labelStyle}
              style={borderStyle}
              hoverColor={UIColor.primaryColor.color}
              onChangeValue={(e) => onChangeState(e, "hospitalSupply")}
              isRequired={true}
              inLineStyles={borderStyle}
              placeholder="select..."
            />
          </Grid>
          
          <Grid item xs={12}>
            <Select
              id={`${parent_id}_patient_name_use_label_select`}
              value={state?.administererBy?.value}
              options={selector?.administratedby || [{}]}
              //getOptionLabel={(options) => options?.name || {}}
              showArrow
              label="Administered by"
              labelStyle={labelStyle}
              style={borderStyle}
              hoverColor={UIColor.primaryColor.color}
              onChangeValue={(e) => onChangeState(e, "administererBy")}
              isRequired={true}
              inLineStyles={borderStyle}
              placeholder="select..."
            />
          </Grid>
          <Grid item xs={12}>
            <p
              style={{
                fontWeight: "450",
                margin: "0",
                color: "#6A7888",
                fontSize: "13px",
                marginBottom: "6px",
              }}
            >
              notes
            </p>
            <TextareaAutosize
              onChange={(e) => onChangeState(e.target.value, "notes")}
              maxRows={8}
              style={{
                width: "100%",
                height: "50px",
                outline: "1px solid rgb(222, 229, 236) ",
                border: "0px",
                borderRadius: "8px",
                padding: "5px",
              }}
              value={state?.notes ?? ""}
            />
          </Grid>
        </Grid>

        <Button
          id={`${parent_id}_cancelbook_buttom`}
          key={"1"}
          type="button"
          disabled={props?.signIn?.loading || props?.cancelAppointment?.loading}
          className="pc_medium"
          variant="outlined"
          style={{
            // display: "flex",
            // justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: "20px",
            fontSize: "14px",
            padding: "8px 16px",
            marginBottom: "10px",
            backgroundColor: UIColor?.primaryColor?.color,
            border: "none",
            borderRadius: 8,
            marginRight: "10px",
          }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
        <Button
          id={`${parent_id}_cancelbook_buttom`}
          key={"1"}
          type="button"
          disabled={props?.signIn?.loading || props?.cancelAppointment?.loading}
          className="pc_medium"
          variant="outlined"
          style={{
            // display: "flex",
            // justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: "20px",
            fontSize: "14px",
            padding: "8px 16px",
            marginBottom: "10px",
            //backgroundColor: UIColor?.primaryColor?.color,
            border: "none",
            borderRadius: 8,
          }}
          onClick={props?.nursingProcedureClose}
        >
          Cancel
        </Button>
          
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={ handleClose }
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          classes={{ paper: classes.muiPopovers }}
        >
            
          <CustomDatePicker
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            selectedDate={
              state?.admindate
                ? new Date(state?.admindate)
                : null
            }
            // minDate={new Date()}
            minTime={"00:00"
              // getCurrentMinTime(
              // state?.admindate
              //   ? new Date(state?.admindate)
              //   : null
            // )
          }
            maxTime={setHours(setMinutes(new Date(), 59), 23)}
            handleChange={(date, e) => {
              if (date) {
                const tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate());
                if (date < tomorrow) {
                  onChangeState(date, "admindate");
                } else {
                  props.alert.setSnack({
                  open: true,
                  severity: AlertProps.severity.warning,
                  msg: "You're not able to select future dates",
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.center,
                  tone: true,
            });
                }
              }
              if (e?.target === undefined) {
                handleClose();
              }
            }}
          />
        </Popover>
      </Container>
    </div>
  );
};

export default withStyles(styles)(withAllContexts(NursingProcedure));
