import React, { useContext, useEffect, useState } from "react";
import TableComponent from "../../cashierBillingDetails/components/tableComponent";
import actions from "../../../redux/actions";
import { AlertProps, AxiosCall, dbName, urls } from "../../../utils";
import { useDispatch } from "react-redux";
import { AlertContext } from "../../../context";
import { Button, Col, Div, H6, Row, TextInput } from "qdm-component-library";
import { UIColor } from "../../../themes/theme";
import axios from "axios";
import jwtDecode from "jwt-decode";
import DataGridComponent from "./tableComponent";
import { IconButton, Select, Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { Box, Grid, Paper } from "@material-ui/core";
import { Selection } from "../../../components";
import CloseIcon from "@material-ui/icons/Close";
const dataType = [
  // { name: "checked", type: "CHECKBOX", header: "All" },
  // { name: "s.no", type: "INCREMENT", header: "S.No" },
  { name: "receiptno", type: "TEXT", header: "scroll", width: 200 },
  {
    name: "receiptdate",
    type: "EPOCH_DATE_DISPLAY",
    header: "scroll",
    width: 200,
  },
  { name: "refundamount", type: "TEXT", header: "scroll", width: 220 },
  // {
  //   name: "refundnumber",
  //   type: "REFUNDNUMBER",
  //   header: "scroll",
  //   width: 200,
  // },
  {
    name: "refundreceipt",
    type: "REFUNDRECEIPT",
    header: "scroll",
    width: 230,
  },
  // {
  //   name: "balancereceipt",
  //   type: "BALANCERECEIPT",
  //   header: "scroll",
  //   width: 130,
  // },
];
let styles = {
  labelStyle: {
    color: "#6F6F6F",
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    border: "1px solid #E0E0E0",
  },
  containedButton: {
    backgroundColor: UIColor.primaryColor.color,
    borderColor: UIColor.primaryColor.color,
    borderRadius: 8,
    padding: "8px 15px",
    minWidth: 100,
    fontFamily: "pc_medium !important",
    fontSize: 14,
  },
  errorborderStyle: {
    borderRadius: 8,
    borderColor: "#FF0000",
  },
};
const RefundReciept = (props) => {
  const userInfo = localStorage.getItem("UserInfo");
  const alertContext = useContext(AlertContext);
  const decodedUserinfo = jwtDecode(userInfo);
  const practitionerId = decodedUserinfo?.practitionerid;
  const Header = [
    // "All",
    // "S.No",
    "Refund Receipt No",
    "Refund Receipt Date",
    "Refund Amount ",
    // "Refund No.",
    "Refund Receipt",
  ];
  const [refundRecieptData, setRefundRecieptData] = useState([]);
  console.log(refundRecieptData,"dateee");
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [collectInfo, setCollectInfo] = useState();

  const refundDatas = async (_id) => {
    const patientid = _id;
    const facilityid = decodedUserinfo?.facilityid;
    const data = await dispatch(actions.ADVANCE_RECIEPT_READ({ patientid, facilityid}));
    if (data?.payload?.data?.length === 0) {
      alertContext?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "No Data Found",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      setTimeout(() => {
        props?.setRefundOpen(false);
      }, 1000);
    }

    let listArrResp = [];
    let selModal = [];
    data?.payload?.data?.forEach((v, i) => {
      let obj = { ...v, isChecked: true };
      listArrResp.push(obj);
      let listCount = i + 1;
      selModal.push(listCount.toString());
    });

    setRefundRecieptData(data?.payload?.data);
    setSelectionModel(selModal);
  };

  const generateSequentialIds = (length) => {
    return Array.from({ length }, (_, i) => (i + 1).toString());
  };

  const ids = generateSequentialIds(refundRecieptData.length);

  const rowData = refundRecieptData?.map((patient, i) => {
    return {
      id: ids[i],
      receiptno: patient?.receiptid,
      receiptdate: patient?.refunddate,
      refundnumber: patient?.refundno,
      refundamount: patient?.refundamt,
      isChecked: false,
      paymentsid: patient?.paymentsid
    };
  });

  const dispatch = useDispatch();

  const alert = useContext(AlertContext);
  const handleSelectedPrint = (e, value, index) => {
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };
    const payload = {
      reportid: process.env.REACT_APP_SAVE_PAYMENT_REPORT_ID,
      inputparams: {
        "@refid": value?.paymentsid
      },

      result: [],
    };
    AxiosCall("post", urls.jasperReport, payload, header)
      .then(async (res) => {
        const response = await axios.get(res?.downloadUrl, {
          method: "GET",
          responseType: "blob",
        });
        const pdfBlob = new Blob([response.data], {
          type: "application/pdf",
        });
        const pdfDataUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfDataUrl, "_blank");
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          msg: "No data found for the given inputs",
          severity: "error",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      });
  };
  React.useEffect(() => {
    if (props?.patientDetails?._id && refundRecieptData.length===0) {
        refundDatas(props?.patientDetails?._id);
    }
  }, [props?.patientDetails]);
  const handleSelectionModelChange = (newSelection) => {
    let isNew = null;
    if (!newSelection?.length > 0) {
      isNew = true;
      let listArrResp = [];
      refundRecieptData?.map((v, i) => {
        let obj = v;
        obj.isChecked = false;
        listArrResp.push(obj);
      });
      setSelectionModel(newSelection);
      setRefundRecieptData(listArrResp);
    } else if (isNew === true) {
      isNew = false;
      let listArrResp = [];
      refundRecieptData?.map((v, i) => {
        let obj = v;
        obj.isChecked = true;
        listArrResp.push(obj);
      });
      setSelectionModel(newSelection);
      setRefundRecieptData(listArrResp);
    }
    if (newSelection?.length < selectionModel?.length) {
      //uncheck
      const deselectedRowIds = selectionModel?.filter(
        (id) => !newSelection.includes(id)
      );
      const deselectedRowData = deselectedRowIds?.map((rowId) =>
        rowData?.find((row) => row.id === rowId)
      );
      const updatedRows = refundRecieptData?.map((row) => {
        const isDeselected = deselectedRowData?.some(
          (deselectedRow) => deselectedRow?.receiptno === row?.receiptno
        );
        if (isDeselected) {
          return { ...row, isChecked: false };
        }
        return row;
      });
      setSelectionModel(newSelection);
      setRefundRecieptData(updatedRows);
    } else if (newSelection?.length > selectionModel?.length) {
      //check
      let arr = [];
      refundRecieptData?.filter((id, i) => {
        let val = i + 1;
        arr.push(val.toString());
      });
      const deselectedRowIds = arr?.filter((id) => newSelection.includes(id));
      const deselectedRowData = deselectedRowIds?.map((rowId) =>
        rowData?.find((row) => row.id === rowId)
      );
      const updatedRows = refundRecieptData?.map((row) => {
        const isDeselected = deselectedRowData?.some(
          (deselectedRow) => deselectedRow?.receiptno === row?.receiptno
        );
        if (isDeselected) {
          return { ...row, isChecked: true };
        }
        return row;
      });
      setSelectionModel(newSelection);
      setRefundRecieptData(updatedRows);
    }
  };
  function calculateTotalCollection(data) {
    const totalPayment = data
      .filter((item) => item.isChecked)
      .reduce((total, item) => total + Number(item.advanceamount), 0);
    return totalPayment;
  }

  function calculateTotalBalance(data) {
    let totalBalanceAmount = 0;
    const totalPayment = data.reduce(
      (total, item) => total + Number(item.advanceamount),
      0
    );
    totalBalanceAmount = (props.totCollectAmt - totalPayment).toFixed(2);
    return totalBalanceAmount;
  }

  // const setData = (name, value, total) => {
  //   let updatedData = { ...collectInfo };
  //   if (name === "totalPayAmount") {
  //     const data = refundRecieptData.filter((x) => x.id !== collectInfo.id);
  //     const totColamt = calculateTotalCollection(data);
  //     if (
  //       collectInfo?.advance &&
  //       (Number.isNaN(value) ? 0 : Number(value)) > advanceAmount
  //     ) {
  //       props?.alert?.setSnack({
  //         open: true,
  //         severity: AlertProps.severity.error,
  //         msg: "Insufficient balance in Advance Amount",
  //         vertical: AlertProps.vertical.top,
  //         horizontal: AlertProps.horizontal.center,
  //         tone: true,
  //       });
  //       return;
  //     }
  //     if (
  //       value === "" ||
  //       (value > 0 && value <= props.totCollectAmt - totColamt)
  //     ) {
  //       updatedData[name] = value;
  //     }
  //   } else {
  //     updatedData[name] = value;
  //     // if (total) {
  //     //   updatedData["totalPayAmount"] = total;
  //     // }
  //     updatedData["date"] = currentDate;
  //   }
  //   setCollectInfo({ ...updatedData });
  //   // props?.setCashierdatas({ ...updatedData });
  // };
  let totalCollectionAmount = calculateTotalCollection(refundRecieptData);
  let totalBalanceAmount = calculateTotalBalance(refundRecieptData);
  const {parent_id} = props;
  return (
    // <Grid container spacing={2}>
    //   <Box width="100%" display="flex" justifyContent="center">
    //     <Paper
    //       id={`${parent_id}referral_parent_parent_paper`}
    //       className="pi-paper"
    //     >
    //       <Grid container item xs={12} sm={12} justify="center" spacing={2}>
    //         <div
    //           style={{
    //             // maxHeight: "80vh",
    //             minHeight:"100vh",
    //             overflow: "auto",
    //             width: "100%",
    //           }}
    //         >
    //           <Typography variant="h5" style={{ padding: 5 }}>
    //             Refund Receipt Print
    //           </Typography>
    //           <DataGridComponent
    //             tableData={rowData}
    //             Header={Header}
    //             dataType={dataType}
    //             handleSelectedPrint={handleSelectedPrint}
    //             handleSelectionModelChange={handleSelectionModelChange}
    //             selectionModel={selectionModel}
    //             refundReciept={"refundReciept"}
    //           />
    //         </div>
    //         <Grid container item xs={5} sm={5} justify="center" spacing={2}>
    //          {/* <Grid item xs={12} sm={5}>
    //             <Typography
    //               className={"pc_regular"}
    //               style={{
    //                 color: "#646464",
    //                 fontSize: 17,
    //                 margin: 0,
    //                 marginBottom: 5,
    //               }}
    //             >
    //               Payment Amount <span style={{ color: "red" }}>*</span>
    //               {decodedUserinfo?.currencyinfo?.currencysymbol ||
    //                 decodedUserinfo?.currencyinfo?.currencycode}
    //               ){" "}
    //             </Typography>
    //           </Grid>
    //           <Grid item xs={12} sm={6}>
    //             <TextInput
    //               variant={"outlined"}
    //               hoverColor={UIColor.primaryColor.color}
    //               placeholder="Add reason"
    //               style={{
    //                 borderRadius: 8,
    //                 borderColor: UIColor.lineBorderFill.color,
    //                 height: 36,
    //                 padding: "5px 10px",
    //               }}
    //               isRequired
    //               value={totalCollectionAmount}
    //             />
    //           </Grid>
    //            <Grid item xs={12} sm={5}>
    //             <Typography
    //               className={"pc_regular"}
    //               style={{
    //                 color: "#646464",
    //                 fontSize: 17,
    //                 margin: 0,
    //                 marginBottom: 5,
    //               }}
    //             >
    //               Cancel Reason <span style={{ color: "red" }}>*</span>
    //             </Typography>
    //           </Grid>
    //           <Grid item xs={12} sm={6}>
    //             <TextInput
    //               variant={"outlined"}
    //               hoverColor={UIColor.primaryColor.color}
    //               placeholder="Add reason"
    //               style={{
    //                 borderRadius: 8,
    //                 borderColor: UIColor.lineBorderFill.color,
    //                 height: 36,
    //                 padding: "5px 10px",
    //               }}
    //               isRequired
    //             />
    //           </Grid>

    //           <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
    //             <Button
    //               name={"Refund"}
    //               type="button"
    //               className="pc_medium"
    //               variant="outlined"
    //               style={{
    //                 display: "flex",
    //                 justifyContent: "center",
    //                 alignItems: "center",
    //                 margin: "auto",
    //                 marginTop: "20px",
    //                 fontSize: "14px",
    //                 padding: "8px 16px",
    //                 marginBottom: "10px",
    //                 backgroundColor: UIColor?.primaryColor?.color,
    //                 border: "none",
    //                 borderRadius: 8,
    //               }}
    //               // onClick={handlesubmit}
    //             >
    //               Refund
    //             </Button>
    //           </Grid> */}
    //         </Grid>
    //       </Grid>
    //     </Paper>
    //   </Box>
    // </Grid>
    <Paper>
      <Grid container item xs={12} sm={12}>
        <Grid item container xs={12} justifyContent="center" alignItems="center" style={{padding:"10px 0px 0px 20px"}}>
          <Grid item xs={11}>
            <Typography variant="h5" style={{ padding: 5 }}>
              Refund Receipt Print
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton>
              <CloseIcon onClick={props?.handleDirectRefundClose} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item container xs={12} justifyContent="center" alignItems="center" style={{overflow:"auto", padding:"0px 0px 0px 10px",}}>
          <DataGridComponent
            tableData={rowData}
            Header={Header}
            dataType={dataType}
            handleSelectedPrint={handleSelectedPrint}
            handleSelectionModelChange={handleSelectionModelChange}
            selectionModel={selectionModel}
            refundReciept={"refundReciept"}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default RefundReciept;
