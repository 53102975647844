import { authActions } from "../slice/auth-slice";
import { personActions } from "../slice/person-slice";
import { searchApiActions } from "../slice/search-slice";
import { oraganizationRegisterPatientActions } from "../slice/organization-slice";
import { docterListingActions } from "../slice/doctorlisting-slice";
import { searchHospitalActions } from "../slice/search-hospital-slice";
import { registrationActions } from "../slice/registration-slice";
import { patientActions } from "../slice/patient-slice";
import { appointmentActions } from "../slice/appointment-slice";
import { visitRegistrationActions } from "../slice/visit-registration-slice";
import { slotApiActions } from "../slice/slot-slice";
import { patientDetailsActions } from "../slice/patientdetails-slice";
import { DialysisApiActions } from "../slice/dialysis-slice";
import { laborderActions } from "../slice/laborder-slice";
import { RadiologyMastersActions } from "../slice/radiologyorder-slice";
import { TreatmentMastersActions } from "../slice/treatmentorder-slice";
import { MedicationMastersActions } from "../slice/medication/masters-slice";
import { signOffActions } from "../slice/signoff-slice";
import { medicationsActions } from "../slice/medication/medication-slice";
import { DiagnosisMastersActions } from "../slice/diagnosis-slice";
import { billingDetailsActions } from "../slice/billing-slice";
import { chiefCompilenceMastersActions } from "../slice/cheifcomplaints-slice";
import { DoccActions } from "../slice/docc/docs-slice";
import { DashboardActions } from "../slice/dashboard-slice";
import { commonActions } from "../slice/common-slice";
import { referalActions } from "../slice/referral-slice";
import { patientDashboardActions } from "../slice/patientDashboard-slice";
import { PayerChargeCodeMappingActions } from "../slice/payerChargeCodeMapping-slice";
import { advancePaymentActions } from "../slice/advancePayment-slice";
import { TransferandScheduleAction } from "../slice/e-referral/transferNschedule-slice";
import { FinanceActions } from "../slice/finance-slice";
import { ReferalRegOutAction } from "../slice/ReferalRegOut-slice";
import { emrBannerActions } from "../slice/emrBanner-slice";
import { preAss_auth_Actions } from "../slice/pre-assemble/pre-ass-auth-slice";
import { notificationActions } from "../slice/notification-slice";

const actions = {
  ...authActions,
  ...personActions,
  ...searchApiActions,
  ...oraganizationRegisterPatientActions,
  ...docterListingActions,
  ...searchHospitalActions,
  ...registrationActions,
  ...patientActions,
  ...appointmentActions,
  ...slotApiActions,
  ...visitRegistrationActions,
  ...patientDetailsActions,
  ...DialysisApiActions,
  ...laborderActions,
  ...RadiologyMastersActions,
  ...TreatmentMastersActions,
  ...MedicationMastersActions,
  ...signOffActions,
  ...medicationsActions,
  ...DiagnosisMastersActions,
  ...billingDetailsActions,
  ...chiefCompilenceMastersActions,
  ...DoccActions,
  ...DashboardActions,
  ...commonActions,
  ...referalActions,
  ...patientDashboardActions,
  ...PayerChargeCodeMappingActions,
  ...advancePaymentActions,
  ...TransferandScheduleAction,
  ...FinanceActions,
  ...ReferalRegOutAction,
  ...emrBannerActions,
  ...preAss_auth_Actions,
  ...notificationActions
};

export default actions;
