import React from 'react'
import JsonModal from './jsonModal'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material'
// import CopyToClipboard from 'react-copy-to-clipboard'
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ChatBot from './chatBot';
import CancelIcon from "@mui/icons-material/Cancel";
import Emr from './CpgEmr';
import jwtDecode from 'jwt-decode';
import axios from 'axios';

const CpgPackage = (props) => {
  const [openCpg, setOpenCpg] = React.useState(false);

  // Show JSON Modal
  const userInfo = localStorage?.getItem("UserInfo");
  const jwtToken = localStorage?.getItem("JwtToken");
  const decodedUserinfo =
  userInfo === null || userInfo === undefined ? "" : jwtDecode(userInfo);

  const practitionerid = decodedUserinfo?.practitionerid ?? "";
  const roleid = decodedUserinfo?.roleid ?? "";

  const [jsonResponse, setJsonResponse] = React.useState(null);
  const [open, setOpen] = React.useState(false);


  // Get Input JSON

  const [openJsonModal, setOpenJsonModal] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("Test");
  const [simulationData, setSimulationData] = React.useState("");

  const options = ["Test", "Simulation", "Production"];

  const handleClickOpen = () => {
    setOpenJsonModal(true);
  };

  const handleCloseJsonModal = () => {
    setOpenJsonModal(false);
  };

  const handleSubmit = () => {
    try {
      const jsonData = JSON.parse(simulationData);
      var simulationInput = {
        inputdata: jsonResponse,
        response: jsonData,
      };
      setSimulationData(simulationInput);
      handleCloseJsonModal();
      setOpenCpg(!openCpg);
    } catch (error) {
      console.log("Invalid JSON input");
      alert("Invalid JSON input");
    }
  };


  const handleClose = () => {
    setOpen(false);
  };
  
  const handleClickAnalzye = () => {
    if (selectedValue === "Simulation") {
      handleClickOpen();
    } else {
      setOpenCpg(!openCpg);
    }
    // let data1 = JSON.stringify({
    //   promptid: "CPG",
    //   filterValues: {
    //     patientid: props?.patientId,
    //     encounterid: props?.encounterid,
    //     practitionerid: practitionerid,
    //     practroleid: roleid,
    //   },
    // });

    let data = JSON.stringify({
      serviceid: "6a195c00-1af0-44d3-9fd6-c22d4e4b99b0",
      mode: selectedValue,
      filterValues: {
        patientid: props?.patientId,
        encounterid: props?.encounterid,
        practitionerid: practitionerid,
        practroleid: roleid,
      },
      isincludeinput: true,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_CPG_AI_API,
      headers: {
        Jwttoken: jwtToken,
        "Content-Type": "application/json",
      },
      data: data,
    };
    try {
      axios
        .request(config)
        .then((response) => {
          setJsonResponse(response.data.inputdata);
          //setOpen(true);
          
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      // setLoading(false);
    }
  };

  return (
    <div>
       <JsonModal
          open={open}
          onClose={handleClose}
          jsonResponse={jsonResponse}
      />
       <Dialog
          open={openJsonModal}
          onClose={handleClose}
          fullWidth
          maxWidth="lg"
        >
          {/* <DialogTitle>JSON Data Input</DialogTitle> */}

          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box
                  display="flex"
                  flexDirection="column"
                  height="100%"
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                  >
                    <h2>Input Data</h2>
                    <Tooltip title="Copy Input Data">
                      {/* <CopyToClipboard
                        text={
                          jsonResponse
                            ? JSON.stringify(jsonResponse, null, 2)
                            : ""
                        }
                      > */}
                        <IconButton>
                          <ContentCopyIcon />
                          <Typography>Copy</Typography>
                        </IconButton>
                      {/* </CopyToClipboard> */}
                    </Tooltip>
                  </Box>
                  <TextField
                    multiline
                    rows={22}
                    variant="outlined"
                    fullWidth
                    value={
                      jsonResponse
                        ? JSON.stringify(jsonResponse, null, 2)
                        : ""
                    }
                    //onChange={(e) => setjsonInput(e.target.value)}
                  />
                  {/* <Box mt={2}>
                    <Button
                      variant="contained"
                      onClick={handleFetchInput}
                    >
                      Fetch Input
                    </Button>
                  </Box> */}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  display="flex"
                  flexDirection="column"
                  height="100%"
                >
                  <Box mb={2}>
                    <h2>Model Data</h2>
                  </Box>
                  <TextField
                    multiline
                    rows={22}
                    variant="outlined"
                    fullWidth
                    //value={simulationData}
                    onChange={(e) => setSimulationData(e.target.value)}
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseJsonModal}>Close</Button>
            <Button variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          </DialogActions>
      </Dialog>
      <ChatBot
        options={options}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        onButtonClick={handleClickAnalzye}
      ></ChatBot>
       <Dialog
          open={openCpg}
          onClose={() => setOpenCpg(false)}
          fullWidth
          maxWidth="xl"
        >
          <DialogTitle sx={{ m: 0, p: 2 }}>
            <Typography variant="h6">MEDAI ASSISTANT ANALYSIS</Typography>
            <IconButton
              aria-label="close"
              onClick={() => setOpenCpg(false)}
              size="large"
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
              color="primary"
            >
              <CancelIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers sx={{ padding: 0 }}>
            <div style={{ overflowX: "hidden", overflowY: "auto" }}>
              <Emr
                mode={selectedValue}
                simulationData={simulationData}
                patientid= {props?.patientId}
                encounterid= {props?.encounterid}
                setOpenCpg={setOpenCpg}
              />
            </div>
          </DialogContent>
          {/* content of the screen */}
        </Dialog>
    </div>
  )
}

export default CpgPackage