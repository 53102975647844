import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';

const JsonModal = ({ open, onClose, jsonResponse }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Input Data</DialogTitle>
      <DialogContent>
        <TextField
          multiline
          rows={20}
          fullWidth
          variant="outlined"
          value={jsonResponse ? JSON.stringify(jsonResponse, null, 2) : ''}
          InputProps={{
            readOnly: true,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JsonModal;