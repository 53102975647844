import React from "react";
import {
  Div,
  Select,
  Col,
  Row,
  Paper,
  H6,
  TextArea,
  TextInput,
  Image,
  Text,
} from "qdm-component-library";
import "./style.css";
import withAllContexts from "../../hoc/withAllContexts";
import { UIColor } from "../../themes/theme";
import { useLocation } from "react-router-dom";
import uploadIcon from "../../assets/img/svg/icons8-upload.svg";
import { Grid, Popover, makeStyles } from "@material-ui/core";
import UploadFiles from "../../components/navBar/uploadFiles";
import FileIcon from '../../assets/img/svg/icons8-file.svg';
const useStyles = makeStyles((theme) => ({
  muiPopovers: {
    boxShadow: "0px 25px 50px #00000029",
    borderRadius: 8,
    marginTop: 10,
    padding: 10,
  },
  mykadbtn: {
    fontFamily: "pc_medium",
    fontSize: "14px !important",
    color: "#6F6F6F !important",
    backgroundColor: "#f2f2f2 !important",
  },
}));
function RefNote(props) {
  const styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    errorborderStyle: {
      borderRadius: 8,
    },
    borderReffralBottom: {
      borderRadius: "2px 2px 6px 6px",
    },
    borderReffralTop: {
      borderRadius: "6px 6px 2px 2px",
    },
    selectBorderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
  };

  const { refNotes, setRefNotes } = props;
  const [isUpload, setIsUpload] = React.useState(null);
  const uploadFun = (e) => {
    setIsUpload(e?.currentTarget);
  };
  const uploadDocument = (files, viewfiles) => {
    
    setRefNotes({ ...refNotes, viewFile: viewfiles, consentFile: files|| [] });
  };

  const setDetails = (name, value) => {
    
    setRefNotes((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (refNotes.error) {
      refNotes.error[name] = false;
    }
    setRefNotes({
      ...refNotes,
      [name]: value,
    });
  };
  const location = useLocation();
  const classes = useStyles();
  const viewMode = location?.state?.isERefView;
  const {
    labelStyle,
    borderStyle,
    borderReffralBottom,
    borderReffralTop,
    selectBorderStyle,
    errorborderStyle,
  } = styles;
  const { parent_id } = props;
  const FlterOptions3 = (props, type) => {
    let filteredArray = props.filter((obj) => obj.hasOwnProperty([type]));
    let list = [];
    let data = filteredArray[0]?.[type].Value;
    for (let index in data) {
      var datatemp = {
        value: data[index]?.display,
        _id: data[index]?._id,
      };

      list.push(datatemp);
    }

    return list;
  };

  return (
    <Div id={`${parent_id}_refnotes_parent_div`} className="pv-root">
      <Paper
        id={`${parent_id}_refnotes_parent_paper`}
        className="pl-root"
        style={{ height: "30vh" }}
      >
        <Row
          id={`${parent_id}_refnotes_parent_row`}
          className="en-actions-root"
        >
          <Col
            id={`${parent_id}_refnotes_parent_col`}
            md={12}
            lg={12}
            sm={12}
            xs={12}
            className="no-padding-left"
          >
            <Div id={`${parent_id}_refnotes_title_div`}>
              <H6
                id={`${parent_id}_refnotes_label_h6`}
                className="en-title space-padding-bottom"
              >
                REFERRAL NOTE
              </H6>
            </Div>
            <Grid container xs={12} style={{ paddingLeft: "10px" }} spacing={3}>
              <Grid
                item
                id={`${parent_id}_refnotes_reason_col`}
                md={2}
                className="space15-padding-bottom no-padding-left"
                lg={6}
                sm={12}
                xs={12}
              >
                <TextArea
                  id={`${parent_id}_refletter_no_label_textinput`}
                  value={refNotes?.referralNotes}
                  onChange={(e) =>
                    setDetails("referralNotes", e.target.value, 20)
                  }
                  label="Referral Notes"
                  placeholder="Referral Notes"
                  labelStyle={labelStyle}
                  hoverColor={UIColor.primaryColor.color}
                  style={{
                    marginTop: "6px",
                    padding: 14,
                    resize: "none",
                    height: "8vh",
                    borderRadius: 4,
                    borderColor: "#E0E0E0",
                    fontFamily: "Arial", 
                    fontSize: "15px",
                  }}
                  maxLength={3000}
                  disabled={viewMode|| props?.isViewReferral}
                />

                {/* <Select
                                id={`${parent_id}_refnotes_reason_select`}
                                // isRequired
                                options={FlterOptions3(props?.allMasters, "REASON")}
                                placeholder='select...'
                                // helperText={(note?.error && note?.error['reason']) ? 'Is required' : ''}
                                error={
                                    // (note?.error && note?.error['reason']) ? true : 
                                    false}
                                value={refNotes?.reason?.value} onChangeValue={e => setDetails("reason", e)}
                                 label="Reason for Referral" 
                                 showArrow 
                                 labelStyle={labelStyle}
                                inLineStyles={selectBorderStyle}
                                hoverColor={UIColor.primaryColor.color} /> */}
                {/* <TextArea
                  id={`${parent_id}_refnotes_reason_textarea`}
                  value={refNotes?.multiLine}
                  onChange={(e) => setDetails("multiLine", e.target.value)}
                  maxLength={500}
                  placeholder=""
                  className="space15-padding-bottom"
                  labelStyle={labelStyle}
                  style={{
                    ...borderStyle,
                    ...borderReffralBottom,
                    height: "auto",
                  }}
                  hoverColor={UIColor.primaryColor.color}
                  disabled={viewMode}
                /> */}
              </Grid>
              <Grid
                container
                xs={12}
                style={{ paddingLeft: "10px" }}
                spacing={3}
              >
                <Grid
                  item
                  id={`${parent_id}_refnotes_reason_col`}
                  md={2}
                  className="space15-padding-bottom no-padding-left"
                  lg={4}
                  sm={12}
                  xs={12}
                >
                  {/* <TextInput
                  id={`${parent_id}_refletter_no_label_textinput`}
                  value={refNotes?.referralNotes}
                  onChange={(e) => setDetails("referralNotes", e.target.value, 20)}
                  label="Referral Notes"
                  placeholder="Referral Notes"
                  labelStyle={labelStyle}
                  style={selectBorderStyle}
                  hoverColor={UIColor.primaryColor.color}
                /> */}
                  <Select
                    id={`${parent_id}_refnotes_reason_select`}
                    isRequired
                    options={FlterOptions3(props?.allMasters, "REASON")}
                    placeholder="select..."
                    // helperText={(note?.error && note?.error['reason']) ? 'Is required' : ''}
                    error={
                      refNotes?.error && refNotes?.error["reason"]
                        ? true
                        : false
                    }
                    value={refNotes?.reason?.value}
                    onChangeValue={(e) => setDetails("reason", e)}
                    label="Consent Given By"
                    showArrow
                    labelStyle={labelStyle}
                    inLineStyles={
                      refNotes?.error && refNotes?.error["reason"]
                        ? errorborderStyle
                        : selectBorderStyle
                    }
                    hoverColor={UIColor.primaryColor.color}
                    disabled={viewMode|| props?.isViewReferral}
                  />
                </Grid>
                <Grid
                  container
                  item
                  id={`${parent_id}_refnotes_reason_col`}
                  md={1}
                  className="space15-padding-bottom no-padding-left"
                  lg={3}
                  sm={12}
                  xs={12}
                  style={{
                    alignItems: "center",
                    marginTop: "8px",
                    marginLeft: "10px",
                  }}
                  spacing={2}
                >
                  <Text
                    id={`${parent_id}_refdetails_service_requested_text`}
                    style={{
                      color: "#6F6F6F",
                      fontSize: "12px",
                      fontFamily: "pc_regular",
                    }}
                  >
                    Upload Consent Document
                  </Text>
                  <Grid item xs={1} style={{ marginTop: "8px" }}>
                    <Div
                      id="Referral_Registration_referral_parent_div"
                      className="uploadIcon"
                    >
                      <Image
                        src={viewMode || props?.isViewReferral ? FileIcon : uploadIcon}
                        alt="upload"
                        onClick={(e) => uploadFun(e)}
                      />
                    </Div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Popover
              anchorEl={isUpload}
              open={Boolean(isUpload)}
              onClose={() => uploadFun(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              style={{ zIndex: 99999 }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              classes={{ paper: classes.muiPopovers }}
            >
              <UploadFiles
                uploadDocument={uploadDocument}
                files={refNotes?.consentFile}
                viewfiles={refNotes?.viewFile}
                isViewMode={props.isViewMode || viewMode || props?.isViewReferral}
              />
            </Popover>
            <Row
              id={`${parent_id}_refnotes_disription_row`}
              className="en-actions-root"
              style={{ display: "none" }}
            >
              <Col
                id={`${parent_id}_refnotes_Exam_clinical_Finding_col`}
                md={6}
                lg={6}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <TextArea
                  id={`${parent_id}_refnotes_Exam_clinical_Finding_textarea`}
                  value={refNotes?.exam_clinic}
                  onChange={(e) => setDetails("exam_clinic", e.target.value)}
                  placeholder=""
                  className="space15-padding-bottom"
                  label="Exam & clinical Findings"
                  labelStyle={labelStyle}
                  style={borderStyle}
                  hoverColor={UIColor.primaryColor.color}
                  disabled={props?.isViewReferral}
                />
              </Col>
              <Col
                id={`${parent_id}_refnotes_Diagnosis_col`}
                className="space15-padding-bottom no-padding-left"
                md={6}
                lg={6}
                sm={12}
                xs={12}
              >
                <TextArea
                  id={`${parent_id}_refnotes_Diagnosis_textarea`}
                  value={refNotes?.diagnosis}
                  onChange={(e) => setDetails("diagnosis", e.target.value)}
                  placeholder=""
                  label="Diagnosis"
                  labelStyle={labelStyle}
                  style={borderStyle}
                  hoverColor={UIColor.primaryColor.color}
                  disabled={props?.isViewReferral}
                />
              </Col>
              <Col
                id={`${parent_id}_refnotes_Treatment_col`}
                md={6}
                lg={6}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <TextArea
                  id={`${parent_id}_refnotes_Treatment_textarea`}
                  value={refNotes?.treatment}
                  onChange={(e) => setDetails("treatment", e.target.value)}
                  placeholder=""
                  label="Treatment"
                  labelStyle={labelStyle}
                  style={borderStyle}
                  hoverColor={UIColor.primaryColor.color}
                />
              </Col>
              <Col
                id={`${parent_id}_refnotes_Referral_Note_col`}
                md={6}
                lg={6}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <TextArea
                  id={`${parent_id}_refnotes_Referral_Note_textarea`}
                  value={refNotes?.referral_notes}
                  onChange={(e) => setDetails("referral_notes", e.target.value)}
                  placeholder=""
                  label="Referral Notes"
                  labelStyle={labelStyle}
                  style={borderStyle}
                  hoverColor={UIColor.primaryColor.color}
                  disabled={viewMode|| props?.isViewReferral}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Paper>
    </Div>
  );
}

export default withAllContexts(RefNote);
