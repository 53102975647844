import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Webcam from "react-webcam";
import { LeftJoinDiv } from "./leftJoinDiv";
import  actions  from "../../../redux/actions"
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import { AppRoutes } from "../../../router/routes";
import { DrawerMeetingContext,VisitDetails } from '../../../context';
import moment from "moment";
import {
	dbName,
	urls,
	fetchData,
	query_ids
} from "../../../utils";
import withAllContexts from "../../../hoc/withAllContexts";
import withAppBar from "../../../hoc/withAppBar";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#FFF",
		height: "100vh",
	},
	cameraPreview: {
		backgroundColor: "#424447",
		height: "100vh",
		width: "auto",
		borderTopLeftRadius: 30,
		borderBottomLeftRadius: 30,
		"& video": {
			width: "100%",
		},
	},
	joinTitle: {
		fontSize: 16,
		textAlign: "center",
		fontWeight: 700,
		padding: 0,
		margin: 0,
	},
	TicketCompDiv: {
		backgroundColor: "#FAFCFF",
		height: "100vh",
		padding: "0px 28px",
		display: "grid",
		justifyContent: "center",
		alignItems: "center",
		overflowY: "auto",
		paddingTop: "6%",
	},
	image: { textAlign: "end", paddingTop: 8 },
	p_1: {
		margin: 0,
		fontSize: 15,
		fontWeight: 600,
	},
	p_2: {
		margin: 0,
		fontSize: 14,
		fontWeight: 400,
		color: "#B6B6B6",
	},
	p_3: {
		margin: 0,
		color: "#B6B6B6",
		fontSize: 12,
		color: "#B6B6B6",
	},
	div: {
		display: "flex",
		justifyContent: "center",
	},
	ControlBtn: {
		boxShadow: "0px 3px 8px #0000001A",
		border: "1px solid #F0F0F0",
		borderRadius: 8,
		padding: 10,
		"& svg": {
			fontSize: 20,
		},
	},
	joinBtn: {
		fontSize: 13,
		backgroundColor: "#0071F2",
		padding: "6px 20px",
		color: "white",
		textTransform: "capitalize",
		"&:hover": {
			backgroundColor: "#0071F2",
		},
	},
	large: {
		width: theme.spacing(20),
		height: theme.spacing(20),
	},
	contentDiv: {
		height: "100vh",
		justifyContent: "center",
	},
	contentPersonName: {
		fontSize: 17,
		fontWeight: 600,
		color: "#fff",
	},
}));

const VideoCall = (props) => {
	const classes = useStyles();
	const [mic, setMic] = React.useState(false);
	const [video, setVideo] = React.useState(true);
	const [setting, setSetting] = React.useState(false);

	const navigate = useNavigate();
	const location = useLocation();
	const drawerMeetingContext = React.useContext(DrawerMeetingContext);
	const { setVisitDetails } = React.useContext(VisitDetails);

	const [state,setState] = React.useState({
		info:{}
	})
		
	const dispatch = useDispatch();

	const ControlBtnClick = (param) => {
		if (param === "mic") {
			setMic(!mic);
		} else if (param === "video") {
			setVideo(!video);
		} else if (param === "setting") {
			setSetting(!setting);
		} else if (param === "meeting") {
			// setMeeting(!stateMeeting);
			videoCall()
		}
	};
	const videoConstraints = {
		width: 1280,
		height: 720,
		facingMode: "user",
	};

	const redirectTreatmentPlan = (
		personId,
		patientId,
		appointmentId,
		encounterId,
		type,
		pId,
		encounter_id,
		app,
		ticketId,
		queueId,
		mrn,
		) => {
		navigate(AppRoutes.patientDetail, {
			state: {
			encounter_id,
			personId,
			patientId,
			appointmentId,
			encounterId,
			type,
			pId,
			app_id: app?.info?._id,
			ticketId,
			allCurrentData: app,
			referralId: app?.inputdoc?.referralTrxId
				? app?.inputdoc?.referralTrxId
				: app?.inputdoc?.referralid,
			mrnId : mrn
			},
		});
		// setpreviousQueue(queueId);
	};
	const videoCall = () =>{
		drawerMeetingContext.setMeetingDrawer({
			...drawerMeetingContext,
			open: true,
			RoomName: state?.info?.dataJson?.avc?.name,
			jwt: state?.info?.dataJson?.avc?.moderatorUrl?.split("jwt=")?.[1],
			widthAdjState: 'side',
			user_name: location?.state?.name,
			domain: state?.info?.dataJson?.avc?.moderatorUrl?.split("/")?.[2]
		})
		
		if(location?.state?.action?.action === process.env.REACT_APP_NURSE_SIGN_OFF || 
			location?.state?.action?.action === process.env.REACT_APP_DOCTOR_SIGN_OFF){
				const roleName = localStorage.getItem("RoleName");
				let firstpass = dispatch(
				  actions.FIRST_PASS_API({
					actionType: "SERVE",
					appId: location?.state?.data?.inputdoc?.appointmentid,
					servingType: (roleName).toLowerCase() === "nurse" ? "VITAL" : "GENERAL" 
				  })
				);
			redirectTreatmentPlan(
				location?.state?.data?.personid,
				location?.state?.data?.inputdoc?.patientid
				  ? location?.state?.data?.inputdoc?.patientid
				  : location?.state?.data?.inputdoc?.patientId,
				location?.state?.data?.inputdoc?.appointmentid,
				location?.state?.data?.inputdoc?.encounterid,
				location?.state?.data?.inputdoc?.resource_type,
				location?.state?.data?.inputdoc?.patientid
				  ? location?.state?.data?.inputdoc?.patientid
				  : location?.state?.data?.inputdoc?.patientId,
				location?.state?.data?.inputdoc?.encounterid,
				location?.state?.data,
				location?.state?.data?.ticketId,
				location?.state?.queueId,
				location?.state?.data?.field2
			);
			// attendPatient(
			// location?.state?.data?.personId,
			// location?.state?.data?.patientId,
			// location?.state?.data?.token,
			// location?.state?.data?.encounterId,
			// location?.state?.data?.type,
			// location?.state?.data?.pId,
			// location?.state?.data?.encounter_id,
			// location?.state?.data
			// );
		} else {
			registerPatient(
				location?.state?.data?.personid,
				location?.state?.data?.inputdoc?.patientid,
				location?.state?.data?.inputdoc?.appointmentid,
				location?.state?.data?.inputdoc?.patientid ? true : false,
				location?.state?.data?.mobileNo,
				location?.state?.action,
				location?.state?.data?.ticketId || location?.state?.data?.info?.ticketid
			);
		}

	}


	const registerPatient = (
		personId,
		patientId,
		appointmentId,
		isPatient,
		mobileNo,
		action,
		ticketid
	  ) => {
		setVisitDetails({
		  encounter: {
			mode: "direct",
		  },
		  practitioner: {},
		  device: {},
		  helthcare: {},
		  accompained: {},
		});
		if (isPatient) {
		  navigate(AppRoutes.visitRegistration, {
			state: {
			  personId,
			  from: 1,
			  patientId,
			  appointmentId,
			  Ispatient: true,
			  isedit: action?.action == "VISIT REGISTER" ? false : true,
			  isRevise: action?.action == "REVISE VISIT" ? true : false,
			  patientData: mobileNo,
			  stateAction: action?.action,
			  stateEndPoint: action?.apiendpoint,
			  stateBackEndCall: action?.backEndCall,
			  stateTicketid: ticketid,
			},
		  });
		} else {
		  navigate(AppRoutes.assemblePatioentRegister, {
			state: {
			  nextRoute: AppRoutes.visitRegistration,
			  personId,
			  patientId,
			  from: 1,
			  appointmentId,
			  patientData: mobileNo,
			  Ispatient: false,
			  idedit: false,
			  fromQueue: true,
			  stateAction: action?.action,
			  stateEndPoint: action?.apiendpoint,
			  stateBackEndCall: action?.backEndCall,
			  stateTicketid: ticketid,
			},
		  });
		}
	  };

	//   const registerPatient = (personId, patientId, appointmentId, isPatient) => {
	// 	setVisitDetails({
	// 	  encounter: {
	// 		mode: "video",
	// 	  },
	// 	  practitioner: {},
	// 	  device: {},
	// 	  helthcare: {},
	// 	  accompained: {},
	// 	});
	// 	if (isPatient) {
	// 		navigate(AppRoutes.visitRegistration, {
	// 		  state: {
	// 			personId,
	// 			from: 1,
	// 			patientId,
	// 			appointmentId,
	// 			Ispatient: true,
	// 			isedit: location?.state?.data?.action == "VISIT REGISTER" ? false : true,
	// 			isRevise: location?.state?.data?.action == "REVISE VISIT" ? true : false,
	// 			patientData: location?.state?.data?.mobileNo,
	// 			stateAction: location?.state?.data?.action,
	// 			stateEndPoint: location?.state?.data?.apiendpoint,
	// 			stateBackEndCall: location?.state?.data?.backEndCall,
	// 			stateTicketid: location?.state?.data?.ticketid,
	// 		  },
	// 		});
	// 	// 	navigate(AppRoutes.patientVisitDetails, {
	// 	// 	state: {
	// 	// 	  personId,
	// 	// 	  patientId,
	// 	// 	  appointmentId,
	// 	// 	  Ispatient: true,
	// 	// 	  patientData: location?.state?.data?.[0]?.mobileNo,
	// 	// 	},
	// 	//   });
	// 	} else {
	// 		navigate(AppRoutes.assemblePatioentRegister, {
	// 		  state: {
	// 			nextRoute: AppRoutes.visitRegistration,
	// 			personId,
	// 			patientId,
	// 			from: 1,
	// 			appointmentId,
	// 			patientData: location?.state?.data?.mobileNo,
	// 			Ispatient: false,
	// 			idedit: false,
	// 			fromQueue: true,
	// 			stateAction: location?.state?.data?.action,
	// 			stateEndPoint: location?.state?.data?.apiendpoint,
	// 			stateBackEndCall: location?.state?.data?.backEndCall,
	// 			stateTicketid: location?.state?.data?.ticketid,
	// 		  },
	// 		});
	// 	// 	navigate(AppRoutes.assemblePatioentRegister, {
	// 	// 	state: {
	// 	// 	  nextRoute: AppRoutes.patientVisitDetails,
	// 	// 	  personId,
	// 	// 	  patientId,
	// 	// 	  appointmentId,
	// 	// 	  patientData: location?.state?.data?.[0]?.mobileNo,
	// 	// 	  Ispatient: false,
	// 	// 	  idedit: false,
	// 	// 	  fromQueue: true,
	// 	// 	},
	// 	//   });
	// 	}
	//   };

	  const getAge = (date) => {
		return (
		  Math.floor(
			moment(new Date()).diff(moment(date, "DD/MM/YYYY"), "years", true)
		  ) + " Yrs"
		);
	  };
	React.useEffect(()=>{
		if(location?.state?.appointmentId){
			if(drawerMeetingContext?.open){
				// props?.goBack()
			}
			else{
				fetchInfo(location?.state?.appointmentId)
			}
		}
		else{
			// props?.goBack()
		}
	},[])


	const fetchInfo = async (id) =>{
		// const info = await dispatch(actions.AVC_LOGIN({id})); //12481
		let queryData ={body: JSON.stringify({
			db_name: dbName,
			queryid: query_ids["avcLogin"],
			filter: {
			  _id: id
			},
		  })};
		const val = await fetchData(queryData, urls.readQdmQueries);
		const data = val?.[0];
		const info = {
			date: data?.start??"",
			app_id: data?.appno??"",
			doctor: {
				name: (data?.resourcecode?.[0]?.name?.[0]?.fulname)? data?.resourcecode?.[0]?.name?.[0]?.fulname:"-",
				type: data?.resourcerole??"",
				org_name: data?.orgid?.name??""
			},
			patient: {
				name: data?.PatientID?.[0]?.name?.[0]?.fulname ? data?.PatientID?.[0]?.name?.[0]?.fulname:"",
				age: data?.PatientID?.[0]?.birthDate? getAge(data?.PatientID?.[0]?.birthDate):"",
				gander: data?.PatientID?.[0]?.gender?.Type??"",
				id: data?.PatientID?.[0]?.alias ?? "",
				mobile: data?.PatientID?.[0]?.telecom?.[0]?.value??""
			},
			personID:{
				name: data?.PersonID?.[0]?.name?.[0]?.fulname ? data?.PersonID?.[0]?.name?.[0]?.fulname : "-",
				age: data?.PersonID?.[0]?.age,
				gander: data?.PersonID?.[0]?.gender?.Type ??"",
				id: data?.PersonID?.[0]?.alias??"",
				mobile: data?.PersonID?.[0]?.telecom?.[0]?.value??""
			},
			dataJson: data,
			avc:data.avc
		};
		if(!(info?.length>0)){
			setState({
				...state,
				info:info
			})
		}
	}

	return (
		<div className={classes.root}>
				<Grid container>
					<Grid item xs={3}>
						<LeftJoinDiv
							micControl={mic}
							videoControl={video}
							ControlBtnClick={(param) => ControlBtnClick(param)}
							info={state?.info}
						/>
					</Grid>
					<Grid item xs={9}>
						<div className={classes.cameraPreview}>
							{video ? (
								<Grid
									container
									direction="column"
									alignItems="center"
									className={classes.contentDiv}
								>
									<Avatar alt="Remy Sharp" className={classes.large} />
									<p className={classes.contentPersonName}>
										{location?.state?.name ?? 'You'}
									</p>
								</Grid>
							) : (
								<Webcam
									audio={false}
									height={100 + "%"}
									width={100 + "%"}
									videoConstraints={videoConstraints}
								/>
							)}
						</div>
					</Grid>
				</Grid>
		</div>
	);
}

export default withAllContexts(withAppBar(VideoCall));