import React, { useState } from "react";
import { Div, Row, SideMenu, Button, Paper, H6 } from "qdm-component-library";
//import { TableList, BillNav } from "./";
import { AppRoutes } from "../../router/routes";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getImgUrl, getUserInfo, makeName } from "../../utils";
import PatientCard from "../patientDashboard/components/patientCard";
import { TopHeader } from "../../components";
import PatientDashboardNavBar from "../patientDashboard/components/patientDashboardNavBar";
import CardList from "../patientDashboard/components/cardList";
import ViewCard from "../patientDashboard/components/viewCard";
import EmptyCard from "../patientDashboard/components/emptyCard";
import ModelComponent from "../../components/modal";
import AddMember from "../../components/global/addMember";
import Addmemberform from "../../components/global/addmemberform";
import Addmemberotp from "../../components/global/addmemberotp";
import AppointmentAddMemberSuccessPage from "../../components/global/appointmentAddMemberSuccessPage";

const tableHeader = [
  "S.No",
  "Bill.No",
  "Date",
  "Gross Amt",
  "Discount",
  "Net Payable",
  "Paid",
  "Outstanding",
  "Settle Now",
];
function PatientDashBoard (props) {
    const navigate  = useNavigate()
    const dispatch = useDispatch()
    const [state,setState] = useState({
      open: false,
      followUpAppointmentData: [],
      cancelbooking: false,
      cancelbookingreason: {},
      cancelbookings: 0,
      activeId: 1,
      addmmber: false,
      sidebar: props.location?.state?.addMember,
      memberForm: props.location?.state?.addMember,
      searchMenber: props.location?.state?.addMember,
      memberSuccessPage: props.location?.state?.addMember,
      appointmentId: 0,
      choosemember: [],
      memberIds: [],
      chooseSearch: null,
      searchmmrdata: null,
      loggedInState: {},
    });
  
  

 const handelChangeSelect = (v) => {
    setState({
        ...state,
      chooseSearch: v,
    });
  };

 const changeSideBar=()=> {
    setState(
      {
        ...state,
        sidebar: false,
        memberForm: false,
        searchMenber: false,
        memberSuccessPage: false,
      },
      () => {
        window.location.reload();
      }
    );
  }

  async function fetchInfo() {
    const persondataInfo = await getUserInfo();
    const info = JSON.parse(JSON.stringify(persondataInfo));
    if ((info.type === 'guest') || (!info)) {
      window.location = '/';
    }
    let userId = Number(info.id);
    const persondata = info.persondata;
    userId = persondata?.payload?.data?.id;
    info["name"] = persondata?.payload?.data?.name || info["name"];
    info["gender"] = persondata?.payload?.data?.gender;
    info["birthDay"] = persondata?.payload?.data?.birthDay;
    info["url"] = getImgUrl(persondata?.payload?.data?.imgUrl);
    if (info.type === "token") {
      if (!(props.values && props.values?.selectedUser)) {
        info["userId"] = userId;
      }
    }
    setState({
        ...state,
      loggedInState: { ...info },
    });
    const data = await props.GET_RELATED_PERSON({ userid: userId });
    if (data?.payload?.data && Array.isArray(data?.payload?.data)) {
      const choosemember = [];
      const memberIds = [userId];
      data.payload.data.forEach((val) => {
        choosemember.push({
          src: getImgUrl(val?.person?.[0]?.photo?.[0]?.fileid),
          label: makeName(val?.name?.[0]),
          id: val?.PersonID,
        });
        memberIds.push(val?.PersonID);
      });
      setState({
        ...state,
        choosemember,
        memberIds,
      });
    }
    const val = await props.GET__FOLLOW_UP_APPOINTMENT({
      patientId: persondata.payload?.data?.pid,
    });
    setState({
        ...state,
      followUpAppointmentData: val?.payload?.data,
    });
    props.ALL_APPOINTMENTS({
      userId,
    });
  }

  useEffect(()=> {
    fetchInfo();
  },[])
  const btnClick = ()=> {
   navigate(AppRoutes.appointmentBooking)
  }
  const selectPayBills=()=> {
    // props.history.push({
    //   pathname: Routes.collection,
    //   state: { nextRoute: Routes.PatientDashBoard },
    // });
  }
  const openView = (appointmentId) => {
    setState({
        ...state,
      open: !state.open,
      appointmentId,
    });
  };
 const handlesidebarCancelbooking = (id) => {
    if (id === "closing") {
      getUserInfo().then(persondataInfo => {
        const info = JSON.parse(JSON.stringify(persondataInfo));
        let userId = Number(info.id);
        props.ALL_APPOINTMENTS({
          userId,
        });
      })
    }
    setState({
        ...state,
      cancelbooking: !state.cancelbooking,
      cancelbookingreason: {},
      cancelbookings: 0,
      appointmentId: id || state.appointmentId,
    });
  };
 const handleSelectCancelbooking = (v) => {
    setState({
        ...state,
      cancelbookingreason: v,
    });
  };

  const changeMembers = (newData) => {
    setState({
      ...state,
      choosemember: [...state.choosemember, ...newData],
    })
  }

  const handlecahngeCancelbooking = async (v, number) => {
    if (v === "back") {
      setState({
        ...state,
        cancelbookings: 0,
      });
      return;
    }
    if (!v) {
      handlesidebarCancelbooking();
      window.location.href = "/profile";
      return;
    }
    let mobile = number;
    if (!number) {
      const userInfo = await getUserInfo();
      mobile = userInfo.mobile;
    }
    if (v === 1) {
      await props.GENERATE_OTP({ mobileNo: mobile });
    }
    setState({
        ...state,
      cancelbookings: v,
      otpnumber: number,
    });
  };
 const setActiveId=(Id)=> {
    setState({ activeId: Id });
  }
  const toggleSideBar = (boolean) => {
    setState({
      ...state,
      sidebar: boolean,
      memberForm: false,
      searchMenber: true,
      addmmber: false,
      memberSuccessPage: false,
    });
  };
  const nextSection = (member, signUpData, l) => {
    if (signUpData === "addMember") {
      setState({
        ...state,
        signUpData: l ? l : signUpData,
        searchMenber: false,
        memberForm: false,
        memberOtp: true,
        memberSuccessPage: false,
        addmmber: true,
      });
      return;
    } else if (member === "addmemberform") {
      setState({
        ...state,
        searchMenber: false,
        memberForm: true,
        memberOtp: false,
        addmmber: false,
        signUpData,
      });
    } else if (member === "addmemberotp") {
      if (signUpData) {
        setState({
          ...state,
          signUpData,
          searchMenber: false,
          memberForm: false,
          memberOtp: true,
          memberSuccessPage: false,
          addmmber: false,
        });
      } else {
        setState({
          ...state,
          searchMenber: false,
          memberForm: false,
          memberOtp: true,
          memberSuccessPage: false,
        });
      }
    } else if (member === "back") {
      setState({
        ...state,
        searchMenber: true,
        memberForm: false,
        memberOtp: false,
        memberSuccessPage: false,
      });
    } else if (member === "member_succss") {
      setState({
        ...state,
        searchMenber: false,
        memberForm: false,
        memberOtp: false,
        sidebar: true,
        memberSuccessPage: true,
        signUpData,
      });
    } else if (member === "movenextscreen") {
      setState({
        ...state,
        searchMenber: true,
        memberForm: false,
        memberOtp: false,
        sidebar: false,
        memberSuccessPage: false,
      });
    }
  };

  
 const containedButton = {
    backgroundColor: "#0071F2",
    borderColor: "#0071F2",
    borderRadius: 8,
    padding: "8px 15px",
    minWidth: 100,
    fontWeight: "600",
    fontSize: 12,
  };

  
    const {
      sidebar,
      activeId,
      cancelbooking,
      cancelbookingreason,
      cancelbookings,
    } = state;
    return (
      <Div style={{ backgroundColor: "#F4F5F8" }}>
        <TopHeader
          info={state.loggedInState}
          noHome
          middleComponent={
            <PatientCard
              info={state.loggedInState}
              isAddBtn
              memberList={state.choosemember}
              addMember={toggleSideBar}
            />
          }
          id={"TopHeader"}
        />
        <PatientDashboardNavBar
          navBarArr={[
            { label: "Appointments", id: 1 },
            // { label: "Bill Info", id: 2 },
          ]}
          btnName="Book New Appointment"
          clickNav={setActiveId}
          activeId={activeId}
          btnClick={btnClick}
          id="PatientDashboardNavBar"
        />
        {activeId === 1 && !props?.appointments?.loading && (
          <React.Fragment>
            <div
              style={{
                height: 'calc(100vh - 129px)',
                overflow: 'hidden',
                padding: '14px 37px'
              }}
            >
              <Row
                key={"0"}
                alignItems="stretch"
                direction="row"
                justify="flex-start"
                spacing="2"
                inLineStyles={{
                  // margin: "14px 37px !important",
                  // border: "1px solid #F0F0F0",
                  borderRadius: "8px",
                  // minHeight: "72vh",
                  backgroundColor: "#F4F5F8",
                  // padding: "15px 7px",
                  height: '100%',
                  overflow: 'auto',
                }}
                id={"dashboard-activeId-Row"}
              >
                {props?.appointments?.data?.upcomingAppointment?.length > 0
                  ?
                  <>
                    <CardList
                      title="UPCOMING"
                      id={"dashboard-CardList-UPCOMING"}
                      middleComponent={
                        <>
                          {
                            props?.appointments?.data?.upcomingAppointmentToday?.length > 0 && (<>
                              <H6
                                key={"0"}
                                name="Today"
                                className="pc_medium"
                                style={{
                                  color: "#6a7888",
                                  fontSize: "12px",
                                  margin: 0,
                                  textTransform: "uppercase",
                                  letterSpacing: 0,
                                  lineHeight: 1,
                                  padding: '0px 17px',
                                  marginBottom: 8,
                                }}
                                id={"patientDashboard-Today-Text"}
                              ></H6>
                              {
                                props?.appointments?.data?.upcomingAppointmentToday.map(
                                  (val, i) => (
                                    <ViewCard
                                      openView={openView}
                                      val={val}
                                      key={i}
                                      handlesidebarCancelbooking={
                                        handlesidebarCancelbooking
                                      }
                                      upcoming
                                      title="UPCOMING"
                                      id={"dashboard-CardList-UPCOMING-ViewCard"}
                                    />
                                  ))
                              }
                            </>)
                          }
                          {
                            props?.appointments?.data?.upcomingAppointmentThisWeek?.length > 0 && (<>
                              <H6
                                key={"0"}
                                name="This Week"
                                className="pc_medium"
                                style={{
                                  color: "#6a7888",
                                  fontSize: "12px",
                                  margin: 0,
                                  textTransform: "uppercase",
                                  letterSpacing: 0,
                                  lineHeight: 1,
                                  padding: '0px 17px',
                                  marginBottom: 8,
                                }}
                                id={"patientDashboard-Today-Text"}
                              ></H6>
                              {
                                props?.appointments?.data?.upcomingAppointmentThisWeek.map(
                                  (val, i) => (
                                    <ViewCard
                                      openView={openView}
                                      val={val}
                                      key={i}
                                      handlesidebarCancelbooking={
                                        handlesidebarCancelbooking
                                      }
                                      upcoming
                                      title="UPCOMING"
                                      id={"dashboard-CardList-UPCOMING-ViewCard"}
                                    />
                                  ))
                              }
                            </>)
                          }
                          {
                            props?.appointments?.data?.upcomingAppointmentNextWeek?.length > 0 && (<>
                              <H6
                                key={"0"}
                                name="Next Week"
                                className="pc_medium"
                                style={{
                                  color: "#6a7888",
                                  fontSize: "12px",
                                  margin: 0,
                                  textTransform: "uppercase",
                                  letterSpacing: 0,
                                  lineHeight: 1,
                                  padding: '0px 17px',
                                  marginBottom: 8,
                                }}
                                id={"patientDashboard-Today-Text"}
                              ></H6>
                              {
                                props?.appointments?.data?.upcomingAppointmentNextWeek.map(
                                  (val, i) => (
                                    <ViewCard
                                      openView={openView}
                                      val={val}
                                      key={i}
                                      handlesidebarCancelbooking={
                                        handlesidebarCancelbooking
                                      }
                                      upcoming
                                      title="UPCOMING"
                                      id={"dashboard-CardList-UPCOMING-ViewCard"}
                                    />
                                  ))
                              }
                            </>)
                          }
                          {
                            props?.appointments?.data?.upcomingAppointmentThisMonth?.length > 0 && (<>
                              <H6
                                key={"0"}
                                name="This Month"
                                className="pc_medium"
                                style={{
                                  color: "#6a7888",
                                  fontSize: "12px",
                                  margin: 0,
                                  textTransform: "uppercase",
                                  letterSpacing: 0,
                                  lineHeight: 1,
                                  padding: '0px 17px',
                                  marginBottom: 8,
                                }}
                                id={"patientDashboard-Today-Text"}
                              ></H6>
                              {
                                props?.appointments?.data?.upcomingAppointmentThisMonth.map(
                                  (val, i) => (
                                    <ViewCard
                                      openView={openView}
                                      val={val}
                                      key={i}
                                      handlesidebarCancelbooking={
                                        handlesidebarCancelbooking
                                      }
                                      upcoming
                                      title="UPCOMING"
                                      id={"dashboard-CardList-UPCOMING-ViewCard"}
                                    />
                                  ))
                              }
                            </>)
                          }
                          {
                            props?.appointments?.data?.upcomingAppointmentNextMonth?.length > 0 && (<>
                              <H6
                                key={"0"}
                                name="Next Month"
                                className="pc_medium"
                                style={{
                                  color: "#6a7888",
                                  fontSize: "12px",
                                  margin: 0,
                                  textTransform: "uppercase",
                                  letterSpacing: 0,
                                  lineHeight: 1,
                                  padding: '0px 17px',
                                  marginBottom: 8,
                                }}
                                id={"patientDashboard-Today-Text"}
                              ></H6>
                              {
                                props?.appointments?.data?.upcomingAppointmentNextMonth.map(
                                  (val, i) => (
                                    <ViewCard
                                      openView={openView}
                                      val={val}
                                      key={i}
                                      handlesidebarCancelbooking={
                                        handlesidebarCancelbooking
                                      }
                                      upcoming
                                      title="UPCOMING"
                                      id={"dashboard-CardList-UPCOMING-ViewCard"}
                                    />
                                  ))
                              }
                            </>)
                          }
                        </>
                      } />
                  </>
                  : <CardList
                    title="UPCOMING"
                    id={"dashboard-CardList-UPCOMING"}
                    middleComponent={<EmptyCard />} />}
                <CardList
                  title="FOLLOW UP REMINDER"
                  middleComponent={
                    state?.followUpAppointmentData?.length > 0 ? (
                      state?.followUpAppointmentData.map((val, i) => (
                        <ViewCard
                          openView={openView}
                          showBtn={true}
                          isFollowUp={true}
                          val={val}
                          key={i}
                          handlesidebarCancelbooking={
                            handlesidebarCancelbooking
                          }
                          title="FOLLOW UP REMINDER"
                          id={"dashboard-CardList-FOLLOW-UP-REMINDER-ViewCard"}
                        />
                      ))
                    ) : (
                      <EmptyCard />
                    )
                  }
                />

                {
                  props?.appointments?.data?.pastAppointment?.length > 0 ? <CardList
                    title="PAST"
                    border={false}
                    middleComponent={ 
                      <>
                      {
                            props?.appointments?.data?.pastAppointmentThisWeek?.length > 0 && (<>
                              <H6
                                key={"0"}
                                name="This Week"
                                className="pc_medium"
                                style={{
                                  color: "#6a7888",
                                  fontSize: "12px",
                                  margin: 0,
                                  textTransform: "uppercase",
                                  letterSpacing: 0,
                                  lineHeight: 1,
                                  padding: '0px 17px',
                                  marginBottom: 8,
                                }}
                                id={"patientDashboard-Today-Text"}
                              ></H6>
                              {
                                props?.appointments?.data?.pastAppointmentThisWeek.map(
                                  (val, i) => (
                                    <ViewCard
                                      openView={openView}
                                      val={val}
                                      key={i}
                                      handlesidebarCancelbooking={
                                        handlesidebarCancelbooking
                                      }
                                      // upcoming
                                      showBtn={true}
                                      isShowLabel={true}
                                      title="PAST"
                                      id={"dashboard-CardList-PAST-ViewCard"}
                                    />
                                  ))
                              }
                            </>)
                          }
                          {
                            props?.appointments?.data?.pastAppointmentLastWeek?.length > 0 && (<>
                              <H6
                                key={"0"}
                                name="Last Week"
                                className="pc_medium"
                                style={{
                                  color: "#6a7888",
                                  fontSize: "12px",
                                  margin: 0,
                                  textTransform: "uppercase",
                                  letterSpacing: 0,
                                  lineHeight: 1,
                                  padding: '0px 17px',
                                  marginBottom: 8,
                                }}
                                id={"patientDashboard-Today-Text"}
                              ></H6>
                              {
                                props?.appointments?.data?.pastAppointmentLastWeek.map(
                                  (val, i) => (
                                    <ViewCard
                                      openView={openView}
                                      val={val}
                                      key={i}
                                      handlesidebarCancelbooking={
                                        handlesidebarCancelbooking
                                      }
                                      // upcoming
                                      showBtn={true}
                                      isShowLabel={true}
                                      title="PAST"
                                      id={"dashboard-CardList-PAST-ViewCard"}
                                    />
                                  ))
                              }
                            </>)
                          }
                          {
                            props?.appointments?.data?.pastAppointmentThisMonth?.length > 0 && (<>
                              <H6
                                key={"0"}
                                name="This Month"
                                className="pc_medium"
                                style={{
                                  color: "#6a7888",
                                  fontSize: "12px",
                                  margin: 0,
                                  textTransform: "uppercase",
                                  letterSpacing: 0,
                                  lineHeight: 1,
                                  padding: '0px 17px',
                                  marginBottom: 8,
                                }}
                                id={"patientDashboard-Today-Text"}
                              ></H6>
                              {
                                props?.appointments?.data?.pastAppointmentThisMonth.map(
                                  (val, i) => (
                                    <ViewCard
                                      openView={openView}
                                      val={val}
                                      key={i}
                                      handlesidebarCancelbooking={
                                        handlesidebarCancelbooking
                                      }
                                      // upcoming
                                      showBtn={true}
                                      isShowLabel={true}
                                      title="PAST"
                                      id={"dashboard-CardList-PAST-ViewCard"}
                                    />
                                  ))
                              }
                            </>)
                          }
                          {
                            props?.appointments?.data?.pastAppointmentLastMonth?.length > 0 && (<>
                              <H6
                                key={"0"}
                                name="Last Month"
                                className="pc_medium"
                                style={{
                                  color: "#6a7888",
                                  fontSize: "12px",
                                  margin: 0,
                                  textTransform: "uppercase",
                                  letterSpacing: 0,
                                  lineHeight: 1,
                                  padding: '0px 17px',
                                  marginBottom: 8,
                                }}
                                id={"patientDashboard-Today-Text"}
                              ></H6>
                              {
                                props?.appointments?.data?.pastAppointmentLastMonth.map(
                                  (val, i) => (
                                    <ViewCard
                                      openView={openView}
                                      val={val}
                                      key={i}
                                      handlesidebarCancelbooking={
                                        handlesidebarCancelbooking
                                      }
                                      // upcoming
                                      title="PAST"
                                      showBtn={true}
                                      isShowLabel={true}
                                      id={"dashboard-CardList-PAST-ViewCard"}
                                    />
                                  ))
                              }
                            </>)
                          }
                      </> 
                    } /> : <CardList
                    title="PAST"
                    border={false}
                    middleComponent={ <EmptyCard /> } /> 
                }
                
              </Row>
            </div>
          </React.Fragment>
        )}
        {activeId === 2 && (
          <React.Fragment>
            <Div
              // spacing="2"
              inLineStyles={{
                margin: "14px !important",
                border: "1px solid #F0F0F0",
                borderRadius: "8px",
                minHeight: "72vh",
                backgroundColor: "#F9F9F9",
                padding: 20,
                display: "flex",
                flexDirection: "column",
              }}
              id={"dashboard-activeId-div"}
            >
              {/* <BillNav
                activeid={1}
                btnList={[
                  { id: 1, label: "Outstanding" },
                  { id: 2, label: "Paid" },
                ]}
                id={"dashboard-BillNav"}
              /> */}
              <Div style={{ flex: 1 }}>
                <Paper
                  style={{
                    margin: 0,
                    padding: "20px 0px",
                    boxShadow: "0px 10px 25px #0000000A",
                    borderRadius: 8,
                    border: "1px solid #F0F0F0",
                  }}
                  id={"dashboard-Paper"}
                >
                  {/* <TableList
                    tableHeader={tableHeader}
                    tableData={[]}
                    isSerialNo={true}
                    isCheckBox={true}
                    tableDataList={[]}
                    loading={false}
                    isempty={false}
                    id={"dashboard-TableList"}
                  /> */}
                </Paper>
              </Div>
              <Div style={{ textAlign: "center" }}>
                <Button
                  style={containedButton}
                  onClick={() => selectPayBills()}
                  id={"dashboard-selectPayBills-btn"}
                >
                  Pay Selected Bills
                </Button>
              </Div>
            </Div>
          </React.Fragment>
        )}
        <ModelComponent
          hiddencloseIcon={true}
          open={state.open}
          onClose={openView}
          childrens={<></>//FIXME - 
            // <ConfirmBook
            //   appointmentId={state.appointmentId}
            //   appointmentDetails={true}
            //   hidden={true}
            // />
          }
          width={530}
          inLineStyles={{ width: "530px" }}
          id={"dashboard-ModelComponent"}
        />
        <SideMenu
          open={cancelbooking}
          direction="right"
          width={435}
          color="default"
          onClose={() => handlesidebarCancelbooking("closing")}
          id={"dashboard-SideMenu"}
        >
          {/* <CancelBookingPage
            cancelbookingreason={cancelbookingreason}
            cancelbookings={cancelbookings}
            appointmentId={state.appointmentId}
            handleSelectCancelbooking={handleSelectCancelbooking}
            handlecahngeCancelbooking={handlecahngeCancelbooking}
            id={"dashboard-SideMenu-CancelBookingPage"}
          /> */}
        </SideMenu>
        <SideMenu
          open={sidebar}
          direction="right"
          width={445}
          color="default"
          onClose={() => toggleSideBar()}
          id={"dashboard-SideMenu-toggleSideBar"}
        >
          {state.searchMenber ? (
            <AddMember
              parent_id={'dashboad'}
              name={"Add Member"}
              nextSection={(member, v, l) => nextSection(member, v, l)}
              handelChangeSelect={(v) => handelChangeSelect(v)}
              signUpData={state.signUpData}
              chooseSearch={state.chooseSearch}
              addMember
              memberIds={state.memberIds}
            />
          ) : state.memberForm ? (
            <Addmemberform
              parent_id={'dashboad'}
              addNewMember
              changeSideBar={changeSideBar}
              signUpData={state.signUpData}
              nextSection={(member, signUpInfo) =>
                nextSection(member, signUpInfo)
              }
            />
          ) : state.memberOtp ? (
            <Addmemberotp
              parent_id={'dashboad'}
              addmmber={state.addmmber}
              nextSection={(member, v) => nextSection(member, v)}
              signUpData={state.signUpData}
              updateMember={changeMembers}
              appointmentDetails={true}
            />
          ) : null}
          {state.memberSuccessPage ? (
            <AppointmentAddMemberSuccessPage
              parent_id={'dashboad'}
              addmmber={state.addmmber}
              memeberIds={state.memberIds}
              signUpData={state.signUpData}
              nextSection={(member, v, l) => nextSection(member, v, l)}
              toggleSideBar={(member) => toggleSideBar(member)}
            />
          ) : null}
        </SideMenu>
      </Div>
    );
  }



export default PatientDashBoard
