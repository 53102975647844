import React from "react";
import { Upload, Div, H6, Text, Divider, Image, Icons } from "qdm-component-library";
import { ToBase64 } from "../../utils";
import FileViewer from 'react-file-viewer';
import { Grid, Typography, Dialog, makeStyles } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import FileUpload from '../../assets/img/svg/icons8-file.svg';
import viewIcon from '../../assets/img/svg/icons8-eye.svg';
import trashIcon from "../../assets/img/svg/icons8-trash1.svg"
const useStyles = makeStyles(theme => ({
    dialogPaper: {
        backgroundColor: "transparent",
    },
    dialogRoot:{
        "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0,0,0,0.9)"
          },
        zIndex:"999999 !important" 
    }
    
}));

function UploadFiles(props) {
    const classes =  useStyles();
    const { uploadDocument } = props;
    const [images, setImages] = React.useState([])
    const [viewimages, setviewImages] = React.useState([])
    const [state, setState] = React.useState({
        openFile: null,
        viewFileName: null,
        fileType: null,
        open: false
    })

    React.useEffect(() => {
        setviewImages(props.viewfiles);
    }, [props.viewfiles])
    React.useEffect(() => {
        setImages(props?.files)
    }, [props.files])

    const [, forceUpdate] = React.useReducer(x => x + 1, 0);
    const uploadFun = (e, data) => {
        let list = images ? images : [];
        list.push(data)
        setImages(list)
        uploadDocument(list,viewimages)
        forceUpdate()
    }
    const getSize = (size) => {
        let fileSize = size / 1024 / 1024;
        if (fileSize < 1) {
            return `${parseInt(size / 1024) + 1} KB`
        } else {
            return `${parseInt(fileSize)} MB`
        }
    }
    const deleteFile = (index) => {
        let list = images;
        list.splice(index, 1);
        setImages(list)
        uploadDocument(list,viewimages)
        forceUpdate()
    }
    const deleteFile2 = (val,index) => {
        let list = [...viewimages]; 
        let tempObj = list[index];
        tempObj.is_deleted = true;
        list.splice(index, 1);
        list.splice(index, 0, tempObj);
        uploadDocument(images,list)
        setviewImages(list)
        forceUpdate()
        // let list = viewimages;
        // //list.splice(index, 1);
        // let tempObj = list[index]
        // list.splice(index, 1);
        // tempObj.is_deleted = true
        // list.push(tempObj)
    }
    const handleClose = () => {
        setState({
            openFile: null,
            fileType: null,
            open: false
        })
        forceUpdate()
    }

    const createFile = async (url, name, type) => {
        let response = await fetch(url);
        let data = await response.blob();
        let metadata = {
          type,
        };
        const file = new File([data], name, metadata);
        return {
            file,
            size: data.size,
        }
    }
      

    const viewFile = async (file, idx) => {
        let data = ""
        if (file.fileUrl) {
            const {
                file: fileData,
                size,
            } = await createFile(file.fileUrl, file.name, file.type);
            const img = [...images];
            img.forEach((file, i) => {
                if (i === idx) {
                    file.size = size;
                }
            })
            setImages(img);
            data = await ToBase64(fileData);
        } else {
            data = await ToBase64(file);
        }
        setState({
            openFile: data,
            viewFileName: file.name,
            fileType: file.type.split('/')[1],
            open: true
        })
        forceUpdate()
    }
    const viewFile2 = async (file, idx) => {
        let data = ""
        if (file?.url) {
            const {
                file: fileData,
                size,
            } = await createFile(file?.url, file?.fileName, file?.filetype);
            const img = [...viewimages];
            img.forEach((file, i) => {
                if (i === idx) {
                    const newFile = { ...file, size };
                    img[i] = newFile;
                }
            })
            // img.forEach((file, i) => {
            //     if (i === idx) {
            //         file.size = size;
            //     }
            // })
            setviewImages(img);
            data = await ToBase64(fileData);
        } 
        setState({
            openFile: data,
            viewFileName: file?.fileName,
            fileType: file?.filetype,
            open: true
        })
        forceUpdate()
    }
    return <React.Fragment>
        {/* File Upload Div */}
        <Div className={"up-parent"}>
            {props?.isViewMode ? (
                <>
                <Typography  style={{
                                fontSize:"14px",
                                fontWeight:"bold",
                                padding:"8px"}} className={"up-btn"}>
                Uploaded Documents
                </Typography>
                <Divider style={{ margin: '0px 0px',padding:"1px" }} />
                {viewimages.length < 1 && ( 
                    <Typography
                    style={{ textAlign: "center", fontSize: "15px", padding:"10px" }}
                    >
                        No Data Found
                    </Typography>

                )}
                </>
            ) : (
                <Upload
                    handleChange={uploadFun}
                    label="Upload Documents"
                    labelStyle={{ fontSize: 14, fontFamily: 'pc_medium !important' }}
                    className={"up-btn"}
                    showIcon={false}
                />
            )}
            

        </Div>
        {viewimages?.map((val, i) => <React.Fragment>
            {props?.isViewMode && i !== 0 && <Divider style={{ margin: '0px 10px' }} />}
            {!val?.is_deleted && (
                <Div className="up-list-parent">
                <Div style={{ width: 22, height: 22 }}>
                    <Image src={FileUpload} alt="file" />
                </Div>
                <Div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    marginLeft: 10
                }}>
                    <H6 className="up-title">{val?.fileName}</H6>
                    {
                        val?.size !== 0
                        && <Text className="up-size">{val?.size ? getSize(val?.size) : ""}</Text>
                    }
                </Div>
                <Div style={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <Image onClick={() => viewFile2(val, i)} src={viewIcon} alt="view" style={{ cursor: 'pointer', marginRight: 16 }} />
                    {!props?.isViewMode && <Image src={trashIcon} onClick={() => deleteFile2(val,i)} alt="view" style={{ cursor: 'pointer' }} />}
                </Div>
            </Div>
            )}
            
            <Dialog
                open={state.open}
                onClose={handleClose}
                className={classes.dialogRoot}
                id="UMHit_13"
                fullScreen 
                style={{ zIndex: "10000000" }}
                PaperProps={{ classes: {root: classes.dialogPaper } }}
            >
                <div style={{ width: "100%", height: "100vh",margin:"auto" }}>
                    <Grid container style={{ background: "transparent" }}>
                        <Grid item style={{ width: "50%" }}>
                            <Grid>
                                <Typography id="UMHit_14" style={{ color: "black", marginTop: "9px",visibility:"hidden" }}>{state.viewFileName}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: "50%", paddingRight: "30px",paddingTop:"16px" }}>
                                <div onClick={() => handleClose()}>
                                    <Typography
                                        style={{color:"#fff",cursor:"pointer",fontSize:"10px",fontWeight:600}}                                        
                                    >CLOSE X</Typography>
                                </div>
                        </Grid>
                    </Grid>
                    <Grid id="UMHit_17" className="openFile-parent">
                        <FileViewer id="UMHit_16" fileType={state.fileType} filePath={state.openFile} />
                    </Grid>
                </div>
            </Dialog>
        </React.Fragment>)}
        {images?.length>0 && <Divider style={{ margin: '0px 10px' }} />}
        {images?.map((val, i) => <React.Fragment>
            {i !== 0 && <Divider style={{ margin: '0px 10px' }} />}
            <Div className="up-list-parent">
                <Div style={{ width: 22, height: 22 }}>
                    <Image src={FileUpload} alt="file" />
                </Div>
                <Div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    marginLeft: 10
                }}>
                    <H6 className="up-title">{val.name}</H6>
                    {
                        val.size !== 0
                        && <Text className="up-size">{getSize(val.size)}</Text>
                    }
                </Div>
                <Div style={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <Image onClick={() => viewFile(val, i)} src={viewIcon} alt="view" style={{ cursor: 'pointer', marginRight: 16 }} />
                    <Image src={trashIcon} onClick={() => deleteFile(i)} alt="view" style={{ cursor: 'pointer' }} />
                </Div>
            </Div>
            <Dialog
                open={state.open}
                onClose={handleClose}
                className={classes.dialogRoot}
                id="UMHit_13"
                fullScreen 
                style={{ zIndex: "10000000" }}
                PaperProps={{ classes: {root: classes.dialogPaper } }}
            >
                <div style={{ width: "100%", height: "100vh",margin:"auto" }}>
                    <Grid container style={{ background: "transparent" }}>
                        <Grid item style={{ width: "50%" }}>
                            <Grid>
                                <Typography id="UMHit_14" style={{ color: "black", marginTop: "9px",visibility:"hidden" }}>{state.viewFileName}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: "50%", paddingRight: "30px",paddingTop:"16px" }}>
                                <div onClick={() => handleClose()}>
                                    <Typography
                                        style={{color:"#fff",cursor:"pointer",fontSize:"10px",fontWeight:600}}                                        
                                    >CLOSE X</Typography>
                                </div>
                        </Grid>
                    </Grid>
                    <Grid id="UMHit_17" className="openFile-parent">
                        <FileViewer id="UMHit_16" fileType={state.fileType} filePath={state.openFile} />
                    </Grid>
                </div>
            </Dialog>
        </React.Fragment>)}

    </React.Fragment>
}

export default UploadFiles;
