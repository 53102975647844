import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  defaultReject,
  defaultState,
  fetchData,
  urls,
  dbName,
  query_ids,
} from "../../utils";

const SAMPLE_COLLECTED = createAsyncThunk(
  `DashboardSlice/sampleCollected`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        filter: {
          practitionerid: payload || "Practitioner/10341",
          tenantid: "",
          facilityid: "",
        },
        queryid: "dbd7cc02-f9c5-4121-b5b2-dac2e6c66a49",
      };

      let data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      data = data.map((val) => {
        return { value: val.name, label: val._id };
      });
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const REGISTERED_BY = createAsyncThunk(
  `DashboardSlice/registeredby`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        filter: {
          orgid: payload,
        },
        queryid: "19d9ce08-a183-4f60-9fcc-4992bff9a8c1",
      };

      let data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      data = data.map((val) => {
        return { value: val.name, label: val._id };
      });
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const ADMINISTRATED_BY = createAsyncThunk(
  `DashboardSlice/administratedby`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        filter: {
          orgid: payload,
        },
        queryid: "64d81678-fd51-40a9-afad-8e483b6be56c",
      };

      let data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      data = data.map((val) => {
        return { value: val.name, label: val._id };
      });
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const BRANCH_CODE = createAsyncThunk(
  `DashboardSlice/branchCode`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        filter: {
          orgid: payload,
        },
        queryid: "83fb1f96-8e94-44ba-ac77-b17c0c694cb8",
      };

      let data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      data = data.map((val) => {
        return { value: val.name, label: val._id, code: val?.entitycode };
      });
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const SAVE_ORDER_SAMPLE = createAsyncThunk(
  `DashboardSlice/saveOrderSample`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        {
          body: JSON.stringify(payload),
        },
        urls.saveOrderSample
      );

      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const SAVE_ORDER_STATEINFO = createAsyncThunk(
  `DashboardSlice/saveOrderStateInfo`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        {
          body: JSON.stringify(payload),
        },
        urls.saveOrderStateInfo
      );

      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const Pic_Review_Sheet_Practitioner_DropDown = createAsyncThunk(
  `DashboardSlice/picReviewSheetFacilityDropDown`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { orgid = "" } = payload;

      const body = {
        db_name: dbName,
        filter: {
          orgid: orgid,
        },

        queryid: "2d237e1c-1432-453d-9bdf-b22e40aeac61",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const PANEL_REPORT_FACILITY_DROPDOWN = createAsyncThunk(
  `DashboardSlice/panelreportfacilitydropdown`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { facilityid = "", } = payload;

      const body = {
        db_name: dbName,
        filter: {
          facilityid: [facilityid],
          tenantid: "1234"
        },

        queryid: "056516ca-0552-4a9c-9e0f-8fd8b983cc86",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const PANEL_REPORT_PAYER_DROPDOWN = createAsyncThunk(
  `DashboardSlice/panelreportpayerdropdown`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { facilityid = "" } = payload;

      const body = {
        db_name: dbName,
        filter: {
          facilityid: [facilityid],
        },

        queryid: "57aa68c2-688c-4b23-a69c-c8e11686b261",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const PANEL_REPORT_PRACTITIONER_DROPDOWN = createAsyncThunk(
  `DashboardSlice/panelreportpractdropdown`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { facilityid = "" } = payload;

      const body = {
        db_name: dbName,
        filter: {
          facilityid: [facilityid],
        },

        queryid: "29668753-e82b-4f72-8b10-1ba176a516a9",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const PANEL_REPORT_PATIENT_DROPDOWN = createAsyncThunk(
  `billingDetailsMastersSlice/panelpatientdropdown`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { facilityid = "", payerid = "", start = "" } = payload;

      const body = {
        facilityid: [facilityid],
        start: start,
        payerid: [payerid]
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.panelreports
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const CASHCOLLECTION_PRACTITIONER_DROPDOWN = createAsyncThunk(
  `billingDetailsMastersSlice/cashcollection_dropdown`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { facilityid = [] } = payload;

      const body = {
        db_name: dbName,
        filter: {
          facilityid: facilityid,
        },
        queryid: "d6308049-ecf1-47d5-9c34-d6938c33e9b5",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const CASHCOLLECTION_FACILITY_DROPDOWN = createAsyncThunk(
  `billingDetailsMastersSlice/cashcollection_facility_dropdown`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { tenantid = "" } = payload;

      const body = {
        db_name: dbName,
        filter: {
          tenantid: tenantid,
        },
        queryid: "61a7c36e-2aaa-42b9-8575-4da2d34f3f7c",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const PREV_ENC_CHECK = createAsyncThunk(
  `DashboardSlice/prevenccheck`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { patientId = "", start = 0, end = 0 } = payload;

      const body = {
        db_name: dbName,
        filter: {
          patientid: patientId,
          startdate: start,
          enddate: end
        },

        queryid: "a7c1fd8a-8824-4b67-9a12-7582581198ec",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const OBSEVATION_REPORT_PATIENT_DROPDOWN = createAsyncThunk(
  `billingDetailsMastersSlice/observationpatientdropdown`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { facilityid = "", enddate = "", startdate = "" } = payload;

      const body = {
        filter: {
          facilityid: facilityid,
          startdate: startdate,
          enddate: enddate
        },
        queryid: "77464263-c2cb-49b4-8860-98f013751672",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.getReportQuery
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const MONTHWISE_ATTENDANCE_PATIENT_DROPDOWN = createAsyncThunk(
  `billingDetailsMastersSlice/monthwisepatientdropdown`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { facilityid = "", startOfMonth = "" } = payload;

      const body = {
        facilityid: facilityid,
        startOfMonth: startOfMonth,
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.patientLoadMonthlyAttendance
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const REPORTS_FACILITY_DROPDOWN = createAsyncThunk(
  `billingDetailsMastersSlice/reports_facility_dropdown`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { facilityid = "", tenantid = "" } = payload;

      const body = {
        db_name: dbName,
        filter: {
          facilityid: [facilityid],
          tenantid: tenantid,
        },
        queryid: "056516ca-0552-4a9c-9e0f-8fd8b983cc86",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const BILL_CANCEL_REPORT_PATIENTID = createAsyncThunk(
  `billingDetailsMastersSlice/billcancelreport_patientid`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { facilityid = "", startdate = "", enddate = "" } = payload;

      const body = {
        db_name: dbName,
        filter: {
          startdate: startdate,
          enddate: enddate,
          facilityid: facilityid,
          searchname: "",
        },
        queryid: "ed6274d4-080b-46a1-83e9-1620a2896984",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const RECEIPT_CANCEL_REPORT_PATIENTID = createAsyncThunk(
  `billingDetailsMastersSlice/receiptcancelreport_patientid`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { facilityid = "", startdate = "", enddate = "" } = payload;

      const body = {
        db_name: dbName,
        filter: {
          startdate: startdate,
          enddate: enddate,
          facilityid: facilityid,
          searchname: "",
        },
        queryid: "7bec4dbf-b688-49d0-bca9-a37018029c2a",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const REFUND_GET_DETAILS = createAsyncThunk(
  `billingDetailsMastersSlice/refundGetDetails`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { refundId = "", statesid = "" } = payload;

      const body = {
        db_name: dbName,
        filter: {
          refundid: refundId,
        },
        queryid:statesid === process.env.REACT_APP_REFUND_REJECTED_STATE_ID
            ? query_ids?.getRefundRejectReason
            : query_ids?.getRefundCancelReason,
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const FIRST_PASS_API = createAsyncThunk(
  `DashboardSlice/firstPassAPI`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { actionType = "", servingType = "", appId = "" } = payload;
      const body = {
        actionType: actionType,
        appId: appId,
        servingType: servingType
      };

      let data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        process.env.REACT_APP_FIRST_PASS_REGISTER
      );
      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const GET_QUEUESTATENAME_BASED_ACTIONID = createAsyncThunk(
  `DashboardSlice/getQueueStateNameBasedActionId`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { ticketId= ""} = payload;
      const body = {
        db_name: dbName,
        filter: {
          ticketid: ticketId,
        },
        queryid: "53842b04-1a35-4a11-81e5-319d7cd5baac",
      };

      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      // data = data.map((val) => {
      //   return { value: val.name, label: val._id };
      // });
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const Dashboard_Slice = createSlice({
  name: "DashboardSlice",
  initialState: {
    sampleCollected: {
      ...defaultState.List,
    },
    registeredby: {
      ...defaultState.List,
    },
    administratedby: {
      ...defaultState.List,
    },
    branchCode: {
      ...defaultState.List,
    },
    saveOrderSample: {
      ...defaultState.Info,
    },
    saveOrderStateInfo: {
      ...defaultState.Info,
    },
    picReviewSheetFacilityDropDown: {
      ...defaultState.Info,
    },
    panelreportfacilitydropdown: {
      ...defaultState.Info,
    },
    panelreportpayerdropdown: {
      ...defaultState.Info,
    },
    panelreportpractdropdown: {
      ...defaultState.Info,
    },
    panelpatientdropdown: {
      ...defaultState.Info,
    },
    cashcollection_dropdown: {
      ...defaultState.Info,
    },
    cashcollection_facility_dropdown: {
      ...defaultState.Info,
    },
    prevenccheck: {
      ...defaultState.Info,
    },
    observationpatientdropdown: {
      ...defaultState.Info,
    },
    monthwisepatientdropdown: {
      ...defaultState.Info,
    },
    reports_facility_dropdown: {
      ...defaultState.Info,
    },
    billcancelreport_patientid: {
      ...defaultState.Info,
    },
    receiptcancelreport_patientid: {
      ...defaultState.Info,
    },
    refundGetDetails: {
      ...defaultState.Info,
    },
    firstPassAPI: {
      ...defaultState.Info,
    },
    getQueueStateNameBasedActionId: {
      ...defaultState.Info,
    },
  },
  extraReducers: {
    //sampleCollected
    [SAMPLE_COLLECTED.fulfilled]: (state, action) => {
      state.sampleCollected = action?.payload ?? {};
    },
    [SAMPLE_COLLECTED.pending]: (state, action) => {
      state.sampleCollected.loading = true;
    },
    [SAMPLE_COLLECTED.rejected]: (state, action) => {
      state.sampleCollected = action?.payload ?? {};
    },

    //registeredby

    [REGISTERED_BY.fulfilled]: (state, action) => {
      state.registeredby = action?.payload ?? {};
    },
    [REGISTERED_BY.pending]: (state, action) => {
      state.registeredby.loading = true;
    },
    [REGISTERED_BY.rejected]: (state, action) => {
      state.registeredby = action?.payload ?? {};
    },

    //administratedby

    [ADMINISTRATED_BY.fulfilled]: (state, action) => {
      state.administratedby = action?.payload ?? {};
    },
    [ADMINISTRATED_BY.pending]: (state, action) => {
      state.administratedby.loading = true;
    },
    [ADMINISTRATED_BY.rejected]: (state, action) => {
      state.administratedby = action?.payload ?? {};
    },

    //branchCode

    [BRANCH_CODE.fulfilled]: (state, action) => {
      state.branchCode = action?.payload ?? {};
    },
    [BRANCH_CODE.pending]: (state, action) => {
      state.branchCode.loading = true;
    },
    [BRANCH_CODE.rejected]: (state, action) => {
      state.branchCode = action?.payload ?? {};
    },
    //saveOrderSample
    [SAVE_ORDER_SAMPLE.fulfilled]: (state, action) => {
      state.saveOrderSample = action?.payload ?? {};
    },
    [SAVE_ORDER_SAMPLE.pending]: (state, action) => {
      state.saveOrderSample.loading = true;
    },
    [SAVE_ORDER_SAMPLE.rejected]: (state, action) => {
      state.saveOrderSample = action?.payload ?? {};
    },
    //saveOrderStateInfo
    [SAVE_ORDER_STATEINFO.fulfilled]: (state, action) => {
      state.saveOrderStateInfo = action?.payload ?? {};
    },
    [SAVE_ORDER_STATEINFO.pending]: (state, action) => {
      state.saveOrderStateInfo.loading = true;
    },
    [SAVE_ORDER_STATEINFO.rejected]: (state, action) => {
      state.saveOrderStateInfo = action?.payload ?? {};
    },
    //picReviewSheetFacilityDropDown
    [Pic_Review_Sheet_Practitioner_DropDown.fulfilled]: (state, action) => {
      state.picReviewSheetFacilityDropDown = action?.payload ?? {};
    },
    [Pic_Review_Sheet_Practitioner_DropDown.pending]: (state, action) => {
      state.picReviewSheetFacilityDropDown.loading = true;
    },
    [Pic_Review_Sheet_Practitioner_DropDown.rejected]: (state, action) => {
      state.picReviewSheetFacilityDropDown = action?.payload ?? {};
    },
    //Panel Report Facility dropdown
    [PANEL_REPORT_FACILITY_DROPDOWN.fulfilled]: (state, action) => {
      state.panelreportfacilitydropdown = action?.payload ?? {};
    },
    [PANEL_REPORT_FACILITY_DROPDOWN.pending]: (state, action) => {
      state.panelreportfacilitydropdown.loading = true;
    },
    [PANEL_REPORT_FACILITY_DROPDOWN.rejected]: (state, action) => {
      state.panelreportfacilitydropdown = action?.payload ?? {};
    },
    //Panel Report Payer dropdown
    [PANEL_REPORT_PAYER_DROPDOWN.fulfilled]: (state, action) => {
      state.panelreportpayerdropdown = action?.payload ?? {};
    },
    [PANEL_REPORT_PAYER_DROPDOWN.pending]: (state, action) => {
      state.panelreportpayerdropdown.loading = true;
    },
    [PANEL_REPORT_PAYER_DROPDOWN.rejected]: (state, action) => {
      state.panelreportpayerdropdown = action?.payload ?? {};
    },
    //Panel Report Practitioner dropdown
    [PANEL_REPORT_PRACTITIONER_DROPDOWN.fulfilled]: (state, action) => {
      state.panelreportpractdropdown = action?.payload ?? {};
    },
    [PANEL_REPORT_PRACTITIONER_DROPDOWN.pending]: (state, action) => {
      state.panelreportpractdropdown.loading = true;
    },
    [PANEL_REPORT_PRACTITIONER_DROPDOWN.rejected]: (state, action) => {
      state.panelreportpractdropdown = action?.payload ?? {};
    },
    //Panel Report Patient dropdown
    [PANEL_REPORT_PATIENT_DROPDOWN.fulfilled]: (state, action) => {
      state.panelpatientdropdown = action?.payload ?? {};
    },
    [PANEL_REPORT_PATIENT_DROPDOWN.pending]: (state, action) => {
      state.panelpatientdropdown.loading = true;
    },
    [PANEL_REPORT_PATIENT_DROPDOWN.rejected]: (state, action) => {
      state.panelpatientdropdown = action?.payload ?? {};
    },
    //Cash Collection Practitioner dropdown
    [CASHCOLLECTION_PRACTITIONER_DROPDOWN.fulfilled]: (state, action) => {
      state.cashcollection_dropdown = action?.payload ?? {};
    },
    [CASHCOLLECTION_PRACTITIONER_DROPDOWN.pending]: (state, action) => {
      state.cashcollection_dropdown.loading = true;
    },
    [CASHCOLLECTION_PRACTITIONER_DROPDOWN.rejected]: (state, action) => {
      state.cashcollection_dropdown = action?.payload ?? {};
    },
    //Cash Collection Facility dropdown
    [CASHCOLLECTION_FACILITY_DROPDOWN.fulfilled]: (state, action) => {
      state.cashcollection_facility_dropdown = action?.payload ?? {};
    },
    [CASHCOLLECTION_FACILITY_DROPDOWN.pending]: (state, action) => {
      state.cashcollection_facility_dropdown.loading = true;
    },
    [CASHCOLLECTION_FACILITY_DROPDOWN.rejected]: (state, action) => {
      state.cashcollection_facility_dropdown = action?.payload ?? {};
    },
    //prev enc check
    [PREV_ENC_CHECK.fulfilled]: (state, action) => {
      state.prevenccheck = action?.payload ?? {};
    },
    [PREV_ENC_CHECK.pending]: (state, action) => {
      state.prevenccheck.loading = true;
    },
    [PREV_ENC_CHECK.rejected]: (state, action) => {
      state.prevenccheck = action?.payload ?? {};
    },
    //Observation Monthly Patient dropdown
    [OBSEVATION_REPORT_PATIENT_DROPDOWN.fulfilled]: (state, action) => {
      state.observationpatientdropdown = action?.payload ?? {};
    },
    [OBSEVATION_REPORT_PATIENT_DROPDOWN.pending]: (state, action) => {
      state.observationpatientdropdown.loading = true;
    },
    [OBSEVATION_REPORT_PATIENT_DROPDOWN.rejected]: (state, action) => {
      state.observationpatientdropdown = action?.payload ?? {};
    },
    //Monthwise Attendance patient dropdown
    [MONTHWISE_ATTENDANCE_PATIENT_DROPDOWN.fulfilled]: (state, action) => {
      state.monthwisepatientdropdown = action?.payload ?? {};
    },
    [MONTHWISE_ATTENDANCE_PATIENT_DROPDOWN.pending]: (state, action) => {
      state.monthwisepatientdropdown.loading = true;
    },
    [MONTHWISE_ATTENDANCE_PATIENT_DROPDOWN.rejected]: (state, action) => {
      state.monthwisepatientdropdown = action?.payload ?? {};
    },
    //Reports Facility dropdown
    [REPORTS_FACILITY_DROPDOWN.fulfilled]: (state, action) => {
      state.reports_facility_dropdown = action?.payload ?? {};
    },
    [REPORTS_FACILITY_DROPDOWN.pending]: (state, action) => {
      state.reports_facility_dropdown.loading = true;
    },
    [REPORTS_FACILITY_DROPDOWN.rejected]: (state, action) => {
      state.reports_facility_dropdown = action?.payload ?? {};
    },
    //Bill Cancel Report Patient ID
    [BILL_CANCEL_REPORT_PATIENTID.fulfilled]: (state, action) => {
      state.billcancelreport_patientid = action?.payload ?? {};
    },
    [BILL_CANCEL_REPORT_PATIENTID.pending]: (state, action) => {
      state.billcancelreport_patientid.loading = true;
    },
    [BILL_CANCEL_REPORT_PATIENTID.rejected]: (state, action) => {
      state.billcancelreport_patientid = action?.payload ?? {};
    },
    //Receipt Cancel Report Patient ID
    [RECEIPT_CANCEL_REPORT_PATIENTID.fulfilled]: (state, action) => {
      state.receiptcancelreport_patientid = action?.payload ?? {};
    },
    [RECEIPT_CANCEL_REPORT_PATIENTID.pending]: (state, action) => {
      state.receiptcancelreport_patientid.loading = true;
    },
    [RECEIPT_CANCEL_REPORT_PATIENTID.rejected]: (state, action) => {
      state.receiptcancelreport_patientid = action?.payload ?? {};
    },
    //REFUND_GET_DETAILS
    [REFUND_GET_DETAILS.fulfilled]: (state, action) => {
      state.refundGetDetails = action?.payload ?? {};
    },
    [REFUND_GET_DETAILS.pending]: (state, action) => {
      state.refundGetDetails.loading = true;
    },
    [REFUND_GET_DETAILS.rejected]: (state, action) => {
      state.refundGetDetails = action?.payload ?? {};
    },
    //Observation Monthly Patient dropdown
    [OBSEVATION_REPORT_PATIENT_DROPDOWN.fulfilled]: (state, action) => {
      state.observationpatientdropdown = action?.payload ?? {};
    },
    [OBSEVATION_REPORT_PATIENT_DROPDOWN.pending]: (state, action) => {
      state.observationpatientdropdown.loading = true;
    },
    [OBSEVATION_REPORT_PATIENT_DROPDOWN.rejected]: (state, action) => {
      state.observationpatientdropdown = action?.payload ?? {};
    },
    //Monthwise Attendance patient dropdown
    [MONTHWISE_ATTENDANCE_PATIENT_DROPDOWN.fulfilled]: (state, action) => {
      state.monthwisepatientdropdown = action?.payload ?? {};
    },
    [MONTHWISE_ATTENDANCE_PATIENT_DROPDOWN.pending]: (state, action) => {
      state.monthwisepatientdropdown.loading = true;
    },
    [MONTHWISE_ATTENDANCE_PATIENT_DROPDOWN.rejected]: (state, action) => {
      state.monthwisepatientdropdown = action?.payload ?? {};
    },
    //FIRST_PASS_API
    [FIRST_PASS_API.fulfilled]: (state, action) => {
      state.firstPassAPI = action?.payload ?? {};
    },
    [FIRST_PASS_API.pending]: (state, action) => {
      state.firstPassAPI.loading = true;
    },
    [FIRST_PASS_API.rejected]: (state, action) => {
      state.firstPassAPI = action?.payload ?? {};
    },
    //GET_QUEUESTATENAME_BASED_ACTIONID
    [GET_QUEUESTATENAME_BASED_ACTIONID.fulfilled]: (state, action) => {
      state.getQueueStateNameBasedActionId = action?.payload ?? {};
    },
    [GET_QUEUESTATENAME_BASED_ACTIONID.pending]: (state, action) => {
      state.getQueueStateNameBasedActionId.loading = true;
    },
    [GET_QUEUESTATENAME_BASED_ACTIONID.rejected]: (state, action) => {
      state.getQueueStateNameBasedActionId = action?.payload ?? {};
    },
  },
});

export const DashboardActions = {
  SAMPLE_COLLECTED,
  REGISTERED_BY,
  ADMINISTRATED_BY,
  BRANCH_CODE,
  SAVE_ORDER_SAMPLE,
  SAVE_ORDER_STATEINFO,
  Pic_Review_Sheet_Practitioner_DropDown,
  PANEL_REPORT_FACILITY_DROPDOWN,
  PANEL_REPORT_PAYER_DROPDOWN,
  PANEL_REPORT_PRACTITIONER_DROPDOWN,
  PANEL_REPORT_PATIENT_DROPDOWN,
  CASHCOLLECTION_PRACTITIONER_DROPDOWN,
  CASHCOLLECTION_FACILITY_DROPDOWN,
  PREV_ENC_CHECK,
  OBSEVATION_REPORT_PATIENT_DROPDOWN,
  MONTHWISE_ATTENDANCE_PATIENT_DROPDOWN,
  REPORTS_FACILITY_DROPDOWN,
  BILL_CANCEL_REPORT_PATIENTID,
  RECEIPT_CANCEL_REPORT_PATIENTID,
  REFUND_GET_DETAILS,
  FIRST_PASS_API,
  GET_QUEUESTATENAME_BASED_ACTIONID,
};
export default Dashboard_Slice;
