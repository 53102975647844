import { useEffect, useState, useContext } from "react";
import { Div, Paper, H6, Text } from "qdm-component-library";
import { Button } from "@material-ui/core";
import {
  PatientIdentification,
  PatientNames,
  Address,
  Communication,
  ContactPoint,
} from "./components";
import { useLocation } from "react-router-dom";
import { getImgUrl, mobileNumberValidation } from "../../utils";
import styles from "./styles";
import ShowList from "./components/showList";
import { AlertContext } from "../../context";
import { AlertProps } from "../../utils";
import moment from "moment";

const NextOfKinDetails = (props) => {
  const [city, setCity] = useState({});
  const [statusData, setStatusData] = useState({});
  const [patientState, setPatientState] = useState({});
  const [Nas, setNas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btnName, setBtnName] = useState(false);
  const [personInfo, setPersonInfo] = useState({});
  const [kadOpen, setKadOpen] = useState(false);
  const [isPersonUpdate, setIsPersonUpdate] = useState(false);
  const { setSnack } = useContext(AlertContext);
  const [state, setState] = useState(false);
  const [edit, setEdit] = useState(false);
  const [LoadContact, setLoadContact] = useState(false);
  const [containerStyle, setContainerStyle] = useState({
    overflow: "auto",
    padding: "5px 15px",
    display:  "block",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100vh - 128px)"
  });

  const location = useLocation();
  useEffect(() => {}, [props.clear]);

  useEffect(() => {}, [state]);

  // const FlterOptions = (type) => {
  //   const { allMasters } = props;
  //   var lista = [];
  //   const data = allMasters?.[type];
  //   for (let index in data) {
  //     var datatemp = {
  //       ...data[index],
  //       value: data[index]?.coding?.display,
  //       label: data[index]?.coding?.display,
  //       _id: data[index]?.coding?._id,
  //       CodeableConcept_id: data[index]?._id,
  //     };
  //     lista.push(datatemp);
  //   }
  //   return lista;
  // };
  const handleAddNextOfKin = async () => {

    var errBooll;
    var errBooll1;
    const data = props?.nextOfKinData?.names?.map((v, i) => {
      if (
        props?.validateNames(i, props?.nextOfKinData, props?.setNextOfKinData)
      ) {
        return (errBooll = false);
      } else {
        return (errBooll = true);
      }
    });
    const data1 = props.nextOfKinData.identification?.map((v, i) => {
      if (
        props.validateIdentifications(
          i,
          props?.nextOfKinData,
          props?.setNextOfKinData
        )
      ) {
        return (errBooll1 = false);
      } else {
        return (errBooll1 = true);
      }
    });

    let errContact;
    let invalidContactObj = {};
    let invalidIndentiObj = {};

    props?.nextOfKinData.identification?.forEach((val, i) => {
      if (val["id_type"]?._id === process.env.REACT_APP_PATIENT_ID_TYPE_NRIC) {
        if (val["id_number"]?.length !== 14) {
          let data = { ...props?.nextOfKinData };
          let remainData = data.identification[i].error;
          data.identification[i].error = { ...remainData, id_number: true };
          props?.setNextOfKinData(data);
          invalidIndentiObj.invalidIdenti = true;
          invalidIndentiObj.respMsg = "length should be 14 for NRIC type";
          errBooll1 = true;
        } else {
          invalidIndentiObj.invalidIdenti = false;
          invalidIndentiObj.respMsg = "";
          errBooll1 = false;
        }
      }
    });
    const data3 = props?.nextOfKinData.contact?.map((val, i) => {
      let cc = val?.mobile_code?.value?.substring(1);
      if (val?.mode?.value === "Phone") {
        if (
          props?.validateContact(
            i,
            props?.nextOfKinData,
            props?.setNextOfKinData
          )
        ) {
          let resp = mobileNumberValidation(val?.mobile, cc);
          if (resp === "Invalid Country code") {
            return (
              (invalidContactObj.invalidContact = true),
              (invalidContactObj.respMsg = resp)
            );
          } else if (resp == false) {
            return (
              (invalidContactObj.invalidContact = true),
              (invalidContactObj.respMsg = "Please enter valid mobile number")
            );
          }
        } else {
          return (errContact = true);
        }
      }
    });
    const ValidateEmail = (email) => {
      return /\S+@\S+\.\S+/.test(email);
    };
    let isEmailValid;
    const data4 = props?.nextOfKinData?.contact.forEach((val, i) => {
      if (val.mode.value === "Email") {
        let email = val.mobile;
        if (ValidateEmail(email)) {
          return (isEmailValid = false);
        } else {
          return (isEmailValid = true);
        }
      }
    });
    const detsils = props?.validateDetails(
      null,
      props?.nextOfKinData,
      props?.setNextOfKinData
    );

    if (
      !errBooll &&
      !errBooll1 &&
      !errContact &&
      !invalidContactObj?.invalidContact &&
      !isEmailValid
    ) {
      if (props?.nextOfKinData?.index !== null) {
        let patientData = props?.nextOfKinData;
        patientData.addformdetails[patientData.index] = {
          identification: patientData.identification,
          names: patientData.names,
          details: patientData.details,
          contact: patientData.contact,
          address: patientData.address,
          _id: patientData?.addformdetails?.[patientData.index]?._id,
          _key: patientData?.addformdetails?.[patientData.index]?._key,
        };
        patientData.identification = [{}];
        patientData.names = [{}];
        patientData.details = {};
        patientData.contact = [];
        patientData.address = [];
        patientData.index = null;
        props?.setNextOfKinData({
          ...patientData,
        });
        setState(!state);
      } else {
        let patientData = props?.nextOfKinData;
        await patientData.addformdetails.push({
          identification: patientData.identification,
          names: patientData.names,
          details: patientData.details,
          contact: patientData.contact,
          address: patientData.address,
        });
        patientData.identification = [{}];
        patientData.names = [{}];
        patientData.details = {};
        // patientData.contact[0].mobile = "";
        patientData.contact = [
          {
            mode: {
              value: "Phone",
              _id: "CodingMaster/10473",
            },
          },
          // { mobile: "" },
          // {
          //   mode: {
          //     // value: phoneOpt?.value,
          //     // _id: phoneOpt?._id,
          //     value:"",
          //     _id:"",          },
          // },
        ];
        patientData.address = [];
        patientData.index = null;
        props?.setNextOfKinData({
          ...patientData,
        });
        setState(!state);
        setLoadContact(true);
        setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg:
            props?.nextOfKinData?.index !== null
              ? "Updated succsssfully"
              : "Added succsssfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      }
    } else if (invalidIndentiObj?.invalidIdenti) {
      setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: invalidIndentiObj?.respMsg,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    } else if (invalidContactObj?.invalidContact) {
      setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: invalidContactObj?.respMsg,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    } else if (isEmailValid) {
      setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Email is not valid [example : abc@gmail.com]",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    } else {
      setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all the mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
  };
  const onedit = (v, i) => {
    let patientData = { ...props?.nextOfKinData };
    patientData.identification = v.identification;
    patientData.names = v.names;
    patientData.details = v.details;
    patientData.contact = v.contact;
    patientData.address = v.address;
    patientData.index = i;
    patientData._id = v?._id;
    patientData._key = v?._key;
    props?.setNextOfKinData({
      ...patientData,
    });
    console.log(patientData, "edit");
    setEdit(!edit);
  };
  const ValidateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  const ondelete = (v, i) => {
    let patientData = props.nextOfKinData;
    patientData.addformdetails.splice(i, 1);
    props?.setNextOfKinData({
      ...patientData,
    });
    setSnack({
      open: true,
      severity: AlertProps.severity.success,
      msg: "Deleted succsssfully",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.right,
    });
  };
  const NavigateAdditonalDetails = () => {
    var errBooll;
    var errBooll1;
    let invalidContactObj = {};
    let isEmailValid;
    if (props?.nextOfKinData?.addformdetails?.length > 0) {
      props?.setActiveId(3);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setContainerStyle({
        ...containerStyle,
          overflow: "auto",
          padding: "5px 15px",
          display:  "block",
          alignItems: "center",
          justifyContent: "center",
          height: "calc(100vh - 128px)"
      });
      // const emailval = props?.nextOfKinData.contact.forEach((val, i) => {
      //   if (val?.mode?.value === "Email") {
      //     let email = val.mobile;
      //     if (ValidateEmail(email)) {
      //       isEmailValid = false;
      //     } else {
      //       isEmailValid = true;
      //     }
      //   }
      // });
      // const data = props?.nextOfKinData?.names?.map((v, i) => {
      //   if (
      //     props?.validateNames(i, props?.nextOfKinData, props?.setNextOfKinData)
      //   ) {
      //     return (errBooll = false);
      //   } else {
      //     return (errBooll = true);
      //   }
      // });
      // const data1 = props.nextOfKinData.identification?.map((v, i) => {
      //   if (
      //     props.validateIdentifications(
      //       i,
      //       props?.nextOfKinData,
      //       props?.setNextOfKinData
      //     )
      //   ) {
      //     return (errBooll1 = false);
      //   } else {
      //     return (errBooll1 = true);
      //   }
      // });
      // const contacts = props.nextOfKinData.contact?.map((v, i) => {
      //   if (v?.mode?.value === "Phone") {
      //     if (
      //       props.validateContact(
      //         i,
      //         props?.nextOfKinData,
      //         props?.setNextOfKinData
      //       )
      //     ) {
      //       let cc = v?.mobile_code?.value.substring(1);
      //       let resp = mobileNumberValidation(v?.mobile, cc);
      //       if (resp === "Invalid Country code") {
      //         invalidContactObj.invalidContact = true;
      //         invalidContactObj.respMsg = resp;
      //         errBooll = true;
      //       } else if (resp == false) {
      //         invalidContactObj.invalidContact = true;
      //         invalidContactObj.respMsg = "Please enter valid mobile number";
      //         errBooll = true;
      //       }
      //       return (errBooll1 = false);
      //     } else {
      //       return (errBooll1 = true);
      //     }
      //   }
      // });
      // const detsils = props.validateDetails(
      //   null,
      //   props?.nextOfKinData,
      //   props?.setNextOfKinData
      // );
      // if (!errBooll && !errBooll1) {
      //   props?.setActiveId(3);
      // } else if (invalidContactObj?.invalidContact) {
      //   setSnack({
      //     open: true,
      //     severity: AlertProps.severity.error,
      //     msg: invalidContactObj?.respMsg,
      //     vertical: AlertProps.vertical.top,
      //     horizontal: AlertProps.horizontal.right,
      //   });
      // } else if (isEmailValid) {
      //   setSnack({
      //     open: true,
      //     severity: AlertProps.severity.error,
      //     msg: "Email is not valid [example : abc@gmail.com]",
      //     vertical: AlertProps.vertical.top,
      //     horizontal: AlertProps.horizontal.center,
      //     tone: true,
      //   });
      // } else {
      //   setSnack({
      //     open: true,
      //     severity: AlertProps.severity.error,
      //     msg: "Please fill all the mandatory fields",
      //     vertical: AlertProps.vertical.top,
      //     horizontal: AlertProps.horizontal.center,
      //     tone: true,
      //   });
      // }
    } else {
      setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please add atleat one nextofkin details",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
  };
  useEffect(() => {
    return () => {
      setContainerStyle({
        overflow: "auto",
        padding: "5px 15px",
        display:  "block",
        alignItems: "center",
        justifyContent: "center",
        height: "calc(100vh - 128px)"
      });
    };
  }, [props?.activeId]);

  return (
    <Div id="nextofkin_parent_div" className="light-background-color"  style={containerStyle}>
      {props.nextOfKinData?.addformdetails?.length > 0 && (
        <Div id={`${props?.parent_id}_parent_div`} className="pi-root">
          <Paper id={`${props?.parent_id}_parent_paper`} className="pi-paper">
            <Div id={`${props?.parent_id}_addnew_div`}>
              <H6 id={`${props?.parent_id}_nextofkin_h6`} className="pi-title">
                Next Of Kin
              </H6>
              {/* <Text
            id={`${props?.parent_id}_addnew_text`}
            // onClick={(e) => OpenAddress(e)}
            className="pi-addbtn
            "
          >
            + Add New
          </Text> */}
            </Div>
            {props.nextOfKinData?.addformdetails?.map((v, i) => {
              console.log(v, "map");
              return (
                <Div
                  id={`${props?.parent_id}_${i}_showlist_div`}
                  className="pa-showList"
                >
                  <ShowList
                    parent_ids={`Register_Patinet_nextoffkin_${i}`}
                    index={i + 1}
                    title={v?.names?.[0]?.first_name}
                    description={
                      (v?.details?.gender?.value ?? "") +
                      (v?.details?.age
                        ? ", " +
                          v?.details?.age +
                          " " +
                          v?.details?.ageType?.value
                        : "") +
                      (v?.details?.nationality?.value
                        ? " | " + v?.details?.nationality?.value
                        : "")
                    }
                    idtype={v?.identification?.[0]?.id_type?.value}
                    idnumber={v?.identification?.[0]?.id_number}
                    relationship={v?.details?.relationship?.value}
                    occupation={v?.details?.occupation?.value}
                    contactmode={v?.contact?.[0]?.mode?.value}
                    countrycode={v?.contact?.[0]?.mobile_code?.value}
                    phone={v?.contact?.[0]?.mobile}
                    ondelete={() => ondelete(v, i)}
                    onedit={() => onedit(v, i)}
                    letter={v?.names?.[0]?.first_name}
                    isViewMode={props?.isViewMode}
                  />
                </Div>
              );
            })}
          </Paper>
        </Div>
      )}
      <PatientIdentification
        parent_id={
          props.parent_edit_id
            ? props.parent_edit_id
            : "Register_Patient_patientdetails"
        }
        allMasters={props.allMasters}
        patientData={props?.nextOfKinData}
        setPatientData={props?.setNextOfKinData}
        saveto={"nextOfKin"}
        setTo={"setNextOfKin"}
        parent_edit_id={props.parent_edit_id}
        City={city}
        clear={props.clear}
        states={state}
        editClick={edit}
        isModify={props?.isModify}
        isViewMode={props?.isViewMode}
      />

      <PatientNames
        parent_id={
          location?.state?.idedit
            ? "Edit_Patient_patientdetails"
            : "Register_Patient_patientdetails"
        }
        allMasters={props.allMasters}
        statusData={() => statusData}
        patientData={props?.nextOfKinData}
        setPatientData={props?.setNextOfKinData}
        parent_edit_id={location?.state?.idedit}
        mrn={(patientState?.alias ?? "") || (patientState?._id ?? "")}
        saveto={"nextOfKin"}
        setTo={"setNextOfKin"}
        Nas={Nas}
        City={city}
        personImg={
          personInfo?.photo?.[0]
            ? getImgUrl(personInfo?.photo?.[0]?.fileid)
            : ""
        }
        clear={props.clear}
        states={state}
        editClick={edit}
        isModify={props?.isModify}
        Nationality={props?.Nationality}
        isViewMode={props?.isViewMode}
      />

      <ContactPoint
        parent_id={"Register_Patient_nextofkindetails"}
        allMasters={props.allMasters}
        saveto={"nextOfKin"}
        setTo={"setNextOfKin"}
        Sbi={props?.Sbi}
        City={city}
        PersonUpdate={isPersonUpdate}
        contact={props?.contact}
        patientData={props?.nextOfKinData}
        setPatientData={props?.setNextOfKinData}
        clear={props.clear}
        states={state}
        editClick={edit}
        isModify={props?.isModify}
        isViewMode={props?.isViewMode}
        LoadContact={LoadContact}
      />

      <Address
        parent_id={"Register_Patient_nextofkindetails"}
        allMasters={props.allMasters}
        saveto={"nextOfKin"}
        setTo={"setNextOfKin"}
        City={props?.city}
        patientData={props?.nextOfKinData}
        setPatientData={props?.setNextOfKinData}
        clear={props.clear}
        states={state}
        editClick={edit}
        isModify={props?.isModify}
        isViewMode={props?.isViewMode}
        loadDefaultAddressNok={props?.loadDefaultAddressNok}
      />

      <Div
        id="Register_Patinet_nextofkin_button_div"
        className="p-continue-action"
      >
        <Button
          id="Register_Patinet_nextofkin_goback_button"
          className={styles.nextKingBtn}
          style={styles.outlinedButton}
          onClick={() => {
            props?.setActiveId(1);
            props?.setGoback(true);
          }}
        >
          Go Back
        </Button>
        {!props?.isViewMode && (
          <Button
            id="Register_Patinet_nextofkin_cancel_button"
            className={styles.nextKingBtn}
            style={styles.outlinedButton}
            // onClick={() => Cancel()}
            onClick={() => props.clearNextOfKinData()}
          >
            Clear
          </Button>
        )}
        <Button
          id="Register_Patinet_nextofkin_next_button"
          className={styles.nextBtn}
          style={styles.containedButton2}
          onClick={() => NavigateAdditonalDetails()}
        >
          Next
        </Button>
        {props?.isViewMode && (
          <Button
            id="Register_Patinet_nextofkin_add_button"
            className={styles.nextKingBtn}
            style={styles.containedButton}
            // onClick={() => handleAddNextOfKin("nextOfKin", "setNextOfKin")}
            onClick={() => props?.viewModeNavigateToFinace()}
          >
            Continue To Finance
          </Button>
        )}
        {!props?.isViewMode && (
          <Button
            id="Register_Patinet_nextofkin_add_button"
            className={styles.nextKingBtn}
            style={styles.containedButton}
            onClick={() => handleAddNextOfKin("nextOfKin", "setNextOfKin")}
          >
            + Add
          </Button>
        )}
      </Div>
    </Div>
  );
};

export default NextOfKinDetails;