import {
  Avatar,
  BackgroundImage,
  Button,
  Col,
  Container,
  Div,
  Divider,
  H6,
  Icons,
  Link,
  OtpInput,
  Paper,
  Row,
  SideMenu,
  Text,
} from "qdm-component-library";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../router/routes";
import { checkError, checkForLatestBuild, LocalStorageKeys } from "../../../utils";
import AddMember from "../../../components/global/addMember";
import Addmemberform from "../../../components/global/addmemberform";
import Addmemberotp from "../../../components/global/addmemberotp";
import Logo from "../../../assets/img/logo_B.svg";
import { UIColor } from "../../../themes/theme";
import BackgroundUrl from "../../../assets/img/backgroundImage.svg";
import { Typography } from "@material-ui/core";
import { AlertContext } from "../../../context";
import { useDispatch } from "react-redux";
import actions from "../../../redux/actions";
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";

function SignInOTP(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const alert = useContext(AlertContext);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    mobileNo: {} ?? {},
    otp: "",
    otpSentMessage: "",
    sidebar: false,
    searchMenber: true,
    memberForm: false,
    memberOtp: false,
  });
  const signIn = useSelector((state) => state.PreAss_AuthSlice.preAss_signIn);
  console.log({ signIn });
  useEffect(() => {
    setState({
      ...state,
      mobileNo: location?.state?.mobileNo,
      otp_minutes: 0,
      otp_seconds: 59,
    });
  }, []);
  function OtpTimer(props) {
    const { initialMinute = 0, initialSeconds = 0 } = props;
    const [minutes, setMinutes] = useState(initialMinute);
    const [seconds, setSeconds] = useState(initialSeconds);
    useEffect(() => {
      setMinutes(initialMinute);
      setSeconds(initialSeconds);
    }, [initialMinute, initialSeconds]);

    useEffect(() => {
      if (seconds === 0 && minutes === 0) {
        props.changeResendState(true);
      }
      let myInterval = setInterval(() => {
        // Block Resend State
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval);
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    });
    return (
      <>
        {minutes === 0 && seconds === 0 ? null : (
          <Typography
            style={{
              fontSize: "12px",
              margin: "0px",
              letterSpacing: 0,
              lineHeight: 1,
            }}
          >
            Resend OTP in {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </Typography>
        )}
      </>
    );
  }
  const changeBtnClick = () => {
    navigate(AppRoutes.patientSignIn);
  };

  const changeState = (key, value) => {
    setState({ ...state, [key]: value });
  };
  const sendOTP = () => {
    if (props?.resendOTP?.loading) {
      return true;
    }

    Promise.resolve(
      props?.GENERATE_OTP({
        mobileNo: `+${state.mobileNo?.countryData?.callingCodes ?? "00"}${state.mobileNo.mobileNo
          }`,
      })
    ).then((res) => {
      setState({
        ...state,
        otpSentMessage: "We have sent you the OTP again, Please check.",
      });
    });
  };

  const onLogIn = async () => {
    //debugger;
    // Promise.resolve(
    //   props?.SIGN_IN({
    //     mobileNo: `+${state.mobileNo?.countryData?.callingCodes ?? "00"}${
    //       state.mobileNo.mobileNo
    //     }`,
    //     otp: state.otp,
    //   })
    // ).then((res) => {
    //   const status = checkError(props?.signIn);
    //   if (true || !status.isError) {
    //     navigate(AppRoutes.searchFacility);
    //   } else {
    //     setState({
    //       ...state,
    //       otpSentMessage: status.errMsg,
    //     });
    //   }
    // });
    let details = location.state;
    if (state.otp === "123456") {
      //debugger;
      let result = await dispatch(
        actions.PRE_ASS_SIGN_IN({
          username: "+".concat(
            details?.mobileNo?.countryData?.callingCodes, " ",
            details?.mobileNo?.mobileNo
          ),
          password: "123456",
        })
      );
      if (result?.payload?.data?.error) {
        alert.setSnack({
          ...alert,
          open: true,
          msg: result?.payload?.data?.message || "Something went wrong !",
          severity: "error",
        });
      } else {
        if (result?.payload?.data?.tokenDetails && result?.payload?.data?.token) {
          let version = localStorage.getItem(LocalStorageKeys.version)
          localStorage.clear();
          localStorage.setItem(LocalStorageKeys.version, version)
          localStorage.setItem(
            "pre_ass_token",
            result?.payload?.data?.tokenDetails?.access_token
          );
          localStorage.setItem("UserInfo", result?.payload?.data?.userinfo);
          localStorage.setItem(
            "PermissionData",
            result?.payload?.data?.permissionData
          );
          localStorage.setItem("RoleData", result?.payload?.data?.role_data);

          localStorage.setItem("Token", result?.payload?.data?.token);
          localStorage.setItem("JwtToken", result?.payload?.data?.jwttoken);
          let decodedUserinfo = jwtDecode(result?.payload?.data?.userinfo)
          localStorage.setItem("RoleName", decodedUserinfo.userrole);

          checkForLatestBuild()
          //debugger
          if (decodedUserinfo?.patientid) {
            navigate(AppRoutes.PatientDashBoard, {
              state: {
                patientId: decodedUserinfo?.patientid

              }
            });

          } else {
            navigate(AppRoutes.patientAppointmentsListingPage);

          }
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            msg: "Please try again",
            severity: "error",
          });
        }
      }
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        msg: "OTP is incorrect",
        severity: "error",
      });
    }
  };
  const toggleSideBar = (boolean) => {
    setState({
      ...state,
      sidebar: boolean,
      memberForm: false,
      searchMenber: true,
    });
  };

  const nextSection = (member) => {
    //debugger
    if (member === "addmemberform") {
      setState({
        ...state,
        searchMenber: false,
        memberForm: true,
        memberOtp: false,
      });
    } else if (member === "addmemberotp") {
      setState({
        ...state,
        searchMenber: false,
        memberForm: false,
        memberOtp: true,
      });
    } else if (member === "back") {
      setState({
        ...state,
        searchMenber: true,
        memberForm: false,
        memberOtp: false,
      });
    } else if (member === "movenextscreen") {
      setState({
        ...state,
        searchMenber: true,
        memberForm: false,
        memberOtp: false,
        sidebar: false,
        chooseSearch: null,
      });
      window.location.href = "/appointments_listing_page";
    }
    navigate(AppRoutes.patientSignIn);
  };

  const handelChangeSelect = (v) => {
    setState({
      ...state,
      chooseSearch: v,
    });
  };
  return (
    <div id="signinotp_parent-div">
      <Container
        id="signinotp_parent-container"
        key={"0"}
        name="container"
        fluid={true}
        style={{
          backgroundColor: "#e8e8e8",
          // height: "100vh",
          padding: "0px",
        }}
      >
        <Row
          id="signinotp_parent_row"
          key={"0"}
          alignItems="stretch"
          direction="row"
          justify="flex-start"
          spacing="2"
          style={{ backgroundColor: "#ffffff", height: "" }}
        >
          <Col
            id="signinotp_parent-col"
            key={"0"}
            name="qdm-col-12"
            alignItems="center"
            direction="row"
            justify="space-between"
            textAlign="left"
            lg="12"
            xs="12"
            md="12"
            sm="12"
            style={{
              height: "64px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "12px 37px",
            }}
          >
            <div
              id="signinotp_header_logo_parent_div"
              style={{
                width: "96px",
                height: "32px",
              }}
            >
              <img
                id="signinotp_header_logo"
                key={"0"}
                variant="rounded"
                letter="Logo"
                src={Logo}
                alt="Logo"
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>

            <Div
              id="signinotp_header_right_div"
              key={"1"}
              inLineStyles="[object Object]"
              className="qdm"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Icons
                id="signinotp_header_phone_icon"
                key={"0"}
                fontIcon="phone"
                ariaHidden="true"
                size="small"
                className=""
                style={{ color: UIColor.secondaryText.color, margin: "10px" }}
              />

              <Text
                id="signinotp_header_number_text"
                key={"1"}
                name="+91 9821315141 , +91 9551482612"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 14,
                  color: UIColor.secondaryText.color,
                  letterSpacing: 0,
                }}
                className={"pc_regular"}
              />

              <Divider
                id="signinotp_header_right_side_divider"
                key={"2"}
                className=""
                inLineStyles="[object Object]"
                label=""
                borderColor=""
                textColor=""
                orientation="vertical"
                variant="middle"
                style={{
                  height: "27px",
                  marginTop: "6px",
                  marginLeft: "24px",
                  marginRight: "17px",
                }}
              />

              <Button
                id="signinotp_header_support_button"
                key={"3"}
                type="button"
                variant="text"
                name="Support"
                style={{
                  borderColor: UIColor.primaryColor.color,
                  borderWidth: "",
                  borderRadius: "8px",
                  color: UIColor.primaryColor.color,
                  height: "36px",
                  lineHeight: 0,
                  fontSize: 14,
                  padding: "8px 16px",
                }}
                className={"pc_medium"}
              />
            </Div>
          </Col>
        </Row>

        <Col
          id="signinotp_body_parent_card_col"
          key={"1"}
          name="qdm-col-12"
          alignItems="stretch"
          direction="row"
          justify="flex-start"
          textAlign="left"
          lg="12"
          xs="12"
          md="12"
          sm="12"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 64px)",
            backgroundColor: "#F0F7FF",
            overflow: "auto",
          }}
        >
          <BackgroundImage
            id="signinotp_body_background_image"
            key={"0"}
            url={BackgroundUrl}
            alt="Mask-Group-71-2x"
            backgroundPosition="0% 0%"
            backgroundPositionX="left"
            backgroundPositionY="top"
            backgroundRepeat="no-repeat"
            backgroundRepeatX="no-repeat"
            backgroundRepeatY="no-repeat"
            backgroundAttachment="scroll"
            backgroundSize="contain"
            backgroundOrigin="padding-box"
            inLineStyles="[object Object]"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Paper
              id="signinotp_body_parent_paper"
              key={"0"}
              children="Paper"
              variant="elevation"
              elevation="1"
              style={{
                width: "350px",
                // height: "",
                backgroundColor: "#ffffff",
                borderRadius: "16px",
                padding: "32px",
              }}
            >
              <H6
                id="signinotp_title_h6"
                key={"0"}
                name="Enter OTP"
                style={{
                  fontSize: "16px",
                  color: UIColor.primaryText.color,
                  textAlign: "center",
                  letterSpacing: 0,
                  lineHeight: 1,
                }}
                inLineStyles={{ fontFamily: "'pc_medium' !important" }}
              />

              <Div
                id="signinotp_sublevel_parent_div"
                key={"1"}
                className="qdm"
                style={{
                  marginTop: "24px",
                }}
              >
                <H6
                  id="signinotp_sublevel_title_h6"
                  key={"0"}
                  name="Please enter the OTP sent to  "
                  style={{
                    color: UIColor.secondaryText.color,
                    fontSize: "14px",
                    margin: "0px",
                    letterSpacing: 0,
                    textAlign: "center",
                  }}
                  className={"pc_regular"}
                >
                  Please enter the OTP sent to &nbsp;
                  <Text
                    id="signinotp_mobilenumber_discription_text"
                    key={"1"}
                    style={{
                      textAlign: "",
                      fontSize: "14px",
                      color: UIColor.primaryText.color,
                      letterSpacing: 0,
                    }}
                    className="pc_medium"
                  >
                    {/* {state.mobileNo?.countryData?.callingCodes ?? "99******66"} */}
                    {state.mobileNo?.mobileNo ?? ""}
                    <Link
                      id="signinotp_number_change_link"
                      key={"0"}
                      underline="hide"
                      color="primary"
                      name="Change"
                      underLine="none"
                      style={{
                        textAlign: "center",
                        color: UIColor.secondaryButtonColor.color,
                        borderWidth: "",
                        fontSize: "14px",
                        fontFamily: "pc_regular",
                      }}
                      onClick={changeBtnClick}
                    />
                  </Text>
                </H6>
              </Div>

              {/* <Div
              id="signinotp_number_change_link_div"
              key={"2"}
              className="qdm"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Link
                id="signinotp_number_change_link"
                key={"0"}
                underline="hide"
                color="primary"
                name="Change"
                underLine="none"
                style={{
                  textAlign: "center",
                  color: UIColor.primaryColor.color,
                  borderWidth: "",
                  fontSize: "14px",
                  fontFamily:'pc_regular'
                }}
                onClick={changeBtnClick}
              />
            </Div> */}

              <Div
                id="signinotp_otp_number_parent_div"
                key={"3"}
                className="qdm"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 12,
                }}
              >
                <div>
                  <OtpInput
                    id="signinotp_otp_number_otpinput"
                    key={"0"}
                    numberOfInputs={6}
                    variant="standard"
                    type="text"
                    focusTextColor={UIColor.secondaryColor.color}
                    focusBorderColor={UIColor.secondaryColor.color}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingLeft: "",
                      margin: 8,
                      fontFamily: "pc_regular",
                    }}
                    value={state.otp}
                    onChange={(e) => changeState("otp", e)}
                    className={state.otpSentMessage && "otpError"}
                  />
                </div>
              </Div>

              <Div
                id="signinotp_didnt_receive_resend_otp_resend_div"
                key={"4"}
                className="qdm"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "28px",
                }}
              >
                {state.otp_state ? (
                  <>
                    <H6
                      id="signinotp_didnt_receive_resend_otp_resend_h6"
                      key={"0"}
                      name="Didn't receive OTP ? "
                      style={{
                        color: UIColor.secondaryText.color,
                        fontSize: "12px",
                        margin: "0px",
                        letterSpacing: 0,
                        lineHeight: 1,
                      }}
                      className={"pc_regular"}
                    />

                    <Link
                      id="signinotp_didnt_receive_resend_otp_resending_link"
                      key={"1"}
                      //   name={
                      //     props.resendOTP.loading
                      //       ? "Resending OTP..."
                      //       : "Resend OTP"
                      //   }
                      name="Resend OTP"
                      underline="hide"
                      color="textPrimary"
                      underLine="none"
                      style={{
                        margin: "0px",
                        marginLeft: "8px",
                        color: UIColor.primaryText.color,
                        fontSize: "12px",
                        letterSpacing: 0,
                      }}
                      onClick={sendOTP}
                      className={"pc_medium"}
                    />
                  </>
                ) : (
                  <>
                    <OtpTimer
                      initialMinute={state.otp_minutes}
                      initialSeconds={state.otp_seconds}
                      changeResendState={(status) => {
                        // alert("Hi")
                        setState({ ...state, otp_state: true });
                      }}
                    />
                  </>
                )}
              </Div>

              {/* {state.otpSentMessage && ( */}
              <Div
                id="signinotp_resending_otpsendmessage_div"
                style={{ textAlign: "center" }}
              >
                {state.otpSentMessage && (
                  <H6
                    key={"0"}
                    name={state.otpSentMessage}
                    // name={'We have sent you the OTP again, Please check.'}
                    className="pc_regular"
                    style={{
                      color: UIColor.success.color,
                      fontSize: "12px",
                      marginTop: "8px",
                      visibility: state.otpSentMessage ? "visible" : "hidden",
                    }}
                  />
                )}
                {state.otpSentErrorMessage && (
                  <H6
                    key={"0"}
                    name={state.otpSentErrorMessage}
                    // name={'We have sent you the OTP again, Please check.'}
                    className="pc_regular"
                    style={{
                      color: UIColor.error.color,
                      fontSize: "12px",
                      marginTop: "8px",
                      visibility: state.otpSentErrorMessage
                        ? "visible"
                        : "hidden",
                    }}
                  />
                )}
              </Div>
              {/* )} */}

              <Button
                id="signinotp_signin_confirmotp_button"
                key={"5"}
                onClick={onLogIn}
                //disabled={props.signIn?.loading}
                type="button"
                variant="outlined"
                name={signIn?.loading ? "Signing In..." : "Confirm OTP"}
                style={{
                  margin: "",
                  marginTop: "40px",
                  width: "100%",
                  backgroundColor: UIColor.primaryColor.color,
                  borderColor: UIColor.primaryColor.color,
                  borderRadius: "8px",
                  fontSize: "14px",
                  fontWeight: "normal",
                  height: "40px",
                }}
                className={"pc_medium"}
              />
              <SideMenu
                id="signinotp_signup_sidemenu_parent"
                open={state.sidebar}
                direction="right"
                width={435}
                color="default"
                onClose={() => toggleSideBar(false)}
              >
                {state.searchMenber ? (
                  <AddMember
                    parent_id={"signinotp"}
                    name={"Sign Up"}
                    nextSection={(member, v, l) => nextSection(member, v, l)}
                    handelChangeSelect={(v) => handelChangeSelect(v)}
                    signUpData={state.signUpData}
                    chooseSearch={state.chooseSearch}
                  />
                ) : state.memberForm ? (
                  <Addmemberform
                    parent_id={"signinotp"}
                    signUpData={state.signUpData}
                    nextSection={(member) => nextSection(member)}
                  />
                ) : state.memberOtp ? (
                  <Addmemberotp
                    parent_id={"signinotp"}
                    addmmber={state.addmmber}
                    signUpData={state.signUpData}
                    nextSection={(member, v) => nextSection(member, v)}
                  />
                ) : null}
                {/* {state.memberSuccessPage ? (
                <AppointmentAddMemberSuccessPage
                  parent_id={"signinotp"}
                  addmmber={state.addmmber}
                  signUpData={state.signUpData}
                  nextSection={(member, v, l) =>
                    nextSection(member, v, l)
                  }
                  toggleSideBar={(member) => toggleSideBar(member)}
                />
              ) : null} */}
              </SideMenu>

              <Div
                id="signinotp_send_otp_button_below_divider_div"
                key={"6"}
                inLineStyles="[object Object]"
                className="qdm"
                style={{
                  marginTop: "24px",
                  marginBottom: "24px",
                  display: "flex",
                  height: "",
                }}
              >
                <Divider
                  id="signinotp_send_otp_button_below_divider_left"
                  key={"0"}
                  className=""
                  label=""
                  borderColor=""
                  textColor=""
                  orientation="horizontal"
                  variant="fullWidth"
                  style={{ width: "130px" }}
                />

                <Text
                  id="signinotp_send_otp_button_below_divider_center_or"
                  key={"1"}
                  name="Or"
                  style={{
                    color: UIColor.secondaryText.color,
                    width: "28px",
                    paddingLeft: "6px",
                    paddingRight: "6px",
                    backgroundColor: "#ffffff",
                    fontSize: 14,
                    marginTop: "4px",
                  }}
                  className={"pc_regular"}
                />

                <Divider
                  id="signinotp_send_otp_button_below_divider_right"
                  key={"2"}
                  className=""
                  label=""
                  borderColor=""
                  textColor=""
                  orientation="horizontal"
                  variant="fullWidth"
                  style={{ width: "130px" }}
                />
              </Div>

              <Button
                id="signinotp_proceed_as_guest_button"
                onClick={() => navigate(AppRoutes.proceesAsAGuest)}
                key={"7"}
                type="button"
                variant="text"
                name="Proceed as Guest"
                style={{
                  width: "100%",
                  borderColor: UIColor.primaryColor.color,
                  borderRadius: "8px",
                  color: UIColor.primaryColor.color,
                  fontSize: "14px",
                  height: "40px",
                }}
                className={"pc_medium"}
              />

              <Div
                id="signinotp_dont_have_an_account_div"
                key={"8"}
                inLineStyles="[object Object]"
                className="qdm"
                style={{
                  marginTop: "48px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "12px",
                  color: UIColor.primaryColor.color,
                }}
              >
                <Text
                  id="signinotp_dont_have_an_account_text"
                  key={"0"}
                  name="Don't have an account?"
                  style={{
                    color: UIColor.secondaryText.color,
                    fontSize: "12px",
                  }}
                  className={"pc_regular"}
                />

                <Link
                  id="signinotp_dont_have_an_account_link"
                  key={"1"}
                  underline="hide"
                  color="primary"
                  name="Sign Up"
                  onClick={() => toggleSideBar(true)}
                  style={{
                    color: UIColor.secondaryButtonColor.color,
                    marginBottom: "0px",
                  }}
                  className={"pc_regular"}
                />
              </Div>
            </Paper>
          </BackgroundImage>
        </Col>
      </Container>
    </div>
  );
}

export default SignInOTP;
