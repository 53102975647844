import { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom';

function UseQueryString(key = "data") {
    const [searchParams] = useSearchParams();
    const [QueryLocationData, setQueryLocationData] = useState({})
    const location = useLocation()
    useEffect(() => {
        if (searchParams.get(key)) {
            setQueryLocationData({ state: JSON.parse(decodeURIComponent(searchParams.get(key))) })
        }
        else {
            setQueryLocationData(location)
        }

    }, [key,location,searchParams])

    return QueryLocationData || {};
}

export default UseQueryString
