import React, { useEffect, useState, useContext } from "react";
import { Div } from "qdm-component-library";
import {
  RefNote,
  RefDetails,
  RefTo,
  RefFrom,
  RefLetter,
  UploadFiles,
} from "../../components";
import actions from "../../redux/actions";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { UIColor } from "../../themes/theme";

import {
  Button,
  Grid,
  IconButton,
  Popover,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { AlertContext } from "../../context";
import {
  AlertProps,
  checkError,
  epochToDatetime,
  getGlobalValueFrom_LclStrg_And_QryStrg,
  getTenantId,
  isEmptyObject,
  isObjectEmpty,
  isReferralInOut,
  readFileAsBase64,
} from "../../utils";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { useSelector } from "react-redux";
import { AppRoutes } from "../../router/routes";
const useStyles = makeStyles((theme) => ({
  muiPopovers: {
    boxShadow: "0px 25px 50px #00000029",
    borderRadius: 8,
    marginTop: 10,
    padding: 10,
  },
  mykadbtn: {
    fontFamily: "pc_medium",
    fontSize: "14px !important",
    color: "#6F6F6F !important",
    backgroundColor: "#f2f2f2 !important",
  },
}));

const PatientReferral = (props) => {
  console.log("PatientReferral", props);
  const navigate = useNavigate();
  const [choosemember, setchoosemember] = useState([]);
  const [allMasters, setallMasters] = useState([]);
  const [facility_type, setfacility_type] = useState([]);
  const [referral_type, setRefferal_type] = useState([]);
  const [error, setError] = React.useState(false);
  const [serviceRequested, setServiceRequested] = useState([]);
  // const [fin_data, setfin_data] = useState([])

  const alert = useContext(AlertContext);
  const classes = useStyles();
  const [refletter, setRefletter] = useState({
    letterNo: "",
    referralDate: "",
    recivedDate: "",//moment(new Date()).format("DD/MM/YYYY"),
    refLetterFile: [],
    viewFile: [],
  });
  const [from, setFrom] = useState({
    from: {},
    facilityType: {},
    facilityName: {},
    specialty: {},
    practitionerType: {},
    practitioner: {},
    hubNameOpenL: {},
    casemanagerOpenL: {},
  });
  const [refTo, setRefTo] = useState({
    to: {},
    facilityType: {},
    facilityName: {},
    specialty: {},
    location: {},
    practitionerType: {},
    practitioner: {},
    hubNameOpenL: {},
    casemanagerOpenL: {},
  });
  const [refDetails, setRefDetails] = useState({
    intentType: {},
    priority: {},
    requestReason: {},
    serviceRequested: [],
    diagnosis: [
      {
        diagnosisName: {},
        diagnosisType: {},
      },
    ],
    targetDate: null,
    referralType: {},
    mode:{},
  });
  const [refNotes, setRefNotes] = useState({
    reason: {},
    referralNotes: "",
    consentFile: [],
    viewFile: [],
  });
  const decodedUserinfo = getGlobalValueFrom_LclStrg_And_QryStrg({ localStoragekeyName: "UserInfo", isFullObject: true })
  const dispatch = useDispatch();
  const location = useLocation();
  console.log(location,"location");
  
  const selector = useSelector((state) => {
    console.log(state, "dropvalues");
    return {
      diagnosisname: [],
      diagnosistype: [],
    };
  });
  const getDataone = async () => {
    dispatch(actions.DAIGNOSIS_NAME());
    dispatch(actions.DAIGNOSIS_TYPE_AND_CONSENT());
  };
  useEffect(() => {
    getDataone();
  }, []);
  const styles = {
    labelStyle: {
      color: UIColor.tertiaryText.color,
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: UIColor.lineBorderFill.color,
    },
    errorborderStyle: {
      borderRadius: 8,
      borderColor: "red",
    },
    inputStyle: {
      padding: 5,
      marginBottom: 20,
      display: "flex",
      alignItems: "flex-end",
      position: "relative",
    },
    inputFiledStyle: {
      borderColor: UIColor.lineBorderFill.color,
      borderRadius: "8px",
    },
    actionButton: {
      backgroundColor: "#FFFFFF",
      border: "solid 1px #DEDEDE",
      borderRadius: 6,
      color: "black",
      marginBottom: 8,
      boxShadow: "none",
      "&:hover": {},
      "&:first-child": {
        backgroundColor: "#FF4D4A",
        borderColor: "#FF4D4A",
        color: "#ffffff",
        fontWeight: 600,
      },
    },
    autocomplete: {
      "& .MuiInputBase-input": {
        color: UIColor.secondaryText.color,
        fontSize: "12px !important",
        fontFamily: "pc_regular!important",
      },
      "& .MuiInputBase-root": {
        //height: 40,
        color: UIColor.secondaryText.color,
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          border: `1px solid ${UIColor.lineBorderFill.color} !important`,
          borderRadius: 8,
        },
        "&:hover fieldset": {
          border: `1px solid ${UIColor.lineBorderFill.color} !important`,
        },
        "&.Mui-focused fieldset": {
          borderWidth: 1,
          border: `1px solid ${UIColor.lineBorderFill.color} !important`,
        },
      },
      "& .MuiAutocomplete-popupIndicator": {
        transform: "none !important",
      },
    },
  };



  const successfun = async (files) => {
    // specific condition
    const momentDate = moment(refletter.referralDate);
    const momentReceivedDate = moment(refletter?.recivedDate);

    if (momentReceivedDate.valueOf() < momentDate.valueOf()) {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Received date and Referral date are mismatched ",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    }
    let diagnosis = [];
    let totalDiagErr = false;
    refDetails.diagnosis.forEach((v, i) => {
      if (v?.diagnosisName?._id) {
        totalDiagErr = false;
        diagnosis.push({
          diagnosisType: false,
          diagnosisName: false,
        });
      } else {
        totalDiagErr = true;
        diagnosis.push({
          diagnosisType: true,
          diagnosisName: true,
        });
      }
    });

    let totServiceRequestedErr = false;
    if (refDetails.serviceRequested.length === 0) {
      totServiceRequestedErr = true;
    } else {
      refDetails.serviceRequested.forEach((v) => {
        if (!v?._id) {
          totServiceRequestedErr = true;
        }
      });
    }
    refDetails["error"] = { diagnosis };
    if (!refletter?.letterNo) {
      refletter["error"] = {
        ...refletter["error"],
        letterNo: true,
        error:true
      };
    }
    if (!refletter?.referralDate) {
      refletter["error"] = {
        ...refletter["error"],
        referralDate: true,
        error:true
      };
    }
    if (!refletter?.recivedDate) {
      refletter["error"] = {
        ...refletter["error"],
        recivedDate: true,
        error:true
      };
    }
    if (isObjectEmpty(from?.from)) {
      from["error"] = {
        ...from["error"],
        from: true,
        error:true
      };
    }
    if (isObjectEmpty(from?.facilityType)) {
      from["error"] = {
        ...from["error"],
        facilityType: true,
        error:true
      };
    }
    if (isObjectEmpty(from?.facilityName)) {
      from["error"] = {
        facilityName: true,
        ...from["error"],
        error:true
      };
    }

    if (isObjectEmpty(from?.specialty)) {
      from["error"] = {
        ...from["error"],
        specialty: true,
        error:true
      };
    }
    if (isObjectEmpty(from?.practitionerType)) {
      from["error"] = {
        ...from["error"],
        practitionerType: true,
        error:true
      };
    }
    if (isObjectEmpty(from?.practitioner)) {
      from["error"] = {
        ...from["error"],
        practitioner: true,
        error:true
      };
    }

    if (isObjectEmpty(refTo?.specialty)) {
      refTo["error"] = {
        ...refTo["error"],
        specialty: true,
        error:true
      };
    }
    if (isObjectEmpty(from?.casemanagerOpenL)) {
      from["error"] = {
        ...from["error"],
        casemanagerOpenL: true,
        error:true
      };

    }
    if (isObjectEmpty(from?.hubNameOpenL)) {
      from["error"] = {
        ...from["error"],
        hubNameOpenL: true,
        error:true
      };
    }

    // if (isObjectEmpty(refTo?.facilityName)) {
    //   refTo["error"] = {
    //     facilityName: true,
    //     ...refTo["error"],
    //   };
    // }
    // if (isObjectEmpty(refTo?.practitioner)) {
    //   refTo["error"] = {
    //     practitioner: true,
    //     ...refTo["error"],
    //   };
    // }
    // if (location?.state?.isERefView && isObjectEmpty(refTo?.to)) {
    //   refTo["error"] = {
    //     to: true,
    //     ...refTo["error"],
    //   };
    // }
    // if (isObjectEmpty(refTo?.practitionerType)) {
    //   refTo["error"] = {
    //     practitionerType: true,
    //     ...refTo["error"],
    //   };
    // }
    // if (isObjectEmpty(refTo?.location)) {
    //   refTo["error"] = {
    //     location: true,
    //     ...refTo["error"],
    //   };
    // }

    if (isObjectEmpty(refDetails?.intentType)) {
      refDetails["error"] = {
        ...refDetails["error"],
        intentType: true,
        error:true
      };
    }
    // if (isObjectEmpty(refDetails?.for)) {
    //   refDetails["error"] = {
    //     for: true,
    //     ...refDetails["error"],
    //   };
    // }
    if (isObjectEmpty(refDetails?.priority)) {
      refDetails["error"] = {
        ...refDetails["error"],
        priority: true,
        error:true
      };
    }

    if (isObjectEmpty(refDetails?.requestReason)) {
      refDetails["error"] = {
        ...refDetails["error"],
        requestReason: true,
        error: true,
      };
    }
    // if (isObjectEmpty(refDetails?.targetDate)) {
    //   refDetails["error"] = {
    //     ...refDetails["error"],
    //     targetDate: true,
    //     error:true
    //   };
    // }
    if (isObjectEmpty(refDetails?.referralType)) {
      refDetails["error"] = {
        ...refDetails["error"],
        referralType: true,
        error: true,
      };
    }
    if (
      !(refDetails?.serviceRequested?.length > 0) ||
      refDetails?.serviceRequested?.length === 0
    ) {
      refDetails["error"] = {
        ...refDetails["error"],
        serviceRequested: true,
        error: true,
      };
    }
    if (isObjectEmpty(refNotes?.reason)) {
      refNotes["error"] = {
        ...refNotes["error"],
        reason: true,
        error: true,
      };
    }
    if (
      refletter?.letterNo &&
      refletter?.referralDate &&
      refletter?.recivedDate &&
      from?.from?.value &&
      from?.facilityType?.value &&
      from?.facilityName?.value &&
      from?.specialty?.value &&
      from?.practitionerType?.value &&
      from?.practitioner?.value &&
      from?.casemanagerOpenL?.value &&
      from?.hubNameOpenL?.value &&
      //  refTo?.specialty?.value &&
      refDetails?.intentType?.value &&
      refDetails?.priority?.value &&
      refDetails?.requestReason?.value &&
      refDetails?.referralType?.value &&
      !totServiceRequestedErr &&
      !totalDiagErr &&
      refNotes?.reason?.value
      // visitform.note?.reason
    ) {
      // alert.setSnack({
      //   open: true,
      //   severity: AlertProps.severity.success,
      //   msg: "not working",
      //   vertical: AlertProps.vertical.top,
      //   horizontal: AlertProps.horizontal.center,
      //   tone: true,
      // });
      setError(false);
      handlesubmit();
    } else {
      setError(true);
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all the mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
  };
  function dateToEpoch(dateString) {
    var date = new Date(dateString);
    var epochTime = date.getTime();
    var epochTimeInSeconds = Math.floor(epochTime / 1000);
    return epochTimeInSeconds;
  }
  const {editIDupdate = () => null,getRefData = () => null} = props
  
  const { userid, phoneno, patientid, personid } = decodedUserinfo || {};

  const navigateToReferralRegister = async (data) => {
          navigate(AppRoutes.erefEMR, {
            state: {
              from: location?.state?.from??"",
              visit: true,
              nextRoute: AppRoutes.referralInRegistration,
              prevRoute: AppRoutes.visitRegistration,
              mobileNo: location?.state?.mobileNumber??phoneno,
              mobileNumber: location?.state?.mobileNumber??phoneno,
              appointmentId: location?.state?.appointmentId??"",
              patientId:  location.state.patientId ?? props?.patientId??patientid,
              referraldetails:data?.referraldetails ||
                location?.state?.referraldetails ||
                location?.state?.data?.referraldetails,
                // getReferralData,
              addtionaldetails: data?.addtionaldetails || location?.state?.addtionaldetails,
              nextofkin: data?.nextofkin || location?.state?.nextofkin,
              patientdetails: data?.patientdetails,
              isModify: location?.state?.isModify ?? false,
              personid: location?.state?.userId || location?.state?.personId || location?.state?.personid||personid,
              userId: location?.state?.userId || location?.state?.personId || location?.state?.personid||userid,
              appointmentInfo: {},
              stateAction: location?.state?.stateAction,
              stateEndPoint: location?.state?.stateEndPoint,
              stateBackEndCall: location?.state?.stateBackEndCall,
              stateTicketid: location?.state?.stateTicketid,
              data: location?.state?.data,
              backMode: location?.state?.backMode,
              isEditedMode: location?.state?.isEditedMode,
              encounterID: data?.encounterid ||  location?.state?.encounterID || "",// encounterid
              referralId: data?.referraldetails?._id ||location?.state?.referraldetails?._id,
              isRefRegister: true,
              encounter_id: data?.encounterid || location?.state?.encounterID || "",// encounterid
            },
          });
  
};
  const handlesubmit = async () => {
    let refLetterFile = [];
    const promises = refletter?.refLetterFile.map(
      async (val) => await readFileAsBase64(val)
    );
    if (promises) {
      const base64Results = await Promise.all(promises);
      console.log(base64Results, "string");
      refletter?.refLetterFile?.forEach((val, i) => {
        let obj = {
          fileName: val?.name,
          fileid: "",
          filetype: val?.type,
          base64string: base64Results[i],
        };
        refLetterFile.push(obj);
      });
    }

    let consentAttachments = [];

    const consentpromises = refNotes?.consentFile.map(
      async (val) => await readFileAsBase64(val)
    );
    if (consentpromises) {
      const base64Results = await Promise.all(consentpromises);
      console.log(base64Results, "string");
      refNotes?.consentFile?.forEach((val, i) => {
        let obj = {
          fileName: val?.name,
          fileid: "",
          filetype: val?.type,
          base64string: base64Results[i],
        };
        consentAttachments.push(obj);
      });
    }
    console.log(moment(refletter?.recivedDate).valueOf().toString()?.length);
    let userInfo = jwtDecode(localStorage.getItem("UserInfo"));
    let doc = {
      _id: props?.editId || props?.refValue?.[0]?._id || "",
      facilityid: userInfo?.facilityid || from?.facilityType?._id,
      HealthCareServiceRequested: from?.facilityName?._id ?? "",
      RefSourceLetDate:
        Math.floor(moment(refletter?.referralDate).valueOf() / 1000) ?? 0,
      RefSourceLetRef: refletter?.letterNo ?? "",
      RefLetReceivedDate: Math.floor(moment().valueOf() / 1000),
      RequestorOrganizationType: from?.from?._id ?? "",
      RequestorOrganizationEntityType: from?.facilityType?._id ?? "",
      RequestorEncounter:  props?.isReferralOut || props?.isReferralIn ? "":
        location.state?.encounterID ||
        location.state?.encounterId ||
        location.state?.encounter_id ||
        "",
      RequestorEncounterType: props?.isReferralOut || props?.isReferralIn ? "":process.env.REACT_APP_EREFFERAL_REQUESTOR_ENCOUNTER_TYPE,
      RequestorSpecialty: from?.specialty?._id ?? "",
      RequestorPractionerType: from?.practitionerType?._id ?? "",
      RequestorPractionerName: from?.practitioner?.value ?? "",
      RequestorPractioner: from?.practitioner?._id ?? "",
      RequestorLocationType: from?.facilityType?._id ?? "",
      RequestorLocationCode: from?.facilityName?._id ?? "",
      RequestorLocationName: from?.facilityName?.value ?? "",
      intent: refDetails?.intentType?._id ?? "",
      // subject: refDetails?.for?._id ?? "",
      priority: refDetails?.priority?._id ?? "",
      reasonReference: refDetails?.requestReason?._id ?? "",
      note: [
        {
          //authorreference: refNotes?.reason?._id ?? "",
          text: refNotes?.referralNotes ?? "",
        },
      ],
      patientId: location?.state?.patientId,
      serviceRequested: refDetails?.serviceRequested.map((v) => v?._id) ?? [],
      recipientLocationName: refTo?.facilityName?.value ?? "",
      recipientLocationCode: refTo?.facilityName?._id ?? "",
      recipientLocType: refTo?.location?._id ?? "",
      recipientPracName: refTo?.practitioner?.value ?? "",
      recipientPract: refTo?.practitioner?._id ?? "",
      recipientPractType: refTo?.practitionerType?._id ?? "",
      recipientSpecialty: refTo?.specialty?._id ?? "",
      attachment: refLetterFile,
      refstatus: process.env.REACT_APP_EREFFERAL_REFSTATUS,
      scenarioid: "",
      tenantid: userInfo?.tenantid,
      remarks: "",
      receiptOrganizationType: refTo?.to?._id ?? "",
      receiptOrganizationEntityType: refTo?.facilityType?._id ?? "",
      receiptfacility: refTo?.facilityName?._id ?? "",
      hubid: from?.hubNameOpenL?._id || "Organization/10165",
      casemanager: from?.casemanagerOpenL?._id || "Practitioner/10413",
      diagnosis: refDetails.diagnosis.map((v) => {
        return {
          diagnosisname: v?.diagnosisName?._id || "",
          diagnosistype: v?.diagnosisType?._id || "",
        };
      }),
      consent: refNotes?.reason?._id || "",
      concentattachment: consentAttachments,
      referraltype:refDetails?.referralType?._id,
      consultationMode:refDetails?.mode?._id,
      targetDate:dateToEpoch(refDetails?.targetDate),
      refinout:props?.isReferralOut ? isReferralInOut?.referralOut : props?.isReferralIn  ? isReferralInOut?.referralIn : "" || props?.refValue?.[0]?.refinout?._id,
      // practitionerId:userInfo?.practitionerid,
      // practitionerRoleId: userInfo?.roleid,
      // "participants": [
      //   {
      //     "resoucetype": userInfo?.roleid,
      //     "ressubtype": userInfo?.roleid,
      //     "resouceid": userInfo?.practitionerid
      //   }
      // ]
    };
    const action = props?.isReferralOut || props?.isReferralIn ? actions.ORGANIZATION_REFERRALOUT_REGISTRATION : actions.ORGANIZATION_REFERRAL_REGISTRATION;
    const res = await dispatch(action({ doc: doc }));
    console.log(res, "saveres");
    const status = checkError(res?.payload);
    // const { nextRoute } = this.props.location.state;
    // const nr = nextRoute ? nextRoute : AppRoutes.PatientDashBoard;
    if (status.isError) {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: status.errMsg,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });

      return;
    }
    if (res?.payload?.data?.error) {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: res.payload?.data?.message,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      // props?.referralData?.setReferralDetails({
      //   from:{},
      //   details:{},
      //   to:{},
      //   letter:{},
      //   note:{}
      // })
      return;
    } else {
      const alertmsg = props?.editIDupdate
        ? "Patient Referral Updated Sucessfully"
        : "Referral id generated successfully";
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.success,
        msg: alertmsg,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    editIDupdate(
        res?.payload?.data?.[0]?.response?.[0]?.referraldetails?._id
      );
    getRefData(
        res?.payload?.data?.[0]?.response?.[0]?.referraldetails?._id
      );
      if(props?.isReferralOut || props?.isReferralIn){
        navigateToReferralRegister(res?.payload?.data?.[0]?.response?.[0])
        }
      // props?.referralData?.setReferralDetails({
      //   from:{},
      //   details:{},
      //   to:{},
      //   letter:{},
      //   note:{}
      // })
      props?.handleClose();
      // setRefletter({})
      // setFrom({})
      // setRefDetails({})
      // setRefNotes({})
      // setRefTo({})
      // const { from, to } = props.referralData;
      // const propObject = {
      //   pathname: AppRoutes.doctorListing, //nr,
      //   state: {
      //     nextRoute: AppRoutes.confirmBooking,
      //     cityName: "",
      //     doctors: [],
      //     mobileNo: location?.state?.mobileNo,
      //     walkin: true,
      //     referral: true,
      //     location: {
      //       address: to?.location?.value || "",
      //       latitude: 0,
      //       longitude: 0,
      //       location: referralDatas?.to?.location?.value,
      //     },
      //     hospital: [
      //       {
      //         label: from.facility_name.value,
      //         value: from.facility_name.label,
      //       },
      //     ],
      //     specialities: [
      //       {
      //         value: to?.specialty?.label,
      //         label: to?.specialty?.value,
      //         type: "Speciality",
      //       },
      //     ],
      //     symptoms: [],
      //     symptomsSearch: [],
      //     selectedUser: {
      //       name: makeName(primaryMember?.allData?.names?.[0]),
      //       // primaryMember?.allData?.names?.[0]?.prefix +
      //       // " " +
      //       // (primaryMember?.allData?.names?.[0]?.text || primaryMember?.allData?.names?.[0]?.given),
      //       email: primaryMember?.allData?.telecom?.[1]?.value,
      //       userId: primaryMember?.id,
      //       birthDay: primaryMember?.birthDay,
      //       gender: primaryMember?.gender,
      //       fileid: primaryMember?.imgUrl,
      //       mobile: location?.state?.mobileNumber,
      //     },
      //     userNo: this.props.location?.state?.mobileNumber,
      //     userId: primaryMember?.id,
      //     patientId: this.props.location?.state?.patientId,
      //     refferalId: res?.payload?.data?.Result?.[0]?.properties?.doc?._id,
      //     mobileNumber: location?.state?.mobileNumber,
      //   },
      // };
      // this.props.history.push(propObject);
    }
  };

  const getallmasters = async () => {
    const data = await dispatch(actions.GET_ALL_MASTERS(""));
    setallMasters(data?.payload?.data);
  };
  const loadDefaultValues = async () => {
    if(props?.isReferralIn){
  let userInfo = jwtDecode(localStorage.getItem("UserInfo"));
    let res = await dispatch(
      actions.GET_SCREEN_DEFAULT_VALUE({
        screenid: "ec08d539-4585-492e-a638-35e9053d5054",
        tenantid: getTenantId(),
        filtervalues: {
          orgid: userInfo?.facilityid,
          loginpract: userInfo?.practitionerid,
        },
      })
    );
    const defaultValues = res?.payload?.data?.response?.defaultValues;
    console.log(defaultValues, "defaultdata referral");
    const stateNames = [
      "pract_name",
      "facility_type",
      "speciality",
      "facility_name",
      "pract_type",
      "Consultation_Mode",
      "referred_from",
      "referral_type",
      "location"
    ];
    const stateValues = {};
    defaultValues?.forEach((s) => {
      if (stateNames?.includes(s?.stateName)) {
        stateValues[s?.stateName] = s?.value;
      }
    });
    const data = await dispatch(
      actions.GET_SCREEN_DEFAULT_VALUE({
        screenid: "2ea9201d-4f54-4075-b508-c2cec8595180",
        tenantid: getTenantId(),
      })
    );
  
    setRefNotes({
      ...refNotes,
      reason: data?.payload?.data?.response?.defaultValues?.[0]?.value,
    });
    setRefDetails({
      ...refDetails,
      mode: {
        value: stateValues?.Consultation_Mode?.value,
        _id: stateValues?.Consultation_Mode?._id,
      },
      referralType: {
        value: stateValues?.referral_type?.value,
        _id: stateValues?.referral_type?._id,
      },
      targetDate:moment(new Date()).format("MM/DD/YYYY")
    });
    setRefTo({
      ...refTo,
      to:  {
        value: stateValues?.referred_from?.value,
        _id: stateValues?.referred_from?._id,
      },
      facilityType: {
        value: stateValues?.facility_type?.value,
        _id: stateValues?.facility_type?._id,
          },
      // "hubNameOpenL": {},
      // "casemanagerOpenL": {},
      facilityName: {
             value: stateValues?.facility_name?.value,
             _id: stateValues?.facility_name?._id,
             label: stateValues?.facility_name?.value,
           },
        specialty: stateValues?.speciality?.value
        ? {
            value: stateValues?.speciality?.value,
            _id: stateValues?.speciality?._id,
          }
        : {},
      location: {
          value: stateValues?.location?.value,
          _id: stateValues?.location?._id
      },
      practitionerType: {
        value: stateValues?.pract_type?.value,
        _id: stateValues?.pract_type?._id,
      },
      practitioner: {
        value: stateValues?.pract_name?.value,
        _id: stateValues?.pract_name?._id,
      },
    });
  
    }else{
    let userInfo = jwtDecode(localStorage.getItem("UserInfo"));
    let res = await dispatch(
      actions.GET_SCREEN_DEFAULT_VALUE({
        screenid: "ec08d539-4585-492e-a638-35e9053d5054",
        tenantid: getTenantId(),
        filtervalues: {
          orgid: userInfo?.facilityid,
          loginpract: userInfo?.practitionerid,
        },
      })
    );
    const defaultValues = res?.payload?.data?.response?.defaultValues;
    console.log(defaultValues, "defaultdata referral");
    const stateNames = [
      "pract_name",
      "facility_type",
      "speciality",
      "facility_name",
      "pract_type",
      "Consultation_Mode",
      "referred_from",
      "referral_type",
    ];
    const stateValues = {};
    defaultValues?.forEach((s) => {
      if (stateNames?.includes(s?.stateName)) {
        stateValues[s?.stateName] = s?.value;
      }
    });
    const data = await dispatch(
      actions.GET_SCREEN_DEFAULT_VALUE({
        screenid: "2ea9201d-4f54-4075-b508-c2cec8595180",
        tenantid: getTenantId(),
      })
    );
  
    setRefNotes({
      ...refNotes,
      reason: data?.payload?.data?.response?.defaultValues?.[0]?.value,
    });
    setFrom({
      ...from,
      from: {
        value: stateValues?.referred_from?.value,
        _id: stateValues?.referred_from?._id,
      },
      facilityType: {
        value: stateValues?.facility_type?.value,
        _id: stateValues?.facility_type?._id,
      },
      facilityName: {
        value: stateValues?.facility_name?.value,
        _id: stateValues?.facility_name?._id,
      },
      specialty: stateValues?.speciality?.value
        ? {
            value: stateValues?.speciality?.value,
            _id: stateValues?.speciality?._id,
          }
        : {},
      practitionerType: {
        value: stateValues?.pract_type?.value,
        _id: stateValues?.pract_type?._id,
      },
      practitioner: {
        value: stateValues?.pract_name?.value,
        _id: stateValues?.pract_name?._id,
      },
    });
    setRefDetails({
      ...refDetails,
      mode: {
        value: stateValues?.Consultation_Mode?.value,
        _id: stateValues?.Consultation_Mode?._id,
      },
      referralType: {
        value: stateValues?.referral_type?.value,
        _id: stateValues?.referral_type?._id,
      },
      targetDate:moment(new Date()).format("MM/DD/YYYY")
    });
  }
  };

  useEffect(() => {
    getData();
    editAndDefaultset();
  }, []);
  const editAndDefaultset = async () => {
    try {
      if (props?.editId) {
        let refvalue = props?.refValue?.[0];
        if (!isEmptyObject(refvalue || {})) {
          let diagnosis = [];
          refvalue?.diagnosis.forEach((v, i) => {
            let obj = {
              diagnosisName: {
                value: v.diagnosisname.icdname + "-" + v.diagnosisname.icdcode,
                _id: v?.diagnosisname?._id,
              },
              diagnosisType: v?.diagnosistype,
            };
            diagnosis.push(obj);
          });
          setRefletter({
            ...refletter,
            letterNo: refvalue?.RefSourceLetRef || "",
            referralDate: refvalue?.RefSourceLetDate
              ? moment.unix(refvalue?.RefSourceLetDate).format("YYYY-MM-DD")
              : "",
            recivedDate: refvalue?.RefLetReceivedDate
              ? moment.unix(refvalue?.RefLetReceivedDate).format("YYYY-MM-DD")
              : "",
            viewFile: refvalue?.attachment,
          });
          setFrom({
            ...from,
            from: refvalue?.RequestorOrganizationType || {},
            facilityType: refvalue?.RequestorOrganizationEntityType || {},
            facilityName: refvalue?.RequestorLocationCode || {},
            specialty: refvalue?.RequestorSpecialty || {},
            practitionerType: refvalue?.RequestorPractionerType || {},
            practitioner: refvalue?.RequestorPractioner || {},
            casemanagerOpenL: refvalue?.casemanager || {},
            hubNameOpenL: refvalue?.hubid || {},
          });
          setRefTo({
            ...refTo,
            to: refvalue?.receiptOrganizationType || {},
            facilityType: refvalue?.receiptOrganizationEntityType || {},
            facilityName: refvalue?.recipientLocationCode || {},
            specialty: refvalue?.recipientSpecialty || {},
            location: refvalue?.recipientLocType || {},
            practitionerType: refvalue?.recipientPractType || {},
            practitioner: refvalue?.recipientPract || {},
          });
          setRefDetails({
            ...refDetails,
            intentType: refvalue?.intent || {},
            // for: refvalue?.subject || {},
            priority: refvalue?.priority || {},
            requestReason: refvalue?.reasonReference || {},
            serviceRequested: refvalue?.serviceRequested || [],
            diagnosis:
              diagnosis.length === 0
                ? [
                    {
                      diagnosisName: {},
                      diagnosisType: {},
                    },
                  ]
                : diagnosis,
            targetDate: refvalue?.targetdate
              ? moment.unix(refvalue?.targetdate).format("YYYY-MM-DD")
              : "",
            referralType: refvalue?.referraltype,
            mode: refvalue?.consultationmode || {},
          });
          setRefNotes({
            ...refNotes,
            referralNotes: refvalue?.note?.[0]?.text || "",
            reason: refvalue?.consent,
            viewFile: refvalue?.concentattachment,
          });
        }
      } else {
        //default setting
        loadDefaultValues();
      }
    } catch (error) {
      window.alert(error.message);
    }
  };
  const getData = async () => {
    const patientID = location?.state?.patientId || "Patient/11219";

    const facility_type = await dispatch(
      actions.GET_FACILITY_TYPE({
        type: "ORGTYPE",
      })
    );
    const facility_type_Data = facility_type?.payload?.data?.map((v) => {
      return {
        value: v?.label,
        codingId: v?.value,
      };
    });
    setfacility_type(facility_type_Data);

    await getallmasters();
    const payload = {
      userid: location?.state?.personId || location?.state?.userId,
    };

    const data = await dispatch(actions.GET_RELATED_PERSON(payload));
    if (data?.payload?.data) {
      setchoosemember([...data?.payload?.data]);
    }

    const fin_data = await dispatch(
      actions.GET_FINANCIAL({ patientId: patientID })
    );
    if (fin_data?.payload?.data) {
      fin_data?.payload?.data?.map((v) => {
        const setdata = v?.payersprioritycoverage?.[0];
        return {
          pic: "images/Group 90507.svg",
          name: null,
          care_type: setdata?.guarantorid,
          title_name: setdata?.receivertype + ", " + setdata?.policyid,
        };
      });
      dispatch(actions.GET_HUBS());
      dispatch(actions.GET_CASEMANAGERS());
      const referral_type = await dispatch(actions.GET_REFERRAL_TYPE());
      setRefferal_type(referral_type?.payload?.data);
      // setselectOption(fin_DATA[0])
      // setfin_data(fin_DATA)
    }
    // setloader(false)
  };
  const getValidation = async () => {
    const orgId = refTo?.facilityName?._id;
    const practitionerId = refTo?.practitioner?._id;
    const specialityId = refTo?.specialty?._id;
    const roleId = decodedUserinfo?.idmroleid;
    const facilityType = refTo?.facilityType?._id;
    const validation = await dispatch(
      actions.GET_VALIDATION_FOR_REFERRAL_ACTIONS({
        orgId,
        practitionerId,
        specialityId,
        roleId,
        facilityType,
      })
    );
    if (validation?.payload?.data?.length > 0) {
      successfun();
    } else {
      props?.handleClose();
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Can't Initiate referral for this user type!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  };

  console.log(refletter, from, refDetails, refNotes, refTo, "refstates//");
  const getServiceRequest = async (typeId) => {
    const data = await dispatch(actions.GET_SERVICE_REQUEST({reftypeid:typeId}));
    setServiceRequested(data?.payload?.response);
  };
  useEffect(() => {
    getServiceRequest(refDetails?.referralType?._id);
  }, [refDetails?.referralType]);

  return (
    // <div style={{ margin: "10px", padding: "10px", overflow: "auto" }}>
      <Div
        id="Referral_Registration_referral_parent_div"
        //className="light-background-color"
      >
        <Grid
          container
          xs={12}
          style={{ paddingLeft: "20px", margin: "8px", alignItems: "center" }}
        >
          <Grid item xs={11}>
            <Typography variant="h6" style={{ textTransform: "capitalize" }}>
              Patient Referral
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={props?.handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" style={{ textTransform: "capitalize" }}>
            Patient Referral
          </Typography>
          <IconButton onClick={props?.handleClose}>
            <CloseIcon />
          </IconButton>
        </div> */}

        <Div
          id="Referral_Registration_referral_sub_div"
          style={{
            padding: "0px 15px",
            height: "calc(100vh - 128px)", // 128px = 72px + 56px // 72 - topheader , 56 - topnav
            display: "block",
            alignItems: "center",
            justifyContent: "unset",
          }}
        >
          <RefLetter
            parent_id={"Referral_Registration_referral"}
            refletter={refletter}
            setRefletter={setRefletter}
            isViewReferral={props?.isViewReferral}
          />
          <RefFrom
            parent_id={"Referral_Registration_referral"}
            facility_type={facility_type}
            allMasters={allMasters}
            choosemember={choosemember}
            from={from}
            setFrom={setFrom}
            error={error}
            isViewReferral={props?.isViewReferral}
          />
          <RefTo
            parent_id={"Referral_Registration_referral"}
            allMasters={allMasters}
            refTo={refTo}
            setRefTo={setRefTo}
            isViewReferral={props?.isViewReferral}
          />
          <RefDetails
            parent_id={"Referral_Registration_referral"}
            allMasters={allMasters}
            refDetails={refDetails}
            setRefDetails={setRefDetails}
            referralType={referral_type}
            isViewReferral={props?.isViewReferral}
            serviceRequested={serviceRequested}
          />
          <RefNote
            parent_id={"Referral_Registration_referral"}
            allMasters={allMasters}
            refNotes={refNotes}
            setRefNotes={setRefNotes}
            isViewReferral={props?.isViewReferral}
        />
        {props?.isViewReferral ? (<></>) : (
          <div
            style={{
              height: "10vh",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <span style={{ display: "flex" }}>
              <Button
                onClick={props?.handleClose}
                color="primary"
                fullWidth
                className={styles.actionButton}
              >
                cancel
              </Button>{" "}
              <Button
                onClick={() => {
                  // handlesubmit();
                  // successfun();
                  getValidation();
                }}
                color="primary"
                fullWidth
                className={styles.actionButton}
              >
                {"SAVE"}
              </Button>
            </span>
          </div>
        )}
        </Div>

        {/* <Div className="p-continue-action">
                <Button>Back</Button>
            </Div> */}
      </Div>
    // </div>
  );
};

export default PatientReferral;
