import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  defaultReject,
  defaultState,
  fetchData,
  urls,
  TransferandSchedule,
  transferCase,
  patientReferralQuery,
} from "../../../utils";
import axios from "axios";
const {
  appointmenttype,
  scheduledspeciality,
  scheduleddoctorname,
  scheduledfacilityaddress,
  scheduledfacilityname,
  servicebooked,
  scheduledBy,
} = TransferandSchedule;
const { hubdetails, casemanagername, transferreason, hubto } = transferCase;
const { daignosistypeandconsent, daignosisname } = patientReferralQuery;

const CASEMANAGER_NAME = createAsyncThunk(
  `transferAndScheduleSlice/caseManagerName`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        casemanagername(payload),
        urls?.readQdmQueries
      );
      let result = [];
      data.forEach((v) => {
        result.push({
          value: v?.name ?? "",
          _id: v?.id ?? "",
        });
      });
      return {
        ...defaultState.Info,
        data: result,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const HUB_DETAILS = createAsyncThunk(
  `transferAndScheduleSlice/hubdetails`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(hubdetails(payload), urls.readQdmQueries);
      let result = [];
      data.forEach((v) => {
        result.push({
          value: v?.name ?? "",
          _id: v?._id ?? "",
        });
      });
      return {
        ...defaultState.Info,
        data: result,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const HUB_TO = createAsyncThunk(
  `transferAndScheduleSlice/hubto`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(hubto(payload), urls.readQdmQueries);
      let result = [];
      data.forEach((v) => {
        result.push({
          value: v?.name ?? "",
          _id: v?._id ?? "",
        });
      });
      return {
        ...defaultState.Info,
        data: result,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const TRANSFER_REASON = createAsyncThunk(
  `transferAndScheduleSlice/transferreason`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        transferreason(payload),
        urls.readQdmQueries
      );
      let result = [];
      data.forEach((e, i) => {
        let key = Object.keys(e)?.[0];
        let arr = [];
        data?.[i]?.[key]?.Value?.forEach((v) => {
          console.log(v, "tv");
          arr.push({
            value: v?.ReasonDesc ?? "",
            _id: v?._id ?? "",
          });
        });
        result[key] = arr;
      });
      return {
        ...defaultState.Info,
        data: result,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const SCHEDULED_FACILITY_NAME = createAsyncThunk(
  `transferAndScheduleSlice/scheduledFacilityName`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        scheduledfacilityname(payload),
        urls.readQdmQueries
      );
      let result = [];
      data.forEach((v) => {
        result.push({
          value: v?.name ?? "",
          _id: v?.id ?? "",
        });
      });
      return {
        ...defaultState.Info,
        data: result,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const APPOINTMENT_TYPE = createAsyncThunk(
  `transferAndScheduleSlice/appointmenttype`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        appointmenttype(payload),
        urls.readQdmQueries
      );
      let result = [];
      data.forEach((e, i) => {
        let key = Object.keys(e)?.[0];
        let arr = [];
        data?.[i]?.[key]?.Value?.forEach((v) => {
          arr.push({
            value: v?.display ?? "",
            _id: v?._id ?? "",
          });
        });
        result[key] = arr;
      });

      return {
        ...defaultState.Info,
        data: result,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
// const SCHEDULED_SPECIALITY = createAsyncThunk(
//   `transferAndScheduleSlice/scheduledspeciality`,
//   async (payload = {}, { rejectWithValue }) => {
//     try {
//       const data = await fetchData(scheduledspeciality(),
//         urls.readQdmQueries
//       );
//       return {
//         ...defaultState.Info,
//         data,
//       };
//     } catch (err) {
//       return rejectWithValue({
//         ...defaultReject,
//         message: err.message,
//       });
//     }
//   }
// );
const SERVICE_BOOKED = createAsyncThunk(
  `transferAndScheduleSlice/servicebooked`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(servicebooked(payload), urls.readQdmQueries);
      let result = [];
      data.forEach((v) => {
        result.push({
          value: v?.longdesc ?? "",
          _id: v?._id ?? "",
        });
      });
      return {
        ...defaultState.Info,
        data: result,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const SCHEDULED_DOCTOR_NAME = createAsyncThunk(
  `transferAndScheduleSlice/scheduleddoctorname`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        scheduleddoctorname(payload?.fac, payload?.spec),
        urls.readQdmQueries
      );
      let result = [];
      data.forEach((v) => {
        result.push({
          value: v?.name ?? "",
          _id: v?._id ?? "",
        });
      });
      return {
        ...defaultState.Info,
        data: result,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const SCHEDULED_FACILITY_ADDRESS = createAsyncThunk(
  `transferAndScheduleSlice/scheduledfacilityaddress`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        scheduledfacilityaddress(payload),
        urls.readQdmQueries
      );
      let result = [];
      data.forEach((v) => {
        result.push({
          value: v?.address ?? "",
          _id: v?.id ?? "",
        });
      });
      return {
        ...defaultState.Info,
        data: result,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const SCHEDULE_ACTION = createAsyncThunk(
  `transferAndScheduleSlice/schedule_action`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        { body: JSON.stringify(payload) },
        urls.performUserAction
      );
      return {
        ...defaultState.Info,
        data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const TRANSFER_ACTION = createAsyncThunk(
  `transferAndScheduleSlice/transfer_action`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        { body: JSON.stringify(payload) },
        urls.performUserAction
      );
      return {
        ...defaultState.Info,
        data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const SCHEDULED_BY = createAsyncThunk(
  `transferAndScheduleSlice/scheduledBy`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(scheduledBy(payload), urls.readQdmQueries);

      let result = [];
      data.forEach((v) => {
        result.push({
          value: v?.name ?? "",
          _id: v?.id ?? "",
          role:v?.role?.[0]
        });
      });

      return {
        ...defaultState.Info,
        data: result,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_OPENL_CASE_MANAG_AND_HUB_ID = createAsyncThunk(
  `transferAndScheduleSlice/openLCasemanaAndHub`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      let data = JSON.stringify({
        fromfacility: payload?.orgId,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: urls.GetCaseMangerAndHubErefOpenL,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      let result = await axios.request(config);
      return {
        ...defaultState.Info,
        data: result.data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const DAIGNOSIS_NAME = createAsyncThunk(
  `transferAndScheduleSlice/daignosisName`,
  async (payload = "", { rejectWithValue }) => {
    try {
      const data = await fetchData(
        daignosisname(payload),
        urls?.readQdmQueries
      );
      
      let result = [];
      data.forEach((v) => {
        result.push({
          value: v?.icdname + v?.icdcode ?? "",
          _id: v?._id ?? "",
        });
      });
      return {
        ...defaultState.Info,
        data: result,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const DAIGNOSIS_TYPE_AND_CONSENT = createAsyncThunk(
  `transferAndScheduleSlice/daignosisTypeANDConsent`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        daignosistypeandconsent(),
        urls?.readQdmQueries
      );
      let result = [];
      data.forEach((e, i) => {
        let key = Object.keys(e)?.[0];
        let arr = [];
        data?.[i]?.[key]?.Value?.forEach((v) => {
          arr.push({
            value: v?.display ?? "",
            _id: v?._id ?? "",
          });
        });
        result[key] = arr;
      });
      return {
        ...defaultState.Info,
        data: result,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const transferAndScheduleSlice = createSlice({
  name: "transferAndScheduleSlice",
  initialState: {
    caseManagerName: { ...defaultState.List },
    schedule_action: { ...defaultState.Info },
    transfer_action: { ...defaultState.Info },
    appointmenttype: { ...defaultState.List },
    scheduledFacilityName: { ...defaultState.List },
    scheduledspeciality: { ...defaultState.List },
    scheduleddoctorname: { ...defaultState.List },
    scheduledfacilityaddress: { ...defaultState.List },
    hubdetails: { ...defaultState.Info },
    servicebooked: { ...defaultState.List },
    transferreason: { ...defaultState.Info },
    hubto: { ...defaultState.Info },
    scheduledBy: { ...defaultState.List },
    daignosisName: { ...defaultState.List },
    daignosisTypeANDConsent: { ...defaultState.List },
    openLCasemanaAndHub: { ...defaultState.List },
  },
  extraReducers: {
    /* CASEMANAGER_NAME */
    [CASEMANAGER_NAME.fulfilled]: (state, action) => {
      state.caseManagerName = action?.payload ?? [];
    },
    [CASEMANAGER_NAME.pending]: (state, action) => {
      state.caseManagerName.loading = true;
    },
    [CASEMANAGER_NAME.rejected]: (state, action) => {
      state.caseManagerName = action?.payload ?? [];
    },
    /* HUB_DETAILS */
    [HUB_DETAILS.fulfilled]: (state, action) => {
      state.hubdetails = action?.payload ?? [];
    },
    [HUB_DETAILS.pending]: (state, action) => {
      state.hubdetails.loading = true;
    },
    [HUB_DETAILS.rejected]: (state, action) => {
      state.hubdetails = action?.payload ?? [];
    },
    /* HUB_TO */
    [HUB_TO.fulfilled]: (state, action) => {
      state.hubto = action?.payload ?? [];
    },
    [HUB_TO.pending]: (state, action) => {
      state.hubto.loading = true;
    },
    [HUB_TO.rejected]: (state, action) => {
      state.hubto = action?.payload ?? [];
    },
    /* TRANSFER_REASON */
    [TRANSFER_REASON.fulfilled]: (state, action) => {
      state.transferreason = action?.payload ?? [];
    },
    [TRANSFER_REASON.pending]: (state, action) => {
      state.transferreason.loading = true;
    },
    [TRANSFER_REASON.rejected]: (state, action) => {
      state.transferreason = action?.payload ?? [];
    },
    /* service_booked */
    [SERVICE_BOOKED.fulfilled]: (state, action) => {
      state.servicebooked = action?.payload ?? [];
    },
    [SERVICE_BOOKED.pending]: (state, action) => {
      state.servicebooked.loading = true;
    },
    [SERVICE_BOOKED.rejected]: (state, action) => {
      state.servicebooked = action?.payload ?? [];
    },
    /* SCHEDULE_ACTION  */
    [SCHEDULE_ACTION.fulfilled]: (state, action) => {
      state.schedule_action = action?.payload ?? [];
    },
    [SCHEDULE_ACTION.pending]: (state, action) => {
      state.schedule_action.loading = true;
    },
    [SCHEDULE_ACTION.rejected]: (state, action) => {
      state.schedule_action = action?.payload ?? [];
    },
    /* TRANSFER_ACTION  */
    [TRANSFER_ACTION.fulfilled]: (state, action) => {
      state.transfer_action = action?.payload ?? [];
    },
    [TRANSFER_ACTION.pending]: (state, action) => {
      state.transfer_action.loading = true;
    },
    [TRANSFER_ACTION.rejected]: (state, action) => {
      state.transfer_action = action?.payload ?? [];
    },
    /* Appointment Type  */
    [APPOINTMENT_TYPE.fulfilled]: (state, action) => {
      state.appointmenttype = action?.payload ?? [];
    },
    [APPOINTMENT_TYPE.pending]: (state, action) => {
      state.appointmenttype.loading = true;
    },
    [APPOINTMENT_TYPE.rejected]: (state, action) => {
      state.appointmenttype = action?.payload ?? [];
    },
    /*scheduledFacilityName */
    [SCHEDULED_FACILITY_NAME.fulfilled]: (state, action) => {
      state.scheduledFacilityName = action?.payload ?? [];
    },
    [SCHEDULED_FACILITY_NAME.pending]: (state, action) => {
      state.scheduledFacilityName.loading = true;
    },
    [SCHEDULED_FACILITY_NAME.rejected]: (state, action) => {
      state.scheduledFacilityName = action?.payload ?? [];
    },
    /*scheduledspeciality*/
    // [SCHEDULED_SPECIALITY.fulfilled]: (state, action) => {
    //   state.scheduledspeciality = action?.payload ?? [];
    // },
    // [SCHEDULED_SPECIALITY.pending]: (state, action) => {
    //   state.scheduledspeciality.loading = true;
    // },
    // [SCHEDULED_SPECIALITY.rejected]: (state, action) => {
    //   state.scheduledspeciality = action?.payload ?? [];
    // },
    /*scheduleddoctorname*/
    [SCHEDULED_DOCTOR_NAME.fulfilled]: (state, action) => {
      state.scheduleddoctorname = action?.payload ?? [];
    },
    [SCHEDULED_DOCTOR_NAME.pending]: (state, action) => {
      state.scheduleddoctorname.loading = true;
    },
    [SCHEDULED_DOCTOR_NAME.rejected]: (state, action) => {
      state.scheduleddoctorname = action?.payload ?? [];
    },
    /*scheduledfacilityaddress*/
    [SCHEDULED_FACILITY_ADDRESS.fulfilled]: (state, action) => {
      state.scheduledfacilityaddress = action?.payload ?? [];
    },
    [SCHEDULED_FACILITY_ADDRESS.pending]: (state, action) => {
      state.scheduledfacilityaddress.loading = true;
    },
    [SCHEDULED_FACILITY_ADDRESS.rejected]: (state, action) => {
      state.scheduledfacilityaddress = action?.payload ?? [];
    },
    /*scheduledBy*/
    [SCHEDULED_BY.fulfilled]: (state, action) => {
      state.scheduledBy = action?.payload ?? [];
    },
    [SCHEDULED_BY.pending]: (state, action) => {
      state.scheduledBy.loading = true;
    },
    [SCHEDULED_BY.rejected]: (state, action) => {
      state.scheduledBy = action?.payload ?? [];
    },
    /* DAIGNOSIS NAME */
    [DAIGNOSIS_NAME.fulfilled]: (state, action) => {
      state.daignosisName = action?.payload ?? [];
    },
    [DAIGNOSIS_NAME.pending]: (state, action) => {
      state.daignosisName.loading = true;
    },
    [DAIGNOSIS_NAME.rejected]: (state, action) => {
      state.daignosisName = action?.payload ?? [];
    },
    /* DAIGNOSIS TYPE */
    [DAIGNOSIS_TYPE_AND_CONSENT.fulfilled]: (state, action) => {
      state.daignosisTypeANDConsent = action?.payload ?? [];
    },
    [DAIGNOSIS_TYPE_AND_CONSENT.pending]: (state, action) => {
      state.daignosisTypeANDConsent.loading = true;
    },
    [DAIGNOSIS_TYPE_AND_CONSENT.rejected]: (state, action) => {
      state.daignosisTypeANDConsent = action?.payload ?? [];
    },

    /* GET_OPENL_CASE_MANAG_AND_HUB_ID */
    [GET_OPENL_CASE_MANAG_AND_HUB_ID.fulfilled]: (state, action) => {
      state.openLCasemanaAndHub = action?.payload ?? [];
    },
    [GET_OPENL_CASE_MANAG_AND_HUB_ID.pending]: (state, action) => {
      state.openLCasemanaAndHub.loading = true;
    },
    [GET_OPENL_CASE_MANAG_AND_HUB_ID.rejected]: (state, action) => {
      state.openLCasemanaAndHub = action?.payload ?? [];
    },
  },
});

export const TransferandScheduleAction = {
  CASEMANAGER_NAME,
  HUB_DETAILS,
  HUB_TO,
  TRANSFER_REASON,
  SERVICE_BOOKED,
  SCHEDULE_ACTION,
  TRANSFER_ACTION,
  APPOINTMENT_TYPE,
  SCHEDULED_FACILITY_NAME,
  //SCHEDULED_SPECIALITY,
  SCHEDULED_DOCTOR_NAME,
  SCHEDULED_FACILITY_ADDRESS,
  SCHEDULED_BY,
  DAIGNOSIS_TYPE_AND_CONSENT,
  DAIGNOSIS_NAME,
  GET_OPENL_CASE_MANAG_AND_HUB_ID,
};

export default transferAndScheduleSlice;
