import { Grid, Paper, Typography } from "@mui/material";
import React from "react";

const ChiefComplaintsCpg = ({ chiefComplaints }) => {
  return (
    <>
      {chiefComplaints &&
        chiefComplaints.map((chiefComplaint) => (
          // <Paper elevation={0} sx={{ marginTop: "5px" }}>
            <Grid container xs={12} marginTop={1}>
              <Grid item xs={12}>
                <Typography variant="caption" fontSize={"12px"} fontWeight={600} pl={0.8} >
                  {" "}
                   {chiefComplaint?.chiefcomplaints}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" fontSize={"12px"}>
                  {" "}
                  🔹 {chiefComplaint?.severity} 🔹 {chiefComplaint?.bodysite} 🔹{" "}
                  {chiefComplaint?.verification}{" "}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" fontSize={"12px"}>
                  {" "}
                  🔹 {chiefComplaint?.remarks}
                </Typography>
              </Grid>
            </Grid>
          // </Paper>
        ))}
    </>
  );
};

export default ChiefComplaintsCpg;
