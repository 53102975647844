import { Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";

const AllergyCpg = ({ allergies }) => {
  return (
    <>
      {allergies &&
        allergies.map((allergy) => (
          // <Paper elevation={0} sx={{ marginTop: "5px" }}>
            <Grid container xs={12} marginTop={1}>
              <Grid item xs={12}>
                <Typography variant="caption" fontSize={"12px"} fontWeight={600} pl={0.8}>
                  {" "}
                   {allergy?.allergy}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" fontSize={"12px"}>
                  {" "}
                  🔹 {allergy?.severity} 🔹 {allergy?.type} 🔹{" "}
                  {allergy?.verification}
                </Typography>
              </Grid>
              <Grid item xs={12}>
              {
                    allergy?.reactions.length > 0 &&
                    allergy?.reactions.map((reaction) => (
                      <Typography variant="caption" fontSize={"12px"} >
                        {" "}
                        🔹 {reaction}
                      </Typography>
                    ))
                  }
              </Grid>
            </Grid>
          // </Paper>
        ))}
    </>
  );
};

export default AllergyCpg;
