import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  defaultReject,
  defaultState,
  fetchData,
  urls,
  dbName,
  query_ids,
  queries,
  utcTOLocal,
  getTenantId,
  getFacilityId,
  getGlobalValueFrom_LclStrg_And_QryStrg,
} from "../../utils";
import { childJSON } from "./orders/child";
// import { generateJSON } from "./orders/saveJson";

const DIAGNOSIS_MASTERS = createAsyncThunk(
  `DiagnosisMasterSlice/diagnosis`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { input_text = "" } = payload;
      // const data = await fetchData(
      //   { body: JSON.stringify(queries.Diagnosis(input_text)) },
      //   __readDocumentUrl__
      // );

      //const { tenantid = "", facilityid = "", lang = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: "289ee358-17b6-43ac-898b-ebf30143b4eb",
        filter: {
          searchcode: `%${input_text}%`,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      
      let arr = [];

      data.map((val) => {
        arr.push({
          value: val?._id,
          label:
            val?.icdcode && val?.icdname
              ? val?.icdcode + " - " + val?.icdname
              : "",
        });
      });
      return {
        ...defaultState.List,
        data: arr,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const DIAGNOSIS_SAVE = createAsyncThunk(
  `DiagnosisMasterSlice/savediagnosis`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { list } = payload;

      let body = generateJSON(list);
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.saveDiagnosis
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const generateJSON = (dataList) => {
  console.log("datalistDiagnosis", dataList);
  let payload = dataList.map((data) => {
    let extraJson = {};
    let filter = {};
    if (data.editId) {
      extraJson = {
        _id: data.editId,
      };

      filter = { filter: { _id: data.editId } };
    }
    //  else {
    //   extraJson = {
    //     recordeddate: data.recordeddate,
    //     practitioner_id: data.practitioner_id,
    //     practitionerrole_id: data.practitionerrole_id,
    //   };
    // }
    return {
      db_name: dbName,
      entity: "CA_Diagnosis",
      is_metadata: true,
      ...filter,
      metadataId: process.env.REACT_APP_METADATAID,
      metadata_dbname: process.env.REACT_APP_METADATA_DBNAME,
      doc: {
        ...extraJson,
        //id: 12,
        //identifier: [],
        //careplan_id: [],
        //ImmunizationRecommendation_id: [],
        //medicationrequest_id: [],
        //nutritionorder_id: [],
        //servicerequest_id: [],
        //status: "",
        //category: "",
        diagcode: data?.diagcode,
        patient_id: data?.patient_id,
        //group_id: 0,
        //device_id: 0,
        //location_id: 0,
        encounter_id: data?.encounter_id,
        // effective: [
        // 	{
        // 		effectivedatetime: 0,
        // 		effectiveperiod: "",
        // 	},
        // ],
        //issued: "",
        //practitioner_id: [data.practitioner_id],
        practitioner_id: data?.practitioner_id,
        // practitionerrole_id: [data.practitionerrole_id],
        practitionerrole_id: data?.practitionerrole_id,
        //organization_id: data.Organization_id,
        //careteam_id: [],
        //specimen_id: 0,
        //observation_id: 0,
        //ImagingStudy: 0,
        // media: [
        // 	{
        // 		comment: "",
        // 		media_id: 0,
        // 	},
        // ],
        //conclusion: "",
        //conclusioncode: "",
        //presentedform: "",
        use: data?.use,
        rank: data?.rank,
        codeset: data?.codeset,
        workrelated: data?.workrelated,
        confidential: data?.confidential,
        // Notifieable: data.Notifieable,
        notifiable: data?.Notifieable,
        // VerificationSTatus: data.VerificationSTatus,
        verificationstatus: data?.VerificationSTatus,
        clinicalstatus: data?.clinicalstatus,
        //OnSet: data.onsetperiod,
        onsetval: data?.onsetperiod ?? 1,
        onsetuom: data?.onsetuom ?? "CodingMaster/11058", //"CodingMaster/11058",
        //onsetuom: "CodingMaster/11058",
        // Abatement: data.abatementdatetime,
        abatementval: data?.abatementdatetime ?? 1,
        // abatementuom: data?.abatementuom ?? "CodingMaster/11058",
        abatementuom: data?.abatementuom,
        manifestation: data?.manifestation,
        // EvidenceReference: data.EvidenceReference,
        evidencereference: data?.EvidenceReference,
        grade: data?.grade,
        // Notes: data.Notes,
        notes: data?.Notes,
        facilityid: data?.Organization_id,
        tenantid: data?.tenantid,
      },
    };
  });

  return payload;
};
const DELETE_DIAGNOSIS = createAsyncThunk(
  `DiagnosisMasterSlice/deletediagnosis`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { id } = payload;
      const data = await fetchData(
        { body: JSON.stringify(queries.deleteDiagnosis(id)) },
        urls.delete_document
      );
      let arr = [];
      // data.result.map((val) => {
      data.map((val) => {
        arr.push({
          value: val._id,
          label: `${val?.code} - ${val.shortdesc}`,
        });
      });
      return {
        ...defaultState.List,
        data: arr,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const DIAGNOSIS_READ = createAsyncThunk(
  `patientDetailsMastersSlice/diagnosisread`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const { patient_id } = payload;

      const body = {
        db_name: dbName,
        filter: {
          patientid: patient_id,
          tenantid: "",
          facilityid: "",
        },
        //queryid: "b92c85e8-a43b-481d-b43b-332a82c2c961",
        queryid: "e4feb145-2a2e-4f22-9c1c-5f668b96bd4b",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      let res = generateResJson1(data);

      return {
        ...defaultState.List,
        data: res,
      };
      // return {
      //   ...defaultState.List,
      //   data: data,

      // };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const generateResJson1 = (dataList, state) => {
  console.log(dataList);
  const list = dataList.map((val) => {
    // let clicnicalstatushistory = val?.clicnicalstatushistory?.map((val) => {
    //   return {
    //     ...val,
    //     personName: val?.Person?.[0]?.name?.text ?? "",
    //   };
    // });
    //

    let day = val?.onsetval;
    let currdate = undefined; //val?.onsetval; onsetval need to find length

    let freqValue = {
      label: val?.onsetuom?.display,
      value: val?.onsetuom?._id,
    };
    let createddate = utcTOLocal(val?.createddate, "DD MMM, YYYY");
    let abateday = val?.abatementval;
    let abatecurrdate = undefined; //val?.abatementval;

    let abatefreqValue = {
      label: val?.abatementuom?.display,
      value: val?.abatementuom?._id,
    };
    const createddateInt = val?.createddate ?? Date.now();
    const updatedateInt = val?.updatedate ?? Date.now();
    return {
      // diagnosis: state?.Diagnosis_masters_value?.data.filter(
      //   (sys) => val.diagcode?.indexOf(sys.value) > -1
      // )?.[0],
      diagnosis: {
        value: val?.diagcode?._id,
        label: val?.diagcode?.icdname,
      },
      // use: state?.Use_masters?.data.filter(
      //   (sys) => val?.use?.indexOf(sys.label) > -1
      // )?.[0],

      use: {
        value: val?.use?._id ?? "",
        label: val?.use?.display ?? "",
      },
      // verification: state?.Verification_masters_dianosis?.data.filter(
      //   (sys) => val.VerificationSTatus?.indexOf(sys.label) > -1
      // )?.[0],

      verification: {
        value: val?.verificationstatus?._id ?? "",
        label: val?.verificationstatus?.display ?? "",
      },
      numberOfDay: "",
      month: {},
      headerShow: true,
      onserOption: "",
      // rank: state?.Diagnosis_rank_masters?.data.filter(
      //   (sys) => val.rank?.indexOf(sys.label) > -1
      // )?.[0],

      rank: {
        value: val?.rank?._id ?? "",
        label: val?.rank?.display ?? "",
      },
      codeSet: val?.codeset,
      onsetcalenderShow: true,
      abatementcalenderShow: true,
      workRelated: val?.workrelated,
      confidential: val?.confidential,
      notifiable: val?.Notifieable,
      // clinicalStatus: state?.Clinical_masters?.data.filter(
      //   (sys) => val.clinicalstatus?.indexOf(sys.label) > -1
      // )?.[0],

      clinicalStatus: {
        value: val?.clinicalstatus?._id ?? "",
        label: val?.clinicalstatus?.display ?? "",
      },
      // manifestation: state?.Manifestation_masters?.data.filter(
      //   (sys) => val.manifestation?.indexOf(sys.label) > -1
      // )?.[0],

      manifestation: {
        value: val?.manifestation?._id ?? "",
        label: val?.manifestation?.display ?? "",
      },
      evidenceReference: val?.evidencereference,
      // gradeCode: state?.Grade_code?.data.filter(
      //   (sys) => val.grade[0].code?.indexOf(sys.label) > -1
      // )?.[0],

      gradeCode: {
        value: val?.grade?.[0]?.gradecode?._id ?? "",
        label: val?.grade?.[0]?.gradecode?.display ?? "",
      },
      // gradeType: state?.Grade_type?.data.filter(
      //   (sys) => val.grade[0].Type?.indexOf(sys.label) > -1
      // )?.[0],

      gradeType: {
        value: val?.grade?.[0]?.gradetype?._id ?? "",
        label: val?.grade?.[0]?.gradetype?.display ?? "",
      },
      // assesmentReference: state?.Assesment_masters?.data.filter(
      //   (sys) => val.grade[0].AssesmentRef?.indexOf(sys.label) > -1
      // )?.[0],

      assesmentReference: {
        value: val?.grade?.[0]?.assesmentref?._id ?? "",
        label: val?.grade?.[0]?.assesmentref?.display ?? "",
      },
      notes: val?.notes,

      onset: {
        day: currdate === undefined ? day.toString() : "01",
        freq: currdate === undefined ? freqValue : {},
        currentDate: currdate !== undefined ? val?.onsetval : "",
        isCalenderEnabled: currdate !== undefined ? true : false,
        calenderIconEnabled: currdate === undefined ? false : true,
      },

      abatement: {
        day: abatecurrdate === undefined ? abateday.toString() : "01",
        freq: abatecurrdate === undefined ? abatefreqValue : {},
        currentDate: abatecurrdate !== undefined ? val?.abatementval : "",
        isCalenderEnabled: abatecurrdate !== undefined ? true : false,
        calenderIconEnabled:
          abatecurrdate === undefined && !abateday ? true : false,
      },
      modifying_factor: val?.modifying_factor,
      createdName: val?.practitioner_id?.[0]?.name?.[0] ?? "",
      createdAt: createddate,
      statusType: "Inactive",
      isNew: createddateInt === updatedateInt,
      isModified: createddateInt !== updatedateInt,
      createddate: createddateInt,
      updatedate: updatedateInt,
      editData: val?._id,
    };
  });
  return list;
};
const DIAGNOSIS_USE = createAsyncThunk(
  `DiagnosisMasterSlice/diagnosisuse`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      let payloadData = {
        db_name: dbName,
        queryid: query_ids["GetAllMasters"],
        filter: {
          type: [
            "ENCOUNTERUSE"
          ],
          tenantid:  getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"tenantid"}),
          facilityid: getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"facilityid"}),
          lang: "en",
        },
      }
      let data = await fetchData(
        { body: JSON.stringify(payloadData) },
        urls.readQdmQueries
      );
      data = data?.[0]?.ENCOUNTERUSE?.Value ?? [];
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const DIAGNOSIS_DURATION = createAsyncThunk(
  `DiagnosisMasterSlice/diagnosisduration`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      let payloadData = {
        db_name: dbName,
        queryid: query_ids["GetAllMasters"],
        filter: {
          type: [
            "DURATIONUOM"
          ],
          tenantid:  getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"tenantid"}),
          facilityid: getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"facilityid"}),
          lang: "en",
        },
      }
      let data = await fetchData(
        { body: JSON.stringify(payloadData) },
        urls.readQdmQueries
      );
      data = data?.[0]?.DURATIONUOM?.Value ?? [];
      console.log(data,"datadata")
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const DiagnosisMasterSlice = createSlice({
  name: "DiagnosisMasterApiSlice",
  initialState: {
    Diagnosis_masters: {
      ...defaultState.List,
    },
    Diagnosis_save: {
      ...defaultState.List,
    },
    Delete_diagnosis: {
      ...defaultState.Info,
    },
    diagnosisread: {
      ...defaultState.Info,
    },
    diagnosisuse: {
      ...defaultState.Info,
    },
    diagnosisduration: {
      ...defaultState.Info,
    },
  },
  extraReducers: {
    //Diagnosis_Masters
    [DIAGNOSIS_MASTERS.fulfilled]: (state, action) => {
      state.Diagnosis_masters = action?.payload ?? {};
    },
    [DIAGNOSIS_MASTERS.pending]: (state, action) => {
      state.Diagnosis_masters.loading = true;
    },
    [DIAGNOSIS_MASTERS.rejected]: (state, action) => {
      state.Diagnosis_masters = action?.payload ?? {};
    },

    /* DIAGNOSIS_MASTERS SAVE */
    [DIAGNOSIS_SAVE.fulfilled]: (state, action) => {
      state.Diagnosis_save = action?.payload ?? {};
    },
    [DIAGNOSIS_SAVE.pending]: (state, action) => {
      state.Diagnosis_save.loading = true;
    },
    [DIAGNOSIS_SAVE.rejected]: (state, action) => {
      state.Diagnosis_save = action?.payload ?? {};
    },

    /* DELETE_CC */
    [DELETE_DIAGNOSIS.fulfilled]: (state, action) => {
      state.Delete_diagnosis = action?.payload ?? {};
    },
    [DELETE_DIAGNOSIS.pending]: (state, action) => {
      state.Delete_diagnosis.loading = true;
    },
    [DELETE_DIAGNOSIS.rejected]: (state, action) => {
      state.Delete_diagnosis = action?.payload ?? {};
    },
    // DIAGNOSIS_READ
    [DIAGNOSIS_READ.fulfilled]: (state, action) => {
      state.diagnosisread = action?.payload ?? {};
    },
    [DIAGNOSIS_READ.pending]: (state, action) => {
      state.diagnosisread.loading = true;
    },
    [DIAGNOSIS_READ.rejected]: (state, action) => {
      state.diagnosisread = action?.payload ?? {};
    },
    // DIAGNOSIS_USE
    [DIAGNOSIS_USE.fulfilled]: (state, action) => {
      state.diagnosisuse = action?.payload ?? [];
    },
    [DIAGNOSIS_USE.pending]: (state, action) => {
      state.diagnosisuse.loading = true;
    },
    [DIAGNOSIS_USE.rejected]: (state, action) => {
      state.diagnosisuse = action?.payload ?? [];
    },
    // DIAGNOSIS_USE
    [DIAGNOSIS_DURATION.fulfilled]: (state, action) => {
      state.diagnosisduration = action?.payload ?? [];
    },
    [DIAGNOSIS_DURATION.pending]: (state, action) => {
      state.diagnosisduration.loading = true;
    },
    [DIAGNOSIS_DURATION.rejected]: (state, action) => {
      state.diagnosisduration = action?.payload ?? [];
    },
  },
});

export const DiagnosisMastersActions = {
  DIAGNOSIS_MASTERS,
  DIAGNOSIS_SAVE,
  DELETE_DIAGNOSIS,
  DIAGNOSIS_READ,
  DIAGNOSIS_USE,
  DIAGNOSIS_DURATION,
};
export default DiagnosisMasterSlice;
