import {
  Divider,
  Text,
  Div,
  Button,
  Card,
  Image,
  H2,
} from "qdm-component-library";
import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { UIColor } from "../../../themes/theme";
import classes from "./styles.json";
import iconHigh from "../../../../src/assets/img/svg/icons8-high priority red.svg";
import iconHighAlert from "../../../../src/assets/img/svg/icons8-high priority white.svg";
import { AlertProps } from "../../../utils";
import { Alerts } from "../../../components/global/alerts";
import { 
  QueueManagementContext,
  VisitDetails,
  DrawerMeetingContext, 
  AlertContext } from "../../../context";
import { actionButtonClick } from "../../../utils";
import { AppRoutes } from "../../../router/routes";
import React from "react";
import actions from "../../../redux/actions"

const ToAttend = (props) => {
  const {
    setloader
  } = React.useContext(QueueManagementContext);
  const navigate = useNavigate();
  const loggedUserInfo = useSelector(
    (state) => state?.authSlice?.loggedUserInfo
  );
  const drawerMeetingContext = React.useContext(DrawerMeetingContext);
  const { setSnack } = React.useContext(AlertContext);
  const { setVisitDetails } = React.useContext(VisitDetails);
  const [state, setState] = React.useState({
    view: null,
    modify: null,
    cancel: null,
    anchorEl: null,
    anchorE2: null,
    openDialog: null,
    openUpload: null,
    cancelbookingreason: "",
    cancelData: {},
    uploadData: {},
    spicemanCollectedData: {},
    cacellationType:process.env.REACT_APP_CANCEL_APPMNT_TYPE_SINGLE
  });
  const dispatch = useDispatch();
  const { parent_id, markHighPriority, assignReassignPractitioner } = props;

  const redirectTreatmentPlan = (
    personId,
    patientId,
    appointmentId,
    encounterId,
    type,
    pId,
    encounter_id,
    app,
    ticketId,
    queueId,
    mrn,
  ) => {
    navigate(AppRoutes.patientDetail, {
      state: {
        encounter_id,
        personId,
        patientId,
        appointmentId,
        encounterId,
        type,
        pId,
        app_id: app?.info?._id,
        ticketId,
        allCurrentData: app,
        referralId: app?.inputdoc?.referralTrxId
          ? app?.inputdoc?.referralTrxId
          : app?.inputdoc?.referralid,
        mrnId : mrn
      },
    });
    // setpreviousQueue(queueId);
  };
  
  const registerPatient = (
    personId,
    patientId,
    appointmentId,
    isPatient,
    mobileNo,
    action,
    ticketid
  ) => {
    setVisitDetails({
      encounter: {
        mode: "direct",
      },
      practitioner: {},
      device: {},
      helthcare: {},
      accompained: {},
    });
    if (isPatient) {
      navigate(AppRoutes.visitRegistration, {
        state: {
          personId,
          from: 1,
          patientId,
          appointmentId,
          Ispatient: true,
          isedit: action?.action == "VISIT REGISTER" ? false : true,
          isRevise: action?.action == "REVISE VISIT" ? true : false,
          patientData: mobileNo,
          stateAction: action?.action,
          stateEndPoint: action?.apiendpoint,
          stateBackEndCall: action?.backEndCall,
          stateTicketid: ticketid,
        },
      });
    } else {
      navigate(AppRoutes.assemblePatioentRegister, {
        state: {
          nextRoute: AppRoutes.visitRegistration,
          personId,
          patientId,
          from: 1,
          appointmentId,
          patientData: mobileNo,
          Ispatient: false,
          idedit: false,
          fromQueue: true,
          stateAction: action?.action,
          stateEndPoint: action?.apiendpoint,
          stateBackEndCall: action?.backEndCall,
          stateTicketid: ticketid,
        },
      });
    }
  };
  const actionButtonClickEvent = async (
    action,
    apiendpoint,
    backEndCall,
    ticketid,
    queue,
    queueId
  ) => {
    //setloader(true);
    if (queue === process.env.REACT_APP_REFERRAL_QUEUE) {
      if (action === "") {
      } else if (
        action === process.env.REACT_APP_REFERRAL_CANCEL ||
        action === process.env.REACT_APP_REFERRAL_ON_HOLD ||
        action === process.env.REACT_APP_REFERRAL_REJECT
      ) {
        setState({
          openDialog: true,
          cancelData: {
            action: action,
            apiEndPoint: apiendpoint,
            backendCall: backEndCall,
            ticketId: ticketid,
            queue: queue,
            queueId: queueId,
          },
        });
      } else if (action === process.env.REACT_APP_REFERRAL_UPLOAD_CONSENT) {
        setState({
          openUpload: true,
          uploadData: {
            action: action,
            apiEndPoint: apiendpoint,
            backendCall: backEndCall,
            ticketId: ticketid,
            queue: queue,
            queueId: queueId,
          },
        });
      } else {
        let fetchVal = await actionButtonClick(
          action,
          apiendpoint,
          backEndCall,
          ticketid,
          queue,
          queue,
          queueId
        );
        if (!fetchVal?.error) {
          setloader(true);
          props?.getTabData(props?.selectedQueue,
            props.item?.queueid,
            props?.scenarioId
          )
          // let data = await GetQueueData();
          // setAllData(data.everyData);
          // setDisplayData(data.getDisplayData);
          // setPreviousData(data.getPreviousData);
          // setpreviousQueue(queueId);
          setloader(false);
        }
      }
    } else if (queue === process.env.REACT_APP_DIALYSIS_QUEUE) {
      if (action == "") {
      } else if (
        action === process.env.REACT_APP_REFERRAL_CANCEL ||
        action === process.env.REACT_APP_DIALYSIS_PAUSE ||
        action === process.env.REACT_APP_DIALYSIS_NO_SHOW ||  action === process.env.REACT_APP_DIALYSIS_CHECKED_IN_CANCEL
      ) {
        setState({
          openDialog: true,
          cancelData: {
            action: action,
            apiEndPoint: apiendpoint,
            backendCall: backEndCall,
            ticketId: ticketid,
            queue: queue,
            queueId: queueId,
          },
        });
      } else {
        let fetchVal = await actionButtonClick(
          action,
          apiendpoint,
          backEndCall,
          ticketid,
          queue,
          queue,
          queueId
        );
        if (!fetchVal?.error) {
          setloader(true);
          props?.getTabData(props?.selectedQueue,
            props.item?.queueid,
            props?.scenarioId
          )
          // let data = await GetQueueData();
          // setAllData(data.everyData);
          // setDisplayData(data.getDisplayData);
          // setPreviousData(data.getPreviousData);
          // setpreviousQueue(queueId);
          setloader(false);
        }
      }
    } else if (queue === process.env.REACT_APP_NURSING_PROCEDURE_QUEUE) {
      if (action == "") {
      } else if (
        action === process.env.REACT_APP_NURSING_PROCEDURE_QUEUE_CANCEL_ADMINISTER
      ) {
        setState({
          openDialog: true,
          cancelData: {
            action: action,
            apiEndPoint: apiendpoint,
            backendCall: backEndCall,
            ticketId: ticketid,
            queue: queue,
            queueId: queueId,
          },
        });
      } else {
        let fetchVal = await actionButtonClick(
          action,
          apiendpoint,
          backEndCall,
          ticketid,
          queue,
          queue,
          queueId
        );
        if (!fetchVal?.error) {
          setloader(true);
          
          props?.getTabData(props?.selectedQueue,
            props.item?.queueid,
            props?.scenarioId
          )
          // let data = await GetQueueData();
          // setAllData(data.everyData);
          // setDisplayData(data.getDisplayData);
          // setPreviousData(data.getPreviousData);
          // setpreviousQueue(queueId);
          setloader(false);
        }
      }
    }
    else {
      if (action == "") {
      } else if (action === "CANCEL") {
        setState({
          openDialog: true,
          cancelData: {
            action: action,
            apiEndPoint: apiendpoint,
            backendCall: backEndCall,
            ticketId: ticketid,
            queue: queue,
            queueId: queueId,
          },
        });
      } else {
        let fetchVal = await actionButtonClick(
          action,
          apiendpoint,
          backEndCall,
          ticketid,
          queue,
          queue,
          queueId
        );
        if (!fetchVal?.error) {
          setloader(true);
          
          props?.getTabData(props?.selectedQueue,
            props.item?.queueid,
            props?.scenarioId
          )
          // let data = await GetQueueData();
          // setAllData(data.everyData);
          // setDisplayData(data.getDisplayData);
          // setPreviousData(data.getPreviousData);
          // setpreviousQueue(queueId);
          setloader(false);
        }
      }
    }
    //setloader(false);
  };
  const attendPatient = async (
    personId,
    patientId,
    appointmentId,
    encounterId,
    type,
    pId,
    encounter_id,
    app,
    ticketId
  ) => {
    let status;
    let isCompleted;
    if (app[0]?.type === "nurse" || app[0]?.type === "Nurse") {
      status = "inprogress_nurse";
      isCompleted = false;
    } else if (app[0]?.type === "doctor" || app[0]?.type === "Doctor") {
      status = "doctor_inprogress";
      isCompleted = false;
    }
    // await dispatch(
    //   actions.UPDATE_APPOINTMENT_STATUS({
    //     appointmentId,
    //     status,
    //     isCompleted,
    //   })
    // );
    navigate(AppRoutes.patientDetail, {
      state: {
        encounter_id,
        personId,
        patientId,
        appointmentId,
        encounterId,
        type,
        pId,
        app_id: app?.info?._id,
        ticketId,
        allCurrentData: app,
      },
    });
  };

  return (
    <Div
      id={`${parent_id}${props.index}_queue_attend_parent_div`}
      style={classes.wrapper}
      key={props.index}
    >
      <Div
        id={`${parent_id}${props.index}_queue_attend_parent_sub_div`}
        style={classes.header}
      >
        <Grid container>
          {(
            <H2
              id={`${parent_id}${props.index}_queue_attend_title_h2`}
              className="pc_semibold"
              style={classes.headerTitle}
            >
              {props.item?.title ?? "To Attend"}
            </H2>
          )}
        </Grid>

        <Grid container justifyContent="space-around">
          <Grid item>
            {props.item?.field5 && (
              <Div
                id={`${parent_id}${props.index}_queue_attend_token_number_div`}
                className="pc_medium"
                style={classes.tokenNumber}
              >
                {props.item?.field5}
              </Div>
            )}
          </Grid>

          <Grid item>
            {props?.item?.field6 && (
              <Div
                id={`${parent_id}${props.index}_queue_attend_token_number_div`}
                className="pc_medium"
                style={classes.appNumber}
              >
                {props?.item?.field6}
              </Div>
            )}
          </Grid>
        </Grid>
      </Div>

      <Card
        id={`${parent_id}${props.index}_queue_attend_parent_card`}
        style={classes.cardStyle}
      >
        {!markHighPriority && (
          <Div style={classes.highAlertPosition}>
            <Div
              style={{
                backgroundColor:
                  props.item?.patientPriority?.priority?.value === "High"
                    ? "#FF4D4A"
                    : "#F9E6E6",

                padding: "5px 8px 3px",

                borderRadius: "50%",

                cursor: "pointer",
              }}

              // onClick={(event) =>

              //   props.handleHighAlertPop(

              //     event,

              //     props.item?.title,

              //     props.item?.encounter,

              //     props.item?.patientPriority,

              //     props.item?.totalData,

              //     props.item?.role_

              //   )

              // }
            >
              <Image
                inLineStyles={{ height: "17px", width: "16px" }}
                src={
                  props.item?.patientPriority?.priority?.value === "High"
                    ? iconHighAlert
                    : iconHigh
                }
              />
            </Div>
          </Div>
        )}

        <Div
          id={`${parent_id}${props.index}_queue_attend_attendbody_div`}
          style={classes.attendBody}
        >
          {(
            <Div
              id={`${parent_id}${props.index}_queue_attend_profile_div`}
              style={{ ...classes.attendBodyAvatar, marginTop: 20 }}
            >
              <Image
                id={`${parent_id}${props.index}_queue_attend_profilepic_image`}
                src={props.item?.field9 ??
                  "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                }
                alt={props.item?.name ?? "profilePic"}
                style={{
                  border: props.item?.isEmergency ? "1px dashed #FF4D4A" : "0",
                }}
              />

              {props.item?.isEmergency && (
                <Div
                  id={`${parent_id}${props.index}_queue_attend_emergency_div`}
                  style={classes.emergencyDiv}
                >
                  <Text
                    id={`${parent_id}${props.index}_queue_attend_emergency_text`}
                    className="pc_medium"
                    style={{
                      ...classes.emergencyText,

                      fontSize: 10,
                    }}
                  >
                    EMERGENCY
                  </Text>
                </Div>
              )}
            </Div>
          )}

          {props.item?.name && (
            <H2
              id={`${parent_id}${props.index}_queue_attend_previousNextBodyName_h2`}
              className="pc_semibold"
              style={classes.previousNextBodyName}
            >
              {props.item?.name}
            </H2>
          )}
          {/* {props.item?.name && (
            <H2
              id={`${parent_id}${props.index}_queue_attend_previousNextBodyName_h2`}
              className=""
              style={classes.previousNextSubBodyName}
            >
              {props.item?.field2 +" | "+ props.item?.field4 +" | "+props.item?.field3+" | "+props.item?.field10  }
            </H2>
          )} */}

          {props.item?.role && (
            <Text
              id={`${parent_id}${props.index}_queue_attend_previousNextBodyRole_text`}
              className="pc_regular"
              style={{
                ...classes.previousNextBodyRole,

                margin: "5px 0px",
              }}
            >
              {props.item?.role}

              {props.item?.isRegister && ` • ${props.item?.isRegister}`}
            </Text>
          )}

          {props.item?.start && (
            <Text
              id={`${parent_id}${props.index}_queue_attend_previousNextBodyRole_start_text`}
              className="pc_regular"
              style={{
                ...classes.previousNextBodyRole,

                margin: "5px 0px",
              }}
            >
              {props.item?.start}
            </Text>
          )}

          {props.item?.primaryAction && (
            <Button
              id={`${parent_id}${props.index}_queue_attend_primarybutton_button`}
              className="pc_medium"
              type="button"
              // disabled={true}

              name={
                // props.item?.consultationMode !== "Direct Consultation"

                //   ? "Join Now"

                //   :

                props.item?.primaryAction?.action
              }
              variant="outlined"
              size={14}
              backgroundColor={UIColor.secondaryButtonColor.color}
              onClick={() => {if (
                props.item?.primaryAction?.action === "VISIT REGISTER" ||
                props.item?.primaryAction?.action === "VIEW VISIT" ||
                props.item?.primaryAction?.action === "REVISE VISIT" ||
                props.item?.primaryAction?.action === "NURSE SIGNOFF" ||
                props.item?.primaryAction?.action === "DOCTOR SIGNOFF" ||
                props.item?.primaryAction?.action === "ATTEND"
              ) {
                if (
                  props.item?.consultationmode?.display ===
                    "Video" &&
                  !drawerMeetingContext?.open
                ) {
                  navigate(AppRoutes.videoCall, {
                    state: {
                      appointmentId: props.item.inputdoc?.appointmentid,
                      name: loggedUserInfo?.data?.name?.text,
                      role: loggedUserInfo?.data?.role,
                      data: props.item,
                      action: props.item?.primaryAction,
                      queueId: props.item?.queueid
                    },
                  });
                } else {
                  // if (
                  //   props.item?.resourcerole?.display == "nurse" ||
                  //   props.item?.resourcerole?.display == "doctor" ||
                  //   props.item?.resourcerole?.display == "Nurse" ||
                  //   props.item?.resourcerole?.display == "Doctor"
                  // ) {
                  //   attendPatient(
                  //     props.item?.personid,
                  //     props.item?.inputdoc?.patientid
                  //       ? props.item?.inputdoc?.patientid
                  //       : props.item?.inputdoc?.patientId,
                  //     props.item?.inputdoc?.appointmentid,
                  //     props.item?.inputdoc?.encounterid,
                  //     props.item?.inputdoc?.resource_type,
                  //     props.item?.inputdoc?.patientid
                  //       ? props.item?.inputdoc?.patientid
                  //       : props.item?.inputdoc?.patientId,
                  //     props.item?.inputdoc?.encounterid,
                  //     props.item,
                  //     props.item?.ticketId
                  //   );
                  //   } else 
                    if (props.item?.queueMetaData?.queuename?.queuename === process.env.REACT_APP_APPOINTMENT_QUEUE &&
                (props.item?.primaryAction?.action === process.env.REACT_APP_NURSE_SIGN_OFF || props.item?.primaryAction?.action === process.env.REACT_APP_DOCTOR_SIGN_OFF)){
                  const roleName = localStorage.getItem("RoleName");
                        let firstpass = dispatch(
                          actions.FIRST_PASS_API({
                            actionType: "SERVE",
                            appId: props.item?.inputdoc?.appointmentid,
                            servingType: (roleName).toLowerCase() === "nurse" ? "VITAL" : "GENERAL" 
                          })
                        );
                 
                  redirectTreatmentPlan(
                  props.item?.personid,
                  props.item?.inputdoc?.patientid
                    ? props.item?.inputdoc?.patientid
                    : props.item?.inputdoc?.patientId,
                  props.item?.inputdoc?.appointmentid,
                  props.item?.inputdoc?.encounterid,
                  props.item?.inputdoc?.resource_type,
                  props.item?.inputdoc?.patientid
                    ? props.item?.inputdoc?.patientid
                    : props.item?.inputdoc?.patientId,
                  props.item?.inputdoc?.encounterid,
                  props.item,
                  props.item?.ticketId,
                  props.item?.queueId,
                  props.item?.field2
                    );
                  } else {
                    registerPatient(
                      props.item?.personid,
                      props.item?.inputdoc?.patientid,
                      props.item.inputdoc?.appointmentid,
                      props.item?.inputdoc?.patientid ? true : false,
                      props.item?.mobileNo,
                      props?.item?.primaryAction,
                      props.item?.ticketId || props.item?.info?.ticketid
                    );
                  }
                }
              } else {
                actionButtonClickEvent(
                  props?.item?.primaryAction.action,
                  props?.item?.primaryAction.apiendpoint,
                  props?.item?.primaryAction.backEndCall,
                  props.item?.ticketId,
                  props.item?.queueMetaData?.queuename?.queuename,
                  props.item?.queueId
                );
              }
            }}
              inLineStyles={{
                marginTop: "10px",

                width: "100%",

                borderRadius: 8,

                fontSize: 13,
              }}
            />
          )}
        </Div>

        <Divider
          id={`${parent_id}${props.index}_queue_attend_divider`}
          style={classes.divider}
        />

        {!props.queueWrite && (
          <Grid
            id={`${parent_id}${props.index}_queue_secondarybutton_div`}
            spacing={2}
            container
          >
            <Grid item xs={!assignReassignPractitioner ? 6 : 12}>
              <Div
                id={`${parent_id}${props.index}_queue_attend_secondarybutton_div`}
                style={classes.secondaryButton}
              >
                {props.item?.secondaryAction && (
                  <Button
                    id={`${parent_id}${props.index}_queue_attend_secondarybutton_button`}
                    type="button"
                    name={""}
                    variant="text"
                    size={12}
                    onClick={() => {if (
                      props.item?.secondaryAction?.action === "VISIT REGISTER" ||
                      props.item?.secondaryAction?.action === "VIEW VISIT" ||
                      props.item?.secondaryAction?.action === "REVISE VISIT" ||
                      props.item?.secondaryAction?.action === "NURSE SIGNOFF" ||
                      props.item?.secondaryAction?.action === "DOCTOR SIGNOFF" || 
                      props.item?.secondaryAction?.action === "ATTEND"
                    ) {
                      if (
                        props.item?.consultationmode?.display ===
                          "Video" &&
                        !drawerMeetingContext?.open
                      ) {
                        navigate(AppRoutes.videoCall, {
                          state: {
                            appointmentId: props.item.inputdoc?.appointmentid,
                            name: loggedUserInfo?.data?.name?.text,
                            role: loggedUserInfo?.data?.role,
                            data: props.item,
                            action: props.item?.secondaryAction,
                            queueId: props.item?.queueid
                          },
                        });
                      } else {
                        // if (
                        //   props.item?.resourcerole?.display == "nurse" ||
                        //   props.item?.resourcerole?.display == "doctor" ||
                        //   props.item?.resourcerole?.display == "Nurse" ||
                        //   props.item?.resourcerole?.display == "Doctor"
                        // ) {
                        //   attendPatient(
                        //     props.item?.personid,
                        //     props.item?.inputdoc?.patientid
                        //       ? props.item?.inputdoc?.patientid
                        //       : props.item?.inputdoc?.patientId,
                        //     props.item?.inputdoc?.appointmentid,
                        //     props.item?.inputdoc?.encounterid,
                        //     props.item?.inputdoc?.resource_type,
                        //     props.item?.inputdoc?.patientid
                        //       ? props.item?.inputdoc?.patientid
                        //       : props.item?.inputdoc?.patientId,
                        //     props.item?.inputdoc?.encounterid,
                        //     props.item,
                        //     props.item?.ticketId
                        //   );
                        //   } else 
                          if (props.item?.queueMetaData?.queuename?.queuename === process.env.REACT_APP_APPOINTMENT_QUEUE &&
                      (props.item?.secondaryAction?.action === process.env.REACT_APP_NURSE_SIGN_OFF || props.item?.secondaryAction?.action === process.env.REACT_APP_DOCTOR_SIGN_OFF)){
                        const roleName = localStorage.getItem("RoleName");
                        let firstpass = dispatch(
                          actions.FIRST_PASS_API({
                            actionType: "SERVE",
                            appId: props.item?.inputdoc?.appointmentid,
                            servingType: (roleName).toLowerCase() === "nurse" ? "VITAL" : "GENERAL" 
                          })
                        );
                 
                        redirectTreatmentPlan(
                        props.item?.personid,
                        props.item?.inputdoc?.patientid
                          ? props.item?.inputdoc?.patientid
                          : props.item?.inputdoc?.patientId,
                        props.item?.inputdoc?.appointmentid,
                        props.item?.inputdoc?.encounterid,
                        props.item?.inputdoc?.resource_type,
                        props.item?.inputdoc?.patientid
                          ? props.item?.inputdoc?.patientid
                          : props.item?.inputdoc?.patientId,
                        props.item?.inputdoc?.encounterid,
                        props.item,
                        props.item?.ticketId,
                        props.item?.queueId,
                        props.item?.field2
                          );
                        } else {
                          registerPatient(
                            props.item?.personid,
                            props.item?.inputdoc?.patientid,
                            props.item.inputdoc?.appointmentid,
                            props.item?.inputdoc?.patientid ? true : false,
                            props.item?.mobileNo,
                            props?.item?.secondaryAction,
                            props.item?.ticketId || props.item?.info?.ticketid
                          );
                        }
                      }
                    } else {
                      actionButtonClickEvent(
                        props?.item?.secondaryAction.action,
                        props?.item?.secondaryAction.apiendpoint,
                        props?.item?.secondaryAction.backEndCall,
                        props.item?.ticketId,
                        props.item?.queueMetaData?.queuename?.queuename,
                        props.item?.queueId
                      );
                    }
                  }}
                    inLineStyles={{
                      color: "#F58B00",

                      fontWeight: "600",

                      display: "flex",

                      alignItems: "center",

                      margin: "auto",
                    }}
                  >
                    {props.item?.secondaryAction?.icon && (() => {
                      let svg = props.item?.secondaryAction?.icon;
                      let blob = new Blob([svg], { type: "image/svg+xml" });
                      let url = URL.createObjectURL(blob);
                      return(<Image
                        style={{ width: 16, height: 16 }}
                        src={url}
                        alt={"image"}
                      />)}
                    )}

                    <Text
                      className="pc_medium"
                      style={classes.secondaryButtonName}
                    >
                      {props.item?.secondaryAction?.action}
                    </Text>
                  </Button>
                )}
              </Div>
            </Grid>

            {!assignReassignPractitioner && (
              <>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ margin: "10px 0px" }}
                />

                <Grid item xs={5}>
                  <Div>
                    {/* {props.item?.assignButtonName && ( */}

                    <>
                      <Button
                        id={`${parent_id}${props.index}_queue_attend_secondarybutton_button`}
                        type="button"
                        name={""}
                        variant="text"
                        size={12}
                        // onClick={

                        //   props.item?.assignButtonAction

                        //     ? () => props.item?.assignButtonAction()

                        //     : null

                        // }

                        inLineStyles={{
                          color: "#F58B00",

                          fontWeight: "600",

                          display: "flex",

                          alignItems: "center",

                          margin: "auto",
                        }}
                      >
                        {props.item?.assignButtonIcon && (
                          <Image
                            style={{ width: 16, height: 16 }}
                            src={props.item?.assignButtonIcon}
                            alt={"image"}
                          />
                        )}

                        <Text
                          className="pc_medium"
                          style={classes.assignButtonName}
                        >
                          {props.item?.assigned ? "Re Assign" : "Assign"}
                        </Text>
                      </Button>
                    </>

                    {/* )} */}
                  </Div>
                </Grid>
              </>
            )}
          </Grid>
        )}
      </Card>
    </Div>
  );
};

export default ToAttend;
