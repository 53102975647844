import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  urls,
  defaultState,
  defaultReject,
  fetchData,
  query_ids,
  dbName,
  registrationRequests,
  queries,
  _lang_,
  __tenantid__,
  axiosCall,
  getFacilityId,
} from "../../utils";

import { generatereferalresponse } from "./orders/referralresponse";

const GET_FACILITY_TYPE = createAsyncThunk(
  `referalslice/GetFacilityType`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,

        filter: {
          type: ["ORGTYPE"],
          tenantid: __tenantid__,
          facilityid: getFacilityId(),
          lang: "en",
        },
        queryid: "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      let arr = [];
      data[0]?.ORGTYPE?.Value.map((val) => {
        arr.push({
          value: val._id,
          label: val?.display ?? "",
        });
      });
      return {
        ...defaultState.List,
        data: arr,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_REFERRAL_LOCATION = createAsyncThunk(
  `referalslice/get_referal_location`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { orgid = "", encounterid = "" } = payload;
      const body = {
        db_name: dbName,
        filter: {
          orgid: orgid,
        },
        queryid: "fc186ba3-3e6b-4eec-b39a-1b51533aede7",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );

      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_FACILITY_NAME = createAsyncThunk(
  `referalslice/get_facility_name`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { orgtype = "", extintid = "",facilityId ="" } = payload;
      const body = {
        db_name: dbName,
        filter: {
          orgtype: orgtype,
          extintid: extintid,
          facilityid:facilityId,
        },
        queryid: "df4dd8f2-7122-4939-8db7-2840b3ebd9d1",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );

      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_TOFACILITY_NAME = createAsyncThunk(
  `referalslice/get_to_facility_name`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { orgtype = "", extintid = "" } = payload;
      const body = {
        db_name: dbName,
        filter: {
          orgtype: orgtype,
          extintid: extintid,
        },
        queryid: "92d0d26f-c0b1-44e1-bd27-36dd9a7d7fae",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );

      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_PRACTITIONER_TYPE = createAsyncThunk(
  `referalslice/get_practitioner_type`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { speciality = "", id = "" } = payload;
      const body = {
        db_name: dbName,
        filter: {
          specialityid: speciality,
        },
        queryid: "8ea2b6fb-b5bd-4714-81b2-84fda9c8dfe8",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );

      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_PRACTITIONER_NAME = createAsyncThunk(
  `referalslice/get_practitioner_name`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { speciality = "", roleid = "", locationid = "" } = payload;
      const body = {
        db_name: dbName,
        filter: {
          specialityid: speciality,
          roleid: roleid,
          locationid: locationid,
        },
        queryid: "b8942f08-1c65-4f59-a60d-d28b43f6e4a7",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );

      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const ORGANIZATION_REFERRAL_REGISTRATION = createAsyncThunk(
  `referalslice/save_referral`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      let { doc, _id } = payload;
      var body;
      if (_id) {
        body = [
          {
            db_name: dbName,
            entity: "ReferralTrx",
            is_metadata: true,
            filter: {
              _id: `${_id}`,
            },
            metadata_dbname: process.env.REACT_APP_META_DB_NAME,
            metadataId: process.env.REACT_APP_METADATA_ID,
            doc: doc,
          },
        ];
      } else {
        body = [
          {
            db_name: dbName,
            entity: "ReferralTrx",
            is_metadata: true,
            metadata_dbname: process.env.REACT_APP_META_DB_NAME,
            metadataId: process.env.REACT_APP_METADATA_ID,
            doc: doc,
          },
        ];
      }

      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.saveReferral
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const ORGANIZATION_REFERRALOUT_REGISTRATION = createAsyncThunk(
  `referalslice/save_referral_out`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      let { doc, _id } = payload;
      var body;
      if (_id) {
        body = [
          {
            db_name: dbName,
            entity: "ReferralTrx",
            is_metadata: true,
            filter: {
              _id: `${_id}`,
            },
            metadata_dbname: process.env.REACT_APP_META_DB_NAME,
            metadataId: process.env.REACT_APP_METADATA_ID,
            doc: doc,
          },
        ];
      } else {
        body = [
          {
            db_name: dbName,
            entity: "ReferralTrx",
            is_metadata: true,
            metadata_dbname: process.env.REACT_APP_META_DB_NAME,
            metadataId: process.env.REACT_APP_METADATA_ID,
            doc: doc,
          },
        ];
      }

      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.saveReferralOut
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_ALL_REFERRAL = createAsyncThunk(
  `referalslice/get_all_referral`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { refid = "" } = payload;
      const body = {
        db_name: dbName,
        filter: {
          refid: refid,
        },
        queryid: "77f697ca-4817-4adf-8ab0-76aca6f7ce29",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      let res = [];
      if (data?.length > 0) {
        res = await generatereferalresponse(data?.[0]);
      }
      return {
        ...defaultState.Info,
        data: res,
        response: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_CASEMANAGERS = createAsyncThunk(
  `referalslice/get_casemanager`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        "filter": {
            "tenantid": "",
            "facilityid": ""
        },
          "queryid": "c7103d81-e17f-4fce-bef4-eb328249c9ad"
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data: data,
        response: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_HUBS = createAsyncThunk(
  `referalslice/get_hubs`,
  async (payload = {}, { rejectWithValue }) => {
    try {
     
      const body = {
        db_name: dbName,
        queryid: "b01580af-59a8-4340-bc09-476dd83fecd1",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data: data,
        response: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_REFERRAL_TYPE = createAsyncThunk(
  `referalslice/GetReferralType`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        queryid: "6a12c15f-f53c-4acd-9c94-8f7a25e74414",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      // let arr = [];
      // data[0]?.ORGTYPE?.Value.map((val) => {
      //   arr.push({
      //     value: val._id,
      //     label: val?.display ?? "",
      //   });
      // });
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const GET_VALIDATION_FOR_REFERRAL_ACTIONS = createAsyncThunk(
  `referalslice/GetValidationForReferralActions`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { orgId = "", practitionerId = "",specialityId="",roleId="",facilityType="" } = payload;
      const body = {
        "db_name": dbName,
        "filter": {
        "orgId": orgId,//"Organization/10161",
        "speciality": specialityId,// "CodingMaster/10452",
        "practitioner": practitionerId,// "Practitioner/10416",
          "roleId": roleId,// "IDM_PermissionRoleMapping/10044",
          "facilityType": facilityType,//"CodingMaster/10008"
        },
        "queryid": "63c270d9-fd5c-4b86-a9fc-08afcb3cef43"
        }
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_SERVICE_REQUEST = createAsyncThunk(
  `referalslice/get_service_request`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { reftypeid = "" } = payload;
      const body = {
        db_name: dbName,
        filter: {
          reftypeid: reftypeid,
        },
        queryid: "17c064c7-aba7-46b7-bb01-a9131c77e65b",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      let res = [];
      if (data?.length > 0) {
        res = await generatereferalresponse(data?.[0]);
      }
      return {
        ...defaultState.Info,
        data: res,
        response: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const referalslice = createSlice({
  name: "referalApiSlice",
  initialState: {
    GetFacilityType: {
      ...defaultState.Info,
    },
    get_referal_location: {
      ...defaultState.Info,
    },
    get_facility_name: {
      ...defaultState.Info,
    },
    get_practitioner_type: {
      ...defaultState.Info,
    },
    get_practitioner_name: {
      ...defaultState.Info,
    },
    save_referral: {
      ...defaultState.Info,
    },
    get_all_referral: {
      ...defaultState.Info,
    },
    get_casemanager: {
      ...defaultState.Info,
    },
    get_hubs: {
      ...defaultState.Info,
    },
    GetReferralType: {
      ...defaultState.Info,
    },
    GetValidationForReferralActions: {
      ...defaultState.Info,
    },
    get_service_request: {
      ...defaultState.Info,
    },
    save_referral_out: {
      ...defaultState.Info,
    },
    get_to_facility_name: {
      ...defaultState.Info,
    },
  },
  extraReducers: {
    /* ENCOUNTER_BASED_CC */
    [GET_FACILITY_TYPE.fulfilled]: (state, action) => {
      state.GetFacilityType = action?.payload ?? {};
    },
    [GET_FACILITY_TYPE.pending]: (state, action) => {
      state.GetFacilityType.loading = true;
    },
    [GET_FACILITY_TYPE.rejected]: (state, action) => {
      state.GetFacilityType = action?.payload ?? {};
    },
    //GET_REFERRAL_LOCATION
    [GET_REFERRAL_LOCATION.fulfilled]: (state, action) => {
      state.get_referal_location = action?.payload ?? {};
    },
    [GET_REFERRAL_LOCATION.pending]: (state, action) => {
      state.get_referal_location.loading = true;
    },
    [GET_REFERRAL_LOCATION.rejected]: (state, action) => {
      state.get_referal_location = action?.payload ?? {};
    },
    //GET_FACILITY_NAME
    [GET_FACILITY_NAME.fulfilled]: (state, action) => {
      state.get_facility_name = action?.payload ?? {};
    },
    [GET_FACILITY_NAME.pending]: (state, action) => {
      state.get_facility_name.loading = true;
    },
    [GET_FACILITY_NAME.rejected]: (state, action) => {
      state.get_facility_name = action?.payload ?? {};
    },
    //GET_PRACTITIONER_TYPE
    [GET_PRACTITIONER_TYPE.fulfilled]: (state, action) => {
      state.get_practitioner_type = action?.payload ?? {};
    },
    [GET_PRACTITIONER_TYPE.pending]: (state, action) => {
      state.get_practitioner_type.loading = true;
    },
    [GET_PRACTITIONER_TYPE.rejected]: (state, action) => {
      state.get_practitioner_type = action?.payload ?? {};
    },
    //GET_PRACTITIONER_NAME
    [GET_PRACTITIONER_NAME.fulfilled]: (state, action) => {
      state.get_practitioner_name = action?.payload ?? {};
    },
    [GET_PRACTITIONER_NAME.pending]: (state, action) => {
      state.get_practitioner_name.loading = true;
    },
    [GET_PRACTITIONER_NAME.rejected]: (state, action) => {
      state.get_practitioner_name = action?.payload ?? {};
    },
    //ORGANIZATION_REFERRAL_REGISTRATION
    [ORGANIZATION_REFERRAL_REGISTRATION.fulfilled]: (state, action) => {
      state.save_referral = action?.payload ?? {};
    },
    [ORGANIZATION_REFERRAL_REGISTRATION.pending]: (state, action) => {
      state.save_referral.loading = true;
    },
    [ORGANIZATION_REFERRAL_REGISTRATION.rejected]: (state, action) => {
      state.save_referral = action?.payload ?? {};
    },
    //GET_ALL_REFERRAL
    [GET_ALL_REFERRAL.fulfilled]: (state, action) => {
      state.get_all_referral = action?.payload ?? {};
    },
    [GET_ALL_REFERRAL.pending]: (state, action) => {
      state.get_all_referral.loading = true;
    },
    [GET_ALL_REFERRAL.rejected]: (state, action) => {
      state.get_all_referral = action?.payload ?? {};
    },
    [GET_CASEMANAGERS.fulfilled]: (state, action) => {
      state.get_casemanager = action?.payload ?? {};
    },
    [GET_CASEMANAGERS.pending]: (state, action) => {
      state.get_casemanager.loading = true;
    },
    [GET_CASEMANAGERS.rejected]: (state, action) => {
      state.get_casemanager = action?.payload ?? {};
    },
    [GET_HUBS.fulfilled]: (state, action) => {
      state.get_hubs = action?.payload ?? {};
    },
    [GET_HUBS.pending]: (state, action) => {
      state.get_hubs.loading = true;
    },
    [GET_HUBS.rejected]: (state, action) => {
      state.get_hubs = action?.payload ?? {};
    },
    /* GET_REFERRAL_TYPE */
    [GET_REFERRAL_TYPE.fulfilled]: (state, action) => {
      state.GetReferralType = action?.payload ?? {};
    },
    [GET_REFERRAL_TYPE.pending]: (state, action) => {
      state.GetReferralType.loading = true;
    },
    [GET_REFERRAL_TYPE.rejected]: (state, action) => {
      state.GetReferralType = action?.payload ?? {};
    },
    //GET_VALIDATION_FOR_REFERRAL_ACTIONS
    [GET_VALIDATION_FOR_REFERRAL_ACTIONS.fulfilled]: (state, action) => {
      state.GetValidationForReferralActions = action?.payload ?? {};
    },
    [GET_VALIDATION_FOR_REFERRAL_ACTIONS.pending]: (state, action) => {
      state.GetValidationForReferralActions.loading = true;
    },
    [GET_VALIDATION_FOR_REFERRAL_ACTIONS.rejected]: (state, action) => {
      state.GetValidationForReferralActions = action?.payload ?? {};
    },
    //GET_SERVICE_REQUEST
    [GET_SERVICE_REQUEST.fulfilled]: (state, action) => {
      state.get_service_request = action?.payload ?? {};
    },
    [GET_SERVICE_REQUEST.pending]: (state, action) => {
      state.get_service_request.loading = true;
    },
    [GET_SERVICE_REQUEST.rejected]: (state, action) => {
      state.get_service_request = action?.payload ?? {};
    },
     //ORGANIZATION_REFERRALOUT_REGISTRATION
     [ORGANIZATION_REFERRALOUT_REGISTRATION.fulfilled]: (state, action) => {
      state.save_referral_out = action?.payload ?? {};
    },
    [ORGANIZATION_REFERRALOUT_REGISTRATION.pending]: (state, action) => {
      state.save_referral_out.loading = true;
    },
    [ORGANIZATION_REFERRALOUT_REGISTRATION.rejected]: (state, action) => {
      state.save_referral_out = action?.payload ?? {};
    },
     //GET_TOFACILITY_NAME
     [GET_TOFACILITY_NAME.fulfilled]: (state, action) => {
      state.get_to_facility_name = action?.payload ?? {};
    },
    [GET_TOFACILITY_NAME.pending]: (state, action) => {
      state.get_to_facility_name.loading = true;
    },
    [GET_TOFACILITY_NAME.rejected]: (state, action) => {
      state.get_to_facility_name = action?.payload ?? {};
    }
  },
});


const referalActions = {
  GET_FACILITY_TYPE,
  GET_REFERRAL_LOCATION,
  GET_FACILITY_NAME,
  GET_PRACTITIONER_TYPE,
  GET_PRACTITIONER_NAME,
  ORGANIZATION_REFERRAL_REGISTRATION,
  GET_ALL_REFERRAL,
  GET_CASEMANAGERS,
  GET_HUBS,
  GET_REFERRAL_TYPE,
  GET_VALIDATION_FOR_REFERRAL_ACTIONS,
  GET_SERVICE_REQUEST,
  ORGANIZATION_REFERRALOUT_REGISTRATION,
  GET_TOFACILITY_NAME,
};

export { referalActions };

export default referalslice;
