import React, { useState } from "react";
import { Avatar, Card, Row, Text, H6, Div, Image } from "qdm-component-library";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Grid } from "@material-ui/core";
import { DetailCard } from "./card";
import { UIColor } from "../../../themes/theme";


const useStyles = makeStyles((theme) => ({
  root: {
     height: "100%", 
     cursor: "pointer",
  }
}));
export default function PatientDeatils(props) {
  const styles = {
    card: {
      //   height: "100%",
      padding: "12px",
      //   boxShadow: "0px 10px 25px #0000000A",
      //   border: "1px solid #E0E0E0",
      //   borderRadius: 8,
      top: "203px",
      left: "815px",
      width: "309px",
      // height: "165px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #E0E0E0",
      borderRadius: "8px",
      opacity: 1,
      boxShadow: "none",
    },
    title: {
      fontSize: 14,
      margin: "inherit",
      textAlign: "left",
      font: "normal normal medium 14px/19px Poppins",
      letterSpacing: "0px",
      color: "#101010",
      opacity: 1,
      display: "flex",
    },
    subTitle: {
      fontSize: 12,
      color: "#646464",
    },
    dot: {
      width: "6px",
      height: "6px",
      background: "#6F6F6F 0% 0% no-repeat padding-box",
      opacity: 1,
      //margin: "3px 5px",
      margin: "auto",
      borderRadius: "8px",
      display: "inline-block",
    },
    profilePic: {
      margin: 0,
      width: 50,
      height: 50,
      borderRadius: "8px",
      backgroundColor: UIColor.lineBorderFill.color,
    },
    name: {
      fontSize: 14,
      textAlign: "left",
      font: "normal normal medium 14px/19px Poppins",
      letterSpacing: "0px",
      color: "#101010",
      opacity: 1,
      margin: "inherit",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      width: "130px",

    },
  };
  const { parent_id } = props;
  const classes = useStyles();

  return (
    <div
      id={`${parent_id}${props.title.replaceAll(" ", "_")}_parent_div`}
      className={classes?.root}
    >
      <Card
        id={`${parent_id}${props.title.replaceAll(" ", "_")}_parent_card`}
        style={styles.card}
      >
        <Row
          id={`${parent_id}${props.title.replaceAll(" ", "_")}_parent_row`}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            id={`${parent_id}${props.title.replaceAll(" ", "_")}_sub_div`}
            md={6}
            lg={6}
          >
            <Row
              id={`${parent_id}${props.title.replaceAll(" ", "_")}_sub_row`}
              alignItems={"center"}
            >
              <Div
                id={`${parent_id}${props.title.replaceAll(
                  " ",
                  "_"
                )}_avatar_div`}
                style={{ position: "relative", marginRight: 12 }}
              >
                <Avatar
                  id={`${parent_id}${props.title.replaceAll(" ", "_")}_avatar`}
                  variant="square"
                  letter={props.name}
                  src={props?.patientPhoto}
                  alt="Image"
                  // inLineStyles={styles.profilePic}
                  style={{
                    ...styles.profilePic,
                    border: props?.isEmergency ? "1px dashed #FF4D4A" : "0",
                  }}
                />
              </Div>
              <div
                id={`${parent_id}${props.title.replaceAll(
                  " ",
                  "_"
                )}_sub_title_div`}
              >
                <div>
                  <H6
                    id={`${parent_id}${props.title.replaceAll(
                      " ",
                      "_"
                    )}_sub_title_h6`}
                    className="pc_medium"
                    style={styles.title}
                  >
                    <H6 style={styles.name} title={props.name}>
                      {" "}
                      {props.name}
                    </H6>
                    &nbsp;•&nbsp;{props.age}&nbsp;
                    {props.gender ? " | " + props.gender : ""}
                  </H6>
                </div>
                <Text
                  id={`${parent_id}${props.title.replaceAll(
                    " ",
                    "_"
                  )}_sub_title_text`}
                  className="pc_regular"
                  style={styles.subTitle}
                >
                  {props.patientId}
                  {/* <span style={styles.dot} />  */}
                  {" • "}
                  {props.mobileNumber}
                </Text>
              </div>
            </Row>
          </div>
        </Row>

        <Divider style={{ marginTop: "10px" }} />

        <Grid container spacing={2} style={{ paddingTop: "15px" }}>
          <Grid item xs={6}>
            <DetailCard
              title="Specialty Name"
              subTitle={props.specialityName}
              icon="images/Mask Group 26.svg"
            />
          </Grid>
          <Grid item xs={6}>
            <DetailCard
              title="Clinic"
              subTitle={props.clinic}
              icon="images/icons8-address_1.svg"
            />
          </Grid>
          {props?.showPractitioner && (
            <Grid item xs={6}>
              <DetailCard
                title="Practitioner"
                subTitle={props?.practitioner ?? ""}
                icon="images/Mask Group 42.svg"
              />
            </Grid>
          )}
        </Grid>
      </Card>
    </div>
  );
}
PatientDeatils.defaultProps = {
  name: "",
  age: "",
  gender: "",
  patientId: "",
  mobileNumber: "",
  specialityName: "",
  clinic: "",
};
