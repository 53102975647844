import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  urls,
  defaultState,
  defaultReject,
  fetchData,
  dbName,
} from "../../../utils";

const PRE_ASS_SIGN_IN = createAsyncThunk(
  `preAss_signIn/preAss_AuthSlice`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        { body: JSON.stringify(payload) },
        urls.preAss_signIN,
        "",
        { tenantid: 1234 }
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const PRE_ASS_SIGN_UP = createAsyncThunk(
  `preAss_signUp/preAss_AuthSlice`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        { body: JSON.stringify(payload) },
        urls.preAss_signUp,
        "",
        { apiKey: process.env.REACT_APP_LOGIN_API_KEY }
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
// const GET_ADVANCE_NO = createAsyncThunk(
//   `advanceNumber/preAss_AuthSlice`,
//   async (payload = {}, { rejectWithValue }) => {
//     try {
//       const { patientId, encounterId } = payload;

//       const body = {
//         db_name: dbName,
//         filter: {
//           tenantid: "",
//           facilityid: "",
//           patientid: patientId,
//           encounter: encounterId,
//         },
//         queryid: "98bbc74c-3c02-4039-b467-983d71e6cb47",
//       };
//       const data = await fetchData(
//         { body: JSON.stringify(body) },
//         urls.readQdmQueries
//       );
//       console.log(data, "redux billing");
//       return {
//         ...defaultState.List,
//         data: data,
//       };
//     } catch (error) {
//       alert(error.message);
//       return rejectWithValue({
//         ...defaultReject,
//         message: error.message,
//       });
//     }
//   }
// );
// const PAYMENT_DROPDOWN = createAsyncThunk(
//   `advanceNumber/payment_dropDown`,
//   async (payload = {}, { rejectWithValue }) => {
//     try {
//       const { tenantid } = payload;

//       const body = {
//         db_name: dbName,
//         filter: {
//           type: ["RC_PAYMENTMODE"],
//           tenantid: "1234",
//           facilityid: "",
//           lang: "en",
//         },
//         queryid: "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e",
//       };
//       const data = await fetchData(
//         { body: JSON.stringify(body) },
//         urls.readQdmQueries
//       );
//       return {
//         ...defaultState.List,
//         data: data,
//       };
//     } catch (error) {
//       alert(error.message);
//       return rejectWithValue({
//         ...defaultReject,
//         message: error.message,
//       });
//     }
//   }
// );

// const SEARCH_CODE_DESC = createAsyncThunk(
//   `advanceNumber/Search_Code_Desc`,
//   async (payload = {}, { rejectWithValue }) => {
//     try {
//       const { encounterId, patientId } = payload;

//       const body = {
//         db_name: dbName,
//         filter: {
//           tenantid: "",
//           facilityid: "",
//           patientid: patientId,
//           encounter: encounterId,
//           searchcode: "%%",
//           isbilled: false,
//         },
//         queryid: "c4847003-26cf-4b0c-b341-eec1d6747545",
//       };
//       const data = await fetchData(
//         { body: JSON.stringify(body) },
//         urls.readQdmQueries
//       );
//       return {
//         ...defaultState.List,
//         data: data,
//       };
//     } catch (error) {
//       alert(error.message);
//       return rejectWithValue({
//         ...defaultReject,
//         message: error.message,
//       });
//     }
//   }
// );
const PreAss_AuthSlice = createSlice({
  name: "preAss_AuthSlice",
  initialState: {
    preAss_signIn: {
      ...defaultState.Info,
    },
    preAss_signUp: {
      ...defaultState.Info,
    },
    // advanceNumber: {
    //   ...defaultState.Info,
    // },
    // payment_dropDown: {
    //   ...defaultState.Info,
    // },
    // Search_Code_Desc: {
    //   ...defaultState.Info,
    // },
  },
  extraReducers: {
    // PRE_ASS_SIGN_IN
    [PRE_ASS_SIGN_IN.fulfilled]: (state, action) => {
      state.preAss_signIn = action?.payload ?? {};
    },
    [PRE_ASS_SIGN_IN.pending]: (state, action) => {
      state.preAss_signIn.loading = true;
    },
    [PRE_ASS_SIGN_IN.rejected]: (state, action) => {
      state.preAss_signIn = action?.payload ?? {};
    },
    // PRE_ASS_SIGN_UP
    [PRE_ASS_SIGN_UP.fulfilled]: (state, action) => {
      state.preAss_signUp = action?.payload ?? {};
    },
    [PRE_ASS_SIGN_UP.pending]: (state, action) => {
      state.preAss_signUp.loading = true;
    },
    [PRE_ASS_SIGN_UP.rejected]: (state, action) => {
      state.preAss_signUp = action?.payload ?? {};
    },
    // GET_ADVANCE_NO
    // [GET_ADVANCE_NO.fulfilled]: (state, action) => {
    //   state.advanceNumber = action?.payload ?? {};
    // },
    // [GET_ADVANCE_NO.pending]: (state, action) => {
    //   state.advanceNumber.loading = true;
    // },
    // [GET_ADVANCE_NO.rejected]: (state, action) => {
    //   state.advanceNumber = action?.payload ?? {};
    // },
    // //PAYMENT_DROPDOWN
    // [PAYMENT_DROPDOWN.fulfilled]: (state, action) => {
    //   state.payment_dropDown = action?.payload ?? {};
    // },
    // [PAYMENT_DROPDOWN.pending]: (state, action) => {
    //   state.payment_dropDown.loading = true;
    // },
    // [PAYMENT_DROPDOWN.rejected]: (state, action) => {
    //   state.payment_dropDown = action?.payload ?? {};
    // },
    // //SEARCH_CODE_DESC
    // [SEARCH_CODE_DESC.fulfilled]: (state, action) => {
    //   state.Search_Code_Desc = action?.payload ?? {};
    // },
    // [SEARCH_CODE_DESC.pending]: (state, action) => {
    //   state.Search_Code_Desc.loading = true;
    // },
    // [SEARCH_CODE_DESC.rejected]: (state, action) => {
    //   state.Search_Code_Desc = action?.payload ?? {};
    // },
  },
});

export const preAss_auth_Actions = {
  PRE_ASS_SIGN_IN,
  PRE_ASS_SIGN_UP,
  // GET_ADVANCE_NO,

  // PAYMENT_DROPDOWN,
  // SEARCH_CODE_DESC,
};

export default PreAss_AuthSlice;
