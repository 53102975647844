import { Avatar, Drawer, Grid, useTheme } from "@mui/material";
import React, { useState } from "react";
import { drawerContext } from "./context";
import { DrawerProps } from "./utils/constants";
import CloseIcon from "@mui/icons-material/Close";
export function Appdrawer(props) {
  const theme = useTheme();
  const [drawerState, setDrawerState] = useState({
    open: false,
    direction: DrawerProps.direction.right,
    variant: DrawerProps.variant.temporary,
    isLarge: null,
    component: <>Hai</>,
  });

  const close = () => {
    setDrawerState({ ...drawerState, open: false, component: <></> });
  };

  return (
    <drawerContext.Provider value={{ ...drawerState, setDrawerState }}>
      {props.children}
      <Drawer
        anchor={DrawerProps.direction.right}
        variant={DrawerProps.variant.temporary}
        open={drawerState.open}
        onClose={close}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Avatar
          sx={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            position: "fixed",
            right: drawerState.isLarge ? "1010px" : "410px", // Adjust based on state
            cursor: "pointer",
            top: 10,
            objectFit: "scale-down",
            backgroundColor: "#fff",
            ...(drawerState.isLarge &&
              {
                // Apply extra-large styles when needed
              }),
            [theme.breakpoints.down("xs")]: {
              right: "313px",
            },
          }}
          onClick={close}
        >
          <CloseIcon sx={{color:"black"}} />
        </Avatar>
        <Grid
          sx={{
            maxWidth: 400,
            minWidth: 400,
            background: "#fff",
            overflowX: "hidden",
            ...(drawerState.isLarge && {
              maxWidth: 1000,
              minWidth: 1000,
            }),
            [theme.breakpoints.down("xs")]: {
              maxWidth: 300,
              minWidth: 300,
            },
          }}
        >
          {drawerState.component}
        </Grid>{" "}
      </Drawer>
    </drawerContext.Provider>
  );
}
