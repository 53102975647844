import React from "react";
import Fab from "@material-ui/core/Fab";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";
// import { UIColor } from "../../utils";
// import SearchIcon from '@mui/icons-material/Search';
import { UIColor } from "../../../../themes/theme";
const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    zIndex: 1,
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    "& .MuiFab-root": {
      // backgroundColor: theme.palette.primary.main,
      backgroundColor: UIColor.secondaryColor.color,
      color: theme.palette.common.white,
    },
  },
}));

const SearchFab = (props) => {
  const classes = useStyles();
  const { parent_id } = props;
  return (
    <div
      style={{ bottom: props.bottom && "88px" }}
      id={`${parent_id}_parent_div`}
      className={classes.root}
    >
      <div style={{ float: "left" }}>
        <Fab
          id={`${parent_id}_parent_fab`}
          aria-label="add"
          onClick={props.handleAction2}
        >
          {props.icon2}
        </Fab>
      </div>
      <div style={{ marginLeft: "70px" }}>
        <Fab
          id={`${parent_id}_parent_fab`}
          aria-label="add"
          onClick={props.handleAction}
        >
          {props.icon}
        </Fab>
      </div>
    </div>
  );
};

SearchFab.propTypes = {
  icon: PropTypes.any,
  handleAction: PropTypes.func,
};

export default SearchFab;
