import React, { useState } from "react";
import { Text, Div, Card, Image, H2, SideMenu } from "qdm-component-library";
import classes from "./styles.json";
import { actionButtonClick, GetQueueData } from "../../../utils";
import {
  QueueManagementContext,
  DrawerMeetingContext,
  VisitDetails,
} from "../../../context";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { actions } from "primarycare-binder";
import { AppRoutes } from "../../../router/routes";
import QueueCard from "../../../components/queueCard";
import CancelBookingPage from "../../../components/queueCard/cancelBooking";
import { Grid } from "@material-ui/core";
import repeatIcon from "../../../assets/img/svg/icons8-repeat.svg";
import changeUserIcon from "../../../assets/img/svg/icons8-change-user.svg";
 
const UpNext = (props) => {
  const navigate = useNavigate();
  const drawerMeetingContext = React.useContext(DrawerMeetingContext);
  const { setVisitDetails } = React.useContext(VisitDetails);
  const dispatch = useDispatch();
  const loggedUserInfo = useSelector(
    (state) => state?.authSlice?.loggedUserInfo
  );
 
  const {
    allData,
    displayData,
    previousData,
    loader,
    setAllData,
    setDisplayData,
    setPreviousData,
    setloader,
  } = React.useContext(QueueManagementContext);
  const [state, setState] = useState({
    openDialog: null,
    cancelbookingreason: "",
    cancelData: {},
  });
  const { parent_id, markHighPriority } = props;
 
  const attendPatient = async (
    personId,
    patientId,
    appointmentId,
    encounterId,
    type,
    pId,
    encounter_id,
    ticketId,
    app
  ) => {
    let status;
    let isCompleted;
    if (app[0]?.type === "nurse") {
      status = "inprogress_nurse";
      isCompleted = false;
    } else if (app[0]?.type === "doctor") {
      status = "doctor_inprogress";
      isCompleted = false;
    }
 
    // await dispatch(
    //   actions.UPDATE_APPOINTMENT_STATUS({
    //     appointmentId,
    //     status,
    //     isCompleted,
    //   })
    // );
 
    navigate(AppRoutes.patientDetail, {
      state: {
        encounter_id,
        personId,
        patientId,
        appointmentId,
        encounterId,
        type,
        pId,
        app_id: app?.info?._id,
        ticketId,
        allCurrentData: app,
      },
    });
  };
 
  const registerPatient = (
    personId,
    patientId,
    appointmentId,
    isPatient,
    mobileNo,
    action,
    ticketid
  ) => {
    setVisitDetails({
      encounter: {
        mode: "direct",
      },
      practitioner: {},
      device: {},
      helthcare: {},
      accompained: {},
    });
    if (isPatient) {
      navigate(AppRoutes.visitRegistration, {
        state: {
          personId,
          from: 1,
          patientId,
          appointmentId,
          Ispatient: true,
          isedit: false,
          isRevise: false,
          patientData: mobileNo,
          stateAction: action?.action,
          stateEndPoint: action?.apiendpoint,
          stateBackEndCall: action?.backEndCall,
          stateTicketid: ticketid,
        },
      });
    } else {
      navigate(AppRoutes.assemblePatioentRegister, {
        state: {
          nextRoute: AppRoutes.visitRegistration,
          personId,
          patientId,
          from: 1,
          appointmentId,
          patientData: mobileNo,
          Ispatient: false,
          idedit: false,
          fromQueue: true,
          stateAction: action?.action,
          stateEndPoint: action?.apiendpoint,
          stateBackEndCall: action?.backEndCall,
          stateTicketid: ticketid,
        },
      });
    }
  };
 
  const actionButtonClickEvent = async (
    action,
    apiendpoint,
    backEndCall,
    ticketid
  ) => {
    if (action === "CANCEL") {
      setState({
        openDialog: true,
        cancelData: {
          action: action,
          apiEndPoint: apiendpoint,
          backendCall: backEndCall,
          ticketId: ticketid,
        },
      });
    } else {
      setloader(true);
      let fetchVal = await actionButtonClick(
        action,
        apiendpoint,
        backEndCall,
        ticketid
      );
 
      if (!fetchVal.error) {
        let data = await GetQueueData();
        setAllData(data.everyData);
        setDisplayData(data.getDisplayData);
        setPreviousData(data.getPreviousData);
      }
      setloader(false);
    }
  };
  const handlesidebarCancelbooking = (id) => {
    if (id === "closing") {
      setState(() => ({ openDialog: false }));
    }
  };
 
  const handlecahngeCancelbooking = async () => {
    setState(() => ({ openDialog: false }));
 
    let fetchVal = await actionButtonClick(
      state?.cancelData?.action,
      state?.cancelData?.apiEndPoint,
      state?.cancelData?.backendCall,
      state?.cancelData?.ticketId,
      state?.cancelbookingreason?._id
    );
 
    if (!fetchVal.error) {
      setloader(true);
      let data = await GetQueueData();
      setAllData(data.everyData);
      setDisplayData(data.getDisplayData);
      setPreviousData(data.getPreviousData);
      setloader(false);
    }
  };
 
  const handleSelectCancelbooking = (v) => {
    setState((prevState) => ({
      ...prevState,
      cancelbookingreason: v,
    }));
  };
  return (
<Div
      id={`${parent_id}${props.index}_queue_next_parent_div`}
      style={classes.wrapper}
      key={props.index}
>
<Div
        id={`${parent_id}${props.index}_queue_next_parent_sub_div`}
        style={classes.header}
>
<Grid container>
          {(
<H2
              id={`${parent_id}${props.index}_queue_next_title_h2`}
              className="pc_semibold"
              style={classes.headerTitle}
>
              {props.item?.title ?? "Up Next"}
</H2>
          )}
</Grid>
<Grid container justifyContent="space-around">
<Grid item>
            {props.item?.field5 && (
<Div
                id={`${parent_id}${props.index}_queue_next_token_number_div`}
                className="pc_medium"
                style={classes.tokenNumber}
>
                {props.item?.field5}
</Div>
            )}
</Grid>
 
          <Grid item>
            {props?.item?.field6 && (
<Div
                id={`${parent_id}${props.index}_queue_next_token_number_div`}
                className="pc_medium"
                style={classes.appNumber}
>
                {props?.item?.field6}
</Div>
            )}
</Grid>
</Grid>
</Div>
{/* <Card
        id={`${parent_id}${props.index}_queue_next_parent_card`}
        style={classes.cardStyle}
>
<Div
          id={`${parent_id}${props.index}_queue_next_previousnextbody_div`}
          style={classes.previousNextBody}
>
          { (
<Div
              id={`${parent_id}${props.index}_queue_next_profile_div`}
              style={classes.previousNextBodyAvatar}
>
<Image
                id={`${parent_id}${props.index}_queue_next_profile_image`}
                src={props.item?.field9 ??
                  "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                }
                alt={props.item?.name ?? "profilePic"}
                style={{
                  border: props.item?.isEmergency ? "1px dashed #FF4D4A" : "0",
                }}
              />
              {props.item?.isEmergency && (
<Div
                  id={`${parent_id}${props.index}_queue_next_emergency_div`}
                  style={classes.emergencyDiv}
>
<Text
                    id={`${parent_id}${props.index}_queue_next_emergency_text`}
                    className="pc_medium"
                    style={classes.emergencyText}
>
                    EMERGENCY
</Text>
</Div>
              )}
</Div>
          )}
<Div
            id={`${parent_id}${props.index}_queue_next_sub_div`}
            style={{ textAlign: "left" }}
>
            {props.item?.name && (
<H2
                id={`${parent_id}${props.index}_queue_next_previousNextBodyName_h2`}
                className="pc_semibold"
                style={classes.previousNextBodyName}
>
                {props.item?.name}
</H2>
            )}
 
            {props.item?.role && (
<Text
                id={`${parent_id}${props.index}_queue_next_previousNextBodyRole_h2`}
                className="pc_regular"
                style={classes.previousNextBodyRole}
>
                {props.item?.role}
</Text>
            )}
 
            <br />
            {props.item?.start && (
<Text
                id={`${parent_id}${props.index}_queue_next_previousNextBodyRole_start_h2`}
                className="pc_regular"
                style={classes.previousNextBodyRole}
>
                {props.item?.start}
</Text>
            )}
</Div>
          {props.item?.action?.map((l, i) => {
            let svg = l.icon;
            let blob = new Blob([svg], { type: "image/svg+xml" });
            let url = URL.createObjectURL(blob);
 
            return (
<Div
                className={"reassign"}
                style={{ marginLeft: "auto" }}
                // onClick={
                //   () => {
                //     if (
                //       l.action == "VISIT REGISTER" ||
                //       l.action == "NURSE SIGNOFF" ||
                //       l.action == "DOCTOR SIGNOFF"
                //     ) {
                //       if (
                //         props.item?.totalData?.consultationMode ===
                //           "Video Consultation" &&
                //         !drawerMeetingContext?.open
                //       ) {
                //         navigate(AppRoutes.videoCall, {
                //           state: {
                //             appointmentId: props.item?.totalData.info?._key,
                //             name: loggedUserInfo?.data?.name?.text,
                //             role: loggedUserInfo?.data?.role,
                //             data: props.item?.totalData,
                //           },
                //         });
                //       } else {
                //         if (
                //           props.item?.totalData?.type === "nurse" ||
                //           props.item?.totalData?.type === "doctor"
                //         ) {
                //           attendPatient(
                //             props.item?.totalData.personId,
                //             props.item?.totalData.patientId,
                //             props.item?.totalData.token,
                //             props.item?.totalData.encounterId,
                //             props.item?.totalData?.type,
                //             props.item?.totalData.pId,
                //             props.item?.totalData.encounter_id,
                //             props.item?.totalData?.info?.ticketid,
                //             props.item?.totalData
                //           );
                //         } else {
                //           registerPatient(
                //             props.item?.totalData.personId,
                //             props.item?.totalData.patientId,
                //             props.item?.totalData?.info?._id,
                //             props.item?.totalData?.isPatient,
                //             props.item?.totalData?.mobileNo,
                //             props.item?.totalData?.info.action.find(
                //               (x) => x.action === "VISIT REGISTER"
                //             ),
                //             props.item?.totalData?.info?.ticketid
                //           );
                //         }
                //       }
                //     } else {
                //       actionButtonClickEvent(
                //         l.action,
                //         l.apiendpoint,
                //         l.backEndCall,
                //         props.item?.ticketId
                //       );
                //     }
                //   }
                //   // props?.assignButtonAction
                //   //   ? () => props.assignButtonAction()
                //   //   : null
                // }
                onClick={() => {
                  if (queue == "Appointment Queue") {
                    if (
                      l.action === "VISIT REGISTER" ||
                      l.action === "VIEW VISIT" ||
                      l.action === "REVISE VISIT" ||
                      l.action === "NURSE SIGNOFF" ||
                      l.action === "DOCTOR SIGNOFF"
                    ) {
                      if (
                        totalData?.consultationMode?.display ===
                          "Video Consultation" &&
                        !drawerMeetingContext?.open
                      ) {
                        navigate(AppRoutes.videoCall, {
                          state: {
                            appointmentId: totalData.inputdoc?.appointmentid,
                            name: loggedUserInfo?.data?.name?.text,
                            role: loggedUserInfo?.data?.role,
                            data: totalData,
                          },
                        });
                      } else {
                        if (
                          totalData?.resourcerole?.display == "nurse" ||
                          totalData?.resourcerole?.display == "doctor" ||
                          totalData?.resourcerole?.display == "Nurse" ||
                          totalData?.resourcerole?.display == "Doctor"
                        ) {
                          attendPatient(
                            totalData?.personid,
                            totalData?.inputdoc?.patientid
                              ? totalData?.inputdoc?.patientid
                              : totalData?.inputdoc?.patientId,
                            totalData?.inputdoc?.appointmentid,
                            totalData?.inputdoc?.encounterid,
                            totalData?.inputdoc?.resource_type,
                            totalData?.inputdoc?.patientid
                              ? totalData?.inputdoc?.patientid
                              : totalData?.inputdoc?.patientId,
                            totalData?.inputdoc?.encounterid,
                            totalData,
                            totalData?.ticketId
                          );
                          } else if (queue === process.env.REACT_APP_APPOINTMENT_QUEUE &&
                      (l.action === process.env.REACT_APP_NURSE_SIGN_OFF || l.action === process.env.REACT_APP_DOCTOR_SIGN_OFF)){
                        redirectTreatmentPlan(
                        totalData?.personid,
                        totalData?.inputdoc?.patientid
                          ? totalData?.inputdoc?.patientid
                          : totalData?.inputdoc?.patientId,
                        totalData?.inputdoc?.appointmentid,
                        totalData?.inputdoc?.encounterid,
                        totalData?.inputdoc?.resource_type,
                        totalData?.inputdoc?.patientid
                          ? totalData?.inputdoc?.patientid
                          : totalData?.inputdoc?.patientId,
                        totalData?.inputdoc?.encounterid,
                        totalData,
                        totalData?.ticketId,
                        queueId,
                        totalData?.field2
                          );
                        } else {
                          registerPatient(
                            totalData?.personid,
                            totalData?.inputdoc?.patientid,
                            totalData.inputdoc?.appointmentid,
                            totalData?.inputdoc?.patientid ? true : false,
                            totalData?.mobileNo,
                            l,
                            totalData?.ticketId || totalData?.info?.ticketid
                          );
                        }
                      }
                    } else {
                      actionButtonClickEvent(
                        l.action,
                        l.apiendpoint,
                        l.backEndCall,
                        ticketId,
                        queue
                      );
                    }
                  } else if (
                    queue === process.env.REACT_APP_REFERRAL_QUEUE &&
                    l.action === process.env.REACT_APP_REFERRAL_APPROVE
                  ) {
                    //  redirectScreen(
                    //   totalData?.personid,
                    //   totalData?.inputdoc?.patientid,
                    //   totalData.token,
                    //   totalData?.inputdoc?.encounterid,
                    //   totalData?.inputdoc?.resource_type,
                    //   totalData.pId,
                    //   totalData.encounter_id,
                    //   totalData,
                    //   totalData?.ticketid
                    //  )
                    handleApprove(totalData, queueId);
                  } else if (
                    queue === process.env.REACT_APP_REFERRAL_QUEUE &&
                    l.action === process.env.REACT_APP_REFERRAL_EDIT
                  ) {
                    //  redirectScreen(
                    //   totalData?.personid,
                    //   totalData?.inputdoc?.patientid,
                    //   totalData.token,
                    //   totalData?.inputdoc?.encounterid,
                    //   totalData?.inputdoc?.resource_type,
                    //   totalData.pId,
                    //   totalData.encounter_id,
                    //   totalData,
                    //   totalData?.ticketid
                    //  )
                    handleEdited(totalData, queueId);
                  } else if (
                    queue === process.env.REACT_APP_REFERRAL_QUEUE &&
                    l.action ===
                      process.env.REACT_APP_REFERRAL_UPDATETREATMENTPLAN
                  ) {
                    redirectTreatmentPlan(
                      totalData?.personid,
                      totalData?.inputdoc?.patientid
                        ? totalData?.inputdoc?.patientid
                        : totalData?.inputdoc?.patientId,
                      totalData?.inputdoc?.appointmentid,
                      totalData?.inputdoc?.encounterid,
                      totalData?.inputdoc?.resource_type,
                      totalData?.inputdoc?.patientid
                        ? totalData?.inputdoc?.patientid
                        : totalData?.inputdoc?.patientId,
                      totalData?.inputdoc?.encounterid,
                      totalData,
                      totalData?.ticketId,
                      queueId
                    );
                  } else if (
                    queue === process.env.REACT_APP_REFERRAL_QUEUE &&
                    l.action === process.env.REACT_APP_REFERRAL_EMR
                  ) {
                    redirectTreatmentPlan(
                      totalData?.personid,
                      totalData?.inputdoc?.patientid
                        ? totalData?.inputdoc?.patientid
                        : totalData?.inputdoc?.patientId,
                      totalData?.inputdoc?.appointmentid,
                      totalData?.inputdoc?.encounterid,
                      totalData?.inputdoc?.resource_type,
                      totalData?.inputdoc?.patientid
                        ? totalData?.inputdoc?.patientid
                        : totalData?.inputdoc?.patientId,
                      totalData?.inputdoc?.encounterid,
                      totalData,
                      totalData?.ticketId,
                      queueId
                    );
                  } else if (
                    queue === process.env.REACT_APP_REFERRAL_QUEUE &&
                    l.action === process.env.REACT_APP_REFERRAL_PRINT_FORM
                  ) {
                    printForm(totalData, queueId);
                  } else if (
                    queue === process.env.REACT_APP_REFERRAL_QUEUE &&
                    l.action === process.env.REACT_APP_REFERRAL_PRINT_PATIENT_FORM
                  ) {
                    referralPrint(totalData, queueId);
                  } else if (
                    queue === process.env.REACT_APP_REFERRAL_QUEUE &&
                    l.action === process.env.REACT_APP_REFERRAL_SCHEDULE
                  ) {
                    handleMultiBookData(props?.PatientData, queueId);
                  } else if (
                    queue === process.env.REACT_APP_DIALYSIS_QUEUE &&
                    l.action === process.env.REACT_APP_DIALYSIS_CHECK_IN
                  ) {
                    redirectVisitRegsitration(
                      totalData?.personid,
                      totalData?.inputdoc?.patientid
                        ? totalData?.inputdoc?.patientid
                        : totalData?.inputdoc?.patientId,
                      totalData?.inputdoc?.appointmentid,
                      totalData?.inputdoc?.encounterid,
                      totalData?.inputdoc?.resource_type,
                      totalData?.inputdoc?.patientid
                        ? totalData?.inputdoc?.patientid
                        : totalData?.inputdoc?.patientId,
                      totalData?.inputdoc?.encounterid,
                      totalData,
                      totalData?.ticketId,
                      l,
                      queue,
                      queueId
                    );
                  } else if (
                    queue === process.env.REACT_APP_DIALYSIS_QUEUE &&
                    l.action === process.env.REACT_APP_DIALYSIS_EMR
                  ) {
                    redirectTreatmentPlan(
                      totalData?.personid,
                      totalData?.inputdoc?.patientid
                        ? totalData?.inputdoc?.patientid
                        : totalData?.inputdoc?.patientId,
                      totalData?.inputdoc?.appointmentid,
                      totalData?.inputdoc?.encounterid,
                      totalData?.inputdoc?.resource_type,
                      totalData?.inputdoc?.patientid
                        ? totalData?.inputdoc?.patientid
                        : totalData?.inputdoc?.patientId,
                      totalData?.inputdoc?.encounterid,
                      totalData,
                      totalData?.ticketId,
                      queueId,
                      totalData?.field2
                    );
                  } else if (
                    queue === process.env.REACT_APP_NEPHROLOGIST_QUEUE &&
                    l.action === process.env.REACT_APP_NEPHROLOGIST_EMR
                  ) {
                    redirectTreatmentPlan(
                      totalData?.personid,
                      totalData?.inputdoc?.patientid
                        ? totalData?.inputdoc?.patientid
                        : totalData?.inputdoc?.patientId,
                      totalData?.inputdoc?.appointmentid,
                      totalData?.inputdoc?.encounterid,
                      totalData?.inputdoc?.resource_type,
                      totalData?.inputdoc?.patientid
                        ? totalData?.inputdoc?.patientid
                        : totalData?.inputdoc?.patientId,
                      totalData?.inputdoc?.encounterid,
                      totalData,
                      totalData?.ticketId,
                      queueId
                    );
                  } else if (
                    queue === process.env.REACT_APP_PIC_REVIEW_QUEUE &&
                    l.action === process.env.REACT_APP_PIC_REVIEW_EMR
                  ) {
                    redirectTreatmentPlan(
                      totalData?.personid,
                      totalData?.inputdoc?.patientid
                        ? totalData?.inputdoc?.patientid
                        : totalData?.inputdoc?.patientId,
                      totalData?.inputdoc?.appointmentid,
                      totalData?.inputdoc?.encounterid,
                      totalData?.inputdoc?.resource_type,
                      totalData?.inputdoc?.patientid
                        ? totalData?.inputdoc?.patientid
                        : totalData?.inputdoc?.patientId,
                      totalData?.inputdoc?.encounterid,
                      totalData,
                      totalData?.ticketId,
                      queueId
                    );
                  } else if (
                    queue === process.env.REACT_APP_DIALYSIS_QUEUE &&
                    l.action === process.env.REACT_APP_DIALYSIS_BILLING
                  ) {
                    redirectBilling(
                      totalData?.personid,
                      totalData?.inputdoc?.patientid
                        ? totalData?.inputdoc?.patientid
                        : totalData?.inputdoc?.patientId,
                      totalData?.inputdoc?.appointmentid,
                      totalData?.inputdoc?.encounterid,
                      totalData?.inputdoc?.resource_type,
                      totalData?.inputdoc?.patientid
                        ? totalData?.inputdoc?.patientid
                        : totalData?.inputdoc?.patientId,
                      totalData?.inputdoc?.encounterid,
                      totalData,
                      totalData?.ticketId,
                      l.action,
                      queueId
                    );
                  } else if (
                    queue === process.env.REACT_APP_LABORDER_QUEUE &&
                    l.action ===
                      process.env.REACT_APP_LABORDER_SPECIMENTCOLLECTED
                  ) {
                    props?.sampleFormOpen(totalData);
                  } else if (
                    queue === process.env.REACT_APP_NURSING_PROCEDURE_QUEUE &&
                    l.action === process.env.REACT_APP_NURSING_ADMINISTER
                  ) {
                    // nurseAdministration(totalData, queueId, l?.action);
                    props?.NursingProcedureOpen(totalData ,  l)
                    // nurseAdministration(
                    //   totalData,
                    //   queueId,
                    //   l?.action,
                    //   l.apiendpoint,
                    //   l.backEndCall
                    // );
                  } else if (
                    queue === process.env.REACT_APP_LABORDER_QUEUE &&
                    l.action ===
                      process.env
                        .REACT_APP_LABORDER_PRINT_ORDER_REQUISITION_FORM
                  ) {
                    printOrderRequestForm(totalData, queueId);
                  } else {
                    actionButtonClickEvent(
                      l.action,
                      l.apiendpoint,
                      l.backEndCall,
                      ticketId,
                      queue,
                      queueId
                    );
                  }
                }}
>
<div
                  style={{
                    cursor: "pointer",
                    borderRadius: "50%",
                    background: "#ebebeb",
                    height: "35px",
                    width: "35px",
                    margin: "auto",
                    display: "flex",
                    alignItems: "center",
                  }}
                  // dangerouslySetInnerHTML={{ __html: l.icon }}
>
<Image
                    title={l.action}
                    inLineStyles={{
                      height: "17px",
                      width: "16px",
                      marginLeft: "10px",
                    }}
                    src={url}
                    alt={l.action.substring(0, 1)}
                  />
</div>
</Div>
            );
          })}
</Div>
</Card> */}
<Card
        id={`${parent_id}${props.index}_queue_next_parent_card`}
        style={classes.cardStyle}
>
<QueueCard
              parent_id={"dashboard_queuecard_upNext"}
              id={props?.item?.token}
              markHighPriority={
                !(props?.permission?.write?.indexOf("markHighPriorityQ") > -1)
              }
              assignReassignPractitioner={
                !(
                  props?.permission?.write?.indexOf("assignReassignPractitionerQ") > -1
                )
              }
              type="All"
              showBtn={props?.item?.showBringtoTop}
              field1={props?.item?.field1}
              field2={props?.item?.field2}
              field3={props?.item?.field3}
              field4={props?.item?.field4}
              field5={props?.item?.field5}
              field6={props?.item?.field6}
              field7={props?.item?.field7}
              field8={props?.item?.field8}
              field9={props?.item?.field9}
              field10={props?.item?.field10}
              field11={props?.item?.field11}
              field12={props?.item?.field12}
              name={props?.item?.name}
              date={props?.item?.date}
              date1={props?.item?.date1}
              // field1={props?.item?.profile_pic}
              isEmergency={props?.item?.isEmergency}
              totalData={props?.item}
              //action={props?.item?.action}
              action={props?.item?.action}
              ticketId={props?.item?.ticketId}
              //getTabData = {props.getTabData()}
              assignButtonAction={() => {
                let assigned =
                  props?.item?.info?.encounter?.[0]?.participant?.length > 0
                    ? true
                    : false;
                // props.assignPractitioner(
                // 	assigned ? "reassign" : "assign",
                // 	l
                // );
              }}
              assignButtonIcon={
                props?.item?.info?.encounter?.[0]?.participant?.length > 0
                  ? repeatIcon
                  : changeUserIcon
              }
              assigned={
                props?.item?.info?.encounter?.[0]?.participant?.length > 0 ? true : false
              }
              role_={props?.item?.type}
              queue={props?.item?.queueMetaData?.queuename?.queuename}
              queueId={props?.item?.queueid}
              // setMultiBookOpen={props?.setMultiBookOpen}
              // multiBookOpen={props?.multiBookOpen}
              PatientData={props?.item}
              queueUpdated={() => props?.queueUpdated()}
              selectedTicket={[]}
              setSelected={false}
              statesid={props?.item?.statesid}
              // FilteredUsers={FilteredUsers}
              SelectedTicketValue={props?.SelectedTicketValue}
              // sampleCollectOpen={sampleCollectOpen}
              // sampleFormOpen={(e) => sampleFormOpen(e)}
              // sampleFormClose={(e) => sampleFormClose(e)}
              // nursingProcedureOpen={nursingProcedureOpen}
              // NursingProcedureOpen={NursingProcedureOpen}
              // nursingProcedureClose={(e) => nursingProcedureClose(e)}
              queueList={props?.queueList}
              getTabData={props?.getTabData}
              selectedQueue={props?.selectedQueue}
              scenarioId={props?.scenarioId}
            />
</Card>
      {state?.openDialog ? (
<SideMenu
          id="patient_dashboard_cancel_sidemenu"
          open={state?.openDialog}
          direction="right"
          width={440}
          color="default"
          onClose={() => handlesidebarCancelbooking("closing")}
          style={{ padding: 0 }}
>
<CancelBookingPage
            parent_id={"patient_dashboard"}
            cancelbookingreason={state?.cancelbookingreason?.value}
            cancelbookings={"4"}
            appointmentId={props?.totalData?.info?._id}
            handleSelectCancelbooking={handleSelectCancelbooking}
            handlecahngeCancelbooking={handlecahngeCancelbooking}
          />
</SideMenu>
      ) : null}
</Div>
  );
};
 
export default UpNext;