// ChatBot.js
import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Button,
  Fab,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import {
  Minimize as MinimizeIcon,
  Chat as ChatIcon,
} from "@mui/icons-material";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import DoDisturbOnRoundedIcon from "@mui/icons-material/DoDisturbOnRounded";
import SmartToyRoundedIcon from "@mui/icons-material/SmartToyRounded";
import Lottie from "react-lottie";
import AIChatBot from "../../../assets/animations/ai-chat-bot-1.json";
import Bot from "../../../assets/animations/bot.json";





const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: AIChatBot,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

const defaultOptionsBot = {
    loop: true,
    autoplay: true,
    animationData: Bot,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
  },
};

const ChatBot = ({ options, selectedValue, setSelectedValue, onButtonClick }) => {
    const [open, setOpen] = useState(false); // Initially closed
    const roleName = localStorage.getItem("RoleName");
    let isDoctor = roleName === "Doctor" ? true : false;
  // useEffect(() => {
  //   // Open chatbot automatically after 10 seconds
  //   const timer = setTimeout(() => {
  //     setOpen(true);
  //     const audio = new Audio(openSound);
  //     audio.play();
  //   }, 8000);

  //   // Cleanup the timer if the component unmounts
  //   return () => clearTimeout(timer);
  // }, []);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    // const audio = new Audio(openSound);
    // audio.play();
  };

  return (
      <div>
      {open && isDoctor && (
        <Box
          sx={{
            position: "fixed",
            bottom: 20,
            right: 20,
            width: 400,
            height: 360,
            bgcolor: "background.paper",
            boxShadow: 3,
            borderRadius: 2,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              bgcolor: "#179e8e",
              color: "primary.contrastText",
              p: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">MEDAI Assistant</Typography>
            <IconButton
              aria-label="minimize"
              onClick={handleClose}
              sx={{ color: "primary.contrastText" }}
            >
              <DoDisturbOnRoundedIcon />
            </IconButton>
          </Box>
          <Box sx={{ p: 2, flexGrow: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Box sx={{ flexShrink: 0 }}>
                <Lottie options={defaultOptions} height={100} width={100} />
              </Box>
              <Typography variant="body1" sx={{ ml: 2, fontWeight: "bold" }}>
                Hello! I am MEDAI Assistant, your AI-powered partner.
              </Typography>
            </Box>
            <Typography variant="body1">
              I assist you by examining patient medical records to uncover
              disease risks and recommend the necessary medical interventions.
              Let’s work together to ensure the best care for your patients.
            </Typography>
          </Box>

          <Box
            sx={{
              p: 1,
              paddingBottom: 3,
              paddingRight: 3,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Autocomplete
              size="small"
              options={options}
              value={selectedValue}
              sx={{ width: 200, backgroundColor: "#FFFFFF" }}
              onChange={(event, newValue) => setSelectedValue(newValue)}
              renderInput={(params) => <TextField {...params} label="Mode" />}
            />
            <Button
              variant="contained"
              sx={{
                bgcolor: "#EC6A49",
                color: "white",
                "&:hover": {
                  backgroundColor: "#BF5037", // Change this to the desired hover color
                },
              }}
              onClick={onButtonClick}
            >
              Analyze
            </Button>
          </Box>
        </Box>
      )}
      {!open && isDoctor && (
        <Fab
          color="#179e8e"
          aria-label="open chatbot"
          onClick={handleOpen}
          variant="extended"
          sx={{
            position: "fixed",
            bottom: 20,
            right: 20,
            //width: 250,
            height: 60,
            color: "white",
            backgroundColor: "#EC6A49",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
              backgroundColor: "#BF5037", // Change this to the desired hover color
            },
          }}
        >
          <Lottie options={defaultOptionsBot} height={60} width={60} />
          <Typography variant="button" sx={{ fontWeight: "bold" }}>
            AI Assistant
          </Typography>
        </Fab>
      )}
    </div>
  );
};

export default ChatBot;
