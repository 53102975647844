import React, { useContext, useEffect, useState } from "react";
import FinanceInputs from "./financeInputs";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { TopNavbar, InfoIcon } from "../../../components";
import jwtDecode from "jwt-decode";
import { AlertProps, getGlobalValueFrom_LclStrg_And_QryStrg, getTenantId, readFileAsBase64 } from "../../../utils";
import dayjs from "dayjs";
import actions from "../../../redux/actions";
import { AlertContext } from "../../../context";
import { Grid, Paper, Skeleton, Typography } from "@mui/material";
import { PatientListView } from ".";
import PayerDetailCard from "./payerDetailCard";
import { UIColor } from "../../../themes/theme";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import EncounterShowingCard from "./encounterCard";

function FinanceCRUD(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const alert = useContext(AlertContext);
  const [Files, setFiles] = useState();
  const [viewFiles, setViewFiles] = useState();
  const [savedFinData, setSavedFinData] = useState([]);
  const [exceptSelfPayData, setExceptSelfPayData] = useState([]);
  const [selfPayData, setSelfPayData] = useState([]);
  const [generalSelfPay, setGeneralSelfPay] = useState({});
  const [personData, setPersonData] = useState({});
  const [editData, setEditData] = useState({});
  const [defData, setDefData] = useState({});
  const [allMasters, setAllMasters] = useState([]);

  const finLoading = useSelector(
    (state) => state?.FinanceSlice?.savefinance?.loading
  );
  const getPatinetLoading = useSelector(
    (state) => state?.patient?.PatientInfo?.loading
  );

  // const masterLoading = useSelector(
  //   (state) => state?.CommonSlice?.someMasters?.loading
  // );
  //const [getPatientRegistration, setGetPatientRegistration] = useState({});
  const EncounterDetails = props?.selectencounterDataList;
  function syncWithSavedFinData() {
    
    let sp = [];
    let exsp = [];
    savedFinData.forEach((v) => {
      // //
      if (v?.is_deleted !== true) {
        // filtering Deleted
        if (process.env.REACT_APP_SELF_PAYER_TYPE === v?.payertype?._id) {
          // self pay
          sp.push({
            ...v,
            deleteIcon: true,
            editIcon: true,
            isDraggable: true,
          });
        } else {
          exsp.push({
            ...v,
            deleteIcon: true,
            editIcon: true,
            isDraggable: true,
          });
        }
      }
    });
    setExceptSelfPayData(exsp);
    setSelfPayData(sp);
  }

  const Handlesave = (finData, editid,mode) => {
    let data = [...savedFinData];
    if (editid) {
      let index = data.findIndex((v) => v?.id === editid);
      finData.mode = mode;
      data[index] = Object.assign({}, data[index], finData);
  
      setSavedFinData(data);
      setEditData({});
      return;
    }
    setSavedFinData((prev) => [...prev, finData]);
  };

  const Tendigits = (isodate) => {
    return isodate ? Math.floor(dayjs(isodate).valueOf() / 1000) : 0;
  };

  const PayloadGenerator = async (data) => {
    const userInfo = localStorage?.getItem("UserInfo");
    const decodedUserinfo = userInfo && jwtDecode(userInfo);
    let attachments = [];
    const convertedFiles = Files
      ? Files?.map((val) => readFileAsBase64(val))
      : [];
    const base64Results = await Promise.all(convertedFiles);
    Files?.forEach((val, i) => {
      let obj = {
        fileName: val?.name,
        fileid: "",
        filetype: val?.type,
        base64string: base64Results[i],
      };
      attachments.push(obj);
    });
    if (Array.isArray(viewFiles) && viewFiles?.length > 0) {
      attachments.concat(viewFiles);
    }

    let priorityFinData = [];
    let deletedFinData = savedFinData.filter((v) => !v?.isNew && v?.is_deleted);
    priorityFinData = [...exceptSelfPayData, ...selfPayData, ...deletedFinData];
    let codingPriority = {};
    allMasters?.PRIORITY?.forEach((v) => (codingPriority[v?.value] = v?._id)); // top priority   //less priority
    let doc = priorityFinData.map((v, i) => {
      return {
        patientaccount_id: v?.patientaccount_id || "",
        is_deleted: v?.is_deleted,
        mode:v?.mode,
        tenantid: decodedUserinfo?.tenantid,
        facilityid: decodedUserinfo?.facilityid,
        privilage: "yes",
        patientid: location.state?.patientId || location.state?.data?.patientId,
        priority: codingPriority[i + 1],
        trantype: "encounter", // always encounter
        payertype: v?.payertype?._id || "",
        payer: v?.payer?._id || "",
        guarantortype: v?.guarantorType?._id || "",
        guarantorid: v?.guarantor?._id || "",
        policyid: v?.policyPlan?._id || "",
        policystart: Tendigits(v?.policyStart),
        policyexpiry: Tendigits(v?.policyEnd),
        sponsorname: v?.sponserName?._id || "",
        membershipid: v?.membershipId || "",
        membertype: v?.membershipType?._id || "",
        memberrelid: v?.memberRelationship?._id || "",
        primemembershipid: v?.primeMemberid || "",
        applencounter: "",
        applencountertype: "",
        applspecialty: "",
        financialclass: [
          {
            classtype: v?.finClassVal?._id || "",
            classval: v?.finClasstype?._id || "",
          },
        ],
        documentref: attachments, //FIXME -
        encounteraccount_id: "",
        encounterid: props?.encounterId,
        eligiblestatus: "",
        eligverifreqdyn: false, //FIXME -
        approvallimit: 0,
        copayperc: 0,
        copayamt: 0,
        glnumber: v?.glNumber || "",
        glissuedate: Tendigits(v?.glIssue),
        servicestart: Tendigits(v?.serviceStart),
        serviceend: Tendigits(v?.serviceEnd),
        status: "CodingMaster/10191", //FIXME -
        referenceid: [
          {
            refsource: "",
            refidtype: "",
            refid: "",
          },
        ],
      };
    });
    let payload = {
      dbName: process.env.REACT_APP_DB, //"primarycareng",
      metadatadbname: process.env.REACT_APP_METADATA_DBNAME,
      patientid: location.state?.patientId || location.state?.data?.patientId,
      doc: doc,
    };

    return payload;
  };
  const HandleFinRegister = async () => {
    try {
      let payload = await PayloadGenerator();
      let saveFin = await dispatch(actions.SAVE_FINANCE(payload));
      let saveFinance = saveFin?.payload?.data?.[0];

      //FIXME -
      if (saveFinance && saveFinance?.response && !saveFinance?.error) {
        if (!location.state?.isVisit)
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg:
              location?.state?.backMode ||
              location?.state?.isEditedMode ||
              location?.state?.isRevise
                ? "Updated successfully"
                : "Added successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
        props?.handleFinClose();
        setSavedFinData([]);
      } else {
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: saveFinance?.message || "something went wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      }
    } catch (error) {}
  };

  const getPatientInfo = async () => {
    let data = await dispatch(
      actions.PATIENT_INFO({
        patientid: location.state?.patientId || location.state?.data?.patientId,
        tenantid: "",
        facilityid: "",
        lang: "",
        encounterid: location.state?.isRevise || location.state?.data?.isRevise
          ? EncounterDetails?._id
          : props?.encounterId
          ? props?.encounterId
          : "",
      })
    );
    data = data?.payload?.data;
    let finSavedData = [];
    data?.financialdetails?.forEach((v) => {
      let obj = {
        patientaccount_id: v?._id,
        mode:"edit",
        is_deleted: false,
        id: uuidv4(),
        payertype: v?.payertype || null,
        payer: v?.payer || null,
        guarantorType: v.guarantortype || null,
        guarantor: v.guarantorid || null,
        policyPlan: v?.policyid || null,
        policyStart: v?.policystart ? dayjs.unix(v?.policystart) : "",
        policyEnd: v?.policyexpiry ? dayjs.unix(v?.policyexpiry) : "",
        membershipId: v?.membershipid || "",
        membershipType: v?.membertype
          ? { _id: v?.membertype?._id, value: v?.membertype?.display }
          : null,
        sponserName: v?.sponsorname
          ? { _id: v?.sponsorname?._id, name: v?.sponsorname?.name }
          : null,
        memberRelationship: v.memberrelid
          ? { _id: v.memberrelid?._id, value: v.memberrelid?.display }
          : null,
        primeMemberid: v?.primemembershipid || "",
        glNumber: v?.glnumber,
        glIssue: v?.policystart ? dayjs.unix(v?.policystart) : "",
        serviceStart: v?.policystart ? dayjs.unix(v?.policystart) : "",
        serviceEnd: v?.policystart ? dayjs.unix(v?.policystart) : "",
        finClasstype: v?.financialclass?.classtype
          ? {
              _id: v?.financialclass?.classtype?._id,
              value: v?.financialclass?.classtype?.display,
            }
          : null,
        finClassVal: v?.financialclass?.classtype
          ? {
              _id: v?.financialclass?.classval?._id,
              value: v?.financialclass?.classval?.display,
            }
          : null,
      };
      if (
        v?.payertype?._id === process.env.REACT_APP_SELF_PAYER_TYPE &&
        v?.policyid?._id === process.env.REACT_APP_SELF_PAYER_GENRERAL_PLAN
      ) {
        setGeneralSelfPay(obj);
      } else {
        finSavedData.push(obj);
      }
    });
    setSavedFinData(finSavedData);
    setPersonData(data?.patientdetails);
  };
  async function GetDefaultValue(params) {
    let payload = {
      screenid: process.env.REACT_APP_FINANCE_REGISTRATION_SCREEN_ID,
      tenantid:  getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"tenantid"}),
    };
    let defaultValue = await dispatch(
      actions.GET_SCREEN_DEFAULT_VALUE(payload)
    );

    if (
      defaultValue?.payload?.data?.response?.screenId ===
      process.env.REACT_APP_FINANCE_REGISTRATION_SCREEN_ID
    ) {
      defaultValue = defaultValue?.payload?.data?.response?.defaultValues;
      let obj = {};
      defaultValue.forEach((v) => {
        obj[v?.stateName] = v?.value;
      });
      let totalObj = {
        payertype: {
          _id: obj.payer_type?._id,
          longdesc: obj?.payer_type?.value,
        },
        policyStart: dayjs.unix(obj?.policy_edate),
        policyEnd: dayjs.unix(obj?.policy_edate),
        membershipType: obj?.membership_type,
      };

      setDefData(totalObj);
    }
  }
  async function getAllData() {
    const allMasters = await dispatch(
      actions.GET_SOME_MASTER({
        type: [
          "MEMBERTYPE",
          "PRIORITY",
          "RELATIONSHIP",
          "FINANCIALCLASSTYPE",
          "FINANCIALCLASS",
        ],
      })
    );

    setAllMasters(allMasters?.payload?.data);
  }
  useEffect(() => {
    
    getPatientInfo();
    GetDefaultValue();
    getAllData();
  }, []);
  useEffect(() => {
    if (savedFinData?.length > 0) {
      
      syncWithSavedFinData();
    }
    // // finance screen is based on patient Id
    // if (!location?.state?.patientId) {
    //   navigate(-1);
    // }
  }, [savedFinData]);
  const handleDragEnd = (dragIndex, dragY) => {
    const destinationIndex = Math.round(dragY / 55);
    const newDestinationIndex = Math.min(
      Math.max(destinationIndex, 0),
      exceptSelfPayData.length - 1
    );
    if (newDestinationIndex !== dragIndex) {
      const newItems = [...exceptSelfPayData];
      const draggedItem = newItems.splice(dragIndex, 1)[0];
      newItems.splice(newDestinationIndex, 0, draggedItem);
      let getdragdata = [];
      newItems?.forEach((data, index) => {
        if (data?.is_deleted !== true) {
          getdragdata.push(data);
        }
      });
      setExceptSelfPayData(getdragdata);
    }
  };
  const handleDragEndForSelfpay = (dragIndex, dragY, isSelfPay) => {
    const destinationIndex = Math.round(dragY / 55);
    const newDestinationIndex = Math.min(
      Math.max(destinationIndex, 0),
      selfPayData.length - 1
    );
    if (newDestinationIndex !== dragIndex) {
      const newItems = [...selfPayData];
      const draggedItem = newItems.splice(dragIndex, 1)[0];
      newItems.splice(newDestinationIndex, 0, draggedItem);
      let getdragdata = [];
      newItems?.forEach((data, index) => {
        if (data?.is_deleted !== true) {
          getdragdata.push(data);
        }
      });
      setSelfPayData(getdragdata);
    }
  };

  const onEdit = (v, i) => {
    const isEditMode =location?.state?.isVisit||location?.state?.isRevise || location?.state?.isModify || location?.state?.isEditedMode || location?.state?.backMode ? true : false;
    const updatedV = { ...v, mode: isEditMode ? "edit" : v?.mode};
    setEditData(updatedV);
  };

  const onDelete = (delcard, i) => {
    let data = [...savedFinData];

    let index = data.findIndex((v) => v?.id === delcard?.id);
    data[index]["is_deleted"] = true;
    data[index]["mode"] = "delete";

    setSavedFinData(data);
    alert.setSnack({
      open: true,
      severity: AlertProps.severity.success,
      msg: "payer deleted added !",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.right,
    });
  };

  return (
    <div
      id="finance_registration_parent_div"
      className="light-background-color"
    >
      <TopNavbar
        goBack={() => props?.setIsFin(false)}
        parent_id={"Financial_Registration"}
        //newUser={location?.state?.newUser}
        successfun={HandleFinRegister}
        title={"Finance Update"}
        btn={finLoading ? "updating " : "update"}
        successBtnLoading={finLoading}
        parent_View_id={false}
        isViewMode={false}
        navigateToNextScreen={() => null}
        setFiles={setFiles}
        setviewFiles={setViewFiles}
        initialFiles={viewFiles}
        // isBackMode={
        //   location?.state?.isModify ||
        //   location?.state?.backMode ||
        //   location?.state?.isEditedMode
        // }
      />
      <div
        style={{
          height: "calc(100vh - 128px)",
          overflow: "auto",
          display: personData?.loading ? "flex" : "block",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          id="financial_parent_sub_div"
          style={{
            margin: "4px 15px",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {" "}
          {getPatinetLoading ? (
            <Skeleton
              variant="rectangular"
              animation="wave"
              // width={}
              height={65}
              sx={{ borderRadius: "8px" }}
            />
          ) : props?.encounterId ? (
            <Grid container className="pv-root" spacing={1}>
              <Grid
                item
                id="financial_patientlist_col"
                lg={6}
                md={6}
                inLineStyles={{ padding: 5 }}
              >
                <EncounterShowingCard
                  parent_id={"patient_care"}
                  moreopen={true}
                  pic={EncounterDetails?.pic}
                  title_name={EncounterDetails?.title_name}
                  encounterName={EncounterDetails?.encounterName}
                  encounterEnd={EncounterDetails?.encounterEnd}
                  encounterStart={EncounterDetails?.encounterStart}
                  type={EncounterDetails?.type}
                  speciality={EncounterDetails?.speciality}
                  doctorName={EncounterDetails?.doctorName}
                  encstatus={EncounterDetails?.encstatus}
                />
              </Grid>
              <Grid
                item
                id="financial_patientstatus_col"
                lg={6}
                md={6}
                inLineStyles={{ padding: 5 }}
              >
                <PatientListView
                  parent_id={"Financial_Registration"}
                  noright={true}
                  // Persondata={state.Persondata?.[0]}
                  Persondata={personData}
                  // patientId={state.patientId}
                  patientId={personData?.identifier?.[0]?.value}
                  IsViewMode={location?.state?.IsViewMode}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              {" "}
              <Grid
                item
                id="financial_patientist_col_one"
                lg={12}
                md={12}
                inLineStyles={{ padding: 5 }}
              >
                <PatientListView
                  parent_id={"Financial_Registration"}
                  noright={true}
                  // Persondata={state.Persondata?.[0]}
                  Persondata={personData}
                  // patientId={state.patientId}
                  patientId={personData?.identifier?.[0]?.value}
                  IsViewMode={location?.state?.IsViewMode}
                />
              </Grid>
            </Grid>
          )}
        </div>
        {exceptSelfPayData?.length > 0 && (
          <Grid
            className="pv-root"
            style={{
              margin: "3px 15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Paper id="financial_applicable_paper" className="pl-root">
              <Typography
                id="financial_applicable_label_h6"
                className="en-title space-padding-bottom"
              >
                APPLICABLE CRITERIA
              </Typography>
              <Grid
                container
                style={{ alignItems: "center", justifyContent: "flex-start" }}
              >
                <InfoIcon
                  width={12}
                  style={{ marginRight: 6 }}
                  color={UIColor.primaryColor.color}
                />
                <span
                  style={{ fontSize: "12px" }}
                  color={UIColor.primaryText.color}
                >
                  Use drag and drop to change the priority
                </span>
              </Grid>

              {exceptSelfPayData?.map((v, i) => (
                <PayerDetailCard
                  key={v?.id} // You might need to set a unique key for each card
                  parent_id={"financial_showlist"}
                  onedit={() => onEdit(v, i)}
                  ondelete={() => onDelete(v, i)}
                  isDelete={v?.deleteIcon}
                  isEdit={v?.editIcon}
                  data={v}
                  index={i}
                  //FIXME -  cleared={cleared}
                  IsViewMode={location?.state?.IsViewMode}
                  keys={v}
                  values={v}
                  priority={i + 1}
                  handleDragEnd={handleDragEnd}
                  //FIXME - newpriority={i+ parseInt(v?.payer?.priority?.value)}
                />
              ))}
            </Paper>
          </Grid>
        )}
        {selfPayData?.length > 0 && (
          <Grid
            className="pv-root"
            style={{
              margin: "3px 15px",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <Paper
              id="financial_applicable_paper"
              className="pl-root"
              sx={{ display: "flex", flexDirection: "column", gap: "12px" }}
            >
              {selfPayData?.map((v, i) => (
                <PayerDetailCard
                  key={v?.id} // You might need to set a unique key for each card
                  parent_id={"financial_showlist"}
                  onedit={() => onEdit(v, i)}
                  ondelete={() => onDelete(v, i)}
                  isDelete={v?.deleteIcon}
                  isEdit={v?.editIcon}
                  data={v}
                  index={i}
                  //FIXME -  cleared={cleared}
                  IsViewMode={location?.state?.IsViewMode}
                  keys={v}
                  values={v}
                  priority={i + exceptSelfPayData?.length + 1}
                  handleDragEnd={handleDragEndForSelfpay}
                  //istitle={true}
                  // newpriority={i+ exceptSelfPayData?.length+1}
                />
              ))}
            </Paper>
          </Grid>
        )}
        <Grid
          className="pv-root"
          style={{
            margin: "3px 15px",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {getPatinetLoading ? (
            <Skeleton
              variant="rectangular"
              animation="wave"
              // width={}
              height={55}
              sx={{ borderRadius: "8px" }}
            />
          ) : Object.keys(generalSelfPay || {})?.length > 0 ? (
            <Paper id="financial_applicable_paper" className="pl-root">
              <PayerDetailCard
                key={`${1}`} // You might need to set a unique key for each card
                parent_id={"financial_showlist"}
                isDelete={false}
                isEdit={false}
                data={generalSelfPay}
                // cleared={cleared}
                IsViewMode={location?.state?.IsViewMode}
                keys={generalSelfPay}
                values={generalSelfPay}
                istitle={true}
                priority={exceptSelfPayData?.length + selfPayData?.length + 1}
              />
            </Paper>
          ) : (
            <></>
          )}
        </Grid>
        <Grid
          className="pv-root"
          style={{
            margin: "8px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Paper id="financial_applicable_paper" className="pl-root">
            <FinanceInputs
              Handlesave={Handlesave}
              editData={editData}
              defData={defData}
              allMasters={allMasters}
              TotalPay={[...selfPayData, ...exceptSelfPayData, generalSelfPay]} // generalSelfPay is obj
            />
          </Paper>
        </Grid>
      </div>
    </div>
  );
}

export default FinanceCRUD;