import React, { useState, useEffect } from "react";
import withAppBar from "../../hoc/withAppBar";
import withAllContexts from "../../hoc/withAllContexts";
import {axiosCall , urls , dbName , query_ids , getUtcTime} from "../../utils"
import moment from "moment";
import { Row, Text, Div, Button, SideMenu, Card, H6 } from "qdm-component-library";
import { Stats, Task, VerticalBarChart, HorizontalBarChart, FinancialClass, LineChart, PieChart, DoughnutChart } from "./components";
import Grid from '@material-ui/core/Grid';
import jwtDecode from "jwt-decode";
import { data } from "./components/verticalBarChart";
//import { WidthProvider, Responsive } from "react-grid-layout";

const DashboardMain = (props) => {
  const styles = {
    card: {
      boxShadow: "0px 10px 25px #0000000A",
      border: "1px solid #E0E0E0",
      borderRadius: 8,
      marginTop:"10px",
      padding: 12,
      height: "280px"
    },
    rowCenter: {
      display: "flex",
      alignItems: "center",
    },
    dot: {
      width: 8,
      height: 8,
      borderRadius: "50%",
      marginRight: 10,
    },
    h6: {
      fontSize: 12,
      color: "#101010",
      margin: 0,
      flex: 1,
    },
    div25: {
        width:"25%",
        padding:"5px"
    }
  };
    const [dashboardTile , setdashboardTile] = useState([]);
    const [currentVisitStats , setcurrentVisitStats] = useState([]);
    const [payerWiseBill , setpayerWiseBill] = useState([]);
    const [cashBill , setcashBill] = useState();
    const [payerMode , setpayerMode] = useState();
    const [pendingTask , setpendingTask] = useState([]);
    const [financeData , setfinanceData] = useState([]);
    const [payerCount , setPayerCount] = useState();
    const [payerValue , setPayerValue] = useState();
    const userInfo = localStorage.getItem("UserInfo");
    const decodedUserinfo = jwtDecode(userInfo);

    useEffect(() => {
        getDashboardTile();
        getCurrentVist();
        getPendingTask()
        PayerWiseBill();
        // CashBill();
        // PayerModeWise()
    },[])

    const getDashboardTile = async () => {
        const DashbardTile = await axiosCall(
            {
              db_name: dbName,
              filter: {
                start: getUtcTime(moment().startOf("day")),
                end: getUtcTime(moment().endOf("day")),
                roleid:decodedUserinfo.roleid,
                tenantid:decodedUserinfo.tenantid,
                facilityid:decodedUserinfo.facilityid
              },
              queryid: query_ids?.dashboard_tilequery,
            },
            urls?.readQdmQueries
          );
          setdashboardTile(DashbardTile)
    }

    const getCurrentVist = async () => {
        const CurrentVisit = await axiosCall(
            {
              db_name: dbName,
              filter: {
                start: getUtcTime(moment().startOf("day")),
                end: getUtcTime(moment().endOf("day")),
                roleid:decodedUserinfo.roleid,
                tenantid:decodedUserinfo.tenantid,
                facilityid:decodedUserinfo.facilityid
              },
              queryid: query_ids?.currentVisitquery,
            },
            urls?.readQdmQueries
          );

          setcurrentVisitStats(CurrentVisit)
    }
    const getPendingTask = async () => {
        const PendingTask = await axiosCall(
            {
              db_name: dbName,
              filter: {
                start: getUtcTime(moment().startOf("day")),
                end: getUtcTime(moment().endOf("day")),
                roleid:decodedUserinfo.roleid,
                tenantid:decodedUserinfo.tenantid,
                facilityid:decodedUserinfo.facilityid
              },
              queryid: query_ids?.pendingtaskquery,
            },
            urls?.readQdmQueries
          );

          setpendingTask(PendingTask)
        }
    function randomRGB() {
      var x = Math.floor(Math.random() * 256);
      var y = Math.floor(Math.random() * 256);
      var z = Math.floor(Math.random() * 256);
      var RGBColor = "rgb(" + x + "," + y + "," + z + ")";  
      return (RGBColor);
    }
    const generateChartData = (PayerWiseBillVal) => {
      let name = [];
      let data = [];
      let colr = [];
      PayerWiseBillVal?.[0]?.dataset?.map(val => {
        name.push(val.name);
        data.push(val.value);
        colr.push(randomRGB());
      });
      return [{title: PayerWiseBillVal?.[0]?.title,
        labels: name,
        datasets: [{data:data,backgroundColor:colr}]
      }];
    }

    const PayerWiseBill = async () => {
        const PayerWiseBill = await axiosCall(
            {
              db_name: dbName,
              filter: {
                start: getUtcTime(moment().startOf("day")),
                end: getUtcTime(moment().endOf("day"))
              },
              queryid: query_ids?.payer_wise_bill_query,
            },
            urls?.readQdmQueries
          );
          const PayerWiseBillVal = await axiosCall(
              {
                db_name: dbName,
                filter: {
                  start: getUtcTime(moment().startOf("day")),
                  end: getUtcTime(moment().endOf("day"))
                },
                queryid: query_ids?.payer_wise_bill_value_query,
              },
              urls?.readQdmQueries
            );
          const CashBill = await axiosCall(
            {
              db_name: dbName,
              filter: {
                start: getUtcTime(moment().startOf("day")),
                end: getUtcTime(moment().endOf("day"))
              },
              queryid: query_ids?.cash_bill_query,
            },
            urls?.readQdmQueries
          );
          const PayerModeWise = await axiosCall(
            {
              db_name: dbName,
              filter: {
                start: getUtcTime(moment().startOf("day")),
                end: getUtcTime(moment().endOf("day"))
              },
              queryid: query_ids?.payer_mode_wise_query,
            },
            urls?.readQdmQueries
          );
          //setpayerWiseBill(PayerWiseBill);
          let data = financeData;
          (generateChartData(PayerWiseBill))?.map(val => {
            if((data?.filter(li => li.title == val?.title)).length === 0)
            setPayerCount(val);
          });
          // let PWBV =
          (generateChartData(PayerWiseBillVal))?.map(val => {
            if((data?.filter(li => li.title == val?.title)).length === 0)
            setPayerValue(val);
          });
          CashBill?.map(val => {
            if((data?.filter(li => li.title == val?.title)).length === 0)
            setcashBill(val);
          });
          (generateChartData(PayerModeWise))?.map(val => {
            if((data?.filter(li => li.title == val?.title)).length === 0)
            setpayerMode(val);
          });
          setfinanceData(data);
    }
    
    return(
        <Grid 
          style={{ 
            // overflow: "auto", 
            borderRadius: "6px", 
            display: "flex", 
            flexDirection: "column",
            padding: "10px"
          }}
          >
          <Grid xs={12} style={{fontSize:"16px", fontWeight:"bold"}}>
              {"Dashboard"}
          </Grid>
          
          <Grid item xs={12}>
              <Stats parent_id = {"D"} data ={dashboardTile}/>
          </Grid>
          <Row>
            <Grid xs={6}>
                <DoughnutChart data={currentVisitStats?.[0]} />
            </Grid>
            <Grid xs={6}>
                <Task parent_id = {"D"} data={pendingTask} />
            </Grid>
          </Row>
          <Grid item xs={12}>
            <Card
              id={`layout_financial_parent_card`}
              style={styles.card}>

              <Div id={`${"parent_id"}_barchart_parent_div`}>
                <H6
                  id={`${"parent_id"}_barchart_label_h6`}
                  className="pc_medium"
                  style={{ marginBottom: 35, fontSize: 14 }}
                >
                  Financial Class
                </H6>
                <Row>
                    <div style={{...styles.div25}}>
                    <VerticalBarChart data={payerCount} />
                    </div>
                    <div style={{...styles.div25}}>
                    <VerticalBarChart data={payerValue} />
                    </div>
                    <div style={{...styles.div25}}>
                    <VerticalBarChart data={cashBill} />
                    </div>
                    <div style={{...styles.div25}}>
                    <VerticalBarChart data={payerMode} />
                    </div>
                  </Row>
              </Div>
            </Card>
          </Grid>
        </Grid>
    )
}



export default (DashboardMain);