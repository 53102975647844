import React from "react";
//import { actions } from "primarycare-binder";
import { connect } from "react-redux";
//import { withRouter } from "react-router-dom";
import { Grid, withStyles } from "@material-ui/core";
import { Col, Row } from "qdm-component-library";
import "./styles.css";
import VitalSigns from "./vitalSigns";
import { Allergy } from "./allergyFlows";
import { Diagnosis } from "./diagnosisFlow";
import { CheifComplaints } from "./cheifComplaintsFlow";
import { Radiology } from "./radiologyFlow";
import LabOrder from "./labOrderFlow/labOrder";
import { MedicationOrderFrom } from "./medicationFlows";
import TreatmentProcedure from "./treatmentProcedureFlow/treatmentProcedure";
import DoccumentationList from "./documentation/index";
import { OrderSets } from "./orderSetFlow";
import { Dialysis } from "./dialysis/dialysis";
import ProgressNotes from "./progressNotes";
import TreatmentPlan from "./treatmentPlan/treatmentPlan";
import { useDispatch } from "react-redux";
import actions from "../../redux/actions";
import CurrentMedication from "../../components/currentMedication";
import NursingProcedure from "./nursingProcedure/nursingProcedure";
import PackageOrder from "./packageOrder/packageOrder";
import ReferralPatient from "./patientRefferal/refPatient"
const materialStyles = (theme) => ({});

const PatientChart = (props) => {
  const [formsList, setformList] = React.useState(null);
  const [patientgetData, setpatientgetData] = React.useState(null);
  const dispatch = useDispatch();
  const [eRefType,setERefType]=React.useState({})
  const styles = {
    columnStyle: {
      padding: 10,
      backgroundColor: "#fff",
      borderRadius: 5,
    },
    divider: {
      margin: "5px 0px",
      border: "0.5px dashed #EEEEEE",
      borderRadius: 10,
    },
  };
  const getSequence = async () => {
    const sequence = await dispatch(actions.SHOW_COMP_BASED_ON_REF_TYPE({ ereftype: eRefType?._id }));
    const formListArray = sequence?.payload?.data?.[0]?.templates;

    if (Array.isArray(formListArray)) {
        const sortedFormListArray = [...formListArray].sort((a, b) => a.sequence - b.sequence);
        let formListNames = sortedFormListArray?.map(item => item.componentname.value);
        setformList(formListNames);
    }
}


  React.useEffect(() => {
    getSequence();
  }, [eRefType]);
  const renderComponentsInArrayOrder = () => {
    return formsList?.map((componentName) => {
        return (
          <div key={componentName} style={{ marginBottom: "12px",marginTop:"12px" }}>
            {componentName === "laborder" ? (
              <LabOrder
                propsData={{ ...props }}
                parent_id={"patientchart"}
                onEditChart={onEditChart}
                saveLabOrders={props.saveLabOrders}
                patientgetData={props.patientgetData}
                handleClose={props.handleClose}
              />
            ) : componentName === "medication" ? (
              <MedicationOrderFrom
                propsData={{ ...props }}
                parent_id={"patientchart"}
                onEditChart={onEditChart}
                handleClose={props.handleClose}
              />
              )
             : componentName === "treatmentprocedure" ? (
              <TreatmentProcedure
                propsData={{ ...props }}
                parent_id={"patientchart"}
                onEditChart={onEditChart}
                saveTreatment={props?.saveTreatment}
                patientgetData={props.patientgetData}
                handleClose={props.handleClose}
                setTreatmentplan={props?.setTreatmentplan}
              />
             ) : componentName === "vitalsigns" ? (
              <VitalSigns Encounterdata={props?.patientgetData} onRegistration={false} />
             ) : componentName === "progressnotes" ? (
              <ProgressNotes
                patientgetData={props?.patientgetData}
                progressNotesData={props?.progressNotesData ?? {}}
              />
            ) : componentName === "treatmentplan" ? (
              <TreatmentPlan
                dialysisData={props?.dialysisData}
                patientgetData={props?.patientgetData}
                getDialysisData={props?.getDialysisData}
                orgdetails={props?.orgdetails}
                treatmentplan={props?.treatmentplan}
                location={props?.locationdata}
                setTreatmentplan={props?.setTreatmentplan}
              />
            ) : componentName === "allergy" ? (
              <Allergy
                parent_id={"patientchart"}
                saveAllergy={props.saveAllergy}
                onEditChart={onEditChart}
                handleClose={props.handleClose}
                Encounterdata={props?.patientgetData}
              />
            ) : componentName === "diagnosis" ? (
              <Diagnosis
                parent_id={"patientchart"}
                saveDiagnosis={props.saveDiagnosis}
                onEditChart={onEditChart}
                handleClose={props.handleClose}
                Encounterdata={props.patientgetData}
              />
            ) : componentName === "cheifcomplaints" ? (
              <CheifComplaints
                parent_id={"patientchart"}
                saveCheifComp={props.saveCheifComp}
                onEditChart={onEditChart}
                handleClose={props.handleClose}
                Encounterdata={props?.patientgetData}
              />
            ) : componentName === "radiology" ? (
              <Radiology
                propsData={{ ...props }}
                parent_id={"patientchart"}
                onEditChart={onEditChart}
                saveRadiology={props.saveRadiology}
                patientgetData={props.patientgetData}
                handleClose={props.handleClose}
              />
             ) : componentName === "medication" ? (
               <CurrentMedication
               isEdit={props?.isEdit}
               oneditClick={props?.oneditClick}
               handleSave={props?.handleSave}
               currentcancel={props?.currentcancel}
               handlecurrentmedicationval={props?.handlecurrentmedicationval}
               currentMedicationValue={props?.currentMedicationValue}
               allNotes={props?.allNotes}
               />
           ) : componentName === "nursingprocedure" ? (
             <NursingProcedure
             saveNursingProcedure={props?.saveNursingProcedure}
             onEditChart={props?.onEditChart}
             patientgetData={props.patientgetData}
             />
           ) : componentName === "packageorder" ? (
             <PackageOrder
             savePackageOrder={props?.savePackageOrder}
             onEditChart={props?.onEditChart}
             patientgetData={props.patientgetData}
             />
           ) : null}
          </div>
        );
    });
 };

  // React.useEffect(() => {
  //   setformList(props?.formsList);
  // }, [props?.formsList]);

  // componentDidMount() {
  //   this.setState({
  //     formsList: this.props.formsList,
  //   });
  // }

  // componentDidUpdate(prevProps) {
  //   if (
  //     !(
  //       this.props.formsList.length === prevProps.formsList.length &&
  //       this.props.formsList.every(
  //         (value, index) => value === prevProps.formsList[index]
  //       )
  //     )
  //   ) {
  //     this.setState({
  //       formsList: this.props.formsList,
  //     });
  //   }
  // }

  const getComponets = () => {
    return JSON.stringify(formsList);
  };

  const onEditChart = (id, type,val) => {
    props?.onEditChart(id, type,val);
  };
  const onEditDiagnosis = (id, type) => {
    props?.onEditDiagnosis(id, type);
  };
  // const { classes } = this.props;
  const { parent_id } = props;
  return (
    <Row
      id={`${parent_id}_patient_chart_parent_row`}
      inLineStyles={{
        height: "calc(100vh - 180px)",
        overflow: "auto",
      }}
    >
       <Col
        >
           <Grid container >
           <div
        id={`${parent_id}_patient_chart_parent_div`}
        style={{ width: "100%" }}
      >
        <div
          id={`${parent_id}_patient_chart_parent_div`}
          style={{ width: "100%" ,marginBottom: "400px",marginTop:"10px"}}
        >
              <ReferralPatient location={props?.locationdata} setERefType={setERefType} />
            {renderComponentsInArrayOrder()}
        </div>
      </div>
           </Grid>
          
        </Col>
     
    </Row>
  );
};

// const mapStateToProps = (state) => ({});

export default PatientChart;