import React from "react";
import {
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  useTheme,
  CircularProgress,
} from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import SettingsBackupRestoreOutlinedIcon from "@mui/icons-material/SettingsBackupRestoreOutlined";

const QueueSelector = (props) => {
  const theme = useTheme();
  const { queueList, selectedQueue ,HandleQueueSelect} = props;
  const [open, setOpen] = React.useState(false);
  const queueSelection = (val) => {
    HandleQueueSelect(val)
    setOpen(false);
  };
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Button
        variant="contained"
        sizw="small"
        sx={{
          borderRadius: "0 0 15px 15px",
          backgroundColor: props?.queueColor||theme.palette.secondary.main,
          '&:hover': {
            backgroundColor: props?.queueColor||theme.palette.secondary.main,
          },
        }}        
        endIcon={props?.isgetQueueListData ? (
          <div style={{ display: "inline-flex" }}>
            <CircularProgress style={{ color: "#FFFFFF" }} size={20} />
          </div>
        ) : (
        <KeyboardArrowDownRoundedIcon />)}
        onClick={() => setOpen(true)}
      >
        {selectedQueue?.value }
      </Button>
      <Dialog open={open} maxWidth="xs" fullWidth>
        <DialogTitle
          sx={{
            borderBottom: `1px solid #000`,
            padding: "8px 20px 8px 30px",
          }}
        >
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item>
              <Typography variant="body1">QUEUE ITEMS</Typography>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => {
                  setOpen(false);
                }}
                size="small"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container direction={"column"} gap={0.5}>
            {queueList.map((Q) => {
              const selected = Q?.id == selectedQueue?.id;
              return (
                <Grid
                  item
                  mt={1}
                  onClick={() => queueSelection(Q)}
                  sx={{ cursor: "pointer" }}
                >
                  <Paper
                    variant="outlined"
                    sx={
                      selected
                        ? { borderColor: props?.queueColor || theme.palette.secondary.main }
                        : ""
                    }
                  >
                    <Grid
                      container
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      p={1}
                    >
                      <Grid item>
                        <Grid container gap={1} alignItems={"center"}>
                          <SettingsBackupRestoreOutlinedIcon
                            fontSize="small"
                            sx={{
                              color: selected ? props?.queueColor || theme.palette.secondary.main : "disabled",
                            }}
                          />
                          <Typography
                            variant="body2"
                            color={selected ? props?.queueColor || theme.palette.secondary.main : "GrayText"}
                          >
                            {Q.value}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={() => {
                            setOpen(false);
                          }}
                          size="small"
                        >
                          <ArrowForwardIosRoundedIcon
                            fontSize="small"
                            sx={{
                              color: selected ? props?.queueColor || theme.palette.secondary.main : "disabled",
                            }}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default QueueSelector;
