import React, { useState, useEffect, useContext } from 'react';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { AlertProps, allowedFileTypesOfReactFileViewer, getGlobalValueFrom_LclStrg_And_QryStrg, isValidUrl, notificationWebSocketURL } from '../../utils';
import { useDispatch } from 'react-redux';
import actions from '../../redux/actions';
import { Card, CardContent, Avatar, ListItemAvatar, Button, Dialog, Grid, DialogTitle, GlobalStyles } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import { AlertContext } from '../../context';
import FileViewer from 'react-file-viewer';
import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from 'axios';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import "./notification.css"
const NotificationComponent = () => {
  const [socket, setSocket] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fileRead, setFileRead] = useState({
    fileUrl: "",
    fileType: "",
    open: false,
    data: ""
  });
  const [deletingNotification, setDeletingNotification] = useState(null);

  const dispatch = useDispatch();
  const alert = useContext(AlertContext);

  async function getInitNotification(id) {
    const initNotific = await dispatch(actions.GET_WEB_SOCKET_MESSAGE(id));
    setNotifications(initNotific?.payload?.data);
    setUnreadCount(initNotific?.payload?.data?.length);
  }

  useEffect(() => {
    getInitNotification(getGlobalValueFrom_LclStrg_And_QryStrg({
      localStoragekeyName: 'UserInfo',
      returnObjKeyName: 'practitionerid',
    }));
  }, []);
  useEffect(() => {
    
    // If the binary data is provided as a Blob (e.g., image, PDF, etc.)
    if (fileRead.data) {
      const blob = new Blob([fileRead.data], { type: fileRead?.fileType });
      const url = URL.createObjectURL(blob);
      setFileRead({ ...fileRead, fileUrl: url })
    }

    // Cleanup Blob URL when the component unmounts
    return () => {
      if (fileRead?.fileUrl) {
        URL.revokeObjectURL(fileRead?.fileUrl);
      }
    };
  }, [fileRead.data]);
  useEffect(() => {
    setUnreadCount(notifications?.length);
  }, [notifications]);

  useEffect(() => {
    const ws = new WebSocket(notificationWebSocketURL);
    setSocket(ws);

    ws.onopen = () => {
      console.log('WebSocket connected');

      ws.send(
        JSON.stringify({
          action: 'NOTIFY',
          payload: {
            altertoid: getGlobalValueFrom_LclStrg_And_QryStrg({
              localStoragekeyName: 'UserInfo',
              returnObjKeyName: 'practitionerid',
            }),
          },
        })
      );
    };

    ws.onmessage = (event) => {
      const data = event.data;
      setNotifications((prev) => [...prev, JSON.parse(data)]);
    };

    ws.onerror = (error) => {
      console.error('WebSocket Error:', error);
    };

    ws.onclose = () => {
      console.log('WebSocket Disconnected');
    };

    return () => {
      ws.close();
    };
  }, []);

  const updateReadStatus = async (logId) => {
    debugger
    try {
      setDeletingNotification(logId);
    // Add a delay for the animation to play
    setTimeout(async () => {
      let apiData = await dispatch(actions.NOTIFiCATION_UPDATE_READ_STATUS([logId]));
      if (apiData?.payload?.data?.statusCode === 200) {
        setDeletingNotification(null);// Reset after deletion
        setNotifications((prev) => prev.filter((notification) => notification._id !== logId));
        setUnreadCount((prev) => prev - 1);

      }
    }, 700); 

    } catch (error) {
      console.error('Failed to update notification status', error);
    }
  };

  const markAllAsRead = async () => {
    try {
      const allNotificationIds = notifications.map((notification) => notification._id);
      if (allNotificationIds.length > 0) {
        dispatch(actions.NOTIFiCATION_UPDATE_READ_STATUS(allNotificationIds));
        setNotifications([]);
        setUnreadCount(0);
      }
    } catch (error) {
      console.error('Failed to mark all notifications as read', error);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = (e) => {
    e?.stopPropagation();
    setFileRead({
      data: null,
      fileType: null,
      open: false,
    });
  };

  const viewFile = async (url, e) => {
    try {
      e?.stopPropagation();
      if (fileRead?.fileUrl) {
        URL.revokeObjectURL(fileRead?.fileUrl); // Cleanup old Blob URL
      }
      if (isValidUrl(url)) {
        // Fetch the file using axios
        const response = await axios.get(url, { responseType: 'blob' });

        // Get the file type from the response headers
        const mimeType = response?.headers?.['content-type'];
        const fileExtension = mimeType?.split('/')[1]; // Extracting the file extension
        const allowedTypesMap = {
          'jpeg': 'jpg',
          'jpg': 'jpg',
          'png': 'png',
          'pdf': 'pdf',
          'plain': 'txt',
          'msword': 'docx',
          'vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
          'vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
          'vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx'
        };

        const fileType = allowedTypesMap[fileExtension] || fileExtension;

        if (allowedFileTypesOfReactFileViewer.includes(fileType)) {
          // Create a Blob URL for viewing in the dialog
          const blobUrl = URL.createObjectURL(response.data);
          setFileRead({
            open: true,
            fileType,
            fileUrl: blobUrl,
            data: response.data,
          });
        } else {
          // If the file type is not supported, trigger a download
          const link = document.createElement('a');
          link.href = url;
          link.download = 'download';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } else {
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: 'Attachment URL is not valid!',
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      }
    } catch (error) {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: error?.message || 'Something went wrong!',
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
  };
  const open = Boolean(anchorEl);

  return (
    <Box>
      <GlobalStyles
        styles={{
          '.pg-viewer-wrapper': {
            overflow: 'hidden',
           
          },
        }}
      />
      <IconButton color="inherit" onClick={handleClick}>
        <Badge badgeContent={unreadCount} color="error" max={500}>
          <NotificationsIcon style={{ color: 'white' }} />
        </Badge>
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          marginTop: '30px', // Add a margin to move it slightly down
        }}
      >
        <Box sx={{ p: 2, backgroundColor: '#f5f5f5' }}>
          <Typography variant="h6" fontWeight="bold">
            Notifications
          </Typography>
          {notifications.length > 0 && (
            <Button
              onClick={markAllAsRead}
              variant="text"
              color="primary"
              size="small"
              sx={{ textTransform: 'none', mt: 1 }}
            >
              Mark All as Read
            </Button>
          )}
        </Box>
        <Divider />
        <List sx={{maxHeight: '400px',minWidth:"600px",}}>
          {notifications.length === 0 ? (
            <ListItem>
              <ListItemText primary="No new notifications" />
            </ListItem>
          ) : (
            notifications.map((notification) => (
              <Card
                key={notification._id}
                variant="outlined"
                sx={{
                  m: 1,
                  '&:hover': {
                    boxShadow: 4,
                  },
                  borderRadius: 2,
                  cursor: 'pointer',
                  width: '600px',
                   
                }}
                className={`notification-card ${
                  deletingNotification === notification._id ? 'slide-out' : ''
                }`}
                onClick={() => updateReadStatus(notification._id)}
              >
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar sx={{
                      bgcolor: deepPurple[500],
                      width: 56,
                      height: 56
                    }} >
                      {notification.alertfrom?.charAt(0)}
                    </Avatar>
                  </ListItemAvatar>

                  <CardContent sx={{ flex: 1 }}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {notification.subject}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {notification.body}
                    </Typography>
                    <Typography
                      variant="caption"
                      display="block"
                      color="text.disabled"
                      sx={{ mt: 1 }}
                    >
                      From: {notification.alertfrom}
                    </Typography>
                    <Typography
                      variant="caption"
                      display="block"
                      color="text.disabled"
                      sx={{ mt: 1 }}
                    >
                      {moment.unix(notification.logtime).format('MMMM Do YYYY, h:mm:ss a')}
                    </Typography>
                  </CardContent>

                  <Box sx={{ alignSelf: "center" }}>
                    {notification?.attachment && (<IconButton
                      onClick={(e) => viewFile(notification?.attachment, e)}
                      color="primary"
                      aria-label="Mark as Read"
                    >
                      <VisibilityIcon />
                    </IconButton>)}
                  </Box>
                </ListItem>
              </Card>
            ))
          )}
        </List>
      </Popover>
      <Dialog open={fileRead?.open} onClose={handleCloseDialog} fullWidth maxWidth="lg">
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',padding:"20px" }}>
          <Typography variant="h6">Attachment</Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {/* Container for the FileViewer to prevent scrollbars */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh', // Adjust height to fit most screens
            overflow: 'hidden', // Prevents scrollbars
          }}
        >
          {/* FileViewer component to display file */}
          {fileRead?.fileUrl && fileRead?.fileType ? (
            <FileViewer
              fileType={fileRead?.fileType} // Extension like 'pdf', 'jpg'
              filePath={fileRead?.fileUrl} // Blob URL
            />
          ) : (
            <Typography variant="body1" sx={{ p: 2 }}>
              Unable to display the file
            </Typography>
          )
          }
        </Box>
      </Dialog>

    </Box>
  );
};

export default NotificationComponent;
