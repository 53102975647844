import React, { useEffect, useState, useContext } from "react";
import { Div } from "qdm-component-library";
import withAppBar from "../../hoc/withAppBar";
import withAllContexts from "../../hoc/withAllContexts";
import { Dialog, DialogContent, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import actions from "../../redux/actions";
import { AlertContext } from "../../context";
import {
  AlertProps,
  DIAGNOSIS_Json_Generator,
  actionButtonClick,
} from "../../utils";
import jwtDecode from "jwt-decode";
import { AppRoutes } from "../../router/routes";
import PatientDetails, { PatientDetailWithoutAppBar } from "../patientDetail";
import axios from "axios";
import { axiosCall, urls, __tenantid__, dbName, query_ids } from "../../utils";
import { QueueManagementContext } from "../../context";
import { GetQueueData } from "../../utils";
import ModifiedTopNavbar from "../../components/navBar/ModifiedTopNavBar";
import { useLocation, useNavigate } from "react-router-dom";
import { TransferCase } from "./transferCase";
import MuiDrawer from "../../components/drawer";
import Commonsidebar from "../../components/commonsidebar";

const ErefEMR = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useContext(AlertContext)
  const {
    setAllData,
    setDisplayData,
    setPreviousData,
    setpreviousQueue,
    setloader,
  } = useContext(QueueManagementContext);
  const [btnName, setBtnName] = useState(false);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [actionName, setActionName] = useState("");
  const [allNotes, setAllNotes] = useState([]);
  const [notesTypes, setNotesTypes] = useState([]);
  const [currentMedicationValue, setCurrentMedicationValue] = useState("");
  const [comp_SideBar_State, setComp_SideBar_State] = React.useState({
    open: false,
    headingName: "",
    reasonType: "",
    isCancellationType: false,
  });
  const [cancel, setCancel] = useState({
    cancelbookingreason: "",
    cancelData: {},
    cacellationType: process.env.REACT_APP_CANCEL_APPMNT_TYPE_SINGLE,
  });
  const [noshow, setNoShow] = React.useState({
    reason: "",
    comments: "", //common for both cancellation n no show
  });
  const [transfer, setTransfer] = React.useState({
    openTransfer: false,
  });

  // * <-------------------EVENTS------------------->
  const userInfo = localStorage?.getItem("UserInfo");
  const decodedUserinfo =
    userInfo === null || userInfo === undefined ? "" : jwtDecode(userInfo);
  const _facility_ = decodedUserinfo?.facilityid ?? "";

  const loadData = async () => {
    await dispatch(
      actions.ENC_BASED_CC({ encounter_id: location?.state?.encounterID })
    );
  };
  useEffect(() => {
    loadData();
  }, [props]);

  const goBackFn = () => {
    navigate(AppRoutes.referralInRegistration, {
      state: {
        loading: false,
        // patientId: location?.state?.patientId??location?.state?.patientId,
        // encounterID: location?.state?.encounterID,
        IsViewMode: location?.state?.IsViewMode,
        isEditedMode: location?.state?.isEditedMode,
        data: location?.state?.data ?? "",
        referraldetails:
          location?.state?.data?.referraldetails ??
          location?.state?.referraldetails ??
          "",
        patientId:
          location?.state?.data?.patientId ??
          location?.state?.data?._id ??
          location?.state?.patientId ??
          location?.state?.patientId,
        encounterID:
          location?.state?.data?.encounterinfo?._id ??
          location?.state?.encounterID,
        personid:
          location?.state?.data?.patientdetails?.personid ??
          location?.state?.personid,
        totalData: location?.state?.totalData,
        addtionaldetails: location?.state?.addtionaldetails,
        nextofkin: location?.state?.nextofkin,
        patientdetails: location?.state?.patientdetails,
        isModify: location?.state?.isModify,
        userId:
          location?.state?.data?.patientdetails?.personid ??
          location?.state?.personId ??
          location?.state?.personid ??
          "",
        //backMode: !location?.state?.IsViewMode && !location?.state?.isModify ? true : false,
        referralId: location?.state?.referralId,
      },
    });
  };
  const gettreatmentdata = async () => {
    await dispatch(
      actions.TRAEMENT_PLAN_READ({
        patientid:
          location?.state?.data?.patientId ?? location?.state?.data?._id,
      })
    );
  };



  const actionsMap = location?.state?.totalData?.action.reduce(
    (map, actionItem) => {
      map[actionItem.action] = {
        actionName: actionItem.action,
        action: actionItem.action,
        apiEndPoint: actionItem.apiendpoint,
        backendCall: actionItem.backEndCall,
      };
      return map;
    },
    {}
  );


  useEffect(() => {
    gettreatmentdata();
  }, []);
  const successfun2 = async () => {
    const userInfo = localStorage.getItem("UserInfo");
    const decodedUserinfo = jwtDecode(userInfo);
    // if (!patientData?.length > 0) {
    //   props?.alert?.setSnack({
    //     open: true,
    //     severity: AlertProps.severity.error,
    //     msg: "Please save the details",
    //     vertical: AlertProps.vertical.top,
    //     horizontal: AlertProps.horizontal.center,
    //     tone: true,
    //   });
    // } else {
    setBtnName(true);
    let payload = {
      ticketId: location?.state?.totalData?.ticketId ?? "",

      userAction: process.env.REACT_APP_E_REFERRAL_APPROVE,
      data: {
        ticketId: location?.state?.totalData?.ticketId ?? "",
        practitionerId: decodedUserinfo?.practitionerid,
        practitionerRoleId: decodedUserinfo?.roleid,
      },
    };
    let token = localStorage.getItem("JwtToken");
    await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        jwtToken: `${token}`,
      },
      url: process.env.REACT_APP_PERFORMUSERACTION_API,
      data: payload,
    })
      .then((res) => {
        if (res.status === 200 && !res.data.error) {
          props?.alert?.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: "Approved successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          setBtnName(false);
          navigate(AppRoutes.dashboard);
        } else {
          props?.alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: res.data.message,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          setBtnName(false);
        }
      })
      .catch((error) => {
        props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please contact to admin",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
        setBtnName(false);
      });
    // }
  };
  console.log(location.state, "encounterData()");
  const getcurrentmedicationval = async () => {
    const data = await axiosCall(
      {
        db_name: dbName,
        filter: {
          patientid: location?.state?.patientId ?? location?.state?.patientId,
          encounterid: location?.state?.encounterID,
        },
        queryid: query_ids?.allNotesAgainstPatientidAndEncoid,
      },
      urls?.readQdmQueries
    );
    setAllNotes(data);
    if (data?.length > 0) {
      data.forEach((v, i) => {
        if (
          v?.clicnicaldoctype?._id === process.env.REACT_APP_CURRENT_MEDICATION
        ) {
          setCurrentMedicationValue(v?.clicnicaldocdesc);
        }
      });
      //setCurrentMedicationValue(data?.[0]?.clicnicaldocdesc)
    }
    const notesType = await axiosCall(
      {
        db_name: dbName,
        filter: {
          type: ["CLINICALNOTETYPE"],
          tenantid: __tenantid__,
          facilityid: _facility_,
          lang: "en",
        },
        queryid: query_ids?.getProgressNotesType,
      },
      urls?.readQdmQueries
    );
    setNotesTypes(notesType);
  };
  async function Comp_SideBar_HandleSubmit(state) {
    setComp_SideBar_State({
      open: false,
      headingName: "",
      reasonType: "",
      isCancellationType: false,
    });
    const data = location?.state?.totalData;
    const TotActionData = location?.state?.l;
    try {
      let fetchVal = await actionButtonClick(
        comp_SideBar_State?.reasonType == "EREFERALONHOLDREASON"
          ? process.env.REACT_APP_E_REFERRAL_ON_HOLD
          : comp_SideBar_State?.reasonType == "EREFERALREJECTREASON"
          ? process.env.REACT_APP_E_REFERRAL_REJECT
          : "",
        TotActionData?.apiEndPoint || TotActionData?.apiendpoint,
        TotActionData?.backendCall || TotActionData?.backEndCall,
        data?.ticketId,
        data?.reason?.value, //app booking cancel reason
        data?.queueMetaData?.queuename?.queuename,
        state?.reason?.value, // no show reason
        state?.otherReason, // app booking n no show others
        data?.reason, // cancel check in reason full object
        data?.otherReason, // cancel check in others
        data?.cacellationType, // app booking type
        data?.inputdoc?.appointmentid
      );
      if (!fetchVal.error) {
        navigate(AppRoutes.dashboard)
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          msg: fetchVal?.response?.[0]?.message,
          severity: "error",
        });
      }
    } catch (error) {
      alert.setSnack({
        ...alert,
        open: true,
        msg: error?.message,
        severity: "error",
      });
    }
  }
  useEffect(() => {
    getcurrentmedicationval();
  }, []);
  return (
    <Div style={{ position: "fixed" ,width:"100%"}}>
      <ModifiedTopNavbar
        goBack={goBackFn}
        isBackIcon={true}
        parent_id={"Clinical Information"}
        alertHold={"alertHold"}
        successfun={() => navigate(AppRoutes.dashboard)}
        navBarArr={[]}
        title={"E-Referral Clinical Information"}
        approveStatus={successfun2}
        setActionName={setActionName}
        actionName={actionName}
        totalData={location?.state?.totalData}
        isFinish={location?.state?.isRefRegister}
        handleHold={() => {
          setComp_SideBar_State({
            ...comp_SideBar_State,
            open: true,
            reasonType: "EREFERALONHOLDREASON",
            headingName: "On Hold",
          })
        }}
        handleReject={() => {
          setComp_SideBar_State({
            ...comp_SideBar_State,
            open: true,
            reasonType: "EREFERALREJECTREASON",
            headingName: "Reject",
          });
        }}
        // isFinishFunc={() => navigate(AppRoutes.dashboard)}
        isFinishFunc={async () => {
          // setloader(true);
          // const data = await GetQueueData();
          // setAllData(data.everyData);
          // setDisplayData(data.getDisplayData);
          // setPreviousData(data.getPreviousData);
          // setpreviousQueue(props?.state?.queueId);
          // setloader(false);
          navigate(AppRoutes.dashboard);
        }}
        ApproveNRejectNHold={location.state?.isERefView}
        appointmentId={props?.totalData?.inputdoc?.appointmentid} //FIXME -
        TransferFunc={() => setTransfer({ ...transfer,open:true })}
      />
      <Grid container>
        <PatientDetailWithoutAppBar location={location} isPatient={true} />
      </Grid>
      <Dialog
        maxWidth="md"
        fullWidth
        open={transfer?.open}
        onClose={() => {
          setTransfer({ ...transfer, open: false });
        }}
      >
        <DialogContent>
          <TransferCase
            state={location?.state}
            setDialog={setTransfer}
          />
        </DialogContent>
      </Dialog>
      <MuiDrawer
        open={comp_SideBar_State?.open}
        close={() => {
          setComp_SideBar_State({ ...comp_SideBar_State, open: false });
        }}
      >
        <Commonsidebar
          reasonType={comp_SideBar_State?.reasonType}
          headingName={comp_SideBar_State?.headingName}
          cancellationType={{
            isCancellationType: comp_SideBar_State?.isCancellationType,
            headingName: "Cancellation Type",
            list: [
              {
                label: "Single",
                value: process.env.REACT_APP_CANCEL_APPMNT_TYPE_SINGLE,
              },
              {
                label: "Multiple",
                value: process.env.REACT_APP_CANCEL_APPMNT_TYPE_MULTIPLE,
              },
            ],
          }}
          Comp_SideBar_HandleSubmit={Comp_SideBar_HandleSubmit}
        />
      </MuiDrawer>
    </Div>
  );
};
export default withAllContexts(withAppBar(ErefEMR));
