import * as React from "react";
import Button from "@mui/material/Button";
import {
  Typography,
  Paper,
  Grid,
  CircularProgress,
} from "@mui/material";
import theme from "../../../utils/theme";
import IntraDialysisTable from "../intraDialysisTable";
import {
  AlertProps,
  __tenantid__,
  axiosCall,
  fetchData,
  getFacilityId,
  getGlobalValueFrom_LclStrg_And_QryStrg,
  getTenantId,
  personRequests,
  urls,
} from "../../../utils";
import { useDispatch } from "react-redux";
import actions from "../../../redux/actions";
import { useSelector } from "react-redux";
import axios from "axios";
import jwtDecode from "jwt-decode";
import TreatmentChart from "./treatmentchart";
import Predialysis from "./predialysis";
import Postdialysis from "./postdialysis";
import { AlertContext } from "../../../context";
import { predialysis } from "../vitalsJson";
import { v4 as uuidv4 } from "uuid";
import PrintIcon from "@mui/icons-material/Print";

export default function HDDialogBox(props) {

  const dispatch = useDispatch();
  const alertContext = React.useContext(AlertContext);
  const [defaultValues, setdefaultValues] = React.useState();
  const [isMasterSave, setisMasterSave] = React.useState(false);
  const [striptestList, setstriptestList] = React.useState();
  const [treatChart, settreatChart] = React.useState();
  const [PreDialysis, setPreDialysis] = React.useState();
  const [PostDialysis, setPostDialysis] = React.useState();
  const [dialysisId, setdialysisId] = React.useState();
  const [IntraDialysis, setIntraDialysis] = React.useState();

  const [isPreDialysis, setisPreDialysis] = React.useState();
  const [isPostDialysis, setisPostDialysis] = React.useState();
  const [isIntraDialysis, setisIntraDialysis] = React.useState();
  const [istreatChart, setistreatChart] = React.useState();
  const readAllergyLoading = useSelector((state) => state?.dialysis?.upsertDailysis?.loading);
  // {
  // LastVisitDate:"",
  // DialysisDate:"",
  // HDMachineNo:"",
  // striptest:"",
  // TargetDryWeight:"",
  // TargetUF:"",
  // PreHDWeight:"",
  // PrevPostHDWeight:"",
  // IDWG:"",
  // Shift:"",
  // Frequency:"",
  // Duration:"",
  // Qb:"",
  // Qd:"",
  // AVFneedlesize:"",
  // DialyzerType:"",
  // SurfaceArea:"",
  // DialysateCa2:"",
  // Noofreuse:"",
  // Type:"",
  // Loading:"",
  // Maintenance:"",
  // StaffID:""
  // }
  const facilityId = getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"facilityid"})
  const practitionerId = getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"practitionerid"});
  const loadDefaultValues = async () => {
    let payload = {
      screenid: process.env.REACT_APP_TREATMENT_CHART_SCREEN_ID,
      tenantid: getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"tenantid"}),
      filtervalues: {
        encounterid: props?.patientData?.inputdoc?.encounterid || " ", //props?.patientgetData?._id||props?.location?.state?.encounterId ||props?.location?.state?.encounter_id,
        patientid: props?.patientData?.inputdoc?.patientid, //props?.patientgetData?.Patient?.[0]?._id||props?.location?.state?.patientId,
        practitionerid: practitionerId,
      },
    };
    let token = localStorage.getItem("JwtToken");

    let register = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_SCREEN_DEFAULT_VALUE,
      headers: {
        "Content-Type": "application/json",
        jwtToken: `${token}`,
      },
      data: JSON.stringify(payload),
    })
      .then(res => {
        if (
          res?.data?.response?.screenId ===
          process.env.REACT_APP_TREATMENT_CHART_SCREEN_ID
        ) {
          const defaultValues = res?.data?.response?.defaultValues;
          const stateNames = [
            "DialysisDate",
            "HDMachineNo",
            "previous_post_hd_weight",
            "LastVisitDate",
            "shift",
            "frequency",
            "arrived_time",
            "end_time",
            "start_time",
            "hd_duration",
            "needleSize",
            "hepatitis",
            "qd",
            "qb",
            "dialysate",
            "vascular_access",
            "TargetDryWeight",
            "previous_post_hd_weight",
            "SurfaceArea",
            "Maintenance",
            "Loading",
            "Heparin",
            "HeparinLoading",
            "DialyzerType",
            "qd_baseline",
            "Conductivity_firsthour",
            "qd_firsthour",
            "qd_secondhour",
            "Conductivity_secondhour",
            "qd_thirdhour",
            "Conductivity_thirdhour",
            "Nursingnotes",
            "Complicationtime_thirdhour",
            "Complicationtime_secondhour",
            "Complicationtime_firsthour",
            "Complicationtime_baseline",
            "Complicationtext_baseline",
            "Complicationaction_baseline",
            "Complicationtext_firsthour",
            "Complicationaction_firsthour",
            "Complicationtext_secondhour",
            "Complicationaction_secondhour",
            "Complicationtext_thirdhour",
            "Complicationaction_thirdhour",
          ];
          //   const stateValues = {};
          //   defaultValues.forEach((s) => {
          //     if (stateNames.includes(s.stateName)) {
          //       stateValues[s.stateName] = s.value;
          //     }
          //   });
          let obj = {};
          defaultValues.forEach(v => {
            obj[v?.stateName] = v?.value;
            if (v?.stateName === "Endedby_sn") {
              obj[v?.stateName] = v?.textfield;
            }
          });
          setdefaultValues(obj);
          console.log(obj, defaultValues, "()()()");
          // settreatChart({
          //     LastVisitDate:obj?.LastVisitDate ?? "",
          //     DialysisDate:obj?.DialysisDate ?? "",
          //     HDMachineNo:obj?.HDMachineNo ?? "",
          //     striptest:"",
          //     TargetDryWeight:obj?.TargetDryWeight ?? "",
          //     TargetUF:"",
          //     PreHDWeight:"",
          //     PrevPostHDWeight:"",
          //     IDWG:"",
          //     Shift: obj?.shift ?? "",
          //     Frequency:obj?.frequency ?? "",
          //     Duration:obj?.hd_duration ?? "",
          //     Qb:obj?.qb ?? "",
          //     Qd:obj?.qd ?? "",
          //     AVFneedlesize:obj?.needleSize ?? "",
          //     DialyzerType:obj?.DialyzerType ?? "",
          //     SurfaceArea:obj?.SurfaceArea ?? "",
          //     DialysateCa2:obj?.dialysate,
          //     Noofreuse:"",
          //     Type:obj?.Heparin ?? "",
          //     Loading:obj?.Loading ?? "",
          //     Maintenance:obj?.Maintenance ?? "",
          //     StaffID:obj?.staff_id ?? ""
          // })
        } else {
        }
      })
      .catch(e => console.error(e));
  };

  const intraPayloadContructor = (IntraDialysis, type) => {
    let currentEpochTime = Math.floor(Date.now() / 1000);
    return Object?.keys(IntraDialysis ?? {}).length !== 0 ? {
      "NN": {
        "shortdesc": "Nursing Notes",
        "time": currentEpochTime,
        "obscode": "NN",
        "obsvalue": IntraDialysis?.NursingNotes ?? "",
      },
      "StaffID": {
        "shortdesc": "Staff ID",
        "time": currentEpochTime,
        "obscode": "StaffID",
        "obsvalue": IntraDialysis?.StaffID ?? "",
      },
      "COMPLICATION": [
        {
          "complication": {
            "abnormalmsg": "\"Min\"",
            "validation_max": 42,
            "shortdesc": "Complication",
            "isabnormal": true,
            "type": "text",
            "groupname": "Complication",
            "mandatory": true,
            "obsinternalid": "VitalsCodes/10003",
            "arrid": "table",
            "validation_min": 35,
            "obsuom": "VitalsMeasureCodes/10002",
            "time": 1721463538,
            "obscode": "COMPLICATION",
            "default_unit": "text",
            "obsvalue": IntraDialysis?.COMPLICATION?.[0]?.complication ?? "",
          },
          "action": {
            "abnormalmsg": "\"Min\"",
            "validation_max": 42,
            "shortdesc": "Action",
            "isabnormal": true,
            "type": "text",
            "groupname": "Complication",
            "mandatory": true,
            "obsinternalid": "VitalsCodes/10003",
            "arrid": "table",
            "validation_min": 35,
            "obsuom": "VitalsMeasureCodes/10002",
            "time": 1721463538,
            "obscode": "ACTION",
            "default_unit": "text",
            "obsvalue": IntraDialysis?.COMPLICATION?.[0]?.action ?? "",
          },
          "time": {
            "abnormalmsg": "\"Min\"",
            "validation_max": 42,
            "shortdesc": "Time",
            "isabnormal": true,
            "type": "time",
            "groupname": "Complication",
            "mandatory": true,
            "obsinternalid": "VitalsCodes/10003",
            "arrid": "table",
            "validation_min": 35,
            "obsuom": "VitalsMeasureCodes/10002",
            "time": 1721463538,
            "obscode": "TIME",
            "default_unit": "time",
            "obsvalue": IntraDialysis?.COMPLICATION?.[0]?.time ?? "",
          }
        }
      ],
      "Time": {
        "shortdesc": "Time",
        "time": currentEpochTime,
        "obscode": "Time",
        "obsvalue": IntraDialysis?.Time ?? "",
      },
      "BP": {
        "shortdesc": "Blood Pressure",
        "time": currentEpochTime,
        "obscode": "BP",
        "obsvalue": IntraDialysis?.BloodPressure ?? "",
      },
      "QB": {
        "shortdesc": "Qb - mL/min",
        "time": currentEpochTime,
        "obscode": "QB",
        "obsvalue": IntraDialysis?.Qb ? String(IntraDialysis?.Qb || "") : "",
      },
      "TEMP": {
        "shortdesc": "Temperature",
        "time": currentEpochTime,
        "obscode": "TEMP",
        "obsvalue": IntraDialysis?.Temperature ? String(IntraDialysis?.Temperature || "") : "",
      },
      "QD": {
        "shortdesc": "Qd - mL/min",
        "time": currentEpochTime,
        "obscode": "QD",
        "obsvalue": String(IntraDialysis?.Qd || "") ?? "",
      },
      "CONDUCTIVITY": {
        "shortdesc": "Conductivity",
        "time": currentEpochTime,
        "obscode": "CONDUCTIVITY",
        "obsvalue": String(IntraDialysis?.Conductivity || "") ?? "",
      },
      "PULSE": {
        "shortdesc": "Pulse",
        "time": currentEpochTime,
        "obscode": "PULSE",
        "obsvalue": String(IntraDialysis?.Pulse || "") ?? "",
      },
      "TMP": {
        "shortdesc": "TRANSMEMBRANE PRESSURE",
        "time": currentEpochTime,
        "obscode": "TMP",
        "obsvalue": String(IntraDialysis?.TransmembranePressure || "") ?? "",
      },
      "VP": {
        "shortdesc": "VENOUS PRESSURE",
        "time": currentEpochTime,
        "obscode": "VP",
        "obsvalue": String(IntraDialysis?.VenousPressure || "") ?? "",
      },
      "AUFV": {
        "shortdesc": "Accumulated UFV - Kg",
        "time": currentEpochTime,
        "obscode": "AUFV",
        "obsvalue": String(IntraDialysis?.AccumulatedUFV || "") ?? "",
      },
      "Heparin": {
        "shortdesc": "Heparin",
        "time": currentEpochTime,
        "obscode": "Heparin",
        "obsvalue": IntraDialysis?.Heparin ?? "",
      },
      "id": {
        "id": IntraDialysis?.id ? IntraDialysis?.id : uuidv4(),//"9e199ee5-7401-4a55-a2d9-60a7518d2e62"
      },
      "Type": {
        "shortdesc": "Type",
        "time": currentEpochTime,
        "obscode": "Type",
        "obsvalue": type,
      }
    }:{}
  }

  const SaveTreatProcedure = async () => {
    if (isMasterSave) {
      let currentEpochTime = Math.floor(Date.now() / 1000);
      let IntraDialysisData = [];
      if (IntraDialysis?.baseline?.BloodPressure !== null && IntraDialysis?.baseline?.BloodPressure !== undefined) {
        IntraDialysisData.push(intraPayloadContructor(IntraDialysis?.baseline,"Baseline"));
        if (IntraDialysis?.firstHour?.BloodPressure !== null && IntraDialysis?.firstHour?.BloodPressure !== undefined) {
          IntraDialysisData.push(intraPayloadContructor(IntraDialysis?.firstHour,"1st Hour"));
          if (IntraDialysis?.secondHour?.BloodPressure !== null && IntraDialysis?.secondHour?.BloodPressure !== undefined) {
            IntraDialysisData.push(intraPayloadContructor(IntraDialysis?.secondHour,"2nd Hour"));
            if (IntraDialysis?.thirdHour?.BloodPressure !== null && IntraDialysis?.thirdHour?.BloodPressure !== undefined) {
              IntraDialysisData.push(intraPayloadContructor(IntraDialysis?.thirdHour,"3rd Hour"));
            }
          }
        }
      }
      let payload = [
        {
          db_name: process.env.REACT_APP_DB,
          entity: "CA_DialysisVitalsSigns",
          filter:  {
            _id: dialysisId?._id,
          },
          is_metadata: true,
          metadataId: process.env.REACT_APP_METADATAID,
          metadata_dbname: process.env.REACT_APP_METADATA_DBNAME,
          doc: {
            tenantid:  getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"tenantid"}),
            facilityid: facilityId,
            orgid: facilityId,
            patientid: props?.patientData?.inputdoc?.patientid,
            confirmedby: "",
            encountertype: "OP",//dialysisId?.encType,   need to fix this encountertype dynamicaly --sathiya
            encounterid: props?.patientData?.inputdoc?.encounterid,
            orderlineid: props?.patientData?.fields[17]?.value,//dialysisId?.orderlineid,
            component: [
              {
                predialysis: PreDialysis===undefined ? [] : [
                  {
                    CCA: {
                      //"shortdesc": "Condition of Cascular Access",
                      time: currentEpochTime,
                      obscode: "CCA",
                      obsvalue: PreDialysis?.CCA  ?? "",
                    },
                    SS: {
                      //"shortdesc": "Started by SN",
                      time: currentEpochTime,
                      obscode: "SS",
                      obsvalue: PreDialysis?.SS  ?? "",
                    },
                    PCS: {
                      //"shortdesc": "Patient Condition/ Symptoms",
                      time: currentEpochTime,
                      obscode: "PCS",
                      obsvalue: PreDialysis?.PCS  ?? "",
                    },
                    Hepatatis: {
                      //"shortdesc": "Hepatatis",
                      time: currentEpochTime,
                      obscode: "Hepatatis",
                      obsvalue: PreDialysis?.Hepatatis  ?? "",
                    },
                    StaffID: {
                      //"shortdesc": "Staff ID",
                      time: currentEpochTime,
                      obscode: "StaffID",
                      obsvalue: PreDialysis?.StaffID  ?? "",
                    },
                    TimeStart: {
                      //"shortdesc": "Time(Start HD)",
                      time: currentEpochTime,
                      obscode: "TimeStart",
                      obsvalue: PreDialysis?.TimeStart  ?? "",
                    },
                    BP: {
                      //"shortdesc": "Blood Pressure",
                      time: currentEpochTime,
                      obscode: "BP",
                      obsvalue: PreDialysis?.BP  ?? "",
                    },
                    TEMP: {
                      //"shortdesc": "Temperature",
                      time: currentEpochTime,
                      obscode: "TEMP",
                      obsvalue: String(PreDialysis?.TEMP || "") ?? "",
                    },
                    PULSE: {
                      //"shortdesc": "Pulse",
                      time: currentEpochTime,
                      obscode: "PULSE",
                      obsvalue: String(PreDialysis?.PULSE || "") ?? "",
                    },
                    TimeArrived: {
                      //"shortdesc": "Time(Arrived)",
                      time: currentEpochTime,
                      obscode: "TimeArrived",
                      obsvalue: PreDialysis?.TimeArrived ?? "",
                    },
                    id: {
                      id: PreDialysis?.id ? PreDialysis?.id : uuidv4(),//"667e3636-3ba1-49d4-ad56-f4d36883fbc2",
                    },
                    TVA: {
                      //"shortdesc": "Type of Vascular Access",
                      time: currentEpochTime,
                      obscode: "TVA",
                      obsvalue: PreDialysis?.TVA ?? "",
                    },
                    HepatatisType: {
                      //"shortdesc": "Hepatatis Type",
                      time: currentEpochTime,
                      obscode: "HepatatisType",
                      obsvalue: PreDialysis?.Hepatatis === "No" ? "" : PreDialysis?.HepatatisType ?? "",
                    },
                  },
                ],
                postdialysis: PostDialysis===undefined ? [] : [
                  {
                    EndTime: {
                      obscode: "EndTime",
                      obsvalue: PostDialysis?.EndTime  ?? "",
                      shortdesc: "End Time",
                      time: currentEpochTime,
                    },
                    EndedBySN: {
                      obscode: "EndedBySN",
                      obsvalue: PostDialysis?.EndedBySN ?? "",
                      //"shortdesc": "Ended By SN",
                      time: currentEpochTime,
                    },
                    TEMP: {
                      obscode: "TEMP",
                      obsvalue: String(PostDialysis?.TEMP || "") ?? "",
                      shortdesc: "Temperature",
                      time: currentEpochTime,
                    },
                    BPStnd: {
                      obscode: "BPStnd",
                      obsvalue: PostDialysis?.BPStnd ?? "",
                      shortdesc: "Blood Pressure(Standing)",
                      time: currentEpochTime,
                    },
                    BPSit: {
                      obscode: "BPSit",
                      obsvalue: PostDialysis?.BPSit ?? "",
                      shortdesc: "Blood Pressure(Sitting)",
                      time: currentEpochTime,
                    },
                    PULSESTND: {
                      obscode: "PULSESTND",
                      obsvalue: String(PostDialysis?.PULSESTND || "") ?? "",
                      shortdesc: "Pulse(Standing)",
                      time: currentEpochTime,
                    },
                    PULSESIT: {
                      obscode: "PULSESIT",
                      obsvalue: String(PostDialysis?.PULSESIT || "") ?? "",
                      shortdesc: "Pulse(Sitting)",
                      time: currentEpochTime,
                    },
                    PHDW: {
                      obscode: "PHDW",
                      obsvalue: String(PostDialysis?.PHDW || "") ?? "",
                      shortdesc: "Post HD Weight - Kg",
                      time: currentEpochTime,
                    },
                    TWL: {
                      obscode: "TWL",
                      obsvalue: String(PostDialysis?.TWL || "") ?? "",
                      shortdesc: "Total Weight Loss - Kg",
                      time: currentEpochTime,
                    },
                    Ktv: {
                      obscode: "Ktv",
                      obsvalue: PostDialysis?.Ktv ? String(PostDialysis?.Ktv) : "",
                      shortdesc: "Kt/V",
                      time: currentEpochTime,
                    },
                    NN: {
                      obscode: "NN",
                      obsvalue: PostDialysis?.NN ?? "",
                      shortdesc: "Nursing Notes",
                      time: currentEpochTime,
                    },
                    StaffID: {
                      obscode: "StaffID",
                      obsvalue: PostDialysis?.StaffID ?? "",
                      shortdesc: "Staff ID",
                      time: currentEpochTime,
                    },
                    id: {
                      id: PostDialysis?.id ? PostDialysis?.id : uuidv4(),//"52133ab0-79f7-4266-b811-300f0a855144",
                    },
                  },
                ],
                intradialysis: IntraDialysisData,
                treatmentChart: treatChart===undefined ? [] : [
                  {
                    LastVisitDate: {
                      obscode: "LastVisitDate",
                      obsvalue: treatChart?.LastVisitDate ?? "",
                      shortdesc: "Last Visit Date",
                      time: currentEpochTime,
                    },
                    DialysisDate: {
                      obscode: "DialysisDate",
                      obsvalue: treatChart?.DialysisDate ?? "",
                      shortdesc: "Dialysis Date",
                      time: currentEpochTime,
                    },
                    HDMachineNo: {
                      obscode: "HDMachineNo",
                      obsvalue: treatChart?.HDMachineNo ?? "",
                      shortdesc: "HD Machine No",
                      time: currentEpochTime,
                    },
                    striptest: {
                      obscode: "striptest",
                      obsvalue: treatChart?.striptest ?? "",
                      shortdesc: "Strip Test By",
                      time: currentEpochTime,
                    },
                    TargetDryWeight: {
                      obscode: "TargetDryWeight",
                      obsvalue: String(treatChart?.TargetDryWeight || "") ?? "",
                      shortdesc: "Target Dry Weight",
                      time: currentEpochTime,
                    },
                    TargetUF: {
                      obscode: "TargetUF",
                      obsvalue: String(treatChart?.TargetUF || "") ?? "",
                      shortdesc: "Target UF",
                      time: currentEpochTime,
                    },
                    PreHDWeight: {
                      obscode: "PreHDWeight",
                      obsvalue: String(treatChart?.PreHDWeight || "") ?? "",
                      shortdesc: "Pre HD Weight",
                      time: currentEpochTime,
                    },
                    PrevPostHDWeight: {
                      obscode: "PrevPostHDWeight",
                      obsvalue: String(treatChart?.PrevPostHDWeight || "") ?? "",
                      shortdesc: "Prev Post HD Weight",
                      time: currentEpochTime,
                    },
                    IDWG: {
                      obscode: "IDWG",
                      obsvalue: treatChart?.IDWG === null || treatChart?.IDWG === undefined ? "" : String(treatChart?.IDWG),
                      shortdesc: "IDWG (Pre last HDi/kg)",
                      time: currentEpochTime,
                    },
                    Shift: {
                      obscode: "Shift",
                      obsvalue: treatChart?.Shift  ?? "",
                      shortdesc: "Shift",
                      time: currentEpochTime,
                    },
                    Frequency: {
                      obscode: "Frequency",
                      obsvalue: treatChart?.Frequency  ?? "",
                      shortdesc: "Frequency (/week)",
                      time: currentEpochTime,
                    },
                    Duration: {
                      obscode: "Duration",
                      obsvalue: treatChart?.Duration ? String(treatChart?.Duration):"",
                      shortdesc: "Duration (hrs)",
                      time: currentEpochTime,
                    },
                    Qb: {
                      obscode: "Qb",
                      obsvalue: treatChart?.Qb ? String(treatChart?.Qb):"",
                      shortdesc: "Qb (ml/min)",
                      time: currentEpochTime,
                    },
                    Qd: {
                      obscode: "Qd",
                      obsvalue: treatChart?.Qd  ? String(treatChart?.Qd):"",
                      shortdesc: "Qd (ml/min)",
                      time: currentEpochTime,
                    },
                    AVFneedlesize: {
                      obscode: "AVFneedlesize",
                      obsvalue: treatChart?.AVFneedlesize  ?? "",
                      shortdesc: "AVF needle size",
                      time: currentEpochTime,
                    },
                    DialyzerType: {
                      obscode: "DialyzerType",
                      obsvalue: treatChart?.DialyzerType  ?? "",
                      shortdesc: "Dialyzer Type",
                      time: currentEpochTime,
                    },
                    SurfaceArea: {
                      obscode: "SurfaceArea",
                      obsvalue: String(treatChart?.SurfaceArea || "")  ?? "",
                      shortdesc: "Surface Area",
                      time: currentEpochTime,
                    },
                    // DialysateCa2:{}
                    DialysateCa2: {
                      obscode: "DialysateCa2",
                      obsvalue: treatChart?.DialysateCa2  ?? "",
                      shortdesc: "Dialysate Ca2+",
                      time: currentEpochTime,
                    },
                    Noofreuse: {
                      obscode: "Noofreuse",
                      obsvalue: treatChart?.Noofreuse  ?? "",
                      shortdesc: "No of reuse",
                      time: currentEpochTime,
                    },
                    Type: {
                      obscode: "Type",
                      obsvalue: treatChart?.Type  ?? "",
                      shortdesc: "Type",
                      time: currentEpochTime,
                    },
                    CMD: {
                      obscode: "CMD",
                      obsvalue: treatChart?.CMD  ?? "",
                      shortdesc: "Comments",
                      time: currentEpochTime,
                    },
                    Loading: {
                      obscode: "Loading",
                      obsvalue: treatChart?.Loading  ?? "",
                      shortdesc: "Loading",
                      time: currentEpochTime,
                    },
                    Maintenance: {
                      obscode: "Maintenance",
                      obsvalue: treatChart?.Maintenance  ?? "",
                      shortdesc: "Maintenance",
                      time: currentEpochTime,
                    },
                    StaffID: {
                      obscode: "StaffID",
                      obsvalue: treatChart?.StaffID  ?? "",
                      shortdesc: "Staff ID",
                      time: currentEpochTime,
                    },
                    id: {
                      id: treatChart?.id ? treatChart?.id : uuidv4(),//"b63ef49d-f11f-44f2-be8d-86c8022e4da3",
                    },
                  },
                ],
              },
            ],
          },
        },
      ];
      const resp = await dispatch(actions.UPSERT_DIALYSIS(payload));
      if(resp?.payload?.data?.error === false){
        props?.setTreatmentProcedureDialogOpen(false);
        loadDefaultValues();
        getReadDialysis();
        props?.RefreshLatestQueue();
        alertContext.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: "Dialysis Record Saved Successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      } else {
        alertContext.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Dialysis Record Save Failed",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      }
      console.log(payload, resp, "&&&");
    }
  };

  const getStripListByData = async key => {
    const payload = personRequests.getStripTestByList(getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"facilityid"}));
    const queryDataResp = await fetchData(payload, urls.readQdmQueries);
    setstriptestList(queryDataResp);
  };

  const getReadDialysis = async () => {
    const read_dialysis = await dispatch(
      actions.READ_DIALYSIS({
        encounterId: props?.patientData?.inputdoc?.encounterid,
      })
    );
    let treatChartData = read_dialysis?.payload?.data?.[0]?.component?.[0]?.treatmentChart?.[0];
    let preDialysisData = read_dialysis?.payload?.data?.[0]?.component?.[0]?.predialysis?.[0];
    let postDialysisData = read_dialysis?.payload?.data?.[0]?.component?.[0]?.postdialysis?.[0];
    let intraDialysisData = read_dialysis?.payload?.data?.[0]?.component?.[0]?.intradialysis;

    setdialysisId({
      orderlineid: read_dialysis?.payload?.data?.[0]?.orderlineid,
      encType: read_dialysis?.payload?.data?.[0]?.encountertype,
      _id: read_dialysis?.payload?.data?.[0]?._id,
    })
      if(read_dialysis?.payload?.data?.[0]?.component?.[0]?.treatmentChart?.length > 0){
        settreatChart({
          LastVisitDate: treatChartData?.LastVisitDate?.obsvalue,
          DialysisDate: treatChartData?.DialysisDate?.obsvalue,
          HDMachineNo: treatChartData?.HDMachineNo?.obsvalue,
          striptest: treatChartData?.striptest?.obsvalue,
          TargetDryWeight: treatChartData?.TargetDryWeight?.obsvalue,
          TargetUF: treatChartData?.TargetUF?.obsvalue,
          PreHDWeight: treatChartData?.PreHDWeight?.obsvalue,
          PrevPostHDWeight: treatChartData?.PrevPostHDWeight?.obsvalue,
          IDWG: treatChartData?.IDWG?.obsvalue,
          Shift: treatChartData?.Shift?.obsvalue,
          Frequency: treatChartData?.Frequency?.obsvalue,
          Duration: treatChartData?.Duration?.obsvalue,
          Qb: treatChartData?.Qb?.obsvalue,
          Qd: treatChartData?.Qd?.obsvalue,
          AVFneedlesize: treatChartData?.AVFneedlesize?.obsvalue,
          DialyzerType: treatChartData?.DialyzerType?.obsvalue,
          SurfaceArea: treatChartData?.SurfaceArea?.obsvalue,
          DialysateCa2: treatChartData?.DialysateCa2?.obsvalue,
          Noofreuse: treatChartData?.Noofreuse?.obsvalue,
          Type: treatChartData?.Type?.obsvalue,
          Loading: treatChartData?.Loading?.obsvalue,
          Maintenance: treatChartData?.Maintenance?.obsvalue,
          StaffID: treatChartData?.StaffID?.obsvalue,
          id:treatChartData?.id?.id
        });
      }
      if(read_dialysis?.payload?.data?.[0]?.component?.[0]?.predialysis?.length > 0){
        setPreDialysis({
          TimeArrived: preDialysisData?.TimeArrived?.obsvalue,
          TimeStart: preDialysisData?.TimeStart?.obsvalue,
          Hepatatis: preDialysisData?.Hepatatis?.obsvalue,
          HepatatisType:preDialysisData?.HepatatisType?.obsvalue,
          TVA:preDialysisData?.TVA?.obsvalue,
          CCA: preDialysisData?.CCA?.obsvalue,
          PCS:preDialysisData?.PCS?.obsvalue,
          TEMP: preDialysisData?.TEMP?.obsvalue,
          BP: preDialysisData?.BP?.obsvalue,
          PULSE: preDialysisData?.PULSE?.obsvalue,
          SS: preDialysisData?.SS?.obsvalue,
          StaffID: preDialysisData?.StaffID?.obsvalue,
          id:preDialysisData?.id?.id,
        })
      }
      if(read_dialysis?.payload?.data?.[0]?.component?.[0]?.postdialysis?.length > 0){
        setPostDialysis({
          BPSit: postDialysisData?.BPSit?.obsvalue,
          BPStnd: postDialysisData?.BPStnd?.obsvalue,
          EndedBySN: postDialysisData?.EndedBySN?.obsvalue,
          EndTime: postDialysisData?.EndTime?.obsvalue,
          Ktv: postDialysisData?.Ktv?.obsvalue,
          NN: postDialysisData?.NN?.obsvalue,
          PHDW: postDialysisData?.PHDW?.obsvalue,
          PULSESIT: postDialysisData?.PULSESIT?.obsvalue,
          PULSESTND: postDialysisData?.PULSESTND?.obsvalue,
          StaffID: postDialysisData?.StaffID?.obsvalue,
          TEMP: postDialysisData?.TEMP?.obsvalue,
          TWL: postDialysisData?.TWL?.obsvalue,
          id: postDialysisData?.id?.id,
        });
      }
      if(read_dialysis?.payload?.data?.[0]?.component?.[0]?.intradialysis?.length > 0){
        let baseline;
        let firstHour;
        let secondHour;
        let thirdHour;
        intraDialysisData?.map((v,i)=>{
          console.log(v,"&&&")
          let istrue = Object.keys(v).length === 0
          let data = istrue ? {} : {
            Time: v?.Time?.obsvalue, //? v?.Time?.obsvalue : defaultValues?.Complicationtime_firsthour ?new Date(defaultValues?.Complicationtime_firsthour * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) : "",
            BloodPressure:v?.BP?.obsvalue,
            Pulse: v?.PULSE?.obsvalue ? parseInt(v?.PULSE?.obsvalue) : null ,
            Heparin:v?.Heparin?.obsvalue, //? v?.Heparin?.obsvalue : defaultValues?.HeparinLoading?.value,
            Qb:v?.QB?.obsvalue ? parseInt(v?.QB?.obsvalue) : null,//defaultValues?.qb,
            AnterialPressure: v?.AnterialPressure?.obsvalue,
            VenousPressure: v?.VP?.obsvalue ? parseInt(v?.VP?.obsvalue) : null,
            Temperature:v?.TEMP?.obsvalue ? parseInt(v?.TEMP?.obsvalue) : null,
            Qd: v?.QD?.obsvalue? parseInt(v?.QD?.obsvalue) : null, //|| defaultValues?.qd,
            Conductivity: v?.CONDUCTIVITY?.obsvalue ? parseInt(v?.CONDUCTIVITY?.obsvalue) : null,//i==1 ? defaultValues?.Conductivity_firsthour : i==2 ? defaultValues?.Conductivity_secondhour : defaultValues?.Conductivity_thirdhour,
            AccumulatedUFV:v?.AUFV?.obsvalue ? parseInt(v?.AUFV?.obsvalue) : null,
            NursingNotes: v?.NN?.obsvalue, //|| defaultValues?.Nursingnotes,
            StaffID: v?.StaffID?.obsvalue,
            COMPLICATION: [{
                complication: v?.COMPLICATION?.[0]?.complication?.obsvalue ? v?.COMPLICATION?.[0]?.complication?.obsvalue : "",//i==1 ? defaultValues?.Complicationtext_firsthour : i==2 ? defaultValues?.Complicationtext_secondhour : defaultValues?.Complicationtext_thirdhour,
                action: v?.COMPLICATION?.[0]?.action?.obsvalue, //|| i==1 ? defaultValues?.Complicationaction_firsthour : i==2 ? defaultValues?.Complicationaction_secondhour : defaultValues?.Complicationaction_thirdhour,
                time: v?.COMPLICATION?.[0]?.time?.obsvalue ? v?.COMPLICATION?.[0]?.time?.obsvalue : "",//i==1 ? new Date(defaultValues?.Complicationtime_firsthour * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) : i==2 ? new Date(defaultValues?.Complicationtime_secondhour * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) :new Date(defaultValues?.Complicationaction_thirdhour * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }),
              }],
            TransmembranePressure: v?.TMP?.obsvalue ? parseInt(v?.TMP?.obsvalue) : null,
            id: v?.id?.id,
          }
          if(i===0){
            baseline=data
            // setIntraDialysis({
            //   baseline:data
            // })
          } else if(i===1){
            firstHour=data
            // setIntraDialysis({
            //   ...IntraDialysis,
            //   firstHour:data,
            // })
          } else if(i===2){
            secondHour=data
            // setIntraDialysis({
            //   ...IntraDialysis,
            //   secondHour:data,
            // })
          } else if(i===3){
            thirdHour=data
            // setIntraDialysis({
            //   ...IntraDialysis,
            //   thirdHour:data
            // })
          }
          console.log(defaultValues?.qb,v?.QB?.obsvalue,"defaultValues?.Complicationtext_firsthour")
        });
        setIntraDialysis({
          baseline:baseline,
          firstHour:firstHour,
          secondHour:secondHour,
          thirdHour:thirdHour,
        })
      }
    console.log(read_dialysis, "read_dialysis");
  };

  const printChart = async () => {
    let payload = {
      "reportid": "1bd7fc83-63a1-4a58-9757-05bcd12f2824",
      "inputparams": {
          "@patientid": props?.patientData?.inputdoc?.patientid,
          "@encounterid": props?.patientData?.inputdoc?.encounterid
      },
      "result": []
  };
  let JwtToken = localStorage.getItem("JwtToken");
  let header = { JwtToken: JwtToken };
  try{
    const queryDataResp = await axiosCall(payload, urls.jasperReport, header);
      if(queryDataResp?.downloadUrl){
        const response = await axios.get(queryDataResp?.downloadUrl, {
          method: "GET",
          responseType: "blob",
        });
        const pdfBlob = new Blob([response.data], {
          type: "application/pdf",
        });
        const pdfDataUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfDataUrl, "_blank");
      } 
    } catch (error){
      if(error?.response?.status == 400) {
        alertContext.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "No Documents !!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      } else {
        alertContext.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please Contact Admin",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      }
    }
  };

  React.useEffect(() => {
    getStripListByData();
    loadDefaultValues();
  }, []);
  React.useEffect(() => {
    getReadDialysis();
  },[defaultValues])
  console.log(treatChart, PreDialysis, PostDialysis,IntraDialysis, "(((");
  return (
    <>
      <Grid container justifyContent={"flex-end"} m={2} pr={6} mb={0}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<PrintIcon />}
          onClick={()=>printChart()}
        >
          PRINT
        </Button>
      </Grid>
      <Grid container style={props?.treatmentPermission == "view" ?{pointerEvents:"none"}:{}}>
      <Grid item xs={12} id="treatment"  ref={props?.treatmentChartRef} >
        <TreatmentChart
          defaultValues={defaultValues}
          settreatChart={settreatChart}
          setisMasterSave={setisMasterSave}
          treatChart={treatChart}
          striptestList={striptestList}
          printChart={printChart}
          setistreatChart={setistreatChart}
          treatmentPermission={props?.treatmentPermission}
        />
      </Grid>
      <Grid item xs={12} id="pre" ref={props?.preDialysisRef}>
        <Predialysis
          defaultValues={defaultValues}
          setPreDialysis={setPreDialysis}
          PreDialysis={PreDialysis}
          setisMasterSave={setisMasterSave}
          striptestList={striptestList}
          setisPreDialysis={setisPreDialysis}
          treatmentPermission={props?.treatmentPermission}
        />
      </Grid>
      <Grid item xs={12} id="intra" ref={props?.intraDialysisRef}>
        <Paper
          sx={{
            padding: "10px",
            margin: "20px",
            backgroundColor: theme.palette.primary.light,
          }}
        >
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 6 }}
            margin={1}
          >
            <Grid item xs={2.3} marginLeft={2}>
              <Typography variant="h6">INTRA DIALYSIS</Typography>
            </Grid>

            <Grid item xs={12}>
              {/* <Paper
                    sx={{
                      padding: "10px",
                      margin: "10px",
                      // backgroundColor: theme.palette.primary.light,
                    }}
                  > */}
              <IntraDialysisTable
                defaultValues={defaultValues}
                setisMasterSave={setisMasterSave}
                striptestList={striptestList}
                setIntraDialysis={setIntraDialysis}
                IntraDialysis={IntraDialysis}
                setisIntraDialysis={setisIntraDialysis}
                treatmentPermission={props?.treatmentPermission}
              />
              {/* </Paper> */}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} id="post"  ref={props?.postDialysisRef} mb={7}>
        <Postdialysis
          defaultValues={defaultValues}
          setisMasterSave={setisMasterSave}
          setPostDialysis={setPostDialysis}
          PostDialysis={PostDialysis}
          PrevHDWeight={treatChart?.PreHDWeight}
          striptestList={striptestList}
          setisPostDialysis={setisPostDialysis}
          treatmentPermission={props?.treatmentPermission}
        />
      </Grid>
      {props?.treatmentPermission == "edit" ? (
        <Grid container bgcolor={"#fff"} justifyContent={"flex-end"} style={{ position: "absolute",bottom:0 }} py={2} px={5} zIndex={999} borderTop={"1px solid #E0E0E0"}>
            <Button
              variant="outlined"
              disabled={!isMasterSave}
              onClick={SaveTreatProcedure}
            >
            {readAllergyLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
            {readAllergyLoading ? "Saving..." : "Save"}
            </Button>
          </Grid>
      ): (<></>)}
      </Grid>
      </>
  );
};
