import { Grid, Typography } from "@mui/material";
import React from "react";

const RiskFactor = ({riskHtml}) => {


  return (
    <>
      {riskHtml ? (
        <div
          dangerouslySetInnerHTML={{
            __html: `${riskHtml}`,
          }}
        />
      
      ) : (
    <Grid container justifyContent={"center"} my={1}>
        <Typography variant="caption" fontSize={"12px"}> No Data Available</Typography>
    </Grid>
      )}
    
    </>
  );
};

export default RiskFactor;
