import React, { useEffect, useState } from "react";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  TextField,
  Typography,
  Button,
  Switch,
  Grid,
  InputAdornment,
  Paper,
  IconButton,
  Collapse,
  Divider,
} from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@mui/styles";
import {
  getCurrentMinTime,
  getData,
  getUserInfo,
  calculateAgeInDays,
  AlertProps,
  formatDateFromEpoch,
  __tenantid__,
  getUtcTime,
  utcTOLocal,
} from "../../../utils";
import { UIColor } from "../../../themes/theme";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import actions from "../../../redux/actions";
import moment from "moment";
import { CalendarIcon, Recurrent } from "../../../components";
import { Checkbox, Popover } from "@mui/material";
import CustomDatePicker from "../../../components/customDatePicker";
import { setHours, setMinutes } from "date-fns";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoIcon from '@mui/icons-material/Info';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import ScreenSearchDesktopOutlinedIcon from '@mui/icons-material/ScreenSearchDesktopOutlined';
const useStyles = makeStyles({
  autocomplete: {
    "& .MuiInputBase-input": {
      color: UIColor.secondaryText.color,
      fontSize: "12px !important",
      fontFamily: "pc_regular!important",
    },
    "& .MuiInputBase-root": {
      //height: 40,
      color: UIColor.secondaryText.color,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
        borderRadius: 8,
      },
      "&:hover fieldset": {
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 1,
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
      },
    },
    "& .MuiAutocomplete-popupIndicator": {
      transform: "none !important",
    },
  },
  input: {
    fontFamily: "pc_regular",
    color: UIColor.secondaryText.color,
    fontSize: 14,
    "&::placeholder": {
      fontSize: 14,
      fontFamily: "pc_regular !important",
    },
  },
  muiPopovers: {
    boxShadow: "0px 4px 24px #00000029",
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 8,
    backgroundColor: "#fff",
    minWidth: 240,
    overflow: "visible",
  },
  switch: {
    "& .Mui-checked": {
      color: "#fff",
    },
    "& .Mui-checked + .MuiSwitch-track": {
      backgroundColor: UIColor.primaryColor.color,
      opacity: 1,
    },
  },
  closeButton: {
    position: "absolute",
    right: "8px",
    top: "8px",
    color: UIColor.primaryColor.color,
  },
});



const filter = createFilterOptions();
const AddObservationOrder = (props) => {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const [diagnosisEntry, setDiagnosisEntry] = React.useState({
    ...props?.Observation,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [openEvi, setOpenEvi] = useState(false);
  useEffect(() => {
    setOpenEvi(props?.collapseAllObsOrder)
  },[props?.collapseAllObsOrder])

  const setObject = (index, updatedObject) => {
    props?.setDataFromAi((prevData) => {
      const newData = [...prevData]; // Create a copy of the array

      if (index >= 0 && index < newData.length) {
        // Check for valid index
        newData[index] = updatedObject; // Update the object
      } else {
        console.log(`Index ${index} is out of bounds for dataFromAi`);
      }

      return newData;
    });
  };


  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleInputChange = async (e, name) => {
    
    setDiagnosisEntry({
      ...diagnosisEntry,
      [name]: e,
    });
  };
 

  useEffect(() => {
    setObject(props?.i, diagnosisEntry);
  }, [diagnosisEntry]);
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems={"center"}
      style={{ display: "flex", gap: "10px" }}
      xs={12}
      m={0.5}
    >
      <Grid item xs={12}>
        {/* <Paper elevation={0}> */}
          <Grid container xs={12} p={0.2} alignItems={"flex-start"}>
            <Grid item xs={11.5} container alignItems={"flex-start"} gap={1}>
              <Grid item>
                <Checkbox
                  checked={diagnosisEntry?.orderCheck}
                  size="small"
                  onChange={(event) => {
                    handleInputChange(event.target.checked, "orderCheck");
                  }}
                  defaultChecked
                />
              </Grid>
              <Grid item>
                {edit ? (
                  <Grid container gap={2} p={0.5} alignItems={"center"}>
                    {/* observation */}
                    <Grid item>
                    <Autocomplete
                        style={{ width: 170, fontSize: "14px" }}
                        // className={"search"}
                        className={classes.autocomplete}
                        id="size-small-standard"
                        size="small"
                        //options={allergiesData?.allery_master?.data ?? []}
                        options={props?.lookup[0]?.observationOrderDescription}
                        popupIcon={
                          <ExpandMoreIcon
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        handleHomeEndKeys
                        selectOnFocus
                        clearOnBlur
                        //getOptionLabel={(option) => option.label}
                        getOptionLabel={(option) => {
                          // Value selected with enter, right from the input
                          if (typeof option === "string") {
                            return option;
                          }
                          // Add "xxx" option created dynamically
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          // Regular option
                          return option.label;
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          // Suggest the creation of a new value
                          if (params.inputValue !== "") {
                            filtered.push({
                              label: `${params.inputValue}`,
                              value: `${params.inputValue}`,
                            });
                          }

                          return filtered;
                        }}
                        renderInput={(params) => (
                          <TextField
                           
                            {...params}
                            variant="standard"
                            placeholder="Search Observation"
                           // inputProps={{
                            //   ...params.inputProps,
                            //   style: { fontSize: "14px" },
                            // }}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                           
                            style={{ width: "100%" }}
                            noWrap
                          >
                            {option.label}
                          </Typography>
                        )}
                        classes={{ popper: "muiPopperDropdown" }}
                        value={diagnosisEntry?.orderCatalog}
                        // onChange={(e, newValue) =>
                        //   handleInputChange(newValue, "name")
                        // }
                        freeSolo
                        // onInputChange={(e) => handleDiagnosis(e?.target?.value ?? "")}
                        onChange={(event, newValue) => {
                          if (typeof newValue === "string") {
                            setDiagnosisEntry({
                              ...diagnosisEntry,
                              orderCatelog: {
                                label: newValue,
                              },
                            });
                          } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setDiagnosisEntry({
                              ...diagnosisEntry,
                              orderCatalog: {
                                label: newValue,
                              },
                            });
                          } else {
                            setDiagnosisEntry({
                              ...diagnosisEntry,
                              orderCatalog: newValue,
                            });
                          }
                        }}
                      />
                    </Grid>
                    {/* frequency */}
                    <Grid item>
                    <Autocomplete
                        style={{ width: 100 }}
                    size="small"
                    // options={storeData?.Use_masters?.data ?? []}
                    options={props?.lookup[0]?.observationFrequency}
                    popupIcon={
                      <ExpandMoreIcon
                        fontSize="small"
                        style={{ color: "#b4aeae" }}
                      />
                    }
                    disableClearable
                    getOptionLabel={(option) => option?.label}
                    renderInput={(params) => (
                      <TextField
                        
                        {...params}
                        variant="standard"
                        placeholder="Frequency"
                      />
                    )}
                    renderOption={(option) => (
                      <Typography
                        
                        style={{ width: "100%" }}
                        noWrap
                      >
                        {option?.label}
                      </Typography>
                    )}
                    classes={{popper: "muiPopperDropdown"}}
                    value={diagnosisEntry?.frequency}
                    onChange={(e, newValue) =>
                      handleInputChange(newValue, "frequency")
                    }
                  />
                    </Grid>
                    {/* duration */}
                    <Grid item>
                    <Autocomplete
                    style={{ width: 120 }}
                    
                    size="small"
                    // options={
                    //   storeData?.Verification_masters_dianosis?.data ?? []
                    // }
                    options={props?.lookup[0]?.observationDuration ?? []}
                    popupIcon={
                      <ExpandMoreIcon
                        fontSize="small"
                        style={{ color: "#b4aeae" }}
                      />
                    }
                    disableClearable
                    getOptionLabel={(option) => option?.label}
                    renderInput={(params) => (
                      <TextField
                       
                        {...params}
                        variant="standard"
                        placeholder="Duration"
                      />
                    )}
                    renderOption={(option) => (
                      <Typography
                        
                        style={{ width: "100%" }}
                      >
                        {option?.label}
                      </Typography>
                    )}
                    classes={{popper: "muiPopperDropdown"}}
                    value={diagnosisEntry?.duration}
                    onChange={(e, newValue) =>
                      handleInputChange(newValue, "duration")
                    }
                  />
                    </Grid>
                    {/* by */}
                    <Grid item>
                    <Autocomplete
                    style={{ width: 120 }}
                    
                    size="small"
                    // options={
                    //   storeData?.Verification_masters_dianosis?.data ?? []
                    // }
                    options={props?.lookup[0]?.by}
                    popupIcon={
                      <ExpandMoreIcon
                        fontSize="small"
                        style={{ color: "#b4aeae" }}
                      />
                    }
                    disableClearable
                    getOptionLabel={(option) => option?.label}
                    renderInput={(params) => (
                      <TextField
                       
                        {...params}
                        variant="standard"
                        placeholder="by"
                      />
                    )}
                    renderOption={(option) => (
                      <Typography
                        
                        style={{ width: "100%" }}
                      >
                        {option?.label}
                      </Typography>
                    )}
                    classes={{popper: "muiPopperDropdown"}}
                    value={diagnosisEntry?.by}
                    onChange={(e, newValue) =>
                      handleInputChange(newValue, "by")
                    }
                  />
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Grid item p={0.5}>
                      <Typography
                        variant="body1"
                        color={UIColor.secondaryButtonColor.color}
                        fontSize={"16px"}
                      >
                        {diagnosisEntry?.orderCatalog?.label}{" "}
                        {/* {
                          openEvi ?  
                            <IconButton size="small" onClick={() => setOpenEvi(!openEvi)}>
                              <InfoOutlinedIcon fontSize="8px" color="primary" />
                            </IconButton>
                              :

                            <IconButton size="small" onClick={() => setOpenEvi(!openEvi)}>
                              <InfoIcon fontSize="8px" color="primary" />
                            </IconButton>
                          } */}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" fontSize={"12px"}>
                        {" ▪️ "}
                        {diagnosisEntry?.frequency?.label}
                        {" ▪️ "}
                        {diagnosisEntry?.duration?.label}
                        {" ▪️ "}
                        {diagnosisEntry?.by?.label}
                      
                      </Typography>
                    </Grid>
                    <Collapse in={openEvi}>
                      {diagnosisEntry?.evidence ?
                        <>
                          <Grid item container alignItems={"center"} gap={0.5} my={0.5}>
                            <SummarizeOutlinedIcon fontSize={"small"} color={"primary"} />
                            <Typography variant="button" fontSize={"12px"} color={"primary"}>Evidence</Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="body2" fontSize={"12px"}>
                              { diagnosisEntry.evidence }
                            </Typography>
                              </Grid>
                        </> : null}
                      {diagnosisEntry?.reference ?
                        <>
                          <Grid item  container alignItems={"center"} gap={0.5} my={0.5}>
                            <ScreenSearchDesktopOutlinedIcon fontSize={"small"} color={"primary"} />
                            <Typography variant="button" fontSize={"12px"} color={"primary"}>Reference</Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="body2" fontSize={"12px"}>
                              {diagnosisEntry?.reference ?  diagnosisEntry.reference : null}
                            </Typography>
                          </Grid>
                        </>
                        : null}
                    </Collapse>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item xs={0.5}>
              {edit ? (
                <IconButton onClick={() => setEdit(false)}>
                  <CheckOutlinedIcon color="success" />
                </IconButton>
              ) : (
                 // <IconButton onClick={() => setEdit(true)}>
                 <IconButton>
                  <EditOutlinedIcon color="primary" />
                </IconButton>
              )}
          </Grid>
          <Grid item xs={12} style={{ padding: 0, marginTop: "10px" }}>
              <Divider></Divider>
            </Grid>
          </Grid>
        {/* </Paper> */}
      </Grid>
    </Grid>
  );
};

export default AddObservationOrder;
