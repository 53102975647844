
import React from 'react'
import {
    Link,
    Div,
    Button,
    SideMenu,
    TextInput,
    Text,
    Divider,
    TapSelect,
    MobileNumberWithCode,
    TermsAndConditions,
    OtpInput,
    H6
  } from "qdm-component-library";
import { UIColor } from '../../themes/theme';
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
    AlertProps,
    checkError,
    checkName,
    fetchUserInfo,
    findAge,
    getImgUrl,
    getMobileNumber,
    getUserInfo,
    mobileNumberValidation,
    upsertPerson,
    ValidateEmail,
  } from "../../utils";
import clsx from "clsx";
// import { AlertContext } from "../../";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    padding: 16,
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  body: {
    paddingTop: 0,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
  },
  label: {
    "& span": {
      fontSize: 12,
    },
    marginBottom: "16px",
  },
  guestTitle: {
    fontSize: 20,
    fontFamily: "pc_semibold",
    letterSpacing: 0,
    lineHeight: 1,
  },
  termsAndConditions: {
    "& .termsConditionsCheckbox": {
      borderRadius: 2,
    },
    "& .termsConditionsLabel": {
      flex: 1,
    },
  },
  monthCalendar: {
    "& strong": {
      fontSize: 13,
    },
    "& span": {
      fontSize: 13,
    },
    backgroundColor: "#fff",
  },
  clear: {
    backgroundColor: "transparent",
    border: "none",
    padding: "9px 12px",
    cursor: "pointer",
    color: theme.palette.text.secondary,
    fontSize: 14,
  },
  apply: {
    backgroundColor: "transparent",
    border: "none",
    padding: "9px 12px",
    cursor: "pointer",
    color: theme.palette.primary.main,
    fontSize: 14,
  },
  divider: {
    minWidth: "0px",
    marginRight: "10px",
    marginLeft: "10px",
    color: theme.palette.text.hint,
    marginBottom: 0,
    marginTop: 0,
  },
  earliestInput: {
    fontFamily: "pc_regular",
    fontSize: 14,
    "&::placeholder": {
      fontFamily: "pc_regular",
      fontSize: 14,
      color: theme.palette.text.hint,
    },
  },
  textField: {
    "& input": {
      color: theme.palette.text.primary,
      fontSize: "14px",
      //border: `1px solid ${theme.palette.divider}`,
      fontFamily: "pc_medium",
      borderRadius: 8,
    },
    "& input::placeholder": {
      color: theme.palette.text.hint,
    },
  },
  tapSelect: {
    "& .selectionDiv": {
      height: 40,
      borderRadius: 8,
      minWidth: 40,
      margin: "8px 8px 0px 0px",
      borderColor: theme.palette.divider,
      "&:last-of-type": {
        marginRight: 0,
      },
      "& span": {
        fontFamily: "pc_regular",
        fontSize: 14,
      },
    },
    "& .active": {
      "& span": {
        fontFamily: "pc_medium !important",
      },
    },
  },
  mobile: {
    marginBottom: 16,
    "& .callingCodes,input": {
      fontSize: 14,
      color: theme.palette.text.primary,
      fontFamily: "pc_medium",
      letterSpacing: "-0.34px",
      fontWeight: "normal",
    },
    "& input::placeholder": {
      fontFamily: "pc_regular",
    },
    // '& #addmember_form_mobilenumberwithcode':{
    //   borderColor: theme.palette.divider
    // },
    "& .mobileCountryDivider": {
      borderColor: theme.palette.divider,
    },
  },
}));

const AppointmentBookingSideMenu = ({ location, ...props }) => {
    const  classes  = useStyles();
    const [state, setState] = React.useState({
        sidebar: false,
        showOtpVerification: false,
        added: false,
        appointmentUpdate: false,
        guestInfo: {},
        guestBookerInfo: {},
    });
    const genderOptions = [
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },
        { value: "Others", label: "Others" },
      ];
    
      const relationshipOptions = [
        { value: "Father", label: "Father" },
        { value: "Mother", label: "Mother" },
        { value: "Brother", label: "Brother" },
        { value: "Sister", label: "Sister" },
        { value: "Others", label: "Others" },
      ];
     const goBack = async() => {
        let mobileNumber = getMobileNumber(state?.guestInfo?.mobileNo);
        if (props?.guestInfo?.data?.type === 1) {
          mobileNumber = getMobileNumber(state?.guestBookerInfo?.mobileNo);
        }
        await props.DELETE_USER({
          username: mobileNumber,
        });
        if (props?.guestInfo?.data?.type === 0) {
          setState({
            ...state,
            sidebar: true,
            showOtpVerification: false,
            collpaseOpen: 0,
          });
        } else {
          setState({
            ...state,
            showOtpVerification: false,
            collpaseOpen: 1,
          });
        }
    }
   const  handleresendOTP = async () => {
        let mobileNumber = getMobileNumber(state?.guestInfo?.mobileNo);
        if (props?.guestInfo?.data?.type === 1) {
          mobileNumber = getMobileNumber(state?.guestBookerInfo?.mobileNo);
        }
        if (state.appointmentUpdate) {
          mobileNumber = state?.otpNumber;
        }
        if (mobileNumber) {
        //   changeState("otp", "");
    
          const meageget = await props.RESEND_OTP({
            mobileNo: mobileNumber,
          });
        //   changeState("meageget", meageget);
        } else {
        //   context.setSnack({
        //     open: true,
        //     severity: AlertProps.severity.error,
        //     msg: "Could not send otp. Please Try Again",
        //     vertical: AlertProps.vertical.top,
        //     horizontal: AlertProps.horizontal.right,
        //     tone: true,
        //   });
        }
    };
    const handleOpen = async (index) => {
        if (index === 1) {
          // await props.REGISTER_USERS();
          // const { data } = props?.docterListingBookingFor
        }
        setState({
          ...state,
          collpaseOpen: index === state.collpaseOpen ? null : index,
        });
    };
    const changeGuestInfo = (type, field, val) => {
        const state = state;
        state[type][field] = val;
        setState(state);
    };
    const handleMySelfSignUp=()=> {
        // //debugger
        state.guestBookerInfo.mobileNo = state.guestInfo?.mobileNo;
        state.guestBookerInfo.name = state.guestInfo?.name;
        state.guestBookerInfo.mailId = state.guestInfo?.mailId;
        state.guestBookerInfo.dob = state.guestInfo?.age.val;
        state.guestBookerInfo.isEmail = state.guestInfo?.isEmail;
        state.guestBookerInfo.isMobile = state.guestInfo?.isMobile;
        state.guestBookerInfo.gender = state.guestInfo?.gender.value;
        // const check = checkValidationsForMyself(state.guestBookerInfo);
        //debugger;
        // if (check) {
        //   setState({ ...state, error: {}, meageget: {} }, () => {
        //     showVerification(null, true);
        //   });
        // }
      }

  return (
    <SideMenu
    id="doctor_listing_sidemenu"
    open={state.sidebar}
    direction="right"
    width={435}
    color="default"
    // onClose={() => {
    //   if (
    //     !state.added &&
    //     state.showOtpVerification &&
    //     !props?.location?.state?.modifyUser
    //   ) {
    //     let mobileNumber = getMobileNumber(
    //       state?.guestInfo?.mobileNo
    //     );
    //     if (props?.guestInfo?.data?.type === 1) {
    //       mobileNumber = getMobileNumber(
    //         state?.guestBookerInfo?.mobileNo
    //       );
    //     }
    //     props.DELETE_USER({
    //       username: mobileNumber,
    //     });
    //   }
    //   toggleSideBar(false);
    // }}
    inLineStyles={{ padding: "16px 24px" }}
    // open={true}
  >
    <text
      id="doctor_listing_sidemenu_upadte"
      className={classes.guestTitle}
    >
      {state.appointmentUpdate
        ? "Update Appointment"
        : "Guest user confirmation"}
    </text>

    <Divider
      id="doctor_listing_sidemenu_divider"
      key={"2"}
      className=""
      label=""
      borderColor=""
      textColor=""
      orientation="horizontal"
      inLineStyles={{ margin: "16px 0px" }}
      // variant="middle"
      // style={{ height: "24px" }}
    />

    {state.showOtpVerification ? (
      <Div id="doctor_listing_sidemenu_appointmentupadte_div">
        {props?.guestInfo?.data?.type === 0 ? null : (
          <Div
            style={{
              border: `1px solid ${UIColor.lineBorderFill.color}`,
              marginBottom: 10,
              borderRadius: 8,
              padding: 16,
              display: state.appointmentUpdate
                ? "none"
                : "block",
            }}
          >
            <H6
              id="doctor_listing_sidemenu_booking_label_h6"
              inLineStyles={{
                fontSize: 16,
                color: UIColor.primaryText.color,
                letterSpacing: 0,
                lineHeight: 1,
              }}
              className="pc_medium"
            >
              BOOKING FOR
              <Text
                id="doctor_listing_sidemenu_booking_text"
                inLineStyles={{
                  color: UIColor.secondaryColor.color,
                  fontSize: 16,
                  letterSpacing: 0,
                }}
                className="pc_medium"
              >
                {" | "}
                {state.guestInfo?.name}
              </Text>
            </H6>
          </Div>
        )}

        <Div
          id="doctor_listing_sidemenu_appointment_div"
          style={{
            border: `1px solid ${UIColor.lineBorderFill.color}`,
            display: state.appointmentUpdate
              ? "none"
              : "block",
            marginBottom: 20,
            borderRadius: 8,
            padding: 16,
          }}
        >
          <H6
            id="doctor_listing_sidemenu_booking_by_h6"
            inLineStyles={{
              fontSize: 16,
              color: UIColor.primaryText.color,
              letterSpacing: 0,
              lineHeight: 1,
            }}
            className="pc_medium"
          >
            BOOKING BY
            <Text
              id="doctor_listing_sidemenu_booking_by_text"
              inLineStyles={{
                color: UIColor.secondaryColor.color,
                fontSize: 16,
                letterSpacing: 0,
              }}
              className="pc_medium"
            >
              {" | "}
              {state.guestBookerInfo?.name}
            </Text>
          </H6>
        </Div>
        <Div
          id="doctor_listing_sidemenu_otp_div"
          inLineStyles={{
            backgroundColor:
              UIColor.differentiationBackground.color,
            padding: 30,
            borderRadius: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: 0,
          }}
        >
          <H6
            id="doctor_listing_sidemenu_otp_verification_h6"
            inLineStyles={{
              marginBottom: 24,
              fontSize: 16,
              letterSpacing: 0,
              color: UIColor.primaryText.color,
            }}
            className="pc_medium"
          >
            OTP Verification
          </H6>
          <Text
            id="doctor_listing_sidemenu_otp_sent_text"
            className="pc_regular"
            inLineStyles={{
              fontSize: 14,
              textAlign: "center",
              letterSpacing: 0,
              color: UIColor.tertiaryText.color,
            }}
          >
            Please enter the OTP sent to{" "}
            <Text
              id="doctor_listing_sidemenu_otp_number_text"
              inLineStyles={{
                color: UIColor.secondaryText.color,
                letterSpacing: 0,
                fontSize: 14,
              }}
              className="pc_medium"
            >
              {state.appointmentUpdate
                ? state?.otpNumber
                : state.guestBookerInfo?.mobileNo?.mobileNo}
            </Text>
            <Link
              id="doctor_listing_sidemenu_change_link"
              color="textPrimary"
              underLine="none"
              inLineStyles={{
                color: UIColor.primaryColor.text,
                letterSpacing: 0,
                display: state.appointmentUpdate
                  ? "none"
                  : "unset",
                textDecoration: "none",
                fontSize: 14,
              }}
              className="pc_medium"
              onClick={() => {
                setState(
                  {
                    meageget: {},
                  },
                  () => {
                    goBack();
                  }
                );
              }}
              name={"Change"}
            />
          </Text>
          <Div
            id="doctor_listing_sidemenu_change_div"
            inLineStyles={{ marginBottom: 40, marginTop: 30 }}
          >
            <OtpInput
              id="doctor_listing_sidemenu_otpinput"
              numberOfInputs={6}
              variant="standard"
              type="text"
              focusTextColor={UIColor.secondaryColor.color}
              focusBorderColor={UIColor.secondaryColor.color}
              inLineStyles={{ backgroundColor: "transparent" }}
              onChange={(e) => {
                setState({
                  ...state,
                  otpError: false,
                });
                // changeState("otp", e);
              }}
              value={state.otp}
              // style={ { display : "flex",justifyContent : "center",alignItems : "center",paddingLeft : "" } }
              className={state?.otpError && "otpError"}
            />
          </Div>
          <Text
            id="doctor_listing_sidemenu_didnt_receive_text"
            inLineStyles={{
              color: UIColor.tertiaryText.color,
              fontSize: 12,
              letterSpacing: 0,
            }}
            className="pc_regular"
          >
            Didn't receive OTP ?{" "}
            <Link
              id="doctor_listing_sidemenu_didnt_receive_link"
              color="textPrimary"
              underLine="none"
              inLineStyles={{
                color: UIColor.primaryText.color,
                letterSpacing: 0,
                fontSize: 12,
              }}
              onClick={() => handleresendOTP()}
              className="pc_medium"
              name={"Resend OTP"}
            />
          </Text>
          {state.meageget?.payload?.data?.message ? (
            <text
              id="doctor_listing_sidemenu_message_text"
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "12px",
                marginBottom: "6px",
                marginTop: "6px",
                color: UIColor.success.color,
                textAlign: "center",
              }}
            >
              {state.meageget?.payload?.data?.message}
            </text>
          ) : null}

          <Div
            id="doctor_listing_sidemenu_message_div"
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 24,
            }}
          ></Div>
        </Div>
        <Div
          id="doctor_listing_sidemenu_changescreen_div"
          style={{ textAlign: "center", marginTop: "24px" }}
        >
          <Button
            id="doctor_listing_sidemenu_confirm_button"
            // key={"7"}
            // onClick={() => changeScreen()}
            type="button"
            variant="outlined"
            name={
              props.upsertAppointment.loading ||
              props.updateAppointment.loading ||
              props.readPerson.loading ||
              props.upsertPerson.loading ||
              props.addRelatedPerson.loading ||
              props.signIn.loading ||
              props?.checkAppointmentAgainstPatient?.loading
                ? "Updating..."
                : "Confirm"
            }
            style={{
              backgroundColor: UIColor.primaryColor.color,
              borderColor: UIColor.primaryColor.color,
              borderRadius: "8px",
              padding: "10px 15px",
              fontSize: "14px",
              height: "40px",
            }}
            disabled={
              props.upsertAppointment.loading ||
              props.updateAppointment.loading ||
              props.readPerson.loading ||
              props.upsertPerson.loading ||
              props.addRelatedPerson.loading ||
              props.signIn.loading ||
              props.checkAppointmentAgainstPatient?.loading
            }
            className="pc_medium"
          ></Button>
        </Div>
      </Div>
    ) : (
      <Div id="doctor_listing_sidemenu_booking_div">
        <Card
          id="doctor_listing_sidemenu_booking_card"
          style={{ marginBottom: "8px", borderRadius: 8 }}
        >
          <CardActions
            onClick={() => 
                state.collpaseOpen === 0 ? handleOpen(0):handleOpen(1)}
            disableSpacing
            style={{
              padding: 0,
              paddingLeft: 16,
              paddingBottom: 0,
              cursor: "pointer",
            }}
          >
            <Text
              id="doctor_listing_sidemenu_booking_text"
              key={"0"}
              name={
                props?.guestInfo?.data?.type === 0
                  ? "BOOKING BY"
                  : "BOOKING FOR"
              }
              style={{
                fontSize: "16px",
                letterSpacing: 0,
                lineHeight: 1,
                color: UIColor.primaryText.color,
              }}
              className="pc_medium"
            />

            <IconButton
              id="doctor_listing_sidemenu_collapse_iconbutton"
              className={clsx(classes.expand, {
                [classes.expandOpen]:
                  state.collpaseOpen === 0 ? true : false,
              })}
              aria-expanded={
                state.collpaseOpen === 0 ? true : false
              }
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          <Collapse
            id="doctor_listing_sidemenu_collapse"
            className={classes.body}
            in={state.collpaseOpen === 0 ? true : false}
            // open={state.collpaseOpen === 0 ? true : false}
            timeout="auto"
            unmountOnExit
          >
            <Div
              id="doctor_listing_sidemenu_patient_name_div"
              className={classes.label}
            >
              <TextInput
                id="doctor_listing_sidemenu_patient_name_label_text"
                // key={"0"}
                placeholder=""
                type="text"
                name="text"
                variant="outlined"
                helperTextType="error"
                label={
                  <H6
                    id="doctor_listing_sidemenu_patient_name_label_span"
                    className="pc_regular"
                    style={{
                      fontSize: "12px",
                      color: UIColor.secondaryText.color,
                      margin: 0,
                      marginBottom: "8px",
                      letterSpacing: 0,
                      lineHeight: 1,
                    }}
                  >
                    Patient name{" "}
                    <span style={{ color: UIColor.error.color }}>
                      *
                    </span>
                  </H6>
                }
                autoFocus={false}
                autoComplete="off"
                search={false}
                borderColor={UIColor.lineBorderFill.color}
                padding="10"
                margin="0"
                elevation="0"
                maxLength={30}
                value={state.guestInfo?.name}
                onChange={(e) => {
                  const key =
                    props?.guestInfo?.data?.type === 0
                      ? "guestBookerInfo"
                      : "guestInfo";
                  changeGuestInfo(
                    "guestInfo",
                    "name",
                    e.target.value
                  );
                  setState({
                    ...state,
                    error: {
                      [key]: {
                        name: !checkName(e.target.value),
                      },
                    },
                  });
                }}
                className={classes.textField}
                error={
                  (props?.guestInfo?.data?.type === 0
                    ? state.error?.guestBookerInfo?.name
                    : state?.error?.guestInfo?.name) && true
                }
              />
              {(props?.guestInfo?.data?.type === 0
                ? state.error?.guestBookerInfo?.name
                : state?.error?.guestInfo?.name) && (
                <div id="doctor_listing_sidemenu_please_enter_div">
                  <Text
                    id="doctor_listing_sidemenu_please_enter_label_text"
                    style={{
                      color: UIColor.error.color,
                      fontSize: 14,
                    }}
                    className="pc_regular"
                  >
                    {"Please Enter a Valid Name"}
                  </Text>
                </div>
              )}
            </Div>
            <div style={{ marginBottom: "16px" }}>
              {/* <DobAge
                parentId="guestsignin_myself_select_dob_textinput"
                showAge
                dateLabel="DOB"
                ageValue={calculateAge(state?.age?.val)}
                isRequired
                dateValue={state.age?.val}
                onChange={(value) => {
                  if (value) {
                    const milli = moment(
                      value,
                      "YYYY-MM-DD"
                    ).valueOf();
                    const date = {
                      val: moment(value, "YYYY-MM-DD").format(
                        "DD-MM-YYYY"
                      ),
                      milli,
                    };
                    changeGuestInfo(
                      "guestInfo",
                      "dob",
                      date.val
                    );
                    changeGuestInfo("guestInfo", "age", date);
                    // changeState("age", date);
                  } else {
                    changeGuestInfo("guestInfo", "dob", "");
                    changeGuestInfo("guestInfo", "age", {
                      val: "",
                      milli: null,
                    });
                    // changeState("age", {
                    //   val: "",
                    //   milli: null,
                    // });
                  }
                }}
                maxDate={new Date()}
                // minDate={moment(new Date())
                //   .subtract(200, "years")
                //   .format("YYYY-MM-DD")}
                placeholder="mm/dd/yyyy"
                minDate={
                  moment(new Date()).subtract(200, "years")._d
                }
                error={
                  ((props?.guestInfo?.data?.type === 0
                    ? state.error?.guestBookerInfo?.dob
                    : state?.error?.guestInfo?.dob) ||
                    checkAge(state?.age?.val)) &&
                  true
                }
                top={39}
                right={10}
              /> */}
            </div>
            {(props?.guestInfo?.data?.type === 0
              ? state.error?.guestBookerInfo?.dob
              : state?.error?.guestInfo?.dob) && (
              <div id="doctor_listing_sidemenu_please_select_dob_div">
                <Text
                  id="doctor_listing_sidemenu_please_select_dob_text"
                  style={{
                    color: UIColor.error.color,
                    fontSize: 14,
                  }}
                >
                  {"Please Select DOB"}
                </Text>
              </div>
            )}
            <Div
              id="doctor_listing_sidemenu_gender_div"
              className={classes.label}
            >
              <TapSelect
                id="doctor_listing_sidemenu_gender_tapselect"
                // key={"2"}
                type="single"
                fullWidth={false}
                selectedBorderColor={
                  UIColor.secondaryButtonColor.color
                }
                selectedBackgroundColor={
                  UIColor.secondaryButtonColor.color
                }
                selectedTextColor="#fff"
                borderColor={
                  UIColor.differentiationBackground.color
                }
                backgroundColor={
                  UIColor.differentiationBackground.color
                }
                textColor={UIColor.secondaryText.color}
                textSize="14"
                iconHeight="20"
                iconWidth="20"
                label={
                  <H6
                    id="doctor_listing_sidemenu_gender_label_span"
                    style={{
                      fontSize: "12px",
                      color: UIColor.secondaryText.color,
                      margin: 0,
                      letterSpacing: 0,
                      lineHeight: 1,
                    }}
                    className="pc_regular"
                  >
                    Gender{" "}
                    <span style={{ color: UIColor.error.color }}>
                      *
                    </span>
                  </H6>
                }
                options={genderOptions}
                defaultSelected={state.guestInfo?.gender}
                onChange={(e) =>
                  changeGuestInfo(
                    "guestInfo",
                    "gender",
                    state?.guestInfo?.gender?.value ===
                      e?.value
                      ? {}
                      : e
                  )
                }
                // inLineStyles={{
                //   margin: 0,
                //   marginBottom: 6,
                //   padding: "4px 12px !important",
                //   marginRight: 6,
                // }}
                inLineStyles={
                  {
                    // borderColor: UIColor.secondaryButtonColor.color,
                  }
                }
                className={classes.tapSelect}
              />
              {(props?.guestInfo?.data?.type === 0
                ? state.error?.guestBookerInfo?.gender
                : state?.error?.guestInfo?.gender) && (
                <div id="doctor_listing_sidemenu_select_gender_div">
                  <Text
                    id="doctor_listing_sidemenu_select_gender_text"
                    style={{
                      color: UIColor.error.color,
                      fontSize: 14,
                    }}
                  >
                    {"Please Select Gender"}
                  </Text>
                </div>
              )}
            </Div>
            <H6
              id="doctor_listing_sidemenu_number_span"
              style={{
                fontSize: "12px",
                color: UIColor.secondaryText.color,
                margin: 0,
                letterSpacing: 0,
                lineHeight: 1,
                marginBottom: 8,
              }}
              className="pc_regular"
            >
              Mobile number
              <span
                id="doctor_listing_sidemenu_number_span"
                style={{ color: UIColor.error.color }}
              >
                *
              </span>
            </H6>
            <div className={classes.mobile}>
              <MobileNumberWithCode
                id="doctor_listing_sidemenu_number_mobilenumberwithcode"
                // key={"3"}
                type="number"
                label="Phone number"
                borderColor={UIColor.lineBorderFill.color}
                borderRadius="6"
                disabled={false}
                maxLength={30}
                value={state.guestInfo?.mobileNo?.mobileNo}
                dropdown={
                  state.guestInfo?.mobileNo?.countryData
                }
                dropdownData={props.countryList?.data}
                onChange={(e) => {
                  setState(
                    {
                      ...state,
                      error: {
                        ...state.error,
                        guestInfo: {
                          ...state.error?.guestInfo,
                          callingCodes: false,
                          mobileNo: false,
                        },
                      },
                    },
                    () => {
                      changeGuestInfo(
                        "guestInfo",
                        "mobileNo",
                        e
                      );
                    }
                  );
                }}
                className={
                  (state.error?.guestInfo?.callingCodes ||
                    state.error?.guestInfo?.mobileNo) &&
                  "errorBorder"
                }
                // style={ { marginTop : "24px",paddingTop : "" } }
                // inLineStyles={{
                //   marginBottom:
                //     state.error?.guestBookerInfo?.callingCodes ||
                //       state.error?.guestBookerInfo?.mobileNo
                //       ? 0
                //       : 16,
                // }}
              />
              {props?.guestInfo?.data?.type === 0 &&
                (state.error?.guestBookerInfo?.mobileNo ||
                  state.error?.guestBookerInfo
                    ?.callingCodes) && (
                  <Div
                    id="doctor_listing_sidemenu_number_please_select_country_divs"
                    style={{ marginBottom: 10 }}
                  >
                    {state.error?.guestBookerInfo
                      ?.callingCodes && (
                      <Text
                        id="doctor_listing_sidemenu_number_please_select_country_text"
                        style={{
                          color: UIColor.error.color,
                          fontSize: 14,
                        }}
                        className="pc_regular"
                      >
                        {"Please select the country code"}
                      </Text>
                    )}
                    {!state.error?.guestBookerInfo
                      ?.callingCodes &&
                      state.error?.guestBookerInfo
                        ?.mobileNo && (
                        <Text
                          id="doctor_listing_sidemenu_please_enter_mobile_text"
                          style={{
                            color: UIColor.error.color,
                            fontSize: 14,
                          }}
                          className="pc_regular"
                        >
                          {"Please enter valid mobile number"}
                        </Text>
                      )}
                  </Div>
                )}

              {props?.guestInfo?.data?.type === 1 &&
                (state.error?.guestInfo?.mobileNo ||
                  state.error?.guestInfo?.callingCodes) && (
                  <Div
                    id="doctor_listing_sidemenu_please_country_code_div"
                    style={{ marginBottom: 10 }}
                  >
                    {state.error?.guestInfo?.callingCodes && (
                      <Text
                        id="doctor_listing_sidemenu_please_country_code_text"
                        style={{
                          color: UIColor.error.color,
                          fontSize: 14,
                        }}
                      >
                        {"Please select the country code"}
                      </Text>
                    )}
                    {!state.error?.guestInfo?.callingCodes &&
                      state.error?.guestInfo?.mobileNo && (
                        <Text
                          id="doctor_listing_sidemenu_please_mobile_number"
                          style={{
                            color: UIColor.error.color,
                            fontSize: 14,
                          }}
                        >
                          {"Please enter the mobile number"}
                        </Text>
                      )}
                  </Div>
                )}
            </div>

            <TermsAndConditions
              id="doctor_listing_sidemenu_send_termsand_conditions"
              value={state.guestInfo?.isMobile}
              checkboxSize={14}
              // key={"4"}
              checkboxTickColor={"#fff"}
              activeCheckboxBorderColor={
                UIColor.secondaryButtonColor.color
              }
              checkboxBorderColor={UIColor.lineBorderFill.color}
              checkboxBackgroundColor={
                UIColor.differentiationBackground.color
              }
              activeCheckboxBackgroundColor={
                UIColor.secondaryButtonColor.color
              }
              onChange={(value) =>
                changeGuestInfo("guestInfo", "isMobile", value)
              }
              lable={
                <H6
                  id={`sdoctor_listing_sidemenu_send_termsand_conditions`}
                  style={{
                    fontSize: "12px",
                    color: UIColor.secondaryText.color,
                    margin: 0,
                    letterSpacing: 0,
                  }}
                  className={"pc_regular"}
                >
                  Send appointment update and notification to this
                  mobile number
                </H6>
              }
              inLineStyles={{ lineHeight: 1, marginBottom: 16 }}
              className={classes.termsAndConditions}
            />
            <Div
              id="doctor_listing_sidemenu_mail_id_div"
              className={classes.label}
            >
              <TextInput
                id="doctor_listing_sidemenu_mail_id_text"
                // key={"5"}
                placeholder=""
                type="text"
                name="text"
                variant="outlined"
                helperTextType="error"
                label={
                  <H6
                    id="doctor_listing_sidemenu_mail_id_span"
                    style={{
                      fontSize: "12px",
                      color: UIColor.secondaryText.color,
                      margin: 0,
                      letterSpacing: 0,
                      lineHeight: 1,
                      marginBottom: 8,
                    }}
                  >
                    Mail Id
                    <span
                      style={{ color: UIColor.error.color }}
                    ></span>
                  </H6>
                }
                autoFocus={false}
                autoComplete="off"
                search={false}
                borderColor={UIColor.lineBorderFill.color}
                padding="10"
                margin="0"
                elevation="0"
                value={state.guestInfo?.mailId}
                onChange={(e) =>
                  changeGuestInfo(
                    "guestInfo",
                    "mailId",
                    e.target.value
                  )
                }
                className={classes.textField}
                // style={{ marginBottom: '14px', marginTop: '2%' }}
              />
              {(props?.guestInfo?.data?.type === 0
                ? state.error?.guestBookerInfo?.mailId
                : state?.error?.guestInfo?.mailId) && (
                <div id="doctor_listing_sidemenu_please_nter_email_div">
                  <Text
                    id="doctor_listing_sidemenu_please_enter_email_text"
                    style={{
                      color: UIColor.error.color,
                      fontSize: 14,
                    }}
                  >
                    {"Please Enter Valid Email ID"}
                  </Text>
                </div>
              )}
            </Div>
            <TermsAndConditions
              id="doctor_listing_sidemenu_send_termsand_conditions"
              onChange={(value) =>
                changeGuestInfo("guestInfo", "isEmail", value)
              }
              checkboxSize={14}
              checkboxTickColor={"#fff"}
              activeCheckboxBorderColor={
                UIColor.secondaryButtonColor.color
              }
              checkboxBorderColor={UIColor.lineBorderFill.color}
              checkboxBackgroundColor={
                UIColor.differentiationBackground.color
              }
              activeCheckboxBackgroundColor={
                UIColor.secondaryButtonColor.color
              }
              value={state.guestInfo?.isEmail}
              lable={
                <H6
                  id={`sdoctor_listing_sidemenu_send_termsand_conditions`}
                  style={{
                    fontSize: "12px",
                    color: UIColor.secondaryText.color,
                    margin: 0,
                    letterSpacing: 0,
                  }}
                  className={"pc_regular"}
                >
                  Send appointment update and notification to this
                  mail id
                </H6>
              }
              inLineStyles={{ lineHeight: 1 }}
              className={classes.termsAndConditions}
            />

            <Button
              id="doctor_listing_sidemenu_send_otp_confirm_button"
              // key={"7"}
            //   onClick={() => {
            //     props?.guestInfo?.data?.type === 0
            //       ? handleMySelfSignUp()
            //       : checkValidationsForOthers();
            //   }}
              type="button"
              variant="outlined"
              name={
                props?.guestInfo?.data?.type === 0
                  ? props.generateOTP?.loading ||
                    props.registerUsers?.loading
                    ? "Sending Otp..."
                    : "Send OTP & Confirm"
                  : "Next"
              }
              style={{
                margin: "auto",
                backgroundColor: UIColor.primaryColor.color,
                borderColor: UIColor.primaryColor.color,
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                padding: "10px 15px",
                fontSize: "14px",
                marginTop: 32,
                height: 40,
              }}
              className="pc_medium"
            ></Button>
          </Collapse>
        </Card>
        {props?.guestInfo?.data?.type === 0 ? null : (
          <Card id="doctor_listing_sidemenu_booking_by_card">
            <CardActions
              id="doctor_listing_sidemenu_booking_by_cardactions"
              // onClick={() => checkValidationsForOthers()}
              disableSpacing
              style={{
                padding: 0,
                paddingLeft: 16,
                paddingBottom: 0,
                cursor: "pointer",
              }}
            >
              <Text
                id="doctor_listing_sidemenu_booking_by_text"
                key={"0"}
                name="BOOKING BY"
                style={{
                  fontSize: "16px",
                  letterSpacing: 0,
                  lineHeight: 1,
                  color: UIColor.primaryText.color,
                }}
                className="pc_medium"
              />

              <IconButton
                id="doctor_listing_sidemenu_collapse_iconbutton"
                className={clsx(classes.expand, {
                  [classes.expandOpen]:
                    state.collpaseOpen === 1 ? true : false,
                })}
                aria-expanded={
                  state.collpaseOpen === 1 ? true : false
                }
                aria-label="show more"
              >
                <ExpandMoreIcon id="doctor_listing_sidemenu_expandmoreicon" />
              </IconButton>
            </CardActions>
            <Collapse
              id="doctor_listing_sidemenu_collapse"
              className={classes.body}
              in={state.collpaseOpen === 1 ? true : false}
              // open={state.collpaseOpen === 0 ? true : false}
              timeout="auto"
              unmountOnExit
            >
              <Div
                id="doctor_listing_sidemenu_collapse_div"
                className={classes.label}
              >
                <TextInput
                  id="doctor_listing_sidemenu_collapse_name_textinput"
                  // key={"0"}
                  placeholder=""
                  type="text"
                  name="text"
                  variant="outlined"
                  helperTextType="error"
                  label={
                    <H6
                      id="doctor_listing_sidemenu_collapse_name_label_textinput"
                      className="pc_regular"
                      style={{
                        fontSize: "12px",
                        color: UIColor.secondaryText.color,
                        margin: 0,
                        marginBottom: "8px",
                        letterSpacing: 0,
                        lineHeight: 1,
                      }}
                    >
                      What's your name ?
                      <span style={{ color: UIColor.error.color }}>
                        *
                      </span>
                    </H6>
                  }
                  autoFocus={false}
                  autoComplete="off"
                  search={false}
                  borderColor={UIColor.lineBorderFill.color}
                  padding="10"
                  margin="0"
                  elevation="0"
                  value={state.guestBookerInfo?.name}
                  onChange={(e) => {
                    changeGuestInfo(
                      "guestBookerInfo",
                      "name",
                      e.target.value
                    );
                    setState({
                      ...state,
                      error: {
                        guestBookerInfo: {
                          name: !checkName(e.target.value),
                        },
                      },
                    });
                  }}
                  className={classes.textField}
                  error={
                    state.error?.guestBookerInfo?.name && true
                  }
                />
                {state.error?.guestBookerInfo?.name && (
                  <div
                    id="doctor_listing_sidemenu_collapse_please_name_div"
                    className={"pc_regular"}
                  >
                    <Text
                      id="doctor_listing_sidemenu_collapse_please_name_text"
                      className="pc_regular"
                      style={{
                        color: UIColor.error.color,
                        fontSize: 14,
                      }}
                    >
                      {"Please Enter a Valid Name"}
                    </Text>
                  </div>
                )}
              </Div>
              <Div
                id="doctor_listing_sidemenu_collapse_relationship_div"
                className={classes.label}
              >
                <TapSelect
                  id="doctor_listing_sidemenu_collapse_relationship_tapselect"
                  // key={"2"}
                  type="single"
                  fullWidth={false}
                  textSize="16"
                  iconHeight="20"
                  iconWidth="20"
                  selectedBorderColor={UIColor.primaryColor.color}
                  selectedBackgroundColor={
                    UIColor.primaryColor.color
                  }
                  selectedTextColor="#fff"
                  borderColor={
                    UIColor.differentiationBackground.color
                  }
                  backgroundColor={
                    UIColor.differentiationBackground.color
                  }
                  textColor={"#000"}
                  label={
                    <H6
                      id="doctor_listing_sidemenu_collapse_relationship_label_span"
                      style={{
                        fontSize: "12px",
                        color: UIColor.secondaryText.color,
                        margin: 0,
                        letterSpacing: 0,
                        lineHeight: 1,
                      }}
                      className="pc_regular"
                    >
                      What's your relationship with{" "}
                      {state.guestInfo.name} ?
                      <span style={{ color: UIColor.error.color }}>
                        {" "}
                        *
                      </span>
                    </H6>
                  }
                  options={relationshipOptions}
                  defaultSelected={{
                    value: state.guestBookerInfo?.relationship,
                    label: state.guestBookerInfo?.relationship,
                  }}
                  onChange={(e) => {
                    changeGuestInfo(
                      "guestBookerInfo",
                      "relationship",
                      state?.guestBookerInfo?.relationship
                        ?.value === e?.value
                        ? {}
                        : e.value
                    );
                    setState({
                      ...state,
                      error: {
                        ...state.error,
                        guestBookerInfo: {
                          ...state.error?.guestBookerInfo,
                          relationship: false,
                        },
                      },
                    });
                  }}
                  // inLineStyles={{
                  //   padding: "4px 12px !important",
                  //   margin: 6,
                  // }}
                  className={classes.tapSelect}
                />
                {state.error?.guestBookerInfo
                  ?.relationship && (
                  <div
                    id="doctor_listing_sidemenu_collapse_relationship_label_div"
                    className={"pc_regular"}
                  >
                    <Text
                      id="doctor_listing_sidemenu_collapse_relationship_label_text"
                      style={{
                        color: UIColor.error.color,
                        fontSize: 14,
                      }}
                    >
                      {"Please Select Relationship"}
                    </Text>
                  </div>
                )}
              </Div>
              <H6
                id="doctor_listing_sidemenu_collapse_phone_number_label_span"
                style={{
                  fontSize: "12px",
                  color: UIColor.secondaryText.color,
                  margin: 0,
                  letterSpacing: 0,
                  lineHeight: 1,
                  marginBottom: 8,
                }}
                className="pc_regular"
              >
                Enter your phone number
                <span style={{ color: UIColor.error.color }}>
                  {" *"}
                </span>
              </H6>
              <div className={classes.mobile}>
                <MobileNumberWithCode
                  id="doctor_listing_sidemenu_collapse_mobilenumberwithocde"
                  // key={"3"}
                  type="number"
                  borderColor={UIColor.lineBorderFill.color}
                  borderRadius="6"
                  disabled={false}
                  maxLength={30}
                  value={
                    state.guestBookerInfo?.mobileNo?.mobileNo
                  }
                  dropdown={
                    state.guestBookerInfo?.mobileNo
                      ?.countryData
                  }
                  dropdownData={props.countryList?.data}
                  onChange={(e) => {
                    setState(
                      {
                        ...state,
                        error: {
                          ...state.error,
                          guestBookerInfo: {
                            ...state.error?.guestBookerInfo,
                            callingCodes: false,
                            mobileNo: false,
                          },
                        },
                      },
                      () => {
                        changeGuestInfo(
                          "guestBookerInfo",
                          "mobileNo",
                          e
                        );
                      }
                    );
                  }}
                  className={
                    (state.error?.guestBookerInfo
                      ?.callingCodes ||
                      state.error?.guestBookerInfo
                        ?.mobileNo) &&
                    "errorBorder"
                  }
                  // style={ { marginTop : "24px",paddingTop : "" } }
                  // inLineStyles={{
                  //   marginBottom:
                  //     state.error?.guestBookerInfo
                  //       ?.callingCodes ||
                  //       state.error?.guestBookerInfo?.mobileNo
                  //       ? 0
                  //       : 16,
                  // }}
                />
                {(state.error?.guestBookerInfo?.mobileNo ||
                  state.error?.guestBookerInfo
                    ?.callingCodes) && (
                  <Div
                    id="doctor_listing_sidemenu_collapse_country"
                    className={"pc_regular"}
                    style={{ marginBottom: 10 }}
                  >
                    {state.error?.guestBookerInfo
                      ?.callingCodes && (
                      <Text
                        id="doctor_listing_sidemenu_collapse_country_text"
                        style={{
                          color: UIColor.error.color,
                          fontSize: 14,
                        }}
                      >
                        {"Please select the country code"}
                      </Text>
                    )}
                    {!state.error?.guestBookerInfo
                      ?.callingCodes &&
                      state.error?.guestBookerInfo
                        ?.mobileNo && (
                        <Text
                          id="doctor_listing_sidemenu_collapse_number_text"
                          style={{
                            color: UIColor.error.color,
                            fontSize: 14,
                          }}
                        >
                          {"Please enter the mobile number"}
                        </Text>
                      )}
                  </Div>
                )}
              </div>
              <TermsAndConditions
                id="doctor_listing_sidemenu_collapse_number_termsandconditons"
                checkboxSize={14}
                onChange={(value) =>
                  changeGuestInfo(
                    "guestBookerInfo",
                    "isMobile",
                    value
                  )
                }
                value={state.guestBookerInfo?.isMobile}
                checkboxTickColor={"#fff"}
                activeCheckboxBorderColor={
                  UIColor.secondaryButtonColor.color
                }
                checkboxBorderColor={UIColor.lineBorderFill.color}
                checkboxBackgroundColor={
                  UIColor.differentiationBackground.color
                }
                activeCheckboxBackgroundColor={
                  UIColor.secondaryButtonColor.color
                }
                lable={
                  <H6
                    id={`sdoctor_listing_sidemenu_send_termsand_conditions`}
                    style={{
                      fontSize: "12px",
                      color: UIColor.secondaryText.color,
                      margin: 0,
                      letterSpacing: 0,
                    }}
                    className={"pc_regular"}
                  >
                    Send appointment update and notification to this
                    phone number
                  </H6>
                }
                inLineStyles={{ lineHeight: 1, marginBottom: 16 }}
                className={classes.termsAndConditions}
              />
              <Div
                id="doctor_listing_sidemenu_collapse_maildid_div"
                className={classes.label}
              >
                <TextInput
                  id="doctor_listing_sidemenu_collapse_maildid_input"
                  // key={"5"}
                  placeholder=""
                  type="text"
                  name="text"
                  variant="outlined"
                  helperTextType="error"
                  label={
                    <H6
                      id="doctor_listing_sidemenu_bookingby_mail_id_span"
                      style={{
                        fontSize: "12px",
                        color: UIColor.secondaryText.color,
                        margin: 0,
                        letterSpacing: 0,
                        lineHeight: 1,
                        marginBottom: 8,
                      }}
                    >
                      Mail Id
                    </H6>
                  }
                  autoFocus={false}
                  autoComplete="off"
                  search={false}
                  borderColor={UIColor.lineBorderFill.color}
                  padding="10"
                  margin="0"
                  elevation="0"
                  value={state.guestBookerInfo?.mailId}
                  onChange={(e) =>
                    changeGuestInfo(
                      "guestBookerInfo",
                      "mailId",
                      e.target.value
                    )
                  }
                  className={classes.textField}
                />
                {state.error?.guestBookerInfo?.mailId && (
                  <div id="doctor_listing_sidemenu_collapse_please_eneter_email_div">
                    <Text
                      id="doctor_listing_sidemenu_collapse_please_eneter_email_text"
                      style={{
                        color: UIColor.error.color,
                        fontSize: 14,
                      }}
                    >
                      {"Please Enter Valid Email ID"}
                    </Text>
                  </div>
                )}
              </Div>

              <TermsAndConditions
                id="doctor_listing_sidemenu_collapse_send_appointment_termsandcondition"
                // value={true}
                // key={"6"}
                onChange={(value) =>
                  changeGuestInfo(
                    "guestBookerInfo",
                    "isEmail",
                    value
                  )
                }
                value={state.guestBookerInfo?.isEmail}
                checkboxSize={14}
                checkboxTickColor={"#fff"}
                activeCheckboxBorderColor={
                  UIColor.secondaryButtonColor.color
                }
                checkboxBorderColor={UIColor.lineBorderFill.color}
                checkboxBackgroundColor={
                  UIColor.differentiationBackground.color
                }
                activeCheckboxBackgroundColor={
                  UIColor.secondaryButtonColor.color
                }
                lable={
                  <H6
                    id={`sdoctor_listing_sidemenu_send_termsand_conditions`}
                    style={{
                      fontSize: "12px",
                      color: UIColor.secondaryText.color,
                      margin: 0,
                      letterSpacing: 0,
                    }}
                    className={"pc_regular"}
                  >
                    Send appointment update and notification to this
                    mail Id
                  </H6>
                }
                inLineStyles={{ lineHeight: 1 }}
                className={classes.termsAndConditions}
              />

              <Button
                id="doctor_listing_sidemenu_collapse_send_otp_button"
                // key={"7"}
                // onClick={() => sendOTPandConfirm()}
                type="button"
                variant="outlined"
                name={
                  props.generateOTP?.loading ||
                  props.registerUsers?.loading
                    ? "Sending Otp..."
                    : "Send OTP & Confirm"
                }
                disabled={
                  props.generateOTP?.loading ||
                  props.registerUsers?.loading
                }
                style={{
                  margin: "auto",
                  marginTop: "34px",
                  backgroundColor: UIColor.primaryColor.color,
                  borderColor: UIColor.primaryColor.color,
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  padding: "10px 16px",
                  fontSize: "14px",
                  height: 40,
                }}
                className="pc_medium"
              ></Button>
            </Collapse>
          </Card>
        )}
      </Div>
    )}
  </SideMenu>
  )
}

export default AppointmentBookingSideMenu