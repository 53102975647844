import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  dbName,
  defaultReject,
  defaultState,
  fetchData,
  query_ids,
  urls,
} from "../../utils";
const {
  getFacilityList,
  getPayerList,
  getChargeCodeList
} = query_ids;

const GET_FACILITY_LIST = createAsyncThunk(
  `payerMapping/facilityList`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        queryid: getFacilityList,
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const GET_PAYER_LIST = createAsyncThunk(
  `payerMapping/payerList`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        queryid: getPayerList,
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const GET_CHARGE_LIST = createAsyncThunk(
  `payerMapping/chargeCodeList`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        filter: {
          tenantid: "",
          facilityid: "",
        },
        queryid: getChargeCodeList,
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const GET_OPENL_DATA = createAsyncThunk(
  "payerMapping/getOpenlData",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        {
          body: JSON.stringify(payload),
        },
        urls.getOpenlData
      );

      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const SET_OPENL_DATA = createAsyncThunk(
  "payerMapping/setOpenlData",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        {
          body: JSON.stringify(payload),
        },
        urls.setOpenlData
      );

      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const PayerChargeCodeMappingSlice = createSlice({
  name: "payerChargeCodeMapping",
  initialState: {
    getOpenlData: {
      ...defaultState.info,
    },
    setOpenlData: {
      ...defaultState.info,
    },
    facilityList: {
      ...defaultState.info,
    },
    payerList: {
      ...defaultState.info,
    },
    chargeCodeList: {
      ...defaultState.info,
    },
  },
  extraReducers: {
    /* GET_FACILITY_LIST  */
    [GET_FACILITY_LIST.fulfilled]: (state, action) => {
      state.facilityList = action?.payload ?? {};
    },
    [GET_FACILITY_LIST.pending]: (state, action) => {
      state.facilityList.loading = true;
    },
    [GET_FACILITY_LIST.rejected]: (state, action) => {
      state.facilityList = action?.payload ?? {};
    },
    /* GET_PAYER_LIST  */
    [GET_PAYER_LIST.fulfilled]: (state, action) => {
      state.payerList = action?.payload ?? {};
    },
    [GET_PAYER_LIST.pending]: (state, action) => {
      state.payerList.loading = true;
    },
    [GET_PAYER_LIST.rejected]: (state, action) => {
      state.payerList = action?.payload ?? {};
    },
    /* GET_CHARGE_LIST  */
    [GET_CHARGE_LIST.fulfilled]: (state, action) => {
      state.chargeCodeList = action?.payload ?? {};
    },
    [GET_CHARGE_LIST.pending]: (state, action) => {
      state.chargeCodeList.loading = true;
    },
    [GET_CHARGE_LIST.rejected]: (state, action) => {
      state.chargeCodeList = action?.payload ?? {};
    },
    /* GET_OPENL_DATA  */
    [GET_OPENL_DATA.fulfilled]: (state, action) => {
      state.getOpenlData = action?.payload ?? {};
    },
    [GET_OPENL_DATA.pending]: (state, action) => {
      state.getOpenlData.loading = true;
    },
    [GET_OPENL_DATA.rejected]: (state, action) => {
      state.getOpenlData = action?.payload ?? {};
    },
    /* SET_OPENL_DATA  */
    [SET_OPENL_DATA.fulfilled]: (state, action) => {
      state.setOpenlData = action?.payload ?? {};
    },
    [SET_OPENL_DATA.pending]: (state, action) => {
      state.setOpenlData.loading = true;
    },
    [SET_OPENL_DATA.rejected]: (state, action) => {
      state.setOpenlData = action?.payload ?? {};
    },
  },
});

export const PayerChargeCodeMappingActions = {
  GET_FACILITY_LIST,
  GET_PAYER_LIST,
  GET_CHARGE_LIST,
  GET_OPENL_DATA,
  SET_OPENL_DATA,
};

export default PayerChargeCodeMappingSlice;
