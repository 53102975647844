import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  urls,
  defaultState,
  defaultReject,
  fetchData,
  query_ids,
  dbName,
  personRequests,
  registerQuery,
  queries,
  generateResponseJson,
  makeName,
  getTenantId,
  getFacilityId,
  getGlobalValueFrom_LclStrg_And_QryStrg,
} from "../../utils";
//import { registerQuery } from "./query";
import axios from "axios";
import { childJSON } from "./orders/child";
import { generateJSON, generateOrderJSON } from "./orders/saveJson";
import { generateResJson, ccgenerateResJson } from "./orders/generateresponse";

const GET_ENCOUNTER = createAsyncThunk(
  `patientDetailsMastersSlice/encounter_details`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { encounter_id } = payload;

      const body = {
        db_name: dbName,
        filter: {
          encounterid: encounter_id,
          tenantid: "",
          facilityid: "",
        },
        queryid: "80099c67-b0a5-4c6f-9735-eacf9ef2fe7e",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const FOLLOW_UP_READ = createAsyncThunk(
  `patientDetailsMastersSlice/followup_read`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const { encounter_id } = payload;

      const body = {
        db_name: dbName,
        filter: {
          encounterid: encounter_id,
          tenantid: "",
          facilityid: "",
        },
        queryid: "26d14fff-d483-477d-871a-616aafe3184d",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const GET_FINANCIAL = createAsyncThunk(
  `patientDetailsMastersSlice/getfinancial`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { patientId = "" } = payload;
      const body = {
        db_name: dbName,
        filter: {
          patientid: patientId,
          tenantid: "",
          facilityid: "",
        },
        queryid: "17d35ba9-3aaa-4c54-b5c5-6c097b2a1b9c",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_ENCOUNTER_FINANCIAL = createAsyncThunk(
  `patientDetailsMastersSlice/getencounterfinancial`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { encounterid = "" } = payload;
      const body = {
        db_name: dbName,
        filter: {
          encounterid: encounterid,
          tenantid: "",
          facilityid: "",
        },
        queryid: "626db8f2-5512-4695-b5d5-168d0fc53836",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const PATIENT_BANNER = createAsyncThunk(
  `patientDetailsMastersSlice/patient_banner`,

  async (payload = {}, { rejectWithValue }) => {
    try {
      const { patientId = "" } = payload;

      const body = {
        db_name: dbName,
        filter: {
          patientid: patientId,
          tenantid: "",
          facilityid: "",
        },
        queryid: "d9f05a99-a65e-45b5-a7e4-220f3fbfa8c4",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,

        message: error.message,
      });
    }
  }
);

const ENCOUNTER_BANNER = createAsyncThunk(
  `patientDetailsMastersSlice/Encounterbanner`,

  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const { appointmentid = "" } = payload;

      const body = {
        db_name: dbName,
        filter: {
          appointmentid: appointmentid,
          tenantid: "",
          facilityid: "",
        },
        queryid: "c4607a48-c238-4784-9a24-6ebdd8c86266",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,

        message: error.message,
      });
    }
  }
);

const ENCOUNTER_BASED_CC = createAsyncThunk(
  `patientDetailsMastersSlice/encounterBasedCC`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const { encounter_id } = payload;
      const body = {
        db_name: dbName,
        filter: {
          encounterid: encounter_id,
          tenantid: "",
          facilityid: "",
        },
        queryid: "4885fb06-0872-4b20-b1f1-0597ca873ff8", //queryid: "4831d841-33aa-424e-ae78-086134623271",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      let res = ccgenerateResJson(data);
      return {
        ...defaultState.List,
        data: res,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const ENCOUNTER_BASED_A = createAsyncThunk(
  `patientDetailsMastersSlice/encounterBasedA`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { patient_id } = payload;
      const body = {
        db_name: dbName,
        filter: {
          patientid: patient_id,
          tenantid: "",
          facilityid: "",
        },
        queryid: "65d36782-4399-487f-90b4-6e53ca8464e2",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      let allergyList = generateResponseJson(data);
      return {
        ...defaultState.List,
        data: allergyList,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultState.List,
        message: error.message,
      });
    }
  }
);
const TRAEMENT_READ = createAsyncThunk(
  `patientDetailsMastersSlice/treatmentread`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { encounter_id = "", orderCat = "",patientid="", startdate="" } = payload;
      const body = {
        db_name: dbName,
        filter: {
          encounterid: encounter_id,
          ord_Category: orderCat,
          tenantid: "",
          CA_OrderLine: "",
          facilityid: "",
          patientid:patientid,
          startdate:startdate,
        },
        queryid: "bd4de695-ff18-4054-9bf0-8b26d3ad0525",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const TRAEMENT_PLAN_SAVE = createAsyncThunk(
  `patientDetailsMastersSlice/treatmentplansave`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { doc } = payload;
      const body = doc;
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.saveTreatmentPlan
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const TRAEMENT_PLAN_READ = createAsyncThunk(
  `patientDetailsMastersSlice/treatmentplanread`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { patientid } = payload;
      const body = {
        db_name: dbName,
        filter: {
          patientid: patientid,
        },
        queryid: "b52eaf55-d37a-4dd7-a5d9-64cc1f232f73",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const DOCC_READ = createAsyncThunk(
  `patientDetailsMastersSlice/docc_read`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      let { patient_id } = payload;
      const body = {
        db_name: dbName,
        filter: {
          patientid: patient_id,
          tenantid: "",
          facilityid: "",
        },
        queryid: "50131a76-fecc-443e-92a9-9c649fe58fd7",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const TREATMENT_ORDER_TYPE = createAsyncThunk(
  `patientDetailsMastersSlice/getordertype`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        queryid: "1b279ca5-dfa5-42a3-a41d-f7b7a33e8ff9",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const TREATMENT_ORDER_CATALOG = createAsyncThunk(
  `patientDetailsMastersSlice/getordercatalog`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        queryid: "2d31764f-035e-45c9-adc7-c9b05eda2e42",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const TREATMENT_DIAGNOSIS = createAsyncThunk(
  `patientDetailsMastersSlice/getorderdiagnosis`,
  async (payload = {}, { rejectWithValue }) => {
    const { id } = payload;
    // alert(JSON.stringify(id))
    try {
      const { encounter_id = "" } = payload;
      const body = {
        db_name: dbName,
        filter: {
          encounterid: encounter_id,
          tenantid: "",
          facilityid: "",
        },
        queryid: "297eaca9-0a36-4602-9d60-9b50dc7919e9",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const RAD_READ = createAsyncThunk(
  `patientDetailsMastersSlice/treatmentread`,
  async (payload = {}, { rejectWithValue, getState }) => {
    // try {
    // 	const body = {
    //             db_name: dbName,
    //             queryid: "2d31764f-035e-45c9-adc7-c9b05eda2e42",
    //         };
    //         const data = await fetchData(
    //             { body: JSON.stringify(body) },
    //             urls.readQdmQueries
    //         );
    // 	return {
    // 		...defaultState.List,
    // 		data: data,
    // 	};
    // } catch (error) {
    // 	return rejectWithValue({
    // 		...defaultReject,
    // 		message: error.message,
    // 	});
    // }
    try {
      const { encounter_id, order_id, patientid, startdate } = payload;
      const body = {
        db_name: dbName,
        filter: {
          encounterid: encounter_id,
          ord_Category: order_id,
          facilityid: "",
          tenantid: "",
          patientid:patientid,
          startdate:startdate,
        },
        queryid: "bd4de695-ff18-4054-9bf0-8b26d3ad0525",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );

      let res = generateResJson(data);

      return {
        ...defaultState.List,
        data: res,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const RAD_SAVE = createAsyncThunk(
  `RadiologySlice/savetreatment`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const {
        list,
        dateArr = [],
        isupdate,
        childIds,
        deleteArr = [],
      } = payload;
      let body = generateJSON(list, "RAD");

      const data = await fetchData(
        { body: JSON.stringify(body) },
        // __uspsertUrl__
        urls.insertOrder
      );

      await Promise.all(
        deleteArr?.map(async (val) => {
          return await fetchData(
            { body: queries.treatment_delete(val) },
            urls.deleteOrder
          );
        })
      );

      // var recurrentData;
      await Promise.all(
        dateArr?.reverse()?.map(async (v, i) => {
          const datas = childJSON(
            list,
            isupdate ? childIds[i] : data.Result?.[0]?.properties?.doc?._id,
            isupdate,
            v,
            "RAD"
          );
          return await fetchData(
            { body: JSON.stringify(datas) },
            // __uspsertUrl__
            urls.insertOrder
          );
        })
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const RAD_DELETE = createAsyncThunk(
  `RadiologySlice/treatmentdelete`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { id } = payload;
      let body = {
          "orderlineId":id,
          "isRecurrentDelete":false
          };

      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.deleteOrder
      );
      // const data = await fetchData(
      // 	{ body: queries.treatment_delete(id) },
      // 	urls.deleteOrder
      // );
      // // let arr = [];
      // // data.result.map((val) => {
      // //     arr.push({
      // //         value: val._id,
      // //         label: `${val?.code} - ${val.shortdesc}`,
      // //     });
      // // });
      return {
        ...defaultState.List,
        data: data.result,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const RAD_ORDER_CATALOG = createAsyncThunk(
  `patientDetailsMastersSlice/radordercatalog`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        queryid: "570fd7fc-8647-493a-9651-03065d9c2fe6",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const RAD_ORGANIZATION = createAsyncThunk(
  `patientDetailsMastersSlice/getorderorganization`,
  async (payload = {}, { rejectWithValue }) => {
    const { id } = payload;
    try {
      const body = {
        db_name: dbName,
        queryid: "6d18b211-1c07-4313-a8ef-3d409137c86d",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const RAD_ORDER_TYPE = createAsyncThunk(
  `patientDetailsMastersSlice/getradordertype`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        queryid: "09bedbd9-5b2b-42f7-9118-dc8e163b77c0",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const ALLERGY_MASTERS_VALUE = createAsyncThunk(
  `patientDetailsMastersSlice/allergymastervalue`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { func, input_text } = payload;
      let body = {
        db_name: dbName,
        filter: {
          searchcode: `%${input_text}%`,
        },
        queryid: "ede063d9-758e-42a9-a722-c27874d8dec5",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );

      let arr = [];
      data.map((val) => {
        arr.push({
          value: val?.id,
          id: val?._id,
          label: val?.shortdesc,
          masterId: val?.code,
          allergyType: val?.allergytype,
        });
      });
      return {
        ...defaultState.List,
        data: arr,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const SAVE_ALLERGY = createAsyncThunk(
  `patientDetailsMastersSlice/saveAllergy`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      let { payloaddata, _id } = payload;
      var body;
      if (_id) {
        body = [
          {
            db_name: dbName,
            entity: "CA_AllergyIntolerance",
            is_metadata: true,
            filter: {
              _id: `${_id}`,
            },
            metadata_dbname: process.env.REACT_APP_META_DB_NAME,
            metadataId: process.env.REACT_APP_METADATA_ID,
            doc: payloaddata,
          },
        ];
      } else {
        body = [
          {
            db_name: dbName,
            entity: "CA_AllergyIntolerance",
            is_metadata: true,
            metadata_dbname: process.env.REACT_APP_META_DB_NAME,
            metadataId: process.env.REACT_APP_METADATA_ID,
            doc: payloaddata,
          },
        ];
      }

      const data = await fetchData(
        { body: JSON.stringify(body) },
        process.env.REACT_APP_SAVE_ALLERGY
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const GET_VITALS_BASED_ENCOUNTER = createAsyncThunk(
  `patientDetailsMastersSlice/vitalsBasedEncounter`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const {PID , encounterid} = payload;

      const body = {
        db_name: dbName,
        filter: {
          patientid: PID,
          tenantid: "",
          facilityid: "",
          encounterid : encounterid
        },
        queryid: "4885fb06-0872-4b20-b1f1-0597ca873ff8",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const GET_VITALS_BASED_PATIENT = createAsyncThunk(
  `patientDetailsMastersSlice/vitalsBasedPatient`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const {PID,encounterId=""} = payload;

      const body = {
        db_name: dbName,
        filter: {
          patientid: PID,
          tenantid: "",
          facilityid: "",
          encounterid:encounterId,
        },
        queryid: "4885fb06-0872-4b20-b1f1-0597ca873ff8",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const GET_VITALS_JSON = createAsyncThunk(
  `patientDetailsMastersSlice/getvitalsjson`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const {
        age,
        diagcode = "",
        gender,
        organization_id,
        spltycode,
        vitalscode = "",
      } = payload;

      const body = {
        db_name: dbName,
        filter: {
          orgid: organization_id,
          gender: gender,
          specialtyCode: spltycode,
          ageindays: age,
          diagcode: diagcode,
          vitalscode: vitalscode,
        },
        queryid: "c421182e-1c83-4d86-bb44-9a4350d882a4",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
     
      let vitelsJson = [];
      if (Array.isArray(data?.[0]?.Observations)) {
        const newObservations = data?.[0]?.Observations;
        newObservations.sort((a, b) => Number(a.obsseq) - Number(b.obsseq));
        vitelsJson = newObservations.map((val) => {
          if (
            val?.obscode?.VitalsCodesReferenceRanges.length > 0 &&
            val?.obscode?.vitalscode
          ) {
            return { ...val.obscode, mandatory: val.mandatoryYN };
          }
        });
      }
      vitelsJson = vitelsJson.filter(function (element) {
        return element !== undefined;
      });
      let adjArray = vitelsJson.filter(
        (val, i) =>
          val.vitalscode?.toUpperCase?.() === "HEIGHT" ||
          val.vitalscode?.toUpperCase?.() === "WEIGHT"
      );

      let extraArr = [
        {
          ...adjArray[0],
          vitalscode: "BMI",
          longdesc: "BMI",
          shortdesc: "BMI",
          default_unit: "",
          id: 100,
          disabled: true,
        },
        {
          ...adjArray[0],
          vitalscode: "BSA",
          longdesc: "BSA",
          shortdesc: "BSA",
          default_unit: "",
          id: 130,
          disabled: true,
        },
      ];

      if (adjArray.length === 2) {
        vitelsJson = vitelsJson.concat(extraArr);
      }
      return {
        ...defaultState.Info,
        data: vitelsJson,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const SAVE_VITALS = createAsyncThunk(
  `patientDetailsMastersSlice/saveVitals`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { defaultValue, months, orgid, type } = payload;
      var body;
      let stateValues = [];
      let stateDatakey = [];
      stateDatakey = Object.keys(payload.dynamicValue).filter((e) =>
        /^[A-Z0-9]+$/.exec(e)
      );
      stateValues?.filter((val, i) => {
        // if (i % 2 === 0) {
        if (
          !(
            val.endsWith("uom") ||
            val.endsWith("error") ||
            val.endsWith("normalorabnormal")
          )
        ) {
          stateDatakey.push(val);
        }
      });

      let component = [];

      stateDatakey.map((val) => {
        let value = payload.dynamicValue[val]
          ? parseInt(payload.dynamicValue[val])
          : 0;

        let isnormalData =
          payload.dynamicValue[val + "normalorabnormal"] == "true"
            ? true
            : false;

        let abnormalmsg = payload.dynamicValue[val + "uom"];

        let _id = payload.dynamicValue[val + "_id"] ?? "";

        let default_unit_id = payload.dynamicValue[val + "uom_id"] ?? "";

        component.push({
          obsinternalid: _id,
          obscode: val,
          obsvalue: value,
          isabnormal: isnormalData,
          abnormalmsg: abnormalmsg ? JSON.stringify(abnormalmsg) : "",
          obsuom: default_unit_id,
        });
      });

      let payloadData = {
        orgid: orgid,
        patientid: payload.patientid,
        confirmedby: payload.confirmedby,
        encountertype: payload.encountertype,
        encounterid: payload.encounterid,
        facilityid: payload.facilityid,
        tenantid: payload.tenantid,
        component: component,
      };

      if (payload.editId) {
        body = [
          {
            db_name: dbName,
            entity: "CA_VitalSigns",
            is_metadata: true,
            filter: {
              _id: `${payload.editId}`,
            },
            metadata_dbname: process.env.REACT_APP_META_DB_NAME,
            metadataId: process.env.REACT_APP_METADATA_ID,
            doc: payloadData,
          },
        ];
      } else {
        body = [
          {
            db_name: dbName,
            entity: "CA_VitalSigns",
            is_metadata: true,
            metadata_dbname: process.env.REACT_APP_META_DB_NAME,
            metadataId: process.env.REACT_APP_METADATA_ID,
            doc: payloadData,
          },
        ];
      }

      const data = await fetchData(
        { body: JSON.stringify(body) },
        process.env.REACT_APP_SAVE_VITALS
      );
      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (err) {
      // ;
      // alert(err.message);
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const DELETE_A = createAsyncThunk(
  `patientDetailsMastersSlice/deleteA`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { id } = payload;
      let body = [
        {
          db_name: dbName,
          entity: "CA_AllergyIntolerance",
          is_metadata: true,
          filter: {
            _id: `${id}`,
          },
          metadata_dbname: process.env.REACT_APP_META_DB_NAME,
          metadataId: process.env.REACT_APP_METADATA_ID,
          doc: {
            activestatus: false,
          },
        },
      ];
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.upsertDocument
      );
      // let arr = [];
      // data.result.map((val) => {
      //   arr.push({
      //     value: val._id,
      //     label: `${val?.code} - ${val.shortdesc}`,
      //   });
      // });
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const LOCATION_DROPDOWN = createAsyncThunk(
  `patientDetailsMastersSlice/loactionDropDown`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      let body = {
        db_name: dbName,
        queryid: "dc7d6966-d859-47a8-80df-a68d5737bd6e",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      let obj = [];
      data.map((val) => {
        obj.push({
          label: val?.shortdesc,
          value: val?._id,
        });
      });
      return {
        ...defaultState.List,
        data: obj,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const PRACTITIONER_DROPDOWN = createAsyncThunk(
  `patientDetailsMastersSlice/practitionerDropDown`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      let body = {
        db_name: dbName,
        filter: {
          tenantid: "",
          facilityid: "",
        },
        queryid: "e79e5466-4d49-4295-963f-744238090467",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      let obj = data.map((val) => {
        const name = makeName(val?.name?.[0] || {});
        return {
          value: val?._id,
          label: name,
        };
      });
      return {
        ...defaultState.List,
        data: obj,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const ORDER_SET_READ = createAsyncThunk(
  `patientDetailsMastersSlice/orderSetRead`,
  async (payload = {}, { rejectWithValue }) => {
    const { list } = payload;
    try {
      const body = {
        db_name: dbName,
        filter: {
          tenantid: "",
          facilityid: "",
          specilityid: list?.speciality,
          locationid: list?.location,
          practroleid: list?.pract,
          diagnosis: list?.diag,
        },
        queryid: "c2f21700-e78b-44fa-ae13-bbed0b4af53a",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );

      let obj = [];
      data.map((val) => {
        obj.push({
          label: val?.shortDesc,
          value: val?._id,
          code: val?.orderSetCode,
          _key: val?._key,
        });
      });

      let orderList = {};
      data.forEach((val) => {
        let data = [];
        let object = {};
        val?.components?.forEach((v) => {
          const key = v?.ordercategory?.longdesc;
          if (!(key in object)) {
            object[key] = [];
          }
          object[key].push({
            child: [],
            parentSqNo: v?.parentCatSeqNo,
            sqNo: v?.seqNo,
            name: v?.orderCatalog?.longdesc ?? v?.orderCatalog?.LongDesc,
            orderCatalog_id: v?.orderCatalog?._id ?? v?.orderCatalog?._id,
            dose: v?.route?.shortdesc,
            checked: false,
            practInstr: v?.practInstr,
            startDay: v?.startDay,
            ordercategory: v?.ordercategory,
            orderType: v?.orderCatalog?.ordertype,
            orderSetsCode: val?.orderSetCode,
            priority: "",
            orderNature: "",
            orderQty: v?.dfltOrderQty,
            orderQtyUOM: v?.dfltOrderUOM,
            ordercatalog: v?.orderCatalog?.ordercatalog,
            freqValue: v?.dfltFreqVal,
            freqValueUnit: v?.dfltFreqValUnit,
            freqRate: v?.dfltFreqRate,
            freqRateUnit: v?.dfltFreqRateUnit,
            duration: v?.dfltDuration,
            durationType: v?.dfltDurUOM,
            IVDosDurUOM: v?.IVDosDurUOM,
            IVDosDurValue: v?.IVDosDurValue,
            _id: v?._id,
            id: v?.id,
            drugType: v?.orderCatalog?.DrugType,
            status: v?.orderCatalog?.Status,
            drugCode: v?.orderCatalog?._id,
            formCode: v?.orderCatalog?.FormCode,
            routeCode: v?.route?._id,
            StrengthValuesPossible: v?.StrengthValuesPossible,
            IVInfusRateUOM: v?.IVInfusRateUOM,
            IVInfusRateValue: v?.IVInfusRateValue,
            IVInfusRateVolUOM: v?.IVInfusRateVolUOM,
            IVInfusRateVol: v?.IVInfusRateVol,
            notes: v?.explNote,
            DrugCategory: v?.orderCatalog?.DrugCategory,
            dosageFreqCode: v?.dosageFreqCode,
          });
        });
        data = Object.keys(object).map((val) => {
          if (val === "Pharmacy") {
            const ordr = object[val];
            const newOrders = [];
            const childOrders = [];
            ordr.forEach((itm) => {
              if (!itm.parentSqNo) {
                newOrders.push(itm);
              } else {
                childOrders.push(itm);
              }
            });
            childOrders.forEach((o) => {
              const parentOrder = newOrders.find(
                (i) => o.parentSqNo === i.sqNo
              );
              if (parentOrder) {
                parentOrder.child.push(o);
              }
            });

            return {
              header: val,
              order: newOrders,
            };
          }
          return {
            header: val,
            order: object[val],
          };
        });
        orderList[val?._id] = data;
      });

      return {
        ...defaultState.List,
        data: {
          orderSet: obj,
          orderList: orderList,
        },
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const ORDER_SET_SAVE = createAsyncThunk(
  `patientDetailsMastersSlice/orderSetSave`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { list, info } = payload;
      let body = generateOrderJSON(list, info);
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.insertOrder
      );

      return {
        data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const PROGRESSNOTES_READ = createAsyncThunk(
  `patientDetailsMastersSlice/progressNotesRead`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const { patientId, encounterId } = payload;
      const body = {
        db_name: dbName,
        filter: {
          patientid: patientId, // "Patient/12576"
          encounterid: encounterId, // "Encounter/11973"
        },
        queryid: "eaa4e83d-b777-49dc-8ba2-fbcdbe4bf1eb",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const REFERRAL_READ = createAsyncThunk(
  `patientDetailsMastersSlice/referralread`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const { referralId } = payload;
      const body = {
        db_name: dbName,
        filter: {
          referralid: referralId, // "Patient/12576"
        },
        queryid: "5c204715-a6c5-42f9-91d0-61e372831cdf",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const GET_TREATEMENTPLAN_HISTORY = createAsyncThunk(
  `patientDetailsMastersSlice/getTreatmentPlanHistory`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const { patientId } = payload;
      const body = {
        db_name: dbName,
        filter: {
          patientid: patientId, //"Patient/12572", //
        },
        queryid: "667c1fb4-0505-4b77-8b11-9c66ad215632",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const PROGRESSNOTES_READ_HISTORY = createAsyncThunk(
  `patientDetailsMastersSlice/progressNotesReadHistory`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const { patientId, encounterId } = payload;
      const body = {
        db_name: dbName,
        filter: {
          patientid: patientId, //"Patient/12576",//
          encounterid: encounterId, //"Encounter/11973",//
        },
        queryid: "34a7ef75-c79b-4a38-940d-b2dd7b9c2a14",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const LABORDER_READ_HISTORY = createAsyncThunk(
  `patientDetailsMastersSlice/labOrderReadHistory`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const { patientId, encounterId, startdate } = payload;
      const body = {
        db_name: dbName,
        filter: {
          encounterid: encounterId, //"Encounter/12478""Encounter/12478",//
          patientid: patientId, //"Patient/12928""Patient/12928",//
          ord_Category: "LAB",
          tenantid: "",
          facilityid: "",
          startdate:startdate,
        },
        queryid: "ffb0574b-c685-49db-b90d-355ebcad6309",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const TREATMENTPROCEDURE_READ_HISTORY = createAsyncThunk(
  `patientDetailsMastersSlice/treatmentProcedureReadHistory`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const { patientId, encounterId } = payload;
      const body = {
        db_name: dbName,
        filter: {
          encounterid: encounterId, //"Encounter/12478",//
          patientid: patientId, //"Patient/12928",//
          ord_Category: "TREAT",
          tenantid: "",
          facilityid: "",
        },
        queryid: "45743923-3ed3-4693-bfe9-acf8b25a84f7",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const NURSETPROCEDURE_READ_HISTORY = createAsyncThunk(
  `patientDetailsMastersSlice/nurseReadHistory`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const { patientId, encounterId, startdate } = payload;
      const body = {
        db_name: dbName,
        filter: {
          encounterid: encounterId, //"Encounter/12478",//
          patientid: patientId, //"Patient/12928",//
          ord_Category: "NURSINGPROCEDURE",
          tenantid: "",
          facilityid: "",
          startdate:startdate,
        },
        queryid: "ffb0574b-c685-49db-b90d-355ebcad6309",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const PACKAGE_ORDER_HISTORY = createAsyncThunk(
  `patientDetailsMastersSlice/packageOrderHistory`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const { patientId, encounterId, startdate } = payload;
      const body = {
        db_name: dbName,
        filter: {
          encounterid: encounterId, //"Encounter/12478",//
          patientid: patientId, //"Patient/12928",//
          ord_Category: "PACK",
          tenantid: "",
          facilityid: "",
          startdate:startdate,
        },
        queryid: "ffb0574b-c685-49db-b90d-355ebcad6309",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const TREATMENTPROCEDURE_READ = createAsyncThunk(
  `patientDetailsMastersSlice/treatmentprocedureread`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const { patientId, encounterId } = payload;
      const body = {
        db_name: dbName,
        filter: {
          encounterid: encounterId, //"Encounter/12478",//
          patientid: patientId, //"Patient/12928",//
          ord_Category: "TREAT",
          tenantid: "",
          facilityid: "",
        },
        queryid: "b5ecd262-2ff7-4928-8bea-76308a523ec1",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const GET_CURRENT_MEDICATION_ORDER = createAsyncThunk(
  `patientDetailsMastersSlice/getMedicationOrder`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const body = {
        db_name: dbName,
        filter: {
          patientid: payload,
        },
        queryid: "93988bb2-23de-4fc0-afb5-236c32be0287",
      };

      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const SAVE_CLINICAL_NOTES = createAsyncThunk(
  `patientDetailsMastersSlice/save_clinicalnotes`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        { body: JSON.stringify(payload) },
        urls.saveClinicalNotes
      );
      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const READ_CLINICAL_NOTES = createAsyncThunk(
  `patientDetailsMastersSlice/read_clinicalnotes`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const { patientId, encounterId } = payload;
      const body = {
        db_name: dbName,
        filter: {
          patientid: patientId,
          encounterid: encounterId
        },
        queryid: query_ids?.readClinicalNotes,
      };

      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const GET_CLINICAL_NOTESTYPE = createAsyncThunk(
  `patientDetailsMastersSlice/get_clinicalnotestype`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const body = {
        db_name: dbName,
        filter: {
          type: ["CLINICALNOTETYPE"],
          tenantid:  getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"tenantid"}),
          facilityid: getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"facilityid"}),
          lang: "en",
        },
        queryid: query_ids?.getClinicalNotesType,
      };

      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const GET_NEXT_ENC_LIST = createAsyncThunk(
  `patientDetailsMastersSlice/get_nextenclilst`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      console.log(payload,"payloadpayload")
      const data = await fetchData(
        { body: JSON.stringify(payload) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const TYPE_MASTERS_A = createAsyncThunk(
  `patientDetailsMastersSlice/allergytype`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      let payloadData = {
        db_name: dbName,
        queryid: query_ids["GetAllMasters"],
        filter: {
          type: [
            "ALTYPE"
          ],
          tenantid:  getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"tenantid"}),
          facilityid: getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"facilityid"}),
          lang: "en",
        },
      }
      let data = await fetchData(
        { body: JSON.stringify(payloadData) },
        urls.readQdmQueries
      );
      
      data?.length>0 ? data = data?.[0]?.ALTYPE?.Value : data = [] 
      return {
        ...defaultState.Info,
        data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const REACTIONCODE_ALLERGY = createAsyncThunk(
  `patientDetailsMastersSlice/allergyreactioncode`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      let payloadData = {
        db_name: dbName,
        queryid: query_ids["GetAllMasters"],
        filter: {
          type: [
            "ALREACTIONCODE"
          ],
          tenantid:  getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"tenantid"}),
          facilityid: getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"facilityid"}),
          lang: "en",
        },
      }
      let data = await fetchData(
        { body: JSON.stringify(payloadData) },
        urls.readQdmQueries
      );
      
      data?.length>0 ? data = data?.[0]?.ALREACTIONCODE?.Value : data = [] 
      return {
        ...defaultState.Info,
        data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const SHOW_COMP_BASED_ON_REF_TYPE = createAsyncThunk(
  `transferAndScheduleSlice/showCompBasedOnRefType`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { ereftype } = payload; 
      let data = JSON.stringify({
        db_name: process.env.REACT_APP_DB,
        filter: {
          ereftype: ereftype,
        },
        queryid: query_ids?.getSequenceOfComponents,
      });
      
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url:urls?.readQdmQueries,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };

      let result = await axios.request(config);
      return {
        ...defaultState.Info,
        data: result.data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const patientDetailsMastersSlice = createSlice({
  name: "patientDetailsMastersSlice",
  initialState: {
    encounter_details: {
      ...defaultState.Info,
    },
    followup_read: {
      ...defaultState.Info,
    },
    getfinancial: {
      ...defaultState.Info,
    },
    getencounterfinancial: {
      ...defaultState.Info,
    },
    patient_banner: {
      ...defaultState.Info,
    },
    Encounterbanner: {
      ...defaultState.Info,
    },

    encounterBasedCC: {
      ...defaultState.Info,
    },
    encounterBasedA: {
      ...defaultState.Info,
    },
    treatmentread: {
      ...defaultState.Info,
    },
    docc_read: {
      ...defaultState.Info,
    },
    getordertype: {
      ...defaultState.Info,
    },
    getordercatalog: {
      ...defaultState.Info,
    },
    getorderdiagnosis: {
      ...defaultState.Info,
    },
    treatmentread: {
      ...defaultState.Info,
    },
    radordercatalog: {
      ...defaultState.Info,
    },
    getorderorganization: {
      ...defaultState.Info,
    },
    getradordertype: {
      ...defaultState.Info,
    },
    allergymastervalue: {
      ...defaultState.Info,
    },
    saveAllergy: {
      ...defaultState.Info,
    },
    vitalsBasedEncounter: {
      ...defaultState.Info,
    },
    getvitalsjson: {
      ...defaultState.Info,
    },
    saveVitals: {
      ...defaultState.Info,
    },
    deleteA: {
      ...defaultState.Info,
    },
    savetreatment: {
      ...defaultState.Info,
    },
    treatmentdelete: {
      ...defaultState.Info,
    },
    loactionDropDown: {
      ...defaultState.Info,
    },
    practitionerDropDown: {
      ...defaultState.Info,
    },
    orderSetRead: {
      ...defaultState.Info,
    },
    orderSetSave: {
      ...defaultState.Info,
    },
    treatmentplansave: {
      ...defaultState.Info,
    },
    treatmentplanread: {
      ...defaultState.List,
    },
    progressNotesRead: {
      ...defaultState.Info,
    },
    referralread: {
      ...defaultState.Info,
    },
    packageOrderHistory: {
      ...defaultState.Info,
    },
    getTreatmentPlanHistory: {
      ...defaultState.Info,
    },
    progressNotesReadHistory: {
      ...defaultState.Info,
    },
    labOrderReadHistory: {
      ...defaultState.Info,
    },
    treatmentProcedureReadHistory: {
      ...defaultState.Info,
    },
    nurseReadHistory: {
      ...defaultState.Info,
    },
    treatmentprocedureread: {
      ...defaultState.Info,
    },
    getMedicationOrder: {
      ...defaultState.Info,
    },
    save_clinicalnotes: {
      ...defaultState.Info,
    },
    read_clinicalnotes: {
      ...defaultState.Info,
    },
    get_clinicalnotestype: {
      ...defaultState.Info,
    },
    get_nextenclilst: {
      ...defaultState.Info,
    },
    allergytype:  {
      ...defaultState.Info,
    },
    allergyreactioncode: {
      ...defaultState.Info,
    },
    showCompBasedOnRefType: {
      ...defaultState.List
    },
  },
  extraReducers: {
    // RAD_SAVE
    [RAD_SAVE.fulfilled]: (state, action) => {
      state.savetreatment = action?.payload ?? {};
    },
    [RAD_SAVE.pending]: (state, action) => {
      state.savetreatment.loading = true;
    },
    [RAD_SAVE.rejected]: (state, action) => {
      state.savetreatment = action?.payload ?? {};
    },
    // RAD_DELETE
    [RAD_DELETE.fulfilled]: (state, action) => {
      state.treatmentdelete = action?.payload ?? {};
    },
    [RAD_DELETE.pending]: (state, action) => {
      state.treatmentdelete.loading = true;
    },
    [RAD_DELETE.rejected]: (state, action) => {
      state.treatmentdelete = action?.payload ?? {};
    },
    // GET_ENCOUNTER
    [GET_ENCOUNTER.fulfilled]: (state, action) => {
      state.encounter_details = action?.payload ?? {};
    },
    [GET_ENCOUNTER.pending]: (state, action) => {
      state.encounter_details.loading = true;
    },
    [GET_ENCOUNTER.rejected]: (state, action) => {
      state.encounter_details = action?.payload ?? {};
    },
    // FOLLOW_UP_READ
    [FOLLOW_UP_READ.fulfilled]: (state, action) => {
      state.followup_read = action?.payload ?? {};
    },
    [FOLLOW_UP_READ.pending]: (state, action) => {
      state.followup_read.loading = true;
    },
    [FOLLOW_UP_READ.rejected]: (state, action) => {
      state.followup_read = action?.payload ?? {};
    },
    // GET_FINANCIAL
    [GET_FINANCIAL.fulfilled]: (state, action) => {
      state.getfinancial = action?.payload ?? {};
    },
    [GET_FINANCIAL.pending]: (state, action) => {
      state.getfinancial.loading = true;
    },
    [GET_FINANCIAL.rejected]: (state, action) => {
      state.getfinancial = action?.payload ?? {};
    },
     // GET_ENCOUNTER_FINANCIAL
     [GET_ENCOUNTER_FINANCIAL.fulfilled]: (state, action) => {
      state.getencounterfinancial = action?.payload ?? {};
    },
    [GET_ENCOUNTER_FINANCIAL.pending]: (state, action) => {
      state.getencounterfinancial.loading = true;
    },
    [GET_ENCOUNTER_FINANCIAL.rejected]: (state, action) => {
      state.getencounterfinancial = action?.payload ?? {};
    },
    // PATIENT_BANNER
    [PATIENT_BANNER.fulfilled]: (state, action) => {
      state.patient_banner = action?.payload ?? {};
    },
    [PATIENT_BANNER.pending]: (state, action) => {
      state.patient_banner.loading = true;
    },
    [PATIENT_BANNER.rejected]: (state, action) => {
      state.patient_banner = action?.payload ?? {};
    },
    // ENCOUNTER_BANNER
    [ENCOUNTER_BANNER.fulfilled]: (state, action) => {
      state.Encounterbanner = action?.payload ?? {};
    },
    [ENCOUNTER_BANNER.pending]: (state, action) => {
      state.Encounterbanner.loading = true;
    },
    [ENCOUNTER_BANNER.rejected]: (state, action) => {
      state.Encounterbanner = action?.payload ?? {};
    },

    // ENCOUNTER_BASED_CC
    [ENCOUNTER_BASED_CC.fulfilled]: (state, action) => {
      state.encounterBasedCC = action?.payload ?? {};
    },
    [ENCOUNTER_BASED_CC.pending]: (state, action) => {
      state.encounterBasedCC.loading = true;
    },
    [ENCOUNTER_BASED_CC.rejected]: (state, action) => {
      state.encounterBasedCC = action?.payload ?? {};
    },
    // ENCOUNTER_BASED_A
    [ENCOUNTER_BASED_A.fulfilled]: (state, action) => {
      state.encounterBasedA = action?.payload ?? [];
    },
    [ENCOUNTER_BASED_A.pending]: (state, action) => {
      state.encounterBasedA.loading = true;
    },
    [ENCOUNTER_BASED_A.rejected]: (state, action) => {
      state.encounterBasedA = action?.payload ?? [];
    },
    // TRAEMENT_READ
    [TRAEMENT_READ.fulfilled]: (state, action) => {
      state.treatmentread = action?.payload ?? {};
    },
    [TRAEMENT_READ.pending]: (state, action) => {
      state.treatmentread.loading = true;
    },
    [TRAEMENT_READ.rejected]: (state, action) => {
      state.treatmentread = action?.payload ?? {};
    },
    // DOCC_READ
    [DOCC_READ.fulfilled]: (state, action) => {
      state.docc_read = action?.payload ?? {};
    },
    [DOCC_READ.pending]: (state, action) => {
      state.docc_read.loading = true;
    },
    [DOCC_READ.rejected]: (state, action) => {
      state.docc_read = action?.payload ?? {};
    },
    // TREATMENT_ORDER_TYPE
    [TREATMENT_ORDER_TYPE.fulfilled]: (state, action) => {
      state.getordertype = action?.payload ?? {};
    },
    [TREATMENT_ORDER_TYPE.pending]: (state, action) => {
      state.getordertype.loading = true;
    },
    [TREATMENT_ORDER_TYPE.rejected]: (state, action) => {
      state.getordertype = action?.payload ?? {};
    },
    // TREATMENT_ORDER_CATALOG
    [TREATMENT_ORDER_CATALOG.fulfilled]: (state, action) => {
      state.getordercatalog = action?.payload ?? {};
    },
    [TREATMENT_ORDER_CATALOG.pending]: (state, action) => {
      state.getordercatalog.loading = true;
    },
    [TREATMENT_ORDER_CATALOG.rejected]: (state, action) => {
      state.getordercatalog = action?.payload ?? {};
    },
    // TREATMENT_DIAGNOSIS
    [TREATMENT_DIAGNOSIS.fulfilled]: (state, action) => {
      state.getorderdiagnosis = action?.payload ?? {};
    },
    [TREATMENT_DIAGNOSIS.pending]: (state, action) => {
      state.getorderdiagnosis.loading = true;
    },
    [TREATMENT_DIAGNOSIS.rejected]: (state, action) => {
      state.getorderdiagnosis = action?.payload ?? {};
    },
    // RAD_READ
    [RAD_READ.fulfilled]: (state, action) => {
      state.treatmentread = action?.payload ?? {};
    },
    [RAD_READ.pending]: (state, action) => {
      state.treatmentread.loading = true;
    },
    [RAD_READ.rejected]: (state, action) => {
      state.treatmentread = action?.payload ?? {};
    },
    // RAD_ORDER_CATALOG
    [RAD_ORDER_CATALOG.fulfilled]: (state, action) => {
      state.radordercatalog = action?.payload ?? {};
    },
    [RAD_ORDER_CATALOG.pending]: (state, action) => {
      state.radordercatalog.loading = true;
    },
    [RAD_ORDER_CATALOG.rejected]: (state, action) => {
      state.radordercatalog = action?.payload ?? {};
    },
    // RAD_ORGANIZATION
    [RAD_ORGANIZATION.fulfilled]: (state, action) => {
      state.getorderorganization = action?.payload ?? {};
    },
    [RAD_ORGANIZATION.pending]: (state, action) => {
      state.getorderorganization.loading = true;
    },
    [RAD_ORGANIZATION.rejected]: (state, action) => {
      state.getorderorganization = action?.payload ?? {};
    },
    // RAD_ORDER_TYPE
    [RAD_ORDER_TYPE.fulfilled]: (state, action) => {
      state.getradordertype = action?.payload ?? {};
    },
    [RAD_ORDER_TYPE.pending]: (state, action) => {
      state.getradordertype.loading = true;
    },
    [RAD_ORDER_TYPE.rejected]: (state, action) => {
      state.getradordertype = action?.payload ?? {};
    },
    // ALLERGY_MASTERS_VALUE
    [ALLERGY_MASTERS_VALUE.fulfilled]: (state, action) => {
      state.allergymastervalue = action?.payload ?? {};
    },
    [ALLERGY_MASTERS_VALUE.pending]: (state, action) => {
      state.allergymastervalue.loading = true;
    },
    [ALLERGY_MASTERS_VALUE.rejected]: (state, action) => {
      state.allergymastervalue = action?.payload ?? {};
    },
    // SAVE_ALLERGY
    [SAVE_ALLERGY.fulfilled]: (state, action) => {
      state.saveAllergy = action?.payload ?? {};
    },
    [SAVE_ALLERGY.pending]: (state, action) => {
      state.saveAllergy.loading = true;
    },
    [SAVE_ALLERGY.rejected]: (state, action) => {
      state.saveAllergy = action?.payload ?? {};
    },
    // GET_VITALS_BASED_ENCOUNTER
    [GET_VITALS_BASED_ENCOUNTER.fulfilled]: (state, action) => {
      state.vitalsBasedEncounter = action?.payload ?? {};
    },
    [GET_VITALS_BASED_ENCOUNTER.pending]: (state, action) => {
      state.vitalsBasedEncounter.loading = true;
    },
    [GET_VITALS_BASED_ENCOUNTER.rejected]: (state, action) => {
      state.vitalsBasedEncounter = action?.payload ?? {};
    },

    // GET_VITALS_BASED_PATIENT
    [GET_VITALS_BASED_PATIENT.fulfilled]: (state, action) => {
      state.vitalsBasedPatient = action?.payload ?? {};
    },
    [GET_VITALS_BASED_PATIENT.pending]: (state, action) => {
      state.vitalsBasedPatient.loading = true;
    },
    [GET_VITALS_BASED_PATIENT.rejected]: (state, action) => {
      state.vitalsBasedPatient = action?.payload ?? {};
    },

    // GET_VITALS_JSON
    [GET_VITALS_JSON.fulfilled]: (state, action) => {
      state.getvitalsjson = action?.payload ?? {};
    },
    [GET_VITALS_JSON.pending]: (state, action) => {
      state.getvitalsjson.loading = true;
    },
    [GET_VITALS_JSON.rejected]: (state, action) => {
      state.getvitalsjson = action?.payload ?? {};
    },
    // SAVE_VITALS
    [SAVE_VITALS.fulfilled]: (state, action) => {
      state.saveVitals = action?.payload ?? {};
    },
    [SAVE_VITALS.pending]: (state, action) => {
      state.saveVitals.loading = true;
    },
    [SAVE_VITALS.rejected]: (state, action) => {
      state.saveVitals = action?.payload ?? {};
    },
    // DELETE_A
    [DELETE_A.fulfilled]: (state, action) => {
      state.deleteA = action?.payload ?? {};
    },
    [DELETE_A.pending]: (state, action) => {
      state.deleteA.loading = true;
    },
    [DELETE_A.rejected]: (state, action) => {
      state.deleteA = action?.payload ?? {};
    },
    // LOCATION_DROPDOWN
    [LOCATION_DROPDOWN.fulfilled]: (state, action) => {
      state.loactionDropDown = action?.payload ?? {};
    },
    [LOCATION_DROPDOWN.pending]: (state, action) => {
      state.loactionDropDown.loading = true;
    },
    [LOCATION_DROPDOWN.rejected]: (state, action) => {
      state.loactionDropDown = action?.payload ?? {};
    },
    // PRACTITIONER_DROPDOWN
    [PRACTITIONER_DROPDOWN.fulfilled]: (state, action) => {
      state.practitionerDropDown = action?.payload ?? {};
    },
    [PRACTITIONER_DROPDOWN.pending]: (state, action) => {
      state.practitionerDropDown.loading = true;
    },
    [PRACTITIONER_DROPDOWN.rejected]: (state, action) => {
      state.practitionerDropDown = action?.payload ?? {};
    },
    // ORDER_SET_READ
    [ORDER_SET_READ.fulfilled]: (state, action) => {
      state.orderSetRead = action?.payload ?? {};
    },
    [ORDER_SET_READ.pending]: (state, action) => {
      state.orderSetRead.loading = true;
    },
    [ORDER_SET_READ.rejected]: (state, action) => {
      state.orderSetRead = action?.payload ?? {};
    },
    // ORDER_SET_SAVE
    [ORDER_SET_SAVE.fulfilled]: (state, action) => {
      state.orderSetSave = action?.payload ?? {};
    },
    [ORDER_SET_SAVE.pending]: (state, action) => {
      state.orderSetSave.loading = true;
    },
    [ORDER_SET_SAVE.rejected]: (state, action) => {
      state.orderSetSave = action?.payload ?? {};
    },
    // ORDER_SET_SAVE
    [TRAEMENT_PLAN_SAVE.fulfilled]: (state, action) => {
      state.treatmentplansave = action?.payload ?? {};
    },
    [TRAEMENT_PLAN_SAVE.pending]: (state, action) => {
      state.treatmentplansave.loading = true;
    },
    [TRAEMENT_PLAN_SAVE.rejected]: (state, action) => {
      state.treatmentplansave = action?.payload ?? {};
    },
    // TRAEMENT_READ
    [TRAEMENT_PLAN_READ.fulfilled]: (state, action) => {
      state.treatmentplanread = action?.payload ?? {};
    },
    [TRAEMENT_PLAN_READ.pending]: (state, action) => {
      state.treatmentplanread.loading = true;
    },
    [TRAEMENT_PLAN_READ.rejected]: (state, action) => {
      state.treatmentplanread = action?.payload ?? {};
    },

    // PROGRESSNOTES_READ

    [PROGRESSNOTES_READ.fulfilled]: (state, action) => {
      state.progressNotesRead = action?.payload ?? {};
    },
    [PROGRESSNOTES_READ.pending]: (state, action) => {
      state.progressNotesRead.loading = true;
    },
    [PROGRESSNOTES_READ.rejected]: (state, action) => {
      state.progressNotesRead = action?.payload ?? {};
    },
    // REFERRAL_READ

    [REFERRAL_READ.fulfilled]: (state, action) => {
      state.referralread = action?.payload ?? {};
    },
    [REFERRAL_READ.pending]: (state, action) => {
      state.referralread.loading = true;
    },
    [REFERRAL_READ.rejected]: (state, action) => {
      state.referralread = action?.payload ?? {};
    },

    // GET_TREATEMENTPLAN_HISTORY

    [GET_TREATEMENTPLAN_HISTORY.fulfilled]: (state, action) => {
      state.referralread = action?.payload ?? {};
    },
    [GET_TREATEMENTPLAN_HISTORY.pending]: (state, action) => {
      state.referralread.loading = true;
    },
    [GET_TREATEMENTPLAN_HISTORY.rejected]: (state, action) => {
      state.referralread = action?.payload ?? {};
    },
    // PROGRESSNOTES_READ_HISTORY

    [PROGRESSNOTES_READ_HISTORY.fulfilled]: (state, action) => {
      state.referralread = action?.payload ?? {};
    },
    [PROGRESSNOTES_READ_HISTORY.pending]: (state, action) => {
      state.referralread.loading = true;
    },
    [PROGRESSNOTES_READ_HISTORY.rejected]: (state, action) => {
      state.referralread = action?.payload ?? {};
    },

    // LABORDER_READ_HISTORY

    [LABORDER_READ_HISTORY.fulfilled]: (state, action) => {
      state.referralread = action?.payload ?? {};
    },
    [LABORDER_READ_HISTORY.pending]: (state, action) => {
      state.referralread.loading = true;
    },
    [LABORDER_READ_HISTORY.rejected]: (state, action) => {
      state.referralread = action?.payload ?? {};
    },

    // TREATMENTPROCEDURE_READ_HISTORY

    [TREATMENTPROCEDURE_READ_HISTORY.fulfilled]: (state, action) => {
      state.referralread = action?.payload ?? {};
    },
    [TREATMENTPROCEDURE_READ_HISTORY.pending]: (state, action) => {
      state.referralread.loading = true;
    },
    [TREATMENTPROCEDURE_READ_HISTORY.rejected]: (state, action) => {
      state.referralread = action?.payload ?? {};
    },
    // NURSETPROCEDURE_READ_HISTORY

    [NURSETPROCEDURE_READ_HISTORY.fulfilled]: (state, action) => {
      state.referralread = action?.payload ?? {};
    },
    [NURSETPROCEDURE_READ_HISTORY.pending]: (state, action) => {
      state.referralread.loading = true;
    },
    [NURSETPROCEDURE_READ_HISTORY.rejected]: (state, action) => {
      state.referralread = action?.payload ?? {};
    },

    // PACKAGE_ORDER_HISTORY

    [PACKAGE_ORDER_HISTORY.fulfilled]: (state, action) => {
      state.packageOrderHistory = action?.payload ?? {};
    },
    [PACKAGE_ORDER_HISTORY.pending]: (state, action) => {
      state.packageOrderHistory.loading = true;
    },
    [PACKAGE_ORDER_HISTORY.rejected]: (state, action) => {
      state.packageOrderHistory = action?.payload ?? {};
    },

    // GET_CURRENT_MEDICATION_ORDERGET_CURRENT_MEDICATION_ORDER

    [TREATMENTPROCEDURE_READ.fulfilled]: (state, action) => {
      state.referralread = action?.payload ?? {};
    },
    [TREATMENTPROCEDURE_READ.pending]: (state, action) => {
      state.referralread.loading = true;
    },
    [TREATMENTPROCEDURE_READ.rejected]: (state, action) => {
      state.referralread = action?.payload ?? {};
    },
    // GET_CURRENT_MEDICATION_ORDER

    [GET_CURRENT_MEDICATION_ORDER.fulfilled]: (state, action) => {
      state.getMedicationOrder = action?.payload ?? {};
    },
    [GET_CURRENT_MEDICATION_ORDER.pending]: (state, action) => {
      state.getMedicationOrder.loading = true;
    },
    [GET_CURRENT_MEDICATION_ORDER.rejected]: (state, action) => {
      state.getMedicationOrder = action?.payload ?? {};
    },
    // SAVE CLINICAL NOTES

    [SAVE_CLINICAL_NOTES.fulfilled]: (state, action) => {
      state.save_clinicalnotes = action?.payload ?? {};
    },
    [SAVE_CLINICAL_NOTES.pending]: (state, action) => {
      state.save_clinicalnotes.loading = true;
    },
    [SAVE_CLINICAL_NOTES.rejected]: (state, action) => {
      state.save_clinicalnotes = action?.payload ?? {};
    },
    // Read CLINICAL NOTES

    [READ_CLINICAL_NOTES.fulfilled]: (state, action) => {
      state.read_clinicalnotes = action?.payload ?? {};
    },
    [READ_CLINICAL_NOTES.pending]: (state, action) => {
      state.read_clinicalnotes.loading = true;
    },
    [READ_CLINICAL_NOTES.rejected]: (state, action) => {
      state.read_clinicalnotes = action?.payload ?? {};
    },
    // Read CLINICAL NOTES

    [GET_CLINICAL_NOTESTYPE.fulfilled]: (state, action) => {
      state.read_clinicalnotes = action?.payload ?? {};
    },
    [GET_CLINICAL_NOTESTYPE.pending]: (state, action) => {
      state.read_clinicalnotes.loading = true;
    },
    [GET_CLINICAL_NOTESTYPE.rejected]: (state, action) => {
      state.read_clinicalnotes = action?.payload ?? {};
    },
    // GET_NEXT_ENC_LIST

    [GET_NEXT_ENC_LIST.fulfilled]: (state, action) => {
      state.get_nextenclilst = action?.payload ?? {};
    },
    [GET_NEXT_ENC_LIST.pending]: (state, action) => {
      state.get_nextenclilst.loading = true;
    },
    [GET_NEXT_ENC_LIST.rejected]: (state, action) => {
      state.get_nextenclilst = action?.payload ?? {};
    },
     // TYPE_MASTERS_A

     [TYPE_MASTERS_A.fulfilled]: (state, action) => {
      state.allergytype = action?.payload ?? {};
    },
    [TYPE_MASTERS_A.pending]: (state, action) => {
      state.allergytype.loading = true;
    },
    [TYPE_MASTERS_A.rejected]: (state, action) => {
      state.allergytype = action?.payload ?? {};
    },
     // REACTIONCODE_ALLERGY

     [REACTIONCODE_ALLERGY.fulfilled]: (state, action) => {
      state.allergyreactioncode = action?.payload ?? {};
    },
    [REACTIONCODE_ALLERGY.pending]: (state, action) => {
      state.allergyreactioncode.loading = true;
    },
    [REACTIONCODE_ALLERGY.rejected]: (state, action) => {
      state.allergyreactioncode = action?.payload ?? {};
    },
    
    /* SHOW_COMP_BASED_ON_REF_TYPE */
    [SHOW_COMP_BASED_ON_REF_TYPE.fulfilled]: (state, action) => {
      state.showCompBasedOnRefType = action?.payload ?? [];
    },
    [SHOW_COMP_BASED_ON_REF_TYPE.pending]: (state, action) => {
      state.showCompBasedOnRefType.loading = true;
    },
    [SHOW_COMP_BASED_ON_REF_TYPE.rejected]: (state, action) => {
      state.showCompBasedOnRefType = action?.payload ?? [];
    },
  },
});

export const patientDetailsActions = {
  GET_ENCOUNTER,
  FOLLOW_UP_READ,
  GET_FINANCIAL,
  PATIENT_BANNER,
  ENCOUNTER_BANNER,
  ENCOUNTER_BASED_CC,
  ENCOUNTER_BASED_A,
  TRAEMENT_READ,
  DOCC_READ,
  TREATMENT_ORDER_TYPE,
  TREATMENT_ORDER_CATALOG,
  TREATMENT_DIAGNOSIS,
  RAD_READ,
  RAD_ORDER_CATALOG,
  RAD_ORGANIZATION,
  RAD_ORDER_TYPE,
  ALLERGY_MASTERS_VALUE,
  SAVE_ALLERGY,
  GET_VITALS_BASED_ENCOUNTER,
  GET_VITALS_BASED_PATIENT,
  GET_VITALS_JSON,
  SAVE_VITALS,
  DELETE_A,
  RAD_SAVE,
  RAD_DELETE,
  LOCATION_DROPDOWN,
  PRACTITIONER_DROPDOWN,
  ORDER_SET_READ,
  ORDER_SET_SAVE,
  TRAEMENT_PLAN_SAVE,
  TRAEMENT_PLAN_READ,
  PROGRESSNOTES_READ,
  REFERRAL_READ,
  GET_TREATEMENTPLAN_HISTORY,
  PROGRESSNOTES_READ_HISTORY,
  LABORDER_READ_HISTORY,
  TREATMENTPROCEDURE_READ_HISTORY,
  NURSETPROCEDURE_READ_HISTORY,
  PACKAGE_ORDER_HISTORY,
  TREATMENTPROCEDURE_READ,
  GET_CURRENT_MEDICATION_ORDER,
  GET_ENCOUNTER_FINANCIAL,
  SAVE_CLINICAL_NOTES,
  READ_CLINICAL_NOTES,
  GET_CLINICAL_NOTESTYPE,
  GET_NEXT_ENC_LIST,
  TYPE_MASTERS_A,
  REACTIONCODE_ALLERGY,
  SHOW_COMP_BASED_ON_REF_TYPE,
};

export default patientDetailsMastersSlice;
