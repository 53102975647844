import { makeStyles } from "@material-ui/core";
import { UIColor } from "../../../themes/theme";

export const useStyles = makeStyles((theme) => ({
  
    expand: {
      padding: 16,
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    body: {
      paddingTop: 0,
      paddingLeft: 16,
      paddingRight: 16,
    },
    TapSelect: {
      "& div": {
        "& span": {
          fontSize: 13,
          fontWeight: "600",
          letterSpacing: "0.9px",
        },
      },
    },
    dropdown: {
      "& .dropDownButton": {
        height: 40,
        border: "1px solid #DEE5EC",
        borderRadius: 8,
        width: "130px",
        "& .placeHolder": {
          fontSize: 12,
          fontFamily: "pc_regular",
          color: UIColor.tertiaryText.color,
          letterSpacing: "-0.29px",
        },
        "& .selectHolder": {
          fontSize: 12,
          fontFamily: "pc_regular",
          color: UIColor.primaryText.color,
          letterSpacing: "-0.29px",
        },
        "& .svgIcon": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    },
    dropdownErr: {
      "& .dropDownButton": {
        height: 40,
        border: "1px solid red",
        borderRadius: 8,
        width: "130px",
        "& .placeHolder": {
          fontSize: 12,
          fontFamily: "pc_regular",
          color: "#B6B6B6",
          letterSpacing: "-0.29px",
        },
        "& .selectHolder": {
          fontSize: 12,
          fontFamily: "pc_regular",
          color: "#101010",
          letterSpacing: "-0.29px",
        },
        "& .svgIcon": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    },
    mobile: {
      "& .callingCodes,input": {
        fontSize: 14,
        color: UIColor.primaryText.color,
        fontFamily: "pc_medium",
        letterSpacing: "-0.34px",
        fontWeight: "normal",
      },
      "& input::placeholder": {
        fontFamily: "pc_medium",
      },
      "& #guestsignin_addmember_sidemenu_mobilenumberwithcode_textfield": {
        borderColor: UIColor.lineBorderFill.color,
      },
      "& .mobileCountryDivider": {
        borderColor: UIColor.lineBorderFill.color,
      },
    },
    textField: {
      "& input": {
        fontFamily: "pc_medium",
        "&::placeholder": {
          fontFamily: "pc_medium",
        },
      },
    },
    termsandcondition: {
      "& .termsConditionsLabel": {
        margin: 0,
      },
      "& .termsConditionCheckbox": {
        // margin:0,
      },
    },
    htwc: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      border: "1px solid #DEE5EC",
      borderRadius: "10px",
      marginTop: "16px",
      marginBottom: "16px",
      position: "relative",
      padding: "12px",
      cursor: "pointer",
    },
    htwc1: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      border: "1px solid #01205C",
      borderRadius: "10px",
      marginTop: "16px",
      marginBottom: "0px",
      position: "relative",
      padding: "12px",
      cursor: "pointer",
    },
    cccb_ef_d_db_efbfbd: {
      fontSize: "14px",
      lineHeight: 1,
      marginBottom: 4,
    },
    ed_c_f_be_ebccac: {
      color: UIColor.tertiaryText.color,
      fontSize: "12px",
      letterSpacing: 0.1,
      lineHeight: 1,
    },
    RrEm: {
      color: UIColor.secondaryColor.color,
      backgroundColor: UIColor.secondaryColor.color, //"#F58B000D",
      borderColor: UIColor.secondaryColor.color, //"#F58B000D",
      marginTop: "-22px",
      marginRight: "8px",
      fontSize: "12px",
      position: "absolute",
      right: "0",
      padding: "0px 6px",
    },
    RrEm1: {
      borderRadius: "3px",
      fontSize: "12px",
      outline: "none",
      cursor: "pointe",
      padding: "6px",
      color: "#fff",
      backgroundColor: "#3f51b5",
      border: "1px solid #3f51b5",
      margin: "auto",
      marginRight: "10px"
    },
    Hdva1: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "auto",
      borderColor: UIColor.primaryColor.color,
      borderStyle: "solid",
      borderRadius: "8px",
      color: "#fff",
      fontSize: "14px",
      marginTop: "16px",
      padding: "8px 16px",
      marginBottom: "16px",
      height: "40px",
      background: UIColor.primaryColor.color,
    },
}));
