import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
    Div,
    Paper,
    H6,
    Divider,
    Row,
    Col,
    Button,
    Image,
    SideMenu,
} from "qdm-component-library";
import "./navBar.css";
//import { withRouter } from "react-router-dom";
import { Popover, makeStyles, Typography, Modal } from "@material-ui/core";
import UploadFiles from "./uploadFiles";
import { AppRoutes } from "../../router/routes";
import { useNavigate } from "react-router-dom";
import { DrawerMeetingContext } from "../../context";
import Loading from "../global/loading";
import uploadIcon from "../../assets/img/svg/icons8-upload.svg";
import backIcon from "../../assets/img/svg/icons8-left.svg";
import { isFullScreen } from "../../utils";
import rightArrowIcon from "../../assets/img/svg/icons8-right.svg";
import CancelBookingPage from "../../components/queueCard/cancelBooking";
import FileIcon from '../../assets/img/svg/icons8-file.svg';

const useStyles = makeStyles((theme) => ({
    muiPopovers: {
        boxShadow: "0px 25px 50px #00000029",
        borderRadius: 8,
        marginTop: 10,
        padding: 10,
    },
    mykadbtn: {
        fontFamily: "pc_medium",
        fontSize: "14px !important",
        color: "#6F6F6F !important",
        backgroundColor: "#f2f2f2 !important",
    },
}));

export const ModifiedTopNavbar = (props) => {
    const {
        sucessBtnName,
        navBarArr = [],
        title,
        btn = "Register & continue",
        noUpload,
        successfun,
        activeId = 1,
        navClick = () => null,
        putOnHold,
        parent_id,
        isRegisterFromMyKad,
        fetchMyKad = () => { },
    } = props;
    const navigate = useNavigate();

    const [isUpload, setIsUpload] = React.useState(null);
    const [files, setFiles] = React.useState([]);
    const [viewfiles, setviewFiles] = React.useState([]);
    const AVCMeeting = React.useContext(DrawerMeetingContext);

    useEffect(() => {
        setviewFiles(props?.initialFiles);
    }, [props?.initialFiles]);

    const redirectLink = (data) => {
        navClick(data.id);
    };

    const uploadFun = (e) => {
        setIsUpload(e?.currentTarget);
    };
    const goback = () => {
        navigate(-1)
        };
    const uploadDocument = (files, viewfiles) => {
        setFiles(files)
        if (viewfiles !== undefined) {
            setviewFiles(viewfiles)
        }
        if (props?.setFiles) {
            props?.setFiles(files)
            props?.setviewFiles(viewfiles);
        }
    };
    const classes = useStyles();
    const handleKadOpen = () => {
        props.handleKad();
        fetchMyKad();
    };
    return (
        <div id={`${parent_id}_ModifiedTopNavbar_parent_div`} style={{ height: "56px" }}>
            <Paper
                id={`${parent_id}_ModifiedTopNavbar_parent_paper`}
                style={{
                    margin: "0px",
                    // position: "fixed",
                    zIndex: "999",
                    background: "white",
                    boxShadow: "0px 3px 6px #0000000A",
                }}
            >
                <Row
                    id={`${parent_id}_ModifiedTopNavbar_parent_row`}
                    className={
                        navBarArr.length > 0 ? "topNav-top-Parent" : "topNav-top-Parent1"
                    }
                >
                    <Col id={`${parent_id}_ModifiedTopNavbar_parent_log_col`}>
                        <Div
                            id={`${parent_id}_ModifiedTopNavbar_parent_log_div`}
                            className={"topNav-parent"}
                        >
                            {/* <i onClick={() => goback()} className="fa fa-angle-left topNav-backIcon" aria-hidden="true"></i> */}
                            {props.isBackIcon && (
                                <Div
                                    id={`${parent_id}_ModifiedTopNavbar_log_sub_div`}
                                    className="uploadIcon"
                                    onClick={() => goback()}
                                >
                                    <Image
                                        id={`${parent_id}_ModifiedTopNavbar_log_image`}
                                        src={backIcon}
                                        alt="left"
                                    />
                                </Div>
                            )}
                            <H6
                                id={`${parent_id}_ModifiedTopNavbar_title_h6`}
                                className={"topNav-title"}
                                style={{
                                    width: props.billView ? "180px" : props?.iframe ? "350px" : "400px",
                                }}
                            >
                                {/* {props.parent_edit_id ? "Modify Patient Details" : title} */}
                                {props.parent_edit_id
                                    ? "Modify Patient Details"
                                    : props.parent_View_id
                                        ? title //"Patient Details"
                                        : title}
                            </H6>
                            {navBarArr.length > 0 ? (
                                <Divider
                                    id={`${parent_id}_ModifiedTopNavbar_title_rightside_divider`}
                                    className={"topNav-divider"}
                                    orientation="vertical"
                                />
                            ) : (
                                <></>
                            )}
                        </Div>
                    </Col>
                    <Col id={`${parent_id}_ModifiedTopNavbar_title_col`}>
                        {navBarArr.map((val, i) => {
                            return (
                                <p
                                    id={`${parent_id}_${i}_ModifiedTopNavbar_title_label_p`}
                                    //onClick={() => redirectLink(val)}
                                    className={`${activeId === val.id ? "topNav-active" : ""
                                        } topNav-tabs`}
                                >
                                    {val.label}
                                    {activeId === val.id && (
                                        <Div
                                            id={`${parent_id}_${i}_ModifiedTopNavbar_title_bottomdivider_div`}
                                            className="bottomDivider"
                                        ></Div>
                                    )}
                                </p>
                            );
                        })}
                    </Col>
                    {props?.sucessAndCancel && (
                        <Col
                            id={`${parent_id}_ModifiedTopNavbar_buttons_div`}
                            className={`topNav-actions-parent`}
                        >
                            <Button
                                id={`${parent_id}_ModifiedTopNavbar_Register_continue_button`}
                                className={"topNav-actions successBtn"}
                                disabled={props.view}
                                // onClick = {() => {props.handleEditPatient()}}
                                onClick={
                                    successfun
                                        ? () => {
                                            successfun(files);
                                        }
                                        : () => null
                                }
                            >
                                {btn === "Registering..." ? "Saving..." : "Save Changes"}
                            </Button>
                            <Button
                                id={`${parent_id}_ModifiedTopNavbar_cancel_button`}
                                variant="text"
                                className={"topNav-actions cancelBtn"}
                                onClick={async () => {
                                    await goback();
                                }}
                                disabled={props.view}
                            >
                                Cancel
                            </Button>


                            {props?.isUpload && <Div
                                id={`${parent_id}_ModifiedTopNavbar_upload_div`}
                                className="uploadIcon"
                                style={{ margin: "0px" }}
                            >
                                <Image
                                    id={`${parent_id}_ModifiedTopNavbar_upload_image`}
                                    src={viewfiles?.length > 0 ? FileIcon : uploadIcon}
                                    alt="upload"
                                    onClick={(e) => uploadFun(e)}
                                    className=""
                                />
                            </Div>}

                        </Col>
                    )}
                    {props?.ApproveNRejectNHold && <Col
                        id={`${parent_id}_ModifiedTopNavbar_buttons_div`}
                        className={`topNav-actions-parent`}
                    >
                     <Button
                            id={`${parent_id}_ModifiedTopNavbar_put_on_hold_button`}
                            variant="outlined"
                            className={"topNav-actions topNav-actions-empty"}
                            onClick={() => {
                                props?.handleReject()

                            }}
                        // disabled={props.view}
                        >
                            Reject
                        </Button>
                        <Button
                            id={`${parent_id}_ModifiedTopNavbar_put_on_hold_button`}
                            variant="outlined"
                            // disabled={props.view}
                            className={"topNav-actions topNav-actions-empty"}
                            // onClick={props?.handleSideMenu}
                            onClick={() => {
                               props?.handleHold()
                            }}
                        >
                            ON Hold
                        </Button>
                       
                        
                        <Button
                            id={`${parent_id}_ModifiedTopNavbar_Register_continue_button`}
                            className={"topNav-actions successBtn_"}
                            disabled={btn === "Cancelling" ? true : false}
                            onClick={props?.TransferFunc}
                        >
                            {"Transfer"}
                        </Button>
                        <Button
                            id={`${parent_id}_ModifiedTopNavbar_Register_continue_button`}
                            className={"topNav-actions successBtn_"}
                            disabled={btn === "Cancelling" ? true : false}
                            onClick={props?.approveStatus}
                        >
                            {"Approve"}
                        </Button>
                       
                       

                    </Col>}
                    {props?.isFinish && <Col
                        id={`${parent_id}_ModifiedTopNavbar_buttons_div`}
                        className={`topNav-actions-parent`}
                    >
                        <Button
                            id={`${parent_id}_ModifiedTopNavbar_Register_continue_button`}
                            className={"topNav-actions successBtn_"}
                            disabled={btn === "Cancelling" ? true : false}
                            onClick={props?.isFinishFunc}
                        >
                            {"Finish"}
                        </Button>
                    </Col>}
                    {props?.isMyKad &&
                        (<>
                            <Divider
                                id={`${parent_id}_ModifiedTopNavbar_leftside_divider`}
                                className={"topNav-divider topNav-divider-actions"}
                                orientation="vertical"
                            />
                            <Div>
                                <Button
                                    id={`${parent_id}_ModifiedTopNavbar_mykad_button`}
                                    variant="text"
                                    className={`${classes.mykadbtn} topNav-actions`}
                                    onClick={handleKadOpen}
                                    disabled={props.view}
                                >
                                    MyKad
                                </Button>
                            </Div>
                        </>)
                    }

                </Row>
                <Popover
                    anchorEl={isUpload}
                    open={Boolean(isUpload)}
                    onClose={() => uploadFun(null)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    style={{ zIndex: 99999 }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    classes={{ paper: classes.muiPopovers }}
                >
                    <UploadFiles
                        id={`${parent_id}_ModifiedTopNavbar_upload_uploadfiles`}
                        uploadDocument={uploadDocument}
                        files={files}
                        viewfiles={viewfiles}
                        isViewMode={props.isViewMode}
                    />
                </Popover>
                <Modal
                    id={`${parent_id}_ModifiedTopNavbar_mykad_modal`}
                    width={1000}
                    open={props.kadOpen}
                    className="docc-modal"
                    onOpen
                // onClose={() => setMyKadModalOpen(false)}
                >
                    <Div
                        style={{
                            borderRadius: 16,
                            background: "#fff",
                            marginTop: 30,
                            paddingTop: 75,
                            paddingBottom: 75,
                            paddingLeft: 25,
                            paddingRight: 25,
                            width: 1000,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: 400,
                            bgcolor: "background.paper",
                            boxShadow: 24,
                            p: 4,
                        }}
                    >
                        <Loading
                            loaderStyle={{ width: 60, height: 60 }}
                            style={{ marginBottom: 40 }}
                            loading={true}
                        />
                        <Div>
                            <Typography style={{ fontSize: 20, color: "#001C3C" }}>
                                Fetching data from MyKad
                            </Typography>
                        </Div>
                    </Div>
                </Modal>
                <SideMenu
                    id="patient_dashboard_cancel_sidemenu"
                    open={props?.isSideMenuOpen}
                    direction="right"
                    width={440}
                    color="default"
                    onClose={props?.handleSideMenuclose}
                    style={{ padding: 0 }}
                >
                    <CancelBookingPage
                        {...props}
                        timer={()=>null}
                       />
                </SideMenu>
            </Paper>
        </div>
    );
};

ModifiedTopNavbar.propTypes = {
    btn: PropTypes.string,
    title: PropTypes.string,
    withBackButton: PropTypes.bool,
    navBarArr: PropTypes.array,
    titleIcon: PropTypes.element,
    onTitleIconClicked: PropTypes.func,
};

ModifiedTopNavbar.defaultProps = {
    withBackButton: false,
};

export default ModifiedTopNavbar;
