import { Grid, Paper, Typography } from '@mui/material'
import React from 'react'

const DiagnosisCpg = ({diagnosis}) => {
  return (
    <>
    {diagnosis &&
      diagnosis.map((dia) => (
        // <Paper elevation={0} sx={{ marginTop: "5px" }}>
          <Grid container xs={12} marginTop={1}>
            <Grid item xs={12}>
              <Typography variant="caption" fontSize={"12px"} fontWeight={600} pl={0.8}>
                {" "}
               {dia?.diagnosisdescription}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" fontSize={"12px"}>
                {" "}
                🔹 {dia?.use} 🔹 {dia?.onsetdate} 🔹{" "}
                {dia?.verificationstatus}
              </Typography>
            </Grid>
          </Grid>
        // </Paper>
      ))}
  </>
  )
}

export default DiagnosisCpg