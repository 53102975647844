import { dbName, getFacilityId, getGlobalValueFrom_LclStrg_And_QryStrg } from "../../../utils";
import moment from "moment";
//import jwtDecode from "jwt-decode";

export const childJSON = (dataList, editIds, edit, vId, oltype) => {
  //let token = jwtDecode(localStorage.getItem("UserInfo"));
  let payload = dataList.map((data, i) => {
    let extraJson = {};
    let filter = {};
    if (edit && editIds) {
      extraJson = {
        _id: editIds,
      };

      filter = {
        filter: {
          // "encounter_Id": data?.encounter_Id,
          // "Oltype": "LAB",
          // "parentOrderId": editIds,
          _id: editIds,
        },
      };
    }
    return {
      db_name: dbName,
      entity: "CA_OrderLine",
      is_metadata: true,
      ...filter,
      metadataId: process.env.REACT_APP_METADATAID,
      metadata_dbname: process.env.REACT_APP_METADATA_DBNAME,
      doc: {
        ...extraJson,
        id: 12,
        facilityid: getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"facilityid"}), //token?.facilityid,
        tenantid:  "1234", //token?.tenantid,
        Oltype: oltype,
        // "orgEntityType": 0,
        org_Id: data?.Organization_id,
        patient_Id: data.patient_id,
        encountertype: "",
        encounter_Id: data?.encounter_Id,
        order_Id: "",
        orderSetCode: "",
        orderCatalog: data?.orderCatalog ?? "",
        //orderCategory: data?.orderSetCode,
        OrderType: data?.OrderType,
        OrderNature: data?.OrderNature,
        orderMode: data?.orderMode,
        priority: data?.priority,
        orderDateTime: data?.orderDateTime,
        occuranceStartDate:
          vId > 0
            ? !edit
              ? getActualDate(vId, data?.starts_on)
              : vId
            : data?.occuranceStartDate,
        occuranceEndDate: data?.occuranceEndDate ?? 0,
        orderQty: data?.orderQty ?? 0,
        orderUOM: "",
        status: data?.status ?? "",
        intent: "",
        workflowStatus: "",
        // billQty: 0,
        billQty: data?.orderQty ?? 0,
        orderPrivilegeLevel: 0,
        apptDate: !edit ? getActualDate(vId, data?.starts_on) : vId,
        apptRefNum: "",
        Requestor: [...data?.Requestor],
        reasonCode: data?.reasonCode ?? "",
        diagnosis: [...data?.diagnosis],
        indications: data?.indications ?? "",
        consentReqd: false,
        freqValue: 0,
        freqValueUnit: "",
        freqRate: 0,
        freqRateUnit: "",
        duration: 0,
        durationType: "",
        parentOrderId: edit ? data?.parentOrderId : editIds ? editIds : "",
        orderGroupRefId: "",
        performer: [...data?.performer],
        specimenRelated: [
          {
            reqd: data.specimenRelated[0].reqd,
            type: data.specimenRelated[0].type,
            volume: parseInt(data.specimenRelated[0].volume),
            volumeUOM: data.specimenRelated[0].volumeUOM,
            collectionMethod: data.specimenRelated[0].collectionMethod,
            container: data.specimenRelated[0].container,
            bodySite: data.specimenRelated[0].bodySite,
            FastingPeriod: parseInt(data.specimenRelated[0].FastingPeriod),
            FastingPeriodUOM: data.specimenRelated[0].FastingPeriodUOM,
          },
        ],
        notes: data?.notes ?? "",
        patientInstruction: [...(data?.patientInstruction || [])],
        recurrent: [
          {
            every_date: data?.every_date,
            every_days: data?.every_days,
            starts_on: data?.starts_on,
            //starts_on_days: data?.starts_on_days,
            // startes_on_occurance_date: data?.startes_on_occurance_date,
            //startes_on_occurance: data?.startes_on_occurance,
            end_on_occurance_date: data?.startes_on_occurance_date,
            end_on_occurance: data?.startes_on_occurance,
            reoccurance_id: "",
          },
        ],
        preparatoryInstruction: [...(data?.preparatoryInstruction || [])], //--
        departmentalInstruction: [...(data?.departmentalInstruction || [])], //--
        attribute: {
          view: data?.attribute?.view ?? "",
          bedSide: data?.attribute?.bedSide ?? false,
          contrast: data?.attribute?.contrast ?? "",
        },
        recurrentList: [...data?.recurrentList],
        statushistory: [...(data.statushistory || [])],
        statusreason: data?.statusreason ?? "",
        accessionNum: data?.accessionNum ?? "",
      },
    };
  });

  return payload;
};

const getActualDate = (incomingDate, actualDate) => {
  const actualFormat = moment.unix(actualDate).format("YYYY-MM-DD,hh:mm A");
  const incomingFormat = moment.unix(incomingDate).format("YYYY-MM-DD,hh:mm A");
  const requiredTime = actualFormat.split(","); //Got the time
  const requiredDate = incomingFormat.slice(0, 11);
  const requiredFormat = moment(
    requiredDate + requiredTime,
    "YYYY-MM-DD,hh:mm A"
  ).unix();
  return requiredFormat;
};
