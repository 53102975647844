import { Grid, Typography } from "@mui/material";
import React from "react";
import { HeartPluseIcon } from "../../../components";
import { WeightKgIcon } from "../../../components";
import { HeightsIcon } from "../../../components";
import { BloodSamplesIcon } from "../../../components";
import { Respiration } from "../../../components";
import { TemperatureIcon } from "../../../components";
import { Bsa } from "../../../components";
import { BmiIcon } from "../../../components";
import { Spo2 } from "../../../components";

const VitalSignsCpg = ({ vitalSigns }) => {
  const icons = [
    <Bsa height="20" width="20"/>,
    <BmiIcon height="16" width="16" />,
    <WeightKgIcon />,
    <HeightsIcon />,
    <BloodSamplesIcon />,
    <BloodSamplesIcon />,
    <HeartPluseIcon />,
    <Respiration />,
    <Spo2 />,
    <TemperatureIcon />,
  ]
  return (
    <Grid container gap={1}>
      {vitalSigns && vitalSigns.map((vital,i) => (
        <Grid item>
          {icons[i]}
          <Typography variant="caption" fontSize={"12px"} ml={0.5}>
            {vital.vitalvalue}&nbsp;{vital.vitalunit}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default VitalSignsCpg;
