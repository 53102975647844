import React, { useContext, useEffect, useState } from "react";
import TableComponent from "../../cashierBillingDetails/components/tableComponent";
import actions from "../../../redux/actions";
import { AlertProps, AxiosCall, urls } from "../../../utils";
import { useDispatch } from "react-redux";
import { AlertContext } from "../../../context";
import { Button, H6, TextInput } from "qdm-component-library";
import { UIColor } from "../../../themes/theme";
import axios from "axios";
import jwtDecode from "jwt-decode";

const dataType = [
  { name: "checked", type: "CHECKBOX", header: "All" },
  { name: "", type: "INCREMENT", header: "S.No" },
  { name: "billno", type: "TEXT", header: "scroll" },
  { name: "billdate", type: "EPOCH_DATE_DISPLAY", header: "scroll" },
  { name: "billhstatus_Display", type: "TEXT", header: "scroll" },
  { name: "bilamt", type: "TEXT", header: "scroll" },
  { name: "patientshare", type: "TEXT", header: "scroll" },
  { name: "creditshare", type: "TEXT", header: "scroll" },
  { name: "paidamount", type: "TEXT", header: "scroll" },
  { name: "", type: "ACTION", header: "scroll" },
  //{ name: "", type: "DELETE", header: "scroll" },
];


const ViewBill = (props) => {
  const userInfo = localStorage.getItem("UserInfo");
  const decodedUserinfo = jwtDecode(userInfo);
  const practitionerId = decodedUserinfo?.practitionerid;
  const roleid = decodedUserinfo?.roleid;
  const [cancel, setCancel] = useState({
    view: false,
    value: "",
  });

  const Header = [
    "All",
    "S.No",
    "Bill No.",
    "Bill Date",
    "Bill Status",
    "Bill Amount "+ `(${decodedUserinfo?.currencyinfo?.currencysymbol || decodedUserinfo?.currencyinfo?.currencycode})`,
    "Patient Share "+ `(${decodedUserinfo?.currencyinfo?.currencysymbol || decodedUserinfo?.currencyinfo?.currencycode})`,
    "Credit Share "+ `(${decodedUserinfo?.currencyinfo?.currencysymbol || decodedUserinfo?.currencyinfo?.currencycode})`,
    "Paid Amount "+ `(${decodedUserinfo?.currencyinfo?.currencysymbol || decodedUserinfo?.currencyinfo?.currencycode})`,
    "Action",
    //"Delete",
  ];
  const [allcheck, setAllCheck] = useState(false);
  const [checkedData, setCheckData] = useState([]);
  const [billData, setBilldata] = useState([]);
  useEffect(() => {
    setBilldata(
      JSON.parse(JSON.stringify(props?.billData?.[0]?.bill ?? [])) || []
    );
  }, [props?.billData?.[0]?.bill]);
  const dispatch = useDispatch();

  const alert = useContext(AlertContext);
  const handleCheckBox = (e, value, index) => {
    let recdata = billData;
    let target = recdata[index];
    if (e.target.checked) {
      setCheckData([...checkedData, value]);
      target["checked"] = true;
      recdata[index] = target;
      setBilldata(recdata);
    } else {
      setCheckData((prev) =>
        prev.filter((v) => v?.billhdid !== value?.billhdid)
      );
      target["checked"] = false;
      recdata[index] = target;
      setBilldata(recdata);
    }
  };
  const handleHeaderCheckBox = (e, value) => {
    let recdata = billData;
    if (e.target.checked) {
      setCancel({ ...cancel, view: true });
      setAllCheck(true);
      setCheckData(billData);
      setBilldata(
        recdata.map((v) => {
          v["checked"] = true;
          return v;
        })
      );
    } else {
      setAllCheck(false);
      setCheckData([]);
      setBilldata(
        recdata.map((v) => {
          v["checked"] = false;
          return v;
        })
      );
    }
  };
  const handleDeleteSelected = (e, value) => {
    setCancel({ ...cancel, view: true });
  };
  const handleSelectedPrint = (e, value, index) => {
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };
    const payload = {
      reportid: process.env.REACT_APP_BILL_RECEIPT_REPORT_ID,
      inputparams: {
        "@billhdrid": value?.billhdid,
        "@loginpract": practitionerId
      },

      result: [],
    };
    AxiosCall("post", urls.jasperReport, payload, header)
      .then(async (res) => {
        const response = await axios.get(res?.downloadUrl, {
          method: "GET",
          responseType: "blob",
        });
        const pdfBlob = new Blob([response.data], {
          type: "application/pdf",
        });
        const pdfDataUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfDataUrl, "_blank");
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          msg: "No data found for the given inputs",
          severity: "error",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      });
  };   
  const getEpochTime = (date) => Math.floor(date.getTime() / 1000);
  const currentDate = new Date();
  const handlesubmit = async (value) => {
    if (!cancel?.value?.length > 0) {
      alert.setSnack({
        ...alert,
        open: true,
        msg: "Please fill the cancellation reason",
        severity: "error",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    } else {
       let payload =[];
      checkedData.forEach((v) => {
        payload.push({
          billhdrid: v?.billhdid,
          billno: v?.billno,
          cancelreason: cancel?.value,
          billcanceldate:getEpochTime(currentDate),
        });
      });
      let data = await dispatch(actions.TRANSACTION_DELETE(payload));

      if (data?.payload?.data?.[0]?.error || data?.payload?.error) {
        alert.setSnack({
          ...alert,
          open: true,
          msg: data?.payload?.data?.[0]?.message || data?.payload?.message,
          severity: "error",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      } else {
        props?.GetBillAndReceipt(props?.billData?.[0]?.appoinmentid);
        props?.getOutstanding();
        alert.setSnack({
          ...alert,
          open: true,
          msg: "cancelled successfully",
          severity: "success",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        setCancel({
          view: false,
          value: "",
        });
        setCheckData([]);
      }
    }
  };
  return (
    <div
      style={{
        padding: "0px 20px 20px 20px",
        width: "100%",
        margin: "auto",
        position: "relative",
      }}
    >
      <div style={{ maxHeight: "60vh", overflow: "auto" }}>
        <TableComponent
          tableData={billData}
          Header={Header}
          dataType={dataType}
          handleCheckBox={handleCheckBox}
          handleHeaderCheckBox={handleHeaderCheckBox}
          allChecked={allcheck}
          handleSelectedPrint={handleSelectedPrint}
        />
      </div>
      
      {props?.permissionData?.["component"]?.["cancelbill"]?.permission?.write === true && !props?.isTransitType && (
      <>
        <div style={{ margin: "20px 0px" }}>
          <H6
            className={"pc_regular"}
            style={{
              color: "#646464",
              fontSize: 12,
              margin: 0,
              marginBottom: 5,
            }}
          >
            Cancel Reason <span style={{ color: "red" }}>*</span>
          </H6>

          <TextInput
            value={cancel?.value}
            onChange={(evt) => setCancel({ ...cancel, value: evt.target.value })}
            variant={"outlined"}
            hoverColor={UIColor.primaryColor.color}
            placeholder="Add reason"
            style={{
              borderRadius: 8,
              borderColor: UIColor.lineBorderFill.color,
              height: 36,
              padding: "5px 10px",
            }}
            isRequired
          />
        </div>
      
        <Button
          name={"Cancel Bill"}
          type="button"
          className="pc_medium"
          variant="outlined"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: "20px",
            fontSize: "14px",
            padding: "8px 16px",
            marginBottom: "10px",
            backgroundColor: UIColor?.primaryColor?.color,
            border: "none",
            borderRadius: 8,
          }}
          onClick={handlesubmit}
        ></Button>
      </>
      )}
    </div>
  );
};

export default ViewBill;
