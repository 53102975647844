import React, { useContext } from "react";
import qdmstyles from "./styles2.json";
import moment from "moment";

import {
  Container,
  H6,
  Divider,
  Div,
  Avatar,
  Text,
  Icons,
  Card,
  OtpInput,
  Button,
  TapSelect,
} from "qdm-component-library";
import {
  checkError,
  AlertProps,
  getMobileNumber,
  upsertPerson,
  getTenantId,
  getFacilityId,
  calculateAgeInDays,
} from "../../../utils";
import { AlertContext } from "../../../context";
import { useState } from "react";
import { UIColor } from "../../../themes/theme";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import actions from "../../../redux/actions";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

export const useStyles = makeStyles((theme) => ({
  tapSelect: {
    "& .selectionDiv": {
      height: 40,
      width: 70,
      borderRadius: 8,
      marginRight: 4,
      marginTop: 8,
      "&:last-of-type": {
        marginRight: 0,
      },
      "& span": {
        fontFamily: "pc_regular",
        fontSize: 14,
      },
    },
    "& .active": {
      "& span": {
        fontFamily: "pc_medium !important",
      },
    },
  },
}));
function AppointmentPageDrawerOtpSection(props) {
  const classes = useStyles();
  const alert = useContext(AlertContext);
  const dispatch = useDispatch();
  const signUp = useSelector((state) => state.PreAss_AuthSlice.preAss_signUp);
  console.log({signUp})
  const [state, setState] = useState({
    otp: "",
    genderValue: "",
    firstNameValue: "",
    resend: "",
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };
  console.log("data", props?.signUpData);
  const handleSubmit = async () => {
    //debugger;
    const { otp } = state;
    const addmmber = props;
    if (addmmber) {
      // const checkmmr = await props.SIGN_IN({
      //   mobileNo: getMobileNumber(props.signUpData?.mobileNo),
      //   otp,
      // });
      // if (checkmmr.payload.data.error === "invalid_grant") {
      //   alert.setSnack({
      //     open: true,
      //     severity: AlertProps.severity.error,
      //     msg: checkmmr.payload.data.error,
      //     vertical: AlertProps.vertical.top,
      //     horizontal: AlertProps.horizontal.center,
      //     tone: true,
      //   });
      //   return false;
      // } else {
      //   let userId = isNaN(Number(localStorage.getItem("idval")))
      //     ? 0
      //     : Number(localStorage.getItem("idval"));
      //   const relatedInput = {
      //     phone: getMobileNumber(props.signUpData?.mobileNo),
      //     dob: props.signUpData?.dob,
      //     firstName: props.signUpData?.firstName,
      //     lastName: props.signUpData?.lastName,
      //     gender: props.signUpData?.gender,
      //     email: props.signUpData?.emailId || "",
      //     relationType: state.genderValue,
      //     relationPersonId: [userId],
      //     personId: props.signUpData?.id,
      //   };

      //   await props.ADD_RELATED_PERSON(relatedInput);
      //   // if(addmmrss){
      //   //   console.log('p')
      //   // }
       if (otp === "123456") {
             let details = props.signUpData;
        console.log({ details });
        let data = {
          name: [
            {
              use: "",
              title: "",
              lang: "en",
              firstname: details?.firstName,
              mldname: "",
              lstname: details?.lastName,
              prefix: "",
              start: 0,
              end: 0,
              fourthname: "",
              fulname: `${details?.firstName}${
                details?.lastName ? " " + details?.lastName : ""
              }`,
            },
          ],
          telecom: [
            {
              system: process.env.REACT_APP_MOBILE_TYPE_CODING,
              value:
                "+".concat(details?.mobileNo?.countryData?.callingCodes," ",                details?.mobileNo?.mobileNo,
                ),
              use: "",
              rank: 1,
              start: 0,
              end: 0,
            },
            {
              system: process.env.REACT_APP_EMAIL_TYPE_CODING, //FIXME -
              value: details?.emailId,
              use: "",
              rank: 2,
              start: 0,
              end: 0,
            },
          ],
          gender: details?.gender,
          birthdate: dayjs(details?.dob, "DD-MM-YYYY").format("DD/MM/YYYY"),
          age: dayjs()
            .diff(dayjs(details?.dob, "DD-MM-YYYY"), "day")
            .toString(), //FIXME -
          photo: [
            {
              reftype: "person photo",
              fileName: details?.image?.file?.name,
              fileid: "",
              filetype: details?.image?.file?.type.split("/")?.[1],
              base64string: details?.image?.url.split(",")[1],
            },
          ],
          _id: "",
          age_type: process.env.REACT_APP_DOB_TYPE_DAYS_CODING, //FIXME -
          tenantid: getTenantId()||"1234",
          facilityid: getFacilityId(),
        };
        try {
          let result = await dispatch(actions.PRE_ASS_SIGN_UP(data));
          if (result?.payload?.data?.[0]?.error) {
            alert.setSnack({
              ...alert,
              open: true,
              msg:
                result?.payload?.data?.[0]?.message || "something went wrong !",
              severity: "error",
            });
          } else {
            alert.setSnack({
              ...alert,
              open: true,
              msg:
                result?.payload?.data?.[0]?.message || "user created Successfully !",
              severity: "success",
            });
            props.nextSection("member_succss", props.signUpData);
          }
        } catch (error) {
          alert.setSnack({
            ...alert,
            open: true,
            msg:
              error.message || "something went wrong!",
            severity: "error",
          });
        }

       
      } else {
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "OTP is incorrect!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      }
    }

   
    // }

    // await props.SIGN_IN({
    //   mobileNo: `+${
    //     props.signUpData?.mobileNo?.countryData?.callingCodes ?? "00"
    //   }${props.signUpData?.mobileNo.mobileNo}`,
    //   otp,
    // });
    // const status = checkError(props.signIn);
    // if (!status.isError) {
    //   const { mobileNo, firstName, lastName, dob, gender, emailId } =
    //     props?.signUpData;
    //   const guestBookerAdd = {
    //     phone: getMobileNumber(mobileNo),
    //     dob,
    //     firstName,
    //     lastName,
    //     gender,
    //     email: emailId,
    //   };
    //   if (props?.signIn?.data?.access_token) {
    //     localStorage.removeItem("guestInfo");
    //     localStorage.setItem("token", props?.signIn?.data?.access_token);
    //   }
    //   const { error, data } = await upsertPerson(
    //     props.READ_PERSON,
    //     props.UPSERT_PERSON,
    //     guestBookerAdd
    //   );
    //   if (error && data.includes("User Already Exists ")) {
    //     alert.setSnack({
    //       open: true,
    //       severity: AlertProps.severity.error,
    //       msg: "Member Already Exists. Please try Sign Up using existing member",
    //       vertical: AlertProps.vertical.top,
    //       horizontal: AlertProps.horizontal.center,
    //       tone: true,
    //     });
    //   } else if (error) {
    //     alert.setSnack({
    //       open: true,
    //       severity: AlertProps.severity.error,
    //       msg: data,
    //       vertical: AlertProps.vertical.top,
    //       horizontal: AlertProps.horizontal.center,
    //       tone: true,
    //     });
    //     setState({
    //       ...state,
    //       error: status.errMsg,
    //     });
    //   } else {
    //     const personId = data.payload?.data?.id || 0;
    //     localStorage.setItem("idval", personId);
    //     props.nextSection("movenextscreen");
    //   }
    // } else {
    //   setState({
    //     ...state,
    //     error: status.errMsg,
    //   });
    // }
  };

  const getAge = (date) => {
    return (
      Math.floor(
        moment(new Date()).diff(moment(date, "DD/MM/YYYY"), "years", true)
      ) + "Yrs"
    );
  };
  const resendOTP = async () => {
    const res = await props.RESEND_OTP({
      mobileNo: `+${
        props.signUpData?.mobileNo?.countryData?.callingCodes ?? "00"
      }${props.signUpData?.mobileNo.mobileNo}`,
    });
    const status = checkError(props.resendOTP);
    if (status.isError) {
      // context.setSnack({
      //   open: true,
      //   severity: AlertProps.severity.error,
      //   msg: status.errMsg,
      //   vertical: AlertProps.vertical.top,
      //   horizontal: AlertProps.horizontal.center,
      //   tone: true
      // })
    } else {
      setState({
        ...state,
        resend: res?.payload?.data?.message,
      });
    }
  };

  const { appointmentDetails, addmmber, parent_id } = props;
  return (
    <div id={`${parent_id}_addmember_otp_parent_div`}>
      <Container
        id={`${parent_id}_addmember_otp_parent_container`}
        key={"0"}
        style={{ padding: "16px 24px" }}
      >
        <Div
          style={addmmber ? qdmstyles.de_da__c_cd : {}}
          id={`${parent_id}_addmember_otp_title_parent_div`}
        >
          <H6
            id={`${parent_id}_addmember_otp_title_text`}
            key={"0"}
            name={
              appointmentDetails
                ? "Add Member"
                : props.signUpData?.existingPerson
                ? "Sign Up as Existing Member"
                : "Sign Up as New Member"
            }
            style={{
              fontSize: "20px",
              display: "",
              color: UIColor.primaryText.color,
              letterSpacing: 0,
              lineHeight: 1,
              margin: 0,
            }}
            //!addmmber ? qdmstyles.dNMp : qdmstyles.dNMp1}
            className={"pc_semibold"}
          ></H6>
          {addmmber && (
            <Div key={"1"} id={`${parent_id}_addmember_otp_back_text_div`}>
              <H6
                className="pc_medium"
                onClick={() => props.nextSection("back")}
                style={{
                  color: UIColor.primaryColor.color,
                  fontSize: "14px",
                  letterSpacing: 0,
                  margin: 0,
                  cursor: "pointer",
                }}
              >
                Back
              </H6>
              {/* <Button
                  id={`${parent_id}_addmember_otp_back_button`}
                  key={"0"}
                  className="pc_medium"
                  name={"Back"}
                  style={qdmstyles.b__ae_ba_fcfdfc}
                  onClick={() => props.nextSection("back")}
                ></Button> */}
            </Div>
          )}
        </Div>
        <Divider
          id={`${parent_id}_addmember_otp_title_bottom_divider`}
          key={"1"}
          style={{ margin: "16px 0px" }}
        ></Divider>

        <Div
          id={`${parent_id}_addmember_otp_memberlisting_parent_div`}
          key={"2"}
          className={"qdm"}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#fff",
            border: "1px solid #DEE5EC",
            borderRadius: "10px",
            borderStyle: "solid",
            padding: "10px",
          }}
        >
          <Div
            id={`${parent_id}_addmember_otp_memberlisting_sublevel_div`}
            key={"0"}
            className={"qdm"}
            style={qdmstyles.nilbd}
          >
            <Avatar
              id={`${parent_id}_addmember_otp_memberlisting_sublevel_avatar`}
              key={"0"}
              variant={"circle"}
              letter={"Avatar"}
              src={
                props.signUpData?.image?.url ||
                "https://i.pravatar.cc/150?img=65"
              }
              alt={"Image"}
              // style={qdmstyles.ddd_dc_c_ee_dbb}
              style={{
                margin: "0px",
                marginRight: "12px",
                height: "40px",
                width: "40px",
              }}
            ></Avatar>

            <Div key={"1"} id={`${parent_id}_addmember_otp_memberlisting_name`}>
              <H6
                key={"0"}
                name={`${props.signUpData?.firstName} ${
                  props?.signUpData?.middleName || ""
                } ${props.signUpData?.lastName || ""}`}
                style={{
                  fontSize: "14px",
                  fontWeight: 600,
                  color: UIColor.primaryText.color,
                }}
                className={"pc_medium"}
              />

              <Text
                id={`${parent_id}_addmember_otp_memberlisting_discription`}
                key={"1"}
                // name={`${getAge(props.signUpData?.dob)} ${props.signUpData?.gender
                // }, +${props.signUpData?.mobileNo?.countryData
                //   ?.callingCodes ?? "00"
                // }${props.signUpData?.mobileNo?.mobileNo ?? "XXXXXXXXX"
                // }, ${props.signUpData?.emailId}`}
                name={`${getAge(props.signUpData?.dob)} ${
                  props.signUpData?.gender
                }, 
                    ${
                      props.signUpData?.mobileNo?.mobileNo?.includes("+")
                        ? props.signUpData?.mobileNo?.mobileNo ?? "XXXXXXXXX"
                        : props.signUpData?.mobileNo?.countryData
                            ?.callingCodes ??
                          "00" + props.signUpData?.mobileNo?.mobileNo ??
                          "XXXXXXXXX"
                    }  
                    ${
                      props.signUpData?.emailId
                        ? ", " + props.signUpData?.emailId
                        : " "
                    }`}
                style={{ color: UIColor.tertiaryText.color, fontSize: "12px" }}
                className={"pc_regular"}
              ></Text>
            </Div>
          </Div>

          {!addmmber && (
            <Icons
              id={`${parent_id}_addmember_otp_memberlisting_edit_icon`}
              key={"1"}
              fontIcon={"pencil"}
              ariaHidden={"true"}
              size={14}
              style={{ color: UIColor.primaryColor.color, cursor: "pointer" }}
              onClick={async () => {
                // await props.DELETE_USER({
                //   username: getMobileNumber(props.signUpData?.mobileNo),
                // })
                props.nextSection("addmemberform", props.signUpData);
              }}
            ></Icons>
          )}
        </Div>
        {addmmber && (
          <>
            <div
              id={`${parent_id}_addmember_otp_relationship_div`}
              style={{
                fontSize: "12px",
                color: UIColor.secondaryText.color,
                lineHeight: 1,
                marginTop: "24px",
              }}
              className="pc_regular"
            >
              What's your relationship with {props.signUpData?.firstName} ?{" "}
              <span
                id={`${parent_id}_addmember_otp_relationship_star`}
                style={{ color: "red" }}
              >
                *
              </span>
            </div>
            <TapSelect
              id={`${parent_id}_addmember_otp_relationship_tapselect`}
              key={"4"}
              fullWidth={false}
              label={false}
              selectedBorderColor={UIColor.secondaryButtonColor.color}
              selectedBackgroundColor={UIColor.secondaryButtonColor.color}
              selectedTextColor="#fff"
              borderColor={UIColor.differentiationBackground.color}
              backgroundColor={UIColor.differentiationBackground.color}
              textColor={UIColor.secondaryText.color}
              textSize="16"
              iconHeight="20"
              iconWidth="20"
              options={[
                { value: "Father", label: "Father" },
                { value: "Mother", label: "Mother" },
                { value: "Brother", label: "Brother" },
                { value: "Sister", label: "Sister" },
                { value: "Others", label: "Others" },
              ]}
              value={{
                value: state.genderValue,
                label: state.genderValue,
              }}
              onChange={(e) => changeState("genderValue", e.value)}
              style={{ marginTop: "", paddingTop: "" }}
              defaultSelected={{
                value: state.genderValue,
                label: state.genderValue,
              }}
              inLineStyles={{
                fontSize: "14px",
                fontFamily: "'pc_regular' !important",
              }}
              className={classes.tapSelect}
              // style={qdmstyles.SREcn}
            ></TapSelect>

            {/* <TextInput
                key={'0'}
                type={'text'}
                name={'text'}
                variant={'outlined'}
                helperTextType={'error'}
                label={
                  <div style={qdmstyles.dda_e_dc_a_afcccbd1}>
                    Mail Id (optional)
                  </div>
                }
                autoFocus={false}
                autoComplete={'off'}
                search={false}
                borderColor={UIColor.lineBorderFill.color}
                elevation={'0'}
                placeholder={''}
                size={'medium'}
                value={state.firstNameValue}
                onChange={e =>
                  changeState('firstNameValue', e.target.value)
                }
                error={state?.error?.firstNameValue}
              ></TextInput> */}
            <br />
            {/* <TermsAndConditions
                id={`${parent_id}_addmember_otp_send_appointment_mailid_termsandconditions`}
                value={true}
                inLineStyles={{
                  fontSize: "1px",
                  marginTop: "-10px",
                  marginBottom: "28px",
                }}
                lable={
                  <span
                    id={`${parent_id}_addmember_otp_send_appointment_mailid_termsandconditions_inside_span`}
                    className={"pc_regular"}
                    style={{ fontSize: "12px", color: UIColor.secondaryText.color }}
                  >
                    Send appointment update and notification to this mail id
                  </span>
                }
              /> */}
          </>
        )}
        <Div
          key={"3"}
          id={`${parent_id}_addmember_otp_enterotpcard_parent_div`}
          className={"qdm"}
          style={qdmstyles.adff___ada_bfbec}
        >
          <Card
            id={`${parent_id}_addmember_otp_enterotp_card_section`}
            key={"0"}
            children={"Card"}
            style={{
              backgroundColor: UIColor.differentiationBackground.color,
              padding: "16px",
              boxShadow: "none",
            }}
          >
            <Text
              id={`${parent_id}_addmember_otp_enterotp_card_inside_title`}
              key={"0"}
              name={"OTP Verification"}
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "16px",
                color: UIColor.primaryText.color,
              }}
              className={"pc_medium"}
            ></Text>
            <Divider
              id={`${parent_id}_addmember_otp_enterotp_card_title_bottom_divider`}
              style={{ backgroundColor: UIColor.lineBorderFill.color }}
            ></Divider>

            <Text
              key={"1"}
              id={`${parent_id}_addmember_otp_enterotp_card_subtitle`}
              name={"Please enter the OTP sent to"}
              style={{
                display: "flex",
                justifyContent: "center",
                color: UIColor.tertiaryText.color,
                marginTop: "16px",
                marginBottom: "2px",
                fontSize: "14px",
              }}
              className={"pc_regular"}
            ></Text>

            <H6
              id={`${parent_id}_addmember_otp_enterotp_card_mobilenumber_h6`}
              key={"2"}
              name={props.signUpData?.mobileNo?.mobileNo ?? "99******66"}
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "14px",
                color: UIColor.secondaryText.color,
                fontWeight: "600",
              }}
            ></H6>

            <Div
              id={`${parent_id}_addmember_otp_enterotp_card_otpinput_parent_div`}
              key={"3"}
              className={"qdm"}
              style={qdmstyles.fabbd_b___dfb}
            >
              <OtpInput
                id={`${parent_id}_addmember_otp_enterotp_card_otpinput`}
                numberOfInputs={6}
                focusTextColor={UIColor.secondaryColor.color}
                focusBorderColor={UIColor.secondaryColor.color}
                key={"0"}
                style={{
                  backgroundColor: UIColor.differentiationBackground.color,
                }}
                value={state.otp}
                onChange={(e) => changeState("otp", e)}
                className={"pc_regular"}
                // className={state.resend === "Invalid OTP" && "otpError"}
              ></OtpInput>
            </Div>

            <Text
              key={"4"}
              id={`${parent_id}_addmember_otp_enterotp_card_resendotp_text`}
              name={
                <span
                  id={`${parent_id}_addmember_otp_enterotp_card_resendotp_inside_recive_span`}
                  style={{ color: UIColor.tertiaryText.color }}
                >
                  Didn't receive OTP ?{" "}
                  <span
                    id={`${parent_id}_addmember_otp_enterotp_card_resendotp_inside_resend_span`}
                    style={{
                      fontWeight: "bold",
                      cursor: "pointer",
                      color: UIColor.primaryText.color,
                    }}
                    onClick={() => resendOTP()}
                  >
                    Resend OTP
                  </span>
                </span>
              }
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "24px",
                marginBottom: "16px",
                fontSize: "12px",
                color: UIColor.primaryText.color,
              }}
              className={"pc_regular"}
            ></Text>

            {(state.resend || state?.error) && (
              <Text
                id={`${parent_id}_addmember_otp_enterotp_card_resendotp_resended_message_text`}
                name={
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "12px",
                      marginBottom: "6px",
                      color: state.resend
                        ? UIColor.success.color
                        : UIColor.error.color,
                    }}
                  >
                    {state.resend || state.error}
                  </span>
                }
                className={"pc_regular"}
              ></Text>
            )}
          </Card>
          <Button
            id={`${parent_id}_addmember_otp_enterotp_card_confirm_button`}
            key={"5"}
            type={"button"}
            variant={"outlined"}
            name={
              appointmentDetails
                ? signUp?.loading
                  ? "Adding Member"
                  : "Confirm & Add Member"
                : signUp?.loading
                ? "Signing Up"
                : "Confirm & Sign Up"
            }
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              marginTop: "24px",
              borderRadius: "8px",
              padding: "8px 16px",
              marginBottom: "10px",
              backgroundColor: UIColor.primaryColor.color,
              fontSize: "14px",
              height: "40px",
              border: "1px solid #01205C",
            }}
            onClick={handleSubmit}
            className={"pc_medium"}
            // onClick={() => props.nextSection("movenextscreen")}
          ></Button>
        </Div>
      </Container>
    </div>
  );
}

export default AppointmentPageDrawerOtpSection;
