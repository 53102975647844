/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc The private route check wheather the user is logged in or not and also check
 * wheather the request route from the user is accessible to them or not using the Access(role,path)
 * function then allow to the particular route or else it will redirect to login page.
 */

import React from "react";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actions } from "primarycare-binder";
import jwtDecode from "jwt-decode";
import { getGlobalValueFrom_LclStrg_And_QryStrg, GetQueryStringKey } from "../utils";

const PrivateRoute = ({ children, path }) => {
  let isAuthenticated = false;
  const dispatch = useDispatch();

  const checkAccess = () => {

    try {
      let roleName=""
      let accessObj={}
      let token =""
      if(GetQueryStringKey("UserInfo") && GetQueryStringKey("Token") && GetQueryStringKey("RoleData") ){
        roleName=getGlobalValueFrom_LclStrg_And_QryStrg("UserInfo","rolename");
        accessObj=getGlobalValueFrom_LclStrg_And_QryStrg("RoleData","access");
        token=GetQueryStringKey("Token")
      }else{
      let userInfo =  localStorage.getItem("UserInfo")
      let roleData = localStorage.getItem("RoleData");
      let decodedUserinfo = jwtDecode(userInfo);
      let decodedRoleData = jwtDecode(roleData);
      roleName = decodedUserinfo.rolename;
      accessObj = decodedRoleData?.access || [];
      token = localStorage.getItem("Token");
      }
      if (token) {
        dispatch(actions.REFRESH_TOKEN({ token, isOrg: true }))
          .then((userInfo) => {
            localStorage.setItem("Token", userInfo.payload?.data || token);
          })
          .catch((err) => {
            console.log(err);
          });
      }
       
      //const accessObj = decodedRoleData?.[roleName.toLowerCase()]?.access || [];
   
      if (accessObj?.length === 0) {
        throw new Error("Invalid Permissions");
      }
      return (
        roleName &&
        accessObj.indexOf(path) > -1 &&
        localStorage.Token
      );
    } catch (err) {
      localStorage.removeItem("Token");
      // localStorage.removeItem("role_name");
      localStorage.removeItem("RoleData");
      return false;
    }
  };

  isAuthenticated = checkAccess()
   //  return isAuthenticated ? <>{children}</> : <Navigate to="/" />;
   return <>{children}</>
};

export default PrivateRoute;
