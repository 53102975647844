import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  defaultReject,
  defaultState,
  fetchData,
  urls, 
} from "../../utils";
import { dbName } from "../../utils";

const GET_WEB_SOCKET_MESSAGE = createAsyncThunk(
  `notificationSlice/getMessageFromWs`,
  async (id = "", { rejectWithValue }) => {
    try {
      const data =  await fetchData( {
        body:
       JSON.stringify({
        "db_name": dbName,
        "queryid": "193a7713-1ff9-4433-b2cb-8fe263b9fc92",
        "filter": {
          "altertoid": id
        }
      })},
      urls?.readQdmQueries
      );
     
      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const NOTIFiCATION_UPDATE_READ_STATUS = createAsyncThunk(
  `notificationSlice/updateReadStatus`,
  async (logId = [], { rejectWithValue }) => {
    try {
      const data = await fetchData(
        {
        body: JSON.stringify({
          db_name:dbName,
          logid:logId
        }),
      },
      urls.notificationUpdateReadStatus
    );
    
      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState: {
    getMessageFromWs: { ...defaultState.List },
    updateReadStatus: { ...defaultState.Info },   
  },
  extraReducers: {
    /* GET_WEB_SOCKET_MESSAGE */
    [GET_WEB_SOCKET_MESSAGE.fulfilled]: (state, action) => {
      state.getMessageFromWs = action?.payload ?? [];
    },
    [GET_WEB_SOCKET_MESSAGE.pending]: (state, action) => {
      state.getMessageFromWs.loading = true;
    },
    [GET_WEB_SOCKET_MESSAGE.rejected]: (state, action) => {
      state.getMessageFromWs = action?.payload ?? [];
    },
    /* NOTIFiCATION_UPDATE_READ_STATUS */
    [NOTIFiCATION_UPDATE_READ_STATUS.fulfilled]: (state, action) => {
      state.updateReadStatus = action?.payload ?? [];
    },
    [NOTIFiCATION_UPDATE_READ_STATUS.pending]: (state, action) => {
      state.updateReadStatus.loading = true;
    },
    [NOTIFiCATION_UPDATE_READ_STATUS.rejected]: (state, action) => {
      state.updateReadStatus = action?.payload ?? [];
    },
    
  },
});

export const notificationActions = {
  GET_WEB_SOCKET_MESSAGE,
  NOTIFiCATION_UPDATE_READ_STATUS,
};

export default notificationSlice;
