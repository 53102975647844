
import { Grid, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import withAppBar from "../../hoc/withAppBar";
import actions from "../../redux/actions";
import jwtDecode from "jwt-decode";
import EditableDataGrid from "./components/editableDataGrid";
import SearchFilter from "./components/SearchFilter";

function PayerMapping() {
    const dispatch = useDispatch();
    const [rows, setRows] = React.useState([]);
    const [rowCount, setRowsCount] = React.useState([]);
    const [facilityList, setFacilityList] = React.useState([]);
    const [payerList, setPayerList] = React.useState([]);
    const [chargecodeList, setChargecodeList] = React.useState([]);
    const [tableDataLoading, setTableDataLoading] = useState(false);
    const [tableInput, setTableInput] = useState({
      recstart: 1,
      recend: 101,
      filter: {
      },
      searchtext:""
    });
  const getTableData = async () => {
    setTableDataLoading(true)
    try{
      const data = await dispatch(actions.GET_OPENL_DATA(tableInput));
      setRows(data?.payload?.data?.data);
      setRowsCount(data?.payload?.data?.count);
      setTableDataLoading(false)
    } catch{
      setTableDataLoading(false)
    }
    
  };
  const getFacilityData = async () => {
    const data = await dispatch(actions.GET_FACILITY_LIST());
    setFacilityList(data?.payload?.data);
  };
  const getPayerData = async () => {
    const data = await dispatch(actions.GET_PAYER_LIST());
    setPayerList(data?.payload?.data);
  };
  const getChargeCodeData = async () => {
    const data = await dispatch(actions.GET_CHARGE_LIST());
    setChargecodeList(data?.payload?.data);
  };
  const setTableData = async (data) => {
    const res = await dispatch(actions.SET_OPENL_DATA(data));
    return res?.payload?.data;
  };

  useEffect(() => {
    getTableData();
    getFacilityData();
    getPayerData();
    getChargeCodeData();
  }, []);

  return (
    <Grid container alignItems="center">
      <Grid item container  xs={12}  alignItems="center" style={{padding:"20px 0px 10px 30px"}}>
      <Typography style={{
          fontSize: "18px",
          color: "#001C3C",
          fontWeight: 600,
        }}>Price Definition</Typography> 
      </Grid>
      <Grid item container xs={12} spacing={2}   alignItems="center" style={{padding:"0px 10px 0px 20px"}}>
        <Grid item xs={12} alignItems="center" >
          <SearchFilter 
            tableInput = {tableInput} 
            setTableInput = {setTableInput}
            facilityList={facilityList}
            payerList={payerList}
            chargecodeList= {chargecodeList}
            getTableData={getTableData}
          />
        </Grid>
        <Grid item  xs={12} alignItems="center" justifyContent="center">
          <EditableDataGrid 
            rows={rows} 
            setRows ={setRows}
            setTableData={setTableData}
            facilityList={facilityList}
            payerList={payerList}
            rowCount={rowCount}
            chargecodeList= {chargecodeList}
            tableInput = {tableInput}
            setTableInput = {setTableInput}
            getTableData={getTableData}
            tableDataLoading={tableDataLoading}
            setTableDataLoading={setTableDataLoading}
          />
        </Grid>
      </Grid>
    </Grid>
    );
  }
  
  export default withAppBar(PayerMapping);