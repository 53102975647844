import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  dbName,
  defaultReject,
  defaultState,
  fetchData,
  query_ids,
  urls,
} from "../../utils";
const BOOK_NEW_BUTTON_CLICK = createAsyncThunk(
  `patientDashboard/bookNewButtonClick`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      let { ticketId = "", userAction = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["GetAllMasters"],
        filter: {},
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_OUTSTANDING_DATA = createAsyncThunk(
  "patientDashboard/outstanding",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        queryid: query_ids["getOutstandingdata"],
        filter: {
          patientid: payload,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );

      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_BILL_AND_RECEIPT = createAsyncThunk(
  "patientDashboard/getBillAndReceipt",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { patientId, appointmentId } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["viewBillAndReceipt"],
        filter: {
          appointmentid: appointmentId,
          patientid: patientId,
        },
      };
      let data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );

      let receipt = [];
      let details = { ...data?.[0] };
      let bill = [];
      delete details?.bill;
      data?.[0]?.bill.forEach((v) => {
        v["billhstatus_id"] = v?.billhtatus?._id;
        v["billhstatus_Display"] = v?.billhtatus?.display;
        bill.push({ ...v, checked: false });
        if (Array.isArray(v?.receipt)) {
          v?.receipt.forEach((e) => {
            e["receiptstatus_id"] = e?.receiptstatus?._id;
            e["receiptstatus_Display"] = e?.receiptstatus?.display;
            let bill = { ...v, checked: false };
            delete bill?.receipt;
            receipt.push({ ...e, ...bill, ...details });
          });
        }
      });
      data[0].bill = bill;
      return {
        ...defaultState.Info,
        data: { bill: data, receipt },
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const TRANSACTION_DELETE = createAsyncThunk(
  "patientDashboard/transDelete",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        {
          body: JSON.stringify(payload),
        },
        urls.cancelBill
      );

      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const CANCEL_RECEIPT = createAsyncThunk(
  "patientDashboard/cancelReceipt",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        {
          body: JSON.stringify(payload),
        },
        urls.cancelReceipt
      );

      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const ADVANCE_REFUND = createAsyncThunk(
  "patientDashboard/advanceRefund",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        {
          body: JSON.stringify(payload),
        },
        urls.advanceRefund
      );

      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_LASTEST_ENCOUNTER = createAsyncThunk(
  "patientDashboard/lastestEncounter",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        filter: { patientid: payload },
        queryid: query_ids.getlatestEncounter,
      };

      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );

      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const PatientDashboardSlice = createSlice({
  name: "patientDashboard",
  initialState: {
    bookNewButtonClick: {
      ...defaultState.info,
    },
    outstanding: {
      ...defaultState.info,
    },
    transDelete: {
      ...defaultState.info,
    },
    getBillAndReceipt: {
      ...defaultState.info,
    },
    getBill: {
      ...defaultState.info,
    },
    lastestEncounter: {
      ...defaultState.info,
    },
    cancelReceipt: {
      ...defaultState.info,
    },
    advanceRefund: {
      ...defaultState.info,
    },
  },
  extraReducers: {
    /* BOOK_NEW_BUTTON_CLICK  */
    [BOOK_NEW_BUTTON_CLICK.fulfilled]: (state, action) => {
      state.bookNewButtonClick = action?.payload ?? {};
    },
    [BOOK_NEW_BUTTON_CLICK.pending]: (state, action) => {
      state.bookNewButtonClick.loading = true;
    },
    [BOOK_NEW_BUTTON_CLICK.rejected]: (state, action) => {
      state.bookNewButtonClick = action?.payload ?? {};
    },
    [GET_OUTSTANDING_DATA.fulfilled]: (state, action) => {
      state.outstanding = action?.payload ?? {};
    },
    [GET_OUTSTANDING_DATA.pending]: (state, action) => {
      state.outstanding.loading = true;
    },
    [GET_OUTSTANDING_DATA.rejected]: (state, action) => {
      state.outstanding = action?.payload ?? {};
    },
    [GET_BILL_AND_RECEIPT.fulfilled]: (state, action) => {
      state.getBillAndReceipt = action?.payload ?? {};
    },
    [GET_BILL_AND_RECEIPT.pending]: (state, action) => {
      state.getBillAndReceipt.loading = true;
    },
    [GET_BILL_AND_RECEIPT.rejected]: (state, action) => {
      state.getBillAndReceipt = action?.payload ?? {};
    },
    [TRANSACTION_DELETE.fulfilled]: (state, action) => {
      state.transDelete = action?.payload ?? {};
    },
    [TRANSACTION_DELETE.pending]: (state, action) => {
      state.transDelete.loading = true;
    },
    [TRANSACTION_DELETE.rejected]: (state, action) => {
      state.transDelete = action?.payload ?? {};
    },
    [GET_LASTEST_ENCOUNTER.fulfilled]: (state, action) => {
      state.lastestEncounter = action?.payload ?? {};
    },
    [GET_LASTEST_ENCOUNTER.pending]: (state, action) => {
      state.lastestEncounter.loading = true;
    },
    [GET_LASTEST_ENCOUNTER.rejected]: (state, action) => {
      state.lastestEncounter = action?.payload ?? {};
    },
    [CANCEL_RECEIPT.fulfilled]: (state, action) => {
      state.cancelReceipt = action?.payload ?? {};
    },
    [CANCEL_RECEIPT.pending]: (state, action) => {
      state.cancelReceipt.loading = true;
    },
    [CANCEL_RECEIPT.rejected]: (state, action) => {
      state.cancelReceipt = action?.payload ?? {};
    },
    [ADVANCE_REFUND.fulfilled]: (state, action) => {
      state.advanceRefund = action?.payload ?? {};
    },
    [ADVANCE_REFUND.pending]: (state, action) => {
      state.advanceRefund.loading = true;
    },
    [ADVANCE_REFUND.rejected]: (state, action) => {
      state.advanceRefund = action?.payload ?? {};
    },
  },
});

export const patientDashboardActions = {
  BOOK_NEW_BUTTON_CLICK,
  GET_OUTSTANDING_DATA,
  GET_BILL_AND_RECEIPT,
  TRANSACTION_DELETE,
  GET_LASTEST_ENCOUNTER,
  CANCEL_RECEIPT,
  ADVANCE_REFUND
};

export default PatientDashboardSlice;
