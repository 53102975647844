import { useEffect, useState } from "react";
import {
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import withRouter from "../../../../hoc/withRouter";
// import { actions } from "primarycare-binder";
// import { connect } from "react-redux";
import { AppRoutes } from "../../../../router/routes";
import calenderIcon from "../../../../assets/img/svg/icons8-calendar.svg";
import calenderIcon2 from "../../../../assets/img/svg/icons8-calendar-grey.svg";
import {
  TextInput,
  Select,
  MobileNumberWithCode,
  H6,
  Div,
  Text,
  SideMenu,
} from "qdm-component-library";
import SearchPatientList from "./searchPatientList";
import withAllContexts from "../../../../hoc/withAllContexts";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import {
  getUserInfo,
  convertTime,
  getImgUrl,
  makeName,
  mobileNumberValidation,
  AlertProps,
  checkWithCasbin,
  utcTOLocal,
  fetchData,
} from "../../../../utils";
import { personRequests } from "../../../../utils/qdmQueries";
import { urls } from "../../../../utils/urls";
import { UIColor } from "../../../../themes/theme";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useStyles } from "./styles";
import { useNavigate } from "react-router-dom";
import Logger from "qdm-log4js";
import jwtDecode from "jwt-decode";

let cCode = [];
const SearchForm = (props) => {

  const userInfo = localStorage?.getItem("UserInfo");
  const decodedUserinfo = userInfo === null || userInfo === undefined ? "" : jwtDecode(userInfo);
  const facilityId = decodedUserinfo?.facilityid ?? "";

  const styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
      background: "#fff",
    },
    borderStyleDisable: {
      borderRadius: 8,
      border: "1px solid #E0E0E0",
      background: "#F0F0F0",
    },
  };
  const navigate = useNavigate();
  let log = new Logger();
  log.init("USER_ID", "primarycare");
  const classes = useStyles();

  // STATE
  const [state, setState] = useState({
    allMasters: [],
    pid_number: "",
    identification: "",
    id_number: "",
    patientName: "",
    mobileNumber: {},
    appointmentName: "",
    searchoptions: true,
    patientData: [],
    cancelbooking: false,
    cancelbookingreason: {},
    cancelbookings: 0,
    otpnumber: "",
    open: false,
    appointmentId: 0,
    button: false,
    dateRange: [
      new Date(new Date().setHours(0, 0, 0, 0)),
      new Date(new Date().setHours(23, 59, 59, 999)),
    ],
    isBilled: false,
  });
  const [callingcodes,setcallingcodes]=useState([]);
  // USE EFFECT
  useEffect(() => {
    getallmasters();
    getCountryCode();
  }, []);

  // FUNCTIONS
  const onChangeDate = (range) => {
    setState((prevState) => ({
      ...prevState,
      dateRange: range,
    }));
  };

  const getCountryCode = async () => {
    const payload = personRequests.getcountrycode(facilityId);

    const getData = await fetchData(payload, urls.readQdmQueries);
    cCode = [];
    getData?.map((v, i) => {
      let setObj = {
        callingCodes: v?.GeoLocationISDCode?.replace("+", ""),
        name: v?.geogLevelName,
        _id: v?._id,
        altSpellings: "",
        flag: "",
      };
      cCode.push(setObj);
    });
    setcallingcodes(cCode);
  };
  const getallmasters = async () => {
    const typeData = ["ALTERNATEIDTYPE"];
    const queryData = personRequests.masterSearch(typeData, facilityId);
    const queryDataResp = await fetchData(queryData, urls.readQdmQueries);
    let data = queryDataResp.length > 0 ? queryDataResp[0]["ALTERNATEIDTYPE"] : queryDataResp;
    let identificationDefaultValue;
    for (let index in data?.Value) {
      if( data.Value[index]?._id === "CodingMaster/10524"){
        var dat = {
          value: data.Value[index]?.display,
          code: data.Value[index]?._id,
        }
        identificationDefaultValue = dat;
      }
    }
    setState((prevState) => ({
      ...prevState,
      allMasters: queryDataResp,
      identification:identificationDefaultValue,
    }));
  };

  const FlterOptions = (type) => {
    const { allMasters } = state;
    let data = allMasters.length > 0 ? allMasters[0][type] : allMasters;
    var lista = [];
    for (let index in data?.Value) {
      var datatemp = {
        value: data.Value[index]?.display,
        code: data.Value[index]?._id,
        dis: data?.Type,
      };
      lista.push(datatemp);
    }
    return lista;
  };

  const setData = (n, v) => {
    if (n === "identification") {
      state.id_number = "";
    }
    setState((prevState) => ({
      ...prevState,
      [n]: v,
    }));
  };

  const handleList = () => {
    const statee = state;
    setState((prevState) => ({
      ...prevState,
      searchoptions: !statee.searchoptions,
    }));
  };

  const getAge = (date) => {
    return (
      Math.floor(
        moment(new Date()).diff(moment(date, "DD/MM/YYYY"), "years", true)
      ) + " Yrs"
    );
  };

  // const searchCall = (data) => {
  //   let lista = [];
  //   const valuedata = data?.map((v) => {
  //     return lista.push({
  //       idnumber: "",
  //       idsourcecode: "",
  //       email: v.telecom?.find?.((tel) => tel.system === "Email")?.value ?? "",
  //       name: makeName(v?.name[0] || {}) || "",
  //       id: v?.alias ?? "",
  //       dis: `${
  //         (v?.gender?.display + ", " ?? "") +
  //         (v?.birthDate ? getAge(v?.birthDate, true) + "Yrs" : "")
  //       }${v.telecom?.[0].value ? " | " + v.telecom?.[0].value : ""}`,
  //       gender: v?.gender?.display.slice(0, 1) ?? "",
  //       age: getAge(v?.birthDate) ?? "",
  //       userId: v?.personId ?? 1111,
  //       appointmentName: "",
  //       mobileNumber:
  //         // v?.telecom?.find?.((tel) => tel.system === "Phone")?.value || v.telecom?.find?.((tel) => tel.system === "Email")?.value || "",
  //         v?.telecom?.[0]?.value,
  //       patientId: v?.id ?? "",
  //       img:
  //         getImgUrl(v?.photo?.[0]?.fileid) ||
  //         getImgUrl(v?.patientDetail?.[0]?.photo?.[0]?.fileid) ||
  //         "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
  //       encounter: v?.encounter,
  //       identifier: v?.identifier?.[0]?.value ?? "",
  //       _id: v?._id ?? "",
  //     });
  //   });
  //   return lista;
  // };

  const SearchUserData = async () => {
    

    var lista = [];
    setState((prevState) => ({
      ...prevState,
      button: true,
    }));
    const start = Math.floor(state.dateRange[0] / 1000);
    const endDAte = new Date(state.dateRange[1].setHours(23, 59, 59, 999));
    const end = Math.floor(endDAte / 1000);
    const name = state.patientName;
    const unbilled = state.isBilled;
    const mbl = state?.mobileNumber?.mobileNo;
    const mrn = state.pid_number;
    const alternateId = state?.identification?.code;
    const alternateNo = state?.id_number;
    if (props?.isCashier) {
      if (!state.dateRange[1]) {
        props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please provide the date range",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      } else {
        let mobileCode = "";
        let mobileNum = "";
        let idCode = state.identification?.code
          ? state.identification?.code
          : "";
        if (state.mobileNumber?.mobileNo && state.dateRange[1]) {
          mobileCode = state?.mobileNumber?.countryData?.callingCodes;
          mobileNum = state?.mobileNumber?.mobileNo;
          let res__ = mobileNumberValidation(
            state?.mobileNumber?.mobileNo,
            state?.mobileNumber?.countryData?.callingCodes
          );
          if (res__ === "Invalid Country code") {
            props.alert.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: res__,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
            });
            return;
          } else if (res__ === false) {
            props.alert.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: "Please enter valid mobile number",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
            });
            return;
          }
          // const searchUserData =  await this.props.SEARCH_VIA_MOBILE({mbl,start,end,unbilled});
          // let data = searchUserData?.payload?.data;
          // const newData = searchCall(data);
          // lista = newData
        }

        const queryData =
          personRequests.getDetailsAgainstPatientSearchByIsCashier(
            start,
            end,
            unbilled,
            state.pid_number,
            idCode,
            state.id_number,
            state.patientName,
            mobileCode,
            mobileNum,
            facilityId,
            0,
            10
          );

        const SearchUserData = await fetchData(queryData, urls.readQdmQueries);

        SearchUserData?.map((v, i) => {
          console.log(v, "search");
          return lista.push({
            idnumber: v?.identifier[0]?.value,
            idsourcecode: v?.identifier[0]?.code,
            address: v?.address,
            age: v?.age,
            gender: v?.gender,
            email:
              v?.telecom?.find((tel) => tel?.system === "CodingMaster/10469")
                ?.value ?? "", //Email
            name: makeName(v?.name?.[0] || {}) || "",
            id: v?.patmrn ?? "",
            dis: `${
              (v?.gender + ", " ?? "") +
              (v?.birthDate ? getAge(v?.birthDate) : "")
            }
            ${v?.telecom[0]?.value ? " | " + v?.telecom[0]?.value : ""}`,
            // userId: v?.personid, //....
            // appointmentName: "", //....
            encounter: v?.encounter,
            mobileNumber:
              v?.telecom?.find((tel) => tel?.system === "CodingMaster/10473")
                ?.value ||
              v.telecom?.find((tel) => tel?.system === "CodingMaster/10469")
                ?.value ||
              "",
            patientId: v?._id,
            img:
              getImgUrl(v?.photo?.[0]?.fileid) ||
              "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
          });
        });

        setState((prevState) => ({
          ...prevState,
          patientData: lista,
          err: true,
        }));
      }
    } else {
      let mobileCode = "";
      let mobileNum = "";
      let idCode = state.identification?.code ? state.identification?.code : "";
      if (state.mobileNumber?.mobileNo) {
        mobileCode = state?.mobileNumber?.countryData?.callingCodes;
        mobileNum = state?.mobileNumber?.mobileNo;
        let res__ = mobileNumberValidation(
          state?.mobileNumber?.mobileNo,
          state?.mobileNumber?.countryData?.callingCodes
        );
        if (res__ === "Invalid Country code") {
          props.alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: res__,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
          setState((prevState) => ({
            ...prevState,
            patientData: [],
            err: false,
          }));
          return;
        } else if (res__ === false) {
          props.alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Please enter valid mobile number",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
          setState((prevState) => ({
            ...prevState,
            patientData: [],
            err: false,
          }));
          return;
        }
      }

      const queryData = personRequests.getDetailsAgainstPatientSearch(
        state.pid_number,
        idCode,
        state.id_number,
        state.patientName,
        mobileCode,
        mobileNum,
        state.appointmentName,
        facilityId
      );
      log.event("Patient Search Input", queryData);
      const SearchUserData = await fetchData(queryData, urls.readQdmQueries);
      log.event("Patient Search Output", SearchUserData);

      SearchUserData?.map((v) => {
        return lista.push({
          idnumber: v?.identifier[0]?.value,
          idsourcecode: v?.identifier[0]?.use,
          email:
            v?.telecom?.find((tel) => tel?.system?._id === "CodingMaster/10469")
              ?.value ?? "", //Email
          name: makeName(v?.name?.[0] || {}) || "",
          id: v?.patmrn ?? "",
          dis: `${
            (v?.gender + ", " ?? "") +
            (v?.birthDate ? getAge(v?.birthDate) : "")
          }
          ${v?.telecom[0]?.value ? " | " + v?.telecom[0]?.value : ""}`,
          userId: v?.personid, //....
          appointmentName: "", //....
          mobileNumber:
            v?.telecom?.find((tel) => tel?.system?._id === "CodingMaster/10473")
              ?.value ||
            v?.telecom?.find((tel) => tel?.system?._id === "CodingMaster/10469")
              ?.value ||
            "",
          patientId: v?.patientid,
          img:
            getImgUrl(v?.photo?.[0]?.fileid) ||
            "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
        });
      });

      setState((prevState) => ({
        ...prevState,
        patientData: lista,
        err: true,
      }));
    }
  };

  const Search = async () => {
    setState((prevState) => ({
      ...prevState,
      searchClick: true,
    }));
    await SearchUserData();
    setState((prevState) => ({
      ...prevState,
      searchoptions: prevState?.patientData?.length > 0 ? false : true,
      button: false,
    }));
  };

  const Clear = () => {
    setState((prevState) => ({
      ...prevState,
      pid_number: "",
      identification: "",
      id_number: "",
      patientName: "",
      mobileNumber: {},
      appointmentName: "",
      searchoptions: true,
      err: false,
      patientData: [],
    }));
  };

  const handleMove = () => {
    // props.history.push({
    // 	pathname: AppRoutes.assemblePatioentRegister,
    // 	state: {
    // 		newUser: true,
    // 		Ispatient: true,
    // 		idedit: false,
    // 	},
    // });
    // const element = document.documentElement;
    //   if (element.requestFullscreen) {
    //     element.requestFullscreen();
    //   } else if (element.mozRequestFullScreen) {
    //     element.mozRequestFullScreen();
    //   } else if (element.webkitRequestFullscreen) {
    //     element.webkitRequestFullscreen();
    //   } else if (element.msRequestFullscreen) {
    //     element.msRequestFullscreen();
    //   }
    navigate(AppRoutes.patientRegistration);
  };

  const handleMoveList = (v) => {
    if (props?.isCashier) {
      navigate(AppRoutes.billingDetails, {
        state: {
          data: v,
        },
      });
    } else {
      // props.history.push({
      // 	pathname: AppRoutes.PatientDashBoard,
      // 	state: {
      // 		userId: v?.userId,
      // 		mobileNumber: v?.mobileNumber,
      // 		patientId: v?.patientId,
      // 		// appointmentId
      // 	},
      // });
      // navigate({
      // 	pathname: AppRoutes.PatientDashBoard,
      // 	state: {
      // 		userId: v?.userId,
      // 		mobileNumber: v?.mobileNumber,
      // 		patientId: v?.patientId,
      // 		// appointmentId
      // 	},
      // });
      navigate(AppRoutes.PatientDashBoard, {
        state: {
          userId: v?.userId,
          mobileNumber: v?.mobileNumber,
          patientId: v?.patientId,
        },
      });
    }
  };

  const fetchAppInfo = async (id) => {
    // const data = await props.APPOINTMENT_READ({ appointmentId: id });
    // return data?.payload?.data || {};
  };

  const handleModify = async (val) => {
    // // props.history.push(AppRoutes.doctorListingWithIdCall+"123")
    // // props.history.push(AppRoutes.doctorListing)
    // const appInfo = JSON.parse(
    // 	JSON.stringify(await fetchAppInfo(val.AppointmentID))
    // );
    // appInfo["HealthcareService"] = appInfo["healthservice"];
    // delete appInfo["healthservice"];
    // props.history.push({
    // 	pathname: AppRoutes.doctorListing,
    // 	state: {
    // 		userId: val.userId,
    // 		mobileNo: val.mobileNumber,
    // 		patientId: val.patientId,
    // 		location: {
    // 			address: "Chennai, Tamil Nadu, India",
    // 			latitude: 13.0826802,
    // 			longitude: 80.2707184,
    // 			city: "Chennai",
    // 			location: "Chennai",
    // 		},
    // 		hospital: {
    // 			label: "",
    // 			value: appInfo?.HealthcareService?.[0]?.Organization?.[0]?.id || 0,
    // 		},
    // 		specialities: [],
    // 		symptoms: [
    // 			{
    // 				label: "Fever",
    // 				type: "Symptom",
    // 				value: "SymptomsMapping/10000",
    // 			},
    // 		],
    // 		selectedUser: {
    // 			//name: appInfo?.Person?.[0]?.name?.[0]?.text ?? "",
    // 			name: makeName(appInfo?.Person?.[0]?.name?.[0]),
    // 			gender: appInfo?.Person?.[0]?.gender ?? "",
    // 			birthDay: appInfo?.Person?.[0]?.birthDay,
    // 		},
    // 		symptomsSearch: [],
    // 		modifyUser: true,
    // 		newBook: false,
    // 		appointMentInfo: appInfo,
    // 	},
    // });
  };

  const openView = (v) => {
    setState((prevState) => ({
      ...prevState,
      open: !state.open,
      appointmentId: v.AppointmentID,
    }));
  };

  const handleCancel = (v) => {
    if (v !== "cancel") {
      setState((prevState) => ({
        ...prevState,
        cancelbooking: !state.cancelbooking,
        appointmentId: v.AppointmentID,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        cancelbooking: !state.cancelbooking,
        appointmentId: 0,
      }));
    }
  };
  const handleSelectCancelbooking = (v) => {
    setState((prevState) => ({
      ...prevState,
      cancelbookingreason: v,
    }));
  };
  const handlecahngeCancelbooking = async (v, number) => {
    if (v === "back") {
      setState((prevState) => ({
        ...prevState,
        cancelbookings: v,
      }));
      return;
    }
    if (!v) {
      handleCancel("cancel");
      window.location.href = "/dashboard";
      return;
    }
    let mobile = number;
    if (!number) {
      // const userInfo = getUserInfo();
      // mobile = userInfo.mobile;
    }
    if (v === 1) {
      // await props.GENERATE_OTP({ mobileNo: mobile });
    }
    setState((prevState) => ({
      ...prevState,
      cancelbookings: v,
      otpnumber: number,
    }));
  };

  const { parent_id, noNewPatient } = props;
  const {
    patientData,
    err,
    pid_number,
    identification,
    id_number,
    patientName,
    mobileNumber,
    appointmentName,
    searchoptions,
    cancelbookingreason,
    cancelbooking,
    cancelbookings,
  } = state;

  const role_name =
    localStorage.getItem("role_name") === "biller and cashier" ? true : false;
  const [startDate, endDate] = state.dateRange;

  return (
    <Div className={"root"}>
      <Container id={`${parent_id}_parent_container`} style={{ padding: 10 }}>
        <Div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <H6 className={classes.title}> Search Patient</H6>
          <CloseIcon
            style={{ fontSize: 20, cursor: "pointer" }}
            onClick={() => props.handleActionClose()}
          />
        </Div>
        {searchoptions && (
          <Grid id={`${parent_id}_parent_grid`} spacing={2} container>
          <Grid item xs={12} className={classes.pd_0}>
              <Text
                id={`${parent_id}_mobilenumberwithcode_title`}
                className="pc_regular"
                name={
                  <span
                    style={{ color: "#6F6F6F", fontSize: 12 }}
                    id={`${parent_id}_mobilenumberwithcode_title_span`}
                  >
                    Mobile
                  </span>
                }
              ></Text>
              <MobileNumberWithCode
                id={`${parent_id}_mobilenumberwithcode`}
                type="number"
                label="Mobile No"
                borderColor="#E0E0E0"
                borderRadius="6"
                backgroundColor="#fff"
                maxLength={30}
                dropdown={mobileNumber?.countryData}
                value={mobileNumber?.mobileNo}
                className={classes.mbl}
                dropdownData={callingcodes}
                disabled={false}
                style={{
                  height: "42px",
                  background: "#fff",
                }}
                placeholder={false}
                onChange={(e) => setData("mobileNumber", e)}
              />
            </Grid>
            <Grid item xs={12} className={classes.pd_0}>
              <TextInput
                maxLength={20}
                id={`${parent_id}_pid_number_textinput`}
                value={pid_number ?? ""}
                onChange={(e) => setData("pid_number", e.target.value, 20)}
                label="MRN Number"
                placeholder={false}
                labelStyle={styles.labelStyle}
                style={styles.borderStyle}
                hoverColor={UIColor.primaryColor.color}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.pd_0}>
              <Select
                search={true}
                id={`${parent_id}_identification_select`}
                value={identification?.value ?? null}
                onChangeValue={(e) => setData("identification", e)}
                options={FlterOptions("ALTERNATEIDTYPE")}
                label="Identification"
                // showArrow
                labelStyle={styles.labelStyle}
                placeholder={false}
                inLineStyles={styles.borderStyle}
                hoverColor={UIColor.primaryColor.color}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.pd_0}>
              <TextInput
                maxLength={20}
                id={`${parent_id}_id_number_textinput`}
                value={id_number ?? ""}
                onChange={(e) => setData("id_number", e.target.value, 20)}
                label="ID Number"
                placeholder={false}
                labelStyle={styles.labelStyle}
                disabled={!identification?.value}
                style={
                  !identification?.value
                    ? styles.borderStyleDisable
                    : styles.borderStyle
                }
                hoverColor={UIColor.primaryColor.color}
              />
            </Grid>
            <Grid item xs={12} className={classes.pd_0}>
              <TextInput
                id={`${parent_id}_patientName_textinput`}
                value={patientName ?? ""}
                onChange={(e) => setData("patientName", e.target.value, 20)}
                label="Patient Name"
                placeholder={false}
                labelStyle={styles.labelStyle}
                style={styles.borderStyle}
                hoverColor={UIColor.primaryColor.color}
              />
            </Grid>
            
            {props?.isCashier === false && (
              <Grid item xs={12} className={classes.pd_0}>
                <TextInput
                  maxLength={20}
                  id={`${parent_id}_appointmentName_textinput`}
                  value={appointmentName ?? ""}
                  onChange={(e) =>
                    setData("appointmentName", e.target.value, 20)
                  }
                  label="Appointment Number"
                  placeholder={false}
                  labelStyle={styles.labelStyle}
                  style={styles.borderStyle}
                  hoverColor={UIColor.primaryColor.color}
                />
              </Grid>
            )}
            {props?.isCashier && (
              <>
                <Grid item xs={12} className={classes.pd_0}>
                  <DatePicker
                    maxDate={new Date()}
                    selectsRange
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(value) => {
                      setState((prevState) => ({
                        ...prevState,
                        dateRange: value,
                      }));
                    }}
                    isClearable={false}
                    customInput={
                      <div style={{ position: "relative", width: "348px" }}>
                        <TextInput
                          value={
                            (!startDate
                              ? ""
                              : utcTOLocal(startDate, "DD MMM, YYYY")) +
                            (!startDate ? "" : " to ") +
                            (!endDate
                              ? ""
                              : utcTOLocal(endDate, "DD MMM, YYYY"))
                          } //send format in DD-MM-YYYY
                          label={"Encounter Date Range"}
                          dateIcon={calenderIcon}
                          placeholder={""}
                          labelStyle={styles.labelStyle}
                          style={styles.borderStyle}
                          borderColor={UIColor.lineBorderFill.color}
                        />
                        <img
                          src={calenderIcon2}
                          style={{ position: "absolute", top: 35, right: 10 }}
                        />
                      </div>
                    }
                  />
                </Grid>
                <Grid item xs={12} className={classes.pd_0}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.isBilled}
                        color="primary"
                        size="small"
                        onChange={(e) =>
                          setState({
                            ...state,
                            isBilled: e.target.checked,
                          })
                        }
                      />
                    }
                    label={
                      <Typography
                        style={{ ...styles.labelStyle, marginBottom: "0px" }}
                      >
                        Unbilled Encounter Only?
                      </Typography>
                    }
                  />
                </Grid>
              </>
            )}
            <Grid item xs={6}>
              <Button
                id={`${parent_id}_search_button`}
                className={classes.clear}
                variant="outlined"
                onClick={() => Search()}
                style={{
                  backgroundColor: UIColor.secondaryButtonColor.color,
                  color: "#fff",
                  textTransform: "capitalize",
                  fontWeight: 600,
                  fontSize: 14,
                }}
              >
                {state.button ? "Loading..." : "Search"}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                id={`${parent_id}_clearAll_button`}
                className={classes.clear}
                variant="outlined"
                onClick={() => Clear()}
                style={{
                  textTransform: "capitalize",
                }}
              >
                Clear All
              </Button>
            </Grid>
          </Grid>
        )}
        {err && !patientData?.length > 0 && (
          <Div className={classes.err}>No Result Found</Div>
        )}
      </Container>
      {patientData?.length > 0 && (
        <Div className={classes.searchOptions} onClick={() => handleList()}>
          Search Options
          {searchoptions ? "▾" : "▴"}
        </Div>
      )}

      <Container id={`${parent_id}_parent_container`} style={{ padding: 10 }}>
        {console.log("PatientData", patientData)}
        {patientData?.length > 0 &&
          patientData.map((v, i) => {
            return (
              <SearchPatientList
                parent_id={parent_id + i}
                handleModify={() => handleModify(v)}
                handleCancel={() => handleCancel(v)}
                handleMoveList={() => handleMoveList(v)}
                openView={() => openView(v)}
                name={v?.name}
                letter={v?.letter}
                gendar={v?.dis?.split("\n")[0]}
                pid={v.id || "---"}
                email={v.email || ""}
                mobile={v.mobileNumber || "---"}
                imgUrl={v.img}
                appointmentName={v.appointmentName}
                date={v?.date}
                status={v?.status}
                appStatus={v?.appStatus}
                allData={v}
              />
            );
          })}

        {!patientData?.length > 0 &&
          !noNewPatient &&
          props?.isCashier == false && (
            <Div style={{ textAlign: "center" }}>
              <Div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "15px 0px 6px",
                }}
              >
                <Div
                  style={{ flex: 1, borderBottom: "1.9px dashed #E0E0E0" }}
                ></Div>
                <H6
                  style={{
                    padding: "0px 10px",
                    fontSize: 14,
                    fontFamily: "pc_medium !important",
                  }}
                >
                  Or
                </H6>
                <Div
                  style={{ flex: 1, borderBottom: "1.9px dashed #E0E0E0" }}
                ></Div>
              </Div>
              <Text className={classes.dis}>
                If you're unable to find patient
              </Text>
              <Button
                key={"3"}
                onClick={() => handleMove()}
                variant={"outline"}
                name={"Register New Patient"}
                className={classes.clear}
                style={{
                  borderColor: UIColor.secondaryButtonColor.color,
                  color: UIColor.secondaryButtonColor.color,
                  textTransform: "capitalize",
                }}
              >
                Register New Patient
              </Button>
            </Div>
          )}
      </Container>
      <SideMenu
        id="patient_dashboard_cancel_sidemenu"
        open={cancelbooking}
        direction="right"
        width={435}
        color="default"
        onClose={() => handleCancel("cancel")}
      >
        {/* <CancelBookingPage
						parent_id={"patient_dashboard"}
						cancelbookingreason={cancelbookingreason}
						cancelbookings={cancelbookings}
						appointmentId={this.state.appointmentId}
						handleSelectCancelbooking={this.handleSelectCancelbooking}
						handlecahngeCancelbooking={this.handlecahngeCancelbooking}
					/> */}
      </SideMenu>

      {/* <ModelComponent
					parent_id={"patient_dashboard"}
					hiddencloseIcon={true}
					open={this.state.open}
					onClose={this.openView}
					childrens={
						<ConfirmationPage
							appoinmentBooking
							parent_id={"patient_dashboard"}
							appointmentId={this.state.appointmentId}
							appointmentDetails={true}
							hidden={true}
						/>
						// <ConfirmBook
						//     appointmentId={this.state.appointmentId}
						//     appointmentDetails={true}
						//     hidden={true}
						// />
					}
					width={530}
					backgroundColorNone
				/> */}
    </Div>
  );
};

//const mapStateToProps = (state) => ({
  // readPerson: state?.personApiSlice?.readPerson,
  // CountryCode: state?.authSlice?.country_code_list,
//});

// export default connect(
//   mapStateToProps,
//   actions
// )(withAllContexts(withRouter(SearchForm)));
export default (withAllContexts(withRouter(SearchForm)));
