import React, { useEffect, useState } from "react";
import {
  Div,
  Paper,
  Select,
  TextInput,
  H6,
  Col,
  Row,
  Radio,
  Button,
  SideMenu,
  Divider,
  Text,
} from "qdm-component-library";
import "./style.css";
import withAllContexts from "../../../hoc/withAllContexts";
import actions from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { UIColor } from "../../../themes/theme";
import {
  Card,
  CardHeader,
  Dialog,
  Grid,
  IconButton,
  List,
  Typography,
  makeStyles,
} from "@material-ui/core";
import FileViewer from "react-file-viewer";
import eyeIcon from "../../../assets/img/svg/icons8-eye.svg";
import { getSize } from "../../patientRegistration/components/handlers";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import { ToBase64, readFileAsBase64 } from "../../../utils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    backgroundColor: "transparent",
  },
  dialogRoot: {
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(0,0,0,0.9)",
    },

    zIndex: "999999 !important",
  },
  chips: {
    "& .MuiChip-root": {
      borderRadius: 8,
      border: "1px solid #E0E0E0",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 8,
      },
    },
  },
  autocompleteBorder: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
        borderRadius: 8,
      },
    },
  },
}));

const ReferralInRegistration = (props) => {
  let styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      border: "1px solid #E0E0E0",
    },
    containedButton: {
      backgroundColor: UIColor.primaryColor.color,
      borderColor: UIColor.primaryColor.color,
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontFamily: "pc_medium !important",
      fontSize: 14,
    },
    errorborderStyle: {
      borderRadius: 8,
      borderColor: "#FF0000",
    },
  };
  const dispatch = useDispatch();
  const classes = useStyles();
  const [referralRegistrationdatas, setReferralRegistratiodatas] = useState({
    ...props?.referralRegistrationdatas,
  });
  const [facilityNameData, setFacilityNameData] = useState([]);
  const [serviceRequestedData, setServiceRequestedData] = useState([]);
  const [practitionerNameData, setPractitionerNameData] = useState([]);
  const [opensidemenu, setopensidemenu] = useState(false);
  let isHealthDisease = false;
  const [state, setState] = React.useState({
    openFile: null,
    viewFileName: null,
    fileType: null,
    open: false,
  });
  const setData = (name, value) => {
    let updatedData = { ...referralRegistrationdatas };
    if (name === "Other_Health_Condition") {
      if (updatedData?.Other_Health_Condition?.length > 0) {
        const seen = new Set();
        const filteredArr = value.filter((el) => {
          const duplicate = seen.has(el.value);
          seen.add(el.value);
          return !duplicate;
        });
        updatedData[name] = filteredArr;
        setReferralRegistratiodatas(updatedData);
        props.setReferralRegistratiodatas(updatedData);
        return;
      }
    }
    updatedData[name] = value;
    setReferralRegistratiodatas(updatedData);
    props.setReferralRegistratiodatas(updatedData);
  };

  const GetIdAndDisplay1 = (data) => {
    let list = [];

    for (let index in data) {
      const item = data[index];
      const datatemp = {
        // org: item.OrgID ?? "",
        value: item.name,
        _id: item._id,
      };

      list.push(datatemp);
    }
    return list;
  };
  const GetIdAndDisplay = (data) => {
    let list = [];

    for (let index in data) {
      const item = data[index];
      const datatemp = {
        chargecode: item.chargecode,
        value: item.longdesc,
        _id: item._id,
      };

      list.push(datatemp);
    }
    return list;
  };
  const { editData } = props;
  useEffect(() => {
    if (props?.loadDefaultData) {
      setReferralRegistratiodatas({ ...props?.referralRegistrationDatas });
    }
  }, [props?.loadDefaultData]);
  useEffect(() => {
    if (editData) {
      setReferralRegistratiodatas({ ...props?.referralRegistrationDatas });
    }
  }, [editData]);
  const FlterOptions3 = (props, type, istrue, index) => {
    let filteredArray = props.filter((obj) => obj.hasOwnProperty([type]));
    let list = [];
    let data = filteredArray[0]?.[type].Value;
    for (let index in data) {
      var datatemp = {
        // isdisabled:false,
        value: data[index]?.display,
        _id: data[index]?._id,
        label: data[index]?.display,
      };
      list.push(datatemp);
    }
    return list;
  };

  const facilityName = async () => {
    const response = await dispatch(actions.Get_Facility_Name());
    setFacilityNameData(response?.payload?.data);
  };
  const serviceRequested = async () => {
    const response = await dispatch(actions.Get_Service_Requested());
    setServiceRequestedData(response?.payload?.data);
  };
  const practitionerName = async () => {
    const response = await dispatch(actions.Get_Practitioner_Name());
    setPractitionerNameData(response?.payload?.data);
  };
  const { labelStyle, borderStyle, containedButton, errorborderStyle } = styles;
  const { parent_id } = props;
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);
  useEffect(() => {
    facilityName();
    serviceRequested();
    practitionerName();
  }, []);
  useEffect(() => {
    if (props?.stateClear)
      setReferralRegistratiodatas({ ...props?.referralRegistrationdatas });
  }, [props?.stateClear]);

  const handleClose = () => {
    setState({
      openFile: null,
      fileType: null,
      open: false,
    });
    forceUpdate();
  };
  const createFile = async (url, name, type) => {
    let response = await fetch(url);
    let data = await response.blob();
    let metadata = {
      type,
    };
    const file = new File([data], name, metadata);
    return {
      file,
      size: data.size,
    };
  };

  const getBase64Url = async (files) => {
    let base64 = await readFileAsBase64(files ?? {});
    console.log(base64, "converttobase64");
    return base64;
  };

  const viewFile = async (file, idx) => {
    let data = "";
    if (file?.url) {
      let getFile = await createFile(file?.url, file?.fileName, file?.filetype);
      console.log("^^^^^^", getFile, file);
      referralRegistrationdatas?.attachment?.forEach((file, i) => {
        if (i === idx) {
          file.size = getFile?.size;
        }
      });
      data = await getBase64Url(getFile?.file);
      // data = base64.split(';base64,').pop();
      console.log("^^^^^^", data);
    } else {
      data = await ToBase64(file);
    }
    setState({
      openFile: file?.url,
      viewFileName: file?.fileName,
      fileType: file?.filetype,
      open: true,
    });
    forceUpdate();
  };
  return (
    <Div id={`${parent_id}referral_parent_parent_div`} className="pi-root">
      <Paper
        id={`${parent_id}referral_parent_parent_paper`}
        className="pi-paper"
      >
        <Div id={`${parent_id}referral_parent_label_div`}>
          <H6 id={`${parent_id}referral_parent_label_h6`} className="pi-title">
            Referral In Registration
          </H6>
        </Div>
        <Row id={`${parent_id}referral_parent_parent_row`}>
          <Col
            id={`${parent_id}referral_parent_Referring_from_Facility_col`}
            md={2}
            lg={3}
            sm={6}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            <Select
              id={`${parent_id}referral_parent_Referring_from_Facility_select`}
              options={GetIdAndDisplay1(
                props?.facilityNameData ?? facilityNameData
              )}
              onChangeValue={(e) => setData("Referring_from_Facility", e)}
              value={referralRegistrationdatas?.Referring_from_Facility?.value}
              label="Referring from Facility"
              placeholder="select..."
              showArrow
              labelStyle={labelStyle}
              //inLineStyles={borderStyle}
              hoverColor="#0071F2"
              disabled={props?.IsViewMode}
              isRequired={true}
              inLineStyles={
                props?.isError &&
                  !referralRegistrationdatas?.Referring_from_Facility?.value
                  ? errorborderStyle
                  : borderStyle
              }
            />
          </Col>
          <Col
            id={`${parent_id}referral_parent_Service_Requested_col`}
            md={2}
            lg={3}
            sm={6}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            <Select
              id={`${parent_id}referral_parent_Service_Requested_select`}
              options={GetIdAndDisplay(
                props?.serviceRequestedData ?? serviceRequestedData
              )}
              onChangeValue={(e) => setData("Service_Requested", e)}
              value={referralRegistrationdatas?.Service_Requested?.value}
              label="Service Requested"
              placeholder="select..."
              showArrow
              labelStyle={labelStyle}
              // inLineStyles={borderStyle}
              hoverColor="#0071F2"
              disabled={props?.IsViewMode}
              isRequired={true}
              inLineStyles={
                props?.isError &&
                  !referralRegistrationdatas?.Service_Requested?.value
                  ? errorborderStyle
                  : borderStyle
              }
            />
          </Col>
          <Col
            id={`${parent_id}referral_parent_Date_of_Referral_In_col`}
            md={2}
            lg={3}
            sm={6}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            <TextInput
              id={`${parent_id}referral_parent_Date_of_Referral_In_label_textinput`}
              onChange={(e) => setData("Date_of_Referral_In", e.target.value)}
              value={referralRegistrationdatas?.Date_of_Referral_In ?? ""}
              label="Date of Referral In"
              //   placeholder=""
              labelStyle={labelStyle}
              max={moment().format("YYYY-MM-DD")}
              // style={borderStyle}
              hoverColor="#0071F2"
              type="date"
              disabled={props?.IsViewMode}
              isRequired={true}
              style={
                props?.isError &&
                  !referralRegistrationdatas?.Date_of_Referral_In
                  ? errorborderStyle
                  : borderStyle
              }
            />
          </Col>
          <Col
            id={`${parent_id}referral_parent_Referring_Doctor_col`}
            md={2}
            lg={3}
            sm={6}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            {/* <Select
              id={`${parent_id}referral_parent_Referring_Doctor_select`}
              options={GetIdAndDisplay1(props?.practitionerNameData ?? practitionerNameData)}
              onChangeValue={(e) => setData("Referring_Doctor", e)}
              value={referralRegistrationdatas?.Referring_Doctor?.value}
              label="Referring Doctor"
              placeholder="select..."
              showArrow
              labelStyle={labelStyle}
              // inLineStyles={borderStyle}
              hoverColor="#0071F2"
              disabled={props?.IsViewMode}
              isRequired={true}
              inLineStyles={
                props?.isError && !referralRegistrationdatas?.Referring_Doctor?.value ? errorborderStyle
                      : borderStyle
                  }
            /> */}
            {/* <Select
              id={`${parent_id}referral_parent_Referring_Doctor_select`}
              options={GetIdAndDisplay1(props?.practitionerNameData ?? practitionerNameData)}
              onChangeValue={(e) => setData("Referring_Doctor", e)}
              value={referralRegistrationdatas?.Referring_Doctor?.value}
              label="Referring Doctor"
              placeholder="select..."
              showArrow
              labelStyle={labelStyle}
              // inLineStyles={borderStyle}
              hoverColor="#0071F2"
              disabled={props?.IsViewMode}
              isRequired={true}
              inLineStyles={
                props?.isError && !referralRegistrationdatas?.Referring_Doctor?.value ? errorborderStyle
                      : borderStyle
                  }
            /> */}

            <TextInput
              id={`${parent_id}referral_parent_disease_label_textinput`}
              onChange={(e) => {
                let pattern = /^[a-z\s]+$/i;
                let result = pattern.test(e.target.value);
                if (result || e.target.value === "") {
                  setData("Referring_Doctor", e.target.value);
                }
              }}
              value={referralRegistrationdatas?.Referring_Doctor}
              label={"Referring Doctor"}
              labelStyle={labelStyle}
              placeholder="Referring Doctor"
              // style={borderStyle}
              hoverColor="#0071F2"
              type="text"
              maxLength={500}
              disabled={props?.IsViewMode}
              isRequired={true}
              style={
                props?.isError && !referralRegistrationdatas?.Referring_Doctor
                  ? errorborderStyle
                  : borderStyle
              }
            />
          </Col>
          <Col
            id={`${parent_id}referral_parent_Hepatitis_col`}
            md={2}
            lg={3}
            sm={6}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            <Select
              id={`${parent_id}referral_parent_Hepatitis_select`}
              onChangeValue={(e) => setData("Hepatitis", e)}
              value={referralRegistrationdatas?.Hepatitis?.value}
              options={FlterOptions3(props?.allMasters, "HEPATITIS")}
              label="Hepatitis/HIV"
              placeholder="select..."
              showArrow
              labelStyle={labelStyle}
              // inLineStyles={borderStyle}
              hoverColor="#0071F2"
              disabled={props?.IsViewMode}
              isRequired={true}
              inLineStyles={
                props?.isError && !referralRegistrationdatas?.Hepatitis?.value
                  ? errorborderStyle
                  : borderStyle
              }
            />
          </Col>
          <Col
            id={`${parent_id}referral_parent_registration_type_col`}
            md={2}
            lg={3}
            sm={6}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            <Select
              id={`${parent_id}referral_parent_registration_type_select`}
              onChangeValue={(e) => setData("registration_type", e)}
              value={referralRegistrationdatas?.registration_type?.value}
              options={FlterOptions3(props?.allMasters, "REGISTRATIONTYPE")}
              label="Registration Type"
              placeholder="select..."
              showArrow
              labelStyle={labelStyle}
              // inLineStyles={borderStyle}
              hoverColor="#0071F2"
              disabled={props?.IsViewMode}
              isRequired={true}
              inLineStyles={
                props?.isError &&
                  !referralRegistrationdatas?.registration_type?.value
                  ? errorborderStyle
                  : borderStyle
              }
            />
          </Col>
          {referralRegistrationdatas?.registration_type?.value ===
            "Transfer from Other Hospital" ? (
            <Col
              id={`${parent_id}referral_parent_patienttype_col`}
              md={2}
              lg={3}
              sm={6}
              xs={12}
              className="no-padding-left"
              inLineStyles={{ padding: 10 }}
            >
              <TextInput
                id={`${parent_id}referral_parent_Previous_Dialysis_Centre_label_textinput`}
                onChange={(e) =>
                // setData("Previous_Dialysis_Centre", e.target.value)
                {
                  let pattern = /^[a-z0-9\s]+$/i;
                  let result = pattern.test(e.target.value);
                  if (result || e.target.value === "") {
                    setData("Previous_Dialysis_Centre", e.target.value);
                  }
                }
                }
                value={referralRegistrationdatas?.Previous_Dialysis_Centre}
                label={"Previous Dialysis Centre"}
                labelStyle={labelStyle}
                // style={borderStyle}
                hoverColor="#0071F2"
                type="text"
                disabled={props?.IsViewMode}
                isRequired={true}
                style={
                  props?.isError &&
                    !referralRegistrationdatas?.Previous_Dialysis_Centre
                    ? errorborderStyle
                    : borderStyle
                }
              />
            </Col>
          ) : null}
          <Col
            id={`${parent_id}referral_parent_Other_Health_Condition_col`}
            md={
              referralRegistrationdatas?.Other_Health_Condition?.length > 1
                ? 6
                : 2
            }
            lg={
              referralRegistrationdatas?.Other_Health_Condition?.length > 1
                ? 6
                : 3
            }
            sm={6}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            {/* <Select
              id={`${parent_id}referral_parent_Other_Health_Condition_select`}
              onChangeValue={(e) => setData("Other_Health_Condition", e)}
              value={referralRegistrationdatas?.Other_Health_Condition?.value}
              options={FlterOptions3(
                props?.allMasters,
                "OTHERHEALTHCONDITIONS"
              )}
              label="Other Health Condition"
              placeholder="select..."
              showArrow
              labelStyle={labelStyle}
              // inLineStyles={borderStyle}
              hoverColor="#0071F2"
              disabled={props?.IsViewMode}
              isRequired={true}
              inLineStyles={
                props?.isError && !referralRegistrationdatas?.Other_Health_Condition?.value ? errorborderStyle
                      : borderStyle
                  }
            /> */}
            <Text
              id={`${parent_id}_communication_language_label_text`}
              style={{
                color: "#6F6F6F",
                fontSize: "12px",
                paddingLeft: "3px",

                fontFamily: "pc_regular",
              }}
            >
              Other Health Condition <Text style={{ color: "red" }}>*</Text>
            </Text>
            <Autocomplete
              id={`${parent_id}_OtherHealthCondition_autocomplete`}
              multiple
              fullWidth
              options={FlterOptions3(
                props?.allMasters,
                "OTHERHEALTHCONDITIONS"
              )}
              style={{
                marginLeft: "3px",
                paddingTop: "5px",
                marginTop: "-5px",
              }}
              size="small"
              getOptionLabel={(option) => option.label}
              value={referralRegistrationdatas?.Other_Health_Condition ?? []}
              onChange={(e, v) => setData("Other_Health_Condition", v)}
              className={
                props?.isError &&
                  (referralRegistrationdatas?.Other_Health_Condition?.length ===
                    0 ||
                    referralRegistrationdatas?.Other_Health_Condition ===
                    undefined)
                  ? classes.autocompleteBorder
                  : classes.chips
              }
              hoverColor="#0071F2"
              renderInput={(params) => (
                <TextField
                  id={`${parent_id}_communication_select_label_textfield`}
                  style={{ height: "20px", borderRadius: "8px" }}
                  {...params}
                  variant="outlined"
                  placeholder="select..."
                  size="small"
                // label ={"other health"}
                // required={true}
                />
              )}
              disabled={props?.IsViewMode}
            />
          </Col>

          <SideMenu
            id="patient_dashboard_cancel_sidemenu"
            open={opensidemenu}
            direction="right"
            width={440}
            color="default"
            onClose={() => setopensidemenu(false)}
            style={{ padding: 0, zIndex: 1000000 }}
          >
            <Grid container direction="column" style={{ marginTop: "20px" }}>
              <Grid item container direction="column">
                <Grid item style={{ marginLeft: "30px" }}>
                  <Typography variant="h6" className={classes.title}>
                    {referralRegistrationdatas?.attachment?.length > 0
                      ? "Uploaded Documents"
                      : "No Documents"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider></Divider>
                </Grid>
              </Grid>
              <Grid item container>
                {referralRegistrationdatas?.attachment?.map((val, i) => (
                  <Card
                    style={{ width: "90%", marginLeft: "20px" }}
                    elevation={0}
                  >
                    <CardHeader
                      avatar={<DescriptionOutlinedIcon />}
                      action={
                        <IconButton onClick={() => viewFile(val, i)}>
                          <VisibilityOutlinedIcon />
                        </IconButton>
                      }
                      title={val?.fileName || val?.fileid}
                      subheader={val?.size ? getSize(val?.size) : ""}
                    />
                  </Card>
                ))}
              </Grid>
              {referralRegistrationdatas?.attachment?.length > 0 ? (
                <Divider />
              ) : null}
              <Grid item container>
                {state?.open && (
                  <div
                    style={{ width: "100%", height: "100vh", margin: "auto" }}
                  >
                    <Grid container style={{ background: "transparent" }}>
                      <Grid item style={{ width: "50%" }}>
                        <Grid>
                          <Typography
                            id="UMHit_14"
                            style={{
                              color: "black",
                              marginTop: "9px",
                              visibility: "hidden",
                            }}
                          >
                            {state.viewFileName}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          width: "50%",
                          paddingRight: "30px",
                          paddingTop: "16px",
                        }}
                      >
                        <div onClick={() => handleClose()}>
                          <Typography
                            style={{
                              color: "#000",
                              cursor: "pointer",
                              fontSize: "10px",
                              fontWeight: 600,
                            }}
                          >
                            CLOSE X
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid id="UMHit_17" className="openFile-parent">
                      <FileViewer
                        id="UMHit_16"
                        fileType={state.fileType}
                        filePath={state.openFile}
                      />
                    </Grid>
                  </div>
                )}
              </Grid>
            </Grid>
          </SideMenu>
          <Col
            id={`${parent_id}referral_parent_patienttype_col`}
            md={2}
            lg={3}
            sm={6}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            {referralRegistrationdatas?.Other_Health_Condition?.map((v, i) => {
              if (
                !isHealthDisease &&
                (v?._id === "CodingMaster/11477" ||
                  v?._id === "CodingMaster/11478")
              ) {
                isHealthDisease = true;
                return (
                  <TextInput
                    id={`${parent_id}referral_parent_disease_label_textinput`}
                    onChange={(e) => {
                      let pattern = /^[a-z\s]+$/i;
                      let result = pattern.test(e.target.value);
                      if (result || e.target.value === "") {
                        setData("disease", e.target.value);
                      }
                    }}
                    value={referralRegistrationdatas?.disease}
                    label={"Health Disease"}
                    labelStyle={labelStyle}
                    // style={borderStyle}
                    hoverColor="#0071F2"
                    type="text"
                    maxLength={500}
                    disabled={props?.IsViewMode}
                    isRequired={true}
                    style={
                      props?.isError && !referralRegistrationdatas?.disease
                        ? errorborderStyle
                        : borderStyle
                    }
                  />
                );
              }
            })}
          </Col>
          {/* {props?.IsViewMode && (
            <Col
              id={`${parent_id}referral_parent_Other_Health_Condition_col`}
              md={2}
              lg={3}
              sm={6}
              xs={12}
              className="no-padding-left"
              inLineStyles={{ padding: 10 }}
            >
              <IconButton
                style={{ marginTop: "20px" }}
                onClick={() => setopensidemenu(true)}
              >
                <CloudDownloadOutlinedIcon style={{ marginRight: "10px" }} />{" "}
                <Typography variant="body2">View Documents</Typography>
              </IconButton>
            </Col>
          )} */}
        </Row>
        {props?.IsViewMode && (
          <Button
            id={`${parent_id}_Financial_save_buttom`}
            inLineStyles={{
              ...containedButton,
              marginTop: 30,
              marginBottom: 20,
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
            onClick={() => props?.viewModeNavigateToClinicalInfo()}
          >
            Continue to Clinical Information
          </Button>
        )}
      </Paper>
    </Div>
  );
};

export default withAllContexts(ReferralInRegistration);
