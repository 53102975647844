import React from "react";
import actions from "../../redux/actions";
import withAllContexts from "../../hoc/withAllContexts";
import { connect } from "react-redux";
//import { withRouter } from "react-router-dom";
// import { Routes } from "../../router/routes";
//import { BloodSampleGray } from "../svg";
//import { BloodSampleGray } from "../svg";
import {
  Div,
  Card,
  Image,
  Icons,
  H6,
  Text,
  Col,
  Row,
  Divider,
} from "qdm-component-library";
import Popover from "@material-ui/core/Popover";
import ViewTrend from "./viewTrend";
import moment from "moment";
import { getData } from "../../utils";
import { WeightKgIcon } from "../svg/components/weightKgIcon";
import { HeightsIcon } from "../svg/components/heightsIcon";
import { BloodSamplesIcon } from "../svg/components/bloodSampleIcon";
import { HeartPluseIcon } from "../svg/components/heartPulseIcon";
import { Respiration } from "../svg/components/respiration";
import { TemperatureIcon } from "../svg/components/temperatureIcon";
import { WSTCM } from "../svg/components/wstcm";
import { Head } from "../svg/components/head";
import { Bsa } from "../svg/components/bsa";
import { BmiIcon } from "../svg/components/bmiIcon";
import { Spo2 } from "../svg/components/spo2";
import { Lmp } from "../svg/components/lmp";
import { BloodGroup } from "../svg/components/bloodGroup";
import { UIColor } from "../../themes/theme";
import { CircularProgress } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import icons8chart from "../../assets/img/svg/icons8-chart.svg";
import { useLocation } from "react-router-dom";
const vitalsList = {
  WEIGHT: <WeightKgIcon />,
  HEIGHT: <HeightsIcon />,
  LOWBP: <BloodSamplesIcon />,
  HIGHBP: <BloodSamplesIcon />,
  LDL: <BloodSamplesIcon />,
  HDL: <BloodSamplesIcon />,
  PULSE: <HeartPluseIcon />,
  RESP: <Respiration />,
  TEMP: <TemperatureIcon width={10} height={16} />,
  WSTCM: <WSTCM width={"30"} height={"20"} />,
  HEADC: <Head width={"30"} height={"20"} />,
  O2S: <Spo2 />,
  LMT: <Lmp />,
  BG: <BloodGroup />,
  WGT: <BmiIcon width={12} />,
  BMI: <BmiIcon width={"30"} height={"20"} />,
  BSA: <Bsa width={"30"} height={"20"} />,
};
const VitalSigns = (props) => {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     showDate: "Today",
  //     showDateCount: 0,
  //     showList: null,
  //     anchorEl: null,
  //     EID: null,
  //     showMore: false,
  //     loading:true,
  //   };
  // }
  const dispatch = useDispatch();
  const location = useLocation();
  const [showDate, setshowDate] = React.useState("Today");
  const [showDateCount, setshowDateCount] = React.useState(0);
  const [showList, setshowList] = React.useState(null);
  const [anchorEl, setanchorEl] = React.useState(null);
  const [showMore, setshowMore] = React.useState(false);
  const [readVitals, setReadVitals] = React.useState([]);
  // const vitalsData = useSelector(
  //   (state) => state?.VitalsApiSlice?.vitalsBasedEncounter
  // );

  const styles = {
    rowFlex: {
      display: "flex",
      alignItems: "center",
    },
    iconWrapper: {
      width: 30,
      height: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      // borderRadius: "50%",
      // backgroundColor: "#E5F1FE",
    },
    title: {
      margin: 0,
      fontSize: 14,
      marginLeft: 10,
      fontWeight: 600,
      color: UIColor.primaryText.color,
    },
    card: {
      padding: 10,
      paddingBottom: 14,
      boxShadow: "none",
    },
    span: {
      fontSize: 12,
    },
    iconSize: {
      width: 15,
      height: 15,
    },
    chartIcon: {
      marginLeft: "6px",
      //cursor: "pointer",
    },
    errors: {
      marginTop: "3%",
      marginBottom: "3%",
      color: "red",
      backgroundColor: "#FFF4F4",
      display: "flex",
      justifyContent: "space-between",
      padding: 8,
      borderRadius: 18,
      alignItems: "center",
    },
    normal: {
      marginTop: "3%",
      marginBottom: "3%",
      color: "#0071F2",
      backgroundColor: "#0071f217",
      display: "flex",
      justifyContent: "space-between",
      padding: 8,
      borderRadius: 18,
      alignItems: "center",
    },
    iconDiv: { display: "flex", alignItems: "end" },
    leftRightIcons: {
      color: UIColor.secondaryText.color,
      marginLeft: 5,
      padding: 8,
      borderRadius: 50,
      cursor: "pointer",
    },
  };

  // const readVitals = useSelector(
  //   (state) => state?.patientDetails?.vitalsBasedEncounter?.data
  // );
 const getVitalsBasedPatient = async () => {
   let allVitals = await dispatch(
     actions.GET_VITALS_BASED_PATIENT({ 
       PID: location?.state?.patientId,
       encounterId:location?.state?.encounterID||location?.state?.encounter_id,
     })
   );
   setReadVitals(allVitals?.payload?.data);
 }
 const readVitalsloading = useSelector(
   (state) => state?.patientDetails?.vitalsBasedEncounter?.loading
 );


  const getList = (data) => {
    if (data?.length > 0) {
      let list = {};
      data.map((val) => {
        let creatediff = moment().diff(
          moment(moment.unix(val?.createddate), "DD/MM/YYYY"),
          "days"
        );
        list[creatediff] = val;
      });

      let showDate1 = "";
      let showDateCount1 = 0;
      let showList1;
      if (list[0]) {
        showDate1 = "Today";
        showDateCount1 = 0;
        showList1 = list[0];
      } else {
        let keyIndex = Object.keys(list);
        keyIndex = keyIndex
          .map((val) => parseInt(val))
          .sort()
          .reverse();
        let dataIndex = keyIndex[keyIndex.length - 1];
        if (list[dataIndex]) {
          showDate1 = getData(
            new Date(
              // moment(list[dataIndex]?.createddate?.split(" ")?.[0],"DD/MM/YYYY")
              moment(moment.unix(list[dataIndex]?.createddate), "DD/MM/YYYY")
            )
          );
          showDateCount1 = dataIndex;
          showList1 = list[dataIndex];
        }
      }
      setshowDate(showDate1);
      setshowDateCount(showDateCount1);
      setshowList(showList1);
    }
  };

  const handleArrow = async (value) => {
    //let { data } = props;
    // let { showDateCount } = this.state;
    let list = {};
    readVitals?.map((val) => {
      let creatediff = moment().diff(
        moment(moment.unix(val?.createddate), "DD/MM/YYYY"),
        "days"
      );
      list[creatediff] = val;
    });
    let keyIndex = Object.keys(list);
    keyIndex = keyIndex
      .map((val) => parseInt(val))
      .sort()
      .reverse();
    if (value) {
      let index = keyIndex.indexOf(showDateCount) + 1;
      let dataIndex = keyIndex[index];
      if (list[dataIndex]) {
        setshowDate(
          dataIndex === 0
            ? "Today"
            : getData(new Date(moment.unix(list[dataIndex]?.createddate)))
        );
        setshowDateCount(dataIndex);
        setshowList(list[dataIndex]);
      }
    } else {
      let index = keyIndex.indexOf(showDateCount) - 1;
      let dataIndex = keyIndex[index];
      if (list[dataIndex]) {
        setshowDate(
          getData(new Date(moment.unix(list[dataIndex]?.createddate)))
        );
        setshowDateCount(dataIndex);
        setshowList(list[dataIndex]);
      }
    }
  };

  const getAbnormalList = () => {
    let list = [];
    if (showList) {
      showList.component.map((val) => {
        if (val.isabnormal) {
          list.push(val);
        }
      });
    }
    let text = list.map((val) => val.obscode).join("&&");
    return { list, text };
  };

  React.useEffect(() => {
      getList(readVitals);
  }, [readVitals]);
  
  React.useEffect(() => {
    getVitalsBasedPatient();
  }, [readVitalsloading]);

  const { title = "",parent_id} = props;

  let abnormalList = getAbnormalList();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Card id={`${parent_id}_parent_card`} style={styles.card}>
        <Div id={`${parent_id}_icon_div`} style={styles.rowFlex}>
          <Div
            id={`${parent_id}_icon_sub_div`}
            style={{ ...styles.rowFlex, flex: 1 }}
          >
            <Div
              id={`${parent_id}_HeartPluseIcon_div`}
              style={styles.iconWrapper}
            >
              <HeartPluseIcon
                id={`${parent_id}_HeartPluseIcon_div`}
                color="#3673B9"
                width={15}
                height={15}
              />
            </Div>
            <H6 id={`${parent_id}_title_h6`} style={styles.title}>
              {title}
            </H6>
            <Div
              id={`${parent_id}_chart_div`}
              style={styles.chartIcon}
              // onClick={}
            >
              <Image
                id={`${parent_id}_chart_image`}
                src={icons8chart}
                style={styles.iconSize}
              />
            </Div>
          </Div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={()=>setanchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <ViewTrend parent_id={"vital_signs"} />
          </Popover>
          <Div
            id={`${parent_id}_${showDate}_parent_div`}
            style={styles.rowFlex}
          >
            <Text
              id={`${parent_id}_${showDate}_text`}
              className={"pc_regular"}
              style={{
                ...styles.span,
                margin: "0px 5px",
                fontWeight: 600,
                color: UIColor.secondaryText.color,
              }}
            >
              {showDate}
            </Text>
            <Icons
              id={`${parent_id}_leftRightIcons`}
              onClick={() => handleArrow(0)}
              fontIcon="angle-left"
              style={styles.leftRightIcons}
            />
            <Icons
              id={`${parent_id}_leftRightIcons_one`}
              onClick={() => handleArrow(1)}
              fontIcon="angle-right"
              style={styles.leftRightIcons}
            />
          </Div>
        </Div>
        <Divider
          id={`${parent_id}_bottom_divider`}
          style={{ margin: "5px -10px", height: "0.1px" }}
        />
        {readVitalsloading ? (
          <>
            <Div
              id={`vitals_CircularProgress_div`}
              style={{ display: "flex", height: "100px" }}
            >
              <CircularProgress
                id={`vitals_CircularProgress`}
                style={{ margin: "auto" }}
                size={23}
              />
            </Div>
          </>
        ) : (
          <>
            {!showList && (
              <H6
                id={`${parent_id}_No_data_h6`}
                style={{ textAlign: "center", fontSize: "15px" }}
              >
                No data Found
              </H6>
            )}
            {showList && abnormalList.list.length === 0 && (
              <Div
                id={`${parent_id}_in_normal_parent_div`}
                style={styles.normal}
              >
                <Div
                  id={`${parent_id}_in_normal_sub_div`}
                  style={styles.iconDiv}
                >
                  <Icons
                    id={`${parent_id}_in_normal_exclamation_icons`}
                    fontIcon="exclamation-circle"
                    style={{
                      color: "#0071F2",
                      marginRight: 5,
                    }}
                  />
                  <Text
                    id={`${parent_id}_in_normal_text`}
                    className={"pc_medium"}
                    style={{
                      fontSize: "10px",
                    }}
                  >
                    Vital signs are in normal
                  </Text>
                </Div>
                <Text
                  id={`${parent_id}_in_normal_showMore_text`}
                  className={"pc_medium"}
                  style={{
                    fontSize: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => setshowMore(!showMore)}
                >
                  {showMore ? "Hide" : "Show all"}
                </Text>
              </Div>
            )}
            {showList && abnormalList.list.length > 0 && (
              <Div
                id={`${parent_id}_Vitals_abnormal_parent_div`}
                style={styles.errors}
              >
                <Div
                  id={`${parent_id}_Vitals_abnormal_sub_div`}
                  style={styles.iconDiv}
                >
                  <Icons
                    id={`${parent_id}_Vitals_abnormal_exclamation_icons`}
                    fontIcon="exclamation-circle"
                    style={{
                      color: "#FF4D4A",
                      marginRight: 5,
                    }}
                  />
                  <Text
                    id={`${parent_id}_Vitals_abnormal_text`}
                    className={"pc_medium"}
                    style={{ fontSize: "10px" }}
                  >
                    {abnormalList.list.length} Vitals abnormal
                  </Text>
                </Div>
                <Text
                  id={`${parent_id}_Vitals_abnormal_showMore_text`}
                  className={"pc_medium"}
                  style={{
                    fontSize: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => setshowMore(!showMore)}
                >
                  {showMore ? "Hide" : "Show all"}
                </Text>
              </Div>
            )}
            {showList && (
              <Div
                id={`${parent_id}_showMore_div`}
                style={{
                  ...styles.rowFlex,
                  marginTop: 10,
                  flexWrap: "wrap",
                  height: showMore ? "auto" : 20,
                  overflow: "hidden",
                }}
              >
                {showList?.component.map((l, i) => {
                  return (
                    <Row id={`${parent_id}_${i}_showMore_row`}>
                      {false ? (
                        ""
                      ) : (
                        <Col
                          md={1}
                          lg={1}
                          sm={4}
                          xs={4}
                          style={{
                            display: "flex",
                            marginBottom: "4%",
                          }}
                        >
                          {l?.obsvalue ? (
                            <Div
                              id={`${parent_id}_${i}_${
                                vitalsList[l?.obscode]
                              }_div`}
                              style={{
                                marginRight: 5,
                                opacity: "0.4",
                                color: l.isabnormal ? "red" : "",
                              }}
                            >
                              {vitalsList[l?.obscode]}
                            </Div>
                          ) : null}

                          {l?.obsvalue ? (
                            <H6
                              id={`${parent_id}_${i}_${l?.obsvalue}_div`}
                              style={{
                                ...styles.span,
                                color: l.isabnormal ? "red" : "#000",
                              }}
                            >
                              {l?.obscode
                                ? l?.abnormalmsg && l?.obsvalue
                                  ? `${l?.obsvalue}${JSON.parse(
                                      l?.abnormalmsg
                                    )}`
                                  : `${l?.obsvalue}`
                                : null}
                            </H6>
                          ) : null}
                        </Col>
                      )}
                    </Row>
                  );
                })}
              </Div>
            )}
          </>
        )}
      </Card>
    </div>
  );
};

// const mapStateToProps = (state) => ({

// });

export default withAllContexts(VitalSigns);
