import React, { useState, useEffect, useRef } from "react";
import { DrawerMeetingContext } from "./context";
import { Drawer, withStyles, Avatar } from "@material-ui/core";
import { DrawerMeetingProps } from "./utils";
import { VideoCallComponent } from "../src/components/avc/VideoCallComponent";
import { AvcSideDock } from "../src/assets/img/avc_sideDock";
import { AvcFullScreen } from "../src/assets/img/avcFullScreen";
import { MiniAvcComp } from "../src/assets/img/avc_mini";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import config from "../src/utils/configurations";

const styles = (theme) => ({
  paper: {
    zIndex: 9999,
    //  display: "none"
  },
  minimize: {
    zIndex: 9999,
    top: "calc(100vh - 80px)",
    position: "fixed",
    width: 286,
    display: "unset",
    height: 40,
    overflow: "hidden",
    right: 0,
    bottom: 20,
    borderRadius: 8,
  },
});

const AppMeeting = (props) => {
  const myRef = useRef();
  const [state, setState] = useState({
    open: false,
    direction: DrawerMeetingProps.direction.right,
    variant: DrawerMeetingProps.variant.temporary,
    user_name: "",
    isLarge: null,
    jwt: "",
    RoomName: "",
    widthAdjState: props.widthAdjState,
    component: <>Hai</>,
    counter: props.counter,
    pos: {
      top: 250,
      left: 150,
    },
    dragging: false,
    rel: null,
    domain: "",
  });

  const close = () => {
    if (document.getElementById("jitsi-iframe").getElementsByTagName("iframe")[0]) {
      document.getElementById("jitsi-iframe").getElementsByTagName("iframe")[0].src = "";
      document.getElementsByClassName("MuiDrawer-paperAnchorRight")[0].style.removeProperty("left");
      document.getElementsByClassName("MuiDrawer-paperAnchorRight")[0].style.removeProperty("top");
      document.getElementsByClassName("MuiDrawer-paperAnchorRight")[0].style.removeProperty("right");
      document.getElementsByClassName("MuiDrawer-paperAnchorRight")[0].style.removeProperty("bottom");
    }
    setState((prevState) => ({
      ...prevState,
      open: false,
      isLarge: null,
      component: <></>,
    }));
    props.onClose_ && props.onClose_();
  };

  const mouseDown = (e) => {
    if (state.widthAdjState === "mini") {
      document.getElementsByClassName("MuiDrawer-paperAnchorRight")[0].style.left = e.x + "px";
      document.getElementsByClassName("MuiDrawer-paperAnchorRight")[0].style.top = e.y + "px";
      document.getElementsByTagName("body")[0].style.removeProperty("userSelect");
    }
    document.addEventListener("mousemove", mouseMove);
    document.addEventListener("mouseup", mouseUp);
  };

  const mouseMove = (e) => {
    if (state.widthAdjState === "mini") {
      document.getElementsByClassName("MuiDrawer-paperAnchorRight")[0].style.left = e.x + "px";
      document.getElementsByClassName("MuiDrawer-paperAnchorRight")[0].style.top = e.y + "px";
      document.getElementsByTagName("body")[0].style.userSelect = "none";
    }
  };

  const mouseUp = (e) => {
    if (state.widthAdjState === "mini") {
      document.getElementsByTagName("body")[0].style.userSelect = "none";
    }
    document.removeEventListener("mousemove", mouseMove);
    document.removeEventListener("mouseup", mouseUp);
  };

  const setSceript = () => {
    let src = `${config.portal_call}://${config.avc_url}/external_api.js`;
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    document.head.appendChild(script);
  };

  useEffect(() => {
    setSceript();
  }, []);

  const set = async (props) => {
    setState((prevState) => ({ ...prevState, ...props }));
  };

  const widthAdj = (val) => {
    if (state.widthAdjState === "mini") {
      document.getElementsByClassName("MuiDrawer-paperAnchorRight")[0].style.removeProperty("left");
      document.getElementsByClassName("MuiDrawer-paperAnchorRight")[0].style.removeProperty("top");
      document.getElementsByClassName("MuiDrawer-paperAnchorRight")[0].style.right = "0px";
      document.getElementsByClassName("MuiDrawer-paperAnchorRight")[0].style.bottom = "0px";
      setState((prevState) => ({
        ...prevState,
        widthAdjState: val,
      }));
    } else {
      document.getElementsByClassName("MuiDrawer-paperAnchorRight")[0].style.display = "inherit";
      setState((prevState) => ({
        ...prevState,
        widthAdjState: val,
      }));
    }
  };

  const { classes } = props;
  const { direction, variant, open, component, isLarge } = state;

  return (
    <DrawerMeetingContext.Provider
      value={{
        ...state,
        setMeetingDrawer: set,
        onClose: close,
      }}
    >
      {props.children}
      <Drawer
        anchor={direction}
        open={open}
        onClose={close}
        hideBackdrop={true}
        variant={"persistent"}
        classes={{
          paper: state.widthAdjState === "mini" ? classes.minimize : classes.paper,
        }}
        ref={myRef}
        onMouseDown={(e) => mouseDown(e)}
      >
        <div style={state.widthAdjState === "full" ? { width: "100vw" } : { width: "" }}>
          <div
            style={{
              backgroundColor: "#000",
              padding: 8,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <IconButton
              component="span"
              style={{ padding: 2, fontSize: 18, backgroundColor: "red" }}
              onClick={close}
            >
              <CloseIcon htmlColor="#fff" style={{ fontSize: 18 }} />
            </IconButton>

            <div>&nbsp;&nbsp;&nbsp;</div>

            <div
              style={{
                backgroundColor: "#000",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                width: "100%",
              }}
            >
              <IconButton component="span" style={{ padding: 2 }} onClick={() => widthAdj("mini")}>
                <MiniAvcComp isactive={state.widthAdjState === "mini"} />
              </IconButton>
              &nbsp;&nbsp;&nbsp;
              <IconButton component="span" style={{ padding: 2 }} onClick={() => widthAdj("side")}>
                <AvcSideDock isactive={state.widthAdjState === "side"} />
              </IconButton>
              &nbsp;&nbsp;&nbsp;
              <IconButton component="span" style={{ padding: 2 }} onClick={() => widthAdj("full")}>
                <AvcFullScreen isactive={state.widthAdjState === "full"} />
              </IconButton>
            </div>
            &nbsp;&nbsp;&nbsp;
          </div>
          <VideoCallComponent
            userName={state.user_name}
            screenSize={state.widthAdjState === "mini"}
            open={open}
            RoomName={state.RoomName}
            jwt={state.jwt}
            closeFun={close}
            domain={config.avc_url}
          />
        </div>
      </Drawer>
    </DrawerMeetingContext.Provider>
  );
};

export default withStyles(styles)(AppMeeting);
