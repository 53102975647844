import { responsiveFontSizes, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles'
import React from 'react'
import { ThemeContext } from './context/index';
import { Themes } from './utils/constants';

const AppTheme = (props) => {

    let [theme, setTheme] = React.useState({
        name: Themes.default
    });

    const giveMeTheme = () => {
        let current_theme_json;

        //Geting the theme json for  the selected theme
        //refer following link for theme creation
        //https://bareynol.github.io/mui-theme-creator/

        switch (theme.name) {
            case Themes.default:
                current_theme_json = require('./themes/default.json');
                break;
            default:
                current_theme_json = require('./themes/default.json');
                break;
        }

        //Creating a Theme with json
        let current_theme = createTheme(current_theme_json);

        //Making theme work with responsive font size
        current_theme = responsiveFontSizes(current_theme);

        return current_theme;
    }
        //Themecontext is used for setting theme like dark and default from the children
    return (
        <ThemeContext.Provider value={{ ...theme, setTheme }}>
            <ThemeProvider theme={giveMeTheme()}>
                {props.children}
            </ThemeProvider>
        </ThemeContext.Provider>
    )
}

export default AppTheme;