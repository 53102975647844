import { Avatar, Drawer, Grid, useTheme } from "@mui/material";
import React from "react";
import { DrawerProps } from "../utils/constants";
import CloseIcon from "@mui/icons-material/Close";
export default function MuiDrawer({
  anchor = "",
  variant = "",
  open = false,
  isLarge = "false",
  close = () => null,
  ...props
}) {
  const theme = useTheme();
  const drawerState = {
    direction: DrawerProps.direction.right,
    variant: DrawerProps.variant.temporary,
    isLarge: null,
  };

  return (
    <Drawer
      anchor={anchor || DrawerProps.direction.right}
      variant={variant || DrawerProps.variant.temporary}
      open={open}
      onClose={close}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Avatar
        sx={{
          width: "50px",
          height: "50px",
          borderRadius: "50%",
          position: "fixed",
          right: props?.isLarge || drawerState.isLarge ? "1010px" : "410px", // Adjust based on state
          cursor: "pointer",
          top: 10,
          objectFit: "scale-down",
          backgroundColor: "#fff",
          ...((props?.isLarge || drawerState.isLarge) &&
            {
              // Apply extra-large styles when needed
            }),
          [theme.breakpoints.down("xs")]: {
            right: "313px",
          },
        }}
        onClick={close}
      >
        <CloseIcon sx={{ color: "black" }} />
      </Avatar>
      <Grid
        sx={{
          maxWidth: 400,
          minWidth: 400,
          background: "#fff",
          overflowX: "hidden",
          ...((props?.isLarge || drawerState.isLarge) && {
            maxWidth: 1000,
            minWidth: 1000,
          }),
          [theme.breakpoints.down("xs")]: {
            maxWidth: 300,
            minWidth: 300,
          },
        }}
      >
        {props?.children}
      </Grid>{" "}
    </Drawer>
  );
}
