import React, { useContext, useEffect, useState } from "react";
import actions from "../../redux/actions";
//import { withRouter } from 'react-router-dom'
import Verification from "../../assets/img/svg/verification.svg";
import { withStyles } from "@material-ui/core/styles";
import {
  Container,
  Div,
  //   Icons,
  Text,
  Divider,
  // Card,
  H6,
  // ListItem,
  Avatar,
  //   Dropdown,
  TextArea,
  Button,
} from "qdm-component-library";
import {
  checkError,
  AlertProps,
  getUserInfo,
  utcTOLocal,
  getImgUrl,
} from "../../utils";
import { UIColor } from "../../themes/theme";
import { BackdropContext } from "../../context";
import { Selection } from "../../components";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
import calendar from "../../assets/img/svg/calendar.svg";
import withAllContexts from "../../hoc/withAllContexts";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
const casbinjs = require("casbin.js");

const styles = (theme) => ({
  select: {
    marginTop: 16,
    "& fieldset": {
      borderColor: "#E0E0E0",
      borderRadius: 8,
    },
    "& input": {
      height: 19,
    },
  },
});

const CancelBookingPage = (props) => {
  const dispatch = useDispatch();
  const backdrop = useContext(BackdropContext)
  const [state, setState] = useState({
    resend: "",
    appointmentInfo: {},
    otp: 0,
    callcen: false,
    cacellationType: process.env.REACT_APP_CANCEL_APPMNT_TYPE_SINGLE,
    cancelBookingOtherReason:""

  });
  const onChangeState = (value) => {
    props.handleSelectCancelbooking(value);
    setState((prevState) => ({
      ...prevState,
      appointmentInfo: {
        ...state?.appointmentInfo,
        cancelbookingreason: value,
      },
    }));
  };
  const reasonForApptCancelModify = useSelector(
    (state) => state?.CommonSlice?.GetAllreasonMasters?.data?.CANCELREASON || []
  );

  useEffect(() => {
    onLoad();
  }, []);
  const onLoad = async () => {
    fetchInfo();
    await dispatch(actions.GET_REASON_MASTERS({type:["CANCELREASON"]}));
    // const roleName = decodedUserinfo.rolename;

    let roleData = localStorage.getItem("RoleData");
    let decodedRoleData = jwtDecode(roleData);
    // const accessObj = decodedRoleData?.[roleName.toLowerCase()]?.access || [];
    const accessObj = decodedRoleData?.access || [];
    const permission = { callcenter: accessObj };
    const authorizer = new casbinjs.Authorizer("manual", permission);
    authorizer.setPermission(permission);
    const result = await authorizer.can("callcenter", "4");

    setState((prevState) => ({
      ...prevState,
      callcen: result,
    }));
  };

  const fetchInfo = async () => {
    const data = await dispatch(
      actions.APPOINTMENT_READ({
        appointmentid: props.appointmentId,
        tenantid: "",
        facilityid: "",
      })
    );
    setState((prevState) => ({
      ...prevState,
      appointmentInfo: data?.payload?.data || {},
    }));
  };


  const { cancelbookings, cancelbookingreason, parent_id, classes } = props;
  return (
    <div id={`${parent_id}_cancelbook_parent_div`}>
      <Container
        id={`${parent_id}_cancelbook_parent_container`}
        key={"0"}
        name="container"
        fluid={false}
        style={{
          padding: "20px",
        }}
      >
        <Text
          id={`${parent_id}_cancelbook_appointment_text`}
          key={"0"}
          className="pc_semibold"
          style={{ fontSize: 20, color: "#101010" }}
          name={"Cancel Appointment"}
        ></Text>
        <Divider
          id={`${parent_id}_cancelbook_bottom_divider`}
          key={"1"}
          textWidth={"50"}
          textColor={"#000"}
          borderColor={"#f0f0f0"}
          borderWidth={"1"}
          orientation={"horizontal"}
          variant={"middle"}
          style={{ marginLeft: 0, marginRight: 0 }}
        //   style={qdmstyles.BKv}
        ></Divider>
        {cancelbookings === 2 && (
          <Div
            id={`${parent_id}_cancelbook_verification_div`}
            key={"0"}
            style={{ textAlign: "center" }}
          >
            <img alt={"Verification"} src={Verification} />
          </Div>
        )}
        {cancelbookings === 2 && (
          <Div
            id={`${parent_id}_cancelbook_your_visit_div`}
            key={"1"}
            className="qdm"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "14px",
            }}
          >
            <Text
              id={`${parent_id}_cancelbook_your_visit_text`}
              key={"0"}
              name={"Your appointment has been cancelled !"}
              style={{
                fontSize: "16px",
                textAlign: "center",
                color: "#38C20A",
                fontWeight: "600",
                textTransform: "",
              }}
            ></Text>
          </Div>
        )}
        <Div
          id={`${parent_id}_cancelbook_side_div`}
          style={{
            marginTop: "10px",
            boxShadow: "none",
            borderTop: cancelbookings === 2 && "1px solid #F0F0F0",
            borderBottom: "1px solid #F0F0F0",
            display: "flex",
            paddingBottom: "14px",
            paddingTop: cancelbookings === 2 && "16px",
            justifyContent: "space-around",
          }}
        >
          <Div
            id={`${parent_id}_cancelbook_side_sub_div`}
            style={{
              display: "flex",
              justifyContent: "space-between",
              // borderRight: "1px solid #F0F0F0",
              paddingRight: "13px",
            }}
          >
            <Div id={`${parent_id}_cancelbook_image_div`}>
              {/* <img
                  id={`${parent_id}_cancelbook_image`}
                  alt='img'
                  width='40px'
                  height='40px'
                  style={{ borderRadius: '50%', marginRight: '10px' }}
                  src='https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg'
                /> */}

              <Avatar
                id={`${parent_id}_cancelbook_logo_image`}
                key={"0"}
                variant="circle"
                letter="Logo"
                //src='https://d27zlipt1pllog.cloudfront.net/pub/static/version1608753130/frontend/Apollo/mobile/en_US/images/new-img/logo_2.png'
                alt="Image"
                style={{
                  // backgroundColor: '#F9F9F9',
                  paddingTop: "0px",
                  width: "40px",
                  height: "40px",
                  margin: "0px",
                  marginRight: "10px",
                }}
              >
                <Avatar
                  src={
                    getImgUrl(
                      state?.appointmentInfo?.resourcecode?.[0]?.photo?.[0]
                        ?.fileid
                    ) || undefined
                  }
                  id={`${parent_id}_cancelbook_image`}
                  alt="img"
                  width="40px"
                  height="40px"
                  style={{ borderRadius: "50%", marginRight: "10px" }}
                >
                  {
                    (state.appointmentInfo?.resourcecode?.[0]?.name?.[0]
                      ?.fulname
                      ? state.appointmentInfo?.resourcecode?.[0]?.name?.[0]
                        ?.fulname
                      : ""
                    )
                      ?.replaceAll("Mrs", "")
                      ?.replaceAll("Mr", "")
                      ?.replaceAll(".", "")[0]
                  }
                </Avatar>
              </Avatar>
            </Div>
            <Div id={`${parent_id}_cancelbook_appointmentinfo_div`}>
              <H6
                id={`${parent_id}_cancelbook_appointmentinfo_h6`}
                className="pc_regular"
                style={{
                  fontSize: "14px",
                  color: "#101010",
                }}
              >
                {state.appointmentInfo?.resourcecode?.[0]?.name?.[0]?.fulname ||
                  ""}
              </H6>
              <Text
                id={`${parent_id}_cancelbook_appointmentinfo_text`}
                className="pc_regular"
                style={{
                  fontSize: "10px",
                  letterSpacing: ".4px",
                  color: "#6f6f6f",
                }}
              >
                {state.appointmentInfo?.orgid?.[0]?.name || ""}
              </Text>
            </Div>
          </Div>
          <Div
            id={`${parent_id}_cancelbook_description_div`}
            style={{ width: 1, height: 42, backgroundColor: "#E0E0E0" }}
          />
          <Div
            id={`${parent_id}_cancelbook_description_sub_div`}
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "6px",
            }}
          >
            <Div id={`${parent_id}_cancelbook_calendar_div`}>
              <Avatar
                id={`${parent_id}_cancelbook_logo_image`}
                key={"0"}
                variant="circle"
                letter="Logo"
                src="https://d27zlipt1pllog.cloudfront.net/pub/static/version1608753130/frontend/Apollo/mobile/en_US/images/new-img/logo_2.png"
                alt="Image"
                style={{
                  backgroundColor: "#F9F9F9",
                  paddingTop: "0px",
                  width: "40px",
                  height: "40px",
                  margin: "0px",
                  marginRight: "10px",
                }}
              >
                <Avatar
                  id={`${parent_id}_cancelbook_calendar_image`}
                  //src='/static/media/calendar.374dbd8e.svg'
                  src={calendar}
                  alt="Image"
                  style={{
                    backgroundColor: "transparent",
                    height: "auto",
                    width: "16px",
                  }}
                  variant="square"
                ></Avatar>
              </Avatar>
            </Div>
            <Div>
              <H6
                id={`${parent_id}_cancelbook_convert_h6`}
                className="pc_regular"
                style={{
                  fontSize: "14px",
                  color: "#101010",
                }}
              >
                {utcTOLocal(
                  state.appointmentInfo?.slots?.[0]?.start,
                  "DD MMM YY | hh:mm a"
                )}
              </H6>
              <Text
                id={`${parent_id}_cancelbook_consultation_text`}
                className="pc_regular"
                style={{
                  fontSize: "10px",
                  letterSpacing: ".4px",
                  color: "#6f6f6f",
                }}
              >
                {state.appointmentInfo?.consultationModedisplay}
              </Text>
            </Div>
          </Div>
        </Div>
        <Div
          id={`${parent_id}_cancelbook_subtitle_div`}
          style={{
            padding: "10px",
            border: "1px solid #E0E0E0",
            background: "#F9F9F9",
            margin: "16px 0px",
            borderRadius: "6px",
            // height: '12vh'
          }}
        >
          <Div
            id={`${parent_id}_cancelbook_subtitle_sub_div`}
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "9px",
            }}
          >
            <Text
              id={`${parent_id}_cancelbook_description_text`}
              className="pc_medium"
              style={{
                fontSize: "12px",
                color: "#6F6F6F",
              }}
            >
              DESCRIPTION
            </Text>
            <Text
              id={`${parent_id}_cancelbook_total_text`}
              className="pc_medium"
              style={{
                fontSize: "12px",
                color: "#6F6F6F",
              }}
            >
              TOTAL
            </Text>
          </Div>
          <Div
            id={`${parent_id}_cancelbook_cancellation_div`}
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "6px",
              borderTop: "1px solid #F0F0F0",
            }}
          >
            <Text
              id={`${parent_id}_cancelbook_cancellation_text`}
              className="pc_regular"
              style={{
                fontSize: "14px",
                color: "#101010",
              }}
            >
              Cancellation charge ( Nil )
            </Text>
            <Text
              id={`${parent_id}_cancelbook_amount_text`}
              className="pc_regular"
              style={{
                fontSize: "14px",
                color: "#101010",
              }}
            >
              ₹ 0.00
            </Text>
          </Div>
        </Div>
        <Div>
          <FormControl component="fieldset">
            <FormLabel component="legend">Cancellation Type</FormLabel>
            <RadioGroup aria-label="gender" name="gender1" value={state?.cacellationType} onChange={(e) => { setState({ ...state, cacellationType: e.target.value }) }}>
              <FormControlLabel value={process.env.REACT_APP_CANCEL_APPMNT_TYPE_SINGLE} control={<Radio />} label="Single" />
              <FormControlLabel value={process.env.REACT_APP_CANCEL_APPMNT_TYPE_MULTIPLE} control={<Radio />} label="Multiple" />
            </RadioGroup>
          </FormControl>
        </Div>
        <Text
          id={`${parent_id}_cancelbook_reason_text`}
          className="pc_regular"
          name={
            <span
              id={`${parent_id}_cancelbook_reason_span`}
              style={{ fontSize: "14px", color: "#6F6F6F" }}
            >
              Reason for cancellation
              <label
                id={`${parent_id}_cancelbook_star_label`}
                style={{ color: "red", marginLeft: 4 }}
              >
                *
              </label>
            </span>
          }
          style={{ fontSize: "17px" }}
        />

        <Div
          id={`${parent_id}_cancelbook_select_div`}
          key={"2"}
          className={classes.select}
        >
          <Selection
            options={reasonForApptCancelModify || []}
            optionLabel="title"
            value={cancelbookingreason}
            label={""}
            parent_id={parent_id}
            // onChange={(e,value)=>props.handleSelectCancelbooking(value)}
            onChange={(e, value) => onChangeState(value)}
          />
        </Div>
        {cancelbookingreason?.value === process.env.REACT_APP_CANCELLATION_REASON_TYPE_OTHER
          &&
          (
            <TextArea
              id={`${parent_id}_cancelbook_reason_manually_textarea`}
              placeholder="Enter your reason manually"
              style={{
                marginTop: "20px",
                padding: 14,
                resize: "none",
                height: "14vh",
            }}
            maxLength={3000}
            value={state?.cancelBookingOtherReason}
            onChange={(e)=>setState({...state,cancelBookingOtherReason:e.target.value})}
            />
          )}

        <Button
          id={`${parent_id}_cancelbook_buttom`}
          key={"1"}
          type="button"
          disabled={props?.signIn?.loading || props?.cancelAppointment?.loading}
          className="pc_medium"
          variant="outlined"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: "20px",
            fontSize: "14px",
            padding: "8px 16px",
            marginBottom: "10px",
            backgroundColor: UIColor?.primaryColor?.color,
            border: "none",
            borderRadius: 8,
          }}
          onClick={async () => {
            if (cancelbookings === 1) {
              if (!cancelbookingreason?.value) {
                props.alert.setSnack({
                  open: true,
                  severity: AlertProps.severity.error,
                  msg: "Please select reason for cancellation",
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.center,
                  tone: true,
                });
                return;
              }  else if (cancelbookingreason?.value === process.env.REACT_APP_CANCELLATION_REASON_TYPE_OTHER && state?.cancelBookingOtherReason?.length === 0) {
                props.alert.setSnack({
                  open: true,
                  severity: AlertProps.severity.error,
                  msg: "Please select reason for other",
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.center,
                  tone: true,
                });
                return;
              }
              else {
                let mobile =
                  state.appointmentInfo?.AppointmentNotification?.[0]?.mobile;
                if (!mobile) {
                  const userInfo = getUserInfo();
                  mobile = userInfo.mobile;
                }
                // const data = await this.props.SIGN_IN({
                //   mobileNo: mobile,
                //   otp: this.state.otp,
                // });
                // const status = checkError(data?.payload || {})
                // if (!status.isError) {
                const appointmentData = JSON.parse(
                  JSON.stringify(state.appointmentInfo)
                );
           

                let payload = {
                  ticketId: appointmentData?.ticketid?.[0],
                  cancelationReason: cancelbookingreason?._id,
                  "cancellationType": state?.cacellationType,
                  cancelationReasondesc: state?.cancelBookingOtherReason,
                  appId:props?.appointmentId

                };
                backdrop?.setBackDrop({
                  ...backdrop,
                  open: true,
                  message: "Cancelling appointment... Please wait!",
                });
                const response = await dispatch(
                  actions.CANCEL_APPOINTMENT(payload)
                );
                const cancelStatus = checkError(response?.payload || {});
                if (!cancelStatus.isError) {
                  await props.handlecahngeCancelbooking(2);
                  await props?.getAllAppointment();
                  props?.setState({
                    ...props?.state,
                    cancelbooking: false
                  });
                } else {
                  props.alert.setSnack({
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: cancelStatus?.errMsg ||response?.data?.payload?.Response?.[0]?.errorMessage || "Something went wrong !",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                    tone: true,
                  });
                  onChangeState("resend", cancelStatus?.errMsg);
                }
                }
                backdrop?.setBackDrop({
                  ...backdrop,
                  open: false,
                  message: "",
                });
            } else {
              if (!cancelbookingreason?.value) {
                props.alert.setSnack({
                  open: true,
                  severity: AlertProps.severity.error,
                  msg: "Please select reason for cancellation",
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.center,
                  tone: true,
                });
                return;
              }
              else if (cancelbookingreason?.value === process.env.REACT_APP_CANCELLATION_REASON_TYPE_OTHER && state?.cancelBookingOtherReason?.length > 0) {
                props.alert.setSnack({
                  open: true,
                  severity: AlertProps.severity.error,
                  msg: "Please select reason for other",
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.center,
                  tone: true,
                });
                return;
              }
              props.handlecahngeCancelbooking(
                cancelbookings === 0 ? 1 : null,
                state.appointmentInfo?.AppointmentNotification?.[0]?.mobile
              );
            }
          }}
        >
          {props?.signIn?.loading || props?.cancelAppointment?.loading
            ? "Canceling..."
            : cancelbookings === 0
              ? "Send OTP & Cancel"
              : cancelbookings === 1
                ? "Confirm & Cancel"
                : cancelbookings === 2 && "Back to Home"}
        </Button>
      </Container>
    </div>
  );
};

export default withStyles(styles)(withAllContexts(CancelBookingPage));
