import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import "./ticket.css";
import Grid from "@material-ui/core/Grid";
import VideoIcon from "../../../assets/img/avc_video_icon_border.svg";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
	Avatar: {
		width: theme.spacing(4),
		height: theme.spacing(4),
	},
}));

export function TicketComp(props) {
	const classes = useStyles();
	const data = props.data;
	return (
		<React.Fragment>
			<div class="ticket">
				<header class="ticket__wrapper">
					<div class="ticket__header">
						<img src={VideoIcon} />
						<div class="date">
							{data?.date
								? moment(data?.dataJson?.avc?.start_time?.trim()).format("DD MMM YYYY hh:mm a")
								// moment(data?.date).format("Do MMM YYYY | h:mm a")
								: ""}
						</div>
						<div class="pin_no">{data?.app_id ?? ""}</div>
					</div>
				</header>
				<div class="ticket__divider">
					<div class="ticket__notch"></div>
					<div class="ticket__notch ticket__notch--right"></div>
				</div>
				<div class="ticket__body">
					<Grid
						container
						style={{ padding: "20px 30px", minHeight: "18vh" }}
						spacing={3}
					>
						<Grid item xs={12} sm={2} className={classes.div}>
							<Avatar alt="Remy Sharp" src="" className={classes.Avatar} />
						</Grid>
						<Grid
							item
							xs={12}
							sm={10}
							className={classes.div}
							style={{ backgroundColor: "white" }}
						>
							<section class="ticket__section">
								<p class="p_1">{data?.doctor?.name ?? ""}</p>
								<p class="p_2">{data?.doctor?.type ?? ""}</p>
								<p class="p_3">{data?.doctor?.org_name ?? ""}</p>
							</section>
						</Grid>
					</Grid>
					<Grid
						container
						style={{ padding: "20px 30px", minHeight: "18vh" }}
						spacing={2}
					>
						<Grid item xs={12} sm={2}>
							<Avatar alt="Remy Sharp" src="" className={classes.Avatar} />
						</Grid>
						<Grid
							item
							xs={12}
							sm={10}
							className={classes.div}
							style={{ backgroundColor: "white" }}
						>
							{
								// JSON.stringify(data.personID)
								data?.patient?.name ? 
								(<section class="ticket__section">
									<p class="p_1">{data?.patient?.name ?? ""}</p>
									<p class="p_2">
										{data?.patient?.age ? data?.patient?.age + " | " : ""}
										{data?.patient?.gander ? data?.patient?.gander + " | " : ""}
										{data?.patient?.id ?? ""}
									</p>
									<p class="p_3">{data?.patient?.mobile ?? ""}</p>
								</section>) 
								: 
								data?.personID?.name ? 
								(<section class="ticket__section">
									<p class="p_1">{data?.personID?.name ?? ""}</p>
									<p class="p_2">
										{data?.personID?.age ? data?.personID?.age + " | " : ""}
										{data?.personID?.gander ? data?.personID?.gander + " | " : ""}
										{data?.personID?.id ?? ""}
									</p>
									<p class="p_3">{data?.personID?.mobile ?? ""}</p>
								</section>)
							:
								<p>-</p>
							}
						</Grid>
					</Grid>
				</div>
			</div>
		</React.Fragment>
	);
}
