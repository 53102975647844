/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc withQueue HOC will send all the necessary Context such as AlertContext,
 * DialogContext, BackdropContext and DrawerContext as a prop.
 */

import React, { useEffect } from "react";
import QueueDrawer from "../components/queueDrawer";
import { Icons, Modal } from "qdm-component-library";
import {
  AlertContext,
  DrawerMeetingContext,
  QueueManagementContext,
  VisitDetails,
} from "../context";
import { actions } from "primarycare-binder";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  AlertProps,
  checkError,
  getRole,
  getUserInfo,
  getUtcTime,
  makeQueueData,
  actionButtonClick,
} from "../utils";
import { AppRoutes } from "../router/routes";
import { checkWithCasbin } from "../utils/permissionHandling";
//import AssignReassign from "../screens/dashboard/assignReassign";
import { UIColor } from "../themes/theme";
import { personRequests } from "../utils/qdmQueries";
import { urls, fetchData, GetQueueData } from "../utils";
import jwtDecode from "jwt-decode";
import PauseButton from "../assets/img/svg/icons8-pause-button.svg";
import RepeatIcon from "../assets/img/svg/icons8-repeat.svg";
import ChangeUser from "../assets/img/svg/icons8-change-user.svg";
import { useNavigate } from "react-router-dom";
import MultiBookComp from "../components/multibook/multiBookComp";

const withQueue = (Component) => (props) => {
  const [permission, setPermission] = React.useState({});
  const [permission_, setPermission_] = React.useState({});
  const [selectedQueue, setSelectedQueue] = React.useState({});
  // const [multiBookOpen, setMultiBookOpen] = React.useState(false);
  // const [mBData, setMbData] = React.useState({});
  const navigate = useNavigate();

  React.useEffect(() => {
    const initFun = async () => {
      let permission = await checkWithCasbin(["queueSideNav"]);
      let permission_ = await checkWithCasbin(["dashboard"]);
      setPermission(permission);
      setPermission_(permission_);
    };
    initFun();
  }, []);

  // const handleMultiBookData = (data) => {
  //
  //   if (data) {
  //     setMbData(data);
  //   }
  //   setMultiBookOpen(!multiBookOpen);
  // };
  const loggedUserInfo = useSelector(
    (state) => state?.authSlice?.loggedUserInfo
  );
  const assignData = useSelector((state) => state?.dashboardApiSlice);
  const practitionerType = useSelector(
    (state) => state?.dashboardApiSlice?.assignPractitionerType
  );
  const practitionerRole = useSelector(
    (state) => state?.dashboardApiSlice?.assignPractitionerRole
  );
  const datas = useSelector((state) => state?.dashboardApiSlice);
  const dispatch = useDispatch();
  const {
    allData,
    displayData,
    previousData,
    loader,
    setAllData,
    setDisplayData,
    setPreviousData,
    setloader,
  } = React.useContext(QueueManagementContext);
  let data = [];
  let onHoldData = [];
  const { setSnack } = React.useContext(AlertContext);
  const drawerMeetingContext = React.useContext(DrawerMeetingContext);
  const { setVisitDetails } = React.useContext(VisitDetails);

  const [queueList, setAllqueueList] = React.useState([]);
  const [isOpen, setOpen] = React.useState(false);
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);
  const OpenSideBar = (bool) => {
    setOpen(bool);
    forceUpdate();
  };
  const [state, setState] = React.useState({
    assignModal: false,
    assignModalType: null,
    encounter_id: null,
    appointmentStart: null,
    appointmentEnd: null,
    appID: null,
    // action:null,
    // apiendpoint:null,
    // backEndCall:null
  });

  // const putOnHold = (index = 0) => {
  //   const masterData = [...data];
  //   const newData = [...displayData];
  //   const onHoldVal = newData.splice(index, 1);
  //   updateStatus(
  //     onHoldVal[0].type === "nurse" ? "nurseonhold" : "fdonhold",
  //     onHoldVal[0].token
  //   );
  //   const statusChnage = masterData.find((d) => d.token === onHoldVal[0].token);
  //   statusChnage.appstatus =
  //     onHoldVal[0].type === "nurse" ? "nurseonhold" : "nurseonhold";
  //   if (newData[0]) {
  //     const changeData = masterData.find((d) => d.token === newData[0].token);
  //     changeData.showBringtoTop = false;
  //     newData.splice(1);
  //     if (newData.length <= 1) {
  //       const newQueueData = masterData.find(
  //         (d) =>
  //           d.index > newData[0].index &&
  //           !(d.appstatus === "fdonhold" || d.appstatus === "NurseSignoff")
  //       );
  //       if (newQueueData) {
  //         newData.push(newQueueData);
  //       }
  //     }
  //   }
  //   const newOnHoldData = [...onHoldData, ...onHoldVal];
  //   setData(masterData);
  //   setDisplayData(newData);
  //   setOnHoldData(newOnHoldData);
  // };

  const updateStatus = async (status, appointmentId) => {
    const data = await dispatch(
      actions.UPDATE_APPOINTMENT_STATUS({
        appointmentId,
        status,
      })
    );
    const { isError } = checkError(data?.payload);
    if (isError) {
      return false;
    }
    return true;
  };

  // const fetchUserInfo = async () => {
  //   const userInfo = localStorage.getItem("UserInfo");
  // const decodedUserinfo = jwtDecode(userInfo);

  // const info = {
  //   orgId: 138,
  //   name: "sathiya",
  //   practionerId:decodedUserinfo?.practitionerid,
  //   role: decodedUserinfo?.rolename.toLowerCase(),
  //   org_id: decodedUserinfo?.facilityid,
  //   idmroleid:decodedUserinfo.idmroleid
  // };

  // return  info;
  // };

  const getFetchData = async () => {
    let setQueueData = await GetQueueData();
    if (setQueueData?.isErrorResp) {
      setSnack(setQueueData.isErrorResp);
    } else {
      setAllData(setQueueData?.everyData);
      setDisplayData(setQueueData?.getDisplayData);
      setPreviousData(setQueueData?.getPreviousData);
      //   let actionData = displayData[0].info.action.find((x) => x.action === "On Hold");
      // setState((prevstate)=>({
      //   // ...prevstate,
      //   // action:actionData[0].action,
      //   // apiendpoint:actionData[0].apiendpoint,
      //   // backEndCall:actionData[0].backEndCall,
      // }));
    }
  };

  useEffect(() => {
    //getFetchData();
    //GetAllQueueData();
  }, []);
  const GetAllQueueData = async () => {
    var myHeaders = new Headers();
    myHeaders.append("jwtToken", localStorage.getItem("JwtToken"));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_QUEUE_LIST, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllqueueList(result?.result);

        //setPermissionData(result?.result);
      })
      .catch((error) => console.log("error", error));
  };
  const attendPatient = async (
    personId,
    patientId,
    appointmentId,
    encounterId,
    type,
    pId,
    encounter_id,
    ticketId,
    app
  ) => {
    let status;
    let isCompleted;
    if (app[0]?.type === "nurse") {
      status = "inprogress_nurse";
      isCompleted = false;
    } else if (app[0]?.type === "doctor") {
      status = "doctor_inprogress";
      isCompleted = false;
    }

    await dispatch(
      actions.UPDATE_APPOINTMENT_STATUS({
        appointmentId,
        status,
        isCompleted,
      })
    );

    navigate(AppRoutes.patientDetail, {
      state: {
        encounter_id,
        personId,
        patientId,
        appointmentId,
        encounterId,
        type,
        pId,
        app_id: app?.[0]?.info?._id,
        ticketId,
        allCurrentData: app?.[0],
      },
    });
  };

  const registerPatient = (
    personId,
    patientId,
    appointmentId,
    isPatient,
    mobileNo,
    action,
    ticketid
  ) => {
    setVisitDetails({
      encounter: {
        mode: "direct",
      },
      practitioner: {},
      device: {},
      helthcare: {},
      accompained: {},
    });
    if (isPatient) {
      navigate(AppRoutes.visitRegistration, {
        state: {
          personId,
          from: 1,
          patientId,
          appointmentId,
          Ispatient: true,
          isedit: false,
          isRevise: false,
          patientData: mobileNo,
          stateAction: action?.action,
          stateEndPoint: action?.apiendpoint,
          stateBackEndCall: action?.backEndCall,
          stateTicketid: ticketid,
        },
      });
    } else {
      navigate(AppRoutes.assemblePatioentRegister, {
        state: {
          nextRoute: AppRoutes.visitRegistration,
          personId,
          patientId,
          from: 1,
          appointmentId,
          patientData: mobileNo,
          Ispatient: false,
          idedit: false,
          fromQueue: true,
          stateAction: action?.action,
          stateEndPoint: action?.apiendpoint,
          stateBackEndCall: action?.backEndCall,
          stateTicketid: ticketid,
        },
      });
    }
  };

  const closeConsultation = async (type, data_) => {
    if (data_?.encounter_status?.toLowerCase() === "finished") {
      setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Close Consultation Done Already.",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    }
    let payload = {
      start: data_?.info?.start ?? "",
      end: data_?.info?.end,
      encounter_key: data_?.info?.encounter?.[0]?._key ?? "",
    };
    await dispatch(actions.CLOSE_CONSULTATION(payload));

    setOpen(false);
    setState({
      ...state,
      assignModal: true,
      assignModalType: "close",
      encounter_id: data_?.info?.encounter?.[0]?._key,
      appointmentStart: data_?.info?.start,
      appointmentEnd: data_?.info?.end,
    });
  };
  const assignPractitioner = async (type, data_) => {
    let d = await dispatch(
      actions.ASSIGN_LIST({
        start: data_?.info?.start ?? "",
        end: data_?.info?.end,
        encounter_key: data_?.info?.encounter?.[0]?._key ?? "",
        type: type,
      })
    );

    setOpen(false);
    setState({
      ...state,
      assignModal: true,
      assignModalType: type,
      encounter_id: data_?.info?.encounter?.[0]?._key,
      appointmentStart: data_?.info?.start,
      appointmentEnd: data_?.info?.end,
      appID: data_?.info?._key,
    });
  };
  const onModalClose = async (value) => {
    setState({
      assignModal: value,
      assignModalType: null,
      encounter_id: null,
      appID: null,
    });
  };

  const actionButtonClickEvent = async (
    action,
    apiendpoint,
    backEndCall,
    ticketid
  ) => {
    setloader(true);
    let fetchVal = await actionButtonClick(
      action,
      apiendpoint,
      backEndCall,
      ticketid
    );

    if (!fetchVal.error) {
      let data = await GetQueueData();
      setAllData(data.everyData);
      setDisplayData(data.getDisplayData);
      setPreviousData(data.getPreviousData);
    }
    setloader(false);
  };

  return (
    <div>
      <div className="queue-style">
        <QueueDrawer
          permission={permission}
          previous={
            previousData.length > 0
              ? [
                  {
                    ...previousData[0],
                    title: "Previous",
                    role_: displayData[0]?.type,
                    assignButtonAction: () => {
                      assignPractitioner("reassign", previousData[0]);
                    },
                    appno: previousData[0]?.info?.appno,
                  },
                ]
              : []
          }
          attend={
            displayData[0]
              ? [
                  {
                    title: "To Attend ",
                    token: displayData[0]["token"],
                    profile_pic: displayData[0]["profile_pic"],
                    name: displayData[0]["name"],
                    role: displayData[0]["role"],
                    role_: displayData[0]?.type,
                    encounter: displayData[0].encounter,
                    patientPriority: displayData[0].patientPriority,
                    consultationMode: displayData[0]?.consultationMode,
                    start:
                      displayData[0]?.type === "nurse" ||
                      displayData[0]?.type === "doctor"
                        ? ""
                        : displayData[0].start,
                    primaryButtonName:
                      displayData[0]?.type === "nurse" ||
                      displayData[0]?.type === "doctor"
                        ? "Attend"
                        : !displayData[0]?.isPatient
                        ? "Register Patient"
                        : "Register Visit",
                    isRegister:
                      displayData[0]?.type === "nurse" ||
                      displayData[0]?.type === "doctor"
                        ? `EN-${displayData[0]?.encounter} • New Patient`
                        : displayData[0]?.isPatient
                        ? "Registered"
                        : "Unregistered",
                    primaryButtonAction: () => {
                      if (
                        displayData[0]?.consultationMode ===
                          "Video Consultation" &&
                        !drawerMeetingContext?.open
                      ) {
                        navigate(AppRoutes.videoCall, {
                          state: {
                            appointmentId: displayData[0].totalData.info?._key,
                            name: loggedUserInfo?.data?.name?.text,
                            role: loggedUserInfo?.data?.role,
                            data: displayData[0],
                          },
                        });
                      } else {
                        if (
                          displayData[0]?.type === "nurse" ||
                          displayData[0]?.type === "doctor"
                        ) {
                          attendPatient(
                            displayData[0].personId,
                            displayData[0].patientId,
                            displayData[0].token,
                            displayData[0].encounterId,
                            displayData[0]?.type,
                            displayData[0].pId,
                            displayData[0].encounter_id,
                            displayData[0]?.info?.ticketid,
                            displayData
                          );
                        } else {
                          registerPatient(
                            displayData[0].personId,
                            displayData[0].patientId,
                            displayData[0]?.info?._id,
                            displayData[0]?.isPatient,
                            displayData[0]?.mobileNo,
                            displayData[0]?.info.action.find(
                              (x) => x.action === "VISIT REGISTER"
                            ),
                            displayData[0]?.info?.ticketid
                          );
                        }
                      }
                    },

                    secondaryButtonName: "Put on Hold",
                    secondaryButtonAction: () =>
                      actionButtonClickEvent(
                        displayData[0]?.onHoldAction?.action,
                        displayData[0]?.onHoldAction?.apiendpoint,
                        displayData[0]?.onHoldAction?.backEndCall,
                        displayData[0]?.info?.ticketid
                      ),
                    secondaryButtonIcon: PauseButton,
                    isEmergency: false,
                    assignButtonAction: () => {
                      let assigned =
                        displayData[0]?.info?.encounter?.[0]?.participant
                          ?.length > 0
                          ? true
                          : false;
                      assignPractitioner(
                        assigned ? "reassign" : "assign",
                        displayData[0]
                      );
                    },
                    assignButtonIcon:
                      displayData[0]?.info?.encounter?.[0]?.participant
                        ?.length > 0
                        ? RepeatIcon
                        : ChangeUser,
                    assigned:
                      displayData[0]?.info?.encounter?.[0]?.participant
                        ?.length > 0
                        ? true
                        : false,
                    //assignButtonName: "Assign",

                    totalData: displayData[0],
                  },
                ]
              : []
          }
          next={[...displayData].splice(1, 1).map((n) => {
            return {
              title: "Up Next",
              token: n.token,
              profile_pic: n?.["profile_pic"],
              name: n.name,
              role: n.role + (n.type === "nurse" ? " • New Patient" : ""),
              isEmergency: n.isEmergency,
              start: n.type === "nurse" ? "" : n.start,
              encounter: n.encounter,
              patientPriority: n.patientPriority,
              assignButtonAction: () => {
                let assigned =
                  n.info?.encounter?.[0]?.participant?.length > 0
                    ? true
                    : false;
                assignPractitioner(assigned ? "reassign" : "assign", n);
              },
              assignButtonIcon:
                n.info?.encounter?.[0]?.participant?.length > 0
                  ? RepeatIcon
                  : ChangeUser,
              assigned:
                n.info?.encounter?.[0]?.participant?.length > 0 ? true : false,
              role_: n.type,
              totalData: n,
              // isclinic: displayData[0]?.info?.resourcetype === "Practitioner" ? false : true,
              action: n.info.action,
              ticketId: n.info.ticketid,
            };
          })}
          AllData={allData}
          pendingData={data}
          onHoldData={onHoldData}
          pastData={previousData}
          open={isOpen}
          setOpen={setOpen}
          onClose={() => OpenSideBar(false)}
          assignPractitioner={assignPractitioner}
          closeConsultation={(t, v) => closeConsultation(t, v)}
          // setMultiBookOpen={handleMultiBookData}
          // multiBookOpen={multiBookOpen}
          queueList={queueList}
        />
      </div>
      {permission_?.write?.indexOf("queue") > -1 && (
        <div
          style={{
            position: "fixed",
            right: "0px",
            top: "139px",
            background: UIColor.primaryColor.color,
            padding: "10px 6px 10px 9px",
            borderTopLeftRadius: "12px",
            borderBottomLeftRadius: "12px",
            color: "white",
            cursor: "pointer",
            zIndex: 3,
          }}
          onClick={() => OpenSideBar(true)}
        >
          <Icons fontIcon="chevron-left" />
        </div>
      )}
      <div>
        <Component {...props} queueList = {queueList}>{props.children}</Component>
      </div>

      <Modal
        id={`assign_modal_popup`}
        open={state?.assignModal}
        onClose={() => onModalClose(false)}
        width={1000}
        inLineStyles={{
          borderRadius: 16,
        }}
      >
        {/* <AssignReassign
          parent_id={"doctor_card_detail"}
          title={
            state?.assignModalType === "assign"
              ? "Assign Practitioner"
              : state?.assignModalType === "reassign"
              ? "Re-Assign Practitioner"
              : "Close Consultation"
          }
          type={state?.assignModalType}
          encounter_id={state?.encounter_id}
          appID={state?.appID}
          assignData={
            state?.assignModalType === "close"
              ? assignData?.closeConsultation?.data
              : assignData?.assignList?.data
          }
          practitionerType={practitionerType?.data ?? []}
          practitionerRole={practitionerRole?.data ?? []}
          onModalClose={() => onModalClose(false)}
          appointmentStart={state?.appointmentStart}
          appointmentEnd={state?.appointmentEnd}
          dispositionData={datas?.dispositionType?.data ?? []}
          followupUOMData={datas?.followupUOM?.data ?? []}
          reasonData={datas?.reasonMaster?.data ?? []}
          showPractitioner={state?.assignModalType === "close" ? true : false}
          Role={
            permission?.write?.indexOf("closeConsultationN") > -1
              ? 2
              : permission?.write?.indexOf("closeConsultationD") > -1
              ? 1
              : null
          }
          fetQueData={fetchData}
        /> */}
      </Modal>
      {/* <MultiBookComp
        multiBookOpen={multiBookOpen}
        setMultiBookOpen={handleMultiBookData}
        mBData={mBData}
      /> */}
    </div>
  );
};

export default withQueue;
