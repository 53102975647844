import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { UIColor } from '../../../themes/theme';
import { ThemeProvider } from '@mui/styles';
import newtheme from '../../../themes/newtheme';

export default function SearchFilter({
  tableInput,
  setTableInput,
  facilityList,
  payerList,
  chargecodeList,
  getTableData
}) {

  const handleChange = (val, name) => {
    let inp = tableInput;
    inp.filter[name] = val?.value;
    setTableInput(inp);
    if (val?.value === null || val?.value === undefined) {
      return {
        ...tableInput,
        filter: {
          ...tableInput.filter,
          [name]: undefined,
        },
      };
    } 
  };
 
  return (
    <ThemeProvider theme={newtheme}>
      <Box
        sx={{
          width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
        }}
      >
        <Grid
          container
          xs={12}
          alignItems="center"
          justifyContent="center"
          style={{ padding: "10px" }}
          spacing={2}
        >
          <Grid item container xs={10} spacing={1}>
            <Grid item xs={4}>
              <Autocomplete
                id="tags-filled"
                options={facilityList}
                getOptionLabel={(option) => option?.label ?? ""}
                hoverColor={UIColor.primaryColor.color}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    placeholder="search facility"
                    label="Facility"
                    hoverColor={UIColor.primaryColor.color}
                    sx={{
                      "& .MuiAutocomplete-inputRoot": {
                        borderRadius: "6px",
                        border: "1px  #E0E0E0",
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
                value={tableInput?.filter?.facility}
                onChange={(e, v) =>
                  handleChange(v , "facility")
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                id="tags-filled"
                options={payerList || []}
                getOptionLabel={(option) => option?.label ?? ""}
                hoverColor={UIColor.primaryColor.color}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    placeholder="search payer"
                    label="Payer"
                    hoverColor={UIColor.primaryColor.color}
                    sx={{
                      "& .MuiAutocomplete-inputRoot": {
                        borderRadius: "6px",
                        border: "1px  #E0E0E0",
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
                value={tableInput?.filter?.payerid}
                onChange={(e, v) => handleChange(v, "payerid")}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                id="tags-filled"
                options={chargecodeList || []}
                getOptionLabel={(option) => option?.label ?? ""}
                hoverColor={UIColor.primaryColor.color}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    placeholder="search Item Name"
                    label="Item Name"
                    hoverColor={UIColor.primaryColor.color}
                    sx={{
                      "& .MuiAutocomplete-inputRoot": {
                        borderRadius: "6px",
                        border: "1px  #E0E0E0",
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
                value={tableInput?.filter?.chargeid}
                onChange={(e, v) => handleChange(v, "chargeid")}
              />
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: newtheme.palette.secondary.main,
                "&:hover": {
                  backgroundColor: newtheme.palette.secondary.main,
                },
              }}
              onClick={() => getTableData()}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
