import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  urls,
  defaultState,
  defaultReject,
  fetchData,
} from "../../utils";

const GET_ENC_LIST = createAsyncThunk(
    `emrBannerSlice/get_enclilst`,
    async (payload = {}, { rejectWithValue, getState }) => {
      try {
        const data = await fetchData(
          { body: JSON.stringify(payload) },
          urls.readQdmQueries
        );
        return {
          ...defaultState.Info,
          data,
        };
      } catch (error) {
        return rejectWithValue({
          ...defaultReject,
          message: error.message,
        });
      }
    }
  );

const emrBannerSlice = createSlice({
name: "emrBannerSlice",
initialState: {
    get_enclilst: {
        ...defaultState.Info,
    },
},
extraReducers: {
    // GET_ENC_LIST

    [GET_ENC_LIST.fulfilled]: (state, action) => {
    state.get_enclilst = action?.payload ?? {};
    },
    [GET_ENC_LIST.pending]: (state, action) => {
    state.get_enclilst.loading = true;
    },
    [GET_ENC_LIST.rejected]: (state, action) => {
    state.get_enclilst = action?.payload ?? {};
    },
}
});

export const emrBannerActions = {
GET_ENC_LIST,
};

export default emrBannerSlice;