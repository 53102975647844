import React, {useState , useEffect} from "react";
import actions from "../../redux/actions";
import { connect } from "react-redux";
//import { withRouter } from "react-router-dom";
import  withAllContexts  from "../../hoc/withAllContexts";
import { Div, Card, Divider, H6 } from "qdm-component-library";
import { Header, Body } from "./components";
import { getUserInfo } from "../../utils";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { CircularProgress, Grid } from "@mui/material";

const Allergies = (props) =>  {

  const [open , setOpen] = useState(false)
  const [statusCheck , setstatusCheck] = useState("All")
  const readAllergyLoading = useSelector((state) => state?.patientDetails?.encounterBasedA?.loading);
  const diagnosisReadLoading = useSelector((state) => state?.DiagnosisMasterSlice?.diagnosisread?.loading);
  const styles = {
    card: {
      boxShadow: "none",
    },
    divider: {
      margin: 0,
      height: "0.1px",
    },
  };
  const editList = (data, index) => {
    // let cheifComplaints = this.props.cheifComplaints
    // cheifComplaints.editIndex = index;
    // cheifComplaints.setCheifComplaints({
    //   ...cheifComplaints
    // })

    props.editWidget(data, props.componentId, index);
  };


    const {
      title = "",
      data = [],
      icon = "",
      parent_id,
    } = props;
   
    const filteredData = props?.data?.filter(
      (item) => item.status === statusCheck
    );
    // console.log("filteredData", filteredData?.length);
    // console.log("data recived", this.props.data);

    return (
      <div id={`${parent_id}_parent_div`}>
        <Card id={`${parent_id}_parent_card`} style={styles.card}>
          <Header
            parent_id={"allergies"}
            icon={icon}
            title={title}
            open={open}
            toggle={() => setOpen(true)}
            handleStatusActive={()=>setstatusCheck("Active")}
            handleStatusInActive={()=>setstatusCheck("InActive")}
            handleStatusResolved={()=>setstatusCheck("Resolved")}
            handleStatusAll={()=>setstatusCheck("All")}
          />
          <Divider id={`${parent_id}_bottom_divider`} style={styles.divider} />
          {(readAllergyLoading || diagnosisReadLoading) && (
            <Grid container justifyContent="center" alignItems="center">
              <CircularProgress color="primary" size={20} />
            </Grid>
          )}
          {!readAllergyLoading && (
            <Div id={`${parent_id}_filter_parent_div`} style={{ padding: 10 }}>
              {filteredData && filteredData?.length < 1
                ? data &&
                  data?.length > 0 &&
                  data
                    .filter(
                      (val) =>
                        statusCheck === "All" || val.new_status === statusCheck
                    )
                    .map((l, i) => {
                      return (
                        <Body
                          parent_id={"allergies" + i}
                          editList={editList}
                          index={i}
                          id={l?.id}
                          bulletColor={l?.bulletColor}
                          hoverColor={l?.hoverColor}
                          value={l?.value}
                          isNew={l?.isNew}
                          isModified={l?.isModified}
                          showDivider={i < data?.length - 1 ? true : false}
                          subData={l?.subData}
                          showSubData={open}
                          status={l?.status}
                          handledStatus={statusCheck}
                          old_status={l?.old_status}
                          new_status={l?.new_status}
                        />
                      );
                    })
                : filteredData &&
                  filteredData?.length > 0 &&
                  filteredData.map((l, i) => {
                    return (
                      <Body
                        parent_id={"allergies" + i}
                        editList={editList}
                        id={l?.id}
                        index={i}
                        bulletColor={l?.bulletColor}
                        hoverColor={l?.hoverColor}
                        value={l?.value}
                        isNew={l?.isNew}
                        isModified={l?.isModified}
                        showDivider={
                          i < filteredData?.length - 1 ? true : false
                        }
                        subData={l?.subData}
                        showSubData={open}
                        status={l?.status}
                        handledStatus={statusCheck}
                        old_status={l?.old_status}
                        new_status={l?.new_status}
                      />
                    );
                  })}
              {(data?.filter(
                (val) => statusCheck === "All" || val.new_status === statusCheck
              )?.length === 0 ||
                data?.length === 0) && (
                <H6
                  id={`${parent_id}_No_data_h6`}
                  style={{ textAlign: "center", fontSize: "15px" }}
                >
                  No data Found
                </H6>
              )}
            </Div>
          )}
        </Card>
      </div>
    );
  }


// const mapStateToProps = (state) => ({
 
// });

export default withAllContexts(Allergies);
