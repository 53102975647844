import React, { useContext, useEffect, useState } from "react";
import {
  Div,
  Card,
  Text,
  Modal,
  Divider,
  Row,
  Icons,
  H6,
  Col,
  Image,
  Select
} from "qdm-component-library";
import { RequestorDetails, OrderStatus } from "../../../components";
import { Popover, makeStyles, Button, Typography,TextareaAutosize, Accordion, AccordionSummary, AccordionDetails, Grid } from "@material-ui/core";
import moment from "moment";
import { useSelector } from "react-redux";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { AlertProps, getOrderName, makeName, utcTOLocal } from "../../../utils";
import { UIColor } from "../../../themes/theme";
import icons8trash from "../../../assets/img/svg/icons8-trash_.svg";
import jwtDecode from "jwt-decode";
import icons8edit from "../../../assets/img/svg/icons8-edit_.svg";
import { InfoOutlined } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import actions from "../../../redux/actions";
import { AlertContext as alert } from "../../../context"

const statusColors = {
  "On-Hold": { color: "rgb(245, 139, 0)", background: "rgb(245, 139, 0)" },
  Draft: { color: "rgb(245, 139, 0)", background: "rgb(245, 139, 0)" },
  Discontinue: { color: "#d60000", background: UIColor.lineBorderFill.color },
  Continue: {
    color: UIColor.primaryColor.color,
    background: UIColor.primaryColor.color,
  },
  //"Active": { color: UIColor.primaryColor.color, background: "#D4E7FD" },
  Active: { color: UIColor.success.color, background: "#fff" },
  Hold: { color: "#5B12BB", background: "#E3D7F3" },
  Complete: { color: UIColor.success.color, background: "hsl(105, 62%, 90%)" },
  Cancel: { color: UIColor.error.color, background: "#FFE1E0" },
  // "Cancel": { color: UIColor.error.color, background: "#FFE1E0" }
};

const useStyles = makeStyles({
  muiPopovers: {
    boxShadow: "0px 4px 24px #00000029",
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 8,
    backgroundColor: "#fff",
    minWidth: 240,
    overflow: "visible",
  },
});

export const PackageList = (props) => {
  const styles = {
    textColor: {
      fontFamily: "pc_regular",
      fontSize: 12,
      paddingLeft: 6,
      color: UIColor.tertiaryText.color,
    },
    textColor1: {
      fontFamily: "pc_regular",
      fontSize: 12,
      color: UIColor.tertiaryText.color,
    },
    userInfo: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      marginRight: "16px",
    },
    editAllergyIcon: {
      padding: "6px 10px",
      background: UIColor.greyBackground.color,
      borderRadius: "50px",
      cursor: "pointer",
    },
    userIcon: {
      width: 25,
      height: 25,
      borderRadius: 50,
      backgroundColor: "#E2EEFB",
      marginRight: 15,
      padding: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalCard: {
      width: 600,
      padding: 20,
    },
    modalHeading: {
      fontSize: 16,
      color: UIColor.primaryText.color,

      marginBottom: 10,
    },
    modalSubTitle: {
      fontSize: 14,
      color: "#242424",

      marginBottom: 20,
    },
    outlinedButton: {
      borderColor: "#DEDEDE",
      color: UIColor.primaryText.color,
      backgroundColor: "#fff",
      borderRadius: 8,
      fontSize: 14,
      fontFamily: "pc_regular !important",
      display: "block",
    },
    outlinedDelButton: {
      borderColor: UIColor.error.color,
      color: UIColor.error.color,
      backgroundColor: "#fff",
      borderRadius: 8,
      fontSize: 14,
      fontFamily: "pc_regular !important",
      display: "block",
      whiteSpace:"nowrap",
    },
    containedButton: {
      backgroundColor: UIColor.error.color,
      borderColor: UIColor.error.color,
      borderRadius: 8,
      fontSize: 14,
      fontFamily: "pc_semibold !important",

      color: "#fff",
    },
    modal: {
      backgroundColor: "#fff",
      boxShadow: "0px 4px 24px #00000029",
      border: `1px solid ${UIColor.lineBorderFill.color}`,
      borderRadius: 8,
      overflowX: "hidden",
      overflowY: "scroll",
    },
    dot: {
      width: 5,
      height: 5,
      borderRadius: "50%",
      backgroundColor: "#6B6B6B",
      margin: "0px 2px",
      display: "inline-block",
    },
    wrapper: {
      width: 15,
      height: 15,
      borderRadius: "50%",
      backgroundColor: UIColor.lineBorderFill.color,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 12,
    },
    statustextColor: {
      color: UIColor.success.color,
      fontSize: 12,
      fontWeight: "bold",
      paddingLeft: 6,
    },
  };
  const dispatch = useDispatch()
  const classes = useStyles(props);
  const AlertContext = useContext(alert);
  const loggedUserInfo = useSelector(
    (state) => state?.authSlice?.loggedUserInfo
  );
  const orderStatus = useSelector(
    (state) => state?.signOffSlice?.getRecordStatus
  );
  const statusVal = orderStatus?.data?.find?.(
    (d) => d?.label === props?.status
  );
  const status = statusVal?.label || "";
  const requestorDetails = {
    name: props.patientgetData?.name ?? "",
    pidNumber: props.patientgetData?.pID ?? "",
    role: props.patientgetData?.speciality ?? "",
    hospital: props.patientgetData?.facilityName ?? "",
    profile_pic:
      "https://thumbs.dreamstime.com/b/portrait-charming-imposing-kid-private-school-boy-feel-glad-ready-work-worker-want-be-cool-professional-look-hear-wear-blazer-152393107.jpg",
  };

  const [infoText, setInfoText] = React.useState(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const [infoOpen, setinfoOpen] = useState(false);

  const [show, hide] = useState(false);

  const [reason, setReason] = useState([]);

  const [delReason, setDelReason] = useState({
    reason: "",
    comments: "",
  });
  const [modal, setModal] = useState({
    isModal:false,
    deleteIndex:null,
    deleteObj:{},
  });

  const handleReason = (e, type) => {
    setDelReason({ ...delReason, [type]: e });
  };

  const getReasonMasters = async () => {
    const response = await dispatch(actions.GET_REASON_MASTERS({type:["ORDERCANCELREASON"]}));
    let list = [];
    let data = response?.payload?.data?.ORDERCANCELREASON;
    for (let index in data) {
      var datatemp = {
        value: data[index]?.label,
        _id: data[index]?.value,
      };
      list.push(datatemp);
    }
    setReason(list);
    return list;
  };
  useEffect(()=>{
    getReasonMasters();
  },[])

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteList = async (i, data, isReccDelete) => {
  
    const reasonIsValid = delReason.reason !== "";
    const commentsAreRequired = delReason.reason === "Others";
    const commentsAreValid = commentsAreRequired ? delReason.comments !== "" : true;
    const canDelete = reasonIsValid && commentsAreValid;

    setDelReason({ ...delReason, error: false });
  
    if (canDelete) {
        setModal({isModal:false,deleteIndex:null,deleteObj:{}});
        props?.backdrop?.setBackDrop({ ...props?.backdrop, open: true, message: "Deleting Package Order" });
        const deleted = await dispatch(actions.ORDER_DELETE({
          id: data.editData,
          isReccDel: isReccDelete,
          reason: delReason.reason,
          reasondesc: delReason.reason === "Others" ? delReason.comments : "",
        }));
        if (deleted?.payload?.data?.error == false) {
          props?.getPackageOrderRead()
        }else{
          AlertContext?.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: "Package Order Delete Failed!",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
            });
          }
        props?.backdrop?.setBackDrop({ ...props?.backdrop, open: false, message: "" });
        setDelReason({ reason: "", comments: "" });
    } else {
      AlertContext?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill the mandatory fields!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      setDelReason({ ...delReason, error: true });
    }
  };

  const handleSetInfoTxt = (val) => {
    setInfoText(true)
  }
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // let arr = [];
  // for (let i = 0; i < props?.recurrentData?.endsAfter?.endsAfter; i++) {
  //   arr.push(i);
  // }

  const { parent_id } = props;

  return (
    <>
       <Accordion elevation={0} expanded={infoOpen}>
        <AccordionSummary
          style={{padding:0}}
          // expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
            <Row
              id={`${parent_id}_list_parent_div`}
              // className={!props.signOff && "allergyListHover"}
              style={{width: "100%"}}
            >
              <Col
                id={`${parent_id}_list_parent_col`}
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                onClick={()=>setinfoOpen(!infoOpen)}
              >
                {props?.treatment_order && (
                  <H6
                    id={`${parent_id}_list_title_h6`}
                    inLineStyles={{
                      fontSize: 14,
                      display: "flex",
                      alignItems: "center",
                      color: UIColor.secondaryButtonColor.color,
                    }}
                  >
                    <Text
                      id={`${parent_id}_list_title_text`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <Div
                        id={`${parent_id}_list_${props?.treatment_order.replaceAll(
                          " ",
                          "_"
                        )}_div`}
                        inLineStyles={{
                          color: UIColor.secondaryButtonColor.color,
                          fontSize: "14px",
                          fontWeight: 500,
                          fontFamily: "pc_regular",
                        }}
                      >
                        {props?.treatment_order}{" "}&nbsp;&nbsp;
                        <InfoOutlined style={{height:"18px",width:"18px"}} color={UIColor.primaryColor.color} />
                        {props?.infoAlertText ? (
                          <ErrorOutlineIcon
                            onClick={(event) => handleSetInfoTxt(event.target.value)}
                            style={{
                              width: "17px",
                              height: "14px",
                              color: "#464242",
                            }}
                          />
                        ) : (
                          ""
                        )}
                        <Popover
                          open={Boolean(infoText)}
                          anchorEl={false}
                          onClose={() => setInfoText(null)}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                        >
                          <Typography
                            style={{
                              padding: 7,
                              background: "lightgrey",
                              color: "black",
                            }}
                          >
                            {" "}
                            {props?.infoAlertText}
                          </Typography>
                        </Popover>
                        {/* {props?.recurrentData?.endsAfter?.endsAfter > 0 ? ( */}
                        {props?.data?.childRecurrent?.length > 0 && (
                          <span
                            id={`${parent_id}_list_icons_span`}
                            // onClick={() => hide(!show)}
                          >
                            <Icons
                              id={`${parent_id}_list_icons`}
                              fontIcon="exchange"
                              style={{ marginLeft: 5, marginRight: 5 }}
                            />
                            {props?.data?.childRecurrent?.length ?? 0}
                            {/* {props?.recurrentData?.endsAfter?.endsAfter} */}
                          </span>
                        )}
                        {/* ) : null} */}
                      </Div>
                    </Text>
                  </H6>
                )}
                {props?.priority && (
                  <Text
                    id={`${parent_id}_list_${props?.priority.replaceAll(
                      " ",
                      "_"
                    )}_text`}
                    inLineStyles={styles.textColor1}
                  >
                    {props?.priority}
                  </Text>
                )}
                {props?.nature && (
                  <Text
                    id={`${parent_id}_list_${props?.nature.replaceAll(
                      " ",
                      "_"
                    )}_text`}
                    inLineStyles={styles.textColor}
                  >
                    {" - "}
                    {props?.nature}
                  </Text>
                )}
                {props?.dateTime && (
                  <Text
                    id={`${parent_id}_list_date-time_text`}
                    inLineStyles={styles.textColor}
                  >
                    {" -  On "}
                    {props?.dateTime}
                  </Text>
                )}
                {props?.val?.packordstatus && (
                  <Text
                    id={`${parent_id}_list_date-time_text`}
                    inLineStyles={{
                      //fontSize: 10,
                      color: UIColor.secondaryButtonColor.color,
                    }}
                  >
                    {"   Order Status: "}
                    {props?.val?.packordstatus}
                  </Text>
                )}
                
                {status && (
                  <Text
                    id={`${parent_id}_list_${props?.status}_text`}
                    onClick={(e) => {
                      if (props?.val?.statushistory?.length > 1) {
                        props.handleOpenStatus &&
                          props.handleOpenStatus(
                            e,
                            props.val,
                            props.IndexValue,
                            "NURSINGPROCEDURE",
                            statusVal
                          );
                      }
                    }}
                    inLineStyles={{
                      ...styles.statusColor,
                      color: statusColors[status]?.color,
                      cursor:
                        props?.val?.statushistory?.length > 1 ? "pointer" : "unset",
                    }}
                  >
                    {" •"} {status}
                  </Text>
                )}
                {/* {
                  <Text inLineStyles={{ color: "#9b9b9b", margin: "0px 5px" }}>
                    {" | "}
                  </Text>
                } */}
                {/* {<Text inLineStyles={styles.textColor}>{"ID 385322"}</Text>}
                {<Text inLineStyles={styles.textColor}>{" - 24 May 12.30"}</Text>} */}
                {/* {<Text inLineStyles={styles.textColor}>{" - Order Pending "}</Text>} */}
              </Col>

              <Col
                id={`${parent_id}_list_On_col`}
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
              >
                <Div
                  id={`${parent_id}_list_userInfoEdit_div`}
                  // className={!props.signOff && "userInfoEdit"}
                  className={"userInfoEdit"}
                  style={{ right: open ? 10 : !props?.val?.billstatus ? -120 : -60 }}
                >
                  <div
                    id={`${parent_id}_list_userInfoEdit_sub_div`}
                    style={styles.userInfo}
                    className={"userHide"}
                  >
                    <Text
                      id={`${parent_id}_list_${
                        JSON.stringify(props?.val?.createdAt)?.replaceAll(" ", "_") ??
                        ""
                      }_text`}
                      inLineStyles={styles.textColor}
                      style={{ marginBottom: 8 }}
                    >
                      {"On "}
                      {props?.val?.createdAt
                        ? utcTOLocal(props?.val?.createdAt, "DD MMM, YYYY")
                        : props.getData()}
                    </Text>
                    <Text
                      id={`${parent_id}_list_${props?.info?.name.replaceAll(
                        " ",
                        "_"
                      )}_text`}
                      inLineStyles={styles.textColor}
                    >
                      {/* {props?.val?.userName.includes(props?.info?.name)
                        ? props?.val?.userName + ' ' + "(You)"
                        : props?.val?.userName} */}
                      {getOrderName(props?.val, loggedUserInfo?.data?.completeName) ??
                        props?.val?.accessionNum ??
                        props?.val?.userName ??
                        (props?.val?.requestorDetails?.practitionerId
                          ? makeName(
                              props?.val?.requestorDetails?.practitionerId || {}
                            ) + " (You)"
                          : props?.val?.userName + " (You)")}
                   </Text> 
                 </div>

                  {!props.signOff && props?.data?.childRecurrent?.length > 0 && (
                    <Text
                      // aria-describedby={id}
                      onClick={() => hide(!show)}
                      // inLineStyles={
                      //   open
                      //     ? styles.userIcon
                      //     : { ...styles.userIcon, backgroundColor: "transparent" }
                      // }
                    >
                      {show ? (
                        <div
                          style={{
                            borderRadius: "50%",
                            border: "1px solid #dadada",
                            width: "30px",
                            height: "30px",
                            padding: "2px",
                            marginLeft: "12px",
                            cursor: "pointer",
                          }}
                        >
                          <ExpandLessIcon />
                        </div>
                      ) : (
                        <div
                          style={{
                            borderRadius: "50%",
                            border: "1px solid #dadada",
                            width: "30px",
                            height: "30px",
                            padding: "2px",
                            marginLeft: "12px",
                            cursor: "pointer",
                          }}
                        >
                          <ExpandMoreIcon />
                        </div>
                      )}
                    </Text>
                  )}

                  {props.signOff && props?.data?.childRecurrent?.length > 0 && (
                    <Text
                      // aria-describedby={id}
                      onClick={() => hide(!show)}
                      // inLineStyles={
                      //   open
                      //     ? styles.userIcon
                      //     : { ...styles.userIcon, backgroundColor: "transparent" }
                      // }
                    >
                      {show ? (
                        <div
                          style={{
                            borderRadius: "50%",
                            border: "1px solid #dadada",
                            width: "30px",
                            height: "30px",
                            padding: "2px",
                            marginLeft: "12px",
                            cursor: "pointer",
                          }}
                        >
                          <ExpandLessIcon />
                        </div>
                      ) : (
                        <div
                          style={{
                            borderRadius: "50%",
                            border: "1px solid #dadada",
                            width: "30px",
                            height: "30px",
                            padding: "2px",
                            marginLeft: "12px",
                            cursor: "pointer",
                          }}
                        >
                          <ExpandMoreIcon />
                        </div>
                      )}
                    </Text>
                  )}
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {!props.signOff && !props?.val?.billstatus && (
                      <Text
                        id={`${parent_id}_list_delete_text`}
                        inLineStyles={{ ...styles.editAllergyIcon, marginLeft: 10 }}
                        onClick={() => {
                          setModal({isModal:true,deleteIndex:props?.IndexValue,deleteObj:props?.val});
                        }}
                      >
                        {/* <Icons
                      id={`${parent_id}_list_delete_icons`}
                      fontIcon="trash" style={{ color: "red" }} /> */}
                        <Image
                          src={icons8trash}
                          alt="delete_icon"
                          // style={{ width: 100, height: 35 }}
                        />
                      </Text>
                    )}

                  </div>

                  <div
                    id={`${parent_id}_list_delete_edit_div`}
                    style={{ display: "flex", alignItems: "center", marginLeft: 64 }}
                  >
                  </div>
                </Div>
              </Col>
            </Row>
            {show && (
              <>
                <Divider
                  id={`${parent_id}_list_bottom_divider`}
                  style={{ margin: "5px 0px" }}
                />
                <div
                  id={`${parent_id}_list_orderstatus_div`}
                  style={{
                    overflowX: "auto",
                    display: "flex",
                    width: props.signOff ? "100%" : "65vw",
                  }}
                >
                  {/* {props?.recurrentData?.every?.value &&
                    arr.map((v, i) => {
                      return ( */}
                  <OrderStatus
                    recurrentDatas={props?.recurrentData}
                    parent_id={"lab_orderlist"}
                    date={props?.recurrentData.startsOn.dateTime}
                    // time={"222"}
                    data={props.data}
                    IndexValue={props.IndexValue}
                    // editFun={props.editFun}
                    // onEditChart={props.onEditChart}
                    Cedit={props.Cedit}
                    // updatefuncy={props.updatefuncy}
                    id={"ID 03421"}
                    status={"Order Pending"}
                    signOff={props.signOff}
                    // saveNursingProcedure={props.saveNursingProcedure}
                  />
                  {/* );
                    })} */}
                </div>
              </>
            )}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="column" style={{borderRadius:5,margin:"0px 0px 0px 7px"}}>
          <Text
            inLineStyles={{
              fontWeight: 600,
              fontSize: "14px",
              cursor: "pointer",
              color: UIColor.primaryColor.color,
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            Package Items
          </Text>
            {props?.val?.packdetails?.map((item)=>{
              return(
              <Grid container justifyContent="space-between" alignItems="center" style={{backgroundColor:UIColor.differentiationBackground.color,padding: "10px 12px",borderTop:`1px solid ${UIColor.lineBorderFill.color}`,borderRadius:5}}>
                <Grid item>
                  <Grid container  direction="column">
                    <Grid item >
                      <Text
                      inLineStyles={{
                        color: UIColor.secondaryButtonColor.color,
                        fontSize: "14px",
                        fontWeight: 500,
                        fontFamily: "pc_regular",
                      }}
                      >
                        {item?.ordername}
                      </Text>
                    </Grid>
                    <Grid item >
                      <Text inLineStyles={styles.textColor1}>
                         Package Qty - {item?.total}  &nbsp;&nbsp;&nbsp;&nbsp;
                         Availed Qty - {item?.availqty} &nbsp;&nbsp;&nbsp;&nbsp;  
                         Order Status -  &nbsp;
                         <Text inLineStyles={styles.textColor1} style={item?.packordstatus=="Completed"? {color:UIColor.success.color}:{color:UIColor.secondaryColor.color} } >{
                            item?.packordstatus}
                          </Text>
                      </Text>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item></Grid>
              </Grid>
              )
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Modal
        id={`${parent_id}_list_parent_modal`}
        open={modal?.isModal}
        onClose={() => setModal({isModal: false,deleteIndex:null,deleteObj:{}})}
        width={600}
        inLineStyles={styles.modal}
      >
        <Card
          id={`${parent_id}_list_parent_card`}
          style={styles.modalCard}
        >
          <H6
            id={`${parent_id}_list_remove_this_h6`}
            className="pc_semibold"
            style={styles.modalHeading}
          >
            Are you sure want to Delete ?
          </H6>
          <H6
            id={`${parent_id}_list_the_order_h6`}
            className="pc_regular"
            style={styles.modalSubTitle}
          >
          If you really want to delete your order give us a reason.
          </H6>
          <Div style={{display: "flex", flexDirection:"column" , gap:"12px"}}>
            <Select
              showArrow
              // options={paymentDropDown?.data ?? []}
              options={reason}
              // onChangeValue={(e) =>
              //   props?.handlePayementDetails(e, "paymentType")
              // }
              onChangeValue={(e)=>{
                handleReason(e.value,"reason")
              }}
              // value={props?.paymentDetails?.paymentType?.value}
              value={delReason?.reason}
              placeholder="select a reason for Delete..."
              labelStyle={styles.labelStyle}
              inLineStyles={{ ...styles.borderStyle }}
              hoverColor={UIColor.primaryColor.color}
              error={delReason?.error}
            />
            {
              delReason?.reason == "Others"?(
                <TextareaAutosize
                multiline
                minrows={8}
                placeholder="Add your comments here *"
                variant="outlined"
                fullWidth
                style={{
                  width: "100%",
                  height: "50px",
                  //  outline: `1px solid ${props?.delReason?.error? {border:`1px solid ${UIColor.error.color}`}:`${UIColor.primaryColor.color}`} `,
                  outline: delReason?.error ? `1px solid ${UIColor.error.color}` : `1px solid ${UIColor.primaryColor.color}`,
                  border: "0px",
                  borderRadius: "4px",
                  padding: "5px",
                }}
                value={delReason?.comments ?? ""}
                onChange={(e)=>{
                    handleReason(e.target.value,"comments")
                }}
              />
              ):(null)
            }
          </Div>
          <Div style={{display:"flex" , justifyContent:"flex-end", gap:"10px",marginTop:"12px"}}>

          <Button
            id={`${parent_id}_list_No_button`}
            variant="contained"
            
            onClick={() => {
              setModal({isModal: false,deleteIndex:null,deleteObj:{}});
              setDelReason({reason:"",comments:""})
            }}
            style={styles.outlinedButton}
          >
            No
          </Button>
          <Button
            id={`${parent_id}_list_Yes_remove_button`}
            variant="contained"
            
            onClick={async () => {
                deleteList(modal?.deleteIndex, modal?.deleteObj,false);
            }}
            style={styles.outlinedDelButton}
          >
            Current Order
          </Button>
          {/* {props?.val?.recurrent ? (
            <Button
              id={`${parent_id}_list_Yes_remove_button`}
              variant="contained"
              
              onClick={async () => {
                await props.deleteList(props.IndexValue, props.val,true);
              }}
              style={styles.containedButton}
            >
              Entire Series
            </Button>
          ):(null)}  */}
          </Div>
        </Card>
      </Modal>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{ paper: classes.muiPopovers }}
      >
        <RequestorDetails
          parent_id={"lab_orderlist"}
          requestorDetails={requestorDetails}
        />
      </Popover>
    </>
  );
};
