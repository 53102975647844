import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import { AlertContext } from "../../../context";
import { AlertProps } from "../../../utils";
import { ThemeProvider } from "@mui/styles";
import newtheme from "../../../themes/newtheme";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { UIColor } from "../../../themes/theme";

const roles = ["Market", "Finance", "Development"];

function EditToolbar(props) {
  const { setRows, rows, rowModesModel, setRowModesModel, focus, title,  setDefaultCharge, setDefaultFacility,
    setDefaultPayer } = props;
  const Alert = React.useContext(AlertContext);

  const handleClick = () => {
    setDefaultCharge({});
    setDefaultFacility({});
    setDefaultPayer({});
    const keys = Object.keys(rowModesModel);
    if(keys?.length > 0){
      Alert?.setSnack({
        open: true,
        severity: AlertProps.severity.warning,
        msg: "Another row in Edit mode",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
      return;
    }

    let id = 1;
    const ids = [...new Set(rows?.map((item) => item.id))];
    if (ids.length > 0) {
      id = Math.max(...ids) + 1;
    }
    let v = {};
    if (title == "Payer Mapping") {
      v = {
        id,
        facility: "",
        payerid: "",
        chargeid: "",
        payerplan: "General",
        encclass: "Permanent",
        facility_name: "",
        payer_name: "",
        charge_desc: "",
        price: 0,
        insurance_share: 0,
        patient_share: 0,
        discount: 0,
        coverage: "Covered",
        maxcov: [],
        isNew: true,
      };
    } else {
      v = {
        id,
        facility_name: "",
        payer_name: "",
        charge_desc: "",
        durtype: "",
        covtyp: "",
        value: 0,
        isNew: true,
      };
    }
    setRows((oldRows) => [...oldRows, v]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: focus },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add {title}
      </Button>
    </GridToolbarContainer>
  );
}

export default function EditableDataGrid({
  rows = [],
  setRows = () => null,
  setTableData,
  facilityList,
  payerList,
  chargecodeList,
  rowCount,
  tableInput,
  setTableInput,
  getTableData,
  tableDataLoading,
  setTableDataLoading,
}) {
  const Alert = React.useContext(AlertContext);
  const [isMaxCov, setIsMaxCov] = React.useState(false);
  const [subRows, setSubRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [subRowModesModel, setSubRowModesModel] = React.useState({});
  const [selectedRow, setSelectedRow] = React.useState({});
  const [defaultFacility, setDefaultFacility] = React.useState({});
  const [defaultPayer, setDefaultPayer] = React.useState({});
  const [defaultCharge, setDefaultCharge] = React.useState({});
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100,
  });

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    const keys = Object.keys(rowModesModel);
    if(keys?.length > 0){

      Alert?.setSnack({
        open: true,
        severity: AlertProps.severity.warning,
        msg: "Another row in Edit mode",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
      return;
    }
    let data = rows.find((x) => x.id == id);
    let fac = facilityList.find((x) => x.value == data.facility);
    let payer = payerList.find((x) => x.value == data.payerid);
    let charge = chargecodeList.find((x) => x.value == data.chargeid);
    setDefaultFacility(fac);
    setDefaultPayer(payer);
    setDefaultCharge(charge);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
  const handleSubEditClick = (id) => () => {
    const keys = Object.keys(subRowModesModel);
    if(keys?.length > 0){
      Alert?.setSnack({
        open: true,
        severity: AlertProps.severity.warning,
        msg: "Another row in Edit mode",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
      return;
    }
    setSubRowModesModel({
      ...subRowModesModel,
      [id]: { mode: GridRowModes.Edit },
    });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  const handleSubSaveClick = (id) => () => {
    setSubRowModesModel({
      ...subRowModesModel,
      [id]: { mode: GridRowModes.View },
    });
  };

  // const handleDeleteClick = (id) => () => {
  //   setRows(rows.filter((row) => row.id !== id));
  // };
  const handleMaxCovClick = (data) => () => {
    const keys = Object.keys(rowModesModel);
    if(keys?.length > 0){
      Alert?.setSnack({
        open: true,
        severity: AlertProps.severity.warning,
        msg: "Another row in Edit mode",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
      return;
    }
    setSubRows(data.row.maxcov);
    setSelectedRow(data.row);
    setIsMaxCov(true);
    // setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };
  const handleSubCancelClick = (id) => () => {
    setSubRowModesModel({
      ...subRowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = subRows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setSubRows(subRows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    setTableDataLoading(true);
    try {
      let inp = [
        {
          id: !newRow.isNew ? newRow.id : null,
          facility: defaultFacility.value,
          payerid: defaultPayer.value,
          chargeid: defaultCharge.value,
          payerplan: newRow?.payerplan,
          encclass: newRow?.encclass,
          facility_name: defaultFacility.label,
          payer_name: defaultPayer.label,
          charge_desc: defaultCharge.label,
          sheetName: "Rules",
          price: newRow?.price,
          insurance_share: newRow?.insurance_share,
          patient_share: newRow?.patient_share,
          discount: newRow?.discount,
          coverage: newRow?.coverage,
        },
      ];
      var res = await setTableData(inp);
      setTableDataLoading(false);
      if (res?.[0].error) {
        // alert(res?.[0].message);
        Alert?.setSnack({
          open: true,
          severity: AlertProps.severity.warning,
          msg: res?.[0].message,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
          });
        setRowModesModel({
          ...rowModesModel,
          [newRow?.id]: { mode: GridRowModes.Edit },
        });
        return newRow;
      } else {
        let updatedRow = {};
        if (newRow?.isNew) {
          updatedRow = res?.[0].response;
        } else {
          updatedRow = { ...newRow, isNew: false };
        }
        // if(res?.[0].error){
        //      updatedRow.mode = GridRowModes.Edit;
        // }
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        await getTableData();
        Alert?.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: res?.[0].message,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
        return updatedRow;
      }
    } catch {}
  };
  const processSubRowUpdate = async (newRow) => {
    setTableDataLoading(true);
    try {
      let inp = [
        {
          id: !newRow.isNew ? newRow.id : null,
          facility: selectedRow?.facility,
          payerid: selectedRow?.payerid,
          chargeid: selectedRow?.chargeid,
          payerplan: selectedRow?.payerplan,
          encclass: selectedRow?.encclass,
          facility_name: selectedRow?.facility_name,
          payer_name: selectedRow?.payer_name,
          charge_desc: selectedRow?.charge_desc,
          sheetName: "MaxCov",
          covtyp: newRow?.covtyp,
          durtype: newRow?.durtype,
          value: newRow?.value,
        },
      ];

      const res = await setTableData(inp);
      setTableDataLoading(false);
      if (res?.[0].error) {
        Alert?.setSnack({
          open: true,
          severity: AlertProps.severity.warning,
          msg: res?.[0].message,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
        setSubRowModesModel({
          ...subRowModesModel,
          [newRow.id]: { mode: GridRowModes.Edit },
        });
        return newRow;
      } else {
        let updatedRow = {};
        if (newRow.isNew) {
          updatedRow = res?.[0].response;
        } else {
          updatedRow = { ...newRow, isNew: false };
        }
        setSubRows(
          subRows.map((row) => (row.id === newRow.id ? updatedRow : row))
        );
        await getTableData();
        Alert?.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: res?.[0].message,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
        return updatedRow;
      }
    } catch (error) {}
  };

  const handleAutocompleteChange = (index, field, newValue) => {
    if (field === "payerid") {
      setDefaultPayer(newValue);
    } else if (field === "facility") {
      setDefaultFacility(newValue);
    } else if (field === "chargeid") {
      setDefaultCharge(newValue);
    }
  };
  const LoadDefaultValue = (id, type) => {
    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

    if (!isInEditMode) {
      if (type == "facility") {
        return rows[id]?.facility?.label;
      } else if (type == "payerid") {
        return rows[id]?.payerid?.label;
      } else {
        return rows[id]?.chargeid?.label;
      }
    } else {
      if (type == "facility") {
        return defaultFacility;
      } else if (type == "payerid") {
        return defaultPayer;
      } else {
        return defaultCharge;
      }
    }
  };
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleSubRowModesModelChange = (newRowModesModel) => {
    setSubRowModesModel(newRowModesModel);
  };
  const onPaginationModelChange = async(page) => {
    setPaginationModel(page);
    let inp = tableInput;
    let start = page.page * page.pageSize;
    inp.recstart = start + 1;
    inp.recend = start + page.pageSize;
    setTableInput(inp);
    await getTableData();
  };
  const MaxCovPopupClose = () => {
    setIsMaxCov(false);
  };

  const cover = [
    { value: "covered", label: "Covered" },
    { value: "not covered", label: "Not Covered" },
  ];

  const columns = [
    {
      field: "facility",
      headerName: " Facility Name",
      headerClassName: "super-app-theme--header",
      width: 220,
      editable: true,
      type: "singleSelect",
      valueOptions: facilityList,
      renderEditCell: (params) => (
        <Grid item>
          {
            <Autocomplete
              id="tags-filled"
              options={facilityList}
              getOptionLabel={(option) => option?.label ?? ""}
              hoverColor={UIColor.primaryColor.color}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  // label="Facility"
                  hoverColor={UIColor.primaryColor.color}
                  sx={{
                    "& .MuiAutocomplete-inputRoot": {
                      borderRadius: "4px",
                      border: "1px  #E0E0E0",
                      width: "210px",
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              value={LoadDefaultValue(parseInt(params?.row?.id), "facility")}
              onChange={(event, newValue) =>
                handleAutocompleteChange(
                  parseInt(params?.row?.id),
                  "facility",
                  newValue
                )
              }
            />
          }
        </Grid>
      ),
    },
    {
      field: "payerid",
      headerName: "Payer Name",
      headerClassName: "super-app-theme--header",
      width: 220,
      editable: true,
      type: "singleSelect",
      valueOptions: payerList,
      renderEditCell: (param) => (
        <Grid item>
          <Autocomplete
            id="tags-filled"
            options={payerList}
            getOptionLabel={(option) => option?.label ?? ""}
            hoverColor={UIColor.primaryColor.color}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                placeholder="search payer"
                // label="Payer"
                hoverColor={UIColor.primaryColor.color}
                sx={{
                  "& .MuiAutocomplete-inputRoot": {
                    borderRadius: "4px",
                    border: "1px  #E0E0E0",
                    width: "210px",
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
            value={LoadDefaultValue(parseInt(param?.row?.id), "payerid")}
            onChange={(event, newValue) =>
              handleAutocompleteChange(
                parseInt(param?.row?.id),
                "payerid",
                newValue
              )
            }
          />
        </Grid>
      ),
    },
    {
      field: "chargeid",
      headerName: "Item Name",
      headerClassName: "super-app-theme--header",
      width: 220,
      editable: true,
      type: "singleSelect",
      valueOptions: chargecodeList,
      renderEditCell: (param) => (
        <Grid item>
          <Autocomplete
            id="tags-filled"
            options={chargecodeList}
            getOptionLabel={(option) => option?.label ?? ""}
            hoverColor={UIColor.primaryColor.color}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                placeholder="search Item Name"
                // label="Item Name"
                hoverColor={UIColor.primaryColor.color}
                sx={{
                  "& .MuiAutocomplete-inputRoot": {
                    borderRadius: "4px",
                    border: "1px  #E0E0E0",
                    width: "210px",
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
            value={LoadDefaultValue(parseInt(param?.row?.id), "chargeid")}
            onChange={(event, newValue) =>
              handleAutocompleteChange(
                parseInt(param?.row?.id),
                "chargeid",
                newValue
              )
            }
          />
        </Grid>
      ),
    },
    {
      field: "payerplan",
      headerName: "Payer Plan",
      headerClassName: "super-app-theme--header",
      width: 140,
      editable: true,
    },
    {
      field: "encclass",
      headerName: "Encounter Class",
      headerClassName: "super-app-theme--header",
      width: 130,
      editable: true,
    },
    {
      field: "price",
      headerName: "Price",
      headerClassName: "super-app-theme--header",
      type: "number",
      width: 80,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "insurance_share",
      headerName: "Insurance Share",
      headerClassName: "super-app-theme--header",
      type: "number",
      width: 80,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "patient_share",
      headerName: "Patient Share",
      headerClassName: "super-app-theme--header",
      type: "number",
      width: 80,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "discount",
      headerName: "Discount",
      headerClassName: "super-app-theme--header",
      type: "number",
      width: 80,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "coverage",
      headerName: "Coverage",
      headerClassName: "super-app-theme--header",
      width: 140,
      editable: true,
      type: "singleSelect",
      valueOptions: cover,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      width: 100,
      cellClassName: "actions",
      getActions: (data) => {
        const isInEditMode = rowModesModel[data.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(data.id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              title="Cancel"
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(data.id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            title="Edit"
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(data.id)}
            color="inherit"
          />,
          // <GridActionsCellItem
          //   icon={<DeleteIcon />}
          //   label="Delete"
          //   onClick={handleDeleteClick(data)}
          //   color="inherit"
          // />,
          <GridActionsCellItem
            icon={<AddCircleIcon />}
            title="Max Covarage"
            label="Max Covarage"
            onClick={handleMaxCovClick(data)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const coverType = [
    { value: "amt", label: "Amount" },
    { value: "qty", label: "Quantity" },
  ];
  const SubdetColumns = [
    {
      field: "durtype",
      headerName: "Duration Type",
      width: 170,
      editable: true,
      type: "singleSelect",
      valueOptions: ["Daily", "Monthly", "Yearly"],
    },
    {
      field: "covtyp",
      headerName: "Coverage Type",
      width: 170,
      editable: true,
      type: "singleSelect",
      valueOptions: coverType,
    },
    {
      field: "value",
      headerName: "Value",
      type: "number",
      width: 80,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (data) => {
        const isInEditMode =
          subRowModesModel[data.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSubSaveClick(data.id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              title="Cancel"
              label="Cancel"
              className="textPrimary"
              onClick={handleSubCancelClick(data.id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            title="Edit"
            label="Edit"
            className="textPrimary"
            onClick={handleSubEditClick(data.id)}
            color="inherit"
          />,
          // <GridActionsCellItem
          //   icon={<DeleteIcon />}
          //   label="Delete"
          //   onClick={handleDeleteClick(data)}
          //   color="inherit"
          // />,
        ];
      },
    },
  ];

  return (
    <ThemeProvider theme={newtheme}>
      <Box
        sx={{
          height: 500,
          width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
          "& .super-app-theme--header": {
            backgroundColor: newtheme.palette.primary.main,
            color: "#ffffff",
          },
          "& .font-tabular-nums": {},
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          editMode="row"
          rowCount={rowCount}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={onPaginationModelChange}
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: {
              setRows,
              rows,
              rowModesModel,
              setRowModesModel,
              focus: "facility",
              title: "Payer Mapping",
              setDefaultCharge,
              setDefaultFacility,
              setDefaultPayer
            },
          }}
          loading={tableDataLoading}
        />

        <Dialog
          maxWidth="sm"
          fullWidth
          open={isMaxCov}
          onClose={() => MaxCovPopupClose((pre) => !pre)}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Max Coverage Details
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => MaxCovPopupClose((pre) => !pre)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
          {/* <DialogTitle disableTypography >
            Max Coverage Details
            <IconButton onClick={() => MaxCovPopupClose((pre) => !pre)}>
                <CancelIcon />
            </IconButton>
        </DialogTitle> */}
          <DialogContent>
            <Grid container>
              <Grid item xs={4}>
                Facility
              </Grid>
              <Grid item xs={8}>
                {selectedRow.facility_name}
              </Grid>
              <Grid item xs={4}>
                Payer
              </Grid>
              <Grid item xs={8}>
                {selectedRow.payer_name}
              </Grid>
              <Grid item xs={4}>
                Item Name
              </Grid>
              <Grid item xs={8}>
                {selectedRow.charge_desc}
              </Grid>
              <Grid item>
                <DataGrid
                  rows={subRows}
                  columns={SubdetColumns}
                  editMode="row"
                  rowModesModel={subRowModesModel}
                  onRowModesModelChange={handleSubRowModesModelChange}
                  onRowEditStop={handleRowEditStop}
                  processRowUpdate={processSubRowUpdate}
                  slots={{
                    toolbar: EditToolbar,
                  }}
                  slotProps={{
                    toolbar: {
                      setRows: setSubRows,
                      rows: subRows,
                      rowModesModel: subRowModesModel,
                      setRowModesModel: setSubRowModesModel,
                      focus: "durtype",
                      title: "Coverage Detail",
                      setDefaultCharge,
                      setDefaultFacility,
                      setDefaultPayer
                    },
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
}
