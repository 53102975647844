import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
  dbName,
  defaultReject,
  defaultState,
  fetchData,
  urls,
  query_ids,
  utcTOLocal,
  timeLine_,
  getTenantId,
} from "../../utils";

const PATIENT_DETAILS_READ = createAsyncThunk(
  `patientReducer/readPatientDetails`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { key = "" } = payload;
      // const read_p = {
      // 	// "db_name": db_name,
      // 	db_name: dbName,
      // 	entity: "Patient",
      // 	filter: `Patient._key=='${key}' && Patient.activestatus==true`,
      // 	return_fields:
      // 		"merge(Patient,{telecom:(for doc in ContactPointMaster filter doc._id in Patient.telecom return merge(doc,{system:(document(doc.system)),use:(document(doc.use)),valueprefix:(document(doc.valueprefix)),rank:(document(doc.rank))}))},{name:(for doc in HumanNameMaster filter doc._id in Patient.name return merge(doc,{use:(document(doc.use)),prefix:(document(doc.prefix)),suffix:(document(doc.suffix))}))},{gender:document(Patient.gender)},{address:(for doc in AddressMaster filter doc._id in Patient.address return merge(doc,{use:(document(doc.use)),city:(document(doc.city)),country:(document(doc.city)),district:(document(doc.district)), Type:(document(doc.Type)),postalCode:(document(doc.postalCode)),state:(document(doc.state))}))},{maritalstatus:document(Patient.maritalstatus)},{photo:document(Patient.photo)},{OrgID:document(Patient.OrgID)},{nationality:document(Patient.nationality)},{activereason:document(Patient.activereason)},{isdeceased:document(Patient.isdeceased)},{deceasedreason:document(Patient.deceasedreason)},{identifier:(for doc in IdentifierMaster filter doc._id in Patient.identifier return merge(doc,{user:(document(doc.user)), assigner:(document(doc.assigner)), period: (document(doc.period))}))},{communicationLanguage:document(Patient.communicationLanguage)},{birthGendercode:document(Patient.birthGendercode)},{citizenshipCode:document(Patient.citizenshipCode)},{disabilityCode:document(Patient.disabilityCode)},{genderIdentitycode:document(Patient.genderIdentitycode)},{religionCode:document(Patient.religionCode)},{raceCode:document(Patient.raceCode)},{ethinicityCode:document(Patient.ethinicityCode)},{patientTypecode:document(Patient.patientTypecode)},{bloodGroup:document(Patient.bloodGroup)},{attachment:document(Patient.attachment)},{age_type:document(Patient.age_type)},{primarycare_Prac:document(Patient.primarycare_Prac)})",
      // };
      const read_p = {
        db_name: dbName,
        entity: "Patient",
        filter: `Patient._key=='${key}' && Patient.activestatus==true`,
        return_fields:
          "merge(Patient,{telecom:(for doc in ContactPointMaster filter doc._id in Patient.telecom return merge(doc,{system:(document(doc.system)),use:(document(doc.use)),valueprefix:(document(doc.valueprefix)),rank:(document(doc.rank))}))},{name:(for doc in HumanNameMaster filter doc._id in Patient.name return merge(doc,{use:(document(doc.use)),prefix:(document(doc.prefix)),suffix:(document(doc.suffix))}))},{gender:document(Patient.gender)},{address:(for doc in AddressMaster filter doc._id in Patient.address return merge(doc,{use:(document(doc.use)),city:(document(doc.city)),country:(document(doc.country)),district:(document(doc.district)), Type:(document(doc.Type)),postalCode:(document(doc.postalCode)),state:(document(doc.state))}))},{maritalstatus:document(Patient.maritalstatus)},{photo:document(Patient.photo)},{OrgID:document(Patient.OrgID)},{nationality:document(Patient.nationality)},{activereason:document(Patient.activereason)},{isdeceased:document(Patient.isdeceased)},{deceasedreason:document(Patient.deceasedreason)},{identifier:(for doc in IdentifierMaster filter doc._id in Patient.identifier return merge(doc,{user:(document(doc.user)), assigner:(document(doc.assigner)), period: (document(doc.period))}))},{communicationLanguage:document(Patient.communicationLanguage)},{birthGendercode:document(Patient.birthGendercode)},{citizenshipCode:document(Patient.citizenshipCode)},{disabilityCode:document(Patient.disabilityCode)},{genderIdentitycode:document(Patient.genderIdentitycode)},{religionCode:document(Patient.religionCode)},{raceCode:document(Patient.raceCode)},{ethinicityCode:document(Patient.ethinicityCode)},{patientTypecode:document(Patient.patientTypecode)},{bloodGroup:document(Patient.bloodGroup)},{attachment:document(Patient.attachment)},{age_type:document(Patient.age_type)},{primarycare_Prac:document(Patient.primarycare_Prac)},{specialArng_code:document(Patient.specialArng_code)},{specialCourtesy_code: document(Patient.specialCourtesy_code)})",
      };

      const data = await fetchData(
        {
          body: JSON.stringify(read_p),
        },
        urls.readDocuments
      );

      return {
        ...defaultState.List,
        data: data.result,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const UPSERT_CANCEL_APP_PATIENT = createAsyncThunk(
  `patientReducer/cancelAapp`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { personId = 0 } = payload;

      const body = {
        personId: personId,
        startDateTime: moment(utcTOLocal(), "DD-MM-YYYY").unix(),
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.cancelAllAppointmentUrl
      );

      return {
        ...defaultState.List,
        data: data.result,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        data: {
          error: err.message || err,
        },
      });
    }
  }
);

const PATIENT_INFO = createAsyncThunk(
  `patientReducer/patientInfo`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const {
        patientid = "",
        tenantid = "",
        facilityid = "",
        lang = "",
        encounterid="",
        personid=""
      } = payload;
      let body = {}
      if(personid){
        body={
          db_name: dbName,
          queryid: "1223da72-0672-4498-8df1-aac92f602a3f",
          filter: {
            "Personid": personid,
	          "tenantid":""
          },
        };
      }
      else{
        body={
          db_name: dbName,
          queryid: query_ids["patientReducer/patientInfo"],
          filter: {
            patientid: patientid,
            tenantid: tenantid,
            facilityid: facilityid,
            lang: lang,
            encounterid
          },
        };
      }
     
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data?.[0] || {},
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const APPOINTMENT_ID_GET_ENCOUNTE_DETAILS = createAsyncThunk(
  `patientReducer/getaApoEncounterDetials`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { appointmentId = "", tenantid = "", facilityid = "" ,encounterid ="" } = payload;
      const body = {
        db_name: dbName,
        filter: {
          appointmentId: appointmentId,
          tenantid: tenantid,
          facilityid: facilityid,
          encounterid:encounterid,
        },
        queryid: query_ids.getAppointmentAgainstEncounter,
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const NATIONALITY_LIST = createAsyncThunk(
  `patientReducer/nationalitylist`,
  async (payload = {}, { rejectWithValue }) => {
    try {
     
      const body = {
        db_name: dbName,
        filter: {
        tenantid: "",
        facilityid: "",
        lang: ""
      },
        queryid: "ca00f3b0-1654-4ee0-8470-065446ca0a00"
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data || [],
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

// PATIENT CREATE INITIALSTATE
const patientSlice = createSlice({
  name: "patientReducer",
  initialState: {
    PatientDetails: {
      ...defaultState.Info,
    },
    PatientCancelDetails: {
      ...defaultState.Info,
    },
    PatientInfo: {
      ...defaultState.Info,
    },
    getaApoEncounterDetials: {
      ...defaultState.Info,
    },
    nationalitylist:{
      ...defaultState.Info,
    },
  },
  extraReducers: {
    /* PATIENT_DETAILS_READ */
    [PATIENT_DETAILS_READ.fulfilled]: (state, action) => {
      state.PatientDetails = action?.payload ?? {};
    },
    [PATIENT_DETAILS_READ.pending]: (state, action) => {
      state.PatientDetails.loading = true;
    },
    [PATIENT_DETAILS_READ.rejected]: (state, action) => {
      state.PatientDetails = action?.payload ?? {};
    },

    /* UPSERT_CANCEL_APP_PATIENT */
    [UPSERT_CANCEL_APP_PATIENT.fulfilled]: (state, action) => {
      state.PatientCancelDetails = action?.payload ?? {};
    },
    [UPSERT_CANCEL_APP_PATIENT.pending]: (state, action) => {
      state.PatientCancelDetails.loading = true;
    },
    [UPSERT_CANCEL_APP_PATIENT.rejected]: (state, action) => {
      state.PatientCancelDetails = action?.payload ?? {};
    },

    /* PATIENT_INFO */
    [PATIENT_INFO.fulfilled]: (state, action) => {
      state.PatientInfo = action?.payload ?? {};
    },
    [PATIENT_INFO.pending]: (state, action) => {
      state.PatientInfo.loading = true;
    },
    [PATIENT_INFO.rejected]: (state, action) => {
      state.PatientInfo = action?.payload ?? {};
    },
    /* APPOINTMENT_ID_GET_ENCOUNTE_DETAILS */
    [APPOINTMENT_ID_GET_ENCOUNTE_DETAILS.fulfilled]: (state, action) => {
      state.getaApoEncounterDetials = action?.payload ?? {};
    },
    [APPOINTMENT_ID_GET_ENCOUNTE_DETAILS.pending]: (state, action) => {
      state.getaApoEncounterDetials.loading = true;
    },
    [APPOINTMENT_ID_GET_ENCOUNTE_DETAILS.rejected]: (state, action) => {
      state.getaApoEncounterDetials = action?.payload ?? {};
    },
    /*Nationality list*/
    [NATIONALITY_LIST.fulfilled]: (state, action) => {
      state.nationalitylist = action?.payload ?? {};
    },
    [NATIONALITY_LIST.pending]: (state, action) => {
      state.nationalitylist.loading = true;
    },
    [NATIONALITY_LIST.rejected]: (state, action) => {
      state.nationalitylist = action?.payload ?? {};
    },
  },
});
// end


// PATIENT ACTION LISTS
export const patientActions = {
  PATIENT_DETAILS_READ,
  UPSERT_CANCEL_APP_PATIENT,
  PATIENT_INFO,
  APPOINTMENT_ID_GET_ENCOUNTE_DETAILS,
  NATIONALITY_LIST,
};
// end

export default patientSlice;
