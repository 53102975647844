import React, { useContext } from "react";
import qdmstyles from "./styles.json";
import clsx from "clsx";
import { AlertProps, checkError, convertTime, getImgUrl, makeName } from "../../../utils";
import {
  Container,
  H6,
  Divider,
  Text,
  Div,
  TextInput,
  Button,
  Avatar,
  Dropdown,
  MobileNumberWithCode,
  TermsAndConditions,
} from "qdm-component-library";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState } from "react";
import { useEffect } from "react";
import { useStyles } from "./style";
import { UIColor } from "../../../themes/theme";
import {AlertContext} from "../../../context/index" 
import actions from "../../../redux/actions";
import { useDispatch } from "react-redux";
function AddMember(props) {
  const alert = useContext(AlertContext);
  const dispatch = useDispatch()
  const [state, setState] = useState({
    showMembers: false,
    error: null,
    expanded1: false,
    expanded2: false,
    addmemberlist: null,
    type: "text",
    limit: 20,
    listMmbr: [],
  });

  const handleExpandClick = (val) => {
    console.log(val + ' vimport clsx from "clsx";');
    val === 1
      ? setState({
          ...state,
          expanded1: !state.expanded1,
        })
      : setState({
          expanded2: !state.expanded2,
        });
  };
  const changeState = (key, val) => {
    let value = val;
    if (key === "Aadhar Number") {
      if (
        value &&
        value.length < state.limit &&
        value.replaceAll("-", "")?.length % 4 === 0
      ) {
        value += "-";
      }
    }

    setState({
      ...state,
      [key]: value,
    });
  };

  const onKeyDown = (key, e) => {
    if (key === "Aadhar Number") {
      let value = e.target.value;
      if (e.keyCode === 8) {
        if (value[value.length - 1] === "-") {
          value = value.substring(0, value.length - 2);
          e.preventDefault();
        } else if (value[value.length - 2] === "-") {
          value = value.substring(0, value.length - 1);
          e.preventDefault();
        }
        setState({
          ...state,
          [key]: value,
        });
      } else if (e.keyCode < 48 || e.keyCode > 57) {
        e.preventDefault();
      }
    }
  };

  useEffect(() => {
    (async function () {
      await dispatch(actions.GET_COUNTRY_LIST());
    })();
  }, []);

  const senOtpMMr = async () => {
    // ;

    // let res__ = mobileNumberValidation(state.mobileNumber.mobileNo, state.mobileNumber.countryData.callingCodes);
    //
    // 	if (res__ === "Invalid Country code") {
    // 		props.alert.setSnack({
    // 			open: true,
    // 			severity: AlertProps.severity.error,
    // 			msg: res__,
    // 			vertical: AlertProps.vertical.top,
    // 			horizontal: AlertProps.horizontal.right,
    // 		});
    // 		return
    // 	} else if (res__ === false) {
    // 		props.alert.setSnack({
    // 			open: true,
    // 			severity: AlertProps.severity.error,
    // 			msg: "Please enter valid mobile number",
    // 			vertical: AlertProps.vertical.top,
    // 			horizontal: AlertProps.horizontal.right,
    // 		});
    // 		return
    // 	}

    const SearchUserData = await props.GENERATE_OTP({
      mobileNo: state.selectData?.telecom?.find(
        (tel) => tel.system === "Phone" && tel.use === "Mobile"
      )?.value,
    });
    if (SearchUserData.payload.data.error) {
      // alert.setSnack({
      //   open: true,
      //   severity: AlertProps.severity.error,
      //   msg: SearchUserData.payload.data.errorMessage,
      //   vertical: AlertProps.vertical.top,
      //   horizontal: AlertProps.horizontal.center,
      //   tone: true,
      // });
      return;
    } else {
      const countryCode = `${
        state[props?.chooseSearch?.value]?.countryData?.callingCodes ?? "00"
      }`;
      const phoneNumber = state.selectData?.telecom
        ?.find((tel) => tel.system === "Phone" && tel.use === "Mobile")
        ?.value?.split(countryCode);
      // props.serchMMrDatafuncydata(SearchUserData)
      const signUpData = {
        mobileNo: {
          mobileNo: phoneNumber[1] || phoneNumber[0],
          countryData: {
            callingCodes: countryCode,
          },
        },
        selectData: state?.selectData,
        id: state?.selectData.Id,
        SearchUserData,
        firstName:
          state?.selectData?.name.text || state?.selectData?.name.given,
        lastName: state?.selectData?.name.family,
        dob: state?.selectData?.birthDay,
        gender: state?.selectData?.gender,
        emailId: state?.selectData?.telecom?.find(
          (tel) => tel.system === "Email" && tel.use === "Work"
        )?.value,
        middleName: "",
      };
      props.nextSection("addmemberotp", "addMember", signUpData);
    }
  };
  const searchMember = async () => {
    let searchType = props?.chooseSearch?.value;
    let value = state?.[searchType];
    let checkErr = checkErr(searchType, value);
    if (searchType && value && checkErr) {
      const SearchUserData = await props.SEARCH_PERSON({
        phone: state[props?.chooseSearch?.value]?.mobileNo,
        // phone: '8056895305',
        use: "Mobile",
      });

      if (SearchUserData?.payload?.data) {
        setState({
          ...state,
          showMembers: true,
          error: null,
          listMmbr: SearchUserData?.payload?.data,
        });
      }
      setState({
        ...state,
        showMembers: true,
        error: null,
      });
    } else {
      setState({
        ...state,
        showMembers: false,
        error: errorMessage(searchType, value),
      });
    }
  };

  const checkErr = (stateName, val) => {
    if (stateName === "PID Number") {
      return val?.length === 20 ? true : false;
    } else if (stateName === "Aadhar Number") {
      return val?.length === 14 ? true : false;
    } else if (stateName === "Mobile Number") {
      return val?.countryData?.callingCodes && val?.mobileNo?.length === 10
        ? true
        : false;
    } else {
      return true;
    }
  };

  const errorMessage = (searchType, value) => {
    if (searchType === "PID Number") {
      return "Please enter valid PID Number";
    } else if (searchType === "Aadhar Number") {
      return "Please enter valid Aadhar Number";
    } else if (searchType === "Mobile Number") {
      if (!value?.countryData?.callingCodes) {
        return "Please select country code";
      } else if (value?.mobileNo?.length !== 10) {
        return "Please enter valid Mobile Number";
      }
    } else {
      return "Please fill all the mandatory fields";
    }
  };

  const handleCheked = (v, l) => {
    setState({
      ...state,
      addmemberlist: l,
      selectData: v,
    });
  };

  const handleChange = (e) => {
    let type, limit;
    if (e.value === "PID Number") {
      type = "text";
      limit = 20;
    } else if (e.value === "Aadhar Number") {
      type = "text";
      limit = 14;
    }
    props.handelChangeSelect && props.handelChangeSelect(e);
    setState({
      ...state,
      type,
      limit,
      [e.value]: "",
    });
  };

  const onChangeStateName = () => {
    return (
      (props.chooseSearch?.value === "PID Number" && "PID Number") ||
      (props.chooseSearch?.value === "Aadhar Number" && "Aadhar Number") ||
      (props.chooseSearch?.value === "Emirate ID" && "Emirate ID") ||
      (props.chooseSearch?.value === "SSN Number" && "SSN Number")
    );
  };

  const classes = useStyles();
  const { addmemberlist, parent_id } = state;
  return (
    <div id={`${parent_id}_addmember_parent_div`}>
      <Container
        id={`${parent_id}_addmember_parent_container`}
        key={"0"}
        name={"container"}
        fluid={true}
        style={qdmstyles.rpP}
      >
        <H6
          id={`${parent_id}_addmember_sidemenu_title`}
          className={"pc_semibold"}
          key={"0"}
          name={props.name}
          style={{
            fontSize: "20px",
            color: UIColor.primaryText.color,
            letterSpacing: 0,
            lineHeight: 1,
          }}
        ></H6>

        <Divider
          id={`${parent_id}_addmember_sidemenu_title_divider`}
          key={"1"}
          textWidth={"50"}
          textColor={"#000"}
          borderColor={"lightgray"}
          borderWidth={"1"}
          orientation={"horizontal"}
          variant={"middle"}
          style={qdmstyles.BKv}
        ></Divider>

        {/* <Collapse
            key={"2"}
            title={"Existing Member"}
            subtitle={""}
            body={"Collapse Body"}
            iconPosition={"left"}
            iconHeight={"20"}
            iconWidth={"20"}
            url={""}
            style={qdmstyles.aGaSE}
            openIcon={
              <svg
                width="26px"
                class="MuiSvgIcon-root"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
              </svg>
            }
            closeIcon={
              <svg
                width="26px"
                class="MuiSvgIcon-root jss107"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"></path>
              </svg>
            }
          > */}

        <Card
          id={`${parent_id}_addmember_sidemenu_search_member_card`}
          style={{
            overflow: "visible",
            boxShadow: "none",
            border: "1px solid #DEE5EC",
            borderRadius: 8,
          }}
        >
          <CardActions
            id={`${parent_id}_addmember_sidemenu_search_member_cardaction`}
            onClick={() => handleExpandClick(1)}
            disableSpacing
            style={{
              padding: 0,
              paddingLeft: 16,
              paddingBottom: 0,
              cursor: "pointer",
            }}
          >
            <Text
              id={`${parent_id}_addmember_sidemenu_existing_member_text`}
              key={"0"}
              name="Existing Member"
              style={{
                fontSize: "16px",
                color: UIColor.primaryText.color,
                letterSpacing: 0,
                lineHeight: 1,
              }}
              className={"pc_semibold"}
            />

            <IconButton
              id={`${parent_id}_addmember_sidemenu_existing_member_icon_button`}
              className={clsx(classes.expand, {
                [classes.expandOpen]: state.expanded1,
              })}
              aria-expanded={state.expanded1}
              aria-label="show more"
            >
              <ExpandMoreIcon
                id={`${parent_id}_addmember_sidemenu_existing_member_icon`}
              />
            </IconButton>
          </CardActions>
          <Collapse
            id={`${parent_id}_addmember_sidemenu_existing_member_collapse`}
            className={classes.body}
            in={state.expanded1}
            timeout="auto"
            unmountOnExit
          >
            <H6
              id={`${parent_id}_addmember_sidemenu_existing_member_discription_text`}
              key={"0"}
              name={"If the member already registered with the hospital."}
              style={{
                width: "100%",
                display: "flex",
                fontSize: "12px",
                marginBottom: "24px",
                color: UIColor.secondaryText.color,
                letterSpacing: 0,
                lineHeight: 1,
              }}
              className="pc_regular"
            ></H6>

            <H6
              id={`${parent_id}_addmember_sidemenu_existing_member_chooseid_text`}
              key={"1"}
              className="pc_regular"
              name={
                <span
                  id={`${parent_id}_addmember_sidemenu_existing_member_chooseid_span`}
                  className="pc_regular"
                  style={{
                    fontSize: 12,
                    letterSpacing: 0,
                    lineHeight: 1,
                    color: UIColor.secondaryText.color,
                  }}
                >
                  Choose ID and enter criteria{" "}
                  <span
                    style={{ color: UIColor.error.color }}
                    id={`${parent_id}_addmember_sidemenu_existing_member_chooseid_staricon`}
                  >
                    *
                  </span>
                </span>
              }
              style={{
                width: "100%",
                color: UIColor.secondaryText.color,
                fontSize: "12px",
              }}
            ></H6>

            <Div
              key={"2"}
              className={"qdm"}
              style={{
                width: "100%",
                color: UIColor.secondaryText.color,
                fontSize: "12px",
                display: "flex",
                marginTop: "10px",
              }}
              id={`${parent_id}_addmember_sidemenu_chooseid_here_dropdown_parent_div`}
            >
              <div
                id={`${parent_id}_addmember_sidemenu_chooseid_here_dropdown_sublevel_div`}
                style={{ marginRight: 8 }}
              >
                <Dropdown
                  id={`${parent_id}_addmember_sidemenu_chooseid_here_dropdown`}
                  inLineStyles={{
                    height: "42px",
                    fontWeight: "400",
                    fontSize: "13px",
                    color: UIColor.secondaryText.color,
                  }}
                  name="Choose ID here"
                  onChange={(e) => {
                    if (state.error) {
                      setState(
                        {
                          ...state,
                          error: null,
                        },
                        () => {
                          handleChange(e);
                        }
                      );
                    } else {
                      handleChange(e);
                    }
                  }}
                  placeholder="Choose ID here"
                  className={
                    !props.chooseSearch && state.error
                      ? classes.dropdownErr
                      : classes.dropdown
                  }
                  options={[
                    {
                      value: "Mobile Number",
                      label: "Mobile Number",
                    },
                    {
                      value: "PID Number",
                      label: "MRN Number",
                    },
                    {
                      value: "Aadhaar Number",
                      label: "Aadhaar Number",
                    },
                    // {
                    //   value: 'Emirate ID',
                    //   label: 'Emirate ID'
                    // },
                    // {
                    //   value: 'SSN Number',
                    //   label: 'SSN Number'
                    // }
                  ]}
                  // options = { props?.practitionerMaster?.id_type_master?.data}
                  value={props.chooseSearch}
                />
              </div>
              {props.chooseSearch?.value === "Mobile Number" ? (
                <div className={classes.mobile}>
                  <MobileNumberWithCode
                    id={`${parent_id}_addmember_sidemenu_mobilenumberwithcode_textfield`}
                    value={state["Mobile Number"]?.mobileNo}
                    defaultValue={state["Mobile Number"]?.mobileNo}
                    dropdown={state["Mobile Number"]?.countryData}
                    key={"2"}
                    type="number"
                    label="Mobile No"
                    borderColor={UIColor.lineBorderFill.color}
                    borderRadius="6"
                    maxLength={30}
                    name=""
                    width=""
                    dropdownData={this?.props?.CountryCode?.data}
                    disabled={false}
                    size=""
                    marginAll="0"
                    marginLR="0"
                    marginTB="0"
                    borderWidth="0"
                    textWidth="0"
                    style={{
                      height: "40px",
                      borderRadius: "8px",
                      // width: "110%",
                    }}
                    onChange={(e) => {
                      if (
                        state.error &&
                        (state.error === "Please select country code" ||
                          state.error === "Please enter valid Mobile Number")
                      ) {
                        let errMsg = null;
                        if (
                          !e?.countryData?.callingCodes &&
                          state.error === "Please select country code"
                        ) {
                          errMsg = "Please select country code";
                        }
                        // else if (
                        //   e?.mobileNo?.length !== 10 &&
                        //   state.error ===
                        //   "Please enter valid Mobile Number"
                        // ) {
                        //   errMsg = "Please enter valid Mobile Number";
                        // }
                        setState(
                          {
                            ...state,
                            error: errMsg,
                          },
                          () => {
                            changeState("Mobile Number", e);
                          }
                        );
                      } else {
                        setState({
                          ...state,
                          error: "",
                        });
                        changeState("Mobile Number", e);
                      }
                    }}
                    className={state.error && "errorBorder"}
                  />
                </div>
              ) : (
                <TextInput
                  id={`${parent_id}_addmember_sidemenu_choose_member_textfield`}
                  className={classes.textField}
                  key={"0"}
                  type={state.type}
                  // onInput={e=>e>10 && }
                  variant={"outlined"}
                  value={state[props?.chooseSearch?.value]}
                  onChange={(e) => {
                    const type = onChangeStateName();
                    if (
                      state.error &&
                      state.error === "Please enter valid Aadhaar Number" &&
                      type === "Aadhaar Number"
                    ) {
                      const value = e?.target?.value || "";
                      let errMsg = null;
                      if (
                        value?.length !== 14 &&
                        state.error === "Please enter valid Aadhaar Number"
                      ) {
                        errMsg = "Please enter valid Aadhaar Number";
                      }
                      setState(
                        {
                          ...state,
                          error: errMsg,
                        },
                        () => {
                          changeState(type, value);
                        }
                      );
                    } else if (
                      state.error &&
                      state.error === "Please enter valid MRN Number" &&
                      type === "PID Number"
                    ) {
                      const value = e?.target?.value || "";
                      let errMsg = null;
                      if (
                        !/MRN00_[0-9]+$/.test(value) &&
                        state.error === "Please enter valid MRN Number"
                      ) {
                        errMsg = "Please enter valid MRN Number";
                      }
                      setState(
                        {
                          ...state,
                          error: errMsg,
                        },
                        () => {
                          changeState(type, value);
                        }
                      );
                    } else {
                      changeState(type, e.target.value);
                    }
                  }}
                  onKeyDown={(e) => onKeyDown(onChangeStateName(), e)}
                  helperTextType={"error"}
                  autoFocus={false}
                  autoComplete={"off"}
                  maxLength={state.limit}
                  search={false}
                  borderColor={UIColor.lineBorderFill.color}
                  elevation={"0"}
                  placeholder={
                    (props.chooseSearch?.value === "PID Number" &&
                      "MRN00_000") ||
                    (props.chooseSearch?.value === "Aadhaar Number" &&
                      "4444-4444-4444") ||
                    (props.chooseSearch?.value === "Emirate ID" &&
                      "989-2345-2345-1") ||
                    (props.chooseSearch?.value === "SSN Number" &&
                      "123-12-1234")
                  }
                  size={"medium"}
                  style={{
                    borderRadius: "8px",
                    height: "40px",
                    //borderColor: UIColor.lineBorderFill.color,
                  }}
                  name={""}
                  label={""}
                  error={state.error}
                ></TextInput>
              )}
            </Div>
            {state.error && (
              <div
                id={`${parent_id}_addmember_sidemenu_choose_member_error_div`}
                style={{ marginTop: "10px" }}
              >
                <Text
                  id={`${parent_id}_addmember_sidemenu_choose_member_error_text`}
                  style={{ color: UIColor.error.color, fontSize: 12 }}
                  className="pc_regular"
                >
                  {state.error}
                </Text>
              </div>
            )}
            <div style={{ margin: "16px auto", textAlign: "center" }}>
              <Button
                id={`${parent_id}_addmember_sidemenu_choose_member_search_button`}
                key={"1"}
                type={"button"}
                variant={"text"}
                disabled={state.loading}
                name={state.loading ? "Searching..." : "Search"}
                // inLineStyles={{
                //   display: "flex",
                //   margin: "auto",
                //   marginTop: "16px !important",
                //   marginBottom: "16px",
                // }}
                style={{
                  padding: "10px 16px",
                  borderRadius: "8px",
                  fontSize: "14px",
                  borderColor: UIColor.lineBorderFill.color,
                  color: UIColor.primaryColor.color,
                  //color: "#fff",
                  backgroundColor: UIColor.lineBorderFill.color,
                  height: "40px",
                  lineHeight: 1,
                  letterSpacing: 0,
                }}
                className="pc_medium"
                onClick={() => searchMember()}
              ></Button>
            </div>
            {state?.showMembers && (
              <>
                {state?.listMmbr?.map((v, key) => {
                  return (
                    <Div
                      id={`${parent_id}_addmember_sidemenu_existing_member_list_div`}
                      key={"3"}
                      //className={"qdm"}
                      className={
                        addmemberlist !== key ? classes.htwc : classes.htwc1
                      }
                      // onClick={(e) => handleCheked(v, key)}
                    >
                      {!props?.memberIds?.includes(v?.Id) ? (
                        <TermsAndConditions
                          // value={true}
                          // key={"4"}
                          id="doctor_listing_iagreeinstructions_termsAndconditions"
                          value={addmemberlist === key ? true : false}
                          disabled={props?.memberIds?.includes(v?.Id)}
                          checkboxTickColor={"#fff"}
                          activeCheckboxBorderColor={
                            UIColor.secondaryButtonColor.color
                          }
                          checkboxBorderColor={UIColor.lineBorderFill.color}
                          checkboxBackgroundColor={
                            UIColor.differentiationBackground.color
                          }
                          activeCheckboxBackgroundColor={
                            UIColor.secondaryButtonColor.color
                          }
                          checkboxSize={14}
                          lable={
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Avatar
                                id={`${parent_id}_addmember_sidemenu_existing_member_list_avatar`}
                                key={"1"}
                                variant={"circle"}
                                letter={v?.name?.text || v?.name?.given}
                                src={getImgUrl(v?.photo?.[0]?.fileid || "")}
                                alt={"Image"}
                                inLineStyles={{
                                  padding: 0,
                                  height: 40,
                                  width: 40,
                                  margin: "0px 8px",
                                }}
                              ></Avatar>
                              <Div
                                key={"2"}
                                id={`${parent_id}_addmember_sidemenu_existing_member_list_rightside_content_parent_div`}
                              >
                                <Div
                                  key={"0"}
                                  id={`${parent_id}_addmember_sidemenu_existing_member_list_rightside_content_sublevel_div`}
                                >
                                  <H6
                                    className="pc_medium"
                                    id={`${parent_id}_addmember_sidemenu_existing_member_list_username_h6`}
                                    key={"0"}
                                    name={makeName(
                                      v?.name?.[0] || v?.name || ""
                                    )}
                                    style={{
                                      fontSize: "14px",
                                      lineHeight: 1,
                                      marginBottom: 4,
                                    }}
                                  ></H6>

                                  <H6
                                    className="pc_regular"
                                    key={"1"}
                                    id={`${parent_id}_addmember_sidemenu_existing_member_list_discription_text`}
                                    name={`${
                                      v?.patient?.[0]?.alias ||
                                      (props?.chooseSearch?.value ===
                                        "PID Number" &&
                                        state[props?.chooseSearch?.value]) ||
                                      "----"
                                    } ,${
                                      v?.telecom?.find(
                                        (tel) =>
                                          tel.system === "Phone" &&
                                          tel.use === "Mobile"
                                      )?.value
                                    }, ${convertTime(
                                      v?.birthDay,
                                      "DD/MM/YYYY",
                                      "years",
                                      "diff"
                                    )} Yrs, ${v?.gender}`}
                                    // name={'PID 24***74 , +91 98******14 , 31 Yrs, Male'}
                                    style={{
                                      color: "#B6B6B6",
                                      fontSize: "12px",
                                      letterSpacing: 0.1,
                                      lineHeight: 1,
                                    }}
                                  ></H6>
                                </Div>
                              </Div>
                            </div>
                          }
                          className={classes.termsandcondition}
                          onChange={(e) => handleCheked(e, v, key)}
                        />
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            id={`${parent_id}_addmember_sidemenu_existing_member_list_avatar`}
                            key={"1"}
                            variant={"circle"}
                            letter={v?.name?.text || v?.name?.given}
                            src={getImgUrl(v?.photo?.[0]?.fileid || "")}
                            alt={"Image"}
                            inLineStyles={{
                              padding: 0,
                              height: 40,
                              width: 40,
                              margin: "0px 8px",
                            }}
                          ></Avatar>
                          <Div
                            key={"2"}
                            id={`${parent_id}_addmember_sidemenu_existing_member_list_rightside_content_parent_div`}
                          >
                            <Div
                              key={"0"}
                              id={`${parent_id}_addmember_sidemenu_existing_member_list_rightside_content_sublevel_div`}
                            >
                              <H6
                                className="pc_medium"
                                id={`${parent_id}_addmember_sidemenu_existing_member_list_username_h6`}
                                key={"0"}
                                name={
                                  v?.name?.text ||
                                  v?.name?.[0]?.text ||
                                  v?.name?.given ||
                                  v?.name?.[0]?.given ||
                                  " "
                                }
                                style={{
                                  fontSize: "14px",
                                  lineHeight: 1,
                                  marginBottom: 4,
                                }}
                              ></H6>

                              <H6
                                className="pc_regular"
                                key={"1"}
                                id={`${parent_id}_addmember_sidemenu_existing_member_list_discription_text`}
                                name={`${
                                  v?.patient?.[0]?.alias ||
                                  (props?.chooseSearch?.value ===
                                    "PID Number" &&
                                    state[props?.chooseSearch?.value]) ||
                                  "----"
                                } ,${
                                  v?.telecom?.find(
                                    (tel) =>
                                      tel.system === "Phone" &&
                                      tel.use === "Mobile"
                                  )?.value
                                }, ${convertTime(
                                  v?.birthDay,
                                  "DD/MM/YYYY",
                                  "years",
                                  "diff"
                                )} Yrs, ${v?.gender}`}
                                // name={'PID 24***74 , +91 98******14 , 31 Yrs, Male'}
                                style={{
                                  color: UIColor.tertiaryText.color,
                                  fontSize: "12px",
                                  letterSpacing: 0.1,
                                  lineHeight: 1,
                                }}
                              ></H6>
                            </Div>
                          </Div>
                        </div>
                      )}
                      {props?.memberIds?.includes(v?.Id) && (
                        // <Button
                        //   id={`${parent_id}_addmember_sidemenu_existing_member_list_added_tag`}
                        //   key={"1"}
                        //   type={"button"}
                        //   variant={"outlined"}
                        //   name={
                        //     props?.memberIds?.includes(v?.Id)
                        //       ? "Added"
                        //       : ""
                        //   }
                        //   className={props?.memberIds?.includes(v?.Id) ? classes.RrEm1 : classes.RrEm}
                        // ></Button>
                        <div
                          style={{
                            width: "40px",
                            height: "15px",
                            letterSpacing: "0px",
                            color: "rgb(42, 96, 188)",
                            opacity: 1,
                            fontSize: "10px",
                            fontWeight: 500,
                          }}
                        >
                          <span
                            style={{
                              border: "1px solid #c9d8f0",
                              borderRadius: "5px",
                              padding: "2px 4px 4px 4px",
                              background: "#c9d8f0",
                            }}
                          >
                            Added
                          </span>
                        </div>
                      )}
                    </Div>
                  );
                })}

                {addmemberlist !== null && (
                  <Button
                    id={`${parent_id}_addmember_sidemenu_existing_member_list_sendotp_button`}
                    key={"1"}
                    type={"button"}
                    variant={"text"}
                    name={
                      props.signUp
                        ? props?.registerUsers?.loading
                          ? "Sending OTP..."
                          : "Send OTP & Sign Up"
                        : props?.generateOTP?.loading
                        ? "Sending OTP..."
                        : "Send OTP & Add Member"
                    }
                    style={{
                      padding: "10px 16px",
                      borderRadius: "8px",
                      fontSize: "14px",
                      borderColor: UIColor.primaryColor.color,
                      // color: UIColor.primaryColor.color,
                      color: "#fff",
                      backgroundColor: UIColor.primaryColor.color,
                      height: "40px",
                      lineHeight: 1,
                      letterSpacing: 0,
                    }}
                    className={classes.Hdva1}
                    onClick={async () => {
                      if (props.signUp) {
                        const firstName =
                          state.selectData?.name?.[0]?.text ||
                          state.selectData?.name?.text ||
                          state.selectData?.name?.[0]?.given ||
                          state.selectData?.name?.given ||
                          "";
                        const lastName =
                          state.selectData?.name?.[0]?.family ||
                          state.selectData?.name?.family ||
                          "";
                        const info = {
                          mobileNo: state.selectData?.telecom?.find(
                            (tel) =>
                              tel.system === "Phone" && tel.use === "Mobile"
                          )?.value,
                          firstName,
                          lastName,
                          emailId:
                            state.selectData?.telecom?.find(
                              (t) => t.system === "Email" && t.use === "Work"
                            )?.value || "",
                        };
                        const data = await props.REGISTER_USERS(info);
                        const status = checkError(data?.payload);
                        if (status.isError) {
                          if (status.errMsg === "User already exists.") {
                            status.errMsg =
                              "User already exists. Please Try Sign in.";
                          }
                          alert.setSnack({
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: status.errMsg,
                            vertical: AlertProps.vertical.top,
                            horizontal: AlertProps.horizontal.right,
                            tone: true,
                          });
                        } else {
                          const countryCode = ``;
                          const phoneNumber = info.mobileNo.split("+");
                          props.nextSection("addmemberotp", {
                            existingPerson: true,
                            mobileNo: {
                              mobileNo: phoneNumber[1] || phoneNumber[0],
                              countryData: {
                                callingCodes: countryCode,
                              },
                            },
                            selectData: state?.selectData,
                            id: state?.selectData.Id,
                            firstName: info.firstName,
                            lastName: info.lastName,
                            dob: state?.selectData?.birthDay,
                            gender: state?.selectData?.gender,
                            emailId: info.emailId,
                            middleName: "",
                            otpNumber: info.mobileNo,
                          });
                        }
                        return;
                      }
                      if (props?.memberIds?.includes(state.selectData.Id)) {
                        alert.setSnack({
                          open: true,
                          severity: AlertProps.severity.error,
                          msg: "Member Already Added To Your Profile",
                          vertical: AlertProps.vertical.top,
                          horizontal: AlertProps.horizontal.right,
                          tone: true,
                        });
                      } else {
                        addmemberlist !== null
                          ? senOtpMMr()
                          : props.nextSection("addmemberotp", "addMember");
                      }
                    }}
                  ></Button>
                )}
              </>
            )}
          </Collapse>
        </Card>

        {/* </Collapse> */}

        {/* <Collapse
            key={"3"}
            title={"New Member"}
            subtitle={""}
            body={"Collapse Body"}
            iconPosition={"left"}
            iconHeight={"20"}
            iconWidth={"20"}
            url={""}
            style={qdmstyles.JgF}
            openIcon={
              <svg
                width="26px"
                class="MuiSvgIcon-root"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
              </svg>
            }
            closeIcon={
              <svg
                width="26px"
                class="MuiSvgIcon-root jss107"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"></path>
              </svg>
            }
          > */}
        <Card
          style={{
            marginTop: "16px",
            boxShadow: "none",
            border: "1px solid #DEE5EC",
            borderRadius: 8,
          }}
          id={`${parent_id}_addmember_sidemenu_addnewmember_parent_card`}
        >
          <CardActions
            id={`${parent_id}_addmember_sidemenu_addnewmember_parent_cardactions`}
            onClick={() => handleExpandClick(2)}
            disableSpacing
            style={{
              padding: 0,
              paddingLeft: 16,
              paddingBottom: 0,
              cursor: "pointer",
            }}
          >
            <Text
              id={`${parent_id}_addmember_sidemenu_addnewmember_title`}
              key={"0"}
              name="New Member"
              style={{
                fontSize: "16px",
                letterSpacing: 0,
                lineHeight: 1,
                color: UIColor.primaryText.color,
              }}
              className={"pc_semibold"}
            />

            <IconButton
              id={`${parent_id}_addmember_sidemenu_addnewmember_showmoreicon_button`}
              className={clsx(classes.expand, {
                [classes.expandOpen]: state.expanded2,
              })}
              aria-expanded={state.expanded2}
              aria-label="show more"
            >
              <ExpandMoreIcon
                id={`${parent_id}_addmember_sidemenu_addnewmember_showmoreicon_icon`}
              />
            </IconButton>
          </CardActions>
          <Collapse
            id={`${parent_id}_addmember_sidemenu_addnewmember_parent_collapse`}
            className={classes.body}
            in={state.expanded2}
            timeout="auto"
            unmountOnExit
          >
            <H6
              id={`${parent_id}_addmember_sidemenu_addnewmember_discription`}
              key={"0"}
              name={
                "If the member is not registered with the hospital before. Please proceed with Add New Member."
              }
              style={{
                color: UIColor.secondaryText.color,
                display: "flex",
                fontSize: "12px",
                letterSpacing: 0,
                lineHeight: 1.6,
              }}
              className={"pc_regular"}
            ></H6>

            <Button
              id={`${parent_id}_addmember_sidemenu_addnewmember_submit_button`}
              key={"1"}
              type={"button"}
              variant={"text"}
              name={props.name}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
                borderColor: UIColor.primaryColor.color,
                borderStyle: "solid",
                borderRadius: "8px",
                color: "#fff",
                fontSize: "14px",
                marginTop: "16px",
                padding: "8px 16px",
                marginBottom: "16px",
                height: "40px",
                background: UIColor.primaryColor.color,
              }}
              onClick={() => props.nextSection("addmemberform")}
              className={"pc_medium"}
            ></Button>
          </Collapse>
        </Card>
        {/* </Collapse> */}
      </Container>
    </div>
  );
}

export default AddMember;

// export default connect(
//   mapStateToProps,
//   actions
// )(withRouter(AddMember));
