import React, { useState, useEffect } from "react";
import withAppBar from "../../hoc/withAppBar";
import { Div, Row, Col, H6, Paper } from "qdm-component-library";
import { TopNavbar, TopHeader } from "../../components";
import withAllContexts from "../../hoc/withAllContexts";
import { Typography } from "@material-ui/core";
import TreatmentPlan from "../patientDetail/treatmentPlan/treatmentPlan";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
import axios from "axios";
import { AlertProps, actionButtonClick } from "../../utils";
import jwtDecode from "jwt-decode";

const TreatmentPlans = (props) => {
  const [btnName, setBtnName] = useState(false);
  const location = useLocation();
  const userInfo = localStorage.getItem("UserInfo");
  const decodedUserinfo = jwtDecode(userInfo);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [actionName, setActionName] = useState("");
  const [cancelbookingreason, setCancelbookingreason] = useState({});
  const navigate = useNavigate();

  //   const userInfo = localStorage.getItem("UserInfo");
  //   const decodedUserinfo = jwtDecode(userInfo);
  //   // const roleName = decodedUserinfo.rolename;
  //   const facilityid = decodedUserinfo.facilityid;
  //   const tenantid = decodedUserinfo.tenantid;
  //   const practitionerid = decodedUserinfo.practitionerid;

  //   const dispatch = useDispatch();
  //   const navigate = useNavigate();
  //   const location = useLocation();
  //   console.log(location, "locations referal");

  //   const [allMasters, setAllMasters] = useState([]);
  //   const [btnName, setBtnName] = useState(false);
  //   const [stateClear, setStateClear] = useState(false);
  //   const [editData, setEditData] = useState(false);

  //   const [referralRegistrationdatas, setReferralRegistratiodatas] = useState({});
  //   const getAllMasters = async () => {
  //     const response = await dispatch(actions.GET_ALL_MASTERS(""));
  //     setAllMasters(response?.payload?.data);
  //   };

  //   function formatDate(inputDate) {
  //     const parts = inputDate?.split("-");
  //     if (parts?.length === 3) {
  //       const year = parts[0];
  //       const month = parts[1];
  //       const day = parts[2];
  //       const formattedDate = `${day}/${month}/${year}`;
  //       return formattedDate;
  //     } else {
  //       // Handle invalid input date format
  //       return "Invalid Date Format";
  //     }
  //   }
  //   let Date_of_Referral_In = formatDate(
  //     referralRegistrationdatas?.Date_of_Referral_In
  //   );
  //   const DateofReferralInEpochTime = dateToEpoch(Date_of_Referral_In);

  //   function dateToEpoch(dateString) {
  //     const [day, month, year] = dateString.split("/").map(Number);

  //     if (isNaN(day) || isNaN(month) || isNaN(year)) {
  //       return null;
  //     }

  //     const date = new Date(year, month - 1, day);
  //     const epochTime = date.getTime() / 1000;

  //     return epochTime;
  //   }
  //   const saveReferralData = {
  //     _id: "",
  //     HealthCareServiceRequested:
  //       referralRegistrationdatas?.Referring_from_Facility?._id,
  //     RefSourceLetDate: DateofReferralInEpochTime,
  //     RequestorPractioner: referralRegistrationdatas?.Referring_Doctor?._id,
  //     patientId: location?.state?.patientId,
  //     recipientBookingReqd: true,
  //     serviceRequested: referralRegistrationdatas?.Service_Requested?._id,
  //     registrationtype: referralRegistrationdatas?.registration_type?._id,
  //     predialcentre: referralRegistrationdatas?.Previous_Dialysis_Centre,
  //     hepatitis: [referralRegistrationdatas?.Hepatitis?._id],
  //     otherhealthcond: [referralRegistrationdatas?.Other_Health_Condition?._id],
  //     attachment: [
  //       {
  //         fileName: "doc.jfif",
  //         fileid: "",
  //         filetype: "jfif",
  //         base64string: "",
  //       },
  //     ],
  //     // refstatus: "CodingMaster/11452",
  //     tenantid: tenantid,
  //     facilityid: facilityid,
  //     reason: " ",
  //     remarks: "",
  //     practitionerroleid: practitionerid,
  //     queueid: "",
  //     scenarioid: "",
  //     healthdisease: referralRegistrationdatas?.disease,
  //     // participants: [
  //     //   {
  //     //     resoucetype: "",
  //     //     ressubtype: "",
  //     //     resouceid: "",
  //     //   },
  //     // ],
  //   };
  const successfun2 = async () => {
    // if (!patientData?.length > 0) {
    //   props?.alert?.setSnack({
    //     open: true,
    //     severity: AlertProps.severity.error,
    //     msg: "Please save the details",
    //     vertical: AlertProps.vertical.top,
    //     horizontal: AlertProps.horizontal.center,
    //     tone: true,
    //   });
    // } else {
    setBtnName(true);
    let payload = {
      ticketId: location?.state?.totalData?.ticketId ?? "",

      userAction: process.env.REACT_APP_REFERRAL_APPROVE,
      data: {
        ticketId: location?.state?.totalData?.ticketId ?? "",
        practitionerId: decodedUserinfo?.practitionerid,
        practitionerRoleId: decodedUserinfo?.roleid,
      },
    };
    let token = localStorage.getItem("JwtToken");
    let register = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        jwtToken: `${token}`,
      },
      url: process.env.REACT_APP_PERFORMUSERACTION_API,
      data: payload,
    })
      .then((res) => {
        if (res.status === 200 && !res.data.error) {
          props?.alert?.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: "Approved successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          setBtnName(false);
          navigate(AppRoutes.dashboard);
        } else {
          props?.alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: res.data.message,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          setBtnName(false);
        }
      })
      .catch((error) => {
        props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please contact to admin",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
        setBtnName(false);
      });
    // }
  };
  //   useEffect(() => {
  //     getAllMasters();
  //   }, []);

  //   const goBackFn = () => {
  //     navigate(AppRoutes.financeRegistration, {
  //       state: {
  //         loading: false,
  //         patientId: location.state.patientId ?? props?.patientId,
  //       },
  //     });
  //   };
  //   let AVCMeeting = {
  //     RoomName: "",
  //     component: {
  //       key: null,
  //       props: { children: "Hai" },
  //       ref: null,
  //       _owner: null,
  //       _store: { validated: false },
  //     },
  //     counter: undefined,
  //     direction: "right",
  //     domain: "",
  //     dragging: false,
  //     isLarge: null,
  //     jwt: "",
  //     onClose: () => {},
  //     open: false,
  //     pos: { top: 250, left: 150 },
  //     rel: null,
  //     setMeetingDrawer: async (props) => {},
  //     user_name: "",
  //     variant: "temporary",
  //     widthAdjState: undefined,
  //   };
  //   const clearState = () => {
  //     setReferralRegistratiodatas({
  //       Referring_from_Facility: {},
  //       Service_Requested: {},
  //       Date_of_Referral_In: "",
  //       Referring_Doctor: {},
  //       Hepatitis: {},
  //       registration_type: {},
  //       Previous_Dialysis_Centre: "",
  //       Other_Health_Condition: {},
  //       disease: "",
  //     });
  //     setStateClear(true);
  //   };
  //   function epochToDate(epoch) {
  //     const date = new Date(epoch * 1000);
  //       const year = date.getFullYear();
  //     const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  //     const day = String(date.getDate()).padStart(2, '0');
  //       const formattedDate = `${year}-${month}-${day}`;
  //     return formattedDate;
  //   }

  //   const setReferralRegistrationData = () => {
  //     console.log(
  //         location?.state?.referraldetails?.RefSourceLetDate, "edit data loc"
  //     );
  //     setReferralRegistratiodatas({
  //       Referring_from_Facility: {
  //         value:
  //           location?.state?.referraldetails?.HealthCareServiceRequested?.name ??
  //           "",
  //         _id:
  //           location?.state?.referraldetails?.HealthCareServiceRequested?._id ??
  //           "",
  //       },
  //       Service_Requested:{
  //         value:
  //           location?.state?.referraldetails?.serviceRequested?.longdesc ??
  //           "",
  //         _id:
  //           location?.state?.referraldetails?.serviceRequested?._id ??
  //           "",
  //       },
  //       Date_of_Referral_In:epochToDate(location?.state?.referraldetails?.RefSourceLetDate),
  //       Referring_Doctor: {
  //         _id: location?.state?.referraldetails?.RequestorPractioner?._id ?? "",
  //         value: location?.state?.referraldetails?.RequestorPractioner?.name ?? "",
  //       },
  //       Hepatitis: {
  //         _id: location?.state?.referraldetails?.hepatitis?.[0]?._id ?? "",
  //         value:
  //           location?.state?.referraldetails?.hepatitis?.[0]?.display ?? "",
  //       },
  //       registration_type: {
  //         _id: location?.state?.referraldetails?.registrationtype?._id ?? "",
  //         value:
  //           location?.state?.referraldetails?.registrationtype?.display ?? "",
  //       },
  //       Previous_Dialysis_Centre:location?.state?.referraldetails?.predialcentre ?? "",

  //       Other_Health_Condition: {
  //         _id: location?.state?.referraldetails?.otherhealthcond?.[0]?._id ?? "",
  //         value:
  //           location?.state?.referraldetails?.otherhealthcond?.[0]?.display ?? "",
  //       },
  //       disease: location?.state?.referraldetails?.healthdisease ?? "",
  //     });
  //     setEditData(true);
  //   };

  //   const isModify = location?.state?.isModify ?? false;

  //   useEffect(() => {
  //     if (isModify) {
  //       setReferralRegistrationData();
  //     }
  //   }, [isModify]);

  //   console.log(referralRegistrationdatas, "parent");
  let AVCMeeting = {
    RoomName: "",
    component: {
      key: null,
      props: { children: "Hai" },
      ref: null,
      _owner: null,
      _store: { validated: false },
    },
    counter: undefined,
    direction: "right",
    domain: "",
    dragging: false,
    isLarge: null,
    jwt: "",
    onClose: () => {},
    open: false,
    pos: { top: 250, left: 150 },
    rel: null,
    setMeetingDrawer: async (props) => {},
    user_name: "",
    variant: "temporary",
    widthAdjState: undefined,
  };
  const successfun = () => {
    navigate(AppRoutes.dashboard, {});
  };
  const goBackFn = () => {
    navigate(AppRoutes.clinicalInfo, {
      state: {
        loading: false,
        // patientId: location.state.patientId ?? props?.patientId,
        IsViewMode: location?.state?.IsViewMode,
        data: location?.state?.data ?? "",
        // patientId: location?.state?.data?.patientId??location?.state?.data?._id ??"",
        // encounterID: location?.state?.data?.encounterinfo?._id??"",
        // personid: location?.state?.data?.patientdetails?.personid??"",
        totalData: location?.state?.totalData,
        IsViewMode: location?.state?.IsViewMode,
        data: location?.state?.data ?? "",
        referraldetails:
          location?.state?.data?.referraldetails ??
          location?.state?.referraldetails ??
          "",
        patientId:
          location?.state?.data?.patientId ||
          location?.state?.data?._id ||
          props?.patientId ||
          location?.state?.patientId,
        encounterID:
          location?.state?.data?.encounterinfo?._id ??
          location?.state?.encounterID ??
          "",
        personid:
          location?.state?.data?.patientdetails?.personid ??
          location?.state?.personId ??
          location?.state?.personid ??
          "",
        totalData: location?.state?.totalData,
        addtionaldetails: location?.state?.addtionaldetails,
        nextofkin: location?.state?.nextofkin,
        patientdetails: location?.state?.patientdetails,
        isModify: location?.state?.isModify ?? false,
        userId:
          location?.state?.data?.patientdetails?.personid ??
          location?.state?.personId ??
          location?.state?.personid ??
          "",
        referralId: location?.state?.referralId,
      },
    });
  };
  const handleSideMenu = () => {
    setIsSideMenuOpen(true);
  };
  const handleSideMenuclose = () => {
    setIsSideMenuOpen(false);
  };

  const handleSelectCancelbooking = (v) => {
    setCancelbookingreason(v);
  };

  const actionsMap = location?.state?.totalData?.action.reduce(
    (map, actionItem) => {
      map[actionItem.action] = {
        actionName: actionItem.action,
        action: actionItem.action,
        apiEndPoint: actionItem.apiendpoint,
        backendCall: actionItem.backEndCall,
      };
      return map;
    },
    {}
  );
  const cancelData = () => {
    if (actionName) {
      let data = {};
      const { action, apiEndPoint, backendCall } = actionsMap[actionName];
      data.action = action;
      data.apiEndPoint = apiEndPoint;
      data.backendCall = backendCall;
      data.ticketId = location?.state?.totalData?.ticketId;
      data.cancelReasonId = cancelbookingreason?._id;
      data.queue =
        location?.state?.totalData?.queueMetaData?.queuename?.queuename ??
        "Referral";

      return data;
    }
  };

  const handlecahngeCancelbooking = async () => {
    const { action, apiEndPoint, backendCall } = actionsMap[actionName];
    let fetchVal = await actionButtonClick(
      action,
      apiEndPoint,
      backendCall,
      location?.state?.totalData?.ticketId,
      cancelbookingreason?._id,
      location?.state?.totalData?.queueMetaData?.queuename?.queuename ??
        "Referral"
    );
    if (!fetchVal.error) {
      setIsSideMenuOpen(false);
      navigate(AppRoutes.dashboard);
    } else if (fetchVal.error) {
      setIsSideMenuOpen(true);
      props?.alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: fetchVal?.message,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
  };
  return (
    <Div id="referral_parent_parent_div" className="light-background-color">
      <TopNavbar
        goBack={goBackFn}
        parent_id={"TreatmentPlan"}
        // alertHold={alertHold}
        // putOnHold={() =>
        //   putOnHold("undefined", props.location?.state?.appointmentId)
        // }
        // newUser={props.location?.state?.newUser}
        successfun={successfun}
        // navBarArr={[]}
        title={"Treatment Plan"}
        btn={
          location?.state?.IsViewMode
            ? "Approve"
            : btnName
            ? "Registering"
            : "Finish"
        }
        isAVC={AVCMeeting?.RoomName}
        isViewMode={location?.state?.IsViewMode}
        approveStatus={successfun2}
        isNextButton={false}
        isSideMenuOpen={isSideMenuOpen}
        handleSideMenu={handleSideMenu}
        handleSideMenuclose={handleSideMenuclose}
        setActionName={setActionName}
        actionName={actionName}
        handlecahngeCancelbooking={handlecahngeCancelbooking}
        handleSelectCancelbooking={handleSelectCancelbooking}
        cancelbookingreason={cancelbookingreason}
        totalData={location?.state?.totalData}
        cancelData={cancelData()}
        isFileUpload={true}
      />
      <div
        style={{
          height: "calc(100vh - 128px)",
          overflow: "auto",
          //   display: state.loading ? "flex" : "block",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Div id="referral_parent_sub_div" className="rv-list-view">
          <TreatmentPlan location={location} history={false} />
        </Div>
      </div>
    </Div>
  );
};

export default withAppBar(withAllContexts(TreatmentPlans));
