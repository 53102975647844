import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  defaultReject,
  defaultState,
  fetchData,
  urls,
  dbName,
  query_ids,
  queries,
  fdbAliasCodeId
} from "../../../utils";

const DURATIONTYPE_MASTER = createAsyncThunk(
  "MedicationMastersSlice/DurationType_masters",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        queryid: query_ids["durationType"],
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      //debugger
      // const data = await fetchData(
      //   {
      //     body: queries.DurationType,
      //   },
      //   __readDocumentUrl__
      // );
      let arr = [];
      data.map((val) => {
        arr.push({
          value: val._id,
          label: val.shortdesc ?? "",
          type: val.shortdesc ?? "",
          id: val.id,
        });
      });
      return {
        ...defaultState.List,
        data: arr,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const PRIORITY_MASTER = createAsyncThunk(
  "MedicationMastersSlice/Priority_masters",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { tenantid = "", facilityid = "", lang = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["GetAllMasters"],
        filter: {
          type: ["LABPRIORITY"],
          tenantid: tenantid,
          facilityid: facilityid,
          lang: lang,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let returnData = [];
      if (Array.isArray(data)) {
        data?.[0]?.LABPRIORITY?.Value?.map((val, i) => {
          returnData.push({
            value: val?._id,
            label: val?.display,
          });
        });
      }
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const ORDERMODE_MASTER = createAsyncThunk(
  "MedicationMastersSlice/OrderMode_masters",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { tenantid = "", facilityid = "", lang = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["GetAllMasters"],
        filter: {
          type: ["ORDERMODE"],
          tenantid: tenantid,
          facilityid: facilityid,
          lang: lang,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let returnData = [];
      if (Array.isArray(data)) {
        data?.[0]?.ORDERMODE?.Value?.map((val, i) => {
          returnData.push({
            value: val?._id,
            label: val?.display,
          });
        });
      }
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const DRUGMASTER_MASTER = createAsyncThunk(
  "MedicationMastersSlice/drugMaster_masters",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { input_text = "" } = payload;

      const body = {
        db_name: dbName,
        queryid: query_ids["durgMaster"],
        filter: {
          searchcode: `%${input_text}%`,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      // const data = await fetchData(
      //   {
      //     body: queries.drugMaster(input_text),
      //   },
      //   __readDocumentUrl__
      // );
      let arr = [];
      data.map((val) => {
        let json = {
          result: val,
          value: val?._id,
          id: val?.id,
          FormCode: val?.FormCode?._id,
          FormData: val?.FormCode?.longdesc,
          StrengthValues: val?.StrengthValuesPossible?.[0]?.StrengthValue,
          StrengthUOM: val?.StrengthValuesPossible?.[0]?.StrengthUOM,
          drugType: val?.DrugType?.drugtype,
          drugTypeLong: val?.DrugType?.longdesc,
          drugTypeShort: val?.DrugType?.shortdesc,
          drugTypeId: val?.DrugType?._id,
          drugroutegenid: val?.drugroutegenid ?? "",
          LongDesc_: val?.LongDesc ?? "",
          DrugCode_: val?.DrugCode,
          DrugCategory: val?.DrugCategory ?? "",
          DefaultRouteCode: val?.DefaultRouteCode ?? "",
          //new values
          doseVal: val?.FrequencyRules?.[0]?.doseval ?? "",
          durationcode: val?.FrequencyRules?.[0]?.durationcode ?? "",
          doseUOM: {
            id: val?.FrequencyRules?.[0]?.doseUOM?._key,
            label:
              val?.FrequencyRules?.[0]?.doseUOM?.UOM &&
              val?.FrequencyRules?.[0]?.doseUOM?.shortdesc
                ? val?.FrequencyRules?.[0]?.doseUOM?.UOM +
                  " - " +
                  val?.FrequencyRules?.[0]?.doseUOM?.shortdesc
                : "",
            value: val?.FrequencyRules?.[0]?.doseUOM?._id,
          },
          freqCode: {
            id: val?.FrequencyRules?.[0]?.freqcode?._key,
            label:
              val?.FrequencyRules?.[0]?.freqcode?.freqcode &&
              val?.FrequencyRules?.[0]?.freqcode?.shortdesc
                ? val?.FrequencyRules?.[0]?.freqcode?.freqcode +
                  " - " +
                  val?.FrequencyRules?.[0]?.freqcode?.shortdesc
                : "",
            value: val?.FrequencyRules?.[0]?.freqcode?._id,
            freqCode: val?.FrequencyRules?.[0]?.freqcode?.freqcode,
            FreqDetls:
              Array.isArray(val?.FrequencyRules?.[0]?.freqcode?.scheduleline) &&
              val?.FrequencyRules?.[0]?.freqcode?.scheduleline.length > 0
                ? constructFreqDetls(
                    val?.FrequencyRules?.[0]?.freqcode?.scheduleline ?? []
                  )
                : [],
          },
          durationCode: {
            id: val?.FrequencyRules?.[0]?.durnUOM?._key,
            label: val?.FrequencyRules?.[0]?.durnUOM?.shortdesc ?? "",
            value: val?.FrequencyRules?.[0]?.durnUOM?._id,
          },
          fdbAliasCode: val?.aliasDtls?.filter(x=> x.aliasType?._id === fdbAliasCodeId)?.[0]?.aliasCode
          // formValue
        };
        if (val?.ShortDesc === val?.LongDesc) {
          arr.push({
            ...json,
            name: val?.ShortDesc,
            label: val?.DrugCode + " - " + val?.ShortDesc,
            nameLabel: val?.ShortDesc,
            // label: val?.ShortDesc
            // label: `${val.ShortDesc ?? ""} - ${json.StrengthValues} ${json.StrengthUOM}`,
          });
        } else {
          arr.push({
            ...json,
            name: val?.ShortDesc,
            label:
              val?.DrugCode + " - " + val?.ShortDesc + " - " + val?.LongDesc,
            nameLabel: val?.ShortDesc,
            // label: val?.ShortDesc
            // label: `${val.ShortDesc ?? ""} - ${json.StrengthValues} ${json.StrengthUOM}`,
          });
        }
      });
      return {
        ...defaultState.List,
        data: arr,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const constructFreqDetls = (data) => {
  let arr = [];
  if (Array.isArray(data) && data?.length > 0) {
    data.map((v) => {
      arr.push({
        remarks: v?.remarks || "",
        dose: v?.dose || "",
        time: v?.time || "",
        baseFreq:
          v?.basefreq?.val ?? v?.basefreq?.freqcode ?? v?.baseFreq ?? "",
        weekDay: v?.weekDay || v?.weekday || "",
        day: v?.day?.code
          ? Number(v?.day?.code)
            ? JSON.parse(v?.day?.code)
            : v?.day?.code
          : 0,
        fromDay: v?.fromDay
          ? Number(v?.fromDay)
            ? JSON.parse(v?.fromDay)
            : v?.fromDay
          : 0,
        toDay: v?.toDay
          ? Number(v?.toDay)
            ? JSON.parse(v?.toDay)
            : v?.toDay
          : 0,
        fromDate: v?.fromDate
          ? Number(v?.fromDate)
            ? JSON.parse(v?.fromDate)
            : v?.fromDate
          : 0,
        toDate: v?.toDate
          ? Number(v?.toDate)
            ? JSON.parse(v?.toDate)
            : v?.toDate
          : 0,
        adminDosageInstruction: v?.adminDosageInstruction ?? "",
      });
    });
  }
  return arr;
};
const STRENGTHUOM_MASTER = createAsyncThunk(
  "MedicationMastersSlice/strengthUom_masters",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        queryid: query_ids["strengthUom"],
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let arr = [];
      data.map((val) => {
        arr.push({
          value: val?._id,
          label:
            val?.UOM && val?.shortdesc ? val?.UOM + " - " + val?.shortdesc : "",
          id: val?._id,
        });
      });
      return {
        ...defaultState.List,
        data: arr,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const ORGANIZATION_MASTER = createAsyncThunk(
  "MedicationMastersSlice/Organization_masters",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        queryid: query_ids["organization"],
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let arr = [];
      data.map((val) => {
        arr.push({
          value: val?._id,
          label: val?.name ?? "",
          id: val?.OrgID,
        });
      });
      return {
        ...defaultState.List,
        data: arr,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const DIAGNOSISAGAINSTENCOUNTER_MASTER = createAsyncThunk(
  "MedicationMastersSlice/DiagnosisAgainstEncounter_masters",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const {
        encounterid = "",
        tenantid = "",
        facilityid = "",
        input_text = "",
      } = payload;
      const body = {
        db_name: dbName,
        // queryid: query_ids["DiagnosisAgainstEncounter"],
        // filter: {
        //   tenantid: tenantid,
        //   facilityid: facilityid,
        //   encounterid: encounterid,
        // },
        filter: {
          searchcode: `%${input_text}%`,
        },
        queryid: "865e0d7d-a5be-4da9-bcb6-75ff333d32c1",
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let arr = [];
      console.log("selectDiagnosis", data);
      data.forEach((val) => {
        arr.push({
          // value: val?._id,
          // label: val?.diagcode?.icdshortname ?? "",
          id: val?.id,
          value: val?._id,
          label: val?.icdname,
          diagnosis: val?.icdname,
        });
      });
      return {
        ...defaultState.List,
        data: arr,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const PRACTITIONERROLE_MASTER = createAsyncThunk(
  "MedicationMastersSlice/PractitionerRole_masters",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { orgid = "", tenantid = "", facilityid = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["PractitionerRole"],
        filter: {
          tenantid: tenantid,
          facilityid: facilityid,
          orgid: orgid,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let arr = [];
      data.map((val) => {
        arr.push({
          value: val?.role?._id,
          label: val?.role?.display ?? "",
          id: val?.role?._id,
        });
      });
      return {
        ...defaultState.List,
        data: arr,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const PRACTITIONER_MASTER = createAsyncThunk(
  "MedicationMastersSlice/Practitioner_masters",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        {
          body: JSON.stringify(queries.Practitioner(payload.orgId)),
        },
        urls.readDocuments
      );
      let arr = [];
      data?.result?.map((val) => {
        arr.push({
          value: val?._id,
          label: val.PractitionerName?.[0]?.text ?? "",
        });
      });
      return {
        ...defaultState.List,
        data: arr,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const PATIENT_INSTRUCTIONS_MASTER = createAsyncThunk(
  "MedicationMastersSlice/Patient_Instruction",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { tenantid = "", facilityid = "", lang = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["GetAllMasters"],
        filter: {
          type: ["PATINSTRUCTION"],
          tenantid: tenantid,
          facilityid: facilityid,
          lang: lang,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let returnData = [];
      if (Array.isArray(data)) {
        data?.[0]?.PATINSTRUCTION?.Value?.map((val, i) => {
          returnData.push({
            value: val?._id,
            label: val?.display,
          });
        });
      }
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const FREQUENCYMASTER_MASTER = createAsyncThunk(
  "MedicationMastersSlice/FrequencyMaster_masters",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        queryid: "be128fe0-e256-477d-b322-e15454d9aadc",
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let arr = [];
      data.map((val) => {
        arr.push({
          value: val?._id,
          label:
            val?.freqcode && val?.shortdesc
              ? val?.freqcode + " - " + val?.shortdesc
              : "",
          id: val?.id,
          freqCode: val?.freqcode,
          FreqDetls:
            Array.isArray(val?.scheduleline) && val?.scheduleline.length > 0
              ? constructFreqDetls(val?.scheduleline ?? [])
              : [],
        });
      });
      return {
        ...defaultState.List,
        data: arr,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const MedicationMastersSlice = createSlice({
  name: "MedicationMastersSlice",
  initialState: {
    DurationType_masters: { ...defaultState.List },
    Priority_masters: { ...defaultState.List },
    OrderMode_masters: { ...defaultState.List },
    drugMaster_masters: { ...defaultState.List },
    strengthUom_masters: { ...defaultState.List },
    Organization_masters: { ...defaultState.List },
    DiagnosisAgainstEncounter_masters: { ...defaultState.List },
    PractitionerRole_masters: { ...defaultState.List },
    Practitioner_masters: { ...defaultState.List },
    Patient_Instruction: { ...defaultState.List },
    FrequencyMaster_masters: { ...defaultState.List },
  },
  extraReducers: {
    /* DURATIONTYPE_MASTER */
    [DURATIONTYPE_MASTER.fulfilled]: (state, action) => {
      state.DurationType_masters = action?.payload ?? [];
    },
    [DURATIONTYPE_MASTER.pending]: (state, action) => {
      state.DurationType_masters.loading = true;
    },
    [DURATIONTYPE_MASTER.rejected]: (state, action) => {
      state.DurationType_masters = action?.payload ?? [];
    },

    /* PRIORITY_MASTER */
    [PRIORITY_MASTER.fulfilled]: (state, action) => {
      state.Priority_masters = action?.payload ?? [];
    },
    [PRIORITY_MASTER.pending]: (state, action) => {
      state.Priority_masters.loading = true;
    },
    [PRIORITY_MASTER.rejected]: (state, action) => {
      state.Priority_masters = action?.payload ?? [];
    },

    /* ORDERMODE_MASTER */
    [ORDERMODE_MASTER.fulfilled]: (state, action) => {
      state.OrderMode_masters = action?.payload ?? [];
    },
    [ORDERMODE_MASTER.pending]: (state, action) => {
      state.OrderMode_masters.loading = true;
    },
    [ORDERMODE_MASTER.rejected]: (state, action) => {
      state.OrderMode_masters = action?.payload ?? [];
    },

    /* DRUGMASTER_MASTER */
    [DRUGMASTER_MASTER.fulfilled]: (state, action) => {
      state.drugMaster_masters = action?.payload ?? [];
    },
    [DRUGMASTER_MASTER.pending]: (state, action) => {
      state.drugMaster_masters.loading = true;
    },
    [DRUGMASTER_MASTER.rejected]: (state, action) => {
      state.drugMaster_masters = action?.payload ?? [];
    },

    /* STRENGTHUOM_MASTER */
    [STRENGTHUOM_MASTER.fulfilled]: (state, action) => {
      state.strengthUom_masters = action?.payload ?? [];
    },
    [STRENGTHUOM_MASTER.pending]: (state, action) => {
      state.strengthUom_masters.loading = true;
    },
    [STRENGTHUOM_MASTER.rejected]: (state, action) => {
      state.strengthUom_masters = action?.payload ?? [];
    },

    /* ORGANIZATION_MASTER */
    [ORGANIZATION_MASTER.fulfilled]: (state, action) => {
      state.Organization_masters = action?.payload ?? [];
    },
    [ORGANIZATION_MASTER.pending]: (state, action) => {
      state.Organization_masters.loading = true;
    },
    [ORGANIZATION_MASTER.rejected]: (state, action) => {
      state.Organization_masters = action?.payload ?? [];
    },
    /* DIAGNOSISAGAINSTENCOUNTER_MASTER */
    [DIAGNOSISAGAINSTENCOUNTER_MASTER.fulfilled]: (state, action) => {
      state.DiagnosisAgainstEncounter_masters = action?.payload ?? [];
    },
    [DIAGNOSISAGAINSTENCOUNTER_MASTER.pending]: (state, action) => {
      state.DiagnosisAgainstEncounter_masters.loading = true;
    },
    [DIAGNOSISAGAINSTENCOUNTER_MASTER.rejected]: (state, action) => {
      state.DiagnosisAgainstEncounter_masters = action?.payload ?? [];
    },

    /* PRACTITIONERROLE_MASTER */
    [PRACTITIONERROLE_MASTER.fulfilled]: (state, action) => {
      state.PractitionerRole_masters = action?.payload ?? [];
    },
    [PRACTITIONERROLE_MASTER.pending]: (state, action) => {
      state.PractitionerRole_masters.loading = true;
    },
    [PRACTITIONERROLE_MASTER.rejected]: (state, action) => {
      state.PractitionerRole_masters = action?.payload ?? [];
    },

    /* PRACTITIONER_MASTER */
    [PRACTITIONER_MASTER.fulfilled]: (state, action) => {
      state.Practitioner_masters = action?.payload ?? [];
    },
    [PRACTITIONER_MASTER.pending]: (state, action) => {
      state.Practitioner_masters.loading = true;
    },
    [PRACTITIONER_MASTER.rejected]: (state, action) => {
      state.Practitioner_masters = action?.payload ?? [];
    },
    /* PATIENT_INSTRUCTIONS_MASTER */
    [PATIENT_INSTRUCTIONS_MASTER.fulfilled]: (state, action) => {
      state.Patient_Instruction = action?.payload ?? [];
    },
    [PATIENT_INSTRUCTIONS_MASTER.pending]: (state, action) => {
      state.Patient_Instruction.loading = true;
    },
    [PATIENT_INSTRUCTIONS_MASTER.rejected]: (state, action) => {
      state.Patient_Instruction = action?.payload ?? [];
    },

    /* FREQUENCYMASTER_MASTER */
    [FREQUENCYMASTER_MASTER.fulfilled]: (state, action) => {
      state.FrequencyMaster_masters = action?.payload ?? [];
    },
    [FREQUENCYMASTER_MASTER.pending]: (state, action) => {
      state.FrequencyMaster_masters.loading = true;
    },
    [FREQUENCYMASTER_MASTER.rejected]: (state, action) => {
      state.FrequencyMaster_masters = action?.payload ?? [];
    },
  },
});

export const MedicationMastersActions = {
  DURATIONTYPE_MASTER,
  PRIORITY_MASTER,
  ORDERMODE_MASTER,
  DRUGMASTER_MASTER,
  STRENGTHUOM_MASTER,
  ORGANIZATION_MASTER,
  DIAGNOSISAGAINSTENCOUNTER_MASTER,
  PRACTITIONERROLE_MASTER,
  PRACTITIONER_MASTER,
  FREQUENCYMASTER_MASTER,
  PATIENT_INSTRUCTIONS_MASTER,
};

export default MedicationMastersSlice;
