import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { dbName, fetchData, getUtcTime, urls } from "../../utils";

const __readDocumentUrl__ = urls?.readDocuments;
const __uspsertUrl__ = urls?.upsertDocument;

const defaultState = {
  List: {
    loading: false,
    error: false,
    data: [],
    message: "",
  },
  Info: {
    loading: false,
    error: false,
    data: {},
    message: "",
  },
};

const defaultReject = {
  loading: false,
  error: true,
  message: "",
  data: {},
};

//ASSIGN_PRACTITIONER_TYPE
const ASSIGN_PRACTITIONER_TYPE = createAsyncThunk(
  `dashboardApiSlice/assignPractitionerType`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        {
          body: JSON.stringify({
            db_name: dbName,
            entity: "CodeableConceptMaster",
            filter: "CodeableConceptMaster.Type=='PRACTTYPE'",
            return_fields:
              "MERGE(CodeableConceptMaster,{coding:(FOR cod IN CodeableConceptMaster.coding RETURN DOCUMENT(cod))})",
          }),
        },
        __readDocumentUrl__
      );
      let arry = [];
      data.result.map((val) => {
        arry.push({
          value: val?._id ?? "",
          label: val?.coding?.[0]?.display ?? "",
        });
      });
      return {
        ...defaultState.Info,
        data: arry,
      };
    } catch (error) {
      alert("error");
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
//ASSIGN_PRACTITIONER_ROLE
const ASSIGN_PRACTITIONER_ROLE = createAsyncThunk(
  `dashboardApiSlice/assignPractitionerRole`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        {
          body: JSON.stringify({
            db_name: dbName,
            entity: "CodeableConceptMaster",
            filter:
              "CodeableConceptMaster.Type=='PRACTROLE' && CodeableConceptMaster.status==true",
            return_fields:
              "MERGE(CodeableConceptMaster,{coding:(FOR cod IN CodeableConceptMaster.coding RETURN DOCUMENT(cod))})",
          }),
        },
        __readDocumentUrl__
      );
      let arry = [];
      data.result.map((val) => {
        arry.push({
          value: val?._id ?? "",
          label: val?.coding?.[0]?.display ?? "",
        });
      });
      return {
        ...defaultState.Info,
        data: arry,
      };
    } catch (error) {
      alert("error");
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
//DISPOSITION_TYPE
const DISPOSITION_TYPE = createAsyncThunk(
  `dashboardApiSlice/dispositionType`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        {
          body: JSON.stringify({
            db_name: dbName,
            entity: "CodingMaster",
            filter:
              "CodingMaster.Type=='DISPOSITIONREASON' && CodingMaster.activestatus==true",
            return_fields:
              "KEEP(CodingMaster,'_id','id','_key','code','display','Type')",
          }),
        },
        __readDocumentUrl__
      );
      let arry = [];
      data.result.map((val) => {
        arry.push({
          value: val?._id ?? "",
          label: val?.display ?? "",
        });
      });
      return {
        ...defaultState.Info,
        data: arry,
      };
    } catch (error) {
      alert("error");
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
//FOLLOWUPUOM
const FOLLOWUPUOM = createAsyncThunk(
  `dashboardApiSlice/followupUOM`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        {
          body: JSON.stringify({
            db_name: dbName,
            entity: "CodingMaster",
            filter:
              "CodingMaster.Type=='FOLLOWUOM' && CodingMaster.activestatus==true",
            return_fields:
              "KEEP(CodingMaster,'_id','id','_key','code','display','Type')",
            sort: "CodingMaster.code",
          }),
        },
        __readDocumentUrl__
      );
      let arry = [];
      data.result.map((val) => {
        arry.push({
          value: val?._id ?? "",
          label: val?.display ?? "",
        });
      });
      return {
        ...defaultState.Info,
        data: arry,
      };
    } catch (error) {
      alert("error");
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
//REASON_MASTER
const REASON_MASTER = createAsyncThunk(
  `dashboardApiSlice/reasonMaster`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        {
          body: JSON.stringify({
            db_name: dbName,
            entity: "CodingMaster",
            filter:
              "CodingMaster.Type=='REASONTYPE' && CodingMaster.activestatus==true",
            return_fields:
              "KEEP(CodingMaster,'_id','id','_key','code','display','Type')",
          }),
        },
        __readDocumentUrl__
      );
      let arry = [];
      data.result.map((val) => {
        arry.push({
          value: val?._id ?? "",
          label: val?.display ?? "",
        });
      });
      return {
        ...defaultState.Info,
        data: arry,
      };
    } catch (error) {
      alert("error");
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const constructStatusHistory = (data) => {
  const statushistory_ = Array.isArray(data?.statushistory)
    ? JSON.parse(JSON.stringify(data?.statushistory))
    : [];
  let obj = {
    "tostatus": data?.toStatus,
    "modifieddate": Math.floor(Date.now() / 1000),
    "frmstatus": data?.fromStatus,
    "remarks": ""
  };
  statushistory_.push(obj);
  return statushistory_;
};



//CLOSE_CONSULTATION_UPDATE
const CLOSE_CONSULTATION_UPDATE = createAsyncThunk(
  `dashboardApiSlice/closeConsultationUpdate`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      let { data } = payload;

      const statusHistoryArr = constructStatusHistory(data);
      const payloadCon = [
        {
          db_name: dbName,
          entity: "Encounter",
          filter: {
            _key: `${data?.encId}`,
          },
          is_metadata: true,
          metadataId: process.env.REACT_APP_METADATAID,
          metadata_dbname: process.env.REACT_APP_METADATA_DBNAME,
          doc: {
            encounterenddate: data?.encounterenddate
              ? getUtcTime(data?.encounterenddate)
              : 0,
            reason: [
              {
                use: "",
                reasoncode: data?.reasonCode ?? "",
                reasondesc: "",
              },
            ],
            followupreqd: data?.followupReqd ?? false,
            followupuom: data?.followupUOM ?? "",
            followupdur: parseInt(data?.followupDur) ?? 0,
            status: data?.toStatus,
            statushistory: statusHistoryArr,
          },
        },
      ];
      const data_ = await fetchData(
        {
          body: JSON.stringify(payloadCon),
        },
        __uspsertUrl__
      );

      return {
        ...defaultState.Info,
        data: data_,
      };
    } catch (error) {
      alert("error");
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const closeConsultationSlice = createSlice({
  name: "dashboardApiSlice",
  initialState: {
    //ASSIGN PRACTITIONER
    assignPractitionerType: {
      ...defaultState.Info,
    },
    assignPractitionerRole: {
      ...defaultState.Info,
    },

    //CLOSE CONSULTATION MASTER
    dispositionType: {
      ...defaultState.Info,
    },
    followupUOM: {
      ...defaultState.Info,
    },
    reasonMaster: {
      ...defaultState.Info,
    },
    closeConsultationUpdate: {
      ...defaultState.Info,
    },
  },
  extraReducers: {
    /* ASSIGN_PRACTITIONER_TYPE */
    [ASSIGN_PRACTITIONER_TYPE.fulfilled]: (state, action) => {
      state.assignPractitionerType = action?.payload ?? {};
    },
    [ASSIGN_PRACTITIONER_TYPE.pending]: (state, action) => {
      state.assignPractitionerType.loading = true;
    },
    [ASSIGN_PRACTITIONER_TYPE.rejected]: (state, action) => {
      state.assignPractitionerType = action?.payload ?? {};
    },

    /* ASSIGN_PRACTITIONER_ROLE */
    [ASSIGN_PRACTITIONER_ROLE.fulfilled]: (state, action) => {
      state.assignPractitionerRole = action?.payload ?? {};
    },
    [ASSIGN_PRACTITIONER_ROLE.pending]: (state, action) => {
      state.assignPractitionerRole.loading = true;
    },
    [ASSIGN_PRACTITIONER_ROLE.rejected]: (state, action) => {
      state.today_scassignPractitionerRolehedule = action?.payload ?? {};
    },

    //CLOSE CONSULTATION MASTER
    /* DISPOSITION_TYPE */
    [DISPOSITION_TYPE.fulfilled]: (state, action) => {
      state.dispositionType = action?.payload ?? {};
    },
    [DISPOSITION_TYPE.pending]: (state, action) => {
      state.dispositionType.loading = true;
    },
    [DISPOSITION_TYPE.rejected]: (state, action) => {
      state.dispositionType = action?.payload ?? {};
    },
    /* FOLLOWUPUOM */
    [FOLLOWUPUOM.fulfilled]: (state, action) => {
      state.followupUOM = action?.payload ?? {};
    },
    [FOLLOWUPUOM.pending]: (state, action) => {
      state.followupUOM.loading = true;
    },
    [FOLLOWUPUOM.rejected]: (state, action) => {
      state.followupUOM = action?.payload ?? {};
    },
    /* REASON_MASTER */
    [REASON_MASTER.fulfilled]: (state, action) => {
      state.reasonMaster = action?.payload ?? {};
    },
    [REASON_MASTER.pending]: (state, action) => {
      state.reasonMaster.loading = true;
    },
    [REASON_MASTER.rejected]: (state, action) => {
      state.reasonMaster = action?.payload ?? {};
    },
    /* CLOSE_CONSULTATION_UPDATE */
    [CLOSE_CONSULTATION_UPDATE.fulfilled]: (state, action) => {
      state.closeConsultationUpdate = action?.payload ?? {};
    },
    [CLOSE_CONSULTATION_UPDATE.pending]: (state, action) => {
      state.closeConsultationUpdate.loading = true;
    },
    [CLOSE_CONSULTATION_UPDATE.rejected]: (state, action) => {
      state.closeConsultationUpdate = action?.payload ?? {};
    },
  },
});

const closeConsultationApiActions = {
  ASSIGN_PRACTITIONER_TYPE,
  ASSIGN_PRACTITIONER_ROLE,
  DISPOSITION_TYPE,
  FOLLOWUPUOM,
  REASON_MASTER,
  CLOSE_CONSULTATION_UPDATE,
};

export { closeConsultationApiActions };

export default closeConsultationSlice.reducer;
