import {
  Backdrop,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  Col,
  Div,
  H6,
  Row,
  TextInput,
  Select,
  Button,
  MultiSelect,
  MultipleSelect,
} from "qdm-component-library";
import React, { useContext } from "react";
import { UIColor } from "../../themes/theme";
import CloseIcon from "@material-ui/icons/Close";
import CustomInputDatePicker from "../../components/customDatePicker/inputDatePicker";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../redux/actions";
import { useState } from "react";
import {
  AlertProps,
  isObjectEmpty,
  GetQueueData,
  getTenantId,
} from "../../utils";
import jwtDecode from "jwt-decode";
import {
  AlertContext,
  BackdropContext,
  QueueManagementContext,
} from "../../context";
import moment from "moment";
import { Autocomplete } from "@material-ui/lab";
const useStyles = makeStyles(()=>({
  Autocomplete:{
    "& .MuiAutocomplete-inputRoot":{
      borderRadius: 8,
      border: "1px  #E0E0E0"
      
    }
  }
}))
const styles = {
  labelStyle: {
    color: "#6F6F6F",
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  inputBorderStyle: {
    borderRadius: 8,
    border: "1px solid #E0E0E0",
  },
  errorinputBorderStyle: {
    borderRadius: 8,
  },
  selectBorderStyle: {
    borderRadius: 8,
    borderColor: "#E0E0E0",
  },
  FormLabelstyle: {
    display: "flex",
    fontWeight: "500",
    margin: "0px 0px 6px",
    color: "rgb(111, 111, 111)",
    fontSize: "12px",
    fontFamily: "pc_regular !important",
  },

  Radiogroupstyle: {
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
  },
  asteriskStyle: {
    color: "red",
    marginLeft: "4px",
  },
};
var debouncing;
const ERefAppointmentConfirmation = (props) => {
  const classes =  useStyles();
  const dispatch = useDispatch();
  const alert = useContext(AlertContext);
  const backdrop = useContext(BackdropContext);
  const [error, setError] = useState({});
  const {
    setAllData,
    setDisplayData,
    setPreviousData,
    setpreviousQueue,
    setloader,
  } = React.useContext(QueueManagementContext);
  const selector = useSelector((state) => {
    return {
      appointmenttype:
        state.TransferandSchedule?.appointmenttype?.data?.CONSULTTYPE || [],
      scheduledFacilityName:
        state?.TransferandSchedule?.scheduledFacilityName?.data || [],
      scheduledspeciality:
        state.TransferandSchedule?.appointmenttype?.data?.SPECIALTY || [],
      scheduleddoctorname:
        state?.TransferandSchedule?.scheduleddoctorname?.data || [],
      scheduledfacilityaddress:
        state?.TransferandSchedule?.scheduledfacilityaddress?.data || [],
      servicebooked: state?.TransferandSchedule?.servicebooked?.data || [],
      serviceloading:
        state?.TransferandSchedule?.servicebooked?.loading || false,
      scheduledBy: state?.TransferandSchedule?.scheduledBy?.data,
    };
  });
  console.log({ selector });

  const [state, setState] = useState({
    scheduledUserRole: {},
    scheduledTimeNDate: "",
    specilaity: {},
    scheduledBy: {},
    serviceBooked: [], //FIXME -
    appointmenttype: "",
    scheduledFacilityName: {},
    scheduledspeciality: {},
    scheduleddoctorname: {},
    scheduledfacilityaddress: {},
    referenceNo: "",
    tokenNo: "",
    timeanddate: "",
  });
  const handleChange = async (key, value) => {
    debugger
    if (key === "scheduledFacilityName" && !isObjectEmpty(value)) {
      dispatch(actions.SCHEDULED_FACILITY_ADDRESS(value?._id)).then((res) => {
        setState({
          ...state,
          scheduledfacilityaddress: res?.payload?.data?.[0],
          scheduleddoctorname: {},
          [key]: value,
        });
      });
    } else if (key === "specilaity" && !isObjectEmpty(value)) {
      dispatch(
        actions.SCHEDULED_DOCTOR_NAME({
          fac: state?.scheduledFacilityName?._id,
          spec: value?._id,
        })
      ).then((res) => {
        setState({
          ...state,
          scheduleddoctorname: {},
          [key]: value,
        });
      });
    } else if (key === "scheduledBy" && !isObjectEmpty(value)) {
      setState({
        ...state,
        scheduledUserRole: value?.role,
        [key]: value,
      });
    } else {
      setState({ ...state, [key]: value });
    }
  };
  console.log({ state });
  const validation = () => {
    let data = {
      scheduledUserRole: {},
      scheduledTimeNDate: "",
      specilaity: {},
      scheduledBy: {},
      serviceBooked: [],
      appointmenttype: "",
      scheduledFacilityName: {},
      scheduleddoctorname: {},
      scheduledfacilityaddress: {},
    };
    let validation = {
      error: false,
    };

    Object.keys(data).forEach((v) => {
      if (v === "scheduledTimeNDate") {
        // For date object
        if (state[v]) {
          if (state[v]?.getTime()?.toString()?.length >= 10) {
            validation[v] = false;
          } else {
            validation.error = true;
            validation[v] = true;
          }
        } else {
          validation.error = true;
          validation[v] = true;
        }
      } else if (Array.isArray(data[v])) {
        if (!state[v]?.length) {
          validation.error = true;
          validation[v] = true;
        } else {
          validation[v] = false;
        }
      } else if (typeof data[v] === "string") {
        if (!state[v]?.trim()) {
          validation.error = true;
          validation[v] = true;
        } else {
          validation[v] = false;
        }
      } else if (typeof data[v] === "object") {
        if (isObjectEmpty(state[v])) {
          validation.error = true;
          validation[v] = true;
        } else {
          validation[v] = false;
        }
      } else {
        if (
          !state[v] ||
          (typeof state[v] === "object" && isObjectEmpty(state[v]))
        ) {
          validation.error = true;
          validation[v] = true;
        } else {
          validation[v] = false;
        }
      }
    });

    console.log(validation, "validation//");
    setError(validation);
    return validation;
  };
  const HandleChangeForSearch = (e, v) => {
    setState({ ...state, serviceBooked: v || [] });
  };
  const onSubmit = async () => {
    try {
      let valid = validation();
      if (!valid?.error) {
        // backdrop.setBackDrop({
        //   ...backdrop,
        //   open: true,
        //   message: "scheduling...",
        // });
        let token = jwtDecode(localStorage.getItem("UserInfo"));
        console.log("props", props);

        let payload = {
          ticketId: props?.state?.totalData?.ticketId,
          userAction: props?.state?.l?.action,
          data: {
            ticketId: props?.state?.totalData?.ticketId,
            practitionerId: token?.practitionerid,
            practitionerRoleId: token?.idmroleid,
            appdetails: [
              {
                appfacilityid: state?.scheduledFacilityName?._id,
                apppractid: state?.scheduleddoctorname?._id,
                appfacilityaddress: state?.scheduledfacilityaddress?.value,
                servicerequested: state?.serviceBooked.map((v) => v?._id),
                appspecialty: state?.specilaity?._id,
                appdatetime: moment(state?.scheduledTimeNDate)?.unix(),
                apptype: state?.appointmenttype,
                apprefno: state?.referenceNo,
                apptokenno: state?.tokenNo,
                scheduleduserid: state?.scheduledBy?._id,//token?.practitionerid,
                scheduleduserrole: state?.scheduledUserRole?._id,//token?.idmroleid,
                scheduledtime: moment()?.unix(),
                isactive: true,
              },
            ],
          },
        };
        setloader(true);
        let data = await dispatch(actions.SCHEDULE_ACTION(payload));
        setloader(false);
        if (data?.payload?.data?.error) {
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.warning,
            msg:
              data?.payload?.data?.Response?.[0]?.errorMessage ||
              "something went wrong",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
        } else {
          props?.RefreshLatestQueue && props?.RefreshLatestQueue(props?.state?.l?.action,"",props?.state?.totalData?.ticketId)
          let data = await GetQueueData();
          setAllData(data.everyData);
          setDisplayData(data.getDisplayData);
          setPreviousData(data.getPreviousData);
          setpreviousQueue(props?.state?.queueId);
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: "scheduled successfully !",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
        }
        props?.setDialog((prev) => {
          return { ...prev, openSchedule: false,open:false  };
        });
        // backdrop.setBackDrop({
        //   ...backdrop,
        //   open: false,
        //   message: "",
        // });
      } else if (valid.error) {
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.warning,
          msg: "Please fill mandatory fields",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
        return;
      }
    } catch (error) {
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.warning,
        msg: error?.message || "something went wrong",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
    backdrop.setBackDrop({
      ...backdrop,
      open: false,
      message: "",
    });
  };
  async function getData() {
    let token = jwtDecode(localStorage.getItem("UserInfo"));
    dispatch(actions.APPOINTMENT_TYPE());
    dispatch(actions.SCHEDULED_FACILITY_NAME());
    dispatch(actions.SCHEDULED_BY(token?.practitionerid));
    dispatch(actions.SERVICE_BOOKED());
    // dispatch(actions.SCHEDULED_SPECIALITY());
  }
  const ServcieSearch = (value) => {
    if (value) {
      clearTimeout(debouncing);
      debouncing = setTimeout(() => {
        dispatch(actions.SERVICE_BOOKED(value));
      }, 700);
    }
  };
  const loadDefaultValues = async () => {
    debugger;
    let res = await dispatch(
      actions.GET_SCREEN_DEFAULT_VALUE({
        screenid: "a1c0ee83-dffa-4aa1-9f8d-7c682d82f0bd",
        tenantid: getTenantId(),
        filtervalues: {
          referraltrxid:
            props?.state?.totalData?.inputdoc?.referraltrxid
        },
      })
    );
    const defaultValues = res?.payload?.data?.response?.defaultValues;
    const stateNames = [
      "facility_address",
      "schedule_facility",
      "service_booked",
      "schedule_doctor",
      "speciality",
      "appointmentType"
    ];
    const stateValues = {};
    defaultValues.forEach((s) => {
      if (stateNames.includes(s.stateName)) {
        stateValues[s.stateName] = s.value;
      }
      console.log(stateValues, "statevalue");
    });
    setState({
      scheduledUserRole: {},
      scheduledTimeNDate: new Date(),
      specilaity: {
        value: stateValues?.speciality?.value,
        _id: stateValues?.speciality?._id,
      },
      scheduledBy: {},
      serviceBooked: [],
      appointmenttype: stateValues?.appointmentType?._id,
      scheduledFacilityName: {
        value: stateValues?.schedule_facility?.value,
        _id: stateValues?.schedule_facility?._id,
      },
      scheduledspeciality: {},
      scheduleddoctorname: {
        value: stateValues?.schedule_doctor?.value,
        _id: stateValues?.schedule_doctor?._id,
      },
      scheduledfacilityaddress: {
        value: stateValues?.facility_address?.value,
        _id: stateValues?.facility_address?._id,
      },
      referenceNo: "",
      tokenNo: "",
      timeanddate: "",
    });
  };
  React.useEffect(() => {
    getData();
    loadDefaultValues();
  }, []);
  const helperTextMsg = "This field is required";
  const {
    labelStyle,
    inputBorderStyle,
    errorinputBorderStyle,
    selectBorderStyle,
    FormLabelstyle,
    Radiogroupstyle,
    asteriskStyle,
  } = styles;
  const { parent_id } = props;

  const handleAppointmentTypeChange = (e) => {
    debugger;
    setState({
      ...state,
      appointmenttype: e.target.value,
    });
  };
  return (
      <Div
        id="Referral_Registration_referral_parent_div"
        //className="light-background-color"
      >
        <Grid
          container
          spacing={2}
          style={{ display: "flex", justifyContent: "space-between", padding:"10px"}}
        >
          <Typography variant="h6" style={{ textTransform: "capitalize" }}>
            E-Referral Schedule
          </Typography>
          <IconButton
            onClick={() =>
              props?.setDialog((prev) => {
                return { ...prev, open: false };
              })
            }
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Paper className="pl-root">
        <Grid
          id={`${parent_id}_refletter_parent_row`}
          className="en-actions-root"
          // inLineStyles={{ marginBottom: "10px !important" }}
          style={{padding:"10px"}}
          container
          spacing={2}
        >
          <Div id={`${parent_id}_refletter_title_label_col`}>
            <H6
              id={`${parent_id}_refletter_title_label_h6`}
              className="en-title space-padding-bottom"
            >
              Schedule Details
            </H6>
          </Div>
          <Grid
          id={`${parent_id}_refletter_sub_parent_row`}
          className="en-actions-root"
          item
          container
          spacing={3}
          xs={12}
          lg={12}
        >
          <Grid
            id={`${parent_id}_refFrom_col`}
            md={3}
            lg={6}
            sm={12}
            xs={12}
            className="no-padding-left"
            item
          >
            <Select
              id={`${parent_id}_patient_name_gender_select`}
              isRequired={true}
              showArrow
              label="Scheduled Facility Name "
              placeholder="select..."
              labelStyle={labelStyle}
              inLineStyles={selectBorderStyle}
              hoverColor={UIColor.primaryColor.color}
              options={selector?.scheduledFacilityName}
              onChangeValue={(e) => {
                handleChange("scheduledFacilityName", e);
              }}
              value={state?.scheduledFacilityName?.value || ""}
              error={error?.scheduledFacilityName}
              helperText={helperTextMsg}
              helperTextType={"error"}
            />
          </Grid>
          <Grid
            item
            id={`${parent_id}_refFrom_col`}
            md={3}
            lg={6}
            sm={12}
            xs={12}
            className="no-padding-left"
          >
            <Select
              id={`${parent_id}_patient_name_gender_select`}
              isRequired={true}
              showArrow
              label="Scheduled facility Address"
              placeholder="select..."
              labelStyle={labelStyle}
              inLineStyles={selectBorderStyle}
              hoverColor={UIColor.primaryColor.color}
              options={selector?.scheduledfacilityaddress}
              onChangeValue={(e) => {
                handleChange("scheduledfacilityaddress", e);
              }}
              value={state?.scheduledfacilityaddress?.value || ""}
              error={error?.scheduledfacilityaddress}
              helperText={helperTextMsg}
              helperTextType={"error"}
              disabled
            />
          </Grid>
          <Grid
            item
            id={`${parent_id}_refletter_reciveddate_col`}
            md={3}
            lg={3}
            sm={12}
            xs={12}
            className="no-padding-left"
          >
            {" "}
            <Select
              id={`${parent_id}_refletter_reciveddate_textinput`}
              label="Speciality"
              isRequired={true}
              showArrow
              placeholder="select..."
              labelStyle={labelStyle}
              inLineStyles={selectBorderStyle}
              hoverColor={UIColor.primaryColor.color}
              options={selector?.scheduledspeciality}
              onChangeValue={(e) => {
                handleChange("specilaity", e);
              }}
              value={state?.specilaity?.value || ""}
              error={error?.specilaity}
              helperText={helperTextMsg}
              helperTextType={"error"}
            />
          </Grid>
          <Grid
            item
            id={`${parent_id}_refFrom_col`}
            md={3}
            lg={3}
            sm={12}
            xs={12}
            className="no-padding-left"
          >
            <Select
              id={`${parent_id}_patient_name_gender_select`}
              isRequired={true}
              showArrow
              label="Scheduled Doctor Name"
              placeholder="select..."
              labelStyle={labelStyle}
              inLineStyles={selectBorderStyle}
              hoverColor={UIColor.primaryColor.color}
              options={selector?.scheduleddoctorname}
              onChangeValue={(e) => {
                handleChange("scheduleddoctorname", e);
              }}
              value={state?.scheduleddoctorname?.value || ""}
              error={error?.scheduleddoctorname}
              helperText={helperTextMsg}
              helperTextType={"error"}
            />
          </Grid>
          <Grid
            item
            id={`${parent_id}_refFrom_col`}
            md={6}
            lg={6}
            sm={12}
            xs={12}
            className="no-padding-left"
          >
            <div>
              <p
                style={{
                  fontSize: "14px",
                  fontSWeight: "500",
                  margin: "0",
                  color: "#6A6A6A",
                  marginBottom: "4px",
                  fontFamily: "pc_regular !important",
                }}
              >
                <span
                  style={{
                    color: "#6F6F6F",
                    fontSize: "12px",
                    // marginBottom: "6px",
                    fontFamily: `pc_regular !important`,
                  }}
                >
                  Service Booked &nbsp;
                </span>
                <span
                  style={{
                    color: "red",
                    fontSWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  {" "}
                  *
                </span>
              </p>

              <Autocomplete
                multiple
                id="tags-filled"
                options={selector?.servicebooked ?? []}
                getOptionLabel={(option) => option?.value ?? ""}
                limitTags={2}
                hoverColor={UIColor.primaryColor.color}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option?.value}
                      {...getTagProps({ index })}
                      size="small"
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    placeholder="search service"
                    hoverColor={UIColor.primaryColor.color}
                    style={{paddingBottom:"10px"}}
                    className={classes.Autocomplete}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {selector?.serviceloading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    error={error?.serviceBooked}
                    helperText={error?.serviceBooked ? helperTextMsg : ""}
                  />
                )}
                loading={selector?.serviceloading}
                onChange={(e, v) => {
                  HandleChangeForSearch(e, v);
                }}
                value={state?.serviceBooked || []}
                onInputChange={(e) => {
                  ServcieSearch(e.target.value);
                }}
              />
            </div>
          </Grid>
          <Grid
            item
            id={`${parent_id}_refletter_calender_col`}
            md={3}
            lg={3}
            sm={12}
            xs={12}
            className="no-padding-left"
          >
            <CustomInputDatePicker
              disabled={false}
              placeholderText="dd/mm/yyyy"
             // maxDate={new Date()}
              handleChange={(e) => {
                handleChange("scheduledTimeNDate", e);
              }}
              minDate={new Date()}
              inputField={
                <TextInput
                  id={`${parent_id}_refletter_referral_date_textinput`}
                  label="Scheduled Date"
                  placeholder="dd/mm/yyyy"
                  dateIcon="images/icons8-calendar-grey.svg"
                  labelStyle={labelStyle}
                  isRequired
                  hoverColor={UIColor.primaryColor.color}
                  style={selectBorderStyle}
                  value={
                    state?.scheduledTimeNDate
                      ? moment(new Date(state?.scheduledTimeNDate)).format(
                          "DD/MM/YYYY"
                        )
                      : ""
                  }
                  error={error?.scheduledTimeNDate}
                  helperText={helperTextMsg}
                  helperTextType={"error"}
                />
              }
            />
          </Grid>
          <Grid
            item
            id={`${parent_id}_refFrom_col`}
            md={3}
            lg={3}
            sm={12}
            xs={12}
            className="no-padding-left"
          >
            {/* <Select
                      placeholder="select..."
                      search
                      isRequired
                      label="Appointment Type"
                      labelStyle={labelStyle}
                      inLineStyles={selectBorderStyle}
                      hoverColor={UIColor.primaryColor.color}
                      options={selector?.appointmenttype}
                      onChangeValue={(e) => {
                        handleChange("appointmenttype", e);
                      }}
                      value={state?.appointmenttype?.value || ""}
                      error={error?.appointmenttype}
                      helperText={helperTextMsg}
                      helperTextType={"error"}
                    /> */}
            <FormControl component="fieldset" style={{ margin: "8px" }}>
              <FormLabel component="legend" style={FormLabelstyle}>
              Consultation Mode <span style={asteriskStyle}> * </span>
              </FormLabel>
              <RadioGroup
                style={Radiogroupstyle}
                aria-label="appointment-type"
                name="appointment-type"
                value={state?.appointmenttype || ""}
                onChange={handleAppointmentTypeChange}
              >
                {selector?.appointmenttype?.map((v) => {
                  return (
                    <FormControlLabel
                      value={v?._id}
                      control={<Radio color="primary" />}
                      label={v?.value}
                      disabled
                    />
                  );
                })}
              </RadioGroup>
              {error?.appointmenttype && (
                <FormHelperText error={true}>{helperTextMsg}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid
            item
            id={`${parent_id}_refletter_sub_parent_col`}
            md={3}
            lg={3}
            sm={12}
            xs={12}
            className="no-padding-left"
          >
            <TextInput
              id={`${parent_id}_refletter_no_label_textinput`}
              label="Reference Number"
              placeholder="Reference Number"
              labelStyle={labelStyle}
              style={selectBorderStyle}
              onChange={(e) => {
                handleChange("referenceNo", e.target.value);
              }}
              value={state?.referenceNo || ""}
              // error={error?.referenceNo}
              // helperText={helperTextMsg}
              // helperTextType={"error"}
            />
          </Grid>
          <Grid
            item
            id={`${parent_id}_refletter_sub_parent_col`}
            md={3}
            lg={3}
            sm={12}
            xs={12}
            className="no-padding-left"
          >
            <TextInput
              id={`${parent_id}_refletter_no_label_textinput`}
              label="Token Number"
              placeholder="Token Number"
              labelStyle={labelStyle}
              style={selectBorderStyle}
              onChange={(e) => {
                handleChange("tokenNo", e.target.value);
              }}
              value={state?.tokenNo || ""}
              // error={error?.tokenNo}
              // helperText={helperTextMsg}
              // helperTextType={"error"}
            />
          </Grid>
          <Grid
              id={`${parent_id}_refto_parent_col`}
              md={12}
              lg={12}
              sm={12}
              xs={12}
              className="no-padding-left"
              item
            >
              <Div id={`${parent_id}_refto_title_h6`}>
                <H6
                  id={`${parent_id}_refto_h6`}
                  className="en-title space-padding-bottom"
                >
                  Additional Information
                </H6>
              </Div>
              </Grid>
              <Grid
                  id={`${parent_id}_refletter_sub_parent_col`}
                  md={3}
                  lg={3}
                  sm={12}
                  xs={12}
                  className="no-padding-left"
                  item
                >
                  <Select
                    isRequired
                    label="Scheduled By "
                    labelStyle={labelStyle}
                    inLineStyles={selectBorderStyle}
                    hoverColor={UIColor.primaryColor.color}
                    options={selector?.scheduledBy}
                    onChangeValue={(e) => {
                      handleChange("scheduledBy", e);
                    }}
                    value={state?.scheduledBy?.value || ""}
                    error={error?.scheduledBy}
                    helperText={helperTextMsg}
                    helperTextType={"error"}
                  />
                </Grid>
                <Grid
                  id={`${parent_id}_refletter_sub_parent_col`}
                  md={3}
                  lg={3}
                  sm={12}
                  xs={12}
                  className="no-padding-left"
                  item
                >
                  <TextInput
                    id={`${parent_id}_refletter_no_label_textinput`}
                    label="Scheduled by user role"
                    isRequired
                    placeholder="Scheduled by user role"
                    labelStyle={labelStyle}
                    style={selectBorderStyle}
                    onChange={(e) => {
                      handleChange("scheduledUserRole", e.target.value);
                    }}
                    value={state?.scheduledUserRole?.display ?? ""}
                    error={error?.scheduledUserRole}
                    helperText={helperTextMsg}
                    helperTextType={"error"}
                    disabled={true}
                  />
                </Grid>
                <Grid
                  id={`${parent_id}_refletter_calender_col`}
                  md={3}
                  lg={3}
                  sm={12}
                  xs={12}
                  className="no-padding-left"
                  style={{paddingTop:"10px"}}
                >
                  <CustomInputDatePicker
                    disabled={false}
                    placeholderText="dd/mm/yyyy"
                    maxDate={new Date()}
                    handleChange={(e) => {
                      handleChange("timeanddate", e);
                    }}
                    inputField={
                      <TextInput
                        id={`${parent_id}_refletter_referral_date_textinput`}
                        label="Time & Date"
                        placeholder="dd/mm/yyyy"
                        dateIcon="images/icons8-calendar-grey.svg"
                        labelStyle={labelStyle}
                        isRequired
                        hoverColor={UIColor.primaryColor.color}
                        style={selectBorderStyle}
                        value={moment().format("DD/MM/YYYY HH:mm")}
                        error={error?.timeanddate}
                        helperText={helperTextMsg}
                        helperTextType={"error"}
                        disabled
                      />
                    }
                  />
                </Grid>
        </Grid>
        </Grid>
       </Paper>
        <Grid container style={{ display: "flex", justifyContent: "flex-end", padding:"10px" }}>
          <span style={{ display: "flex", gap: "10px" }}>
            <Button
              onClick={() =>
                props?.setDialog((prev) => {
                  return { ...prev, open: false };
                })
              }
              color="primary"
              fullWidth
              type="outlined"
              style={{
                color: UIColor.primaryColor.color,
                borderColor: UIColor.primaryColor.color,
                backgroundColor: "#FFFFFF",
                fontWeight: 500,
                padding: "10px",
                margin: "4px",
              }}
            >
              CANCEL
            </Button>{" "}
            <Button
              onClick={onSubmit}
              color="primary"
              fullWidth
              type="contained"
              style={{
                color: "#fff",
                background: UIColor.primaryColor.color,
                fontWeight: 500,
                padding: "10px",
                margin: "4px",
              }}
            >
              {"SAVE"}
            </Button>
          </span>
        </Grid>
      </Div>
  );
};
export default ERefAppointmentConfirmation;
