import React, { useEffect,useState } from "react";
import { BorderLinearProgress } from "qdm-component-library";
import { NetworkCall } from "./networkcall";
import { LocalStorageKeys, NetWorkCallMethods, semverGreaterThan, refreshCacheAndReload, __tenantid__ } from "./utils";
import actions from "./redux/actions";
import { useDispatch } from "react-redux";
import { AuthContext } from "./context";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "./router/routes";
import jwtDecode from "jwt-decode";
function AppAuth(props) {
  const dispatch = useDispatch();
  const [tokenData,setTokenData]=useState({})
  let timeoutId = ""
  const checkForLatestBuild = () => {
    console.log(`${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ''}/meta.json`, "app auth");
    NetworkCall(
      `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ''}/meta.json`,
      NetWorkCallMethods.get,
      null,
      null,
      false,
      true
    ).then((_) => {
      const isVersion = semverGreaterThan(_.data.version, localStorage.getItem(LocalStorageKeys.version));
      localStorage.setItem(LocalStorageKeys.version, _.data.version)
      if (isVersion) {
        refreshCacheAndReload();
      }
    }).catch(err => {
      console.log('err:', err);
    })
  }
  const resetTimer = (remainingTime) => {
    clearTimeout(timeoutId);
    setTimeout(refreshOrLogoutToken, remainingTime);
  };
  useEffect(() => {
    checkForLatestBuild();
    RefreshTokenFunc()
    return () => {
      ['mousemove', 'keypress'].forEach(event => {
        document.removeEventListener(event, resetTimer);
      });
      clearTimeout(timeoutId);
    };
  }, []);
  async function RefreshTokenFunc(state) {
    try {
      
      const jwtToken = jwtDecode(localStorage.getItem("Token"));
      const UserInfo = jwtDecode(localStorage.getItem("UserInfo"));
      const expiryTime = jwtToken?.expires_in * 1000  // seconds to milliseconds
      setTokenData(UserInfo)
      // in milli seconds
      if (jwtToken && expiryTime) {
        const remainingTime = (expiryTime + Date.now()) - Date.now();
        if (remainingTime > 0) {
         // SkipLogin()
          // Token is still valid, set up a timer to refresh or logout
          if (remainingTime < 20) {
            timeoutId = setTimeout(refreshOrLogoutToken, remainingTime);
          }
          //window.alert("logout",remainingTime)
          // Attach event listeners to reset the timer on user activity
          ['mousemove', 'keypress'].forEach(event => {
            document.addEventListener(event, () => resetTimer(remainingTime));
          });

          return () => {
            ['mousemove', 'keypress'].forEach(event => {
              document.removeEventListener(event, () => resetTimer(remainingTime));
            });
            clearTimeout(timeoutId);
          };
        } else {
          // Token has already expired, logout the user
          logout();
        }
      }
    } catch (error) {
    }
  };

  const SkipLogin = () => {
    
    let skip = true
    for (let index = 0; index < localStorage.length; index++) {

      if (!localStorage.getItem((localStorage.key(index)))) {
        skip = false
      }
    }
    if (skip && window.location.pathname==="/") {
      window.location.href = AppRoutes.dashboard
    }
    
  }
  const refreshOrLogoutToken = async () => {
    
    try {
      logout();

     //FIXME -  setTokenData()
      // // Call your API to refresh the token
      // const refreshedToken = await fetchRefreshToken();
      // localStorage.setItem(TOKEN_KEY, refreshedToken);
      // // Reset expiry time
      // localStorage.setItem('expiryTime', calculateExpiryTime());
    } catch (error) {
      // Refresh token failed, logout the user
      logout();
    }
  };

  const logout = () => {
    localStorage.clear();
    window.location.href = "/";

  };

  const calculateExpiryTime = () => {
    // Calculate and return the expiry time for the new token
    // This depends on your token structure or backend implementation
    // For example:
    // const expiryTime = Date.now() + (3600 * 1000); // 1 hour from now
  };
  return (
    <AuthContext.Provider value={{tokenData,RefreshTokenFunc}}>
      {props.loading && (
        <BorderLinearProgress />
      )}
      {props.children}
    </AuthContext.Provider>
  );
}

export default AppAuth;
