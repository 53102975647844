import React from "react";
import { H6, Row } from "qdm-component-library";
import StatsCard from "./statsCard";

function Stats(props) {
  const DATA = [
    {
      label: "New Referrals",
      count: "0",
      color: "#57ABE3",
      actionsName: "APPOINTMENTS",
    },
    {
      label: "Dialysis Appointments",
      count: "0",
      color: "#F4748F",
      actionsName: "FIRST_VISIT_COUNT"
    },
    {
      label: "PIC Review Appointments",
      count: "0",
      color: "#4BC0C0",
      actionsName: "OTHER_VISIT_COUNT"
    },
    {
      label: "Nephrologist Appointments",
      count: "0",
      color: "#5AA3D5",
      actionsName: "WALK_INS_COUNT"
    }
  ];
  // [
  //   {
  //     label: "Appointments",
  //     count: "0",
  //     total: "0",
  //     color: "#57ABE3",
  //     actionsName: "APPOINTMENTS",
  //   },
  //   {
  //     label: "First Visits",
  //     count: "0",
  //     color: "#F4748F",
  //     actionsName: "FIRST_VISIT_COUNT"
  //   },
  //   {
  //     label: "Other Visits",
  //     count: "0",
  //     color: "#4BC0C0",
  //     actionsName: "OTHER_VISIT_COUNT"
  //   },
  //   {
  //     label: "Walk-Ins",
  //     count: "0",
  //     color: "#5AA3D5",
  //     actionsName: "WALK_INS_COUNT"
  //   },
  //   {
  //     label: "New Patients",
  //     count: "0",
  //     color: "#F16389",
  //     actionsName: "NEW_PATIENT_COUNT"
  //   },
  // ];
  const list = props?.Permission?.write ?? [];
  let roleId = list.indexOf("statsWidgetD") > -1 ? 1 : list.indexOf("statsWidgetN") > -1 ? 2 : 3
  return (
    <div
      parent_id={props.parent_id + "_parent_div"}
      style={{ height: "100%", padding: 10, paddingLeft: 0, paddingTop: 0 }}>
      <Row
        parent_id={props.parent_id + "_parent_row"}
      >
        {props?.data &&
          props?.data.map((stats, i) => {
            return (
              <div
                parent_id={props.parent_id + '_' + i + "_parent_stats_div"}
                style={{ width: (100/props?.data.length) + "%" }}>
                <StatsCard
                  roleId={roleId}
                  parent_id={props.parent_id + '_' + i}
                  title={stats?.label}
                  count={stats?.count}
                  color={stats?.color}
                  total={stats?.total}
                  actionsName={stats?.actionsName}
                  index={i}
                />
              </div>
            );
          })}
      </Row>
    </div>
  );
}

export default Stats;
