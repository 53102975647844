import React from "react";
import {
  Box,
  Paper,
  Grid,
  Avatar,
  Typography,
  Divider,
  Button,
  CircularProgress,
  IconButton,
  Collapse,
} from "@mui/material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonIcon from "@mui/icons-material/Person";
import AddLabOrder from "./addLabOrder";
import { UIColor } from "../../../themes/theme";
import AddNurProOrder from "./addNurProOrder";
import VitalSignsCpg from "./vitalSignsCpg";
import AllergyCpg from "./allergyCpg";
import DiagnosisCpg from "./diagnosisCpg";
import AddDiagnosisOrder from "./addDiagnosisOrder";
import AddRadiologyOrder from "./addRadiologyOrder";
import AddInvestigationOrder from "./addInvestigationOrder";
import AddMedicationOrder from "./addMedicationOrder";
import AddObservationOrder from "./addObservationOrder";
import AddAssesmentOrder from "./addAssesmentOrder";
import AddReferralOrder from "./addReferralOrder";
import AddLifeStyleAdvice from "./addLifeStyleAdvice";
import HotelIcon from "@mui/icons-material/Hotel";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import TourIcon from "@mui/icons-material/Tour";
import ChiefComplaintsCpg from "./chiefComplaintsCpg";
import { __tenantid__, AlertProps, urls } from "../../../utils";
import jwtDecode from "jwt-decode";
import RiskFactor from "./riskFactor";
import { AlertContext } from "../../../context";
import Lottie from "react-lottie";
import animationData from "../../../assets/animations/loading.json";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';


const Emr = (props) => {
  const alert = React.useContext(AlertContext);
  const userInfo = localStorage?.getItem("UserInfo");
  const jwtToken = localStorage?.getItem("JwtToken");
  const decodedUserinfo =
    userInfo === null || userInfo === undefined ? "" : jwtDecode(userInfo);
  const _facility_ = decodedUserinfo?.facilityid ?? "";

  const practitionerid = decodedUserinfo?.practitionerid ?? "";
  const roleid = decodedUserinfo?.roleid ?? "";
  const [loading, setLoading] = React.useState(true);
  const [saving, setSaving] = React.useState(false);
  const [state, setState] = React.useState();

  const [diagnosisDataFromAi, setDiagnosisDataFromAi] = React.useState();

  const [labDataFromAi, setLabDataFromAi] = React.useState();

  const [radiologyDataFromAi, setRadiologyDataFromAi] = React.useState();

  const [investicationDataFromAi, setInvesticationDataFromAi] =
    React.useState();

  const [medicationDataFromAi, setMedicationDataFromAi] = React.useState();

  const [nurDataFromAi, setNurDataFromAi] = React.useState();

  const [observationDataFromAi, setObservationDataFromAi] = React.useState();

  const [referralDataFromAi, setReferralDataFromAi] = React.useState();

  const [assesmentDataFromAi, setAssesmentDataFromAi] = React.useState();

  const [adviceDataFromAi, setAdviceDataFromAi] = React.useState();

  const [riskFactorsFromAi, setRiskFactorsFromAi] = React.useState();

  const [collapseDiagnosis, setCollapseDiagnosis] = React.useState(false);
  
  const [collapseAllDiagnosis, setCollapseAllDiagnosis] = React.useState(false);

  const [collapseLabOrder, setCollapseLabOrder] = React.useState(false);

  const [collapseAllLabOrder, setCollapseAllLabOrder] = React.useState(false);

  const [collapseRadOrder, setCollapseRadOrder] = React.useState(false);

  const [collapseAllRadOrder, setCollapseAllRadOrder] = React.useState(false);

  const [collapseInvestOrder, setCollapseInvestOrder] = React.useState(false);

  const [collapseAllInvestOrder, setCollapseAllInvestOrder] = React.useState(false);

  const [collapseMedOrder, setCollapseMedOrder] = React.useState(false);

  const [collapseAllMedOrder, setCollapseAllMedOrder] = React.useState(false);

  const [collapseNurOrder, setCollapseNurOrder] = React.useState(false);

  const [collapseAllNurOrder, setCollapseAllNurOrder] = React.useState(false);

  const [collapseObsOrder, setCollapseObsOrder] = React.useState(false);

  const [collapseAllObsOrder, setCollapseAllObsOrder] = React.useState(false);

  const [collapseRefOrder, setCollapseRefOrder] = React.useState(false);

  const [collapseAllRefOrder, setCollapseAllRefOrder] = React.useState(false);

  const [collapseAssesOrder, setCollapseAssesOrder] = React.useState(false);

  const [collapseAllAssesOrder, setCollapseAllAssesOrder] = React.useState(false);

  const [collapseLifeStyleAdvice, setCollapseLifeStyleAdvice] = React.useState(false);

  const [collapseAllLifeStyleAdvice, setCollapseAllLifeStyleAdvice] = React.useState(false);

  const handleCollapse = (state,setState) => {
    setState(!state);
  }
  // input
  const lookup = {
    Diagnosis: [
      {
        diagnosisCodeDescription: [
          {
            value: "Diagnosis/651138",
            label: "A96 - Arenaviral hemorrhagic fever",
          },
          {
            value: "Diagnosis/651143",
            label: "A96.9 - Arenaviral hemorrhagic fever, unspecified",
          },
          {
            value: "Diagnosis/650797",
            label: "A48.4 - Brazilian purpuric fever",
          },
          {
            value: "Diagnosis/680147",
            label: "R68.83 - Chills (without fever)",
          },
          {
            value: "Diagnosis/651131",
            label: "A93.2 - Colorado tick fever",
          },
          {
            value: "Diagnosis/651145",
            label: "A98.0 - Crimean-Congo hemorrhagic fever",
          },
          {
            value: "Diagnosis/651112",
            label: "A90 - Dengue fever [classical dengue]",
          },
          {
            value: "Diagnosis/651114",
            label: "A91 - Dengue hemorrhagic fever",
          },
          {
            value: "Diagnosis/680069",
            label: "R50.2 - Drug induced fever",
          },
          {
            value: "Diagnosis/651108",
            label: "A88.0 - Enteroviral exanthematous fever [Boston exanthem]",
          },
          {
            value: "Diagnosis/651028",
            label:
              "A75.0 - Epidemic louse-borne typhus fever due to Rickettsia prowazekii",
          },
          {
            value: "Diagnosis/680068",
            label: "R50 - Fever of other and unknown origin",
          },
          {
            value: "Diagnosis/680071",
            label:
              "R50.81 - Fever presenting with conditions classified elsewhere",
          },
          {
            value: "Diagnosis/680075",
            label: "R50.9 - Fever, unspecified",
          },
          {
            value: "Diagnosis/651150",
            label: "A98.5 - Hemorrhagic fever with renal syndrome",
          },
          {
            value: "Diagnosis/651139",
            label: "A96.0 - Junin hemorrhagic fever",
          },
          {
            value: "Diagnosis/651141",
            label: "A96.2 - Lassa fever",
          },
          {
            value: "Diagnosis/651001",
            label: "A68.0 - Louse-borne relapsing fever",
          },
          {
            value: "Diagnosis/651140",
            label: "A96.1 - Machupo hemorrhagic fever",
          },
          {
            value: "Diagnosis/651127",
            label: "A92.9 - Mosquito-borne viral fever, unspecified",
          },
          {
            value: "Diagnosis/650795",
            label: "A48.2 - Nonpneumonic Legionnaires' disease [Pontiac fever]",
          },
          {
            value: "Diagnosis/651117",
            label: "A92.1 - O'nyong-nyong fever",
          },
          {
            value: "Diagnosis/651146",
            label: "A98.1 - Omsk hemorrhagic fever",
          },
          {
            value: "Diagnosis/651142",
            label: "A96.8 - Other arenaviral hemorrhagic fevers",
          },
          {
            value: "Diagnosis/651128",
            label:
              "A93 - Other arthropod-borne viral fevers, not elsewhere classified",
          },
          {
            value: "Diagnosis/651115",
            label: "A92 - Other mosquito-borne viral fevers",
          },
          {
            value: "Diagnosis/651132",
            label: "A93.8 - Other specified arthropod-borne viral fevers",
          },
          {
            value: "Diagnosis/680070",
            label: "R50.8 - Other specified fever",
          },
          {
            value: "Diagnosis/651126",
            label: "A92.8 - Other specified mosquito-borne viral fevers",
          },
          {
            value: "Diagnosis/651151",
            label: "A98.8 - Other specified viral hemorrhagic fevers",
          },
          {
            value: "Diagnosis/651043",
            label: "A77.8 - Other spotted fevers",
          },
          {
            value: "Diagnosis/651144",
            label:
              "A98 - Other viral hemorrhagic fevers, not elsewhere classified",
          },
          {
            value: "Diagnosis/650466",
            label: "A01.1 - Paratyphoid fever A",
          },
          {
            value: "Diagnosis/650467",
            label: "A01.2 - Paratyphoid fever B",
          },
          {
            value: "Diagnosis/650468",
            label: "A01.3 - Paratyphoid fever C",
          },
          {
            value: "Diagnosis/650469",
            label: "A01.4 - Paratyphoid fever, unspecified",
          },
          {
            value: "Diagnosis/666106",
            label: "M04.1 - Periodic fever syndromes",
          },
          {
            value: "Diagnosis/680072",
            label: "R50.82 - Postprocedural fever",
          },
          {
            value: "Diagnosis/680073",
            label: "R50.83 - Postvaccination fever",
          },
          {
            value: "Diagnosis/651045",
            label: "A78 - Q fever",
          },
          {
            value: "Diagnosis/650649",
            label: "A25.9 - Rat-bite fever, unspecified",
          },
          {
            value: "Diagnosis/650646",
            label: "A25 - Rat-bite fevers",
          },
          {
            value: "Diagnosis/651003",
            label: "A68.9 - Relapsing fever, unspecified",
          },
          {
            value: "Diagnosis/651000",
            label: "A68 - Relapsing fevers",
          },
          {
            value: "Diagnosis/661473",
            label: "I01 - Rheumatic fever with heart involvement",
          },
          {
            value: "Diagnosis/661470",
            label: "I00 - Rheumatic fever without heart involvement",
          },
          {
            value: "Diagnosis/651124",
            label: "A92.4 - Rift Valley fever",
          },
          {
            value: "Diagnosis/651130",
            label: "A93.1 - Sandfly fever",
          },
          {
            value: "Diagnosis/650724",
            label: "A38 - Scarlet fever",
          },
          {
            value: "Diagnosis/650726",
            label: "A38.1 - Scarlet fever with myocarditis",
          },
          {
            value: "Diagnosis/650727",
            label: "A38.8 - Scarlet fever with other complications",
          },
          {
            value: "Diagnosis/650725",
            label: "A38.0 - Scarlet fever with otitis media",
          },
          {
            value: "Diagnosis/650728",
            label: "A38.9 - Scarlet fever, uncomplicated",
          },
          {
            value: "Diagnosis/651034",
            label: "A77 - Spotted fever [tick-borne rickettsioses]",
          },
          {
            value: "Diagnosis/651038",
            label: "A77.3 - Spotted fever due to Rickettsia australis",
          },
          {
            value: "Diagnosis/651036",
            label: "A77.1 - Spotted fever due to Rickettsia conorii",
          },
          {
            value: "Diagnosis/651035",
            label: "A77.0 - Spotted fever due to Rickettsia rickettsii",
          },
          {
            value: "Diagnosis/651037",
            label: "A77.2 - Spotted fever due to Rickettsia siberica",
          },
          {
            value: "Diagnosis/651044",
            label: "A77.9 - Spotted fever, unspecified",
          },
          {
            value: "Diagnosis/651135",
            label: "A95.0 - Sylvatic yellow fever",
          },
          {
            value: "Diagnosis/651002",
            label: "A68.1 - Tick-borne relapsing fever",
          },
          {
            value: "Diagnosis/651047",
            label: "A79.0 - Trench fever",
          },
          {
            value: "Diagnosis/650457",
            label: "A01 - Typhoid and paratyphoid fevers",
          },
          {
            value: "Diagnosis/650458",
            label: "A01.0 - Typhoid fever",
          },
          {
            value: "Diagnosis/650461",
            label: "A01.02 - Typhoid fever with heart involvement",
          },
          {
            value: "Diagnosis/650465",
            label: "A01.09 - Typhoid fever with other complications",
          },
          {
            value: "Diagnosis/650459",
            label: "A01.00 - Typhoid fever, unspecified",
          },
          {
            value: "Diagnosis/651027",
            label: "A75 - Typhus fever",
          },
          {
            value: "Diagnosis/651031",
            label: "A75.3 - Typhus fever due to Rickettsia tsutsugamushi",
          },
          {
            value: "Diagnosis/651030",
            label: "A75.2 - Typhus fever due to Rickettsia typhi",
          },
          {
            value: "Diagnosis/651032",
            label: "A75.9 - Typhus fever, unspecified",
          },
          {
            value: "Diagnosis/651133",
            label: "A94 - Unspecified arthropod-borne viral fever",
          },
          {
            value: "Diagnosis/651152",
            label: "A99 - Unspecified viral hemorrhagic fever",
          },
          {
            value: "Diagnosis/651136",
            label: "A95.1 - Urban yellow fever",
          },
          {
            value: "Diagnosis/651118",
            label: "A92.2 - Venezuelan equine fever",
          },
          {
            value: "Diagnosis/651134",
            label: "A95 - Yellow fever",
          },
          {
            value: "Diagnosis/651137",
            label: "A95.9 - Yellow fever, unspecified",
          },
        ],
        use: [
          {
            value: "CodingMaster/10665",
            label: "Admission",
          },
          {
            value: "CodingMaster/10666",
            label: "Billing",
          },
          {
            value: "CodingMaster/10667",
            label: "Discharge",
          },
        ],
        verificationStatus: [
          {
            value: "CodingMaster/10668",
            label: "Confirmed",
          },
          {
            value: "CodingMaster/10669",
            label: "Entered-in-error",
          },
          {
            value: "CodingMaster/10670",
            label: "Refirmed",
          },
          {
            value: "CodingMaster/10671",
            label: "Unconfirmed",
          },
        ],
        duration: [
          {
            value: "CodingMaster/11058",
            label: "Days",
          },
          {
            value: "CodingMaster/11059",
            label: "Week",
          },
          {
            value: "CodingMaster/11060",
            label: "Month",
          },
          {
            value: "CodingMaster/11061",
            label: "Year",
          },
        ],
      },
    ],
    LaboratoryOrder: [
      {
        serviceCodeDescription: [
          {
            value: "OrderCatalog/10795",
            label: "A/G Ratio",
          },
          {
            value: "OrderCatalog/10748",
            label: "Albumin",
          },
          {
            value: "OrderCatalog/10751",
            label: "Alkaline Phosphatase(ALP)",
          },
          {
            value: "OrderCatalog/10750",
            label: "ALT(SGPT)",
          },
          {
            value: "OrderCatalog/10801",
            label: "Anti-HBs",
          },
          {
            value: "OrderCatalog/10802",
            label: "Anti-HCV",
          },
          {
            value: "OrderCatalog/10749",
            label: "AST(SGOT)",
          },
          {
            value: "OrderCatalog/10796",
            label: "Bilirubin",
          },
          {
            value: "OrderCatalog/10720",
            label: "Blood Glucose",
          },
          {
            value: "OrderCatalog/10718",
            label: "Creatinine",
          },
          {
            value: "OrderCatalog/10734",
            label: "DIALYSIS ADEQUANCY",
          },
          {
            value: "OrderCatalog/10713",
            label: "ELECTROLYTES & BLOOD GLUCOSE",
          },
          {
            value: "OrderCatalog/10732",
            label: "ESR (Westergren)",
          },
          {
            value: "OrderCatalog/10708",
            label: "Full Blood Picture",
          },
          {
            value: "OrderCatalog/10797",
            label: "Gamma GT",
          },
          {
            value: "OrderCatalog/10794",
            label: "Globulin",
          },
          {
            value: "OrderCatalog/10728",
            label: "HAEMATOLOGY",
          },
          {
            value: "OrderCatalog/10730",
            label: "Haemotocrit(PCV)",
          },
          {
            value: "OrderCatalog/10729",
            label: "HB Level",
          },
          {
            value: "OrderCatalog/10706",
            label: "HbA1c",
          },
          {
            value: "OrderCatalog/10800",
            label: "HBsAg",
          },
          {
            value: "OrderCatalog/10742",
            label: "Hepatitis Bs Ab",
          },
          {
            value: "OrderCatalog/10741",
            label: "Hepatitis Bs Ag",
          },
          {
            value: "OrderCatalog/10743",
            label: "Hepatitis C Antibody",
          },
          {
            value: "OrderCatalog/10799",
            label: "HIV Ag/Ab",
          },
          {
            value: "OrderCatalog/10744",
            label: "HIV I and II Antibody(ELISA)",
          },
          {
            value: "OrderCatalog/10805",
            label: "IPTH",
          },
          {
            value: "OrderCatalog/10711",
            label: "IRON PROFILE",
          },
          {
            value: "OrderCatalog/10709",
            label: "LIPIDS STUDIES",
          },
          {
            value: "OrderCatalog/10746",
            label: "LIVER FUNCTION TEST",
          },
          {
            value: "OrderCatalog/10798",
            label: "MULTIPLE BIOCHEMISTRY PROFILE",
          },
          {
            value: "OrderCatalog/10788",
            label: "NRR-1CV",
          },
          {
            value: "OrderCatalog/10806",
            label: "NRR-2CV",
          },
          {
            value: "OrderCatalog/10705",
            label: "NRR-3CV",
          },
          {
            value: "OrderCatalog/10704",
            label: "Package 4 - B.P Clinical Lab Sdn Bh",
          },
          {
            value: "OrderCatalog/10803",
            label: "Parathyroid Hormone",
          },
          {
            value: "OrderCatalog/10731",
            label: "Platelet Count",
          },
          {
            value: "OrderCatalog/10715",
            label: "Potassium",
          },
          {
            value: "OrderCatalog/10733",
            label: "RENAL BONE METABOLISM",
          },
          {
            value: "OrderCatalog/10804",
            label: "RPR",
          },
          {
            value: "OrderCatalog/10789",
            label: "RTK",
          },
          {
            value: "OrderCatalog/10714",
            label: "Sodium",
          },
          {
            value: "OrderCatalog/10747",
            label: "Total Protein",
          },
          {
            value: "OrderCatalog/10707",
            label: "Urea Post",
          },
          {
            value: "OrderCatalog/10735",
            label: "Urea Pre",
          },
          {
            value: "OrderCatalog/10719",
            label: "Uric Acid",
          },
          {
            value: "OrderCatalog/10745",
            label: "VDRL",
          },
          {
            value: "OrderCatalog/10740",
            label: "VIRAL STUDIES",
          },
          {
            value: "OrderCatalog/10712",
            label: "Virology Screening",
          },
          {
            value: "OrderCatalog/10738",
            label: "WBC",
          },
        ],
        priority: [
          {
            value: "CodingMaster/10689",
            label: "Routine",
          },
          {
            value: "CodingMaster/10690",
            label: "Stat",
          },
          {
            value: "CodingMaster/10691",
            label: "Urgent",
          },
        ],
        nature: [
          {
            value: "CodingMaster/10621",
            label: "Discharged",
          },
          {
            value: "CodingMaster/11666",
            label: "Treatment",
          },
        ],
        frequency: [
          {
            value: "CodingMaster/11058",
            label: "Days",
          },
          {
            value: "CodingMaster/11059",
            label: "Week",
          },
          {
            value: "CodingMaster/11060",
            label: "Month",
          },
          {
            value: "CodingMaster/11061",
            label: "Year",
          },
        ],
        frequencyDuration: [],
      },
    ],
    RadiologyOrder: [
      {
        serviceCode: [
          {
            value: "OrderCatalog/10713",
            label: "FDSB-Lab-0021",
          },
          {
            value: "OrderCatalog/10714",
            label: "FDSB-Lab-0041",
          },
          {
            value: "OrderCatalog/10715",
            label: "FDSB-Lab-0014",
          },
          {
            value: "OrderCatalog/10718",
            label: "FDSB-Lab-0042",
          },
          {
            value: "OrderCatalog/10719",
            label: "FDSB-Lab-0043",
          },
          {
            value: "OrderCatalog/10728",
            label: "FDSB-Lab-0022",
          },
          {
            value: "OrderCatalog/10729",
            label: "FDSB-Lab-0011",
          },
          {
            value: "OrderCatalog/10734",
            label: "FDSB-Lab-0018",
          },
          {
            value: "OrderCatalog/10735",
            label: "FDSB-Lab-0015",
          },
          {
            value: "OrderCatalog/10738",
            label: "FDSB-Lab-0012",
          },
          {
            value: "OrderCatalog/10741",
            label: "FDSB-Lab-0025",
          },
          {
            value: "OrderCatalog/10742",
            label: "FDSB-Lab-0026",
          },
          {
            value: "OrderCatalog/10743",
            label: "FDSB-Lab-0027",
          },
          {
            value: "OrderCatalog/10744",
            label: "FDSB-Lab-0028",
          },
          {
            value: "OrderCatalog/10745",
            label: "FDSB-Lab-0029",
          },
          {
            value: "OrderCatalog/10747",
            label: "FDSB-Lab-0032",
          },
          {
            value: "OrderCatalog/10748",
            label: "FDSB-Lab-0033",
          },
          {
            value: "OrderCatalog/10749",
            label: "FDSB-Lab-0034",
          },
          {
            value: "OrderCatalog/10750",
            label: "FDSB-Lab-0035",
          },
          {
            value: "OrderCatalog/10751",
            label: "FDSB-Lab-0036",
          },
          {
            value: "OrderCatalog/10704",
            label: "FDSB-LAB-0001",
          },
          {
            value: "OrderCatalog/10789",
            label: "FDSB-Lab-0050",
          },
          {
            value: "OrderCatalog/10791",
            label: "FDSB-PACK-0003",
          },
          {
            value: "OrderCatalog/10792",
            label: "FDSB-PACK-0004",
          },
          {
            value: "OrderCatalog/10793",
            label: "FDSB-PACK-0005",
          },
          {
            value: "OrderCatalog/10773",
            label: "FDSB-PACK-0001",
          },
          {
            value: "OrderCatalog/10706",
            label: "FDSB-Lab-0045",
          },
          {
            value: "OrderCatalog/10707",
            label: "FDSB-Lab-0016",
          },
          {
            value: "OrderCatalog/10709",
            label: "FDSB-Lab-0020",
          },
          {
            value: "OrderCatalog/10794",
            label: "FDSB-Lab-0051",
          },
          {
            value: "OrderCatalog/10795",
            label: "FDSB-Lab-0052",
          },
          {
            value: "OrderCatalog/10796",
            label: "FDSB-Lab-0053",
          },
          {
            value: "OrderCatalog/10797",
            label: "FDSB-Lab-0054",
          },
          {
            value: "OrderCatalog/10711",
            label: "FDSB-Lab-0023",
          },
          {
            value: "OrderCatalog/10799",
            label: "FDSB-Lab-0056",
          },
          {
            value: "OrderCatalog/10800",
            label: "FDSB-Lab-0057",
          },
          {
            value: "OrderCatalog/10801",
            label: "FDSB-Lab-0058",
          },
          {
            value: "OrderCatalog/10802",
            label: "FDSB-Lab-0059",
          },
          {
            value: "OrderCatalog/10740",
            label: "FDSB-Lab-0017",
          },
          {
            value: "OrderCatalog/10805",
            label: "FDSB-Lab-0062",
          },
          {
            value: "OrderCatalog/10720",
            label: "FDSB-Lab-0044",
          },
          {
            value: "OrderCatalog/10788",
            label: "FDSB-Lab-0049",
          },
          {
            value: "OrderCatalog/10733",
            label: "FDSB-Lab-0024",
          },
          {
            value: "OrderCatalog/10806",
            label: "FDSB-LAB-0063",
          },
          {
            value: "OrderCatalog/10732",
            label: "FDSB-Lab-0048",
          },
          {
            value: "OrderCatalog/10705",
            label: "FDSB-LAB-0002",
          },
          {
            value: "OrderCatalog/10803",
            label: "FDSB-Lab-0060",
          },
          {
            value: "OrderCatalog/10804",
            label: "FDSB-Lab-0061",
          },
          {
            value: "OrderCatalog/10798",
            label: "FDSB-Lab-0055",
          },
          {
            value: "OrderCatalog/10730",
            label: "FDSB-Lab-0046",
          },
          {
            value: "OrderCatalog/10731",
            label: "FDSB-Lab-0047",
          },
          {
            value: "OrderCatalog/10746",
            label: "FDSB-Lab-0019",
          },
          {
            value: "OrderCatalog/10712",
            label: "FDSB-Lab-0013",
          },
          {
            value: "OrderCatalog/10708",
            label: "FDSB-Lab-0010",
          },
          {
            value: "OrderCatalog/10790",
            label: "FDSB-PACK-0002",
          },
          {
            value: "OrderCatalog/10929",
            label: "FDSB-PACK-0006",
          },
        ],
        serviceDescription: [
          {
            value: "OrderCatalog/10795",
            label: "A/G Ratio",
          },
          {
            value: "OrderCatalog/10748",
            label: "Albumin",
          },
          {
            value: "OrderCatalog/10751",
            label: "Alkaline Phosphatase(ALP)",
          },
          {
            value: "OrderCatalog/10750",
            label: "ALT(SGPT)",
          },
          {
            value: "OrderCatalog/10801",
            label: "Anti-HBs",
          },
          {
            value: "OrderCatalog/10802",
            label: "Anti-HCV",
          },
          {
            value: "OrderCatalog/10749",
            label: "AST(SGOT)",
          },
          {
            value: "OrderCatalog/10796",
            label: "Bilirubin",
          },
          {
            value: "OrderCatalog/10720",
            label: "Blood Glucose",
          },
          {
            value: "OrderCatalog/10718",
            label: "Creatinine",
          },
          {
            value: "OrderCatalog/10734",
            label: "DIALYSIS ADEQUANCY",
          },
          {
            value: "OrderCatalog/10713",
            label: "ELECTROLYTES & BLOOD GLUCOSE",
          },
          {
            value: "OrderCatalog/10732",
            label: "ESR (Westergren)",
          },
          {
            value: "OrderCatalog/10708",
            label: "Full Blood Picture",
          },
          {
            value: "OrderCatalog/10797",
            label: "Gamma GT",
          },
          {
            value: "OrderCatalog/10794",
            label: "Globulin",
          },
          {
            value: "OrderCatalog/10728",
            label: "HAEMATOLOGY",
          },
          {
            value: "OrderCatalog/10730",
            label: "Haemotocrit(PCV)",
          },
          {
            value: "OrderCatalog/10729",
            label: "HB Level",
          },
          {
            value: "OrderCatalog/10706",
            label: "HbA1c",
          },
          {
            value: "OrderCatalog/10800",
            label: "HBsAg",
          },
          {
            value: "OrderCatalog/10742",
            label: "Hepatitis Bs Ab",
          },
          {
            value: "OrderCatalog/10741",
            label: "Hepatitis Bs Ag",
          },
          {
            value: "OrderCatalog/10743",
            label: "Hepatitis C Antibody",
          },
          {
            value: "OrderCatalog/10799",
            label: "HIV Ag/Ab",
          },
          {
            value: "OrderCatalog/10744",
            label: "HIV I and II Antibody(ELISA)",
          },
          {
            value: "OrderCatalog/10805",
            label: "IPTH",
          },
          {
            value: "OrderCatalog/10711",
            label: "IRON PROFILE",
          },
          {
            value: "OrderCatalog/10709",
            label: "LIPIDS STUDIES",
          },
          {
            value: "OrderCatalog/10746",
            label: "LIVER FUNCTION TEST",
          },
          {
            value: "OrderCatalog/10798",
            label: "MULTIPLE BIOCHEMISTRY PROFILE",
          },
          {
            value: "OrderCatalog/10788",
            label: "NRR-1CV",
          },
          {
            value: "OrderCatalog/10806",
            label: "NRR-2CV",
          },
          {
            value: "OrderCatalog/10705",
            label: "NRR-3CV",
          },
          {
            value: "OrderCatalog/10704",
            label: "Package 4 - B.P Clinical Lab Sdn Bh",
          },
          {
            value: "OrderCatalog/10803",
            label: "Parathyroid Hormone",
          },
          {
            value: "OrderCatalog/10731",
            label: "Platelet Count",
          },
          {
            value: "OrderCatalog/10715",
            label: "Potassium",
          },
          {
            value: "OrderCatalog/10733",
            label: "RENAL BONE METABOLISM",
          },
          {
            value: "OrderCatalog/10804",
            label: "RPR",
          },
          {
            value: "OrderCatalog/10789",
            label: "RTK",
          },
          {
            value: "OrderCatalog/10714",
            label: "Sodium",
          },
          {
            value: "OrderCatalog/10747",
            label: "Total Protein",
          },
          {
            value: "OrderCatalog/10707",
            label: "Urea Post",
          },
          {
            value: "OrderCatalog/10735",
            label: "Urea Pre",
          },
          {
            value: "OrderCatalog/10719",
            label: "Uric Acid",
          },
          {
            value: "OrderCatalog/10745",
            label: "VDRL",
          },
          {
            value: "OrderCatalog/10740",
            label: "VIRAL STUDIES",
          },
          {
            value: "OrderCatalog/10712",
            label: "Virology Screening",
          },
          {
            value: "OrderCatalog/10738",
            label: "WBC",
          },
        ],
        priority: [
          {
            value: "CodingMaster/10197",
            label: "High",
          },
          {
            value: "CodingMaster/10240",
            label: "Low",
          },
          {
            value: "CodingMaster/10243",
            label: "Medium",
          },
        ],
        frequency: [
          {
            value: "CodingMaster/11058",
            label: "Days",
          },
          {
            value: "CodingMaster/11059",
            label: "Week",
          },
          {
            value: "CodingMaster/11060",
            label: "Month",
          },
          {
            value: "CodingMaster/11061",
            label: "Year",
          },
        ],
        frequencyOccurrence: [],
        status: [
          {
            value: "CodingMaster/11630",
            label: "Nr Triage completed",
          },
          {
            value: "CodingMaster/11631",
            label: "Dr Consult in Progress",
          },
          {
            value: "CodingMaster/11635",
            label: "Dr Checked IN",
          },
          {
            value: "CodingMaster/10724",
            label: "Cancelled",
          },
          {
            value: "CodingMaster/11773",
            label: "Nr Triage checkin",
          },
          {
            value: "CodingMaster/11774",
            label: "Close consult  + bill settled",
          },
          {
            value: "CodingMaster/11775",
            label: "Nr  Triage WIP",
          },
          {
            value: "CodingMaster/11776",
            label: "Dr Consult Over",
          },
          {
            value: "CodingMaster/11777",
            label: "Arrived",
          },
          {
            value: "CodingMaster/12680",
            label: "closed",
          },
          {
            value: "CodingMaster/10727",
            label: "In-Progress",
          },
        ],
      },
    ],
    OtherInvestigationOrders: [
      {
        serviceCode: [
          {
            value: "OrderCatalog/10713",
            label: "FDSB-Lab-0021",
          },
          {
            value: "OrderCatalog/10714",
            label: "FDSB-Lab-0041",
          },
          {
            value: "OrderCatalog/10715",
            label: "FDSB-Lab-0014",
          },
          {
            value: "OrderCatalog/10718",
            label: "FDSB-Lab-0042",
          },
          {
            value: "OrderCatalog/10719",
            label: "FDSB-Lab-0043",
          },
          {
            value: "OrderCatalog/10728",
            label: "FDSB-Lab-0022",
          },
          {
            value: "OrderCatalog/10729",
            label: "FDSB-Lab-0011",
          },
          {
            value: "OrderCatalog/10734",
            label: "FDSB-Lab-0018",
          },
          {
            value: "OrderCatalog/10735",
            label: "FDSB-Lab-0015",
          },
          {
            value: "OrderCatalog/10738",
            label: "FDSB-Lab-0012",
          },
          {
            value: "OrderCatalog/10741",
            label: "FDSB-Lab-0025",
          },
          {
            value: "OrderCatalog/10742",
            label: "FDSB-Lab-0026",
          },
          {
            value: "OrderCatalog/10743",
            label: "FDSB-Lab-0027",
          },
          {
            value: "OrderCatalog/10744",
            label: "FDSB-Lab-0028",
          },
          {
            value: "OrderCatalog/10745",
            label: "FDSB-Lab-0029",
          },
          {
            value: "OrderCatalog/10747",
            label: "FDSB-Lab-0032",
          },
          {
            value: "OrderCatalog/10748",
            label: "FDSB-Lab-0033",
          },
          {
            value: "OrderCatalog/10749",
            label: "FDSB-Lab-0034",
          },
          {
            value: "OrderCatalog/10750",
            label: "FDSB-Lab-0035",
          },
          {
            value: "OrderCatalog/10751",
            label: "FDSB-Lab-0036",
          },
          {
            value: "OrderCatalog/10704",
            label: "FDSB-LAB-0001",
          },
          {
            value: "OrderCatalog/10789",
            label: "FDSB-Lab-0050",
          },
          {
            value: "OrderCatalog/10791",
            label: "FDSB-PACK-0003",
          },
          {
            value: "OrderCatalog/10792",
            label: "FDSB-PACK-0004",
          },
          {
            value: "OrderCatalog/10793",
            label: "FDSB-PACK-0005",
          },
          {
            value: "OrderCatalog/10773",
            label: "FDSB-PACK-0001",
          },
          {
            value: "OrderCatalog/10706",
            label: "FDSB-Lab-0045",
          },
          {
            value: "OrderCatalog/10707",
            label: "FDSB-Lab-0016",
          },
          {
            value: "OrderCatalog/10709",
            label: "FDSB-Lab-0020",
          },
          {
            value: "OrderCatalog/10794",
            label: "FDSB-Lab-0051",
          },
          {
            value: "OrderCatalog/10795",
            label: "FDSB-Lab-0052",
          },
          {
            value: "OrderCatalog/10796",
            label: "FDSB-Lab-0053",
          },
          {
            value: "OrderCatalog/10797",
            label: "FDSB-Lab-0054",
          },
          {
            value: "OrderCatalog/10711",
            label: "FDSB-Lab-0023",
          },
          {
            value: "OrderCatalog/10799",
            label: "FDSB-Lab-0056",
          },
          {
            value: "OrderCatalog/10800",
            label: "FDSB-Lab-0057",
          },
          {
            value: "OrderCatalog/10801",
            label: "FDSB-Lab-0058",
          },
          {
            value: "OrderCatalog/10802",
            label: "FDSB-Lab-0059",
          },
          {
            value: "OrderCatalog/10740",
            label: "FDSB-Lab-0017",
          },
          {
            value: "OrderCatalog/10805",
            label: "FDSB-Lab-0062",
          },
          {
            value: "OrderCatalog/10720",
            label: "FDSB-Lab-0044",
          },
          {
            value: "OrderCatalog/10788",
            label: "FDSB-Lab-0049",
          },
          {
            value: "OrderCatalog/10733",
            label: "FDSB-Lab-0024",
          },
          {
            value: "OrderCatalog/10806",
            label: "FDSB-LAB-0063",
          },
          {
            value: "OrderCatalog/10732",
            label: "FDSB-Lab-0048",
          },
          {
            value: "OrderCatalog/10705",
            label: "FDSB-LAB-0002",
          },
          {
            value: "OrderCatalog/10803",
            label: "FDSB-Lab-0060",
          },
          {
            value: "OrderCatalog/10804",
            label: "FDSB-Lab-0061",
          },
          {
            value: "OrderCatalog/10798",
            label: "FDSB-Lab-0055",
          },
          {
            value: "OrderCatalog/10730",
            label: "FDSB-Lab-0046",
          },
          {
            value: "OrderCatalog/10731",
            label: "FDSB-Lab-0047",
          },
          {
            value: "OrderCatalog/10746",
            label: "FDSB-Lab-0019",
          },
          {
            value: "OrderCatalog/10712",
            label: "FDSB-Lab-0013",
          },
          {
            value: "OrderCatalog/10708",
            label: "FDSB-Lab-0010",
          },
          {
            value: "OrderCatalog/10790",
            label: "FDSB-PACK-0002",
          },
          {
            value: "OrderCatalog/10929",
            label: "FDSB-PACK-0006",
          },
        ],
        serviceDescription: [
          {
            value: "OrderCatalog/10795",
            label: "A/G Ratio",
          },
          {
            value: "OrderCatalog/10748",
            label: "Albumin",
          },
          {
            value: "OrderCatalog/10751",
            label: "Alkaline Phosphatase(ALP)",
          },
          {
            value: "OrderCatalog/10750",
            label: "ALT(SGPT)",
          },
          {
            value: "OrderCatalog/10801",
            label: "Anti-HBs",
          },
          {
            value: "OrderCatalog/10802",
            label: "Anti-HCV",
          },
          {
            value: "OrderCatalog/10749",
            label: "AST(SGOT)",
          },
          {
            value: "OrderCatalog/10796",
            label: "Bilirubin",
          },
          {
            value: "OrderCatalog/10720",
            label: "Blood Glucose",
          },
          {
            value: "OrderCatalog/10718",
            label: "Creatinine",
          },
          {
            value: "OrderCatalog/10734",
            label: "DIALYSIS ADEQUANCY",
          },
          {
            value: "OrderCatalog/10713",
            label: "ELECTROLYTES & BLOOD GLUCOSE",
          },
          {
            value: "OrderCatalog/10732",
            label: "ESR (Westergren)",
          },
          {
            value: "OrderCatalog/10708",
            label: "Full Blood Picture",
          },
          {
            value: "OrderCatalog/10797",
            label: "Gamma GT",
          },
          {
            value: "OrderCatalog/10794",
            label: "Globulin",
          },
          {
            value: "OrderCatalog/10728",
            label: "HAEMATOLOGY",
          },
          {
            value: "OrderCatalog/10730",
            label: "Haemotocrit(PCV)",
          },
          {
            value: "OrderCatalog/10729",
            label: "HB Level",
          },
          {
            value: "OrderCatalog/10706",
            label: "HbA1c",
          },
          {
            value: "OrderCatalog/10800",
            label: "HBsAg",
          },
          {
            value: "OrderCatalog/10742",
            label: "Hepatitis Bs Ab",
          },
          {
            value: "OrderCatalog/10741",
            label: "Hepatitis Bs Ag",
          },
          {
            value: "OrderCatalog/10743",
            label: "Hepatitis C Antibody",
          },
          {
            value: "OrderCatalog/10799",
            label: "HIV Ag/Ab",
          },
          {
            value: "OrderCatalog/10744",
            label: "HIV I and II Antibody(ELISA)",
          },
          {
            value: "OrderCatalog/10805",
            label: "IPTH",
          },
          {
            value: "OrderCatalog/10711",
            label: "IRON PROFILE",
          },
          {
            value: "OrderCatalog/10709",
            label: "LIPIDS STUDIES",
          },
          {
            value: "OrderCatalog/10746",
            label: "LIVER FUNCTION TEST",
          },
          {
            value: "OrderCatalog/10798",
            label: "MULTIPLE BIOCHEMISTRY PROFILE",
          },
          {
            value: "OrderCatalog/10788",
            label: "NRR-1CV",
          },
          {
            value: "OrderCatalog/10806",
            label: "NRR-2CV",
          },
          {
            value: "OrderCatalog/10705",
            label: "NRR-3CV",
          },
          {
            value: "OrderCatalog/10704",
            label: "Package 4 - B.P Clinical Lab Sdn Bh",
          },
          {
            value: "OrderCatalog/10803",
            label: "Parathyroid Hormone",
          },
          {
            value: "OrderCatalog/10731",
            label: "Platelet Count",
          },
          {
            value: "OrderCatalog/10715",
            label: "Potassium",
          },
          {
            value: "OrderCatalog/10733",
            label: "RENAL BONE METABOLISM",
          },
          {
            value: "OrderCatalog/10804",
            label: "RPR",
          },
          {
            value: "OrderCatalog/10789",
            label: "RTK",
          },
          {
            value: "OrderCatalog/10714",
            label: "Sodium",
          },
          {
            value: "OrderCatalog/10747",
            label: "Total Protein",
          },
          {
            value: "OrderCatalog/10707",
            label: "Urea Post",
          },
          {
            value: "OrderCatalog/10735",
            label: "Urea Pre",
          },
          {
            value: "OrderCatalog/10719",
            label: "Uric Acid",
          },
          {
            value: "OrderCatalog/10745",
            label: "VDRL",
          },
          {
            value: "OrderCatalog/10740",
            label: "VIRAL STUDIES",
          },
          {
            value: "OrderCatalog/10712",
            label: "Virology Screening",
          },
          {
            value: "OrderCatalog/10738",
            label: "WBC",
          },
        ],
        priority: [
          {
            value: "CodingMaster/10689",
            label: "Routine",
          },
          {
            value: "CodingMaster/10690",
            label: "Stat",
          },
          {
            value: "CodingMaster/10691",
            label: "Urgent",
          },
        ],
        nature: [
          {
            value: "CodingMaster/10621",
            label: "Discharged",
          },
          {
            value: "CodingMaster/11666",
            label: "Treatment",
          },
        ],
        orderQty: [],
        frequency: [
          {
            value: "CodingMaster/11058",
            label: "Days",
          },
          {
            value: "CodingMaster/11059",
            label: "Week",
          },
          {
            value: "CodingMaster/11060",
            label: "Month",
          },
          {
            value: "CodingMaster/11061",
            label: "Year",
          },
        ],
        frequencyDuration: [],
      },
    ],
    MedicationOrder: [
      {
        drugCode: [
          {
            value: "DrugMaster/143210892",
            label: "57765",
          },
          {
            value: "DrugMaster/143211294",
            label: "48958",
          },
          {
            value: "DrugMaster/143211855",
            label: "3806",
          },
          {
            value: "DrugMaster/143212032",
            label: "46217",
          },
          {
            value: "DrugMaster/143211833",
            label: "69770",
          },
          {
            value: "DrugMaster/143209967",
            label: "80668",
          },
          {
            value: "DrugMaster/143209962",
            label: "70726",
          },
          {
            value: "DrugMaster/143211011",
            label: "26886",
          },
          {
            value: "DrugMaster/143211243",
            label: "2858",
          },
          {
            value: "DrugMaster/143210407",
            label: "48508",
          },
        ],
        drugDescription: [
          {
            value: "DrugMaster/143210892",
            label:
              "ACTIVATED CHARCOAL ORAL 50 gram/240 mL SUSPENSION, ORAL (FINAL DOSE FORM)",
          },
          {
            value: "DrugMaster/143211294",
            label: "CASCARA SAGRADA ORAL 1,000 mg/mL FLUID EXTRACT",
          },
          {
            value: "DrugMaster/143211855",
            label: "PROMAZINE HCL ORAL 30 mg/mL CONCENTRATE, ORAL",
          },
          {
            value: "DrugMaster/143212032",
            label: "FLUOXETINE HCL ORAL 20 mg/5 mL (4 mg/mL) SOLUTION, ORAL",
          },
          {
            value: "DrugMaster/143211833",
            label: "MIDAZOLAM HCL ORAL 10 mg/5 mL SYRINGE (ML)",
          },
          {
            value: "DrugMaster/143209967",
            label: "FERROUS SULFATE ORAL 7.5 mg iron/0.5 mL SYRINGE (EA)",
          },
          {
            value: "DrugMaster/143209962",
            label:
              "FERROUS SULFATE ORAL 220 mg (44 mg iron)/5 mL (5 mL) ELIXIR",
          },
          {
            value: "DrugMaster/143211011",
            label:
              "CALCIUM CARBONATE ORAL 400 mg/5 mL SUSPENSION, ORAL (FINAL DOSE FORM)",
          },
          {
            value: "DrugMaster/143211243",
            label:
              "BISMUTH SUBSALICYLATE ORAL 262 mg/15 mL SUSPENSION, ORAL (FINAL DOSE FORM)",
          },
          {
            value: "DrugMaster/143210407",
            label: "ASCORBIC ACID ORAL 500 mg/15 mL SYRUP",
          },
        ],
        orderDose: [
          {
            value: "DrugMaster/143210892",
            label: "50mL",
          },
          {
            value: "DrugMaster/143211294",
            label: "1000mL",
          },
          {
            value: "DrugMaster/143211855",
            label: "30mL",
          },
          {
            value: "DrugMaster/143212032",
            label: "20mL",
          },
          {
            value: "DrugMaster/143211833",
            label: "10mL",
          },
          {
            value: "DrugMaster/143209967",
            label: "7.5mL",
          },
          {
            value: "DrugMaster/143209962",
            label: "220mL",
          },
          {
            value: "DrugMaster/143211011",
            label: "400mL",
          },
          {
            value: "DrugMaster/143211243",
            label: "262mL",
          },
          {
            value: "DrugMaster/143210407",
            label: "500mL",
          },
        ],
        orderDoseUom: [
          {
            value: "CodingMaster/11977",
            label: "Tablet",
          },
          {
            value: "CodingMaster/11978",
            label: "ML",
          },
          {
            value: "CodingMaster/11979",
            label: "MG",
          },
          {
            value: "CodingMaster/11980",
            label: "Sachet",
          },
          {
            value: "CodingMaster/11981",
            label: "Apply",
          },
          {
            value: "CodingMaster/11982",
            label: "Drop",
          },
          {
            value: "CodingMaster/11987",
            label: "Capsule",
          },
          {
            value: "CodingMaster/11988",
            label: "Puff",
          },
          {
            value: "CodingMaster/11989",
            label: "Vial",
          },
          {
            value: "CodingMaster/11990",
            label: "Ampoule",
          },
          {
            value: "CodingMaster/11991",
            label: "Kit",
          },
          {
            value: "CodingMaster/11992",
            label: "Tube",
          },
        ],
        doseFrequency: [
          {
            value: "FrequencyMaster/10469",
            label: "FRQ0030-Frequency Name*",
          },
          {
            value: "FrequencyMaster/10163",
            label: "C1-c1",
          },
          {
            value: "FrequencyMaster/10468",
            label: "UDEF-User Defined",
          },
          {
            value: "FrequencyMaster/10459",
            label: "FRQ0022-Frequency Name",
          },
          {
            value: "FrequencyMaster/10491",
            label: "CODE0025-name data ",
          },
          {
            value: "FrequencyMaster/10381",
            label: "PRN5-when necessary",
          },
          {
            value: "FrequencyMaster/10442",
            label: "A0017-Frequency Name",
          },
          {
            value: "FrequencyMaster/10458",
            label: "FRQ0021-Frequency Name*",
          },
          {
            value: "FrequencyMaster/10204",
            label: "Q10H-Every Ten hours",
          },
          {
            value: "FrequencyMaster/10511",
            label: "CODE0035-Frequency Name *",
          },
          {
            value: "FrequencyMaster/10499",
            label: "SDFVVVVV-3asdasdasd",
          },
          {
            value: "FrequencyMaster/10449",
            label: "FRQ22-Frequency Name*",
          },
          {
            value: "FrequencyMaster/10451",
            label: "FRQ23-Frequency Name*",
          },
          {
            value: "FrequencyMaster/10447",
            label: "FRQ0011-Frequency Name*",
          },
          {
            value: "FrequencyMaster/10443",
            label: "FRQ0001-Frequency Name",
          },
          {
            value: "FrequencyMaster/10441",
            label: "A0016-data name",
          },
          {
            value: "FrequencyMaster/10430",
            label: "A001-name char",
          },
          {
            value: "FrequencyMaster/10440",
            label: "A0015-name char ",
          },
          {
            value: "FrequencyMaster/10439",
            label: "TOKENCHECL-jhhjkjkh",
          },
          {
            value: "FrequencyMaster/10417",
            label: "CRAY-Short ",
          },
          {
            value: "FrequencyMaster/10438",
            label: "A0021-test char",
          },
          {
            value: "FrequencyMaster/10437",
            label: "A0020-char name test",
          },
          {
            value: "FrequencyMaster/10433",
            label: "HEPTAZ-tripo",
          },
          {
            value: "FrequencyMaster/10436",
            label: "STATUS01-name",
          },
          {
            value: "FrequencyMaster/10131",
            label: "CHECK COD2-dfa",
          },
          {
            value: "FrequencyMaster/10414",
            label: "ADAM-test data ",
          },
          {
            value: "FrequencyMaster/10424",
            label: "POPMISSILE-rtfaf",
          },
          {
            value: "FrequencyMaster/10422",
            label: "GBHV-RTGC",
          },
          {
            value: "FrequencyMaster/10420",
            label: "CHECKDE-checkdetails",
          },
          {
            value: "FrequencyMaster/10413",
            label: "TRAP-test data",
          },
          {
            value: "FrequencyMaster/10495",
            label: "CODE0029-code nbame ",
          },
          {
            value: "FrequencyMaster/10219",
            label: "F211-F11 race",
          },
          {
            value: "FrequencyMaster/10094",
            label: "AT6PM-one month only",
          },
          {
            value: "FrequencyMaster/10410",
            label: "TEST07-frqq ",
          },
          {
            value: "FrequencyMaster/10408",
            label: "QD11-dAD",
          },
          {
            value: "FrequencyMaster/10407",
            label: "AFS-FREQUENCY",
          },
          {
            value: "FrequencyMaster/10405",
            label: "Q11HH-ever twelve hours",
          },
          {
            value: "FrequencyMaster/10406",
            label: "DQ-dqdq",
          },
          {
            value: "FrequencyMaster/10400",
            label: "ADF20-user defined name",
          },
          {
            value: "FrequencyMaster/10072",
            label: "Q5D-evey 5h once",
          },
          {
            value: "FrequencyMaster/10393",
            label: "DQD-adqd",
          },
          {
            value: "FrequencyMaster/10155",
            label: "CHECK-cec",
          },
          {
            value: "FrequencyMaster/10388",
            label: "Q12-every 12",
          },
          {
            value: "FrequencyMaster/10287",
            label: "TYRE-adfs",
          },
          {
            value: "FrequencyMaster/10194",
            label: "LOAD3-load3",
          },
          {
            value: "FrequencyMaster/10500",
            label: "VVZXV-rrtrg",
          },
          {
            value: "FrequencyMaster/10448",
            label: "FRQ00112-Frequency Name*",
          },
          {
            value: "FrequencyMaster/10102",
            label: "Q5H-Every five hours",
          },
          {
            value: "FrequencyMaster/10290",
            label: "ASD-as",
          },
          {
            value: "FrequencyMaster/10274",
            label: "CRAYOND009-CRAYOND009",
          },
          {
            value: "FrequencyMaster/10419",
            label: "SEQUE-sequence",
          },
          {
            value: "FrequencyMaster/10277",
            label: "TEST-test",
          },
          {
            value: "FrequencyMaster/10249",
            label: "FRECODE55-FREQUENCY",
          },
          {
            value: "FrequencyMaster/10498",
            label: "CODE0032-name",
          },
          {
            value: "FrequencyMaster/10128",
            label: "WWW-ddd",
          },
          {
            value: "FrequencyMaster/10177",
            label: "22-",
          },
          {
            value: "FrequencyMaster/10074",
            label: "Q6W-evey day once",
          },
          {
            value: "FrequencyMaster/10344",
            label: "FENTANYL -Fentanyl ",
          },
          {
            value: "FrequencyMaster/10109",
            label: "Q@#H-test",
          },
          {
            value: "FrequencyMaster/10285",
            label: "YTRU-fgjh",
          },
          {
            value: "FrequencyMaster/10389",
            label: "FR002-Twice a day",
          },
          {
            value: "FrequencyMaster/10238",
            label: "OOPS24-FRE",
          },
          {
            value: "FrequencyMaster/10395",
            label: "S1242-adwd",
          },
          {
            value: "FrequencyMaster/10505",
            label: "CODE0034-test name",
          },
          {
            value: "FrequencyMaster/10269",
            label: "TELLMA123-tellmakind",
          },
          {
            value: "FrequencyMaster/10427",
            label: "HWRE-msnir",
          },
          {
            value: "FrequencyMaster/10399",
            label: "2EFW-2sw2w",
          },
          {
            value: "FrequencyMaster/10501",
            label: "ASC-Asci",
          },
          {
            value: "FrequencyMaster/10099",
            label: "UDE-User defined",
          },
          {
            value: "FrequencyMaster/10275",
            label: "CRAYOND010-CRAYOND010",
          },
          {
            value: "FrequencyMaster/10126",
            label: "Q34AER2-23r523",
          },
          {
            value: "FrequencyMaster/10506",
            label: "TANYA-tanya nae",
          },
          {
            value: "FrequencyMaster/10267",
            label: "CRAYOND997-CRAYOND997",
          },
          {
            value: "FrequencyMaster/10243",
            label: "READS-red",
          },
          {
            value: "FrequencyMaster/10213",
            label: "OD-Once A Day",
          },
          {
            value: "FrequencyMaster/10387",
            label: "QV-as much as you wish",
          },
          {
            value: "FrequencyMaster/10291",
            label: "ANUJ12345-Anuj123",
          },
          {
            value: "FrequencyMaster/10256",
            label: "Q12H-Every 12 hours",
          },
          {
            value: "FrequencyMaster/10294",
            label: "ALU1240-alu",
          },
          {
            value: "FrequencyMaster/10331",
            label: "NC003-Nc",
          },
          {
            value: "FrequencyMaster/10101",
            label: "QD-Once daily",
          },
          {
            value: "FrequencyMaster/10140",
            label: "34EWK-aeskrjbh32",
          },
          {
            value: "FrequencyMaster/10326",
            label: "BOLUSOTO-FREQUENCY",
          },
          {
            value: "FrequencyMaster/10164",
            label: "TOTAL-total",
          },
          {
            value: "FrequencyMaster/10228",
            label: "TR002-Testing Test",
          },
          {
            value: "FrequencyMaster/10453",
            label: "R2R-fe",
          },
          {
            value: "FrequencyMaster/10514",
            label: "SDFG-gnrwew",
          },
          {
            value: "FrequencyMaster/10479",
            label: "232-WE",
          },
          {
            value: "FrequencyMaster/10064",
            label: "HS-Weekly twise",
          },
          {
            value: "FrequencyMaster/10255",
            label: "FRECODE10-FREQUENCY",
          },
          {
            value: "FrequencyMaster/10358",
            label: "QHS-every night at bedtime",
          },
          {
            value: "FrequencyMaster/10444",
            label: "FRQ0003-Frequency Name",
          },
          {
            value: "FrequencyMaster/10244",
            label: "9873-val12",
          },
          {
            value: "FrequencyMaster/10252",
            label: "FRECODE98-FREQUENCY",
          },
          {
            value: "FrequencyMaster/10121",
            label: "USER DEF-user defined name",
          },
          {
            value: "FrequencyMaster/10248",
            label: "LOADER7-",
          },
          {
            value: "FrequencyMaster/10347",
            label: "CODE-test ",
          },
          {
            value: "FrequencyMaster/10235",
            label: "OOPS10-val1",
          },
          {
            value: "FrequencyMaster/10125",
            label: "134-sdgwerg",
          },
          {
            value: "FrequencyMaster/10272",
            label: "PARA1267-para",
          },
          {
            value: "FrequencyMaster/10184",
            label: "WQEQWEQ-asdfasf",
          },
          {
            value: "FrequencyMaster/10481",
            label: "CODE00101-name code",
          },
          {
            value: "FrequencyMaster/10268",
            label: "ERRE222-Frequency Name",
          },
          {
            value: "FrequencyMaster/10158",
            label: "RAT- rat",
          },
          {
            value: "FrequencyMaster/10134",
            label: "AWR-asdf",
          },
          {
            value: "FrequencyMaster/10316",
            label: "MY123-my",
          },
          {
            value: "FrequencyMaster/10526",
            label: "RTUY-freqrtiy",
          },
          {
            value: "FrequencyMaster/10378",
            label: "Q6HR-Every 6 hr",
          },
          {
            value: "FrequencyMaster/10135",
            label: "VSZDG-srjgb",
          },
          {
            value: "FrequencyMaster/10412",
            label: "RICO-test data ",
          },
          {
            value: "FrequencyMaster/10090",
            label: "DAILY-daily",
          },
          {
            value: "FrequencyMaster/10069",
            label: "Q3W-dayily once",
          },
          {
            value: "FrequencyMaster/10455",
            label: "FRQ0019-Frequency Name*",
          },
          {
            value: "FrequencyMaster/10142",
            label: "76U-jh",
          },
          {
            value: "FrequencyMaster/10117",
            label: "DAY TEST-day test name",
          },
          {
            value: "FrequencyMaster/10504",
            label: "CODE0033-name test",
          },
          {
            value: "FrequencyMaster/10425",
            label: "ROCK-Music",
          },
          {
            value: "FrequencyMaster/10123",
            label: "12ASF-sgefwe",
          },
          {
            value: "FrequencyMaster/10070",
            label: "Q4D-evey month once",
          },
          {
            value: "FrequencyMaster/10118",
            label: "DETAIL-detail name",
          },
          {
            value: "FrequencyMaster/10257",
            label: "TESTTEST-test",
          },
          {
            value: "FrequencyMaster/10502",
            label: "RTUT-Route",
          },
          {
            value: "FrequencyMaster/10089",
            label: "TIDAC-every week for 1 year",
          },
          {
            value: "FrequencyMaster/10496",
            label: "CODE0030-code name ",
          },
          {
            value: "FrequencyMaster/10300",
            label: "IOO12-albuterol sulfate",
          },
          {
            value: "FrequencyMaster/10153",
            label: "NEWTEST-newtest",
          },
          {
            value: "FrequencyMaster/10079",
            label: "31-s",
          },
          {
            value: "FrequencyMaster/10186",
            label: "3-s",
          },
          {
            value: "FrequencyMaster/10470",
            label: "FRQ0031-Frequency Name*",
          },
          {
            value: "FrequencyMaster/10519",
            label: "LOP-LOPP",
          },
          {
            value: "FrequencyMaster/10279",
            label: "TESTNOW-TEST",
          },
          {
            value: "FrequencyMaster/10066",
            label: "PREOP-Before eat",
          },
          {
            value: "FrequencyMaster/10239",
            label: "FRECODE-frename",
          },
          {
            value: "FrequencyMaster/10318",
            label: "MY12345-my12345",
          },
          {
            value: "FrequencyMaster/10461",
            label: "TWD-Twice Daily",
          },
          {
            value: "FrequencyMaster/10512",
            label: "SIFJG-ojrg",
          },
          {
            value: "FrequencyMaster/10270",
            label: "TEMA-ert",
          },
          {
            value: "FrequencyMaster/10178",
            label: "8382-",
          },
          {
            value: "FrequencyMaster/10503",
            label: "TAPER-TAPERING",
          },
          {
            value: "FrequencyMaster/10280",
            label: "QWERTY-QWERTY",
          },
          {
            value: "FrequencyMaster/10098",
            label: "QMW-Every Monday and Wednesday",
          },
          {
            value: "FrequencyMaster/10478",
            label: "CODE0010-Frequency Name*",
          },
          {
            value: "FrequencyMaster/10175",
            label: "QD1-dddd",
          },
          {
            value: "FrequencyMaster/10386",
            label: "TESTING ER-frq name",
          },
          {
            value: "FrequencyMaster/10508",
            label: "BOVON2-BOVONTO",
          },
          {
            value: "FrequencyMaster/10174",
            label: "TEO-te",
          },
          {
            value: "FrequencyMaster/10203",
            label: "Q6H-Every SIX hours",
          },
          {
            value: "FrequencyMaster/10182",
            label: "N3EDIT-n3edit",
          },
          {
            value: "FrequencyMaster/10251",
            label: "FRECODE78-FREQUENCY",
          },
          {
            value: "FrequencyMaster/10080",
            label: "QID-thrice a months",
          },
          {
            value: "FrequencyMaster/10486",
            label: "CODE0020-Frequency Name*",
          },
          {
            value: "FrequencyMaster/10165",
            label: "TEST001-Day Based",
          },
          {
            value: "FrequencyMaster/10490",
            label: "CODE0024-name frq 2",
          },
          {
            value: "FrequencyMaster/10185",
            label: "QD10-Ammu",
          },
          {
            value: "FrequencyMaster/10359",
            label: "SIG-directions",
          },
          {
            value: "FrequencyMaster/10122",
            label: "Q7H-Every 7",
          },
          {
            value: "FrequencyMaster/10475",
            label: "CODE0006-Frequency Name*",
          },
          {
            value: "FrequencyMaster/10520",
            label: "E3D-E3D",
          },
          {
            value: "FrequencyMaster/10497",
            label: "CODE0031-code name",
          },
          {
            value: "FrequencyMaster/10119",
            label: "INTERVAL-interval name",
          },
          {
            value: "FrequencyMaster/10215",
            label: "0101-OD",
          },
          {
            value: "FrequencyMaster/10271",
            label: "ERREDDD-ERRE Name",
          },
          {
            value: "FrequencyMaster/10516",
            label: "PEPSI-pepsi co",
          },
          {
            value: "FrequencyMaster/10472",
            label: "CODE0003-Frequency Name*",
          },
          {
            value: "FrequencyMaster/10428",
            label: "KYFDN-ytsdk",
          },
          {
            value: "FrequencyMaster/10161",
            label: "JJW-dasDas",
          },
          {
            value: "FrequencyMaster/10493",
            label: "CODE0027-hariram.jagannathan@ainqa.com",
          },
          {
            value: "FrequencyMaster/10154",
            label: "FINTEST-finTest",
          },
          {
            value: "FrequencyMaster/10245",
            label: "3FRE-FREQUENCY",
          },
          {
            value: "FrequencyMaster/10148",
            label: "TR11-trread11",
          },
          {
            value: "FrequencyMaster/10100",
            label: "TID-Three times daily",
          },
          {
            value: "FrequencyMaster/10282",
            label: "UYIR-cvzx",
          },
          {
            value: "FrequencyMaster/10189",
            label: "21-name",
          },
          {
            value: "FrequencyMaster/10518",
            label: "FRE_12-asd",
          },
          {
            value: "FrequencyMaster/10372",
            label: "CYCLOBE-Cyclobenzaprine ",
          },
          {
            value: "FrequencyMaster/10471",
            label: "CODE0001-Frequency Name",
          },
          {
            value: "FrequencyMaster/10214",
            label: "09-",
          },
          {
            value: "FrequencyMaster/10362",
            label: "NAPROXEN -Naproxen ",
          },
          {
            value: "FrequencyMaster/10289",
            label: "TRYU-dfh",
          },
          {
            value: "FrequencyMaster/10515",
            label: "CODE00351-skfng",
          },
          {
            value: "FrequencyMaster/10342",
            label: "PORK-TESST",
          },
          {
            value: "FrequencyMaster/10202",
            label: "TEST 22-test 32",
          },
          {
            value: "FrequencyMaster/10385",
            label: "OU-both eyes",
          },
          {
            value: "FrequencyMaster/10482",
            label: "CODE0013-name cide",
          },
          {
            value: "FrequencyMaster/10150",
            label: "BET-bet",
          },
          {
            value: "FrequencyMaster/10529",
            label: "COCA-Coca pepsi",
          },
          {
            value: "FrequencyMaster/10523",
            label: "BIDSD-TWICE A DAY",
          },
          {
            value: "FrequencyMaster/10522",
            label: "BIDS-TWICE A DAY",
          },
          {
            value: "FrequencyMaster/10375",
            label: "WELLBUTRIN-Wellbutrin ",
          },
          {
            value: "FrequencyMaster/10201",
            label: "QWF-",
          },
          {
            value: "FrequencyMaster/10223",
            label: "THY-",
          },
          {
            value: "FrequencyMaster/10116",
            label: "TRQ 5-jus",
          },
          {
            value: "FrequencyMaster/10176",
            label: "PO07-",
          },
          {
            value: "FrequencyMaster/10426",
            label: "RTG123-asdc",
          },
          {
            value: "FrequencyMaster/10488",
            label: "CODE0022-code name",
          },
          {
            value: "FrequencyMaster/10507",
            label: "BOVON-BOVONTO",
          },
          {
            value: "FrequencyMaster/10321",
            label: "FR005-Detail",
          },
          {
            value: "FrequencyMaster/10199",
            label: "LOAD12-load12",
          },
          {
            value: "FrequencyMaster/10111",
            label: "#^&*((-111111111111111111",
          },
          {
            value: "FrequencyMaster/10494",
            label: "CODE0028-admin frq",
          },
          {
            value: "FrequencyMaster/10207",
            label: "Q24H-Every 24 hours",
          },
          {
            value: "FrequencyMaster/10335",
            label: "BRILINTA-brilinta",
          },
          {
            value: "FrequencyMaster/10525",
            label: "FREQCOMP-Freq component",
          },
          {
            value: "FrequencyMaster/10312",
            label: "AMITRIP-amitriptyline tablet",
          },
          {
            value: "FrequencyMaster/10147",
            label: "REDTEST-redTest",
          },
          {
            value: "FrequencyMaster/10212",
            label: "ON-Once a Night",
          },
          {
            value: "FrequencyMaster/10435",
            label: "TESTOOO-test",
          },
          {
            value: "FrequencyMaster/10097",
            label: "A2D-Alternate 2 days",
          },
          {
            value: "FrequencyMaster/10237",
            label: "121-val",
          },
          {
            value: "FrequencyMaster/10398",
            label: "D1W-frename",
          },
          {
            value: "FrequencyMaster/10162",
            label: "FFWW-dasDas",
          },
          {
            value: "FrequencyMaster/10445",
            label: "FRQ0004-Frequency Name*",
          },
          {
            value: "FrequencyMaster/10391",
            label: "D23D2R-frename",
          },
          {
            value: "FrequencyMaster/10467",
            label: "2DO-2 Days Once",
          },
          {
            value: "FrequencyMaster/10396",
            label: "1-val",
          },
          {
            value: "FrequencyMaster/10156",
            label: "TIT-tit",
          },
          {
            value: "FrequencyMaster/10369",
            label: " BID-twice daily",
          },
          {
            value: "FrequencyMaster/10063",
            label: "BOLUS-Everyday",
          },
          {
            value: "FrequencyMaster/10527",
            label: "CVBBN-asddcvczxc",
          },
          {
            value: "FrequencyMaster/10521",
            label: "EV3D-Every 3 Days",
          },
          {
            value: "FrequencyMaster/10197",
            label: "LOAD6-load6",
          },
          {
            value: "FrequencyMaster/10138",
            label: "QQQQ-jsneifh",
          },
          {
            value: "FrequencyMaster/10524",
            label: "BIDDD-TWICE A DAY",
          },
          {
            value: "FrequencyMaster/10073",
            label: "Q6D-evey 12hr once",
          },
          {
            value: "FrequencyMaster/10382",
            label: "TID1-THREE TIMES DAY",
          },
          {
            value: "FrequencyMaster/10394",
            label: "JHJVU-gvhv",
          },
          {
            value: "FrequencyMaster/10462",
            label: "TWDAILY-Twice Daily",
          },
          {
            value: "FrequencyMaster/10132",
            label: "REF123-REF",
          },
          {
            value: "FrequencyMaster/10105",
            label: "FREQUENCY-",
          },
          {
            value: "FrequencyMaster/10106",
            label: "Q4H-Every 4 Hours",
          },
          {
            value: "FrequencyMaster/10484",
            label: "BIDDEV-Base Interval Frequency",
          },
          {
            value: "FrequencyMaster/10136",
            label: "Q3R-zsf g",
          },
          {
            value: "FrequencyMaster/10152",
            label: "TRACK-track",
          },
          {
            value: "FrequencyMaster/10179",
            label: "220-333",
          },
          {
            value: "FrequencyMaster/10434",
            label: "A006-name char example",
          },
          {
            value: "FrequencyMaster/10485",
            label: "CODE00121-Frequency Name*",
          },
          {
            value: "FrequencyMaster/10242",
            label: "FRECODE12-frename",
          },
          {
            value: "FrequencyMaster/10510",
            label: "COKE-coke",
          },
          {
            value: "FrequencyMaster/10200",
            label: "QMF-",
          },
          {
            value: "FrequencyMaster/10169",
            label: "TE-tem",
          },
          {
            value: "FrequencyMaster/10146",
            label: "REDD-red1",
          },
          {
            value: "FrequencyMaster/10183",
            label: "READD1-read11",
          },
          {
            value: "FrequencyMaster/10159",
            label: "LOAD-load",
          },
          {
            value: "FrequencyMaster/10160",
            label: "ETT-ett",
          },
          {
            value: "FrequencyMaster/10168",
            label: "TEMP-temp",
          },
          {
            value: "FrequencyMaster/10115",
            label: "TRQ 2-name 22",
          },
          {
            value: "FrequencyMaster/10127",
            label: "Q234-awrgseg",
          },
          {
            value: "FrequencyMaster/10113",
            label: "123-test name",
          },
          {
            value: "FrequencyMaster/10450",
            label:
              'FRQ21-val.schedulelines_time ? moment.unix(val.schedulelines_time).format("HH:mm")',
          },
          {
            value: "FrequencyMaster/10423",
            label: "RTGB-tyhujnm",
          },
          {
            value: "FrequencyMaster/10170",
            label: "C4-c4",
          },
          {
            value: "FrequencyMaster/10210",
            label: "Q3D-Every 3 Days",
          },
          {
            value: "FrequencyMaster/10181",
            label: "N2EDIT-n2edit",
          },
          {
            value: "FrequencyMaster/10166",
            label: "C3-c3",
          },
          {
            value: "FrequencyMaster/10487",
            label: "CODE0021-Frequency Name*",
          },
          {
            value: "FrequencyMaster/10144",
            label: "READTEST-readTest",
          },
          {
            value: "FrequencyMaster/10208",
            label: "BOT2-bot2",
          },
          {
            value: "FrequencyMaster/10353",
            label: "FYI-frq name",
          },
          {
            value: "FrequencyMaster/10429",
            label: "HEPTA-tripo",
          },
          {
            value: "FrequencyMaster/10233",
            label: "OOPS5-FREQUENCY1",
          },
          {
            value: "FrequencyMaster/10193",
            label: "LOAD2-lod",
          },
          {
            value: "FrequencyMaster/10273",
            label: "ALU1237-awse",
          },
          {
            value: "FrequencyMaster/10432",
            label: "A003-name",
          },
          {
            value: "FrequencyMaster/10278",
            label: "ANU1234-anu",
          },
          {
            value: "FrequencyMaster/10209",
            label: "Q8H-Every 8 Hours",
          },
          {
            value: "FrequencyMaster/10286",
            label: "GSXJ-ghhj",
          },
          {
            value: "FrequencyMaster/10465",
            label: "423-dqd21",
          },
          {
            value: "FrequencyMaster/10454",
            label: "Q4H-Every Four hours",
          },
          {
            value: "FrequencyMaster/10137",
            label: "AWE-a wef",
          },
          {
            value: "FrequencyMaster/10473",
            label: "CODE0004-Frequency Name",
          },
          {
            value: "FrequencyMaster/10068",
            label: "Q2W-weekly once",
          },
          {
            value: "FrequencyMaster/10172",
            label: "TT-ff",
          },
          {
            value: "FrequencyMaster/10062",
            label: "HJBNKLM-3 days",
          },
          {
            value: "FrequencyMaster/10227",
            label: "MKLI123-mlkiname",
          },
          {
            value: "FrequencyMaster/10489",
            label: "CODE0023-name freq",
          },
          {
            value: "FrequencyMaster/10377",
            label: "YGDUY1-dqde1",
          },
          {
            value: "FrequencyMaster/10071",
            label: "Q4W-evey day once",
          },
          {
            value: "FrequencyMaster/10226",
            label: "PARE234-para",
          },
          {
            value: "FrequencyMaster/10250",
            label: "FRECODE14-FREQUENCY",
          },
          {
            value: "FrequencyMaster/10192",
            label: "LOAD1-loader",
          },
          {
            value: "FrequencyMaster/10225",
            label: "12-",
          },
          {
            value: "FrequencyMaster/10218",
            label: "BIDDT-Twice Daily",
          },
          {
            value: "FrequencyMaster/10180",
            label: "N1WDIT-n1edit",
          },
          {
            value: "FrequencyMaster/10221",
            label: "VTV-",
          },
          {
            value: "FrequencyMaster/10339",
            label: "UDE2-User Defined 2",
          },
          {
            value: "FrequencyMaster/10220",
            label: "Z1-zet",
          },
          {
            value: "FrequencyMaster/10171",
            label: "R-Paraselvuuu",
          },
          {
            value: "FrequencyMaster/10191",
            label: "DEMI1-demi2",
          },
          {
            value: "FrequencyMaster/10492",
            label: "CODE0026-frq name",
          },
          {
            value: "FrequencyMaster/10222",
            label: "FREQ1001-Custom",
          },
          {
            value: "FrequencyMaster/10187",
            label: "TACK-tack",
          },
          {
            value: "FrequencyMaster/10360",
            label: "NOP-nothing by mouth",
          },
          {
            value: "FrequencyMaster/10476",
            label: "CODE0007-Frequency Name*",
          },
          {
            value: "FrequencyMaster/10124",
            label: "231-zdg a",
          },
          {
            value: "FrequencyMaster/10421",
            label: "HJN-sdv",
          },
          {
            value: "FrequencyMaster/10141",
            label: "GUF-jhtcf",
          },
          {
            value: "FrequencyMaster/10120",
            label: "SEQUENCE-sequence name",
          },
          {
            value: "FrequencyMaster/10129",
            label: "Q3H-",
          },
          {
            value: "FrequencyMaster/10067",
            label: "PRN-After aet",
          },
          {
            value: "FrequencyMaster/10139",
            label: "JKSEFUG-2sjefh",
          },
          {
            value: "FrequencyMaster/10240",
            label: "OOPS22-FREQUENCY",
          },
          {
            value: "FrequencyMaster/10365",
            label: "SECUKINUM-Secukinumab",
          },
          {
            value: "FrequencyMaster/10397",
            label: "D1EQE-wd12",
          },
          {
            value: "FrequencyMaster/10276",
            label: "EREWW-Name Frequency",
          },
          {
            value: "FrequencyMaster/10085",
            label: "Q@#-every day ",
          },
          {
            value: "FrequencyMaster/10311",
            label: "AMIO-amiodarone tablet",
          },
          {
            value: "FrequencyMaster/10384",
            label: "HS1- at bedtime",
          },
          {
            value: "FrequencyMaster/10149",
            label: "RET-retT",
          },
          {
            value: "FrequencyMaster/10206",
            label: "Q2H-Every TWO hours",
          },
          {
            value: "FrequencyMaster/10188",
            label: "EWE-ewe",
          },
          {
            value: "FrequencyMaster/10130",
            label: "CHECK CODE-bsi",
          },
          {
            value: "FrequencyMaster/10373",
            label: "DOXYCYCLIN-Doxycycline",
          },
          {
            value: "FrequencyMaster/10151",
            label: "TEST1-test1",
          },
          {
            value: "FrequencyMaster/10095",
            label: "Q18H-for one year only",
          },
          {
            value: "FrequencyMaster/10301",
            label: "100W-frequency",
          },
          {
            value: "FrequencyMaster/10401",
            label: "DTT07-frqcy name",
          },
          {
            value: "FrequencyMaster/10288",
            label: "URY-hgf",
          },
          {
            value: "FrequencyMaster/10077",
            label: "QAM-thrice a day",
          },
          {
            value: "FrequencyMaster/10254",
            label: "OOPS28-FREQUENCY",
          },
          {
            value: "FrequencyMaster/10355",
            label: "MY TEST CO-frq name",
          },
          {
            value: "FrequencyMaster/10356",
            label: "STAT-immediately",
          },
          {
            value: "FrequencyMaster/10092",
            label: "QSMTWT-two days only",
          },
          {
            value: "FrequencyMaster/10296",
            label: "FR001-Fre",
          },
          {
            value: "FrequencyMaster/10281",
            label: "ALU12378-alu",
          },
          {
            value: "FrequencyMaster/10093",
            label: "QFS-tow days only",
          },
          {
            value: "FrequencyMaster/10418",
            label: "DAYTEST-dayTest",
          },
          {
            value: "FrequencyMaster/10110",
            label: "1234567891-1999999",
          },
          {
            value: "FrequencyMaster/10370",
            label: "GTTS-drops",
          },
          {
            value: "FrequencyMaster/10509",
            label: "SPRITE2-sprite co",
          },
          {
            value: "FrequencyMaster/10083",
            label: "QPM-every 18hr ",
          },
          {
            value: "FrequencyMaster/10091",
            label: "A5D-daily for 1 week",
          },
          {
            value: "FrequencyMaster/10096",
            label: "Q19H-for half year only",
          },
          {
            value: "FrequencyMaster/10416",
            label: "TID3-Thrice in a day",
          },
          {
            value: "FrequencyMaster/10380",
            label: "Q. SHIFT -every shift",
          },
          {
            value: "FrequencyMaster/10284",
            label: "SARE-asdf",
          },
          {
            value: "FrequencyMaster/10173",
            label: "IIII-cc",
          },
          {
            value: "FrequencyMaster/10084",
            label: "QSAT-every 20hr ",
          },
          {
            value: "FrequencyMaster/10086",
            label: "QSMW-every month ",
          },
          {
            value: "FrequencyMaster/10302",
            label: "ANI12345-ani12345",
          },
          {
            value: "FrequencyMaster/10143",
            label: "READ-readName",
          },
          {
            value: "FrequencyMaster/10253",
            label: "FRECODE35-FREQUENCY",
          },
          {
            value: "FrequencyMaster/10517",
            label: "UTAH-utah",
          },
          {
            value: "FrequencyMaster/10323",
            label: "987S-FREQUENCY",
          },
          {
            value: "FrequencyMaster/10087",
            label: "QTHF-every hour for 2 day",
          },
          {
            value: "FrequencyMaster/10346",
            label: "GST-dev test ",
          },
          {
            value: "FrequencyMaster/10383",
            label: "Q48-every 48 hours",
          },
          {
            value: "FrequencyMaster/10195",
            label: "LOAD4-load4",
          },
          {
            value: "FrequencyMaster/10466",
            label: "1E-dqd",
          },
          {
            value: "FrequencyMaster/10513",
            label: "ASEKFMER-jijfkjnfiknf",
          },
          {
            value: "FrequencyMaster/10236",
            label: "OOPS21-FREQUENCY2",
          },
          {
            value: "FrequencyMaster/10452",
            label: "FRQ24-Frequency Name*",
          },
          {
            value: "FrequencyMaster/10205",
            label: "QM-Day based",
          },
          {
            value: "FrequencyMaster/10376",
            label: "SQD1-FREQUENCY",
          },
          {
            value: "FrequencyMaster/10075",
            label: "SA-pARA",
          },
          {
            value: "FrequencyMaster/10112",
            label: "SS-pARA",
          },
          {
            value: "FrequencyMaster/10299",
            label: "ANI1234-ani1234",
          },
          {
            value: "FrequencyMaster/10477",
            label: "CODE0008-Frequency Name*",
          },
          {
            value: "FrequencyMaster/10216",
            label: "TEST CODE-Testcode",
          },
          {
            value: "FrequencyMaster/10247",
            label: "LOADER6-",
          },
          {
            value: "FrequencyMaster/10352",
            label: "DEV TEST 5-frq name",
          },
          {
            value: "FrequencyMaster/10133",
            label: "QWDF-test",
          },
          {
            value: "FrequencyMaster/10217",
            label: "TR005-Travenue",
          },
          {
            value: "FrequencyMaster/10293",
            label: "ALU1230-awse",
          },
          {
            value: "FrequencyMaster/10292",
            label: "ALU123678-anuqwert",
          },
          {
            value: "FrequencyMaster/10076",
            label: "Q6-evey day once",
          },
          {
            value: "FrequencyMaster/10295",
            label: "DE001-Detail",
          },
          {
            value: "FrequencyMaster/10303",
            label: "ALU123456-alu123456",
          },
          {
            value: "FrequencyMaster/10304",
            label: "AN1234567I-ani",
          },
          {
            value: "FrequencyMaster/10190",
            label: "DEMI-demi",
          },
          {
            value: "FrequencyMaster/10528",
            label: "FRE001-Test Frequency",
          },
          {
            value: "FrequencyMaster/10297",
            label: "ANI12-ani",
          },
          {
            value: "FrequencyMaster/10305",
            label: "OOPS111-FREQUENCY",
          },
          {
            value: "FrequencyMaster/10308",
            label: "FRE-paracetamol",
          },
          {
            value: "FrequencyMaster/10246",
            label: "2FRECODE-FREQUENCY",
          },
          {
            value: "FrequencyMaster/10309",
            label: "OOPS215-FREQUENCY",
          },
          {
            value: "FrequencyMaster/10283",
            label: "WEQ-gfsd",
          },
          {
            value: "FrequencyMaster/10460",
            label: "FRQ0024-Frequency Name",
          },
          {
            value: "FrequencyMaster/10310",
            label: "ALTRE-altretamine",
          },
          {
            value: "FrequencyMaster/10198",
            label: "LAOD11-load11",
          },
          {
            value: "FrequencyMaster/10313",
            label: "AMLO-amlodipine and valsartan",
          },
          {
            value: "FrequencyMaster/10457",
            label: "FRQ0023-Frequency Name",
          },
          {
            value: "FrequencyMaster/10403",
            label: "QWD2-sadws",
          },
          {
            value: "FrequencyMaster/10404",
            label: "Q12HH-ever twelve hours",
          },
          {
            value: "FrequencyMaster/10314",
            label: "ALPRA-alprazolam",
          },
          {
            value: "FrequencyMaster/10307",
            label: "OOPS209-frename",
          },
          {
            value: "FrequencyMaster/10306",
            label: "FRECODEE3-frename",
          },
          {
            value: "FrequencyMaster/10402",
            label: "LF2-test name",
          },
          {
            value: "FrequencyMaster/10319",
            label: "MY123456-my123456",
          },
          {
            value: "FrequencyMaster/10224",
            label: "RE1123-Name RE1123",
          },
          {
            value: "FrequencyMaster/10320",
            label: "MY1234567-my1234567",
          },
          {
            value: "FrequencyMaster/10317",
            label: "MY1234-my1234",
          },
          {
            value: "FrequencyMaster/10322",
            label: "TR004-Detail",
          },
          {
            value: "FrequencyMaster/10327",
            label: "AT6PM -FREQUENCY",
          },
          {
            value: "FrequencyMaster/10196",
            label: "LOAD5-load5a",
          },
          {
            value: "FrequencyMaster/10328",
            label: "OOPS2IS-frename",
          },
          {
            value: "FrequencyMaster/10167",
            label: "MKLI1237-mlkiname",
          },
          {
            value: "FrequencyMaster/10409",
            label: "FREQUENCY0-Frequency",
          },
          {
            value: "FrequencyMaster/10333",
            label: "NC005-nc",
          },
          {
            value: "FrequencyMaster/10325",
            label: "OOPS11134-FREQUENCY",
          },
          {
            value: "FrequencyMaster/10082",
            label: "QAD-every 12hr ",
          },
          {
            value: "FrequencyMaster/10324",
            label: "OOPS1122-FREQUENCY1",
          },
          {
            value: "FrequencyMaster/10065",
            label: "OTO-Once per week",
          },
          {
            value: "FrequencyMaster/10145",
            label: "READT-readT",
          },
          {
            value: "FrequencyMaster/10354",
            label: "TEST32-frenamev1",
          },
          {
            value: "FrequencyMaster/10103",
            label: "BID-Twice daliy",
          },
          {
            value: "FrequencyMaster/10464",
            label: "EMF-Every Monday & Friday",
          },
          {
            value: "FrequencyMaster/10336",
            label: "VC001-vc",
          },
          {
            value: "FrequencyMaster/10474",
            label: "CODE0005-Frequency Name",
          },
          {
            value: "FrequencyMaster/10157",
            label: "TRRT-trrt",
          },
          {
            value: "FrequencyMaster/10337",
            label: "OOPS123E-FREQUENCY",
          },
          {
            value: "FrequencyMaster/10456",
            label: "FRQ0020-Frequency Name*",
          },
          {
            value: "FrequencyMaster/10211",
            label: "TAER-TAPERING",
          },
          {
            value: "FrequencyMaster/10104",
            label: "DRD-dasDas",
          },
          {
            value: "FrequencyMaster/10338",
            label: "UDE1-User Defined",
          },
          {
            value: "FrequencyMaster/10340",
            label: "TEST008-TEST001",
          },
          {
            value: "FrequencyMaster/10241",
            label: "FRECODE1-FREQUENCY",
          },
          {
            value: "FrequencyMaster/10341",
            label: "TEST0001-TEST001",
          },
          {
            value: "FrequencyMaster/10088",
            label: "QTHFBID-every week for 2 month",
          },
          {
            value: "FrequencyMaster/10483",
            label: "CODE0019-name tst",
          },
          {
            value: "FrequencyMaster/10345",
            label: "TESTC-testing",
          },
          {
            value: "FrequencyMaster/10348",
            label: "CODETESTD-frq name",
          },
          {
            value: "FrequencyMaster/10349",
            label: "FIRST-frq name",
          },
          {
            value: "FrequencyMaster/10350",
            label: "SECOND-frq name",
          },
          {
            value: "FrequencyMaster/10480",
            label: "23-SDS",
          },
          {
            value: "FrequencyMaster/10334",
            label: "AZITHRO-Azithromycin",
          },
          {
            value: "FrequencyMaster/10351",
            label: "LOAD 2-frq name",
          },
          {
            value: "FrequencyMaster/10332",
            label: "NC004-Nc",
          },
          {
            value: "FrequencyMaster/10361",
            label: "1002-frq name",
          },
          {
            value: "FrequencyMaster/10357",
            label: "QOD-every other day",
          },
          {
            value: "FrequencyMaster/10411",
            label: "TRIPLE-triple",
          },
          {
            value: "FrequencyMaster/10343",
            label: "ENTYVIO -Entyvio ",
          },
          {
            value: "FrequencyMaster/10363",
            label: "LOFEXIDINE-Lofexidine",
          },
          {
            value: "FrequencyMaster/10431",
            label: "A002-name",
          },
          {
            value: "FrequencyMaster/10298",
            label: "ANI123-ani123",
          },
          {
            value: "FrequencyMaster/10364",
            label: "ZUBSOLV -Zubsolv ",
          },
          {
            value: "FrequencyMaster/10371",
            label: "CYMBALTA-Cymbalta",
          },
          {
            value: "FrequencyMaster/10114",
            label: "TEST CODE -trial name",
          },
          {
            value: "FrequencyMaster/10366",
            label: "NOCTE-every night",
          },
          {
            value: "FrequencyMaster/10108",
            label: "PNR-Sela",
          },
          {
            value: "FrequencyMaster/10329",
            label: "NC001-Nc",
          },
          {
            value: "FrequencyMaster/10367",
            label: "BDS-2 times a day",
          },
          {
            value: "FrequencyMaster/10368",
            label: "BED-2 times daily",
          },
          {
            value: "FrequencyMaster/10415",
            label: "RTG-rgsa",
          },
          {
            value: "FrequencyMaster/10315",
            label: "OOPS109-FREQUENCY",
          },
          {
            value: "FrequencyMaster/10463",
            label: "Q2D-EVERY 2 DAYS",
          },
          {
            value: "FrequencyMaster/10374",
            label: "Q 8TO12H-Every 8 to 12 Hours",
          },
          {
            value: "FrequencyMaster/10379",
            label: " Q. MONTH -ALTARNATE THREE DAYS",
          },
          {
            value: "FrequencyMaster/10390",
            label: "3RTTT5-val",
          },
          {
            value: "FrequencyMaster/10392",
            label: "QD112EDQ-1e12e1",
          },
          {
            value: "FrequencyMaster/10446",
            label: "FRQ0010-Frequency Name*",
          },
          {
            value: "FrequencyMaster/10330",
            label: "NC002-Nc",
          },
        ],
        doseDuration: [
          {
            value: "FrequencyMaster/10469",
            label: "Frequency Name*",
          },
          {
            value: "FrequencyMaster/10163",
            label: "c1",
          },
          {
            value: "FrequencyMaster/10468",
            label: "User Defined",
          },
          {
            value: "FrequencyMaster/10459",
            label: "Frequency Name",
          },
          {
            value: "FrequencyMaster/10491",
            label: "name data ",
          },
          {
            value: "FrequencyMaster/10381",
            label: "when necessary",
          },
          {
            value: "FrequencyMaster/10442",
            label: "Frequency Name",
          },
          {
            value: "FrequencyMaster/10458",
            label: "Frequency Name*",
          },
          {
            value: "FrequencyMaster/10204",
            label: "Every Ten hours",
          },
          {
            value: "FrequencyMaster/10511",
            label: "Frequency Name *",
          },
          {
            value: "FrequencyMaster/10499",
            label: "3asdasdasd",
          },
          {
            value: "FrequencyMaster/10449",
            label: "Frequency Name*",
          },
          {
            value: "FrequencyMaster/10451",
            label: "Frequency Name*",
          },
          {
            value: "FrequencyMaster/10447",
            label: "Frequency Name*",
          },
          {
            value: "FrequencyMaster/10443",
            label: "Frequency Name",
          },
          {
            value: "FrequencyMaster/10441",
            label: "data name",
          },
          {
            value: "FrequencyMaster/10430",
            label: "name char",
          },
          {
            value: "FrequencyMaster/10440",
            label: "name char ",
          },
          {
            value: "FrequencyMaster/10439",
            label: "jhhjkjkh",
          },
          {
            value: "FrequencyMaster/10417",
            label: "Short ",
          },
          {
            value: "FrequencyMaster/10438",
            label: "test char",
          },
          {
            value: "FrequencyMaster/10437",
            label: "char name test",
          },
          {
            value: "FrequencyMaster/10433",
            label: "tripo",
          },
          {
            value: "FrequencyMaster/10436",
            label: "name",
          },
          {
            value: "FrequencyMaster/10131",
            label: "dfa",
          },
          {
            value: "FrequencyMaster/10414",
            label: "test data ",
          },
          {
            value: "FrequencyMaster/10424",
            label: "rtfaf",
          },
          {
            value: "FrequencyMaster/10422",
            label: "RTGC",
          },
          {
            value: "FrequencyMaster/10420",
            label: "checkdetails",
          },
          {
            value: "FrequencyMaster/10413",
            label: "test data",
          },
          {
            value: "FrequencyMaster/10495",
            label: "code nbame ",
          },
          {
            value: "FrequencyMaster/10219",
            label: "F11 race",
          },
          {
            value: "FrequencyMaster/10094",
            label: "one month only",
          },
          {
            value: "FrequencyMaster/10410",
            label: "frqq ",
          },
          {
            value: "FrequencyMaster/10408",
            label: "dAD",
          },
          {
            value: "FrequencyMaster/10407",
            label: "FREQUENCY",
          },
          {
            value: "FrequencyMaster/10405",
            label: "ever twelve hours",
          },
          {
            value: "FrequencyMaster/10406",
            label: "dqdq",
          },
          {
            value: "FrequencyMaster/10400",
            label: "user defined name",
          },
          {
            value: "FrequencyMaster/10072",
            label: "evey 5h once",
          },
          {
            value: "FrequencyMaster/10393",
            label: "adqd",
          },
          {
            value: "FrequencyMaster/10155",
            label: "cec",
          },
          {
            value: "FrequencyMaster/10388",
            label: "every 12",
          },
          {
            value: "FrequencyMaster/10287",
            label: "adfs",
          },
          {
            value: "FrequencyMaster/10194",
            label: "load3",
          },
          {
            value: "FrequencyMaster/10500",
            label: "rrtrg",
          },
          {
            value: "FrequencyMaster/10448",
            label: "Frequency Name*",
          },
          {
            value: "FrequencyMaster/10102",
            label: "Every five hours",
          },
          {
            value: "FrequencyMaster/10290",
            label: "as",
          },
          {
            value: "FrequencyMaster/10274",
            label: "CRAYOND009",
          },
          {
            value: "FrequencyMaster/10419",
            label: "sequence",
          },
          {
            value: "FrequencyMaster/10277",
            label: "test",
          },
          {
            value: "FrequencyMaster/10249",
            label: "FREQUENCY",
          },
          {
            value: "FrequencyMaster/10498",
            label: "name",
          },
          {
            value: "FrequencyMaster/10128",
            label: "ddd",
          },
          {
            value: "FrequencyMaster/10177",
            label: null,
          },
          {
            value: "FrequencyMaster/10074",
            label: "evey day once",
          },
          {
            value: "FrequencyMaster/10344",
            label: "Fentanyl ",
          },
          {
            value: "FrequencyMaster/10109",
            label: "test",
          },
          {
            value: "FrequencyMaster/10285",
            label: "fgjh",
          },
          {
            value: "FrequencyMaster/10389",
            label: "Twice a day",
          },
          {
            value: "FrequencyMaster/10238",
            label: "FRE",
          },
          {
            value: "FrequencyMaster/10395",
            label: "adwd",
          },
          {
            value: "FrequencyMaster/10505",
            label: "test name",
          },
          {
            value: "FrequencyMaster/10269",
            label: "tellmakind",
          },
          {
            value: "FrequencyMaster/10427",
            label: "msnir",
          },
          {
            value: "FrequencyMaster/10399",
            label: "2sw2w",
          },
          {
            value: "FrequencyMaster/10501",
            label: "Asci",
          },
          {
            value: "FrequencyMaster/10099",
            label: "User defined",
          },
          {
            value: "FrequencyMaster/10275",
            label: "CRAYOND010",
          },
          {
            value: "FrequencyMaster/10126",
            label: "23r523",
          },
          {
            value: "FrequencyMaster/10506",
            label: "tanya nae",
          },
          {
            value: "FrequencyMaster/10267",
            label: "CRAYOND997",
          },
          {
            value: "FrequencyMaster/10243",
            label: "red",
          },
          {
            value: "FrequencyMaster/10213",
            label: "Once A Day",
          },
          {
            value: "FrequencyMaster/10387",
            label: "as much as you wish",
          },
          {
            value: "FrequencyMaster/10291",
            label: "Anuj123",
          },
          {
            value: "FrequencyMaster/10256",
            label: "Every 12 hours",
          },
          {
            value: "FrequencyMaster/10294",
            label: "alu",
          },
          {
            value: "FrequencyMaster/10331",
            label: "Nc",
          },
          {
            value: "FrequencyMaster/10101",
            label: "Once daily",
          },
          {
            value: "FrequencyMaster/10140",
            label: "aeskrjbh32",
          },
          {
            value: "FrequencyMaster/10326",
            label: "FREQUENCY",
          },
          {
            value: "FrequencyMaster/10164",
            label: "total",
          },
          {
            value: "FrequencyMaster/10228",
            label: "Testing Test",
          },
          {
            value: "FrequencyMaster/10453",
            label: "fe",
          },
          {
            value: "FrequencyMaster/10514",
            label: "gnrwew",
          },
          {
            value: "FrequencyMaster/10479",
            label: "WE",
          },
          {
            value: "FrequencyMaster/10064",
            label: "Weekly twise",
          },
          {
            value: "FrequencyMaster/10255",
            label: "FREQUENCY",
          },
          {
            value: "FrequencyMaster/10358",
            label: "every night at bedtime",
          },
          {
            value: "FrequencyMaster/10444",
            label: "Frequency Name",
          },
          {
            value: "FrequencyMaster/10244",
            label: "val12",
          },
          {
            value: "FrequencyMaster/10252",
            label: "FREQUENCY",
          },
          {
            value: "FrequencyMaster/10121",
            label: "user defined name",
          },
          {
            value: "FrequencyMaster/10248",
            label: null,
          },
          {
            value: "FrequencyMaster/10347",
            label: "test ",
          },
          {
            value: "FrequencyMaster/10235",
            label: "val1",
          },
          {
            value: "FrequencyMaster/10125",
            label: "sdgwerg",
          },
          {
            value: "FrequencyMaster/10272",
            label: "para",
          },
          {
            value: "FrequencyMaster/10184",
            label: "asdfasf",
          },
          {
            value: "FrequencyMaster/10481",
            label: "name code",
          },
          {
            value: "FrequencyMaster/10268",
            label: "Frequency Name",
          },
          {
            value: "FrequencyMaster/10158",
            label: " rat",
          },
          {
            value: "FrequencyMaster/10134",
            label: "asdf",
          },
          {
            value: "FrequencyMaster/10316",
            label: "my",
          },
          {
            value: "FrequencyMaster/10526",
            label: "freqrtiy",
          },
          {
            value: "FrequencyMaster/10378",
            label: "Every 6 hr",
          },
          {
            value: "FrequencyMaster/10135",
            label: "srjgb",
          },
          {
            value: "FrequencyMaster/10412",
            label: "test data ",
          },
          {
            value: "FrequencyMaster/10090",
            label: "daily",
          },
          {
            value: "FrequencyMaster/10069",
            label: "dayily once",
          },
          {
            value: "FrequencyMaster/10455",
            label: "Frequency Name*",
          },
          {
            value: "FrequencyMaster/10142",
            label: "jh",
          },
          {
            value: "FrequencyMaster/10117",
            label: "day test name",
          },
          {
            value: "FrequencyMaster/10504",
            label: "name test",
          },
          {
            value: "FrequencyMaster/10425",
            label: "Music",
          },
          {
            value: "FrequencyMaster/10123",
            label: "sgefwe",
          },
          {
            value: "FrequencyMaster/10070",
            label: "evey month once",
          },
          {
            value: "FrequencyMaster/10118",
            label: "detail name",
          },
          {
            value: "FrequencyMaster/10257",
            label: "test",
          },
          {
            value: "FrequencyMaster/10502",
            label: "Route",
          },
          {
            value: "FrequencyMaster/10089",
            label: "every week for 1 year",
          },
          {
            value: "FrequencyMaster/10496",
            label: "code name ",
          },
          {
            value: "FrequencyMaster/10300",
            label: "albuterol sulfate",
          },
          {
            value: "FrequencyMaster/10153",
            label: "newtest",
          },
          {
            value: "FrequencyMaster/10079",
            label: "s",
          },
          {
            value: "FrequencyMaster/10186",
            label: "s",
          },
          {
            value: "FrequencyMaster/10470",
            label: "Frequency Name*",
          },
          {
            value: "FrequencyMaster/10519",
            label: "LOPP",
          },
          {
            value: "FrequencyMaster/10279",
            label: "TEST",
          },
          {
            value: "FrequencyMaster/10066",
            label: "Before eat",
          },
          {
            value: "FrequencyMaster/10239",
            label: "frename",
          },
          {
            value: "FrequencyMaster/10318",
            label: "my12345",
          },
          {
            value: "FrequencyMaster/10461",
            label: "Twice Daily",
          },
          {
            value: "FrequencyMaster/10512",
            label: "ojrg",
          },
          {
            value: "FrequencyMaster/10270",
            label: "ert",
          },
          {
            value: "FrequencyMaster/10178",
            label: null,
          },
          {
            value: "FrequencyMaster/10503",
            label: "TAPERING",
          },
          {
            value: "FrequencyMaster/10280",
            label: "QWERTY",
          },
          {
            value: "FrequencyMaster/10098",
            label: "Every Monday and Wednesday",
          },
          {
            value: "FrequencyMaster/10478",
            label: "Frequency Name*",
          },
          {
            value: "FrequencyMaster/10175",
            label: "dddd",
          },
          {
            value: "FrequencyMaster/10386",
            label: "frq name",
          },
          {
            value: "FrequencyMaster/10508",
            label: "BOVONTO",
          },
          {
            value: "FrequencyMaster/10174",
            label: "te",
          },
          {
            value: "FrequencyMaster/10203",
            label: "Every SIX hours",
          },
          {
            value: "FrequencyMaster/10182",
            label: "n3edit",
          },
          {
            value: "FrequencyMaster/10251",
            label: "FREQUENCY",
          },
          {
            value: "FrequencyMaster/10080",
            label: "thrice a months",
          },
          {
            value: "FrequencyMaster/10486",
            label: "Frequency Name*",
          },
          {
            value: "FrequencyMaster/10165",
            label: "Day Based",
          },
          {
            value: "FrequencyMaster/10490",
            label: "name frq 2",
          },
          {
            value: "FrequencyMaster/10185",
            label: "Ammu",
          },
          {
            value: "FrequencyMaster/10359",
            label: "directions",
          },
          {
            value: "FrequencyMaster/10122",
            label: "Every 7",
          },
          {
            value: "FrequencyMaster/10475",
            label: "Frequency Name*",
          },
          {
            value: "FrequencyMaster/10520",
            label: "E3D",
          },
          {
            value: "FrequencyMaster/10497",
            label: "code name",
          },
          {
            value: "FrequencyMaster/10119",
            label: "interval name",
          },
          {
            value: "FrequencyMaster/10215",
            label: "OD",
          },
          {
            value: "FrequencyMaster/10271",
            label: "ERRE Name",
          },
          {
            value: "FrequencyMaster/10516",
            label: "pepsi co",
          },
          {
            value: "FrequencyMaster/10472",
            label: "Frequency Name*",
          },
          {
            value: "FrequencyMaster/10428",
            label: "ytsdk",
          },
          {
            value: "FrequencyMaster/10161",
            label: "dasDas",
          },
          {
            value: "FrequencyMaster/10493",
            label: "hariram.jagannathan@ainqa.com",
          },
          {
            value: "FrequencyMaster/10154",
            label: "finTest",
          },
          {
            value: "FrequencyMaster/10245",
            label: "FREQUENCY",
          },
          {
            value: "FrequencyMaster/10148",
            label: "trread11",
          },
          {
            value: "FrequencyMaster/10100",
            label: "Three times daily",
          },
          {
            value: "FrequencyMaster/10282",
            label: "cvzx",
          },
          {
            value: "FrequencyMaster/10189",
            label: "name",
          },
          {
            value: "FrequencyMaster/10518",
            label: "asd",
          },
          {
            value: "FrequencyMaster/10372",
            label: "Cyclobenzaprine ",
          },
          {
            value: "FrequencyMaster/10471",
            label: "Frequency Name",
          },
          {
            value: "FrequencyMaster/10214",
            label: null,
          },
          {
            value: "FrequencyMaster/10362",
            label: "Naproxen ",
          },
          {
            value: "FrequencyMaster/10289",
            label: "dfh",
          },
          {
            value: "FrequencyMaster/10515",
            label: "skfng",
          },
          {
            value: "FrequencyMaster/10342",
            label: "TESST",
          },
          {
            value: "FrequencyMaster/10202",
            label: "test 32",
          },
          {
            value: "FrequencyMaster/10385",
            label: "both eyes",
          },
          {
            value: "FrequencyMaster/10482",
            label: "name cide",
          },
          {
            value: "FrequencyMaster/10150",
            label: "bet",
          },
          {
            value: "FrequencyMaster/10529",
            label: "Coca pepsi",
          },
          {
            value: "FrequencyMaster/10523",
            label: "TWICE A DAY",
          },
          {
            value: "FrequencyMaster/10522",
            label: "TWICE A DAY",
          },
          {
            value: "FrequencyMaster/10375",
            label: "Wellbutrin ",
          },
          {
            value: "FrequencyMaster/10201",
            label: null,
          },
          {
            value: "FrequencyMaster/10223",
            label: "",
          },
          {
            value: "FrequencyMaster/10116",
            label: "jus",
          },
          {
            value: "FrequencyMaster/10176",
            label: null,
          },
          {
            value: "FrequencyMaster/10426",
            label: "asdc",
          },
          {
            value: "FrequencyMaster/10488",
            label: "code name",
          },
          {
            value: "FrequencyMaster/10507",
            label: "BOVONTO",
          },
          {
            value: "FrequencyMaster/10321",
            label: "Detail",
          },
          {
            value: "FrequencyMaster/10199",
            label: "load12",
          },
          {
            value: "FrequencyMaster/10111",
            label: "111111111111111111",
          },
          {
            value: "FrequencyMaster/10494",
            label: "admin frq",
          },
          {
            value: "FrequencyMaster/10207",
            label: "Every 24 hours",
          },
          {
            value: "FrequencyMaster/10335",
            label: "brilinta",
          },
          {
            value: "FrequencyMaster/10525",
            label: "Freq component",
          },
          {
            value: "FrequencyMaster/10312",
            label: "amitriptyline tablet",
          },
          {
            value: "FrequencyMaster/10147",
            label: "redTest",
          },
          {
            value: "FrequencyMaster/10212",
            label: "Once a Night",
          },
          {
            value: "FrequencyMaster/10435",
            label: "test",
          },
          {
            value: "FrequencyMaster/10097",
            label: "Alternate 2 days",
          },
          {
            value: "FrequencyMaster/10237",
            label: "val",
          },
          {
            value: "FrequencyMaster/10398",
            label: "frename",
          },
          {
            value: "FrequencyMaster/10162",
            label: "dasDas",
          },
          {
            value: "FrequencyMaster/10445",
            label: "Frequency Name*",
          },
          {
            value: "FrequencyMaster/10391",
            label: "frename",
          },
          {
            value: "FrequencyMaster/10467",
            label: "2 Days Once",
          },
          {
            value: "FrequencyMaster/10396",
            label: "val",
          },
          {
            value: "FrequencyMaster/10156",
            label: "tit",
          },
          {
            value: "FrequencyMaster/10369",
            label: "twice daily",
          },
          {
            value: "FrequencyMaster/10063",
            label: "Everyday",
          },
          {
            value: "FrequencyMaster/10527",
            label: "asddcvczxc",
          },
          {
            value: "FrequencyMaster/10521",
            label: "Every 3 Days",
          },
          {
            value: "FrequencyMaster/10197",
            label: "load6",
          },
          {
            value: "FrequencyMaster/10138",
            label: "jsneifh",
          },
          {
            value: "FrequencyMaster/10524",
            label: "TWICE A DAY",
          },
          {
            value: "FrequencyMaster/10073",
            label: "evey 12hr once",
          },
          {
            value: "FrequencyMaster/10382",
            label: "THREE TIMES DAY",
          },
          {
            value: "FrequencyMaster/10394",
            label: "gvhv",
          },
          {
            value: "FrequencyMaster/10462",
            label: "Twice Daily",
          },
          {
            value: "FrequencyMaster/10132",
            label: "REF",
          },
          {
            value: "FrequencyMaster/10105",
            label: null,
          },
          {
            value: "FrequencyMaster/10106",
            label: "Every 4 Hours",
          },
          {
            value: "FrequencyMaster/10484",
            label: "Base Interval Frequency",
          },
          {
            value: "FrequencyMaster/10136",
            label: "zsf g",
          },
          {
            value: "FrequencyMaster/10152",
            label: "track",
          },
          {
            value: "FrequencyMaster/10179",
            label: "333",
          },
          {
            value: "FrequencyMaster/10434",
            label: "name char example",
          },
          {
            value: "FrequencyMaster/10485",
            label: "Frequency Name*",
          },
          {
            value: "FrequencyMaster/10242",
            label: "frename",
          },
          {
            value: "FrequencyMaster/10510",
            label: "coke",
          },
          {
            value: "FrequencyMaster/10200",
            label: null,
          },
          {
            value: "FrequencyMaster/10169",
            label: "tem",
          },
          {
            value: "FrequencyMaster/10146",
            label: "red1",
          },
          {
            value: "FrequencyMaster/10183",
            label: "read11",
          },
          {
            value: "FrequencyMaster/10159",
            label: "load",
          },
          {
            value: "FrequencyMaster/10160",
            label: "ett",
          },
          {
            value: "FrequencyMaster/10168",
            label: "temp",
          },
          {
            value: "FrequencyMaster/10115",
            label: "name 22",
          },
          {
            value: "FrequencyMaster/10127",
            label: "awrgseg",
          },
          {
            value: "FrequencyMaster/10113",
            label: "test name",
          },
          {
            value: "FrequencyMaster/10450",
            label:
              'val.schedulelines_time ? moment.unix(val.schedulelines_time).format("HH:mm")',
          },
          {
            value: "FrequencyMaster/10423",
            label: "tyhujnm",
          },
          {
            value: "FrequencyMaster/10170",
            label: "c4",
          },
          {
            value: "FrequencyMaster/10210",
            label: "Every 3 Days",
          },
          {
            value: "FrequencyMaster/10181",
            label: "n2edit",
          },
          {
            value: "FrequencyMaster/10166",
            label: "c3",
          },
          {
            value: "FrequencyMaster/10487",
            label: "Frequency Name*",
          },
          {
            value: "FrequencyMaster/10144",
            label: "readTest",
          },
          {
            value: "FrequencyMaster/10208",
            label: "bot2",
          },
          {
            value: "FrequencyMaster/10353",
            label: "frq name",
          },
          {
            value: "FrequencyMaster/10429",
            label: "tripo",
          },
          {
            value: "FrequencyMaster/10233",
            label: "FREQUENCY1",
          },
          {
            value: "FrequencyMaster/10193",
            label: "lod",
          },
          {
            value: "FrequencyMaster/10273",
            label: "awse",
          },
          {
            value: "FrequencyMaster/10432",
            label: "name",
          },
          {
            value: "FrequencyMaster/10278",
            label: "anu",
          },
          {
            value: "FrequencyMaster/10209",
            label: "Every 8 Hours",
          },
          {
            value: "FrequencyMaster/10286",
            label: "ghhj",
          },
          {
            value: "FrequencyMaster/10465",
            label: "dqd21",
          },
          {
            value: "FrequencyMaster/10454",
            label: "Every Four hours",
          },
          {
            value: "FrequencyMaster/10137",
            label: "a wef",
          },
          {
            value: "FrequencyMaster/10473",
            label: "Frequency Name",
          },
          {
            value: "FrequencyMaster/10068",
            label: "weekly once",
          },
          {
            value: "FrequencyMaster/10172",
            label: "ff",
          },
          {
            value: "FrequencyMaster/10062",
            label: "3 days",
          },
          {
            value: "FrequencyMaster/10227",
            label: "mlkiname",
          },
          {
            value: "FrequencyMaster/10489",
            label: "name freq",
          },
          {
            value: "FrequencyMaster/10377",
            label: "dqde1",
          },
          {
            value: "FrequencyMaster/10071",
            label: "evey day once",
          },
          {
            value: "FrequencyMaster/10226",
            label: "para",
          },
          {
            value: "FrequencyMaster/10250",
            label: "FREQUENCY",
          },
          {
            value: "FrequencyMaster/10192",
            label: "loader",
          },
          {
            value: "FrequencyMaster/10225",
            label: null,
          },
          {
            value: "FrequencyMaster/10218",
            label: "Twice Daily",
          },
          {
            value: "FrequencyMaster/10180",
            label: "n1edit",
          },
          {
            value: "FrequencyMaster/10221",
            label: null,
          },
          {
            value: "FrequencyMaster/10339",
            label: "User Defined 2",
          },
          {
            value: "FrequencyMaster/10220",
            label: "zet",
          },
          {
            value: "FrequencyMaster/10171",
            label: "Paraselvuuu",
          },
          {
            value: "FrequencyMaster/10191",
            label: "demi2",
          },
          {
            value: "FrequencyMaster/10492",
            label: "frq name",
          },
          {
            value: "FrequencyMaster/10222",
            label: "Custom",
          },
          {
            value: "FrequencyMaster/10187",
            label: "tack",
          },
          {
            value: "FrequencyMaster/10360",
            label: "nothing by mouth",
          },
          {
            value: "FrequencyMaster/10476",
            label: "Frequency Name*",
          },
          {
            value: "FrequencyMaster/10124",
            label: "zdg a",
          },
          {
            value: "FrequencyMaster/10421",
            label: "sdv",
          },
          {
            value: "FrequencyMaster/10141",
            label: "jhtcf",
          },
          {
            value: "FrequencyMaster/10120",
            label: "sequence name",
          },
          {
            value: "FrequencyMaster/10129",
            label: null,
          },
          {
            value: "FrequencyMaster/10067",
            label: "After aet",
          },
          {
            value: "FrequencyMaster/10139",
            label: "2sjefh",
          },
          {
            value: "FrequencyMaster/10240",
            label: "FREQUENCY",
          },
          {
            value: "FrequencyMaster/10365",
            label: "Secukinumab",
          },
          {
            value: "FrequencyMaster/10397",
            label: "wd12",
          },
          {
            value: "FrequencyMaster/10276",
            label: "Name Frequency",
          },
          {
            value: "FrequencyMaster/10085",
            label: "every day ",
          },
          {
            value: "FrequencyMaster/10311",
            label: "amiodarone tablet",
          },
          {
            value: "FrequencyMaster/10384",
            label: " at bedtime",
          },
          {
            value: "FrequencyMaster/10149",
            label: "retT",
          },
          {
            value: "FrequencyMaster/10206",
            label: "Every TWO hours",
          },
          {
            value: "FrequencyMaster/10188",
            label: "ewe",
          },
          {
            value: "FrequencyMaster/10130",
            label: "bsi",
          },
          {
            value: "FrequencyMaster/10373",
            label: "Doxycycline",
          },
          {
            value: "FrequencyMaster/10151",
            label: "test1",
          },
          {
            value: "FrequencyMaster/10095",
            label: "for one year only",
          },
          {
            value: "FrequencyMaster/10301",
            label: "frequency",
          },
          {
            value: "FrequencyMaster/10401",
            label: "frqcy name",
          },
          {
            value: "FrequencyMaster/10288",
            label: "hgf",
          },
          {
            value: "FrequencyMaster/10077",
            label: "thrice a day",
          },
          {
            value: "FrequencyMaster/10254",
            label: "FREQUENCY",
          },
          {
            value: "FrequencyMaster/10355",
            label: "frq name",
          },
          {
            value: "FrequencyMaster/10356",
            label: "immediately",
          },
          {
            value: "FrequencyMaster/10092",
            label: "two days only",
          },
          {
            value: "FrequencyMaster/10296",
            label: "Fre",
          },
          {
            value: "FrequencyMaster/10281",
            label: "alu",
          },
          {
            value: "FrequencyMaster/10093",
            label: "tow days only",
          },
          {
            value: "FrequencyMaster/10418",
            label: "dayTest",
          },
          {
            value: "FrequencyMaster/10110",
            label: "1999999",
          },
          {
            value: "FrequencyMaster/10370",
            label: "drops",
          },
          {
            value: "FrequencyMaster/10509",
            label: "sprite co",
          },
          {
            value: "FrequencyMaster/10083",
            label: "every 18hr ",
          },
          {
            value: "FrequencyMaster/10091",
            label: "daily for 1 week",
          },
          {
            value: "FrequencyMaster/10096",
            label: "for half year only",
          },
          {
            value: "FrequencyMaster/10416",
            label: "Thrice in a day",
          },
          {
            value: "FrequencyMaster/10380",
            label: "every shift",
          },
          {
            value: "FrequencyMaster/10284",
            label: "asdf",
          },
          {
            value: "FrequencyMaster/10173",
            label: "cc",
          },
          {
            value: "FrequencyMaster/10084",
            label: "every 20hr ",
          },
          {
            value: "FrequencyMaster/10086",
            label: "every month ",
          },
          {
            value: "FrequencyMaster/10302",
            label: "ani12345",
          },
          {
            value: "FrequencyMaster/10143",
            label: "readName",
          },
          {
            value: "FrequencyMaster/10253",
            label: "FREQUENCY",
          },
          {
            value: "FrequencyMaster/10517",
            label: "utah",
          },
          {
            value: "FrequencyMaster/10323",
            label: "FREQUENCY",
          },
          {
            value: "FrequencyMaster/10087",
            label: "every hour for 2 day",
          },
          {
            value: "FrequencyMaster/10346",
            label: "dev test ",
          },
          {
            value: "FrequencyMaster/10383",
            label: "every 48 hours",
          },
          {
            value: "FrequencyMaster/10195",
            label: "load4",
          },
          {
            value: "FrequencyMaster/10466",
            label: "dqd",
          },
          {
            value: "FrequencyMaster/10513",
            label: "jijfkjnfiknf",
          },
          {
            value: "FrequencyMaster/10236",
            label: "FREQUENCY2",
          },
          {
            value: "FrequencyMaster/10452",
            label: "Frequency Name*",
          },
          {
            value: "FrequencyMaster/10205",
            label: "Day based",
          },
          {
            value: "FrequencyMaster/10376",
            label: "FREQUENCY",
          },
          {
            value: "FrequencyMaster/10075",
            label: "pARA",
          },
          {
            value: "FrequencyMaster/10112",
            label: "pARA",
          },
          {
            value: "FrequencyMaster/10299",
            label: "ani1234",
          },
          {
            value: "FrequencyMaster/10477",
            label: "Frequency Name*",
          },
          {
            value: "FrequencyMaster/10216",
            label: "Testcode",
          },
          {
            value: "FrequencyMaster/10247",
            label: null,
          },
          {
            value: "FrequencyMaster/10352",
            label: "frq name",
          },
          {
            value: "FrequencyMaster/10133",
            label: "test",
          },
          {
            value: "FrequencyMaster/10217",
            label: "Travenue",
          },
          {
            value: "FrequencyMaster/10293",
            label: "awse",
          },
          {
            value: "FrequencyMaster/10292",
            label: "anuqwert",
          },
          {
            value: "FrequencyMaster/10076",
            label: "evey day once",
          },
          {
            value: "FrequencyMaster/10295",
            label: "Detail",
          },
          {
            value: "FrequencyMaster/10303",
            label: "alu123456",
          },
          {
            value: "FrequencyMaster/10304",
            label: "ani",
          },
          {
            value: "FrequencyMaster/10190",
            label: "demi",
          },
          {
            value: "FrequencyMaster/10528",
            label: "Test Frequency",
          },
          {
            value: "FrequencyMaster/10297",
            label: "ani",
          },
          {
            value: "FrequencyMaster/10305",
            label: "FREQUENCY",
          },
          {
            value: "FrequencyMaster/10308",
            label: "paracetamol",
          },
          {
            value: "FrequencyMaster/10246",
            label: "FREQUENCY",
          },
          {
            value: "FrequencyMaster/10309",
            label: "FREQUENCY",
          },
          {
            value: "FrequencyMaster/10283",
            label: "gfsd",
          },
          {
            value: "FrequencyMaster/10460",
            label: "Frequency Name",
          },
          {
            value: "FrequencyMaster/10310",
            label: "altretamine",
          },
          {
            value: "FrequencyMaster/10198",
            label: "load11",
          },
          {
            value: "FrequencyMaster/10313",
            label: "amlodipine and valsartan",
          },
          {
            value: "FrequencyMaster/10457",
            label: "Frequency Name",
          },
          {
            value: "FrequencyMaster/10403",
            label: "sadws",
          },
          {
            value: "FrequencyMaster/10404",
            label: "ever twelve hours",
          },
          {
            value: "FrequencyMaster/10314",
            label: "alprazolam",
          },
          {
            value: "FrequencyMaster/10307",
            label: "frename",
          },
          {
            value: "FrequencyMaster/10306",
            label: "frename",
          },
          {
            value: "FrequencyMaster/10402",
            label: "test name",
          },
          {
            value: "FrequencyMaster/10319",
            label: "my123456",
          },
          {
            value: "FrequencyMaster/10224",
            label: "Name RE1123",
          },
          {
            value: "FrequencyMaster/10320",
            label: "my1234567",
          },
          {
            value: "FrequencyMaster/10317",
            label: "my1234",
          },
          {
            value: "FrequencyMaster/10322",
            label: "Detail",
          },
          {
            value: "FrequencyMaster/10327",
            label: "FREQUENCY",
          },
          {
            value: "FrequencyMaster/10196",
            label: "load5a",
          },
          {
            value: "FrequencyMaster/10328",
            label: "frename",
          },
          {
            value: "FrequencyMaster/10167",
            label: "mlkiname",
          },
          {
            value: "FrequencyMaster/10409",
            label: "Frequency",
          },
          {
            value: "FrequencyMaster/10333",
            label: "nc",
          },
          {
            value: "FrequencyMaster/10325",
            label: "FREQUENCY",
          },
          {
            value: "FrequencyMaster/10082",
            label: "every 12hr ",
          },
          {
            value: "FrequencyMaster/10324",
            label: "FREQUENCY1",
          },
          {
            value: "FrequencyMaster/10065",
            label: "Once per week",
          },
          {
            value: "FrequencyMaster/10145",
            label: "readT",
          },
          {
            value: "FrequencyMaster/10354",
            label: "frenamev1",
          },
          {
            value: "FrequencyMaster/10103",
            label: "Twice daliy",
          },
          {
            value: "FrequencyMaster/10464",
            label: "Every Monday & Friday",
          },
          {
            value: "FrequencyMaster/10336",
            label: "vc",
          },
          {
            value: "FrequencyMaster/10474",
            label: "Frequency Name",
          },
          {
            value: "FrequencyMaster/10157",
            label: "trrt",
          },
          {
            value: "FrequencyMaster/10337",
            label: "FREQUENCY",
          },
          {
            value: "FrequencyMaster/10456",
            label: "Frequency Name*",
          },
          {
            value: "FrequencyMaster/10211",
            label: "TAPERING",
          },
          {
            value: "FrequencyMaster/10104",
            label: "dasDas",
          },
          {
            value: "FrequencyMaster/10338",
            label: "User Defined",
          },
          {
            value: "FrequencyMaster/10340",
            label: "TEST001",
          },
          {
            value: "FrequencyMaster/10241",
            label: "FREQUENCY",
          },
          {
            value: "FrequencyMaster/10341",
            label: "TEST001",
          },
          {
            value: "FrequencyMaster/10088",
            label: "every week for 2 month",
          },
          {
            value: "FrequencyMaster/10483",
            label: "name tst",
          },
          {
            value: "FrequencyMaster/10345",
            label: "testing",
          },
          {
            value: "FrequencyMaster/10348",
            label: "frq name",
          },
          {
            value: "FrequencyMaster/10349",
            label: "frq name",
          },
          {
            value: "FrequencyMaster/10350",
            label: "frq name",
          },
          {
            value: "FrequencyMaster/10480",
            label: "SDS",
          },
          {
            value: "FrequencyMaster/10334",
            label: "Azithromycin",
          },
          {
            value: "FrequencyMaster/10351",
            label: "frq name",
          },
          {
            value: "FrequencyMaster/10332",
            label: "Nc",
          },
          {
            value: "FrequencyMaster/10361",
            label: "frq name",
          },
          {
            value: "FrequencyMaster/10357",
            label: "every other day",
          },
          {
            value: "FrequencyMaster/10411",
            label: "triple",
          },
          {
            value: "FrequencyMaster/10343",
            label: "Entyvio ",
          },
          {
            value: "FrequencyMaster/10363",
            label: "Lofexidine",
          },
          {
            value: "FrequencyMaster/10431",
            label: "name",
          },
          {
            value: "FrequencyMaster/10298",
            label: "ani123",
          },
          {
            value: "FrequencyMaster/10364",
            label: "Zubsolv ",
          },
          {
            value: "FrequencyMaster/10371",
            label: "Cymbalta",
          },
          {
            value: "FrequencyMaster/10114",
            label: "trial name",
          },
          {
            value: "FrequencyMaster/10366",
            label: "every night",
          },
          {
            value: "FrequencyMaster/10108",
            label: "Sela",
          },
          {
            value: "FrequencyMaster/10329",
            label: "Nc",
          },
          {
            value: "FrequencyMaster/10367",
            label: "2 times a day",
          },
          {
            value: "FrequencyMaster/10368",
            label: "2 times daily",
          },
          {
            value: "FrequencyMaster/10415",
            label: "rgsa",
          },
          {
            value: "FrequencyMaster/10315",
            label: "FREQUENCY",
          },
          {
            value: "FrequencyMaster/10463",
            label: "EVERY 2 DAYS",
          },
          {
            value: "FrequencyMaster/10374",
            label: "Every 8 to 12 Hours",
          },
          {
            value: "FrequencyMaster/10379",
            label: "ALTARNATE THREE DAYS",
          },
          {
            value: "FrequencyMaster/10390",
            label: "val",
          },
          {
            value: "FrequencyMaster/10392",
            label: "1e12e1",
          },
          {
            value: "FrequencyMaster/10446",
            label: "Frequency Name*",
          },
          {
            value: "FrequencyMaster/10330",
            label: "Nc",
          },
        ],
        drugRoute: [
          {
            value: "DrugRoutes/10061",
            label: "IMPLANTATION",
          },
          {
            value: "DrugRoutes/10007",
            label: "testtest",
          },
          {
            value: "DrugRoutes/10048",
            label: "INTRAVENOUS",
          },
          {
            value: "DrugRoutes/10076",
            label: "INJECTION",
          },
          {
            value: "DrugRoutes/10069",
            label: "VAGINAL",
          },
          {
            value: "DrugRoutes/10052",
            label: "EPIDURAL",
          },
          {
            value: "DrugRoutes/10005",
            label: "powerful hallucinogenic",
          },
          {
            value: "DrugRoutes/10082",
            label: "OTIC (EAR)",
          },
          {
            value: "DrugRoutes/10060",
            label: "MISCELLANEOUS",
          },
          {
            value: "DrugRoutes/10065",
            label: "IRRIGATION",
          },
          {
            value: "DrugRoutes/10081",
            label: "NASAL",
          },
          {
            value: "DrugRoutes/10080",
            label: "OPHTHALMIC (EYE)",
          },
          {
            value: "DrugRoutes/10010",
            label: "a",
          },
          {
            value: "DrugRoutes/10051",
            label: "DENTAL",
          },
          {
            value: "DrugRoutes/10084",
            label: "take it orally",
          },
          {
            value: "DrugRoutes/10066",
            label: "SUBLINGUAL",
          },
          {
            value: "DrugRoutes/10075",
            label: "ORALLY",
          },
          {
            value: "DrugRoutes/10053",
            label: "PERFUSION",
          },
          {
            value: "DrugRoutes/10058",
            label: "INTRAARTICULAR",
          },
          {
            value: "DrugRoutes/10011",
            label: "a",
          },
          {
            value: "DrugRoutes/10085",
            label: "IBU - Intrabursal",
          },
          {
            value: "DrugRoutes/10055",
            label: "INHALATION",
          },
          {
            value: "DrugRoutes/10079",
            label: "TOPICAL",
          },
          {
            value: "DrugRoutes/10000",
            label: "intravenous Drip",
          },
          {
            value: "DrugRoutes/10001",
            label: "",
          },
          {
            value: "DrugRoutes/10067",
            label: "TRANSDERMAL",
          },
          {
            value: "DrugRoutes/10083",
            label: "INTRADERMAL",
          },
          {
            value: "DrugRoutes/10068",
            label: "URETHRAL",
          },
          {
            value: "DrugRoutes/10054",
            label: "SUBCUTANEOUS",
          },
          {
            value: "DrugRoutes/10057",
            label: "INTRAARTERIAL",
          },
          {
            value: "DrugRoutes/10064",
            label: "INTRAVESICAL",
          },
          {
            value: "DrugRoutes/10050",
            label: "INTRAMUSCULAR",
          },
          {
            value: "DrugRoutes/10006",
            label: "full doses",
          },
          {
            value: "DrugRoutes/10049",
            label: "BUCCAL",
          },
          {
            value: "DrugRoutes/10063",
            label: "INTRAPERITONEAL",
          },
          {
            value: "DrugRoutes/10072",
            label: "IN VITRO",
          },
          {
            value: "DrugRoutes/10078",
            label: "MUCOUS MEMBRANE",
          },
          {
            value: "DrugRoutes/10071",
            label: "INTRAPLEURAL",
          },
          {
            value: "DrugRoutes/10056",
            label: "INTRACAVERNOSAL",
          },
          {
            value: "DrugRoutes/10074",
            label: "HEMODIALYSIS",
          },
          {
            value: "DrugRoutes/10062",
            label: "INTRATHECAL",
          },
          {
            value: "DrugRoutes/10008",
            label: "Long",
          },
          {
            value: "DrugRoutes/10073",
            label: "INTRAUTERINE",
          },
          {
            value: "DrugRoutes/10059",
            label: "TRANSLINGUAL",
          },
          {
            value: "DrugRoutes/10009",
            label: "NOW",
          },
          {
            value: "DrugRoutes/10077",
            label: "RECTAL",
          },
          {
            value: "DrugRoutes/10070",
            label: "INTRAOCULAR",
          },
        ],
        refillsAllowed: [],
      },
    ],
    Procedures: [
      {
        procedureCode: [
          {
            value: "OrderCatalog/10702",
            label: "FDSB-HDS-00010",
          },
        ],
        procedureDescription: [
          {
            value: "OrderCatalog/10702",
            label: "Hemodialysis Treatment",
          },
        ],
        priority: [
          {
            value: "CodingMaster/10689",
            label: "Routine",
          },
          {
            value: "CodingMaster/10690",
            label: "Stat",
          },
          {
            value: "CodingMaster/10691",
            label: "Urgent",
          },
        ],
        nature: [
          {
            value: "CodingMaster/10621",
            label: "Discharged",
          },
          {
            value: "CodingMaster/11666",
            label: "Treatment",
          },
        ],
      },
    ],
    ObservationOrders: [
      {
        observationOrderCode: [
          {
            value: "OrderCatalog/10713",
            label: "FDSB-Lab-0021",
          },
          {
            value: "OrderCatalog/10714",
            label: "FDSB-Lab-0041",
          },
          {
            value: "OrderCatalog/10715",
            label: "FDSB-Lab-0014",
          },
          {
            value: "OrderCatalog/10718",
            label: "FDSB-Lab-0042",
          },
          {
            value: "OrderCatalog/10719",
            label: "FDSB-Lab-0043",
          },
          {
            value: "OrderCatalog/10728",
            label: "FDSB-Lab-0022",
          },
          {
            value: "OrderCatalog/10729",
            label: "FDSB-Lab-0011",
          },
          {
            value: "OrderCatalog/10734",
            label: "FDSB-Lab-0018",
          },
          {
            value: "OrderCatalog/10735",
            label: "FDSB-Lab-0015",
          },
          {
            value: "OrderCatalog/10738",
            label: "FDSB-Lab-0012",
          },
          {
            value: "OrderCatalog/10741",
            label: "FDSB-Lab-0025",
          },
          {
            value: "OrderCatalog/10742",
            label: "FDSB-Lab-0026",
          },
          {
            value: "OrderCatalog/10743",
            label: "FDSB-Lab-0027",
          },
          {
            value: "OrderCatalog/10744",
            label: "FDSB-Lab-0028",
          },
          {
            value: "OrderCatalog/10745",
            label: "FDSB-Lab-0029",
          },
          {
            value: "OrderCatalog/10747",
            label: "FDSB-Lab-0032",
          },
          {
            value: "OrderCatalog/10748",
            label: "FDSB-Lab-0033",
          },
          {
            value: "OrderCatalog/10749",
            label: "FDSB-Lab-0034",
          },
          {
            value: "OrderCatalog/10750",
            label: "FDSB-Lab-0035",
          },
          {
            value: "OrderCatalog/10751",
            label: "FDSB-Lab-0036",
          },
          {
            value: "OrderCatalog/10704",
            label: "FDSB-LAB-0001",
          },
          {
            value: "OrderCatalog/10789",
            label: "FDSB-Lab-0050",
          },
          {
            value: "OrderCatalog/10791",
            label: "FDSB-PACK-0003",
          },
          {
            value: "OrderCatalog/10792",
            label: "FDSB-PACK-0004",
          },
          {
            value: "OrderCatalog/10793",
            label: "FDSB-PACK-0005",
          },
          {
            value: "OrderCatalog/10773",
            label: "FDSB-PACK-0001",
          },
          {
            value: "OrderCatalog/10706",
            label: "FDSB-Lab-0045",
          },
          {
            value: "OrderCatalog/10707",
            label: "FDSB-Lab-0016",
          },
          {
            value: "OrderCatalog/10709",
            label: "FDSB-Lab-0020",
          },
          {
            value: "OrderCatalog/10794",
            label: "FDSB-Lab-0051",
          },
          {
            value: "OrderCatalog/10795",
            label: "FDSB-Lab-0052",
          },
          {
            value: "OrderCatalog/10796",
            label: "FDSB-Lab-0053",
          },
          {
            value: "OrderCatalog/10797",
            label: "FDSB-Lab-0054",
          },
          {
            value: "OrderCatalog/10711",
            label: "FDSB-Lab-0023",
          },
          {
            value: "OrderCatalog/10799",
            label: "FDSB-Lab-0056",
          },
          {
            value: "OrderCatalog/10800",
            label: "FDSB-Lab-0057",
          },
          {
            value: "OrderCatalog/10801",
            label: "FDSB-Lab-0058",
          },
          {
            value: "OrderCatalog/10802",
            label: "FDSB-Lab-0059",
          },
          {
            value: "OrderCatalog/10740",
            label: "FDSB-Lab-0017",
          },
          {
            value: "OrderCatalog/10805",
            label: "FDSB-Lab-0062",
          },
          {
            value: "OrderCatalog/10720",
            label: "FDSB-Lab-0044",
          },
          {
            value: "OrderCatalog/10788",
            label: "FDSB-Lab-0049",
          },
          {
            value: "OrderCatalog/10733",
            label: "FDSB-Lab-0024",
          },
          {
            value: "OrderCatalog/10806",
            label: "FDSB-LAB-0063",
          },
          {
            value: "OrderCatalog/10732",
            label: "FDSB-Lab-0048",
          },
          {
            value: "OrderCatalog/10705",
            label: "FDSB-LAB-0002",
          },
          {
            value: "OrderCatalog/10803",
            label: "FDSB-Lab-0060",
          },
          {
            value: "OrderCatalog/10804",
            label: "FDSB-Lab-0061",
          },
          {
            value: "OrderCatalog/10798",
            label: "FDSB-Lab-0055",
          },
          {
            value: "OrderCatalog/10730",
            label: "FDSB-Lab-0046",
          },
          {
            value: "OrderCatalog/10731",
            label: "FDSB-Lab-0047",
          },
          {
            value: "OrderCatalog/10746",
            label: "FDSB-Lab-0019",
          },
          {
            value: "OrderCatalog/10712",
            label: "FDSB-Lab-0013",
          },
          {
            value: "OrderCatalog/10708",
            label: "FDSB-Lab-0010",
          },
          {
            value: "OrderCatalog/10790",
            label: "FDSB-PACK-0002",
          },
          {
            value: "OrderCatalog/10929",
            label: "FDSB-PACK-0006",
          },
        ],
        observationOrderDescription: [
          {
            value: "OrderCatalog/10795",
            label: "A/G Ratio",
          },
          {
            value: "OrderCatalog/10748",
            label: "Albumin",
          },
          {
            value: "OrderCatalog/10751",
            label: "Alkaline Phosphatase(ALP)",
          },
          {
            value: "OrderCatalog/10750",
            label: "ALT(SGPT)",
          },
          {
            value: "OrderCatalog/10801",
            label: "Anti-HBs",
          },
          {
            value: "OrderCatalog/10802",
            label: "Anti-HCV",
          },
          {
            value: "OrderCatalog/10749",
            label: "AST(SGOT)",
          },
          {
            value: "OrderCatalog/10796",
            label: "Bilirubin",
          },
          {
            value: "OrderCatalog/10720",
            label: "Blood Glucose",
          },
          {
            value: "OrderCatalog/10718",
            label: "Creatinine",
          },
          {
            value: "OrderCatalog/10734",
            label: "DIALYSIS ADEQUANCY",
          },
          {
            value: "OrderCatalog/10713",
            label: "ELECTROLYTES & BLOOD GLUCOSE",
          },
          {
            value: "OrderCatalog/10732",
            label: "ESR (Westergren)",
          },
          {
            value: "OrderCatalog/10708",
            label: "Full Blood Picture",
          },
          {
            value: "OrderCatalog/10797",
            label: "Gamma GT",
          },
          {
            value: "OrderCatalog/10794",
            label: "Globulin",
          },
          {
            value: "OrderCatalog/10728",
            label: "HAEMATOLOGY",
          },
          {
            value: "OrderCatalog/10730",
            label: "Haemotocrit(PCV)",
          },
          {
            value: "OrderCatalog/10729",
            label: "HB Level",
          },
          {
            value: "OrderCatalog/10706",
            label: "HbA1c",
          },
          {
            value: "OrderCatalog/10800",
            label: "HBsAg",
          },
          {
            value: "OrderCatalog/10742",
            label: "Hepatitis Bs Ab",
          },
          {
            value: "OrderCatalog/10741",
            label: "Hepatitis Bs Ag",
          },
          {
            value: "OrderCatalog/10743",
            label: "Hepatitis C Antibody",
          },
          {
            value: "OrderCatalog/10799",
            label: "HIV Ag/Ab",
          },
          {
            value: "OrderCatalog/10744",
            label: "HIV I and II Antibody(ELISA)",
          },
          {
            value: "OrderCatalog/10805",
            label: "IPTH",
          },
          {
            value: "OrderCatalog/10711",
            label: "IRON PROFILE",
          },
          {
            value: "OrderCatalog/10709",
            label: "LIPIDS STUDIES",
          },
          {
            value: "OrderCatalog/10746",
            label: "LIVER FUNCTION TEST",
          },
          {
            value: "OrderCatalog/10798",
            label: "MULTIPLE BIOCHEMISTRY PROFILE",
          },
          {
            value: "OrderCatalog/10788",
            label: "NRR-1CV",
          },
          {
            value: "OrderCatalog/10806",
            label: "NRR-2CV",
          },
          {
            value: "OrderCatalog/10705",
            label: "NRR-3CV",
          },
          {
            value: "OrderCatalog/10704",
            label: "Package 4 - B.P Clinical Lab Sdn Bh",
          },
          {
            value: "OrderCatalog/10803",
            label: "Parathyroid Hormone",
          },
          {
            value: "OrderCatalog/10731",
            label: "Platelet Count",
          },
          {
            value: "OrderCatalog/10715",
            label: "Potassium",
          },
          {
            value: "OrderCatalog/10733",
            label: "RENAL BONE METABOLISM",
          },
          {
            value: "OrderCatalog/10804",
            label: "RPR",
          },
          {
            value: "OrderCatalog/10789",
            label: "RTK",
          },
          {
            value: "OrderCatalog/10714",
            label: "Sodium",
          },
          {
            value: "OrderCatalog/10747",
            label: "Total Protein",
          },
          {
            value: "OrderCatalog/10707",
            label: "Urea Post",
          },
          {
            value: "OrderCatalog/10735",
            label: "Urea Pre",
          },
          {
            value: "OrderCatalog/10719",
            label: "Uric Acid",
          },
          {
            value: "OrderCatalog/10745",
            label: "VDRL",
          },
          {
            value: "OrderCatalog/10740",
            label: "VIRAL STUDIES",
          },
          {
            value: "OrderCatalog/10712",
            label: "Virology Screening",
          },
          {
            value: "OrderCatalog/10738",
            label: "WBC",
          },
        ],
        observationFrequency: [
          {
            value: "CodingMaster/11058",
            label: "Days",
          },
          {
            value: "CodingMaster/11059",
            label: "Week",
          },
          {
            value: "CodingMaster/11060",
            label: "Month",
          },
          {
            value: "CodingMaster/11061",
            label: "Year",
          },
        ],
        observationDuration: [],
        by: [
          {
            value: "Practitioner/10427",
            label: " SPE DOCTOR",
          },
          {
            value: "Practitioner/10416",
            label: "Mr PUCAKALUM",
          },
          {
            value: "Practitioner/10415",
            label: "Dr Ruslinda Mustafar",
          },
          {
            value: "Practitioner/10420",
            label: " foresightspecialuser",
          },
          {
            value: "Practitioner/10413",
            label: "Dr Maisarah Jamalonmuhali",
          },
          {
            value: "Practitioner/10414",
            label: "Dr Khairun Nadzar Mohd Dahlan",
          },
          {
            value: "Practitioner/10418",
            label: "Mr Mohamad Sabry Laya",
          },
          {
            value: "Practitioner/10421",
            label: " foresightdoctor",
          },
          {
            value: "Practitioner/10419",
            label: "Mr Lim Li Han",
          },
          {
            value: "Practitioner/10422",
            label: " ainqadoctor",
          },
        ],
      },
    ],
    ReferralOrders: [
      {
        referralToSpecialty: [
          {
            value: "CodingMaster/10453",
            label: "General Medicine",
          },
          {
            value: "CodingMaster/10456",
            label: "Nephrology",
          },
        ],
      },
    ],
    AssessmentOrders: [
      {
        AssessmentOrdersDescription: [
          {
            value: "OrderCatalog/10795",
            label: "A/G Ratio",
          },
          {
            value: "OrderCatalog/10748",
            label: "Albumin",
          },
          {
            value: "OrderCatalog/10751",
            label: "Alkaline Phosphatase(ALP)",
          },
          {
            value: "OrderCatalog/10750",
            label: "ALT(SGPT)",
          },
          {
            value: "OrderCatalog/10801",
            label: "Anti-HBs",
          },
          {
            value: "OrderCatalog/10802",
            label: "Anti-HCV",
          },
          {
            value: "OrderCatalog/10749",
            label: "AST(SGOT)",
          },
          {
            value: "OrderCatalog/10796",
            label: "Bilirubin",
          },
          {
            value: "OrderCatalog/10720",
            label: "Blood Glucose",
          },
          {
            value: "OrderCatalog/10718",
            label: "Creatinine",
          },
          {
            value: "OrderCatalog/10734",
            label: "DIALYSIS ADEQUANCY",
          },
          {
            value: "OrderCatalog/10713",
            label: "ELECTROLYTES & BLOOD GLUCOSE",
          },
          {
            value: "OrderCatalog/10732",
            label: "ESR (Westergren)",
          },
          {
            value: "OrderCatalog/10708",
            label: "Full Blood Picture",
          },
          {
            value: "OrderCatalog/10797",
            label: "Gamma GT",
          },
          {
            value: "OrderCatalog/10794",
            label: "Globulin",
          },
          {
            value: "OrderCatalog/10728",
            label: "HAEMATOLOGY",
          },
          {
            value: "OrderCatalog/10730",
            label: "Haemotocrit(PCV)",
          },
          {
            value: "OrderCatalog/10729",
            label: "HB Level",
          },
          {
            value: "OrderCatalog/10706",
            label: "HbA1c",
          },
          {
            value: "OrderCatalog/10800",
            label: "HBsAg",
          },
          {
            value: "OrderCatalog/10742",
            label: "Hepatitis Bs Ab",
          },
          {
            value: "OrderCatalog/10741",
            label: "Hepatitis Bs Ag",
          },
          {
            value: "OrderCatalog/10743",
            label: "Hepatitis C Antibody",
          },
          {
            value: "OrderCatalog/10799",
            label: "HIV Ag/Ab",
          },
          {
            value: "OrderCatalog/10744",
            label: "HIV I and II Antibody(ELISA)",
          },
          {
            value: "OrderCatalog/10805",
            label: "IPTH",
          },
          {
            value: "OrderCatalog/10711",
            label: "IRON PROFILE",
          },
          {
            value: "OrderCatalog/10709",
            label: "LIPIDS STUDIES",
          },
          {
            value: "OrderCatalog/10746",
            label: "LIVER FUNCTION TEST",
          },
          {
            value: "OrderCatalog/10798",
            label: "MULTIPLE BIOCHEMISTRY PROFILE",
          },
          {
            value: "OrderCatalog/10788",
            label: "NRR-1CV",
          },
          {
            value: "OrderCatalog/10806",
            label: "NRR-2CV",
          },
          {
            value: "OrderCatalog/10705",
            label: "NRR-3CV",
          },
          {
            value: "OrderCatalog/10704",
            label: "Package 4 - B.P Clinical Lab Sdn Bh",
          },
          {
            value: "OrderCatalog/10803",
            label: "Parathyroid Hormone",
          },
          {
            value: "OrderCatalog/10731",
            label: "Platelet Count",
          },
          {
            value: "OrderCatalog/10715",
            label: "Potassium",
          },
          {
            value: "OrderCatalog/10733",
            label: "RENAL BONE METABOLISM",
          },
          {
            value: "OrderCatalog/10804",
            label: "RPR",
          },
          {
            value: "OrderCatalog/10789",
            label: "RTK",
          },
          {
            value: "OrderCatalog/10714",
            label: "Sodium",
          },
          {
            value: "OrderCatalog/10747",
            label: "Total Protein",
          },
          {
            value: "OrderCatalog/10707",
            label: "Urea Post",
          },
          {
            value: "OrderCatalog/10735",
            label: "Urea Pre",
          },
          {
            value: "OrderCatalog/10719",
            label: "Uric Acid",
          },
          {
            value: "OrderCatalog/10745",
            label: "VDRL",
          },
          {
            value: "OrderCatalog/10740",
            label: "VIRAL STUDIES",
          },
          {
            value: "OrderCatalog/10712",
            label: "Virology Screening",
          },
          {
            value: "OrderCatalog/10738",
            label: "WBC",
          },
        ],
      },
    ],
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const cpgAnalyzer = async () => {
    //debugger;
    if (props?.mode == "Simulation") {
      var data = props?.simulationData;
      setState(data);
      setDiagnosisDataFromAi(data?.response?.Diagnosis);
      setLabDataFromAi(data?.response?.LaboratoryOrders);
      setRadiologyDataFromAi(data?.response?.RadiologyOrders);
      setInvesticationDataFromAi(data?.response?.OtherInvestigationOrders);
      setMedicationDataFromAi(data?.response?.MedicationOrders);
      setNurDataFromAi(data?.response?.Procedures);
      setObservationDataFromAi(data?.response?.ObservationOrders);
      setReferralDataFromAi(data?.response?.ReferralOrders);
      setAssesmentDataFromAi(data?.response?.Assessment);
      setAdviceDataFromAi(data?.response?.PatientEducationAndLifeStyleAdvices);
      setRiskFactorsFromAi(data?.response?.RiskFactors);
    } else {
      const url = process.env.REACT_APP_CPG_AI_API;
      const token = localStorage.getItem("JwtToken");
      const payload = {
        serviceid: "6a195c00-1af0-44d3-9fd6-c22d4e4b99b0",
        mode: props?.mode,
        filterValues: {
          patientid: props?.patientid,
          encounterid: props?.encounterid,
          practitionerid: practitionerid,
          practroleid: roleid,
        },
        isincludeinput: true,
      };
      console.log(payload, "cpganalyzer");
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          JwtToken: token,
        },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          if (!response.ok) {
            alert.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong !",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
              tone: true,
            });
          }
          return response.json();
        })
        .then((data) => {
          if (!data?.error) {
            setState(data);
            setDiagnosisDataFromAi(data?.response?.Diagnosis);
            setLabDataFromAi(data?.response?.LaboratoryOrders);
            setRadiologyDataFromAi(data?.response?.RadiologyOrders);
            setInvesticationDataFromAi(
              data?.response?.OtherInvestigationOrders
            );
            setMedicationDataFromAi(data?.response?.MedicationOrders);
            setNurDataFromAi(data?.response?.Procedures);
            setObservationDataFromAi(data?.response?.ObservationOrders);
            setReferralDataFromAi(data?.response?.ReferralOrders);
            setAssesmentDataFromAi(data?.response?.Assessment);
            setAdviceDataFromAi(
              data?.response?.PatientEducationAndLifeStyleAdvices
            );
            setRiskFactorsFromAi(data?.response?.RiskFactors);
          } else {
            props?.setOpenCpg(false);
            alert.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong !",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
              tone: true,
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          props?.setOpenCpg(false);
          alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: error.message,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
        });
    }
    setLoading(false);
  };

  React.useEffect(() => {
    cpgAnalyzer();
  }, []);

  const patientDemographics = state?.inputdata?.PatientDemographic; //Patient Demographic
  const patientVisit = state?.inputdata?.VisitInformation?.[0]; //Patient Information
  const vitalSigns = state?.inputdata?.Vitals; //Vital Signs
  const allergies = state?.inputdata?.Allergy; //Allergies
  const chiefComplaints = state?.inputdata?.ChiefComplaints; //Chief Complaints
  const diagnosis = state?.inputdata?.Diagnosis; //Diagnosis

  const saveCpgEmr = async () => {
    setSaving(true);
    const myHeaders = new Headers();
    myHeaders.append("JwtToken", jwtToken);
    myHeaders.append("Content-Type", "application/json");

    //diagnosis Payload Construction
    const diagnosisPayload = [];
    for (const index in diagnosisDataFromAi) {
      if (diagnosisDataFromAi[index]?.orderCheck !== false) {
        let obj = {
          diagcode: diagnosisDataFromAi[index]?.diagnosisCodeDescription?.value,
          use: diagnosisDataFromAi[index]?.use?.value,
          verificationstatus:
            diagnosisDataFromAi[index]?.verificationStatus?.value,
          onsetval: 1, //----------------------------
          onsetuom: diagnosisDataFromAi[index]?.duration?.value,
        };
        diagnosisPayload.push(obj);
      }
    }
    //labOrder Payload Construction
    const labOrderPayload = [];
    for (const index in labDataFromAi) {
      if (labDataFromAi[index]?.orderCheck !== false) {
        let obj = {
          orderCatalog: `${labDataFromAi[index]?.orderCodeDescription?.value} - ${labDataFromAi[index]?.orderCodeDescription?.label}`,
          OrderNature: labDataFromAi[index]?.nature?.value,
          priority: labDataFromAi[index]?.priority?.value,
          orderDateTime: parseInt(labDataFromAi[index]?.dateTime),
          occuranceStartDate: parseInt(
            labDataFromAi[index]?.recurrentData?.startsOn?.dateTime
          ),
          orderQty: 1,
          recurrent: [
            {
              every_date: parseInt(
                labDataFromAi[index]?.recurrentData?.every?.value
              ),
              every_days:
                labDataFromAi[index]?.recurrentData?.every?.periodicity?.value,
              starts_on: parseInt(
                labDataFromAi[index]?.recurrentData?.startsOn?.dateTime
              ),
              end_on_occurance_date: 0,
              end_on_occurance: parseInt(
                labDataFromAi[index]?.recurrentData?.endsAfter?.endsAfter
              ),
              reoccurance_id: "",
              preferredDays:
                labDataFromAi[index]?.recurrentData?.preferrence?.preferredDays,
              preferredWeeks:
                labDataFromAi[index]?.recurrentData?.preferrence
                  ?.preferredWeeks,
            },
          ],
        };
        labOrderPayload.push(obj);
      }
    }
    //radiology Payload Construction
    const radiologyPayload = [];
    for (const index in radiologyDataFromAi) {
      if (radiologyDataFromAi[index]?.orderCheck !== false) {
        let obj = {
          orderCatalog: `${radiologyDataFromAi[index]?.orderCodeDescription?.value} - ${radiologyDataFromAi[index]?.orderCodeDescription?.label}`,
          OrderNature: radiologyDataFromAi[index]?.nature?.value || "", //---------------------------------------------
          priority: radiologyDataFromAi[index]?.priority?.value,
          orderDateTime: parseInt(radiologyDataFromAi[index]?.dateTime),
          occuranceStartDate: parseInt(
            radiologyDataFromAi[index]?.recurrentData?.startsOn?.dateTime
          ),
          orderQty: 1,
          recurrent: [
            {
              every_date: parseInt(
                radiologyDataFromAi[index]?.recurrentData?.every?.value
              ),
              every_days:
                radiologyDataFromAi[index]?.recurrentData?.every?.periodicity
                  ?.value,
              starts_on: parseInt(
                radiologyDataFromAi[index]?.recurrentData?.startsOn?.dateTime
              ),
              end_on_occurance_date: 0,
              end_on_occurance: parseInt(
                radiologyDataFromAi[index]?.recurrentData?.endsAfter?.endsAfter
              ),
              reoccurance_id: "",
              preferredDays:
                radiologyDataFromAi[index]?.recurrentData?.preferrence
                  ?.preferredDays,
              preferredWeeks:
                radiologyDataFromAi[index]?.recurrentData?.preferrence
                  ?.preferredWeeks,
            },
          ],
        };
        radiologyPayload.push(obj);
      }
    }

    //medication Payload Construction

    const medicationPayload = [];
    for (const index in medicationDataFromAi) {
      if (medicationDataFromAi[index]?.orderCheck !== false) {
        let obj = {
          drugType: "DrugType/10004",
          drugCode: medicationDataFromAi[index]?.orderCodeDescription?.value,
          dosageValue: parseInt(medicationDataFromAi[index]?.dose?.value) || 0,
          dosageUOM: medicationDataFromAi[index]?.uom?.value,
          drug_category: "DrugCategory/10004",
          dosageFreqCode: medicationDataFromAi[index]?.frequency?.value,
          dosageDurationValue: parseInt(
            medicationDataFromAi[index]?.dose?.value
          )|| 0,
          dosageDurationUOM: "DrugUOM/10007",
          orderdescription:
            medicationDataFromAi[index]?.orderCodeDescription?.label,
        };
        medicationPayload.push(obj);
      }
    }

    //Procedure(nur) Payload Construction
    const procedurePayload = [];
    for (const index in nurDataFromAi) {
      if (nurDataFromAi[index]?.orderCheck !== false) {
        let obj = {
          orderCatalog: `${nurDataFromAi[index]?.orderCodeDescription?.value} - ${nurDataFromAi[index]?.orderCodeDescription?.label}`,
          OrderNature: "CodingMaster/11552", //---------------------------------------------
          priority: nurDataFromAi[index]?.priority?.value,
          orderDateTime: parseInt(nurDataFromAi[index]?.dateTime),
          occuranceStartDate: 0, //---------------------------------------------
          orderQty: 1, //---------------------------------------------
          recurrent: [
            //---------------------------------------------
            {
              every_date: 0,
              every_days: "",
              starts_on: 0,
              end_on_occurance_date: 0,
              end_on_occurance: 0,
              reoccurance_id: "",
              preferredDays: [],
              preferredWeeks: [],
            },
          ],
        };
        procedurePayload.push(obj);
      }
    }

    //other order payload construction
    const otherOrderPayload = [];
    for (const index in investicationDataFromAi) {
      if (investicationDataFromAi[index]?.orderCheck !== false) {
        let obj = {
          orderCatalog: `${investicationDataFromAi[index]?.orderCodeDescription?.value} - ${investicationDataFromAi[index]?.orderCodeDescription?.label}`,
          OrderNature: "",
          priority: investicationDataFromAi[index]?.priority?.value,
          orderDateTime: parseInt(investicationDataFromAi[index]?.dateTime),
          occuranceStartDate:
            parseInt(
              investicationDataFromAi[index]?.recurrentData?.startsOn?.dateTime
            ) || 0,
          orderQty: 1,
          Oltype: "",
          recurrent: [
            {
              every_date:
                parseInt(
                  investicationDataFromAi[index]?.recurrentData?.every?.value
                ) || 0,
              every_days:
                investicationDataFromAi[index]?.recurrentData?.every
                  ?.periodicity?.value || "",
              starts_on:
                parseInt(
                  investicationDataFromAi[index]?.recurrentData?.startsOn
                    ?.dateTime
                ) || 0,
              end_on_occurance_date: 0,
              end_on_occurance:
                parseInt(
                  investicationDataFromAi[index]?.recurrentData?.endsAfter
                    ?.endsAfter
                ) || 0,
              reoccurance_id: "",
              preferredDays: [],
              preferredWeeks: [],
            },
          ],
        };
        otherOrderPayload.push(obj);
      }
    }

    //referral order payload construction
    const referralOrderPayload = [];
    for (const index in referralDataFromAi) {
      if (referralDataFromAi[index]?.orderCheck !== false) {
        let obj = {
          RefSourceLetDate: parseInt(referralDataFromAi[index]?.dateTime),
          RefSourceLetRef: "",
          HealthCareServiceRequested: "", //"Organization/10163",
          RequestorSpecialty: "", // "CodingMaster/10452",
          RequestorPractioner: practitionerid, //"Practitioner/10416", //login user
          RequestorPractionerName: "", //"CF001doctor",
          recipientSpecialty:
            referralDataFromAi[index]?.referralToSpecialty?.value, // "CodingMaster/10845", ///speciality
          recipientPracName: "", // "CF002doctor",
          recipientPract: "", //"Practitioner/10412",
          receiptfacility: "", // "Organization/10164",
          consent: "",
          concentattachment: [
            {
              _id: "",
              fileName: "",
              fileid: "",
              filetype: "",
              base64string: "",
              url: "",
            },
          ],
          remarks: "",
          attachment: [
            {
              _id: "",
              fileName: "",
              fileid: "",
              filetype: "",
              base64string: "",
              url: "",
            },
          ],
        };
        referralOrderPayload.push(obj);
      }
    }

    const payload = [
      {
        facilityid: _facility_,
        tenantid: __tenantid__,
        patient_Id: props?.patientid,
        encountertype: "CodingMaster/10162", //---------------------------------------------
        encounter_Id: props?.encounterid,
        practitioner_id: practitionerid,
        practitionerrole_id: roleid,
        labOrder: labOrderPayload,
        nursingProcedure: [],
        packageOrder: [],
        diagnosis: diagnosisPayload,
        treatmentProc: procedurePayload,
        radiologyOrder: radiologyPayload,
        medicationOrder: medicationPayload,
        clinicalDocs: [],
        referral: referralOrderPayload,
        otherOrders: otherOrderPayload,
      },
    ];
    const raw = JSON.stringify(payload);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(urls.saveCpgEmr, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("cpgresult", result);
        props?.setOpenCpg(false);
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: "Orders Placed in EMR !",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
        //hard refresh
        // window.location.reload();
      })
      .catch((error) => {
        console.error("cpgresult", error);
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong !",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      });
    setSaving(false);
  };
  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      {/* Banner */}
      {!loading ? (
        <>
          <Grid container xs={12} p={1} gap={1} justifyContent={"center"}>
            <Grid item xs={5.9}>
              <Paper elevation={0} variant="outlined">
                <Grid
                  container
                  spacing={2}
                  margin={0}
                  sx={{ backgroundColor: "#F0F4FF" }}
                >
                  <Grid item xs={1} style={{ padding: 0 }}>
                    <Avatar
                      sx={{
                        bgcolor: UIColor.primaryColor.color,
                        height: "100%",
                        width: "100%",
                        borderRadius: "3px",
                      }}
                      variant="square"
                    >
                      <PersonIcon></PersonIcon>
                    </Avatar>
                  </Grid>
                  <Grid item xs={11} style={{ padding: 0 }}>
                    <Grid
                      container
                      spacing={1}
                      style={{ margin: 0 }}
                      alignItems={"center"}
                      sx={{ backgroundColor: "#f1f6fc" }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant="caption"
                          fontSize={"14px"}
                          fontWeight={"600"}
                        >
                          {patientDemographics?.name} &nbsp; &nbsp;&nbsp;
                        </Typography>
                        <Typography variant="caption" fontSize={"14px"}>
                          {patientDemographics?.age?.slice(0, 3)} &nbsp;
                        </Typography>
                        <Typography variant="caption" fontSize={"14px"}>
                          {patientDemographics?.gender?.slice(0, 1)}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        container
                        gap={0.5}
                        alignItems={"center"}
                      >
                        <Grid item>
                          <AccountBoxIcon color="primary" fontSize={"small"} />
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle2" fontSize={"10px"}>
                            {patientDemographics?.patientid}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={3}
                        container
                        gap={0.5}
                        alignItems={"center"}
                      >
                        <Grid item>
                          <PhoneIcon color="primary" fontSize={"small"} />
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle2" fontSize={"10px"}>
                            +60-0168989891
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={5.9}>
              <Paper elevation={0} variant="outlined" sx={{marginRight:"20px"}}>
                <Grid
                  container
                  spacing={2}
                  margin={0}
                  sx={{ backgroundColor: "#f1f6fc" }}
                >
                  <Grid item xs={1} style={{ padding: 0 }}>
                    <Avatar
                      sx={{
                        bgcolor: UIColor.primaryColor.color,
                        height: "100%",
                        width: "100%",
                        borderRadius: "3px",
                      }}
                      variant="square"
                    >
                      <CalendarIcon></CalendarIcon>
                    </Avatar>
                  </Grid>
                  <Grid item xs={11} style={{ padding: 0 }}>
                    <Grid
                      container
                      spacing={1}
                      style={{ margin: 0 }}
                      alignItems={"center"}
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant="caption"
                          fontSize={"14px"}
                          fontWeight={"600"}
                        >
                          {patientVisit?.encounternumber} &nbsp; &nbsp;&nbsp;
                        </Typography>
                        <Typography variant="caption" fontSize={"14px"}>
                          ▪️ {patientVisit?.encountertype} &nbsp;&nbsp;&nbsp;
                        </Typography>
                        <Typography variant="caption" fontSize={"14px"}>
                          ▪️ {patientVisit?.speciality}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        container
                        gap={0.5}
                        alignItems={"center"}
                      >
                        <Grid item>
                          <PendingActionsIcon
                            color="primary"
                            fontSize={"small"}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle2" fontSize={"10px"}>
                            {patientVisit?.visitdatetime}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        container
                        gap={0.5}
                        alignItems={"center"}
                      >
                        <Grid item>
                          <HotelIcon color="primary" fontSize={"small"} />
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle2" fontSize={"10px"}>
                            {patientVisit?.bednumber}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        container
                        gap={0.5}
                        alignItems={"center"}
                      >
                        <Grid item>
                          <TourIcon color="primary" fontSize={"small"} />
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle2" fontSize={"10px"}>
                            {patientVisit?.status}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} overflowX={"hidden"}>
            {/* Left */}
            <Grid item xs={9.1}>
              {/* Risk Factors */}
              <Paper sx={{ padding: "10px", margin: "10px", backgroundColor: "#f1f6fc", }} variant="outlined">
                <Grid container rowSpacing={2} margin={0}>
                  <Grid
                    item
                    xs={11}
                    style={{ padding: 3 }}
                    container
                    alignItems="center"
                  >
                    <Typography variant="button" fontSize={"12px"} fontWeight={600}>
                      Summary
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{ padding: 0 }}
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    {/* place for add new order */}
                  </Grid>
                  <Grid item xs={12} style={{ padding: 0 }}>
                    <Divider></Divider>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems={"flex-end"}
                    justifyContent={"space-between"}
                  >
                    <Grid item xs={12}>
                      <RiskFactor riskHtml={riskFactorsFromAi} />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              {/* diagnosis */}
              {diagnosisDataFromAi ? (
                <Paper
                  elevation={5}
                  sx={{
                    padding: "10px",
                    margin: "10px",
                    backgroundColor: "#f1f6fc",
                  }}
                >
                  <Grid container rowSpacing={2} margin={0}>
                    <Grid
                      item
                      xs={11.5}
                      style={{ padding: 3 }}
                      container
                      alignItems="center"
                    >
                      <Typography variant="button" fontSize={"12px"} fontWeight={600}>
                        Diagnosis 
                      </Typography>
                      {/* <Avatar sx={{ height: "22px", width: "22px", marginLeft: "5px", bgcolor: blue[600] }}>
                        <Typography fontSize={"12px"}>
                          {diagnosisDataFromAi.length ? diagnosisDataFromAi.length : 0}
                        </Typography>
                      </Avatar> */}
                      {collapseAllDiagnosis ?
                        <IconButton onClick={() =>{
                          setCollapseAllDiagnosis(false);
                          // setCollapseDiagnosis(false);
                        }}><InfoIcon color="primary" fontSize="8px" /></IconButton>
                        :
                        <IconButton onClick={() => {
                          setCollapseAllDiagnosis(true);
                          setCollapseDiagnosis(true);
                        }}><InfoOutlinedIcon color="primary" fontSize="8px" /></IconButton>
                      }
                    </Grid>
                    <Grid
                      item
                      xs={0.5}
                      style={{ padding: 0 }}
                      container
                      justifyContent="center"
                      alignItems="center"
                    >
                      {/* place for add new order */}
                      {collapseDiagnosis ?
                        <IconButton onClick={() => {
                          setCollapseDiagnosis(false);
                          setCollapseAllDiagnosis(false);
                        }}><ExpandLessIcon color="primary" fontSize="8px"  /></IconButton>
                        :
                        <IconButton onClick={() => setCollapseDiagnosis(true)}><ExpandMoreIcon /></IconButton>
                      }
                    
                    </Grid>
                    <Grid item xs={12} style={{ padding: 0 }}>
                      <Divider></Divider>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems={"flex-end"}
                      justifyContent={"space-between"}
                      pt={0}
                    >
                      <Grid item xs={12} pt={0}>
                         <Collapse in={collapseDiagnosis}>
                          {diagnosisDataFromAi?.map((diagnosis, i) => (
                            <AddDiagnosisOrder
                              diagnosis={diagnosis}
                              i={i}
                              dataFromAi={diagnosisDataFromAi}
                              setDataFromAi={setDiagnosisDataFromAi}
                              lookup={lookup.Diagnosis}
                              collapseAllDiagnosis={collapseAllDiagnosis}
                            />
                          ))}
                          </Collapse>
                        </Grid>
                      </Grid>
                  </Grid>

                  {/* Lab Order */}
                  {labDataFromAi ? (
                    // <Paper sx={{ padding: "10px", margin: "10px" }}>
                    <Grid container rowSpacing={2} margin={0}>
                      <Grid
                        item
                        xs={11.5}
                        style={{ padding: 3 }}
                        container
                        alignItems="center"
                      >
                        <Typography variant="button" fontSize={"12px"} fontWeight={600}>
                          Lab Order
                        </Typography>
                        {/* <Avatar sx={{ height: "22px", width: "22px", marginLeft: "5px", bgcolor: blue[600] }}>
                        <Typography fontSize={"12px"}>
                          {labDataFromAi.length ? labDataFromAi.length : 0}
                          </Typography>
                        </Avatar> */}
                          {collapseAllLabOrder ?
                            <IconButton onClick={() =>{
                              setCollapseAllLabOrder(false);
                              // setCollapseLabOrder(false);
                            }}><InfoIcon color="primary" fontSize="8px" /></IconButton>
                            :
                            <IconButton onClick={() => {
                              setCollapseAllLabOrder(true);
                              setCollapseLabOrder(true);
                            }}><InfoOutlinedIcon color="primary" fontSize="8px" /></IconButton>
                          }
                      </Grid>
                      <Grid
                        item
                        xs={0.5}
                        style={{ padding: 0 }}
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        {/* place for add new order */}
                        {collapseLabOrder ?
                        <IconButton onClick={() => {
                          setCollapseLabOrder(false);
                          setCollapseAllLabOrder(false);
                        }}><ExpandLessIcon color="primary" fontSize="8px"  /></IconButton>
                        :
                        <IconButton onClick={() => setCollapseLabOrder(true)}><ExpandMoreIcon /></IconButton>
                      }
                      </Grid>
                      <Grid item xs={12} style={{ padding: 0 }}>
                        <Divider></Divider>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        container
                        alignItems={"flex-end"}
                        justifyContent={"space-between"}
                      >
                        <Grid item xs={12}>
                          <Collapse in={collapseLabOrder}>
                            {labDataFromAi?.map((labOrder, i) => (
                              <AddLabOrder
                                labOrder={labOrder}
                                i={i}
                                dataFromAi={labDataFromAi}
                                setDataFromAi={setLabDataFromAi}
                                lookup={lookup?.LaboratoryOrder}
                                collapseAllLabOrder={collapseAllLabOrder}
                              />
                            ))}
                          </Collapse>   
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    // </Paper>
                    <> </>
                  )}

                  {/* Radiology */}
                  {radiologyDataFromAi ? (
                    // <Paper sx={{ padding: "10px", margin: "10px" }}>
                    <Grid container rowSpacing={2} margin={0}>
                      <Grid
                        item
                        xs={11.5}
                        style={{ padding: 3 }}
                        container
                        //justifyContent="center"
                        alignItems="center"
                      >
                        <Typography variant="button" fontSize={"12px"} fontWeight={600}>
                          Radiology
                        </Typography>
                        {/* <Avatar sx={{ height: "22px", width: "22px", marginLeft: "5px", bgcolor: blue[600] }}>
                        <Typography fontSize={"12px"}>
                          {radiologyDataFromAi.length ? radiologyDataFromAi.length : 0}
                        </Typography>
                        </Avatar> */}
                        {collapseAllRadOrder ?
                            <IconButton onClick={() =>{
                              setCollapseAllRadOrder(false);
                              // setCollapseLabOrder(false);
                            }}><InfoIcon color="primary" fontSize="8px" /></IconButton>
                            :
                            <IconButton onClick={() => {
                              setCollapseAllRadOrder(true);
                              setCollapseRadOrder(true);
                            }}><InfoOutlinedIcon color="primary" fontSize="8px" /></IconButton>
                          }
                      </Grid>
                      <Grid
                        item
                        xs={0.5}
                        style={{ padding: 0 }}
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        {/* place for add new order */}
                        {collapseRadOrder ?
                        <IconButton onClick={() => {
                          setCollapseRadOrder(false);
                          setCollapseAllRadOrder(false);
                        }}><ExpandLessIcon color="primary" fontSize="8px"  /></IconButton>
                        :
                        <IconButton onClick={() => setCollapseRadOrder(true)}><ExpandMoreIcon /></IconButton>
                      }
                      </Grid>
                      <Grid item xs={12} style={{ padding: 0 }}>
                        <Divider></Divider>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        container
                        alignItems={"flex-end"}
                        justifyContent={"space-between"}
                      >
                        <Grid item xs={12}>
                          <Collapse in={collapseRadOrder}>
                            {radiologyDataFromAi?.map((radiology, i) => (
                              <AddRadiologyOrder
                                radiology={radiology}
                                i={i}
                                dataFromAi={radiologyDataFromAi}
                                setDataFromAi={setRadiologyDataFromAi}
                                lookup={lookup.RadiologyOrder}
                                collapseAllRadOrder={collapseAllRadOrder}
                              />
                            ))}
                          </Collapse>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {/* Investigation order */}
                  {investicationDataFromAi ? (
                    // <Paper sx={{ padding: "10px", margin: "10px" }}>
                    <Grid container rowSpacing={2} margin={0}>
                      <Grid
                        item
                        xs={11.5}
                        style={{ padding: 3 }}
                        container
                        alignItems="center"
                      >
                        <Typography variant="button" fontSize={"12px"} fontWeight={600}>
                          Other Investigation Order
                        </Typography>
                        {/* <Avatar sx={{ height: "22px", width: "22px", marginLeft: "5px", bgcolor: blue[600] }}>
                        <Typography fontSize={"12px"}>
                          {investicationDataFromAi.length ? investicationDataFromAi.length : 0}
                        </Typography>
                        </Avatar> */}
                        {collapseAllInvestOrder ?
                            <IconButton onClick={() =>{
                              // setCollapseInvestOrder(false);
                              setCollapseAllInvestOrder(false);
                            }}><InfoIcon color="primary" fontSize="8px" /></IconButton>
                            :
                            <IconButton onClick={() => {
                              setCollapseInvestOrder(true);
                              setCollapseAllInvestOrder(true);
                            }}><InfoOutlinedIcon color="primary" fontSize="8px" /></IconButton>
                        }
                      </Grid>
                      <Grid
                        item
                        xs={0.5}
                        style={{ padding: 0 }}
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        {/* place for add new order */}
                        {collapseInvestOrder ?
                        <IconButton onClick={() => {
                          setCollapseInvestOrder(false);
                          setCollapseAllInvestOrder(false);
                        }}><ExpandLessIcon color="primary" fontSize="8px"  /></IconButton>
                        :
                        <IconButton onClick={() => setCollapseInvestOrder(true)}><ExpandMoreIcon /></IconButton>
                      }
                      </Grid>
                      <Grid item xs={12} style={{ padding: 0 }}>
                        <Divider></Divider>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        container
                        alignItems={"flex-end"}
                        justifyContent={"space-between"}
                      >
                        <Grid item xs={12}>
                           <Collapse in={collapseInvestOrder}>
                              {investicationDataFromAi?.map((investigation, i) => (
                                <AddInvestigationOrder
                                  investigation={investigation}
                                  i={i}
                                  dataFromAi={investicationDataFromAi}
                                  setDataFromAi={setInvesticationDataFromAi}
                                  lookup={lookup?.OtherInvestigationOrders}
                                  collapseAllInvestOrder={collapseAllInvestOrder}
                                />
                              ))}
                            </Collapse>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {/* Medication order */}
                  {medicationDataFromAi ? (
                    // <Paper sx={{ padding: "10px", margin: "10px" }}>
                    <Grid container rowSpacing={2} margin={0}>
                      <Grid
                        item
                        xs={11.5}
                        style={{ padding: 3 }}
                        container
                        alignItems="center"
                      >
                        <Typography variant="button" fontSize={"12px"} fontWeight={600}>
                          Medication Order
                        </Typography>
                        {/* <Avatar sx={{ height: "22px", width: "22px", marginLeft: "5px", bgcolor: blue[600] }}>
                        <Typography fontSize={"12px"}>
                          {medicationDataFromAi.length ? medicationDataFromAi.length : 0}
                        </Typography>
                        </Avatar> */}
                        {collapseAllMedOrder ?
                            <IconButton onClick={() =>{
                              // setCollapseMedOrder(false);
                              setCollapseAllMedOrder(false);
                            }}><InfoIcon color="primary" fontSize="8px" /></IconButton>
                            :
                            <IconButton onClick={() => {
                              setCollapseMedOrder(true);
                              setCollapseAllMedOrder(true);
                            }}><InfoOutlinedIcon color="primary" fontSize="8px" /></IconButton>
                          }
                      </Grid>
                      <Grid
                        item
                        xs={0.5}
                        style={{ padding: 0 }}
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        {/* place for add new order */}
                        {collapseMedOrder ?
                        <IconButton onClick={() => {
                          setCollapseMedOrder(false);
                          setCollapseAllMedOrder(false);
                        }}><ExpandLessIcon color="primary" fontSize="8px"  /></IconButton>
                        :
                        <IconButton onClick={() => setCollapseMedOrder(true)}><ExpandMoreIcon /></IconButton>
                      }
                      </Grid>
                      <Grid item xs={12} style={{ padding: 0 }}>
                        <Divider></Divider>
                      </Grid>
                      {/* -------------------------- */}
                      <Grid
                        item
                        xs={12}
                        container
                        alignItems={"flex-end"}
                        justifyContent={"space-between"}
                      >
                        <Grid item xs={12}>
                          <Collapse in={collapseMedOrder}>
                            {medicationDataFromAi?.map((medication, i) => (
                              <AddMedicationOrder
                                medication={medication}
                                i={i}
                                dataFromAi={medicationDataFromAi}
                                setDataFromAi={setMedicationDataFromAi}
                                lookup={lookup?.MedicationOrder}
                                collapseAllMedOrder={collapseAllMedOrder}
                              />
                            ))}
                          </Collapse>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {/* Nursing Procedure */}
                  {nurDataFromAi ? (
                    // <Paper sx={{ padding: "10px", margin: "10px" }}>
                    <Grid container rowSpacing={2} margin={0}>
                      <Grid
                        item
                        xs={11.5}
                        style={{ padding: 3 }}
                        container
                        alignItems="center"
                      >
                        <Typography variant="button" fontSize={"12px"} fontWeight={600}>
                          Procedures
                        </Typography>
                        {/* <Avatar sx={{ height: "22px", width: "22px", marginLeft: "5px", bgcolor: blue[600] }}>
                        <Typography fontSize={"12px"}>
                          {nurDataFromAi.length ? nurDataFromAi.length : 0}
                        </Typography>
                        </Avatar> */}
                        {collapseAllNurOrder ?
                            <IconButton onClick={() =>{
                              // setCollapseNurOrder(false);
                              setCollapseAllNurOrder(false);
                            }}><InfoIcon color="primary" fontSize="8px" /></IconButton>
                            :
                            <IconButton onClick={() => {
                              setCollapseNurOrder(true);
                              setCollapseAllNurOrder(true);
                            }}><InfoOutlinedIcon color="primary" fontSize="8px" /></IconButton>
                        }
                      </Grid>
                      <Grid
                        item
                        xs={0.5}
                        style={{ padding: 0 }}
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        {/* place for add new order */}
                      {collapseNurOrder ?
                        <IconButton onClick={() => {
                          setCollapseNurOrder(false);
                          setCollapseAllNurOrder(false);
                        }}><ExpandLessIcon color="primary" fontSize="8px"  /></IconButton>
                        :
                        <IconButton onClick={() => setCollapseNurOrder(true)}><ExpandMoreIcon /></IconButton>
                      }
                      </Grid>
                      <Grid item xs={12} style={{ padding: 0 }}>
                        <Divider></Divider>
                      </Grid>
                      {/* -------------------------- */}
                      <Grid item xs={12} container alignItems={"flex-end"}>
                        <Grid item xs={12}>
                          <Collapse in={collapseNurOrder}>
                          {nurDataFromAi?.map((labOrder, i) => (
                            <AddNurProOrder
                              labOrder={labOrder}
                              i={i}
                              dataFromAi={nurDataFromAi}
                              setDataFromAi={setNurDataFromAi}
                              lookup={lookup?.Procedures}
                              collapseAllNurOrder={collapseAllNurOrder}
                            />
                          ))}
                            </Collapse>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {/* {Observation Orders}*/}
                  {observationDataFromAi ? (
                    // <Paper sx={{ padding: "10px", margin: "10px" }}>
                    <Grid container rowSpacing={2} margin={0}>
                      <Grid
                        item
                        xs={11.5}
                        style={{ padding: 3 }}
                        container
                        alignItems="center"
                      >
                        <Typography variant="button" fontSize={"12px"} fontWeight={600}>
                          Observation Order
                        </Typography>
                        {/* <Avatar sx={{ height: "22px", width: "22px", marginLeft: "5px", bgcolor: blue[600] }}>
                        <Typography fontSize={"12px"}>
                          {observationDataFromAi.length ? observationDataFromAi.length : 0}
                        </Typography>
                        </Avatar> */}
                        {collapseAllObsOrder ?
                            <IconButton onClick={() =>{
                              // setCollapseObsOrder(false);
                              setCollapseAllObsOrder(false);
                            }}><InfoIcon color="primary" fontSize="8px" /></IconButton>
                            :
                            <IconButton onClick={() => {
                              setCollapseObsOrder(true);
                              setCollapseAllObsOrder(true);
                            }}><InfoOutlinedIcon color="primary" fontSize="8px" /></IconButton>
                          }
                      </Grid>
                      <Grid
                        item
                        xs={0.5}
                        style={{ padding: 0 }}
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        {/* place for add new order */}
                        {collapseObsOrder ?
                          <IconButton onClick={() => {
                            setCollapseObsOrder(false);
                            setCollapseAllObsOrder(false);
                          }}><ExpandLessIcon color="primary" fontSize="8px"  /></IconButton>
                          :
                          <IconButton onClick={() => setCollapseObsOrder(true)}><ExpandMoreIcon /></IconButton>
                        }
                      </Grid>
                      <Grid item xs={12} style={{ padding: 0 }}>
                        <Divider></Divider>
                      </Grid>
                      {/* -------------------------- */}
                      <Grid
                        item
                        xs={12}
                        container
                        alignItems={"flex-end"}
                        justifyContent={"space-between"}
                      >
                        <Grid item xs={12}>
                          <Collapse in={collapseObsOrder}>
                          {observationDataFromAi?.map((Observation, i) => (
                            <AddObservationOrder
                              Observation={Observation}
                              i={i}
                              dataFromAi={observationDataFromAi}
                              setDataFromAi={setObservationDataFromAi}
                              lookup={lookup?.ObservationOrders}
                              collapseAllObsOrder={collapseAllObsOrder}
                            />
                          ))}
                            </Collapse>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {/* {Referral  Orders} */}
                  {referralDataFromAi ? (
                    // <Paper sx={{ padding: "10px", margin: "10px" }}>
                    <Grid container rowSpacing={2} margin={0}>
                      <Grid
                        item
                        xs={11.5}
                        style={{ padding: 3 }}
                        container
                        alignItems="center"
                      >
                        <Typography variant="button" fontSize={"12px"} fontWeight={600}>
                          Referral Orders
                        </Typography>
                        {/* <Avatar sx={{ height: "22px", width: "22px", marginLeft: "5px", bgcolor: blue[600] }}>
                        <Typography fontSize={"12px"}>
                          {referralDataFromAi.length ? referralDataFromAi.length : 0}
                        </Typography>
                        </Avatar> */}
                        {collapseAllRefOrder ?
                            <IconButton onClick={() =>{
                              // setCollapseRefOrder(false);
                              setCollapseAllRefOrder(false);
                            }}><InfoIcon color="primary" fontSize="8px" /></IconButton>
                            :
                            <IconButton onClick={() => {
                              setCollapseRefOrder(true);
                              setCollapseAllRefOrder(true);
                            }}><InfoOutlinedIcon color="primary" fontSize="8px" /></IconButton>
                          }
                      </Grid>
                      <Grid
                        item
                        xs={0.5}
                        style={{ padding: 0 }}
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        {/* place for add new order */}
                        {collapseRefOrder ?
                          <IconButton onClick={() => {
                            setCollapseRefOrder(false);
                            setCollapseAllRefOrder(false);
                          }}><ExpandLessIcon color="primary" fontSize="8px"  /></IconButton>
                          :
                          <IconButton onClick={() => setCollapseRefOrder(true)}><ExpandMoreIcon /></IconButton>
                        }
                      </Grid>
                      <Grid item xs={12} style={{ padding: 0 }}>
                        <Divider></Divider>
                      </Grid>
                      {/* -------------------------- */}
                      <Grid
                        item
                        xs={12}
                        container
                        alignItems={"flex-end"}
                        justifyContent={"space-between"}
                      >
                        <Grid item xs={12}>
                          <Collapse in={collapseRefOrder}>
                          {referralDataFromAi?.map((referral, i) => (
                            <AddReferralOrder
                              referral={referral}
                              i={i}
                              dataFromAi={referralDataFromAi}
                              setDataFromAi={setReferralDataFromAi}
                              lookup={lookup?.ReferralOrders}
                              collapseAllRefOrder={collapseAllRefOrder}
                            />
                          ))}
                            </Collapse>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {/* {Assesment  Orders} */}
                  {assesmentDataFromAi ? (
                    // <Paper sx={{ padding: "10px", margin: "10px" }}>
                    <Grid container rowSpacing={2} margin={0}>
                      <Grid
                        item
                        xs={11.5}
                        style={{ padding: 3 }}
                        container
                        alignItems="center"
                      >
                        <Typography variant="button" fontSize={"12px"} fontWeight={600}>
                          Assesment Orders
                        </Typography>
                        {/* <Avatar sx={{ height: "22px", width: "22px", marginLeft: "5px", bgcolor: blue[600] }}>
                        <Typography fontSize={"12px"}>
                          {assesmentDataFromAi.length ? assesmentDataFromAi.length : 0}
                        </Typography>
                        </Avatar> */}
                        {collapseAllAssesOrder ?
                            <IconButton onClick={() =>{
                              // setCollapseAssesOrder(false);
                              setCollapseAllAssesOrder(false);
                            }}><InfoIcon color="primary" fontSize="8px" /></IconButton>
                            :
                            <IconButton onClick={() => {
                              setCollapseAssesOrder(true);
                              setCollapseAllAssesOrder(true);
                            }}><InfoOutlinedIcon color="primary" fontSize="8px" /></IconButton>
                          }
                      </Grid>
                      <Grid
                        item
                        xs={0.5}
                        style={{ padding: 0 }}
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        {/* place for add new order */}
                        {collapseAssesOrder ?
                          <IconButton onClick={() => {
                            setCollapseAssesOrder(false);
                            setCollapseAllAssesOrder(false);
                          }}><ExpandLessIcon color="primary" fontSize="8px"  /></IconButton>
                          :
                          <IconButton onClick={() => setCollapseAssesOrder(true)}><ExpandMoreIcon /></IconButton>
                        }
                      </Grid>
                      <Grid item xs={12} style={{ padding: 0 }}>
                        <Divider></Divider>
                      </Grid>
                      {/* -------------------------- */}
                      <Grid
                        item
                        xs={12}
                        container
                        alignItems={"flex-end"}
                        justifyContent={"space-between"}
                      >
                        <Grid item xs={12}>
                          <Collapse in={collapseAssesOrder}>
                          {assesmentDataFromAi?.map((assesment, i) => (
                            <AddAssesmentOrder
                              assesment={assesment}
                              i={i}
                              dataFromAi={assesmentDataFromAi}
                              setDataFromAi={setAssesmentDataFromAi}
                              lookup={lookup?.AssessmentOrders}
                              collapseAllAssesOrder={collapseAllAssesOrder}
                            />
                          ))}
                            </Collapse>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {/* {Lifestyle advice} */}
                  {adviceDataFromAi ? (
                    // <Paper sx={{ padding: "10px", margin: "10px" }}>
                    <Grid container rowSpacing={2} margin={0}>
                      <Grid
                        item
                        xs={11.5}
                        style={{ padding: 3 }}
                        container
                        alignItems="center"
                      >
                        <Typography variant="button" fontSize={"12px"} fontWeight={600}>
                          LifeStyle Advice
                        </Typography>
                        {/* <Avatar sx={{ height: "22px", width: "22px", marginLeft: "5px", bgcolor: blue[600] }}>
                        <Typography fontSize={"12px"}>
                          {adviceDataFromAi.length ? adviceDataFromAi.length : 0}
                        </Typography>
                        </Avatar> */}
                        {collapseAllLifeStyleAdvice ?
                            <IconButton onClick={() =>{
                              // collapseLifeStyleAdvice(false);
                              setCollapseAllLifeStyleAdvice(false);
                            }}><InfoIcon color="primary" fontSize="8px" /></IconButton>
                            :
                            <IconButton onClick={() => {
                              setCollapseLifeStyleAdvice(true);
                              setCollapseAllLifeStyleAdvice(true);
                            }}><InfoOutlinedIcon color="primary" fontSize="8px" /></IconButton>
                          }
                      </Grid>
                      <Grid
                        item
                        xs={0.5}
                        style={{ padding: 0 }}
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        {/* place for add new order */}
                        {collapseLifeStyleAdvice ?
                          <IconButton onClick={() => {
                            setCollapseLifeStyleAdvice(false);
                            setCollapseAllLifeStyleAdvice(false);
                          }}><ExpandLessIcon color="primary" fontSize="8px"  /></IconButton>
                          :
                          <IconButton onClick={() => setCollapseLifeStyleAdvice(true)}><ExpandMoreIcon /></IconButton>
                        }
                      </Grid>
                      <Grid item xs={12} style={{ padding: 0 }}>
                        <Divider></Divider>
                      </Grid>
                      {/* -------------------------- */}
                      <Grid
                        item
                        xs={12}
                        container
                        alignItems={"flex-end"}
                        justifyContent={"space-between"}
                      >
                        <Grid item xs={12}>
                          <Collapse in={collapseLifeStyleAdvice}>
                          {adviceDataFromAi?.map((advice, i) => (
                            <AddLifeStyleAdvice
                              advice={advice}
                              i={i}
                              dataFromAi={adviceDataFromAi}
                              setDataFromAi={setAdviceDataFromAi}
                              collapseAllLifeStyleAdvice={collapseAllLifeStyleAdvice}
                            />
                          ))}
                            </Collapse>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Paper>
              ) : (
                <></>
              )}

              <Grid item>
                <Button
                  variant="contained"
                  // size="small"
                  onClick={saveCpgEmr}
                  disabled={saving}
                  startIcon={
                    saving ? <CircularProgress size={"12px"} /> : <></>
                  }
                  sx={{
                    bgcolor: "#EC6A49",
                    position: "absolute",
                    bottom: "30px",
                    right: "50px",
                    borderRadius: "5px",
                  }}
                >
                  Place Order
                </Button>
              </Grid>
            </Grid>
            {/* Right side bar */}
            <Grid
              item
              xs={2.8}
              bgcolor={"white"}
              //mt={2}
              mr={1}
              borderRadius={"3px"}
            >
              {/* <Typography
                variant="button"
                fontSize={"12px"}
                pl={1}
                fontWeight={600}
              >
                Info Widget
              </Typography> */}

              {/* Vitals */}
              <Paper sx={{ padding: "10px", margin: "10px", backgroundColor: "#f1f6fc" }} variant="outlined">
                <Grid container rowSpacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="button"
                      fontSize={"12px"}
                      fontWeight={600}
                    >
                      Vitals
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ padding: 0 }}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    {vitalSigns?.length !== 0 ? (
                      <VitalSignsCpg vitalSigns={vitalSigns} />
                    ) : (
                      <Grid container justifyContent={"center"} my={1}>
                        <Typography variant="caption" fontSize={"12px"}>
                          {" "}
                          No Data Available
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Paper>
              {/* Allergy */}
              <Paper sx={{ margin: "10px", padding: "10px", backgroundColor: "#f1f6fc" }} variant="outlined">
                <Grid container rowSpacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="button"
                      fontSize={"12px"}
                      fontWeight={600}
                    >
                      Allergy
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ padding: 0, margin: 0 }}>
                    <Divider />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    gap={0.5}
                    style={{ padding: 0, margin: 0 }}
                  >
                    {allergies?.length !== 0 ? (
                      <AllergyCpg allergies={allergies} />
                    ) : (
                      <Grid container justifyContent={"center"} my={1}>
                        <Typography variant="caption" fontSize={"12px"}>
                          {" "}
                          No Data Available
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Paper>

              {/* chiefcomplaints */}
              <Paper sx={{ margin: "10px", padding: "10px" , backgroundColor: "#f1f6fc"}} variant="outlined">
                <Grid container rowSpacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="button"
                      fontSize={"12px"}
                      fontWeight={600}
                    >
                      chiefcomplaints
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ padding: 0, margin: 0 }}>
                    <Divider />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    gap={0.5}
                    style={{ padding: 0, margin: 0 }}
                  >
                    {chiefComplaints?.length !== 0 ? (
                      <ChiefComplaintsCpg chiefComplaints={chiefComplaints} />
                    ) : (
                      <Grid container justifyContent={"center"} my={1}>
                        <Typography variant="caption" fontSize={"12px"}>
                          {" "}
                          No Data Available
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Paper>

              {/* Diagnosis */}
              <Paper sx={{ margin: "10px", padding: "10px", backgroundColor: "#f1f6fc" }} variant="outlined">
                <Grid container rowSpacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="button"
                      fontSize={"12px"}
                      fontWeight={600}
                    >
                      Diagnosis
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ padding: 0, margin: 0 }}>
                    <Divider />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    gap={0.5}
                    style={{ padding: 0, margin: 0 }}
                  >
                    {diagnosis?.length !== 0 ? (
                      <DiagnosisCpg diagnosis={diagnosis} />
                    ) : (
                      <Grid container justifyContent={"center"} my={1}>
                        <Typography variant="caption" fontSize={"12px"}>
                          {" "}
                          No Data Available
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container justifyContent="center" alignItem="center">
          <Grid item margin={"50px"}>
            <Lottie options={defaultOptions} height={150} width={150} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Emr;
