import React from "react";
import { connect } from "react-redux";
import { actions } from "primarycare-binder";
//import { withRouter } from "react-router-dom";
import {
  Container,
  Row,
  Div,
  Avatar,
  Text,
  Divider,
  Icons,
  H6,
  SideMenu
} from "qdm-component-library";
import { AppRoutes } from "../../router/routes";
import { CapitalizeString, checkWithCasbin, getUserInfo, Logout } from "../../utils";
import { Typography, Popover } from "@material-ui/core";
import Logo from "../../assets/img/Logo_W.svg";
import { CustomDrawer } from '../../components';
import {
  Hamburger, DashboardIcon,
  CloseConsultation, WorkList, MenuIcon, DocumentIcon
} from '../svg/components';
import {AssignPractitioner} from "../svg/components/assignPractitioner"
import {ReAssignPractitioner} from "../svg/components/reassignPractitioner"

// import "./styles.css";
import { UIColor } from "../../themes/theme";

const drawerData = {
  sideMenuDashboard: {
    icon: <DashboardIcon />,
    label: "Dashboard",
  },
  worklist: {
    icon: <WorkList />,
    label: "Work List",
    children: [
      {
        icon: <AssignPractitioner />,
        label: "Assign Practitioner",
        backgroundColor: "#F6EFF8",
        color: "#AA62BA",
        link: AppRoutes.workList,
        key_: "assignPractitionerW",
      },
      {
        icon: <ReAssignPractitioner />,
        label: "Re-Assign Practitioner",
        backgroundColor: "#EFF2F9",
        color: "#5F84C0",
        link: AppRoutes.workList,
        key_: "reassignPractitionerW",
      },
      {
        icon: <CloseConsultation />,
        label: "Close Consultation",
        backgroundColor: "#F7EFEA",
        color: "#AD6338",
        link: AppRoutes.workList,
        key_: "closeConsultationW",
      },
      {
        icon: <DocumentIcon />,
        label: "Day End Process",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: AppRoutes.dayEndProcess,
        key_: "dayEndProcessW",
      },
    ],
  },
  billingCashier: {
    icon: <WorkList />,
    label: "Biller and Cashier",
  },
  // mainMenu: {
  //   icon: <MenuIcon />,
  //   label: 'Menu name',
  //   children: [

  //   ]
  // },
};

class TopHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      open: false,
      permissons: null,
      drawerDataList: [],
      pic: "",
    };
  }
  redirectLink = (data) => {
    if (data.id !== this.giveMeSelectedTab())
      this.props.history.push(data.link);
  };
  async componentDidMount() {
    let permission = await checkWithCasbin(["sideMenu"]);
    let arr = [];
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }
    var unique = [...permission.read, ...permission.write].filter(onlyUnique);
    unique.map((val) => {
      //WORK LIST MENU CONSTRUCT
      if (val === "worklist") {
        let obj = JSON.parse(JSON.stringify(drawerData[val]));
        obj.children = [];
        unique.map((perVal) => {
          drawerData[val].children.filter((v, i) => {
            if (v.key_ === perVal) {
              obj.children.push(drawerData[val].children[i]);
            }
          });
        });
        arr.push(obj);
      } else {
        if (drawerData[val]) {
          arr.push(drawerData[val]);
        }
      }
      // arr.push(drawerData[val])
    });

    this.setState({
      permissons: permission,
      drawerDataList: arr,
      pic: this.props?.loggedUserInfo?.data?.photo ?? "",
    });
  }
  giveMeSelectedTab = () => {
    let id = "";
    const { navBarArr } = this.props;
    navBarArr.map((nav) => {
      if (window.location.pathname === nav.link) {
        id = nav.id;
      }
      return nav;
    });
    return id;
  };
  clickHome = () => {
    this.props.history.push(AppRoutes.dashboard);
  };
  handleProfileClose = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };
  handleProfileClick = (event) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
    });
  };

  changeState = (key, value) => {
    this.setState({
      ...this.state,
      [key]: value,
    });
  };

  render() {
    const { name } = getUserInfo();
    const {
      navBarArr = [],
      noHome,
      middleComponent = <></>,
      parent_id,
      hideHumburger,
    } = this.props;
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;
    let { permissons } = this.state;
    return (
      <div id={`${parent_id}_header_parent_div`}>
        <Container
          id={`${parent_id}_header_parent_container`}
          key={"0"}
          name="container"
          fluid={true}
          style={{
            backgroundColor: !this.props.hideBackground
              ? "#e8e8e8"
              : "transparent",
            padding: "0px",
          }}
        >
          <Row
            id={`${parent_id}_header_parent_row`}
            key={"0"}
            alignItems="stretch"
            direction="row"
            justify="flex-start"
            spacing="2"
            style={{
              backgroundColor: UIColor.primaryColor.color,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px 37px",
            }}
          >
            <Div
              id={`${parent_id}_header_section_div`}
              key={"0"}
              inLineStyles="[object Object]"
              className="qdm"
              style={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {!hideHumburger &&
                  (permissons?.write?.length > 0 ||
                    permissons?.read?.length > 0) && (
                    <div
                      style={{
                        marginRight: 18,
                        cursor: "pointer",
                        display: "flex",
                      }}
                    >
                      <Hamburger
                        onClick={() => this.changeState("open", true)}
                      />
                      <SideMenu
                        style={{
                          zIndex: 99999999,
                        }}
                        id="sidemenu_parent_sidemenu"
                        open={this.state?.open}
                        inLineStyles={{
                          zIndex: 99999999,
                          padding: "20px 0px",
                          cursor: "auto",
                        }}
                        direction="left"
                        width={260}
                        color="default"
                        onClose={() => this.changeState("open", false)}
                      >
                        <CustomDrawer
                          list={this.state.drawerDataList}
                          onClose={() => this.changeState("open", false)}
                        />
                      </SideMenu>
                    </div>
                  )}
                <div
                  style={{ display: "flex" }}
                  id={`${parent_id}_header_logo_image_div`}
                >
                  <img
                    id={`${parent_id}_header_logo_image`}
                    key={"0"}
                    variant="rounded"
                    letter="Logo"
                    src={Logo}
                    alt="Logo"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
              </div>
              <Div
                id={`${parent_id}_header_sub_div`}
                key={"1"}
                inLineStyles="[object Object]"
                className="qdm"
                style={{
                  display: "flex",
                  marginLeft: "20px",
                }}
              >
                {navBarArr.map((val, i) => {
                  return (
                    <Div
                      id={`${parent_id}_header_label_div`}
                      key={"0"}
                      inLineStyles="[object Object]"
                      className="qdm"
                      style={{
                        display: "",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "",
                      }}
                      onClick={() => this.redirectLink(val)}
                    >
                      <Text
                        id={`${parent_id}_header_label_text`}
                        name={val.label}
                        style={{
                          color: "#ffffff",
                          fontSize: "16px",
                          fontWeight: "normal",
                        }}
                        inLineStyles={{
                          fontFamily: "'pc_semibold' !important",
                        }}
                        className={"pc_semibold"}
                      ></Text>
                      {this.giveMeSelectedTab() === val.id && (
                        <Divider
                          id={`${parent_id}_header_rightside_label`}
                          key={"1"}
                          className=""
                          label=""
                          borderColor=""
                          textColor=""
                          orientation="horizontal"
                          variant="middle"
                          style={{
                            // width: '40px',
                            height: "3px",
                            borderColor: "#ffffff",
                            backgroundColor: "#ffffff",
                            margin: "4px auto",
                          }}
                        ></Divider>
                      )}
                    </Div>
                  );
                })}
              </Div>
            </Div>
            <Div id={`${parent_id}_header_middlecomponent_div`}>
              {middleComponent}
            </Div>
            <Div
              id={`${parent_id}_header_profile_parent_div`}
              key={"2"}
              inLineStyles="[object Object]"
              className="qdm"
              style={{
                display: name ? "flex" : "none",
                // alignSelf: "flex-start",
                alignItems: "center",
                width: "40%",
                justifyContent: "right",
              }}
            >
              {!noHome && (
                <Div
                  id={`${parent_id}_header_home_div`}
                  style={{
                    cursor: "pointer",
                    paddingRight: "2%",
                    marginBottom: "0.6%",
                  }}
                  onClick={() => this.clickHome()}
                >
                  <i
                    id={`${parent_id}_header_home_icon`}
                    className="fa fa-home"
                    aria-hidden="true"
                    style={{
                      fontSize: "16px !important",
                      paddingRight: "7px",
                      color: "white",
                    }}
                  />
                  <Text
                    id={`${parent_id}_header_home_label_text`}
                    className={"pc_medium"}
                    style={{ color: "white" }}
                  >
                    Home
                  </Text>
                </Div>
              )}
              <H6
                id={`${parent_id}_header_version_text`}
                key={"0"}
                name={"version : " + localStorage.getItem("version")}
                style={{ color: "#FFFFFF", fontSize: 12, margin: "3px 0px" }}
                className={"pc_regular"}
              ></H6>
              <Divider
                id={`${parent_id}_header_rightside_divider`}
                orientation="vertical"
                style={{ width: "1px", height: "32px" }}
              />
              <Popover
                id={id}
                open={open}
                anchorEl={this.state.anchorEl}
                onClose={() => this.handleProfileClose()}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Typography
                  id={`${parent_id}_header_logout_typography`}
                  onClick={() => {
                    Logout();
                    window.location.href = "/";
                  }}
                  style={{ padding: "10px", cursor: "pointer", fontSize: 14 }}
                  className={"pc_medium"}
                >
                  Log Out
                </Typography>
              </Popover>

              <Div
                id={`${parent_id}_header_version_div`}
                style={{ padding: "0px", cursor: "pointer" }}
                onClick={(e) => this.handleProfileClick(e)}
              >
                <H6
                  id={`${parent_id}_header_name_h6`}
                  key={"0"}
                  name={name || ""}
                  style={{
                    color: "#FFFFFF",
                    lineHeight: 1,
                    fontSize: "14px",
                    margin: "0px",
                    marginBottom: 6,
                  }}
                  className={"pc_regular"}
                ></H6>
                {/* <Text key={'0'} name='Receptionist Apollo, chennai' style={{ color: '#FFFFFF', fontSize: "12px" }}></Text> */}

                <H6
                  id={`${parent_id}_header_version_text`}
                  key={"0"}
                  name={
                   ( this.props?.loggedUserInfo?.data?.role ||
                    localStorage.getItem("role_name") )?(  "Role : " +
                    CapitalizeString(
                      this.props?.loggedUserInfo?.data?.role ||
                        localStorage.getItem("role_name") ||
                        ""
                    )):""
                  }
                  style={{ color: "#FFFFFF", fontSize: 12, margin: 0 }}
                  className={"pc_regular"}
                ></H6>
              </Div>
              <Avatar
                id={`${parent_id}_header_letters_name_avatar`}
                key={"1"}
                variant="rounded"
                letter={this.props?.loggedUserInfo?.data?.completeName ?? ""}
                // src={this.props?.loggedUserInfo?.data?.photo}
                src={this.state?.pic ?? ""}
                alt={this.props?.loggedUserInfo?.data?.completeName}
                style={{
                  width: "40px",
                  height: "40px",
                  padding: "",
                  // marginLeft: "16px",
                  // marginRight: "10px",
                  backgroundColor: "#E0E0E0",
                  fontSize: "16px",
                  fontFamily: "pc_medium",
                  margin: "0px 10px 0px 16px",
                }}
              ></Avatar>
              <Icons
                id={`${parent_id}_header_down_icon`}
                onClick={(e) => this.handleProfileClick(e)}
                key={"2"}
                fontIcon="angle-down"
                ariaHidden="true"
                className=""
                size={18}
                style={{ color: "#ffffff", cursor: "pointer" }}
              ></Icons>
            </Div>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedUserInfo: state?.authSlice?.loggedUserInfo,
});

export default connect(mapStateToProps, actions)(TopHeader);
