import React from "react";
import { Div } from "qdm-component-library";
import { Chip } from "@material-ui/core";
import jwtDecode from "jwt-decode";
import { getGlobalValueFrom_LclStrg_And_QryStrg } from "../../../utils";
import { useSelector } from "react-redux";

const Outstanding = (props) => {
  const { OutstandingData = "", OutstandingClick = () => null } = props;
  const userInfo = localStorage.getItem("UserInfo");
  const currencyinfo = getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"currencyinfo"})
  const Outstanding = useSelector((state) => state?.PatientDashboard?.outstanding);
  
  return (
    <React.Fragment>
      <Div>
        {Outstanding ? (
          <Chip
            variant="outlined"
            size="small"
            label={`Outstanding : ${currencyinfo?.currencysymbol || currencyinfo?.currencycode} ${Outstanding?.data?.[0]?.outstandingamount}`}
            onClick={OutstandingClick}
            style={{
              borderRadius: 4,
              color: "#EC6A49",
              borderColor: "#EC6A49",
              backgroundColor: "#FFF2F2",
              width: "170px",
            }}
          />
        ) : null}
      </Div>
    </React.Fragment>
  );
};

Outstanding.defaultProps = {
  careList: [],
};

export default Outstanding;
