import React, { useContext, useEffect, useState } from "react";
import {
  makeStyles,
  Typography,
  Card,
  CardContent,
  TextField,
  Grid,
  Button,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch } from "react-redux";
// import { actions } from "frequencyscreen_v_dbinder";
import useStyles from "./styles";
import actions from "../../redux/actions";

const initialOptions = {
  enterprisesList: [],
  organisationList: [],
  facilityList: [],
  locationList: [],
};
const initialState = {
  enterprise: null,
  organisation: null,
  facility: null,
  location: null,
};

const LocationRoles = (props) => {
  const classes = useStyles();
  // const { practitonerDetails } = props;
  const dispatch = useDispatch();
  const [optionsData, setOptionsData] = React.useState(initialOptions);
  const [state, setState] = React.useState(initialState);
  const [enterprisesList, setEnterprisesList] = React.useState([]);
  const [organisationList, setOrganisationList] = React.useState([]);
  const [facilityList, setFacilityList] = React.useState([]);
  const [locationLists, setLocationLists] = React.useState([]);
  const [roleLists, setRoleLists] = React.useState([]);
  const [practitioner_id, setPractitioner_id] = React.useState("");
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [organisationOptions, setOrganisationOptions] = useState([]);
  const [enterpriseOptions, setEnterpriseOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const handleDropdown = (data, dropdownType, statics) => {
    if (!data) {
      let stateData = state;
      let options = optionsData;
      statics.forEach((item) => {
        stateData[item] = null;
        options[item] = [];
      });
      setState(stateData);
      setOptionsData(options);
      // }
    } else {
      if (dropdownType === "facility") {
        setRoleLists([]);
        setEnterprisesList([]);
        setOrganisationList([]);
        setLocationLists([]);
        getOrg(data.id);
        getRolelist(practitioner_id, data.id);
        getlocationlist(data.id);
      }
    }

    setState({
      ...state,
      [dropdownType]: data,
    });
  };

  // const getRole = async () => {
  //   const response = await dispatch(
  //     actions.GET_PRACTITIONER_ROLE({ userId: props?.practitonerId })
  //   );
  //   setPractitonerDetails(response?.payload?.data?.[0]?.practitioner_role);
  // };

  //get organization
  const getOrg = async (facilityId) => {
    const enterpriseList = await dispatch(
      actions.GET_ORGANIZATION_NAME({
        organizationId: facilityId,
      })
    );
    let mappedData = enterpriseList?.payload?.data?.map((item) => {
      return {
        _id: item._id,
        orgName: item.orgname,
        orgType: item.OrgType,
        parentOrg: {
          orgName: item.ParentOrgID.orgname,
          orgType: item.ParentOrgID.OrgType,
        },
        parentOfParent: item.Parentofparent,
      };
    });
    setEnterprisesList(mappedData?.[0]?.parentOfParent);
    // setOrg_id(mappedData?.[0]?._id);
    setOrganisationList(mappedData?.[0]?.parentOrg);
  };

  //get role
  const getRolelist = async (practid, orgid) => {
    const roleListres = await dispatch(
      actions.GET_ROLE_NAME({
        practid: practid,
        orgid: orgid,
      })
    );
    setRoleLists(roleListres?.payload?.data);
  };
  //get facility
  const getfacilitylist = async (emailId) => {
    const facilityListres = await dispatch(
      actions.GET_USER_FACILITY({
        emailId: emailId,
      })
    );
    setFacilityList(facilityListres?.payload?.data?.[0]?.org);
    setPractitioner_id(facilityListres?.payload?.data?.[0]?.practid);
  };
  // get locations
  const getlocationlist = async (Id) => {
    const locationListres = await dispatch(
      actions.GET_LOCATIONS_NAME({
        organizationId: Id,
      })
    );
    setLocationLists(locationListres?.payload?.data);
  };
  React.useEffect(() => {
    // getRole();
    getfacilitylist(props?.practitonerId);
  }, [props?.practitonerId]);

  const GetIdAndDisplay2 = (data) => {
    let list = [];

    for (let index in data) {
      const item = data[index];
      const datatemp = {
        name: item?.orgname,
        //name: item?.OrgType?.display,
        id: item?._id,
      };

      list.push(datatemp);
    }
    return list;
  };
  const filterOptions = (data) => {
    let list = [];

    for (let index in data) {
      const item = data[index];
      const datatemp = {
        name: item?.locationname, //item.OrgType?.display
        id: item?.id,
      };

      list.push(datatemp);
    }
    return list;
  };
  const GetIdAndDisplay = (data) => {
    return [
      {
        name: data?.orgName,
        //name: data?.orgType?.display,
        id: data?._id,
      },
    ];
  };
  const filterOption = (data) => {
    let list = [];

    for (let index in data) {
      const item = data[index];
      const datatemp = {
        name: item.display,
        id: item._id,
      };

      list.push(datatemp);
    }
    return list;
  };

  const isValidateArray = (list) => Array.isArray(list) && list.length > 0;
  const isValidateObject = (obj) =>
    obj && typeof obj === "object" && Object.keys(obj).length > 0;
  useEffect(() => {
    if (
      isValidateArray(enterprisesList) &&
      isValidateObject(organisationList) &&
      isValidateArray(locationLists) &&
      state?.facility
    ) {
      const defaultValues = {
        enterprise: GetIdAndDisplay2(enterprisesList)[0],
        organisation: GetIdAndDisplay(organisationList)[0],
        location: filterOptions(locationLists)[0],
      };
      let newState = {};

      Object.entries(defaultValues).forEach(([dropdownName, value]) => {
        if (value !== null && value !== undefined) {
          if (!Array.isArray(value) || value?.length !== 0) {
            if (state[dropdownName]?.id !== value.id) {
              newState[dropdownName] = value;
            }
          }
        }
      });

      if (Object.keys(newState).length > 0) {
        setState((prevState) => ({ ...prevState, ...newState }));
      }
    }
  }, [enterprisesList, organisationList, locationLists, state?.facility]);
  useEffect(() => {
    setFacilityOptions(filterOption(facilityList ?? []));
    setOrganisationOptions(GetIdAndDisplay(organisationList ?? []));
    setEnterpriseOptions(GetIdAndDisplay2(enterprisesList ?? []));
    setRoleOptions(filterOption(roleLists ?? []));
    setLocationOptions(filterOptions(locationLists ?? []));
  }, [
    facilityList,
    organisationList,
    enterprisesList,
    roleLists,
    locationLists,
  ]);
  useEffect(() => {
    if (props?.sessioninfoValue) {
      setState({
        role: {
          name: props?.sessioninfoValue?.roleid?.value,
          id: props?.sessioninfoValue?.roleid?._id,
        },
        enterprise: {
          name: props?.sessioninfoValue?.enterpriseid?.value,
          id: props?.sessioninfoValue?.enterpriseid?._id,
        },
        organisation: {
          name: props?.sessioninfoValue?.orgid?.value,
          id: props?.sessioninfoValue?.orgid?._id,
        },
        facility: {
          name: props?.sessioninfoValue?.facilityid?.value,
          id: props?.sessioninfoValue?.facilityid?._id,
        },
        location: {
          name: props?.sessioninfoValue?.locationid?.value,
          id: props?.sessioninfoValue?.locationid?._id,
        },
      });
      getRolelist(practitioner_id, props?.sessioninfoValue?.facilityid?._id);    
    }
  }, [props,practitioner_id]);
  const clearState = () => {
    setEnterprisesList([]);
    setOrganisationList([]);
    setFacilityList([]);
    setLocationLists([]);
    setPractitioner_id([]);
    setRoleLists([]);
  };
  const getSelectedOption = (options, value) => {
    return options.find((option) => option?.id === value?.id) || value;
  };
  const getRoleSelectedOption = (options, value) => {
    return options.find((option) => option?.id === value?.id) || null;
  };
  return (
    <div style={{ minWidth: 240, minHeight: 300 }}>
      <Grid style={{ marginTop: "19px", marginLeft: "23px" }}>
        <Typography
          style={{ fontSize: "12px", fontWeight: 500, marginBottom: "10px" }}
        >
          Facility
        </Typography>
        <Autocomplete
          id="combo-box-demo"
          size={"small"}
          options={facilityOptions ?? []}
          value={state?.facility}
          onChange={(e, newValue) =>
            handleDropdown(newValue, "facility", ["facility", "location"])
          }
          getOptionLabel={(option) => option?.name}
          style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      </Grid>
      <Grid className={classes.align}>
        <Typography className={classes.heading}>Organization</Typography>
        <Autocomplete
          id="combo-box-demo"
          size={"small"}
          options={organisationOptions ?? []}
          // value={state?.organisation}
          value={getSelectedOption(organisationOptions, state?.organisation)}
          onChange={(e, newValue) =>
            handleDropdown(newValue, "organisation", [
              "organisation",
              "facility",
              "location",
            ])
          }
          getOptionLabel={(option) => option?.name}
          style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
          disabled
        />
      </Grid>
      <Grid style={{ marginTop: "19px", marginLeft: "23px" }}>
        <Typography
          style={{ fontSize: "12px", fontWeight: 500, marginBottom: "10px" }}
        >
          Enterprise
        </Typography>
        <Autocomplete
          id="combo-box-demo"
          size={"small"}
          options={enterpriseOptions ?? []}
          // value={state?.enterprise}
          // value={state?.enterprise}
          value={getSelectedOption(enterpriseOptions, state?.enterprise)}
          onChange={(e, newValue) =>
            handleDropdown(newValue, "enterprise", [
              "enterprise",
              "organisation",
              "facility",
              "location",
            ])
          }
          getOptionLabel={(option) => option?.name}
          style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
          disabled
        />
      </Grid>
      <Grid className={classes.align}>
        <Typography className={classes.heading}>Role</Typography>
        <Autocomplete
          id="combo-box-demo"
          size={"small"}
          options={roleOptions ?? []}
          value={getRoleSelectedOption(roleOptions, state?.role)}
          onChange={(e, newValue) => handleDropdown(newValue, "role", ["role"])}
          getOptionLabel={(option) => option?.name}
          style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      </Grid>
      <Grid className={classes.align}>
        <Typography className={classes.heading}>Location</Typography>
        <Autocomplete
          id="combo-box-demo"
          size={"small"}
          options={locationOptions ?? []}
          value={state?.location}
          onChange={(e, newValue) =>
            handleDropdown(newValue, "location", ["location"])
          }
          getOptionLabel={(option) => option?.name}
          style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
          disabled
        />
      </Grid>
      <Button
        onClick={() => {
          props?.onNextClick(
            state.location,
            state.role,
            state.facility,
            state.organisation,
            state.enterprise
          );
          clearState();
        }}
        disabled={props?.disableSubmitButton}
        color="primary"
        style={{ float: "right", marginTop: "20px" }}
      >
        Submit
      </Button>
    </div>
  );
};

export default LocationRoles;
