import React, { useState, useEffect } from "react";
import withAppBar from "../../hoc/withAppBar";
import withAllContexts from "../../hoc/withAllContexts";
import DashboardMain from "./dashboard";

const DashboardLanding = (props) => {
    return(
        <DashboardMain />
    )
}
export default withAppBar(withAllContexts(DashboardLanding));
