import React, { useContext, useReducer, useRef } from "react";
import {
  Container,
  Div,
  H6,
  Divider,
  Upload,
  TextInput,
  TapSelect,
  Text,
  // DateTimepicker,
  MobileNumberWithCode,
  Button,
  TermsAndConditions,
  MonthCalendar,
  Popover,
  Icons,
} from "qdm-component-library";
import {
  checkError,
  AlertProps,
  ValidateEmail,
  getMobileNumber,
  getUserInfo,
  upsertPerson,
  mobileNumberValidation,
  checkName,
} from "../../../utils";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { UIColor } from "../../../themes/theme";
import { makeStyles } from "@material-ui/core";
import { DobAge } from "../../dobAge";
import { AlertContext } from "../../../context";
import { useDispatch } from "react-redux";
import actions from "../../../redux/actions";
import { useSelector } from "react-redux";


export const useStyles = makeStyles((theme) => ({
  upload: {
    "& .uploadWrapper": {
      boxShadow: "none",
      "& p": {
        lineHeight: 1,
        fontFamily: "pc_semibold",
        fontSize: 12,
        color: UIColor.tertiaryText.color,
      },
    },
  },
  textField: {
    marginRight: "15px",
    "& input": {
      color: UIColor.primaryText.color,
      fontSize: "14px",
      border: "1px solid #DEE5EC",
      fontFamily: "pc_medium",
      borderRadius: 8,
    },
    "& input::placeholder": {
      fontFamily: "pc_regular",
      color: UIColor.tertiaryText.color,
    },
  },
  tapSelect: {
    "& .selectionDiv": {
      height: 40,
      borderRadius: 8,
      minWidth: 48,
      margin: "8px 8px 0px 0px",
      "&:last-of-type": {
        marginRight: 0,
      },
      "& span": {
        fontFamily: "pc_regular",
        fontSize: 14,
      },
    },
    "& .active": {
      "& span": {
        fontFamily: "pc_medium !important",
      },
    },
  },
  mobile: {
    "& .callingCodes,input": {
      fontSize: 14,
      color: UIColor.primaryText.color,
      fontFamily: "pc_medium",
      letterSpacing: "-0.34px",
      fontWeight: "normal",
    },
    "& input::placeholder": {
      fontFamily: "pc_medium",
    },
    // '& #addmember_form_mobilenumberwithcode':{
    //   borderColor: UIColor.lineBorderFill.color
    // },
    "& .mobileCountryDivider": {
      borderColor: UIColor.lineBorderFill.color,
    },
  },
  termsAndConditions: {
    "& .termsConditionsCheckbox": {
      borderRadius: 2,
    },
    "& .termsConditionsLabel": {
      flex: 1,
    },
  },
  a_c_c_aed_fbeabbf: {
    fontSize: "20px",
    color: UIColor.primaryText.color,
    letterSpacing: 0,
    lineHeight: 1,
    margin: 0,
  },
  b__ae_ba_fcfdfc: {
    color: UIColor.primaryColor.color,
    fontSize: "14px",
    letterSpacing: 0,
    margin: 0,
    cursor: "pointer",
  },
}))

const Styles = {
  inputStyle:{
    borderRadius: 8,
    color: UIColor.primaryText.color,
    border: "1px solid #DEE5EC"
  },
  errorStyle:{
    borderRadius: 8,
    color: UIColor.primaryText.color,
  }
}
function AddMemberForm(props) {
  const alert = useContext(AlertContext);
  const dispatch = useDispatch()
  const classes = useStyles()
  const mobileRef = useRef()
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    gender: "",
    dob: {
      val: moment().format("DD-MM-YYYY"),
      milli: moment().valueOf(),
    },
    relation: {},
    emailId: "",
    mobileNo: {},
    opencalendar: false,
    loadingReadPerson: false,
  });
const countryListReducer = useSelector((state)=> state?.CommonSlice?.countryList)
const masters = useSelector((state)=> state?.CommonSlice?.masters)
console.log({masters});

  const changeState = (key, value) => {
    //debugger
    setState({
      ...state,
      [key]: value,
    });
  };

  useEffect(() => {
    (async function () {
      //debugger
      await dispatch(actions.GET_COUNTRY_LIST());
      await dispatch(actions.Masters({type:["AGETYPUOM","GENDER"]}));
      if (props.signUpData) {
        const data = props.signUpData;
         setState({
          mobileNo: props.signUpData.mobileNo,
          firstName: data?.firstName,
          lastName: data?.lastName,
          middleName: data?.middleName,
          gender: data?.gender,
          dob: {
            val: props.signUpData.dob,
            milli: moment().valueOf(),
          },
          emailId: data?.emailId,
          image:data?.image
        });
      }
    })();
  }, []);

  const handleSignUp = async () => {
    //debugger
    if (
      // state?.mobileNo?.mobileNo?.length > 9 &&
      // state?.mobileNo?.countryData?.callingCodes &&
      state?.firstName &&
      state?.gender &&
      // state?.lastName &&
      (state.emailId ? ValidateEmail(state?.emailId) : true)
    ) {
      // let res__ = mobileNumberValidation(
      //   state?.mobileNo?.mobileNo,
      //   state?.mobileNo?.countryData?.callingCodes
      // );
      // //  ;
      // if (res__ === "Invalid Country code") {
      //   alert.setSnack({
      //   	open: true,
      //   	severity: AlertProps.severity.error,
      //   	msg: res__,
      //   	vertical: AlertProps.vertical.top,
      //   	horizontal: AlertProps.horizontal.right,
      //   });
      //   return;
      // } else if (res__ === false) {
      //   alert.setSnack({
      //   	open: true,
      //   	severity: AlertProps.severity.error,
      //   	msg: "Please enter valid mobile number",
      //   	vertical: AlertProps.vertical.top,
      //   	horizontal: AlertProps.horizontal.right,
      //   });
      //   return;
      // }

      const {
        firstName,
        lastName,
        middleName,
        dob,
        gender,
        emailId,
        mobileNo,
        image
      } = state;
      //if (!props.addNewMember) {
        // await props.REGISTER_USERS({
        //   mobileNo: `+${state.mobileNo?.countryData?.callingCodes ?? "00"}${
        //     state.mobileNo.mobileNo
        //   }`,
        //   firstName,
        //   lastName,
        //   emailId,
        // });
        // const status = checkError(props.registerUsers);
        // if (status.isError) {
        //   setState({
        //     error: status.errorMessage,
        //   });
        //   alert.setSnack({
        //     open: true,
        //     severity: AlertProps.severity.error,
        //     msg: status.errMsg,
        //     vertical: AlertProps.vertical.top,
        //     horizontal: AlertProps.horizontal.center,
        //     tone: true
        //   })
        // } else {
          const signUpData = {
            mobileNo,
            firstName,
            lastName,
            middleName,
            dob: dob.val,
            gender,
            emailId,
            image
          };
          props.nextSection("addmemberotp", signUpData);
       // }
      // } else {
      //   const guestBookerAdd = {
      //     phone: getMobileNumber(state.mobileNo),
      //     dob: dob.val,
      //     firstName,
      //     lastName,
      //     gender,
      //     email: state.emailId,
      //   };
      //   const { data: res, error } = await upsertPerson(
      //     props.READ_PERSON,
      //     props.UPSERT_PERSON,
      //     guestBookerAdd
      //   );
      //   if (error) {
      //     alert.setSnack({
      //       open: true,
      //       severity: AlertProps.severity.error,
      //       msg: res,
      //       vertical: AlertProps.vertical.top,
      //       horizontal: AlertProps.horizontal.center,
      //       tone: true,
      //     });
      //   } else {
      //     let personId = isNaN(Number(localStorage.getItem("idval")))
      //       ? 0
      //       : Number(localStorage.getItem("idval"));
      //     if (!personId) {
      //       const userInfo = getUserInfo();
      //       let mobileNo = userInfo.mobile;
      //       const persondata = await props.READ_PERSON({
      //         phone: mobileNo,
      //       });
      //       personId = persondata?.payload?.data?.id;
      //     }
      //     const relatedPersonInput = {
      //       ...guestBookerAdd,
      //       relationType: state.relation || "",
      //       relationPersonId: [personId || 0],
      //       personId: res.payload?.data?.id || 0,
      //     };
      //     await props.ADD_RELATED_PERSON(relatedPersonInput);
      //     alert.setSnack({
      //       open: true,
      //       severity: AlertProps.severity.success,
      //       msg: "Member Added Successfully",
      //       vertical: AlertProps.vertical.top,
      //       horizontal: AlertProps.horizontal.center,
      //       tone: true
      //     })
      //     if (props.changeSideBar) {
      //       props.changeSideBar();
      //     }
      //     // const signUpData = {
      //     //   mobileNo,
      //     //   firstName,
      //     //   lastName,
      //     //   middleName,
      //     //   dob: dob.val,
      //     //   gender,
      //     //   emailId
      //     // }
      //     // props.nextSection('addmemberotp', signUpData)
      //     // alert('User Added')
      //   }
      // }
    } else {
      setState({
        ...state,
        error: {
          mobileNo: state?.mobileNo?.mobileNo?.length > 9 ? false : true,
          callingCodes: state?.mobileNo?.countryData?.callingCodes
            ? false
            : true,
          firstName: state?.firstName?.length > 0 ? false : true,
          gender: state?.gender ? false : true,
          lastName: false,
          emailId: state?.emailId
            ? ValidateEmail(state?.emailId)
              ? false
              : true
            : false,
        },
      });
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: 'Please fill all the mandatory fields',
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true
      })
    }
  };
  const handlepapover = (e) => {
    if (state.opencalendar) {
      handlepapoverclose();
    } else {
      setState({ opencalendar: e.currentTarget });
    }
  };
  const getErrorMessage = () => {
    let errMsg = "Please enter valid";
    let isFirst = true;
    let isSecond = true;
    if (state.error?.firstName) {
      isFirst = false;
      errMsg += " First Name";
    }
    if (state.error?.middleName) {
      isSecond = false;
      errMsg += `${!isFirst ? "," : ""} Middle Name`;
    }
    if (state.error?.lastName) {
      errMsg += `${!isSecond ? "," : ""} Last Name`;
    }
    return errMsg;
  };
  const handlepapoverclose = () => {
    setState({ ...state, opencalendar: null });
  };
  const { addNewMember={}, parent_id="",relationshipData={} } = props;
  return (
    <div id={`${parent_id}_addmember_form_parent_div`}>
        <Container
          id={`${parent_id}_addmember_form_parent_container`}
          key={"0"}
          name={"container"}
          fluid={true}
          // style={qdmstyles.Bc}
          style={{ padding: "16px 24px" }}
        >
          <Div
            id={`${parent_id}_addmember_form_title_parent_div`}
            key={"0"}
            className={"qdm"}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "",
              width: "100%",
            }}
          >
            <Div
              key={"0"}
              id={`${parent_id}_addmember_form_title_sublevel_div`}
            >
              <H6
                id={`${parent_id}_addmember_form_title_h6`}
                key={"0"}
                name={addNewMember ? "Add Member" : "Sign Up as New Member"}
                style={{
                  fontSize: "20px",
                  color: UIColor.primaryText.color,
                  letterSpacing: 0,
                  lineHeight: 1,
                  margin: 0,
                }}
                className={"pc_semibold"}
              ></H6>
            </Div>

            <Div key={"1"} id={`${parent_id}_addmember_form_back_title_div`}>
              <H6
                className="pc_medium"
                onClick={() => props.nextSection("back")}
                style={{
                  color: UIColor.secondaryButtonColor.color,
                  fontSize: "14px",
                  letterSpacing: 0,
                  margin: 0,
                  cursor: "pointer",
                }}
              >
                Back
              </H6>

              {/* <Button
                id={`${parent_id}_addmember_form_back_title_button`}
                key={"0"}
                name={"Back"}
                style={{
                  color: UIColor.primaryColor.color,
                  fontSize: "14px",
                  letterSpacing: 0,
                  margin: 0,
                  cursor: "pointer",
                }}
                onClick={() => props.nextSection("back")}
              ></Button> */}
            </Div>
          </Div>

          <Divider
            id={`${parent_id}_addmember_form_titile_bottom_divider`}
            style={{ margin: "16px 0px" }}
            key={"1"}
          ></Divider>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Div
              style={{
                background: UIColor.lineBorderFill.color,
                width: "64px",
                height: "64px",
                borderRadius: "50%",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // overflow: "hidden",
              }}
              id={`${parent_id}_addmember_form_upload_image_parent_div`}
              className={classes.upload}
            >
              <Upload
                id={`${parent_id}_addmember_form_upload_image`}
                key={"2"}
                handleChange={(e, value) => {
                  if (
                    value.type === "image/png" ||
                    value.type === "image/jpg" ||
                    value.type === "image/jpeg"
                  ) {
                    if (value.size > 1000000) {
                      alert.setSnack({
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Please Select an Image with size less than 1MB.",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                        tone: true,
                      });
                    } else {
                      changeState("image", { url: e, file: value });
                    }
                  } else {
                    alert.setSnack({
                      open: true,
                      severity: AlertProps.severity.error,
                      msg: "Please Select an Image file.",
                      vertical: AlertProps.vertical.top,
                      horizontal: AlertProps.horizontal.right,
                      tone: true,
                    });
                  }
                }}
                label={
                  state.image?.url ? (
                    <img
                      style={{
                        width: "64px",
                        height: "64px",
                        borderRadius: "50%",
                      }}
                      src={state.image?.url}
                      alt="Profile"
                    />
                  ) : (
                    "IMG"
                  )
                }
                variant={"classic"}
                showIcon={false}
                accept="image/*"
                labelSize={"small"}
                labelColor={"#000"}
                iconColor={"#000"}
                iconSize={"16"}
                // inLineStyles={qdmstyles.zRf}
              ></Upload>
              <div
                id={`${parent_id}_addmember_form_upload_inside_editicon_div`}
                style={{
                  position: "absolute",
                  bottom: "-2px",
                  right: "0px",
                  background: "#DEE5EC",
                  borderRadius: "50%",
                  width: "24px",
                  height: "24px",
                  border: "2px solid #FFFFFF",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Icons
                  id={`${parent_id}_addmember_form_upload_inside_editicon`}
                  key={"0"}
                  fontIcon="camera"
                  size={12}
                  style={{ color: UIColor.secondaryText.color }}
                ></Icons>
              </div>
            </Div>
          </div>

          <Div
            id={`${parent_id}_addmember_form_firstname_textfield_parent_div`}
            style={{ marginBottom: 16 }}
          >
            <Div
              id={`${parent_id}_addmember_form_firstname_textfield_sublevel_div`}
              key={"3"}
              className={"qdm"}
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "16px",
                lineHeight: 1,
              }}
            >
              <TextInput
                id={`${parent_id}_addmember_form_firstname_textfield`}
                key={"0"}
                type={"text"}
                name={"text"}
                variant={"outlined"}
                helperTextType={"error"}
                maxLength={20}
                label={
                  <div
                    id={`${parent_id}_addmember_form_firstname_label_div`}
                    className={"pc_regular"}
                    style={{
                      fontSize: "12px",
                      color: UIColor.secondaryText.color,
                      marginBottom: 8,
                    }}
                  >
                    First name{" "}
                    <span
                      id={`${parent_id}_addmember_form_firstname_span`}
                      style={{ color: UIColor.error.color }}
                    >
                      *
                    </span>
                  </div>
                }
                autoFocus={false}
                autoComplete={"off"}
                search={false}
                borderColor={UIColor.primaryColor.color}
                elevation={"0"}
                placeholder={"First name"}
                size={"medium"}
                value={state.firstName}
                onChange={(e) => {
                  if (e.target.value || e.target.value === "") {
                    setState({
                      ...state,
                      error: {
                        ...state.error,
                        firstName:
                          e.target.value === ""
                            ? false
                            : !checkName(e.target.value),
                      },
                    });
                  }
                  changeState("firstName", e.target.value);
                }}
                // style={qdmstyles.vQU}
                error={state?.error?.firstName}
                className={classes.textField}
                // helperText=""
              ></TextInput>

              <TextInput
                id={`${parent_id}_addmember_form_middlename_textfield`}
                key={"1"}
                type={"text"}
                name={"text"}
                variant={"outlined"}
                maxLength={20}
                helperTextType={"error"}
                label={
                  <div
                    id={`${parent_id}_addmember_form_middlename_label_textfield`}
                    className={"pc_regular"}
                    style={{
                      fontSize: "12px",
                      color: UIColor.secondaryText.color,
                      marginBottom: 8,
                    }}
                  >
                    Middle name
                  </div>
                }
                autoFocus={false}
                autoComplete={"off"}
                search={false}
                borderColor={UIColor.lineBorderFill.color}
                elevation={"0"}
                placeholder={"Middle name"}
                size={"medium"}
                value={state.middleName}
                onChange={(e) => {
                  if (e.target.value) {
                    setState({
                      ...state,
                      error: {
                        ...state.error,
                        middleName: !checkName(e.target.value),
                      },
                    });
                  } else if (e.target.value === "") {
                    setState({
                      ...state,
                      error: {
                        ...state.error,
                        middleName: false,
                      },
                    });
                  }
                  changeState("middleName", e.target.value);
                }}
                // style={qdmstyles.BAIIv}
                className={classes.textField}
              ></TextInput>

              <TextInput
                id={`${parent_id}_addmember_form_lastname_textfield`}
                key={"2"}
                type={"text"}
                name={"text"}
                maxLength={20}
                variant={"outlined"}
                helperTextType={"error"}
                label={
                  <div
                    id={`${parent_id}_addmember_form_lastname_label_textfield`}
                    className={"pc_regular"}
                    style={{
                      fontSize: "12px",
                      color: UIColor.secondaryText.color,
                      marginBottom: 8,
                    }}
                  >
                    Last name
                    {/* <span style={{ color: UIColor.error.color }}>*</span> */}
                  </div>
                }
                autoFocus={false}
                autoComplete={"off"}
                search={false}
                borderColor={UIColor.lineBorderFill.color}
                elevation={"0"}
                placeholder={"Last name"}
                size={"medium"}
                value={state.lastName}
                onChange={(e) => {
                  if (e.target.value) {
                    setState({
                      ...state,
                      error: {
                        ...state.error,
                        lastName: !checkName(e.target.value),
                      },
                    });
                  } else if (e.target.value === "") {
                    setState({
                      ...state,
                      error: {
                        ...state.error,
                        lastName: false,
                      },
                    });
                  }
                  changeState("lastName", e.target.value);
                }}
                // style={qdmstyles.pyYgb}
                className={classes.textField}
                error={state?.error?.lastName}
              ></TextInput>
            </Div>
            {(state.error?.firstName ||
              state.error?.middleName ||
              state.error?.lastName) && (
              <div
                style={{ marginTop: 8 }}
                id={`${parent_id}_addmember_form_firstname_error_label`}
              >
                <Text
                  id={`${parent_id}_addmember_form_firstname_error_text`}
                  className={"pc_regular"}
                  style={{ color: UIColor.error.color, fontSize: 14 }}
                >
                  {getErrorMessage()}
                </Text>
              </div>
            )}
          </Div>

          <TapSelect
            id={`${parent_id}_addmember_form_gender_tapselect`}
            key={"4"}
            fullWidth={false}
            label={
              <H6
                id={`${parent_id}_addmember_form_gender_label_div`}
                className={"pc_regular"}
                style={{
                  fontSize: "12px",
                  color: UIColor.secondaryText.color,
                  lineHeight: 1,
                  margin: 0,
                  marginBottom: 8,
                  letterSpacing: 0,
                }}
              >
                Gender
                <span
                  id={`${parent_id}_addmember_form_gender_span`}
                  style={{ color: UIColor.error.color }}
                >
                  *
                </span>
              </H6>
            }
            // label={false}
            selectedBorderColor={UIColor.secondaryButtonColor.color} 
            selectedBackgroundColor={UIColor.secondaryButtonColor.color}
            selectedTextColor="#fff"
            borderColor={UIColor.differentiationBackground.color}
            backgroundColor={UIColor.differentiationBackground.color}
            textSize="16"
            iconHeight="20"
            iconWidth="20"
            textColor={UIColor.secondaryText.color}
            options={masters?.data?.GENDER}
            value={{ value: state.gender, label: state.gender }}
            onChange={(e) =>{
               setState({
                 ...state,
                 error: {
                   ...state.error,
                   gender: false,
                 },
               });
              changeState("gender", e.value)
            }}
            style={{ marginTop: "", paddingTop: "" }}
            defaultSelected={{
              value: state.gender,
              label: state.gender,
            }}
            inLineStyles={{
              //borderColor: UIColor.secondaryButtonColor.color,
              fontSize: "14px",
            }}
            className={classes.tapSelect}
            // style={qdmstyles.SREcn}
          ></TapSelect>
          {state.error?.gender && (
            <div
              style={{ marginTop: "8px" }}
              id={`${parent_id}_addmember_form_gender_error_div`}
            >
              <Text
                id={`${parent_id}_addmember_form_gender_error_text`}
                className={"pc_regular"}
                style={{ color: UIColor.error.color, fontSize: 14 }}
              >
                {"Please select the Gender"}
              </Text>
            </div>
          )}
          <DobAge
            parentId="addmember_form_select_dob_textinput"
            dateLabel="DOB"
            isRequired
            dateValue={state.dob?.val}
            onChange={(value) => {
              setState({
                ...state,
                error: {
                  ...state.error,
                  dob:false,
                },
              });
              if (value) {
                const milli = moment(value, "YYYY-MM-DD").valueOf();
                const date = {
                  val: moment(value, "YYYY-MM-DD").format("DD-MM-YYYY"),
                  milli,
                };
                changeState("dob", date);
              } else {
                changeState("dob", {
                  val: null,
                  milli: null,
                  value: "",
                });
              }
            }}
            maxDate={new Date()}
            minDate={(moment(new Date()).subtract(200, 'years'))._d}
            error={ (state.error?.dob ) && true }
            labelStyle={{ margin: "16px 0px 8px 0px" }}
            placeholder="dd-mm-yyyy"
            top={48}
            right={10}
          />
          {state.error?.dob && (
            <div
              style={{ marginTop: "8px" }}
              id={`${parent_id}_addmember_form_dob_error_parent_div`}
            >
              <Text
                id={`${parent_id}_addmember_form_dob_error_text`}
                className={"pc_regular"}
                style={{ color: UIColor.error.color, fontSize: 14 }}
              >
                {"Please enter the valid date dd-mm-yyyy"}
              </Text>
            </div>
          )}
          <H6
            id={`${parent_id}_addmember_form_mobilenumber_text`}
            key={"7"}
            name={
              <span
                id={`${parent_id}_addmember_form_mobilenumber_inside_text`}
                style={{
                  fontSize: "12px",
                }}
                className={"pc_regular"}
              >
                Enter your Mobile number{" "}
                <span
                  id={`${parent_id}_addmember_form_mobilenumber_inside_star`}
                  style={{ color: UIColor.error.color }}
                >
                  *
                </span>
              </span>
            }
            style={{
              fontSize: "12px",
              color: UIColor.secondaryText.color,
              lineHeight: 1,
              margin: 0,
              marginBottom: 8,
              letterSpacing: 0,
              marginTop: "16px",
            }}
          ></H6>
          <div className={classes.mobile} ref={mobileRef}>
            <MobileNumberWithCode 
              id={`${parent_id}_addmember_form_mobilenumberwithcode`}
              key={"8"}
              type={"number"}
              label={"Mobile Number"}
              borderColor={UIColor.lineBorderFill.color}
              maxLength={30}
              borderRadius={"6"}
              disabled={false}
              size={"medium"}
              style={{ marginBottom: "8px", border: "1px solid #DEE5EC" }}
              value={
                state?.mobileNo?.mobileNo ? state?.mobileNo?.mobileNo : props.signUpData?.mobileNo?.mobileNo
              }
              // defaultValue={state.mobileNo.mobileNo}
              dropdown={
                state?.dropdownData?.length > 0
                  ? state?.dropdownData?.[0]
                  : state?.mobileNo?.countryData
              }
              // dropdownData={state.dropdownData}
              dropdownData={countryListReducer?.data}
              onChange={(e) =>{ 
                setState({
                  ...state,
                  error: {
                    ...state.error,
                    mobileNo: false,
                  },
                });
                changeState("mobileNo", e)}}
              // onChange={(e) => console.log(e,"e")}
               className={
                      (state.error?.mobileNo || state.error?.countryData) && "errorBorder" 
                    }
              error={
                state.error?.mobileNo || state.error?.countryData
                  ? true
                  : false
              }
            ></MobileNumberWithCode>
          </div>
          {(state.error?.mobileNo || state.error?.callingCodes) && (
            <div
              id={`${parent_id}_addmember_form_mobilenumberwithcode_error_parent_div`}
              style={{ marginTop: "8px", marginBottom: 16 }}
            >
              {state.error?.callingCodes && (
                <Text
                  id={`${parent_id}_addmember_form_mobilenumberwithcode_error_text`}
                  style={{
                    color: UIColor.error.color,
                    fontSize: 14,
                  }}
                  className={"pc_regular"}
                >
                  {"Please select the country code"}
                </Text>
              )}
              {!state.error?.callingCodes && state.error?.mobileNo && (
                <Text
                  id={`${parent_id}_addmember_form_mobilenumberwithcode_empty_error_text`}
                  className={"pc_regular"}
                  style={{ color: UIColor.error.color, fontSize: 14 }}
                >
                  {"Please enter the mobile number"}
                </Text>
              )}
            </div>
          )}
          <TermsAndConditions
            id={`${parent_id}_addmember_form_send_appointment_mobilenumber_termsandconditions`}
            value={true}
            checkboxSize={14}
            checkboxTickColor={"#fff"}
            activeCheckboxBorderColor={UIColor.secondaryButtonColor.color}
            checkboxBorderColor={UIColor.lineBorderFill.color}
            checkboxBackgroundColor={UIColor.differentiationBackground.color}
            activeCheckboxBackgroundColor={UIColor.secondaryButtonColor.color}
            disabled
            lable={
              <H6
                id={`${parent_id}_addmember_form_send_appointment_mobilenumber_span`}
                style={{
                  fontSize: "12px",
                  color: UIColor.primaryText.color,
                  margin: 0,
                  letterSpacing: 0,
                }}
                className={"pc_regular"}
              >
                Send appointment update and notification to this Mobile number
              </H6>
            }
            inLineStyles={{ lineHeight: 1 }}
            //className={classes.termsAndConditions}
          />
          <div
            id={`${parent_id}_addmember_form_mailid_parent_div`}
            style={{ marginTop: 16 }}
          >
            <H6
              id={`${parent_id}_addmember_form_mailid_text`}
              key={"13"}
              // name={'Mail Id (optional)'}
              name={
                <span
                  id={`${parent_id}_addmember_form_mailid_inside_label_span`}
                  style={{
                    fontSize: "12px",
                    color: UIColor.secondaryText.color,
                  }}
                  className={"pc_regular"}
                >
                  Mail Id
                  {state.emailIdChecked ? <span style={{ color: UIColor.error.color }}> *</span>: null}
                </span>
              }
              style={{
                fontSize: "12px",
                color: UIColor.secondaryText.color,
                lineHeight: 1,
                margin: 0,
                marginBottom: 8,
                letterSpacing: 0,
              }}
            ></H6>
          </div>
          <TextInput
            id={`${parent_id}_addmember_form_email_textfield`}
            key={"9"}
            type={"text"}
            name={""}
            variant={"outlined"}
            helperTextType={"error"}
            label={""}
            autoFocus={false}
            autoComplete={"off"}
            search={false}
            // borderColor={"#9b9b9b"}
            elevation={"0"}
            placeholder={"example@gmail.com"}
            // size={"medium"}
            style={state?.error?.emailId ? Styles.errorStyle : Styles.inputStyle}
            value={state.emailId}
            onChange={(e) => changeState("emailId", e.target.value)}
            error={state?.error?.emailId}
          ></TextInput>
          {state.emailId && state.error?.emailId && (
            <div
              id={`${parent_id}_addmember_form_error_parent_div`}
              style={{ marginTop: 8 }}
            >
              <Text
                id={`${parent_id}_addmember_form_error_text`}
                style={{ color: UIColor.error.color, fontSize: 14 }}
                className="pc_regular"
              >
                {"Please Enter valid Email Id"}
              </Text>
            </div>
          )}
          <TermsAndConditions
            id={`${parent_id}_addmember_form_send_appointment_emailid_termsandcondition`}
            checkboxSize={14}
            checkboxTickColor={"#fff"}
            activeCheckboxBorderColor={UIColor.secondaryButtonColor.color}
            checkboxBorderColor={UIColor.lineBorderFill.color}
            checkboxBackgroundColor={UIColor.differentiationBackground.color}
            activeCheckboxBackgroundColor={UIColor.secondaryButtonColor.color}
            value={state.emailIdChecked}
            onChange={(e) => {
              changeState("emailIdChecked", e);
            }}
            className={classes.termsAndConditions}
            lable={
              <H6
                id={`${parent_id}_addmember_form_send_appointment_emailid_termsandcondition_inside_span`}
                className="pc_regular"
                style={{
                  fontSize: "12px",
                  color: UIColor.primaryText.color,
                  margin: 0,
                  letterSpacing: 0,
                }}
              >
                Send appointment update and notification to this mail id
              </H6>
            }
            inLineStyles={{ lineHeight: 1, marginTop: 16 }}
          />
          {addNewMember && (
            <>
              <H6
                id={`${parent_id}_addmember_form_relationship_text`}
                key={"10"}
                name={
                  <span
                    id={`${parent_id}_addmember_form_relationship_inside_span`}
                    className="pc_regular"
                  >
                    What's your relationship with{" "}
                    {state.firstName || "the person"}?{" "}
                    <span
                      id={`${parent_id}_addmember_form_relationship_inside_star`}
                      style={{ color: UIColor.error.color }}
                    >
                      *
                    </span>
                  </span>
                }
                style={{
                  color: UIColor.secondaryText.color,
                  margin: 0,
                  marginTop: "16px",
                  fontSize: "12px",
                  lineHeight: 1,
                }}
                className={"pc_regular"}
              ></H6>
              <TapSelect
                id={`${parent_id}_addmember_form_relationship_tapselect`}
                key={"11"}
                label={false}
                fullWidth={false}
                selectedBorderColor={UIColor.secondaryButtonColor.color} 
                selectedBackgroundColor={UIColor.secondaryButtonColor.color}
                selectedTextColor="#fff"
                borderColor={UIColor.differentiationBackground.color}
                backgroundColor={UIColor.differentiationBackground.color}
                textColor={UIColor.secondaryText.color}
                textSize="16"
                iconHeight="20"
                iconWidth="20"
                title={false}
                name={false}
                inLineStyles={{
                  fontSize: "14px",
                  fontFamily: "'pc_regular' !important",
                }}
                options={relationshipData?.data??[]}
                // value={state.relation}
                defaultSelected={{
                  value: state.relation?.value, label: state.relation?.label
                }}
                value={{ value: state.relation?.value, label: state.relation?.label }}
                onChange={(e) => changeState("relation", e)}
                iconPosition={"left"}
                className={classes.tapSelect}
              ></TapSelect>{" "}
            </>
          )}

          <Button
            id={`${parent_id}_addmember_form_relationship_sendotp_button`}
            key={"12"}
            type={"button"}
            disabled={props?.registerUsers?.loading}
            variant={"outlined"}
            name={
              props?.registerUsers?.loading
                ? "Sending OTP..."
                : addNewMember
                ? props?.addMember?.loading ||
                  props?.readPerson?.loading
                  ? "Adding..."
                  : "Send Otp & Add Member"
                : state.loadingReadPerson
                ? "Signing Up..."
                : props?.readPerson?.loading || props?.registerUsers?.loading ? "Sending OTP..." : "Send OTP & Sign Up"
            }
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              marginTop: "40px",
              borderRadius: "8px",
              padding: "8px 16px",
              marginBottom: "10px",
              backgroundColor: UIColor.primaryColor.color,
              border: "none",
              fontSize: "14px",
              height: "40px",
            }}
            onClick={handleSignUp}
            className={"pc_medium"}
          ></Button>
        </Container>
      </div>
  );
}



export default (AddMemberForm);
